import React, { useEffect, useState } from "react";

import api from "../../../../../../services/api";

import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import { Row, Modal } from "react-bootstrap";

import './SessionChange.scss';

export interface propChange {
    sessionInfo: any,
    items: any,
    row: any,
    show?: any;
    setResponseText?: any,
    setModalContent?: any,
    setLog?: any
};

const SessionChange: React.FC<propChange> = ({
    sessionInfo, items, row, show, setResponseText, setModalContent, setLog
}: propChange) => {

    const [sessions, setSessions] = useState<any>([]);

    const validated = false;

    const operation = window.location.href.split('?')[2].split('operation=')[1];
    const [loading, setLoading] = useState<any>(false);
    const [modalShow, setModalShow] = useState<any>(false);

    const {
        control,
        handleSubmit,
        watch,
        //formState: { errors },
    } = useForm();

    useEffect(() => {
        const ListSessions = async () => {
            try {
                const { data } = await api.post(`/Manifest/GetSessionsByDate`,
                    {
                        date: sessionInfo.data,
                        operationType: Number(operation)
                    }
                );
                if (data.status !== 400) {
                    setSessions(data.data);
                    console.log(data.data);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) { }
                if (error?.response?.status === 400) { }
            }
        }
        ListSessions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (data: any) => {
        setLoading(true)
        data.session = data.session !== undefined ? Number(data.session) : sessionInfo.sessionId;

        const ChangeSession = async () => {
            try {
                const res = await api.post(`/Manifest/ChangeSession`,
                    {
                        "localizador": row.localizador,
                        "itemId": row.newItemsId,
                        "qtdePax": row.qtdePax,
                        "sessionOrigem": sessionInfo.sessionId,
                        "sessionDestino": data.session
                    }
                );
                if (res.status !== 400) {
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                    setLoading(false);
                    closeModal();
                    show(true);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    alert("Erro ao processar operação");
                    setModalShow(true);
                    setLog(1);
                    setModalContent("error");
                    setLoading(false)
                }
                if (error?.response?.status === 400) {
                    setModalShow(true);
                    alert("Erro ao processar operação");
                    setLog(1);
                    setModalContent("error");
                    setLoading(false)
                }
            }
        }
        ChangeSession();
    }

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const sessionId = watch(`session`, false);

    console.log(row)

    return (
        <>
            <Form noValidate validated={validated} autoComplete="false">
                <div>
                    <p className="subtitle-change-session">Session Destino</p>
                </div>
                <Form.Group>
                    <Controller
                        control={control}
                        name={`session`}
                        render={({ field }: any) => (
                            <div className="endpoint" >
                                <Form.Select
                                    aria-labelledby="aria-label"
                                    inputId="aria-example-input"
                                    name="aria-live-color"
                                    {...field}
                                    as="select"
                                    variant="standard"
                                    margin="normal"
                                    //defaultValue={sessionInfo.sessionId}
                                    value={sessionId === undefined ? sessionInfo.sessionId : sessionId}
                                >
                                    {/* <option value='' selected>Selecione</option> */}
                                    {
                                        sessions.map((session: any) => {
                                            return (
                                                <option value={`${session.sessionId}`}>{`${session.sessionId} - ${session.hora} - ${session.produto} - ${session.vagasUsadas} PAX`}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        )}
                    />
                </Form.Group>
                <Modal.Footer>
                    <Row className="buttons-modal d-flex justify-content-end mx-0">
                        <div className='mt-4 d-flex justify-content-end px-0'>
                            <div>
                                <button className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase" onClick={closeModal}>
                                    Cancelar
                                </button>
                            </div>
                            <div /* onClick={() => setModalShow(true)} */>
                                <button className="btn btn-default btn-primary px-4 text-uppercase" type="submit" onClick={handleSubmit(onSubmit)}>
                                    {
                                        loading === true
                                            ?
                                            <div className="d-flex align-items-center" style={{ width: "113px", height: "30px" }}>
                                                <div className="load"></div>
                                            </div>
                                            :
                                            "Cadastrar"
                                    }
                                </button>
                            </div>
                        </div>
                    </Row>
                </Modal.Footer>
            </Form>
        </>
    )
}

export default SessionChange;