/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

import { Row, Col, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
//import SingleCalendar from '../../../../../../components/Calendar/SingleCalendar';
//import GetCostCenter from '../../../../../../components/C2Points/GetCostCenter';
import GetSuppliersFilter from '../../../../components/C2Points/GetSuppliersFilter';
//import GetAccountingAccountsFilter from '../../../../../../components/C2Points/GetAccountingAccountsFilter';
//import GetPercentSupplierComission from '../../../../../../components/C2Points/GetPercentSupplierComission';
import GetSearchCategoriesMult from '../../../../components/C2Points/GetSearchCategoriesMult';
import GetSearchProductMult from '../../../../components/C2Points/GetSearchProductMult';
import SingleCalendarTimeCupom from '../../../../components/Calendar/SingleCalendarTimeCupom';
import GetAffiliatesSupplierId from '../../../../components/C2Points/GetAffiliatesSupplierId';

export interface propInsert {
    show?: any;
    setResponseText?: any,
    setModalContent?: any,
    setLog?: any
}

const InsertCoupom: React.FC<propInsert> = ({
    show, setResponseText, setModalContent, setLog
}: propInsert) => {
    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('');

    const [fileName] = useState<any>('');
    const [date, setDate] = useState<any>();
    const [vencimento, setVencimento] = useState<any>();
    const [cupom, setCupom] = useState<boolean>(true);

    const [loading, setLoading] = useState<any>(false);

    const [valueD, setValueD] = useState<any>(null);
    const [valueC, setValueC] = useState<any>(null);
    const [maxDiscount, setMaxDiscount] = useState<number>(20);
    const [maxDiscountReal, setMaxDiscountReal] = useState<number>(10);

    const [valueCError, setValueCError] = useState<boolean>(false);
    const [valueDError, setValueDError] = useState<boolean>(false);

    function setFilterSupplier() { }

    //onsole.log(date === "", vencimento === "");

    const validated = false;

    /*     const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    
        const userName: any = tourAuth.user.nome;
        const userLastName: any = tourAuth.user.lastName;
        const uId: any = tourAuth.user.uId;
    
        const fileHandler = (e: any) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setFile(reader.result)
                }
            }
            reader.readAsDataURL(e.target.files[0]);
            setFileName(e.target.files[0].name);
        };
    
        function handleIsTour(e: any) {
            setIsTour(e.target.value)
        };
     */
    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();

    const typeD = watch("typeDiscount", false);
    const typeC = watch("typeCommission", false);
    const categoryOrProduct = watch("categoryOrProduct", '1');

    useEffect(() => {
        console.log(categoryOrProduct)
    }, [categoryOrProduct])

    useEffect(() => {

        if (valueD > maxDiscount) {
            setValueD(maxDiscount);
        }

        if (valueC > maxDiscount) {
            setValueC(maxDiscount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueD, typeD, valueC, typeC])

    const fixDate = (data: any) => {
        var aux = data.split(' ')[0].split('/');
        var auxTime = data.split(' ')[1];

        return (`${aux[2]}-${aux[1]}-${aux[0]}T${auxTime}:00`);
    }

    function verifyDate(now: any, due: any) {
        var nowArray: any = now.split('-').join("").split("T")[0];
        var dueArray: any = due.split('-').join("").split("T")[0];

        console.log(nowArray, dueArray);

        if (Number(nowArray) >= Number(dueArray)) {
            return false
        }

        return true;
    }

    const onSubmit = (data: any) => {
        setLoading(true);

        if (date === "" || vencimento === "") {
            alert("Por favor, informe a data de início e fim!");
            setLoading(false);
        } else if (valueC === "" || valueD === "" || valueC === null || valueD === null) {
            setLoading(false);

            if (valueC === "" || valueC === null) {
                setValueCError(true);

                /* setTimeout(() => {
                    setValueCError(false);
                }, 2000); */
            }

            if (valueD === "" || valueD === null) {
                setValueDError(true);

                /* setTimeout(() => {
                    setValueDError(false);
                }, 2000); */
            }
        } else {
            data.cupom = cupom !== false ? 1 : 2;
            data.valueCommission = valueC;
            data.valueDiscount = valueD;
            data.beginDate = fixDate(date);
            data.endDate = fixDate(vencimento);

            setModalContent("loading");

            if (verifyDate(data.beginDate, data.endDate)) {
                const insertCoupon = async () => {
                    const token = localStorage.getItem('GroupId') || '{}';
                    const config = {
                        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                    };
                    try {
                        const res = await api.post(`/TourCode/CreateTourCodeCupomAsync`,
                            {
                                "beginDate": data.beginDate,
                                "endDate": data.endDate,
                                "valueDiscount": data.valueDiscount,
                                "code": cupom !== false ? data.codeName : undefined,
                                "valueCommission": data.valueCommission,
                                "codeName": data.descricao,
                                "isCupom": data.cupom
                            }, config);
                        if (res.status !== 400) {
                            if (res.data.data.log === 0) {
                                setResponseText(res.data.data.texto);
                                setLog(res.data.data.log);
                                setModalContent("success");
                                closeModal();
                            } else {
                                setResponseText(res.data.data.texto);
                                setLog(res.data.data.log);
                                setModalContent("error");
                            }
                            setLoading(false);
                            show(true);
                        }
                    } catch (error: any) {
                        setLoading(false);
                        setModalContent("error");
                        setModalMessage(error?.response?.data.errorMessage || "Erro ao processar operação");
                        setModalShow(true);
                    }
                };

                insertCoupon();
            } else {
                setModalShow(true);
                setModalContent("error");
                setModalMessage("Data de vencimento antes da data atual.");
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (valueC === "") {
            setValueCError(true);
        } else {
            setValueCError(false);
        }

        if (valueD === "") {
            setValueDError(true);
        } else {
            setValueDError(false);
        }
    }, [valueC, valueD])

    return (
        <>
            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div>
                        <Row className="mb-3">
                            {/* <Form.Group as={Col} md="3" controlId="">
                                <div className="d-flex align-items-center pt-4" style={{ height: "70px" }}>
                                    <Controller
                                        control={control}
                                        name="cupom"
                                        render={({ field }: any) => (
                                            <Form.Check
                                                {...field}
                                                defaultChecked={cupom === false}
                                                onClick={() => { setCupom(false) }}
                                                type="radio"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                className="mx-2"
                                            />
                                        )}
                                    />
                                    <Form.Label className="mb-0">
                                        TourCode   *****FOI PEDIDO PARA REMOVER TOURCODE******
                                    </Form.Label>
                                </div>
                            </Form.Group> */}

                            <Form.Group as={Col} md="6" controlId="">
                                <div className="d-flex align-items-center pt-4" style={{ height: "70px" }}>
                                    <Controller
                                        control={control}
                                        name="cupom"
                                        render={({ field }: any) => (
                                            <Form.Check
                                                {...field}
                                                defaultChecked={cupom === true}
                                                onClick={() => { setCupom(true) }}
                                                type="radio"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                className="mx-2"
                                            />
                                        )}
                                    />
                                    <Form.Label className="mb-0">
                                        Cupom
                                    </Form.Label>
                                </div>
                            </Form.Group>
                            {cupom === true ? <>
                                <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                    <Form.Label>Nome do cupom</Form.Label>
                                    <Controller
                                        control={control}
                                        name="codeName"
                                        rules={{ required: { value: true, message: "Por favor, informe o nome do cupom" }, }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.descricao ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                maxLength={20}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="codeName"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </>
                                :
                                <></>
                            }
                        </Row>

                        <Row>
                            <Form.Group as={Col} className="mb-3" controlId="formGridDate">
                                <Form.Label>Data de início</Form.Label>
                                <InputGroup
                                    hasValidation
                                    className=""
                                    size="sm"
                                    placeholder="Quando?"
                                >
                                    <SingleCalendarTimeCupom setValue={setDate} />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="formGridVenc">
                                <Form.Label>Data Fim</Form.Label>
                                <InputGroup
                                    hasValidation
                                    className=""
                                    size="sm"
                                    placeholder="Quando?"
                                >
                                    <SingleCalendarTimeCupom setValue={setVencimento} />
                                </InputGroup>
                            </Form.Group>
                        </Row>


                        <Row className="mb-3">
                            <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                <Form.Label>Tipo de desconto</Form.Label>
                                <Controller
                                    control={control}
                                    name="typeDiscount"
                                    render={({ field }: any) => (
                                        <div className={errors.typeDiscount ? "endpoint-error" : "endpoint"}>
                                            <Form.Select
                                                {...field}
                                                as="select"
                                                variant="standard"
                                                margin="normal"
                                                disabled
                                            >
                                                <option value='4' selected disabled>Percentual do total</option>
                                            </Form.Select>
                                        </div>
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={6} controlId="formGridSupplier">

                                <Form.Label>{`Valor (máx: ${maxDiscount}%)`}</Form.Label>

                                {/* <Form.Control autoComplete='off' type="text" /> */}
                                <Controller
                                    control={control}
                                    name="valueDiscount"
                                    //rules={{ required: { value: true, message: "Por favor, informe o valor." }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={valueDError === true ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                            value={valueD}
                                            onChange={(e: any) => setValueD(e.target.value)}
                                        />
                                    )}
                                />
                                {
                                    valueDError === true
                                        ?
                                        <small style={{ color: "red" }}>Por favor, informe o valor.</small>
                                        :
                                        <></>
                                }
                            </Form.Group>
                            <div className="col-12 col-md-3 d-flex align-items-end">
                                <small style={{ lineHeight: "38px" }}>{fileName}</small>

                            </div>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>Nome amigável</Form.Label>
                                <Controller
                                    control={control}
                                    name="descricao"
                                    rules={{ required: { value: true, message: "Por favor, informe o nome amigável." }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.descricao ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            maxLength={20}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="descricao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                        <hr style={{ margin: "30px 0" }} />

                        {/*  <Row className="mb-3">
                            <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                <Form.Label>Cliente</Form.Label>
                                <Controller
                                    control={control}
                                    name="supplierId"
                                    rules={{ required: { value: true, message: "Por favor, informe o fornecedor." }, }}
                                    render={({ field }: any) => (
                                        <GetAffiliatesSupplierId
                                            propsField={field}
                                            propsErrors={errors}
                                            propsLabel="supplierId"
                                            setFilterSupplier={setFilterSupplier}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="supplierId"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row> */}

                        <Row className="mb-3">
                            <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                <Form.Label>Tipo de comissão</Form.Label>
                                <Controller
                                    control={control}
                                    name="typeCommission"
                                    render={({ field }: any) => (
                                        <div className={errors.typeCommission ? "endpoint-error" : "endpoint"}>
                                            <Form.Select
                                                {...field}
                                                as="select"
                                                variant="standard"
                                                margin="normal"
                                                disabled
                                            >
                                                <option value='4' selected disabled>Percentual do total</option>
                                            </Form.Select>
                                        </div>
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={6} controlId="formGridSupplier">

                                <Form.Label>{`Comissão (máx: ${maxDiscount}%)`}</Form.Label>

                                <Controller
                                    control={control}
                                    name="valueCommission"
                                    //rules={{ required: { value: true, message: "Por favor, informe o valor da comissão." }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={valueCError === true ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                            value={valueC}
                                            onChange={(e: any) => setValueC(e.target.value)}
                                        />
                                    )}
                                />
                                {
                                    valueCError === true
                                        ?
                                        <small style={{ color: "red" }}>Por favor, informe o valor.</small>
                                        :
                                        <></>
                                }
                            </Form.Group>
                            <div className="col-12 col-md-3 d-flex align-items-end">
                                <small style={{ lineHeight: "38px" }}>{fileName}</small>
                            </div>
                        </Row>

                        {/*                         <Row>
                            <Form.Group as={Col} md="3" controlId="">
                                <div className="d-flex align-items-center" style={{ height: "70px" }}>
                                    <Controller
                                        control={control}
                                        name="categoryOrProduct"
                                        rules={{ required: { value: true, message: "Por favor, preencha esse campo" }, }}
                                        render={({ field }: any) => (
                                            <Form.Check
                                                {...field}
                                                checked={categoryOrProduct === '1'}
                                                // onClick={() => { setCupom(true) }}
                                                value="1"
                                                type="radio"
                                                id="categoryOrProduct-1"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                className="mx-2"
                                            />
                                        )}
                                    />
                                    <Form.Label className="mb-0" htmlFor='categoryOrProduct-1'>
                                        Por Categoria
                                    </Form.Label>
                                </div>
                                <ErrorMessage
                                    errors={errors}
                                    name="categoryOrProduct"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="">
                                <div className="d-flex align-items-center" style={{ height: "70px" }}>
                                    <Controller
                                        control={control}
                                        name="categoryOrProduct"
                                        rules={{ required: { value: true, message: "Por favor, preencha esse campo" }, }}
                                        render={({ field }: any) => (
                                            <Form.Check
                                                {...field}
                                                // onClick={() => { setCupom(true) }}
                                                value="2"
                                                type="radio"
                                                id="categoryOrProduct-2"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                className="mx-2"
                                            />
                                        )}
                                    />
                                    <Form.Label className="mb-0" htmlFor='categoryOrProduct-2'>
                                        Por Produto
                                    </Form.Label>
                                </div>
                            </Form.Group>
                        </Row> */}

                        {/*  {
                            Number(categoryOrProduct) === 1 &&

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridDesc">
                                    <Form.Label>Escolha a categoria</Form.Label>
                                    <Controller
                                        control={control}
                                        name="categoriaId"
                                        rules={{ required: { value: true, message: "Por favor, selecione as categorias." }, }}
                                        render={({ field }: any) => (
                                            <div className={errors.categoriaId ? "endpoint-error" : ""}>
                                                <GetSearchCategoriesMult
                                                    propsField={field}
                                                    propsErrors={errors}
                                                    propsLabel="categoriaId"
                                                />
                                            </div>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="categoriaId"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
                        } */}

                        {/* {
                            Number(categoryOrProduct) === 2 &&

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridDesc">
                                    <Form.Label>Escolha o produto</Form.Label>
                                    <Controller
                                        control={control}
                                        name="productId"
                                        rules={{ required: { value: true, message: "Por favor, selecione os produtos." }, }}
                                        render={({ field }: any) => (
                                            <div className={errors.productId ? "endpoint-error" : ""}>
                                                <GetSearchProductMult
                                                    propsField={field}
                                                    propsErrors={errors}
                                                    propsLabel="productId"
                                                />
                                            </div>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="productId"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
                        } */}
                    </div>
                    <div className='mt-4 d-flex justify-content-end'>
                        <button className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase" onClick={closeModal}>
                            Cancelar
                        </button>
                        <button className="btn btn-default btn-primary px-4 text-uppercase" type="submit">
                            {
                                loading === true
                                    ?
                                    <div className="load"></div>
                                    :
                                    "Cadastrar"
                            }
                        </button>
                    </div>
                </div>
            </Form>

            <Modal
                className="modal-custom modalAuth"
                show={modalShow}
                onHide={() => { setModalShow(false); setModalMessage('') }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <div className='d-flex w-100 h-100 justify-content-center py-5'>
                        <h4>{modalMessage}</h4>
                    </div>
                </>
            </Modal>
        </>
    )
}

export default InsertCoupom;