import React from 'react';

import { Container } from 'react-bootstrap';
import '../../../App.css';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import EditSuppliersData from '../components/EditSupplier/EditSuppliersData/EditSuppliersData';
import DesktopDefault from '../../../templates/DesktopDefault';

function EditSuppliers() {
  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
            <Breadcrumb title="Editar Fornecedores"/>
            <div className="partners mt-4">
              <h2 id="page-title" className="title" style={{color: "#707070"}}>Fornecedores</h2>
              
              {/* Content */}

              <EditSuppliersData  />

              {/* Content */}

            </div>
        </div>
      </DesktopDefault>
    </>
  );
}

export default EditSuppliers;