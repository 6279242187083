import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
//import logo from '../../logo.svg';

export interface propInfo {
    info: any;
    setChangeEdit: any;
    showDocument: any;
};

const EditDocuments: React.FC<propInfo> = ({
    info, setChangeEdit, showDocument
}: propInfo) => {

    function textReduce(cell: any) {
        var aux = cell.split(' ')
        if (aux.length >= 4) {
            aux = aux[0] + ' ' + aux[1] + ' ' + aux[2] + ' ' + aux[3] + '...';
        } else {
            aux = cell;
        }

        return (
            <>
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip">{cell}</Tooltip>
                    }>
                    <span className="text-wrap-custom">
                        {cell}
                    </span>
                </OverlayTrigger>
            </>
        )
    }

    const downloadContract = (contract: any, name: any) => {
        const link = document.createElement('a');
        link.href = `${contract.split(";").join(";base64")}`;
        link.setAttribute(
            'download',
            `${name.split(".")[1] === undefined ? `${name}.pdf` : `${name}`}`,
        );
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);
    };

    return (
        <>
            <div id="documentsData" className="d-flex">
                <div className="border">
                    <div className="edit-title container-fluid">
                        <h3 className="">Documentos</h3>
                        <button onClick={() => { setChangeEdit(false) }}>EDITAR</button>
                    </div>
                    {info.compType === 1
                        ?
                        <div className="data">
                            <div className="data-unit">
                                {info.cpfDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>CPF:</p>
                                        {
                                            info.cpfDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.cpfDoc, info.cpfName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.cpfDoc, info.cpfName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>CPF:</p>
                                }
                                <p className='w-100'>{info.cpfName ? textReduce(info.cpfName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.rgDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>RG:</p>
                                        {
                                            info.rgDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.rgDoc, info.rgName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.rgDoc, info.rgName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>RG:</p>
                                }
                                <p className='w-100'>{info.rgName ? textReduce(info.rgName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.compResDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>COMPROVANTE DE RESIDÊNCIA:</p>
                                        {
                                            info.compResDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.compResDoc, info.compResidFileName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.compResDoc, info.compResidFileName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>COMPROVANTE DE RESIDÊNCIA:</p>
                                }
                                <p className='w-100'>{info.compResidFileName ? textReduce(info.compResidFileName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.docSerasaDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>DOCUMENTAÇÃO SERASA:</p>
                                        {
                                            info.docSerasaDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.docSerasaDoc, info.docSerasaFileName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.docSerasaDoc, info.docSerasaFileName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>DOCUMENTAÇÃO SERASA:</p>
                                }
                                <p className='w-100'>{info.docSerasaFileName ? textReduce(info.docSerasaFileName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.cartHabDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>CARTEIRA DE HABILITAÇÃO:</p>
                                        {
                                            info.cartHabDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.cartHabDoc, info.cnhFileName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.cartHabDoc, info.cnhFileName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>CARTEIRA DE HABILITAÇÃO:</p>
                                }
                                <p className='w-100'>{info.cnhFileName ? textReduce(info.cnhFileName) : "não definido"}</p>
                            </div>
                            <div className="data-unit data-unit-white-bg"></div>
                        </div>
                        :
                        <div className="data">
                            <div className="data-unit">
                                {info.socialContractDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>CONTRATO SOCIAL:</p>
                                        {
                                            info.socialContractDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.socialContractDoc, info.socialContractName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.socialContractDoc, info.socialContractName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>CONTRATO SOCIAL:</p>
                                }
                                <p className='w-100'>{info.socialContractName ? textReduce(info.socialContractName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.rgReprDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>RG DO REPRESENTANTE DA EMPRESA:</p>
                                        {
                                            info.rgReprDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.rgReprDoc, info.rgReprName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.rgReprDoc, info.rgReprName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>RG DO REPRESENTANTE DA EMPRESA:</p>
                                }
                                <p className='w-100'>{info.rgReprName ? textReduce(info.rgReprName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.cpfSocReprDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>TELEFONE E CPF DOS SÓCIOS:</p>
                                        {
                                            info.cpfSocReprDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.cpfSocReprDoc, info.cpfSocReprName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.cpfSocReprDoc, info.cpfSocReprName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>TELEFONE E CPF DOS SÓCIOS:</p>
                                }
                                <p className='w-100'>{info.cpfSocReprName ? textReduce(info.cpfSocReprName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.rgTurReprDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>REGISTRO DO MINISTÉRIO DO TURISMO:</p>
                                        {
                                            info.rgTurReprDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.rgTurReprDoc, info.rgTurReprName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.rgTurReprDoc, info.rgTurReprName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>REGISTRO DO MINISTÉRIO DO TURISMO:</p>
                                }
                                <p className='w-100'>{info.rgTurReprName ? textReduce(info.rgTurReprName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.cnpjReprDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>CNPJ COM ATIVIDADE PRINCIPAL AGÊNCIA DE TURISMO:</p>
                                        {
                                            info.cnpjReprDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.cnpjReprDoc, info.cnpjReprName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.cnpjReprDoc, info.cnpjReprName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>CNPJ COM ATIVIDADE PRINCIPAL AGÊNCIA DE TURISMO:</p>
                                }
                                <p className='w-100'>{info.cnpjReprName ? textReduce(info.cnpjReprName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.insMunReprDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>INSCRIÇÃO MUNICIPAL:</p>
                                        {
                                            info.insMunReprDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.insMunReprDoc, info.insMunReprName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.insMunReprDoc, info.insMunReprName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>INSCRIÇÃO MUNICIPAL:</p>
                                }
                                <p className='w-100'>{info.insMunReprName ? textReduce(info.insMunReprName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.certNegReprDoc !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>CERTIDÃO NEGATIVA (ATUAL) DE PROTESTOS E TÍTULOS:</p>
                                        {
                                            info.certNegReprDoc.split(";")[0] === "data:image/pdf"
                                            ?
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadContract(info.certNegReprDoc, info.certNegReprName) }}>Baixar</span>
                                            :
                                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.certNegReprDoc, info.certNegReprName) }}>Ver</span>
                                        }
                                    </div>
                                    :
                                    <p>CERTIDÃO NEGATIVA (ATUAL) DE PROTESTOS E TÍTULOS:</p>
                                }
                                <p className='w-100'>{info.certNegReprName ? textReduce(info.certNegReprName) : "não definido"}</p>
                            </div>
                            <div className="data-unit data-unit-white-bg"></div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default EditDocuments;