import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductCarrousel.scss";

export interface arrayImages {
    arrayImages: any;
}

function TourSlider(props: any) {
    const [nav1, setNav1] = useState<any>();
    const [nav2, setNav2] = useState<any>();
    const [slider1, setSlider1] = useState<any>();
    const [slider2, setSlider2] = useState<any>();

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, [slider1, slider2]);

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        swipeToSlide: true,
        focusOnSelect: true,
        asNavFor: ".slider-nav",
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    //infinite: true,
                    dots: true,
                    arrows: true
                }
            }]
    };

    const settingsThumbs = {
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: ".slider-for",
        dots: false,
        centerMode: false,
        infinite: true,
        vertical: true,
        focusOnSelect: true,
        verticalSwiping: true,
        arrows: false,
    };

    console.log(props)

    if (props.arrayImages.images !== null) {
        return (
            <div className="tourSlider mb-5">
                <div className="slider-wrapper">
                    <Row>
                        <Col className="col-3 p-0 m-0">
                            <div className="thumbnail-slider-wrap d-none d-md-block">
                                <Slider
                                    {...settingsThumbs}
                                    asNavFor={nav1}
                                    ref={(slider) => setSlider2(slider)}
                                >
                                    {props.arrayImages.images.length > 0
                                        ? props.arrayImages.images.map((img: any, index: any) => (
                                            <div className="slick-slide" key={index}>
                                                <img
                                                    className="slick-slide-image"
                                                    src={`${props.arrayImages.imagesBaseUrl}${img}`}
                                                    alt="..."
                                                />
                                            </div>
                                        ))
                                        : ""}
                                </Slider>
                            </div>
                        </Col>
                        <Col className="col-12 col-md-9 m-0" style={{ paddingRight: "0" }}>
                            <Slider
                                {...settingsMain}
                                asNavFor={nav2}
                                ref={(slider) => setSlider1(slider)}
                            >
                                {props.arrayImages.images.length > 0
                                    ? props.arrayImages.images.map((img: any, index: any) => (
                                        <div className="slick-slide" key={index}>
                                            <img
                                                className="slick-slide-image"
                                                src={`${props.arrayImages.imagesBaseUrl}${img}`}
                                                alt="..."
                                            />
                                        </div>
                                    ))
                                    : ""}
                            </Slider>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}

export default TourSlider;
