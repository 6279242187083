import React, { useEffect, useRef, useState } from "react";

import api from "../../services/api";

import "./DropdownHours.scss";

export interface propsR {
    changePriceDateTour: any,
    dateTour: any,
    index?: any,
    setPeopleDisponible?: any,
    edit?: any,
    item?: any,
    info?: any,
    isCorp?: any
}

const DropdownHoursEvent: React.FC<propsR> = ({
    changePriceDateTour, dateTour, index = 0, setPeopleDisponible, edit = false, item, info, isCorp = false
}: propsR) => {
    console.log(item)
    const [isOpen, setIsOpen] = useState<any>(false);
    const [hours, setHours] = useState<any>(null);
    const [select, setSelect] = useState<any>(null);
    const [loading, setLoading] = useState<any>(true);

    /* Detecta clique fora da div#wrapper para fechar*/
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const handleClickHours = () => {
        isOpen === false ? setIsOpen(true) : setIsOpen(false);
    };

    function handleSelectHours(hour: any, price: any, hours: any) {
        setSelect(hour);

        var aux: any = dateTour;
        //aux[index].data = dateTour.data;
        //aux[index].idSellingType = objCalendar.idSellingType;
        aux[index].time = hour;
        aux[index].adult = price.sellingTarifAdultFinal;
        aux[index].child = price.sellingTarifChildFinal;
        aux[index].infant = price.sellingTarifInfantFinal;
        aux[index].student = price.sellingTarifEldersFinal;
        aux[index].elders = price.sellingTarifStudentFinal;
        aux[index].global = price.sellingTarifGlobalFinal;
        aux[index].maxStockNumber = price.balanceAvailableByChannel;
        aux[index].hours = hours;

        setIsOpen(false);
        if (edit !== true) {
            //setPeopleDisponible(true);
        }
        changePriceDateTour(aux, hour);
    }

    useEffect(() => {
        let idChannel: number = 1;
        if (edit !== true) {
            setSelect(null);
            //setPeopleDisponible(false);
        } else {
            if (dateTour[0]?.time === undefined) {
                dateTour[0].time = item.startTimeLocal.split("T")[1];
            }
        }
        setLoading(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };

        async function listHours() {
            try {
                const { data } = await api.post(`/Products/GetTicketAndTourHoursStockAsync`,
                    {
                        "page": 1,
                        "rowsPerPage": 10,
                        "dateStart": dateTour[index].data,
                        "tarUniqueCode": dateTour[index].tarUniqueCode,
                        "prodModUniqueCode": dateTour[index].prodModUniqueCode/* 'MOA5357' */,
                        "daysUniqueTarCode": dateTour[index].daysUniqueTarCode,
                        "idCanal": idChannel
                    }, config);
                if (data.status !== 400) {
                    setLoading(false);
                    setHours(data.data);

                    //caso só exista um horário, já seleciona automático
                    if (data.data.length === 1) {
                        handleSelectHours(data.data[0].hora, data.data[0], data.data);
                    }
                } else {

                }
            } catch (error: any) {
                /* if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                } */
            }
        }

        async function getIdChannel() {
            const config = {
                headers: { "ngrok-skip-browser-warning": "69420" },
            };

            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/${isCorp === true ? `${item?.source}` : "sig"}`, config);
                if (data.statusCode === 200) {
                    idChannel = data.data.data;
                    listHours();
                }
            } catch (error) { }
        }
        getIdChannel();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTour[index]?.data]);

    useEffect(() => {
        if(hours !== null && hours.length > 1) {
            handleSelectHours(item?.startTimeLocal.split("T")[1], hours[0], hours);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hours])

    return (
        <span className="response-modal">
            <b>{item?.startTimeLocal?.split("T")[1]}</b>
        </span>
    )

}

export default DropdownHoursEvent;