/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Card, Container, Row, Col, OverlayTrigger } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import { useTranslation } from "react-i18next";

import '../../../../assets/sass/accordion.scss';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from 'react-bootstrap/Tooltip';

export interface propAccordion {
    action: any,
    ContextAwareToggle: any,
    compType: any,
    setStepCtrl: any
    isEdit?: any;
    infoEdit?: any;
    editPartners?: any;
    hasSavedInputs?: any;
};

const AccordionDocuments: React.FC<propAccordion> = ({
    action, ContextAwareToggle, compType, setStepCtrl, infoEdit, isEdit = false, editPartners, hasSavedInputs
}: propAccordion) => {

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(true);
    // const [hasContractControl, setHasContractControl] = useState({ value: true, message: 'Por favor, preencher esse campo.' });
    const { t } = useTranslation();
    const validated = false;

    const [cpf, setCpf] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cpfDoc : '');
    const [cpfName, setCpfName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cpfName : '');

    const [rg, setRg] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.rgDoc : '');
    const [rgName, setRgName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.rgName : '');

    const [compRes, setCompRes] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.compResDoc : '');
    const [compResName, setCompResName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.compResName : '');

    const [docSerasa, setDocSerasa] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.docSerasaDoc : '');
    const [docSerasaName, setDocSerasaName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.docSerasaName : '');

    const [cartHab, setCartHab] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cartHabDoc : '');
    const [cartHabName, setCartHabName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cartHabName : '');

    const [socialContract, setsocialContract] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.socialContractDoc : '');
    const [socialContractName, setsocialContractName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.socialContractName : '');
    const [socialContractVal, setSocialContractVal] = useState<boolean>(false);

    const [rgRepr, setrgRepr] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.rgReprDoc : '');
    const [rgReprName, setrgReprName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.rgReprName : '');
    const [rgReprVal, setRgReprVal] = useState<boolean>(false);

    const [cnpjRepr, setCnpjRepr] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cnpjReprDoc : '');
    const [cnpjReprName, setCnpjReprName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cnpjReprName : '');
    const [cnpjReprVal, setCnpjReprVal] = useState<boolean>(false);

    const [rgTurRepr, setRgTurRepr] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.rgTurReprDoc : '');
    const [rgTurReprName, setRgTurReprName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.rgTurReprName : '');
    const [rgTurReprVal, setRgTurReprVal] = useState<boolean>(false);

    const [insMunRepr, setInsMunRepr] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.insMunReprDoc : '');
    const [insMunReprName, setInsMunReprName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.insMunReprName : '');
    const [insMunReprVal, setInsMunReprVal] = useState<boolean>(false);

    const [certNegRepr, setCertNegRepr] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.certNegReprDoc : '');
    const [certNegReprName, setCertNegReprName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.certNegReprName : '');

    const [cpfSocRepr, setCpfSocRepr] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cpfSocReprDoc : '');
    const [cpfSocReprName, setCpfSocReprName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cpfSocReprName : '');
    const [cpfSocReprVal, setCpfSocReprVal] = useState<boolean>(false);

    const idPartner = window.location.href.split('id=')[1];

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm(/* {
        defaultValues: {
            rgDoc: isEdit === true ? infoEdit.rgDoc : '',
            cpfDoc: isEdit === true ? infoEdit.cpfDoc : '',
            compResDoc: isEdit === true ? infoEdit.compResDoc : '',
            docSerasaDoc: isEdit === true ? infoEdit.docSerasaDoc : '',
            cartHabDoc: isEdit === true ? infoEdit.cartHabDoc : '',
            socialContractDoc: isEdit === true ? infoEdit.socialContractDoc : '',
            rgReprDoc: isEdit === true ? infoEdit.rgReprDoc : ''
        },
    } */)

    const cpfHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                let docImage: any = reader.result;
                let changeDoc: any = docImage.split("base64");
                const finishDoc: any = changeDoc[0] + changeDoc[1];
                setCpf(finishDoc);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setCpfName(e.target.files[0].name);
    }

    const rgHandler = (e: any) => {

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                let docImage: any = reader.result;
                let changeDoc: any = docImage.split("base64");
                const finishDoc: any = changeDoc[0] + changeDoc[1];
                setRg(finishDoc);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setRgName(e.target.files[0].name);
    }

    const compResHandler = (e: any) => {

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                let docImage: any = reader.result;
                let changeDoc: any = docImage.split("base64");
                const finishDoc: any = changeDoc[0] + changeDoc[1];
                setCompRes(finishDoc);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setCompResName(e.target.files[0].name);
    }

    const docSerasaHandler = (e: any) => {

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                let docImage: any = reader.result;
                let changeDoc: any = docImage.split("base64");
                const finishDoc: any = changeDoc[0] + changeDoc[1];
                setDocSerasa(finishDoc);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setDocSerasaName(e.target.files[0].name);
    }

    const cartHabHandler = (e: any) => {

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                let docImage: any = reader.result;
                let changeDoc: any = docImage.split("base64");
                const finishDoc: any = changeDoc[0] + changeDoc[1];
                setCartHab(finishDoc);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setCartHabName(e.target.files[0].name);
    }

    const socialContractHandler = (e: any) => {
        if (e.target.files[0]?.type !== undefined) {
            if (e.target.files[0]?.type === "application/pdf" || e.target.files[0]?.type === "image/jpeg") {
                if (e.target.files[0]?.size <= 5242880) { //5MB -> 5242880 
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            let docImage: any = reader.result;
                            let changeDoc: any = docImage.split("base64");
                            const finishDoc: any = changeDoc[0] + changeDoc[1];
                            setsocialContract(finishDoc);
                        }
                    }
                    reader.readAsDataURL(e.target.files[0]);
                    setsocialContractName(e.target.files[0].name);
                } else {
                    alert("O tamanho da imagem tem que ser menor que 5MB");
                }
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }

    const cnpjReprHandler = (e: any) => {
        if (e.target.files[0]?.type !== undefined) {
            if (e.target.files[0]?.type === "application/pdf" || e.target.files[0]?.type === "image/jpeg") {
                if (e.target.files[0]?.size <= 5242880) { //5MB -> 5242880 
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            let docImage: any = reader.result;
                            let changeDoc: any = docImage.split("base64");
                            const finishDoc: any = changeDoc[0] + changeDoc[1];
                            setCnpjRepr(finishDoc);
                        }
                    }
                    reader.readAsDataURL(e.target.files[0]);
                    setCnpjReprName(e.target.files[0].name);
                } else {
                    alert("O tamanho da imagem tem que ser menor que 5MB");
                }
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }

    const rgTurReprHandler = (e: any) => {
        if (e.target.files[0]?.type !== undefined) {
            if (e.target.files[0]?.type === "application/pdf" || e.target.files[0]?.type === "image/jpeg") {
                if (e.target.files[0]?.size <= 5242880) { //5MB -> 5242880 
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            let docImage: any = reader.result;
                            let changeDoc: any = docImage.split("base64");
                            const finishDoc: any = changeDoc[0] + changeDoc[1];
                            setRgTurRepr(finishDoc);
                        }
                    }
                    reader.readAsDataURL(e.target.files[0]);
                    setRgTurReprName(e.target.files[0].name);
                } else {
                    alert("O tamanho da imagem tem que ser menor que 5MB");
                }
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }

    const insMunReprHandler = (e: any) => {
        if (e.target.files[0].type !== undefined) {
            if (e.target.files[0].type === "application/pdf" || e.target.files[0].type === "image/jpeg") {
                if (e.target.files[0]?.size <= 5242880) { //5MB -> 5242880 
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            let docImage: any = reader.result;
                            let changeDoc: any = docImage.split("base64");
                            const finishDoc: any = changeDoc[0] + changeDoc[1];
                            setInsMunRepr(finishDoc);
                        }
                    }
                    reader.readAsDataURL(e.target.files[0]);
                    setInsMunReprName(e.target.files[0].name);
                } else {
                    alert("O tamanho da imagem tem que ser menor que 5MB");
                }
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }

    const certNegReprHandler = (e: any) => {
        if (e.target.files[0].type !== undefined) {
            if (e.target.files[0].type === "application/pdf" || e.target.files[0].type === "image/jpeg") {
                if (e.target.files[0]?.size <= 5242880) { //5MB -> 5242880 
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            let docImage: any = reader.result;
                            let changeDoc: any = docImage.split("base64");
                            const finishDoc: any = changeDoc[0] + changeDoc[1];
                            setCertNegRepr(finishDoc);
                        }
                    }
                    reader.readAsDataURL(e.target.files[0]);
                    setCertNegReprName(e.target.files[0].name);
                } else {
                    alert("O tamanho da imagem tem que ser menor que 5MB");
                }
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }

    const cpfSocReprHandler = (e: any) => {
        if (e.target.files[0].type !== undefined) {
            if (e.target.files[0].type === "application/pdf" || e.target.files[0].type === "image/jpeg") {
                if (e.target.files[0]?.size <= 5242880) { //5MB -> 5242880 
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            let docImage: any = reader.result;
                            let changeDoc: any = docImage.split("base64");
                            const finishDoc: any = changeDoc[0] + changeDoc[1];
                            setCpfSocRepr(finishDoc);
                        }
                    }
                    reader.readAsDataURL(e.target.files[0]);
                    setCpfSocReprName(e.target.files[0].name);
                } else {
                    alert("O tamanho da imagem tem que ser menor que 5MB");
                }
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }

    const rgReprHandler = (e: any) => {
        if (e.target.files[0].type !== undefined) {
            if (e.target.files[0].type === "application/pdf" || e.target.files[0].type === "image/jpeg") {
                if (e.target.files[0]?.size <= 5242880) { //5MB -> 5242880 
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            let docImage: any = reader.result;
                            let changeDoc: any = docImage.split("base64");
                            const finishDoc: any = changeDoc[0] + changeDoc[1];
                            setrgRepr(finishDoc);
                        }
                    }
                    reader.readAsDataURL(e.target.files[0]);
                    setrgReprName(e.target.files[0].name);
                } else {
                    alert("O tamanho da imagem tem que ser menor que 5MB");
                }
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }

    //console.log(contratName)

    const onSubmit = (data: any) => {

        data.rgDoc = rg === undefined ? '' : rg;

        data.cpfDoc = cpf === undefined ? '' : cpf;

        data.compResDoc = compRes === undefined ? '' : compRes;

        data.docSerasaDoc = docSerasa === undefined ? '' : docSerasa;

        data.cartHabDoc = cartHab === undefined ? '' : cartHab;

        data.socialContractDoc = socialContract === undefined ? '' : socialContract;

        data.rgReprDoc = rgRepr === undefined ? '' : rgRepr;

        data.cnpjReprDoc = cnpjRepr === undefined ? '' : cnpjRepr;

        data.rgTurReprDoc = rgTurRepr === undefined ? '' : rgTurRepr;

        data.insMunReprDoc = insMunRepr === undefined ? '' : insMunRepr;

        data.certNegReprDoc = certNegRepr === undefined ? '' : certNegRepr;

        data.cpfSocReprDoc = cpfSocRepr === undefined ? '' : cpfSocRepr;

        data.rgName = rgName;
        data.cpfName = cpfName;
        data.compResName = compResName;
        data.docSerasaName = docSerasaName;
        data.cartHabName = cartHabName;
        data.socialContractName = socialContractName;
        data.rgReprName = rgReprName;
        data.cnpjReprName = cnpjReprName;
        data.rgTurReprName = rgTurReprName;
        data.insMunReprName = insMunReprName;
        data.certNegReprName = certNegReprName;
        data.cpfSocReprName = cpfSocReprName;

        if (isEdit === false) {
            /// ADD SUPPLIERS PAGE
            if (compType !== '1') {
                if(socialContractName === "" || rgReprName === "" || rgTurReprName === "" || cnpjReprName === "" || cpfSocReprName === "" || insMunReprName === "") {
                    if (socialContractName === "") {
                        setSocialContractVal(true);
                    } 
                    if (rgReprName === "") {
                        setRgReprVal(true);
                    }
                    if (rgTurReprName === "") {
                        setRgTurReprVal(true);
                    } 
                    
                    if (cnpjReprName === "") {
                        setCnpjReprVal(true);
                    }
                    
                    if (cpfSocReprName === "") {
                        setCpfSocReprVal(true);
                    }
                    
                    if (insMunReprName === "") {
                        setInsMunReprVal(true);
                    } 
                } else {
                    setCtrlEventKey(true);
                    action(data);
                }
            } else {
                setCtrlEventKey(true);
                action(data);
            }
        } else {
            /// EDIT SUPPLIERS PAGE
            let edit: any = { ...infoEdit, ...data };
            edit.affiliateId = parseInt(idPartner);

            edit.rgName = rgName;
            edit.cpfName = cpfName;
            edit.compResName = compResName;
            edit.docSerasaName = docSerasaName;
            edit.cartHabName = cartHabName;
            edit.socialContractName = socialContractName;
            edit.rgReprName = rgReprName;
            edit.cnpjReprName = cnpjReprName;
            edit.rgTurReprName = rgTurReprName;
            edit.insMunReprName = insMunReprName;
            edit.certNegReprName = certNegReprName;
            edit.cpfSocReprName = cpfSocReprName;

            edit.supplierContactModel = edit.affiliateContact;
            edit.costCenterSupplierModel = edit.costCenterSupplierDTO;
            edit.usersSupplierModel = edit.usersSupplierDTO;

            if (edit.masterFilho === null) {
                edit.masterFilho = '0';
            }

            editPartners(edit);

        }
    };

    function handleCancel() {
        window.location.reload();
    }

    return (
        <Card className="bg-white mt-4">
            <Card.Header id="accordionDocuments">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>Documentos</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0 mt-3" fluid>
                        <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                {compType === "1"
                                    ?
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>CPF</Form.Label><br />
                                            <Controller
                                                control={control}
                                                name="cpfDoc"
                                                render={({ field }: any) => (
                                                    <Form.Control type="file"
                                                        {...field}
                                                        label="CPF"
                                                        aria-invalid={errors?.cpfDoc ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        id="cpf"
                                                        accept=".jpg"
                                                        onChange={cpfHandler}
                                                        autoComplete="0ff"
                                                        required />
                                                )}
                                            />
                                            <Row>
                                                <Col md={5}>
                                                    <label className="btn-file" htmlFor="cpf">{t('accordionAccount.anex')}</label>
                                                    <small>{cpfName}</small>
                                                </Col>
                                                <Col md={7}>
                                                    {cpf !== null &&
                                                        <div className="w-100"
                                                            style={{ backgroundImage: `url(${cpf?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                            <ErrorMessage
                                                errors={errors}
                                                name="cpfDoc"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>RG</Form.Label><br />
                                            <Controller
                                                control={control}
                                                name="rgDoc"
                                                render={({ field }: any) => (
                                                    <Form.Control type="file"
                                                        {...field}
                                                        label="RG"
                                                        aria-invalid={errors?.rgDoc ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        id="rg"
                                                        accept=".jpg"
                                                        onChange={rgHandler}
                                                        autoComplete="off"
                                                        required />
                                                )}
                                            />
                                            <Row>
                                                <Col md={5}>
                                                    <label className="btn-file" htmlFor="rg">{t('accordionAccount.anex')}</label>
                                                    <small>{rgName}</small>
                                                </Col>
                                                <Col md={7}>
                                                    {rg !== null &&
                                                        <div className="w-100"
                                                            style={{ backgroundImage: `url(${rg?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                            <ErrorMessage
                                                errors={errors}
                                                name="rgDoc"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>Comprovante de residência</Form.Label><br />
                                            <Controller
                                                control={control}
                                                name="compResDoc"
                                                render={({ field }: any) => (
                                                    <Form.Control type="file"
                                                        {...field}
                                                        label="Comprovante de residência"
                                                        aria-invalid={errors?.compResDoc ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        id="compRes"
                                                        accept=".jpg"
                                                        onChange={compResHandler}
                                                        autoComplete="off"
                                                        required />
                                                )}
                                            />
                                            <Row>
                                                <Col md={5}>
                                                    <label className="btn-file" htmlFor="compRes">{t('accordionAccount.anex')}</label>
                                                    <small>{compResName}</small>
                                                </Col>
                                                <Col md={7}>
                                                    {compRes !== null &&
                                                        <div className="w-100"
                                                            style={{ backgroundImage: `url(${compRes?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>

                                            <ErrorMessage
                                                errors={errors}
                                                name="compResDoc"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>Documentação Serasa</Form.Label><br />
                                            <Controller
                                                control={control}
                                                name="docSerasaDoc"
                                                render={({ field }: any) => (
                                                    <Form.Control type="file"
                                                        {...field}
                                                        label="Documentação Serasa"
                                                        aria-invalid={errors?.docSerasaDoc ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        id="serasa"
                                                        accept=".jpg"
                                                        onChange={docSerasaHandler}
                                                        autoComplete="off"
                                                        required />
                                                )}
                                            />
                                            <Row>
                                                <Col md={5}>
                                                    <label className="btn-file" htmlFor="serasa">{t('accordionAccount.anex')}</label>
                                                    <small>{docSerasaName}</small>
                                                </Col>
                                                <Col md={7}>
                                                    {docSerasa !== null &&
                                                        <div className="w-100"
                                                            style={{ backgroundImage: `url(${docSerasa?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>

                                            <ErrorMessage
                                                errors={errors}
                                                name="docSerasaDoc"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" className="mt-4">
                                            <Form.Label>Carteira de Habilitação</Form.Label><br />
                                            <Controller
                                                control={control}
                                                name="cartHabDoc"
                                                render={({ field }: any) => (
                                                    <Form.Control type="file"
                                                        {...field}
                                                        label="Anexar CPF (no formato JPG)"
                                                        aria-invalid={errors?.cartHabDoc ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        id="cartHab"
                                                        accept=".jpg"
                                                        onChange={cartHabHandler}
                                                        autoComplete="off"
                                                        required />
                                                )}
                                            />
                                            <Row>
                                                <Col md={5}>
                                                    <label className="btn-file" htmlFor="cartHab">{t('accordionAccount.anex')}</label>
                                                    <small>{cartHabName}</small>
                                                </Col>
                                                <Col md={7}>
                                                    {cartHab !== null &&
                                                        <div className="w-100"
                                                            style={{ backgroundImage: `url(${cartHab?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>

                                            <ErrorMessage
                                                errors={errors}
                                                name="cartHabDoc"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    : compType === "2" || compType === "3"
                                        ?
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>RG do representante da empresa*</Form.Label><br />
                                                <Controller
                                                    control={control}
                                                    name="rgReprDoc"
                                                    render={({ field }: any) => (
                                                        <Form.Control type="file"
                                                            {...field}
                                                            label="Anexar CPF (no formato JPG)"
                                                            aria-invalid={errors?.rgReprDoc ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="rgRepr"
                                                            //accept=".jpg"
                                                            onChange={rgReprHandler}
                                                            autoComplete="off"
                                                            required />
                                                    )}
                                                />
                                                <Row>
                                                    <Col md={rgReprName === "" ? 12 : 5}>
                                                        <label className="btn-file" htmlFor="rgRepr">{t('accordionAccount.anex')}</label>
                                                        {
                                                            rgReprVal === true && rgReprName === ""
                                                                ?
                                                                <small style={{ color: "red" }}>Por favor, anexe um documento</small>
                                                                :
                                                                <small>{rgReprName}</small>
                                                        }
                                                    </Col>
                                                    <Col md={rgReprName === "" ? 0 : 7}>
                                                        {rgRepr !== null &&
                                                            <div className="w-100"
                                                                style={{ backgroundImage: `url(${rgRepr?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>

                                                <ErrorMessage
                                                    errors={errors}
                                                    name="rgReprDoc"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>Contrato Social*</Form.Label><br />
                                                <Controller
                                                    control={control}
                                                    name="socialContractDoc"
                                                    render={({ field }: any) => (
                                                        <Form.Control type="file"
                                                            {...field}
                                                            label="Anexar RG (no formato JPG)"
                                                            aria-invalid={errors?.socialContractDoc ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="social"
                                                            //accept=".pdf"
                                                            onChange={socialContractHandler}
                                                            autoComplete="off"
                                                            required />
                                                    )}
                                                />
                                                <Row>
                                                    <Col md={socialContractName === "" ? 12 : 5}>
                                                        <label className="btn-file" htmlFor="social">{t('accordionAccount.anex')}</label>
                                                        {
                                                            socialContractVal === true && socialContractName === ""
                                                                ?
                                                                <small style={{ color: "red" }}>Por favor, anexe um documento</small>
                                                                :
                                                                <small>{socialContractName}</small>
                                                        }
                                                    </Col>
                                                    <Col md={socialContractName === "" ? 0 : 7}>
                                                        {socialContract !== null &&
                                                            <div className="w-100"
                                                                style={{ backgroundImage: `url(${socialContract?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="socialContractDoc"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label className="d-flex align-items-center gap-2">
                                                    Telefone e CPF dos Sócios*
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        overlay={
                                                            <Tooltip id="custom-tooltip">Para entrar em contato com você, se necessário e identificar o responsável pelo cadastro, e em caso de Pessoa Jurídica, identificar o responsável final da empresa (o representante legal).</Tooltip>
                                                        }>
                                                        <span>
                                                            <FontAwesomeIcon
                                                                icon={["fal", "info-circle"]}
                                                                size="1x"
                                                                style={{ cursor: "pointer" }} />
                                                        </span>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="cpfSocReprDoc"
                                                    render={({ field }: any) => (
                                                        <Form.Control type="file"
                                                            {...field}
                                                            label="Anexar RG (no formato JPG)"
                                                            aria-invalid={errors?.cpfSocReprDoc ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="cpfSocRepr"
                                                            //accept=".jpg"
                                                            onChange={cpfSocReprHandler}
                                                            autoComplete="off"
                                                            required />
                                                    )}
                                                />
                                                <Row>
                                                    <Col md={cpfSocReprName === "" ? 12 : 5}>
                                                        <label className="btn-file" htmlFor="cpfSocRepr">{t('accordionAccount.anex')}</label>
                                                        {
                                                            cpfSocReprVal === true && cpfSocReprName === ""
                                                                ?
                                                                <small style={{ color: "red" }}>Por favor, anexe um documento</small>
                                                                :
                                                                <small>{cpfSocReprName}</small>
                                                        }
                                                    </Col>
                                                    <Col md={cpfSocReprName === "" ? 0 : 7}>
                                                        {cpfSocRepr !== null &&
                                                            <div className="w-100"
                                                                style={{ backgroundImage: `url(${cpfSocRepr?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="CpfSocReprDoc"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label className="d-flex align-items-center gap-2">
                                                    Registro do Ministério do Turismo*
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        overlay={
                                                            <Tooltip id="custom-tooltip">Para garantir segurança e confiabilidade aos nossos usuários.</Tooltip>
                                                        }>
                                                        <span>
                                                            <FontAwesomeIcon
                                                                icon={["fal", "info-circle"]}
                                                                size="1x"
                                                                style={{ cursor: "pointer" }} />
                                                        </span>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="rgTurReprDoc"
                                                    render={({ field }: any) => (
                                                        <Form.Control type="file"
                                                            {...field}
                                                            label="Anexar RG (no formato JPG)"
                                                            aria-invalid={errors?.rgTurReprDoc ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="rgTurRepr"
                                                            //accept=".jpg"
                                                            onChange={rgTurReprHandler}
                                                            autoComplete="off"
                                                            required />
                                                    )}
                                                />
                                                <Row>
                                                    <Col md={rgTurReprName === "" ? 12 : 5}>
                                                        <label className="btn-file" htmlFor="rgTurRepr">{t('accordionAccount.anex')}</label>
                                                        {
                                                            rgTurReprVal === true && rgTurReprName === ""
                                                                ?
                                                                <small style={{ color: "red" }}>Por favor, anexe um documento</small>
                                                                :
                                                                <small>{rgTurReprName}</small>
                                                        }
                                                    </Col>
                                                    <Col md={rgTurReprName === "" ? 0 : 7}>
                                                        {rgTurRepr !== null &&
                                                            <div className="w-100"
                                                                style={{ backgroundImage: `url(${rgTurRepr?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="RgTurReprDoc"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>CNPJ com atividade principal Agência de Turismo*</Form.Label><br />
                                                <Controller
                                                    control={control}
                                                    name="cnpjReprDoc"
                                                    render={({ field }: any) => (
                                                        <Form.Control type="file"
                                                            {...field}
                                                            label="Anexar RG (no formato JPG)"
                                                            aria-invalid={errors?.cnpjReprDoc ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="cnpjRepr"
                                                            //accept=".jpg"
                                                            onChange={cnpjReprHandler}
                                                            autoComplete="off"
                                                            required />
                                                    )}
                                                />
                                                <Row>
                                                    <Col md={cnpjReprName === "" ? 12 : 5}>
                                                        <label className="btn-file" htmlFor="cnpjRepr">{t('accordionAccount.anex')}</label>
                                                        {
                                                            cnpjReprVal === true && cnpjReprName === ""
                                                                ?
                                                                <small style={{ color: "red" }}>Por favor, anexe um documento</small>
                                                                :
                                                                <small>{cnpjReprName}</small>
                                                        }
                                                    </Col>
                                                    <Col md={cnpjReprName === "" ? 0 : 7}>
                                                        {cnpjRepr !== null &&
                                                            <div className="w-100"
                                                                style={{ backgroundImage: `url(${cnpjRepr?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="CnpjReprDoc"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>Inscrição Municipal*</Form.Label><br />
                                                <Controller
                                                    control={control}
                                                    name="insMunReprDoc"
                                                    render={({ field }: any) => (
                                                        <Form.Control type="file"
                                                            {...field}
                                                            label="Anexar RG (no formato JPG)"
                                                            aria-invalid={errors?.insMunReprDoc ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="insMunRepr"
                                                            //accept=".jpg"
                                                            onChange={insMunReprHandler}
                                                            autoComplete="off"
                                                            required />
                                                    )}
                                                />
                                                <Row>
                                                    <Col md={insMunReprName === "" ? 12 : 5}>
                                                        <label className="btn-file" htmlFor="insMunRepr">{t('accordionAccount.anex')}</label>
                                                        {
                                                            insMunReprVal === true && insMunReprName === ""
                                                                ?
                                                                <small style={{ color: "red" }}>Por favor, anexe um documento</small>
                                                                :
                                                                <small>{insMunReprName}</small>
                                                        }
                                                    </Col>
                                                    <Col md={insMunReprName === "" ? 0 : 7}>
                                                        {insMunRepr !== null &&
                                                            <div className="w-100"
                                                                style={{ backgroundImage: `url(${insMunRepr?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="InsMunReprDoc"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>Certidão Negativa (atual) de protestos e títulos</Form.Label><br />
                                                <Controller
                                                    control={control}
                                                    name="certNegReprDoc"
                                                    render={({ field }: any) => (
                                                        <Form.Control type="file"
                                                            {...field}
                                                            label="Anexar RG (no formato JPG)"
                                                            aria-invalid={errors?.certNegReprDoc ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="certNegRepr"
                                                            //accept=".jpg"
                                                            onChange={certNegReprHandler}
                                                            autoComplete="off"
                                                            required />
                                                    )}
                                                />
                                                <Row>
                                                    <Col md={5}>
                                                        <label className="btn-file" htmlFor="certNegRepr">{t('accordionAccount.anex')}</label>
                                                        <small>{certNegReprName}</small>
                                                    </Col>
                                                    <Col md={7}>
                                                        {certNegRepr !== null &&
                                                            <div className="w-100"
                                                                style={{ backgroundImage: `url(${certNegRepr?.split(",").join("base64,")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px' }}>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                {/*  <ErrorMessage
                                                    errors={errors}
                                                    name="CertNegReprDoc"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                /> */}
                                            </Form.Group>
                                            <p className="mt-3 mb-0"><b>Os anexos em formato JPEG ou PDF</b></p>
                                        </Row>
                                        : ""
                                }

                            </Row>
                            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                {
                                    isEdit === true
                                        ?
                                        <Button
                                            className="form-button cancel"
                                            onClick={handleCancel}
                                        >
                                            Cancelar
                                        </Button>
                                        :
                                        <Button
                                            type="button"
                                            className="form-button back-btn"
                                            onClick={() => {
                                                setStepCtrl(0)
                                            }}
                                        >
                                            voltar
                                        </Button>
                                }
                                <Button
                                    type="submit"
                                    className="form-button"
                                >
                                    {t('accordionDocuments.buttonContinue')}
                                </Button>
                            </Row>
                        </Form>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionDocuments;