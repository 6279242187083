import React, { Key, useState, useEffect } from 'react';
import { Row, Col, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//import Select from "react-select";
//import GetPickups from '../../../../components/C2Points/GetPickups';
import deleteIcon from '../../../assets/icons/icon-garbage.png';

import { useAppDispatch } from "../../../store/hooks";
import { removeItemCart } from "../../../store/cart";
//import GetDDI from '../../C2Points/GetDDI';

export interface propCart {
    info: any,
    control: any,
    errors: any,
    setValue: any,
    data: any;
    mainContactObj?: any;
};

const CartCheckoutTickets: React.FC<propCart> = ({
    info, control, errors, setValue, data, mainContactObj
}: propCart) => {
    const [numAdultos, setNumAdultos] = useState<any>([]);
    const [allowOthers, setAllowOthers] = useState<boolean>(false);
    //const [pickupSelect, setPickupSelect] = useState<any>(null);
    /* function getParticipants() {
      return;
    } */
    const dispatch = useAppDispatch();

    useEffect(() => {
        var aux: any = [];
        for (var i: any = 0; i < info.adults; i++) {
            aux.push(i)
        }
        setNumAdultos(aux)
    }, [info])

    useEffect(() => {
        setValue(`dados[${data}].passengers.0.firstName`, mainContactObj?.name);
        setValue(`dados[${data}].passengers.0.lastName`, mainContactObj?.lastName);
        setValue(`dados[${data}].passengers.0.email`, mainContactObj?.email);
        setValue(`dados[${data}].passengers.0.phone`, mainContactObj?.phone);
    }, [data, setValue,/*  */ mainContactObj])

    const handleClickRemove = (productE: any) => {
        dispatch(removeItemCart(productE))
    };

    return (


        <>
            <Card className="cart-card-unit card-unit bg-white mt-4">
                <Card.Body>
                    <div className="card-title">
                        <div className="logo-button">
                            <FontAwesomeIcon
                                className='icon-primary'
                                icon={["fad", "ticket"]}
                                size="1x"
                                style={{ marginBottom: "5px", width: "25px", fontSize: "1.5rem" }}
                            />
                            <button onClick={() => { handleClickRemove(info) }}><img src={deleteIcon} alt="delete" /></button>
                        </div>
                        <div className="mb-2" style={{ borderBottom: "1px solid rgb(112 112 112 / 19%)", paddingBottom: ".875rem" }}>
                            <h4 className='mb-0'>{info.productName}</h4>
                            <p>{info.modalityName}</p>
                        </div>
                        <p>Fornecedor: <span>{info.supplierFantasyName}</span></p>
                    </div>

                    {/* <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false"> */}
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="formCheckoutDate">
                            <Form.Label>Data:</Form.Label>
                            <p>{info.date.split('-').reverse().join('/')}</p>
                            <ErrorMessage
                                errors={errors}
                                name="date"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutHour">
                            {/* <Form.Label>Hora:</Form.Label>
                            <p>{info.time}</p>
                            <ErrorMessage
                                errors={errors}
                                name="hour"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            /> */}
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutNumberOfPeople">
                            <Form.Label>Pessoas:</Form.Label>
                            {info.sellingType === 2 ?
                                <p> <small>Adultos: {info.adults}</small></p>
                                :
                                <p> <small>Total: {info.globalPeople}</small></p>
                            }
                            <ErrorMessage
                                errors={errors}
                                name="numberOfPeople"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>

                    <hr />

                    <div className="participants">
                        <h3>Participantes das atrações</h3>

                        {numAdultos.length > 0
                            ? numAdultos.slice(0, allowOthers === false ? 1 : numAdultos.length).map((person: any, index: Key) => {
                                return (
                                    <>
                                        <div className='person-form' key={index}>
                                            <h4>Participante (Adulto)</h4>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonName">
                                                    <Form.Label>Nome:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.firstName`}
                                                        rules={{ required: { value: index === 0, message: 'Por favor, informe esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.firstName`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonLastname">
                                                    <Form.Label>Sobrenome:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.lastName`}
                                                        rules={{ required: { value: index === 0, message: 'Por favor, informe esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.lastName`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonEmail">
                                                    <Form.Label>Email:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.email`}
                                                        rules={{ required: { value: index === 0, message: 'Por favor, informe esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.email`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonPhone">
                                                    <Form.Label>Telefone:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.phone`}
                                                        rules={{ required: { value: index === 0, message: 'Por favor, informe esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.phone`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </Row>

                                        </div>
                                    </>
                                )
                            })
                            : ''}
                        <div>
                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { setAllowOthers(!allowOthers) }}>
                                {allowOthers === false ? "Adicionar informações dos outros adultos" : "Ocultar informações dos outros adultos"}
                            </span>
                        </div>
                    </div>

                    <hr />

                    <Row className="mb-3">
                        <Form.Group as={Col} md="8" className="form-textarea" controlId="formCheckoutNotes">
                            <Form.Label>Notas Internas:</Form.Label>
                            <Controller
                                control={control}
                                name={`dados[${data}].internalNotes`}
                                // rules={{ required: { value: true, message: 'Por favor, informe esse campo.' } }}
                                render={({ field }: any) => (
                                    <textarea
                                        {...field}
                                        className='form-control'
                                        aria-invalid={errors?.pickup ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`dados[${data}].internalNotes`}
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
}

export default CartCheckoutTickets;