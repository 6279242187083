import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import CartHeader from "../CartHeader/CartHeader";

import './BannerHome.scss';

export interface modalityInfo {
    corporate: boolean
}

const BannerHome = ({
    corporate = false
}: any) => {
    const [width, setWidth] = useState<any>(window.innerWidth);

    //const cartSIG = useAppSelector((state) => state.cart) 

    useEffect(() => {
        window.addEventListener('resize', function () {
            var newWidth = window.innerWidth;
            setWidth(newWidth)
        });
    }, [])

    if (corporate === false) {
        return (
            <Container className="p-0 mx-0" fluid>
                <div className="banner-home" style={{ backgroundImage: `url(${width >= 768 ? process.env.REACT_APP_SERVER_BANNERHOME : process.env.REACT_APP_SERVER_BANNERHOME_MOBILE})` }}>
                    <div className="icon-top">
                        <CartHeader></CartHeader>
                    </div>
                    <div className="container-content h-100 content-banner">
                        <Row className="bg-home-size align-items-center m-0">
                            <Col md={6}>
                                <h2>
                                    Encontre atividades <br />
                                    incríveis quando e <br />
                                    <span style={{ color: "#ffc107" }}>onde quiser</span>.
                                </h2>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        )
    } else {
        return (
            <Container className="p-0 mx-0" fluid>
                <div className="banner-home" style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_BANNERHOME})` }}>
                    <div className="icon-top">
                        <CartHeader></CartHeader>
                    </div>
                    <div className="container-content h-100 content-banner">
                        <Row className="bg-home-size align-items-center m-0">
                            <Col md={6}>
                                <h2>
                                    Reagendamentos
                                </h2>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        )
    }
}

export default BannerHome;