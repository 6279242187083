import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import './AccountsFilter.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface propFilter {
    buscar: any,
    clearActualValues: any;
}

const AccountsFilter: React.FC<propFilter> = ({
    buscar, clearActualValues
}) => {
    const { t } = useTranslation();

    const [filterName, setFilterName] = useState<any>('');
    const [filterType, setFilterType] = useState<any>(0);
    const [filterStatus, setFilterStatus] = useState<any>(0);

    const handleFilterClick = (event: any) => {
        buscar(filterName, filterType, filterStatus);
    };

    const handleClearClick = () => {
        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if(selectValue[i].id === "rowsPerPage"){
                selectValue[i].value = "10";
            }else{
                selectValue[i].value = "";
            }
        }

        setFilterName("");
        setFilterStatus(0);
        setFilterType(0);
        buscar("", 0, 0);
        clearActualValues();
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterName(newValue);
    }

    const handleChangeTypee = (e: any) => {
        var newValue = e.target.value;
        setFilterType(newValue);
    }

    const handleChangeStatus = (e: any) => {
        var newValue = e.target.value;
        setFilterStatus(newValue);
    }

    return (
        <div className="accounts-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">{t('accounts.accountsfilter.title')}</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>

                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>{t('accounts.accountsfilter.name')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChangeName}
                                                    autoComplete="off"
                                                />
                                                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('accounts.accountsfilter.type')}</Form.Label>
                                                <InputGroup hasValidation>
                                                    <div className="endpoint w-100">
                                                        <Form.Select
                                                            //value={filterType}
                                                            onChange={(e:any) => handleChangeTypee(e)}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Conta Corrente</option>
                                                            <option value="2">Caixa</option>
                                                            <option value="3">Conta Aplicação</option>
                                                            <option value="4">Outros</option>
                                                        </Form.Select>
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('accounts.accountsfilter.status')}</Form.Label>
                                                <InputGroup hasValidation>
                                                    <div className="endpoint w-100">
                                                        <Form.Select
                                                            //value={filterStatus}
                                                            onChange={(e:any) => handleChangeStatus(e)}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Ativo</option>
                                                            <option value="2">Inativo</option>
                                                        </Form.Select>
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>


                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default AccountsFilter;