import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './SessionBilling.scss'
import Button from "react-bootstrap/Button";

//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

import TableSessionBillingBillsReceive from '../components/TableSessionBillingBillsReceive/TableSessionBillingBillsReceive';
import TableSessionBillingBillsPay from '../components/TableSessionBillingBillsPay/TableSessionBillingBillsPay';
import ModalQuestion from '../../../components/Modal/ModalQuestion/ModalQuestion';
import DesktopDefault from '../../../templates/DesktopDefault';


function SessionBilling() {
    //const { t } = useTranslation();
    const [billsReceive, setBillsReceive] = useState<any>(null);
    const [billsPay, setBillsPay] = useState<any>(null);
    const [error, setError] = useState<any>();

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    /* Filter */
    const [sessionId, setSessionId] = useState<any>(null);
    const [sessionType, setSessionType] = useState<any>(1);
    /* END - Filter */

    /* session data */
    const [sessionReloc, setSessionReloc] = useState<any>('');
    const [sessionDesc, setSessionDesc] = useState<any>('');
    const [sessionDate, setSessionDate] = useState<any>('');
    const [sessionOperationType, setSessionOperationType] = useState<any>(1);
    /* END - session data */

    /* totais */
    const [totalRecebimentos, setTotalRecebimentos] = useState<any>(0);
    const [totalPagamentos, setTotalPagamentos] = useState<any>(0);
    const [totalMargem, setTotalMargem] = useState<any>(0);
    const [taxaMargem, setTaxaMargem] = useState<any>(0);
    /* END - totais */

    const [issueInvoiceModalShow, setIssueInvoiceModalShow] = React.useState(false);
    const [issueInvoiceModal2Show, setIssueInvoiceModal2Show] = React.useState(false);

    function calcula(pBillsReceive: any, pBillsPay: any) {
        var recebe = 0;
        var paga = 0;

        if(pBillsReceive) {
            pBillsReceive.forEach((e: any) => {
                recebe += e.valor;
            });
        }
        
        if(pBillsPay) {
            pBillsPay.forEach((e: any) => {
                paga += e.valor;
            });
        }
        
        const margem = recebe - paga;
        const taxaMargem = recebe != 0 ? margem / recebe : 0;

        setTotalRecebimentos(recebe);
        setTotalPagamentos(paga);
        setTotalMargem(margem);
        setTaxaMargem((taxaMargem) * 100);
    }

    useEffect(() => {
        const session = window.location.href.split('session=')[1].replace('#', '');

        const sessionId = session.substring(1);

        const sessionTypeTemp = session.substring(0,1);

        const sessionTypeTemp2 = (sessionTypeTemp == 'S')? 1 : 2;

        setSessionId(sessionId);
        setSessionType(sessionTypeTemp2);
        
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        async function listSuppliers() {
            var billsReceiveT = billsReceive;
            var billsPayT = billsPay;
            try {
                const { data } = await api.post('SessionClosure/GetSessionBillsToReceive',
                    {
                        "page": 1,
                        "rowsPerPage": 0,
                        "sessionId": sessionId,
                        "sessionType": sessionTypeTemp2
                    }, config
                );

                if (data.status !== 400) {
                    billsReceiveT = data.data.rows;
                    setBillsReceive(billsReceiveT);
                    //setSessionId(data.data.sessionId);
                    setSessionReloc(data.data.sessionReloc);
                    setSessionDesc(data.data.descricao);
                    setSessionDate(data.data.data);
                    setSessionOperationType(data.data.operationType);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }

            try {
                const { data } = await api.post('SessionClosure/GetSessionBillsToPay',
                    {
                        "page": 1,
                        "rowsPerPage": 0,
                        "sessionId": sessionId,
                        "sessionType": sessionTypeTemp2
                    }, config
                );
    
                if (data.status !== 400) {
                    billsPayT = data.data.rows;
                    setBillsPay(billsPayT);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
            calcula(billsReceiveT, billsPayT);
        }
        listSuppliers();
    }, [pageCount, rowsPerPage]);

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
//console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
          let link = document.createElement('a');
          link.href = blob;//window.URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.target = "_blank";
          link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
          link.remove();
          window.URL.revokeObjectURL(link.href);
        //}
    };

    async function refreshData() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        var billsReceiveT = billsReceive;
        var billsPayT = billsPay;

        try {
            const { data } = await api.post('SessionClosure/GetSessionBillsToReceive',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "sessionId": sessionId,
                    "sessionType": sessionType
                }, config
            );

            if (data.status !== 400) {
                billsReceiveT = data.data.rows;
                setBillsReceive(billsReceiveT);
                setSessionDesc(data.data.descricao);
                setSessionDate(data.data.data);
                setSessionOperationType(data.data.operationType);
                setTotalRows(data.data.rowsCount);
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }

        try {
            const { data } = await api.post('SessionClosure/GetSessionBillsToPay',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "sessionId": sessionId,
                    "sessionType": sessionType
                }, config
            );

            if (data.status !== 400) {
                billsPayT = data.data.rows;
                setBillsPay(billsPayT);
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }

        calcula(billsReceiveT, billsPayT);
    }

    async function exportReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/ExportSessionBillsToReceive',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "sessionId": sessionId,
                    "sessionType": sessionType
                }, config
            );
            if (res.status !== 400) {
                //download file
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/PrintSessionBillsToReceive',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "sessionId": sessionId,
                    "sessionType": sessionType
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    function issueInvoiceInBath() {
        var tour_dat = new Date(sessionDate.substring(0,4), parseInt(sessionDate.substring(5,7))-1, sessionDate.substring(8,10));//new Date(data_tour);
        var hoje = new Date();
        hoje.setHours(0,0,0,0);
        
        if(tour_dat < hoje && tour_dat.getMonth() < hoje.getMonth()) {
            //console.log('menor');
            setIssueInvoiceModalShow(true);
        } else {
            //console.log('n menor');
            issueInvoiceInBath1();
        }
    }

    function issueInvoiceInBath1() {
        setIssueInvoiceModalShow(false);
        setIssueInvoiceModal2Show(true);
    }

    async function issueInvoiceInBath2(notaCliente: any) {
        setIssueInvoiceModal2Show(false);
     
        let userData = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');
        const user = userData.user;

        const uid = user.uId;
        const usuario = `${user.nome} ${user.lastName}`;

        const config = {
            headers: { 'Authorization': `Bearer ${userData.token.token}` },
        };

        try {
            const res = await api.post('SessionClosure/IssueInvoiceInBathAsync',
                {
                    "sessionId": sessionId,
                    "sessionType": sessionType,
                    "notaCliente": notaCliente,
                    "uid": uid,
                    "usuario": usuario
                }, config
            );
            if (res.status !== 400) {
                const dados = res.data.data;

                alert(dados.texto);

                if(dados.log != 1) {
                    refreshData();
                }
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    }

    /*converte a data UTC para a data local do usuário*/
    function convertUTCDateToLocalDate(date: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        //newDate.setHours(hours - offset);

        return newDate.toLocaleString().split(' ')[0];
    }

    function convertDate(cell: any) {
        var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
        /* console.log(date) */
        return (
        <>
            {date}
        </>
        );
    }

    function convertValue(cell: any) {
        //console.log(Math.sign(cell))
        return (
        <>
            <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
        </>
        )
    }

    if (billsReceive !== null && billsPay !== null) {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard">
                        <Breadcrumb title={'Financeiro / Fechamento / Faturamento'} />
                        <div className="suppliers mt-4 justify-content-between align-items-center d-flex">
                            <h2 className="title" style={{ color: "#707070" }}>{`Session - ${sessionReloc} - ${sessionDesc}`}</h2>
                            <h3 style={{color: "#c0c0c0" }}>Data Tour - {convertDate(sessionDate)}</h3>
                        </div>
                        <div style={{ padding: "20px 10px 0px" }}>
                            <div className="d-flex">
                                <div className="px-2">
                                    <button className="btn btn-bills" onClick={(e:any) => issueInvoiceInBath()}>
                                        <FontAwesomeIcon
                                        icon={["fal", "file-invoice"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                        />
                                        Emitir Nota Fiscal em Lote
                                    </button>
                                </div>
                                <div className="px-2">
                                    <button className="btn btn-bills" onClick={(e:any) => exportReport()}>
                                        <FontAwesomeIcon
                                        icon={["fal", "share"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                        />
                                        Exportar
                                    </button>
                                </div>
                                <div className="px-2">
                                    <button className="btn btn-bills" onClick={(e:any) => exportReport()}>
                                        <FontAwesomeIcon
                                        icon={["fal", "share"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                        />
                                        Exportar Conta Azul
                                    </button>
                                </div>
                                <div className="px-2">
                                    <button className="btn btn-bills" onClick={(e:any) => printReport()}>
                                        <FontAwesomeIcon
                                        file-invoice
                                        icon={["fal", "print"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                        />
                                        Imprimir
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: "20px 0px 5px" }}>
                            <div className="d-flex">
                                <div className="card px-2 mr-2">
                                    <div className="card-body p-2">
                                        <div className="text-xs font-weight-bold text-uppercase mb-1">Total Recebimentos</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {convertValue(totalRecebimentos)}</div>
                                    </div>
                                </div>
                                <div className="card px-2 mr-2">
                                    <div className="card-body p-2">
                                        <div className="text-xs font-weight-bold text-uppercase mb-1">Total Pagamentos</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {convertValue(totalPagamentos)}</div>
                                    </div>
                                </div>
                                <div className="card px-2 mr-2">
                                    <div className="card-body p-2">
                                        <div className="text-xs font-weight-bold text-uppercase mb-1">Margem</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {convertValue(totalMargem)}</div>
                                    </div>
                                </div>
                                <div className="card px-2">
                                    <div className="card-body p-2">
                                        <div className="text-xs font-weight-bold text-uppercase mb-1">Margem / Receita (%)</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{convertValue(taxaMargem)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TableSessionBillingBillsReceive
                            bills={billsReceive}
                            dataOperacao={sessionDate}
                            refrestData={refreshData}
                        />
                        <TableSessionBillingBillsPay
                            bills={billsPay}
                        />

                        <ModalQuestion 
                            modalShow={issueInvoiceModalShow}
                            setModalShow={setIssueInvoiceModalShow}
                            titulo="Emitir Nota Fiscal"
                            mensagem="Data com mes anterior ao da data corrente, Continua?"
                            handleActionConfirmModal={(e: any) => issueInvoiceInBath1()}
                            //handleActionCancelModal={handleActionCancelDeleteClick}
                        />

                        <ModalQuestion 
                            modalShow={issueInvoiceModal2Show}
                            setModalShow={setIssueInvoiceModal2Show}
                            titulo="Emitir Nota Fiscal"
                            mensagem="Nota emitida contra (P)assageiro ou (C)liente ?"
                            buttons={
                            <>
                                <Button className="form-button mr-3" onClick={(e:any) => issueInvoiceInBath2(2)}>
                                Passageiro
                                </Button>
                                <Button className="form-button mr-3" onClick={(e:any) => issueInvoiceInBath2(1)}>
                                Cliente
                                </Button>
                            </>
                            }
                            //handleActionConfirmModal={(e: any) => issueInvoice2(invoiceToIssue,2)}
                            //handleActionCancelModal={handleActionCancelDeleteClick}//1=cliente;2=passageiro
                        />
                    </div>
                </DesktopDefault>
            </>
        );
    } else {
        return (
            <>
            </>
        )
    }
}

export default SessionBilling;