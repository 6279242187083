import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsId: number;
  actionPickup: any;
  alert: any;
}

const GetPickups: React.FC<propPlus> = ({
  propsId, actionPickup, alert
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [check, setCheck] = useState<any>(true);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { Authorization: `Bearer ${token}`, "ngrok-skip-browser-warning": "69420"  },
    };
    async function listPickups() {
      try {
        const { data } = await api.get(`/Pickup/GetListByIdStrangerToCheckOutAsync/${propsId}`, config);
        if (data.status !== 400) {
          setInfo(data.data);
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + "/";
        }
      }
    }
    listPickups();
  }, []);

  const handleChange = (options: any) => {
    actionPickup(options);
    setCheck(false)
  };

  if (info !== null) {
    /* console.log(check) */
    return (
      <>
        <Select
          aria-labelledby="aria-label"
          inputId="aria-example-input"
          className="endpoint"
          name="aria-live-color"
          placeholder="Selecione"
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuOpen}
          onChange={handleChange}
          options={Array.from(info)}
          getOptionLabel={(option: any) => `${option.name}: ${option.address}`}
        />
        {
          check === true
            ?
            alert
            :
            ""
        }
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
};

export default GetPickups;
