/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../../services/api';

import './ListReservations.scss'
import { useTranslation } from 'react-i18next';

//COMPONENTS
import ReservationsFilter from '../components/ReservationsFilter/ReservationsFilter';
import ReservationsTable from '../components/ReservationsTable/ReservationsTable';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../../templates/DesktopDefault';
//import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import ModalCancelSitef from '../components/ModalCancelSitef/ModalCancelSitef';
import { useForm } from 'react-hook-form';

let continua = 0;

function ListReservations() {
  const { t } = useTranslation();

  const [, setCancelTextResponse] = useState<any>('');

  const [reservations, setReservations] = useState<any>(null);
  const [modalContent, setModalContent] = useState<any>();
  const [confirm, setConfirm] = useState<any>("Qual é o motivo do cancelamento?");
  const [log, setLog] = useState<any>();
  const [booking, setBooking] = useState<any>("");
  const [name, setName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [id, setId] = useState<any>("");
  const [acessCode, setAcessCode] = useState<any>("");
  const [actualDate, setActualDate] = useState<any>("");
  const [actualAntifraude, setActualAntifraude] = useState<any>(0);
  const [status, setStatus] = useState<any>(0);
  const [channel, setChannel] = useState<any>("");
  const [codigoCaixa, setCodigoCaixa] = useState<string>("");
  const [uidOperador, setUidOperador] = useState<string>("");
  const [loading, setLoading] = useState<any>(false);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  /* Cancelamento SITEF */
  const [management, setManagement] = useState('inicio');
  const [infosMaquina, setInfosMaquina] = useState('');
  //const [continua] = useState(0);

  const [modalCancel, setModalCancel] = useState<boolean>(false);
  const [continueReady, setContinueReady] = useState<boolean>(false);

  const [reason, setReason] = useState<any>('0');
  const [valReason, setValReason] = useState<any>(false);

  //let installmentSelect = 1;
  let sessionId = '';

  useEffect(() => {
    setReservations(null);
    setLoading(true);
    let beginDate: any = []
    let endDate: any = []

    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/')
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/')
      endDate = actualDate[1].split('/')
    }

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    ;
    async function listarReservas() {
      try {
        const { data } = await api.post(`/NewReservations/GetNewReservationsByFilterAsync`, //Trocar a API para hml.backend.bondinho
          {
            "page": pageCount,
            "rowsPerPage": rowsPerPage,
            "loc": "",
            "paxName": name,
            "name": booking,
            "cpf": id,
            "email": email,
            "accesscode": acessCode,
            "status": status,
            "productType": 0,
            "dataType": 0,
            "beginDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : "",
            "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0]) + 1}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : "",
            "orderByField": "",
            "source": channel,
            "uidOperator": uidOperador || '',
            "codigoCaixa": codigoCaixa || '',
            "manualFraudAnalisys": 0
          }, config
        );

        if (data.status !== 400) {
          setLoading(false);
          setReservations(data.data.rows);
          setTotalRows(data.data.rowsCount);
          //console.log(data.data);
        }
      } catch (error: any) {
        setLoading(false);
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listarReservas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  async function buscar(booking: string, name: string, email: string, id: string, acessCode: string, status: any, date: any, channel: any, antifraude: any, codigoCaixa: string = '', uidOperator: string = '', actualPage: number = 1) {
    setReservations(null);
    setLoading(true);
    setBooking(booking);
    setName(name);
    setEmail(email);
    setId(id);
    setAcessCode(acessCode);
    setStatus(status);
    setChannel(channel);
    setActualDate(date);
    setActualAntifraude(antifraude);
    setCodigoCaixa(codigoCaixa);
    setUidOperador(uidOperator);
    setPageCount(actualPage);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    let beginDate = [];
    let endDate = [];

    if (date.length === 1) {
      beginDate = date[0].split('/')
    } else if (date.length === 2) {
      beginDate = date[0].split('/')
      endDate = date[1].split('/')
    }

    try {
      const res = await api.post(`/NewReservations/GetNewReservationsByFilterAsync`, //Trocar a API para hml.backend.bondinho
        {
          "page": actualPage,
          "rowsPerPage": rowsPerPage,
          "loc": '',
          "paxName": name,
          "name": booking,
          "cpf": id.replaceAll(".","").replaceAll("-", ""),
          "email": email,
          "accesscode": acessCode,
          "status": parseInt(status),
          "productType": 0,
          "dataType": 0,
          "beginDate": date.length > 0 ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : '',
          "endDate": date.length === 1 ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0]) + 1}` : date.length === 2 ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : '',
          "orderByField": "",
          "source": channel,
          "uidOperator": uidOperator,
          "codigoCaixa": codigoCaixa,
          "manualFraudAnalisys": 0
        }, config
      );
      if (res.status !== 400) {
        setLoading(false);
        setReservations(res.data.data.rows);
        setTotalRows(res.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        /* window.location.href = window.location.origin + '/'; */
      }
    }
  };

  function cancelReservation(item: any, edit: any) {
    console.log(reason)
    if(reason !== '0' && reason !== undefined) {
      if (item.source === "TOTEM" && item.idMetodoPagamento !== 96) {
        setModalCancel(true);
        setInfosMaquina("Processando...");
      }
  
      setModalContent('loading');

      const cancelamento = async () => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
          headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
          const res = await api.post(`/Reservations/TryCancelItemSIG`,
            {
              "idItem": edit === 1 ? item.idNewItems : item.idItem,
              "bookingLoc": edit === 1 ? item.reservaC2 : item.bookingLoc,
              "cancelamentoTotal": item.source === "TOTEM" || item.idMetodoPagamento === 96 ? true : false,
              "motivo": reason
            }, config);
  
          if (res.status !== 400) {
            if (res.data.data.log === 0 || res.data.data.log === '0') {
              if (item.source !== "TOTEM" || item.idMetodoPagamento === 96) {
                setConfirm(res.data.data.texto);
                setLog(res.data.data.log);
                setModalContent('sucesso');
              } else {
                setInfosMaquina(res.data.data.texto);
                setLog(res.data.data.log);
                setModalContent('sucesso');
                setManagement("aprovado");
  
                setTimeout(() => {
                  createSession(item, res.data.data.data.guid, res.data.data.data.valorReembolso);
                  setManagement("");
                }, 5000);
  
              }
              //setTimeout(() => window.location.reload(), 2000);
            } else {
              if (item.source !== "TOTEM" || item.idMetodoPagamento === 96) {
                setConfirm(res.data.data.texto);
                setLog(res.data.data.log);
                setModalContent('error');
              } else {
                setInfosMaquina(res.data.data.texto);
                setLog(res.data.data.log);
                setModalContent('error');
                setManagement("error");
              }
            }
            setCancelTextResponse(res.data.data.texto);
          }
        } catch (error: any) {
  
          if (item.source !== "TOTEM" || item.idMetodoPagamento === 96) {
            setModalContent('error');
            setLog(1);
            setConfirm("Erro ao processar operação <br /> Entre em contato com nosso suporte");
          } else {
            setInfosMaquina("Erro ao processar operação <br /> Entre em contato com nosso suporte");
            setLog(1);
            setModalContent('error');
            setManagement("error");
          }
        }
      };
  
      cancelamento();
    } else {
      setValReason(true)
      setTimeout(() => {
        setValReason(false);
      }, 2000);
    }
  }

  function finalizeReservation(hash: any, valueTotal: any) {
    setManagement("");
    setInfosMaquina("Processando...");

    setModalContent('loading');

    const finalize = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}` },
      };
      try {
        const res = await api.post(`/Checkout/ApproveSitefCancelAllItem`,
          {
            "guid": hash,
            "parameters": comp_cancel
          }, config);

        if (res.status !== 400) {
          if (res.data.data.log === 0 || res.data.data.log === '0') {
            setInfosMaquina(res.data.data.texto);
            setManagement("aprovado");
            /* setConfirm(res.data.data.texto);
            setLog(res.data.data.log);
            setModalContent('sucesso'); */
            //setTimeout(() => window.location.reload(), 2000);
          } else {
            setInfosMaquina(res.data.data.texto);
            setManagement("error");
            /* setConfirm(res.data.data.texto);
            setLog(res.data.data.log);
            setModalContent('error'); */
          }
          setCancelTextResponse(res.data.data.texto);
        }
      } catch (error: any) {
      }
    };

    finalize();
  }

  function handleModal() {
    let modal = document.querySelector('.bg-modal-custom');
    modal?.classList.toggle('modal-close');
  }

  //
  //
  //
  /* Cancelamento SITEF */
  const sitefIp = "127.0.0.1";
  const empresa = "00000000";
  const terminal = "REST0001";
  const cnpjEstabelecimento = "31406434895111";
  const cnpjLoja = "12523654185985";

  let tempObject = {
    nsu_sitef: '',
    nsu_host: '',
    codigo_autorizacao: '',
    terminal: terminal,
    estabelecimento: '',
    rede_autorizadora: '',
    bandeira: ''
  }

  let comp_cancel = {
    comprovante_estabelecimento_cancel: '',
    comprovante_cliente_cancel: ''
  }

  const createSession = useCallback(async (newObject, hash, valueTotal) => {
    try {
      const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/session`, `sitefIp=${sitefIp}&storeId=${empresa}&terminalId=${terminal}&sessionParameters=[ParmsClient=1=${cnpjEstabelecimento};2=${cnpjLoja}]`);
      if (data?.statusCode !== 400) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        sessionId = data.sessionId;
        verifyPinpad(data.sessionId, newObject, hash, valueTotal);
      }
    } catch (e) {
      setInfosMaquina("Erro ao realizar cancelamento<br /> Por favor, entre em contato com nosso suporte");
      setLog(1);
      setModalContent('error');
      setManagement("error");
    }
  }, [sessionId]);

  const verifyPinpad = useCallback(async (dataSessionId, newObject, hash, valueTotal) => {
    try {
      const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/pinpad/isPresent`, `sessionId=${dataSessionId}`);
      if (data?.statusCode !== 400) {
        getCancel(newObject.idMetodoPagamento, newObject, hash, valueTotal);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } catch (e) {
      setInfosMaquina("Erro ao realizar cancelamento<br /> Por favor, entre em contato com nosso suporte");
      setLog(1);
      setModalContent('error');
      setManagement("error");
    }
  }, []);

  const handleNext = (newObject: any) => {
    //console.log(newObject)
    createSession(newObject, "", 0);
  };

  const getCancel = async (methodPay: number | undefined, newObject: any, hash: any, valueTotal: any) => {
    if (methodPay === 1) {
      startTransaction(1, 210, "1234", newObject, hash, valueTotal);
    } else if (methodPay === 47) {
      startTransaction(1, 211, "1234", newObject, hash, valueTotal);
    }
  }

  const startTransaction = useCallback(async (tipo, funcao, booklock, newObject, hash, valueTotal) => {
    let param = '';
    if (tipo === 1) {
      param = `sitefIp=${sitefIp}&storeId=${empresa}&terminalId=${terminal}&functionId=${funcao}&trnAmount=${newObject.amount * 100}&taxInvoiceNumber=${newObject.reservaC2}&taxInvoiceDate=20170304&taxInvoiceTime=170000&cashierOperator=CAIXA&trnAdditionalParameters=&trnInitParameters=[ParmsClient=1=${cnpjEstabelecimento};2=${cnpjLoja}]`;

    }
    else if (tipo === 2) {
      param = `sitefIp=${sitefIp}&storeId=${empresa}&terminalId=${terminal}&functionId=${funcao}&trnAmount=${newObject.amount * 100}&taxInvoiceNumber=${newObject.reservaC2}&taxInvoiceDate=20170304&taxInvoiceTime=170000&cashierOperator=CAIXA&trnAdditionalParameters=&trnInitParameters=[ParmsClient=1=${cnpjEstabelecimento};2=${cnpjLoja}]`;
    }

    const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/startTransaction`, param);
    if (data?.statusCode !== 400) {
      if (data.serviceStatus !== 0) {
        console.log("Agente ocupado: " + data.serviceStatus + " " + data.serviceMessage);
      }
      else if (data.clisitefStatus !== 10000) {
        console.log("Retorno " + data.clisitefStatus + " da CliSiTef");
      }
      else {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        sessionId = data.sessionId;
        continueTransaction('', funcao, newObject, hash, valueTotal);
      }

    } else {
      setManagement('metodo-pagamento');
    }
  }, []);

  const continueTransaction = useCallback(async (dados, installMent, newObject, hash, valueTotal) => {
    const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/continueTransaction`, `sessionId=${sessionId}&data=${dados}&continue=${continua}`);
    //console.log(data)
    if (data?.statusCode !== 400) {

      if (data.clisitefStatus !== 10000) {

        if (data.clisitefStatus === 0) {
          finishTransaction(1, newObject, hash, valueTotal);
          //cancelReservation(newObject);
        } else if (data.clisitefStatus === -43) {
          setManagement('error');
        } else if (data.clisitefStatus === 255) {
          setManagement('error');
          setInfosMaquina("Dados Inválidos!");
        } else if (data.clisitefStatus === -2) {
          setManagement('error');
          setInfosMaquina("Operação Cancelada!");

          /* setTimeout(() => {
            window.location.reload();
          }, 3000); */
        }

        // alert("Fim - Retorno: " + data.clisitefStatus + "<br>" + s);
        return;
      }

      //console.log(data.commandId, dados, installMent);
      switch (data.commandId) {
        case 0:
          setContinueReady(false);
          // acumula o resultado em um JSON, pode ser usado no final da transa��o para POST ao servidor da automa��o
          // sessao.ret.push(item);
          // console.info("TipoCampo [" + data.fieldId + "] Valor[" + data.data + "]");            

          switch (data.fieldId) {
            case 133:
              tempObject.nsu_sitef = data.data;
              break;
            case 134:
              tempObject.nsu_host = data.data;
              break;
            case 135:
              tempObject.codigo_autorizacao = data.data;
              break;
            case 132:
              tempObject.bandeira = data.data;
              break;
            case 157:
              tempObject.estabelecimento = data.data;
              break;
            case 158:
              tempObject.rede_autorizadora = data.data;
              break;
          }

          if (data.fieldId === 121) {
            console.log(data.data);
            comp_cancel.comprovante_estabelecimento_cancel = data.data;
          }

          // alert("Cupom Estabelecimento: \n" + data.data);

          if (data.fieldId === 122) {
            console.log(data.data);
            comp_cancel.comprovante_cliente_cancel = data.data;
            setManagement('aprovado');
          }

          continueTransaction('', installMent, newObject, hash, valueTotal);
          break;
        case 1:
        case 2:
        case 3:
        case 4:
        case 15:
          setContinueReady(false);
          setInfosMaquina(data.data);
          continueTransaction('', installMent, newObject, hash, valueTotal);
          break;
        case 11:
        case 12:
        case 13:
        case 14:
        case 16:
          //Apaga display
          setContinueReady(false);
          continueTransaction('', installMent, newObject, hash, valueTotal);
          break;
        case 20:
          setContinueReady(false);
          setManagement('error');
          setInfosMaquina(data.data);

          setTimeout(() => {
            continueTransaction(0, installMent, newObject, hash, valueTotal);
          }, 1000);

          //finishTransaction(1);

          /* setTimeout(() => {
            window.location.reload();
          }, 3000); */
          break;
        case 22:
          setContinueReady(false);
          setInfosMaquina(data.data);
          continueTransaction('', installMent, newObject, hash, valueTotal);

          /* if (data.fieldId === -1 && data.data === "Dados invalidos") {
            setManagement('error');
            setInfosMaquina(data.data);

          } else {
            setInfosMaquina(data.data);
            continueTransaction('', installMent, newObject);
          } */

          break;
        case 23:
          // No comando 23, faz o reset da flag de continuidade, para sensibilizar tratamento de confirma��es de cancelamento da clisitef.
          setContinueReady(true);
          setTimeout(function () { continueTransaction('', installMent, newObject, hash, valueTotal); }, 500);
          break;
        case 21:
          if (installMent === 2) {
            setContinueReady(false);
            setTimeout(function () { continueTransaction(1, installMent, newObject, hash, valueTotal); }, 500);
          } else if (installMent === 3) {
            //Função de Parcelas
            setContinueReady(false);
            continueTransaction(2, installMent, '', hash, valueTotal);
          }
          break;
        case 30:
          if (data.fieldId === 515) {
            let date: any = newObject.dataEmissao.split("T")[0].split("-").reverse().join("");
            setContinueReady(false);
            continueTransaction(date, installMent, newObject, hash, valueTotal);
          } else if (data.fieldId === 516) {
            setContinueReady(false);
            continueTransaction(newObject.nsuHost, installMent, newObject, hash, valueTotal);
          }
          //continueTransaction(22062022, installMent);

          break;
        case 31:
        case 32:
        case 33:
        case 34:
          setContinueReady(false);
          continueTransaction((valueTotal * 100), installMent, newObject, hash, valueTotal);
          break;
        case 35:
        case 38:
          setContinueReady(false);
          // console.log(data);            
          setTimeout(function () { continueTransaction(1, installMent, newObject, hash, valueTotal); }, 100);
          break;
        default:
          continueTransaction('', installMent, '', hash, valueTotal);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const finishTransaction = useCallback(async (confirma, newObject, hash, valueTotal) => {
    //console.log(confirma)
    const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/finishTransaction`, `sessionId=${sessionId}&taxInvoiceNumber=${newObject.reservaC2}&taxInvoiceDate=20170304&taxInvoiceTime=170000&confirm=${confirma}`);
    if (data?.statusCode !== 400) {
      finalizeReservation(hash, valueTotal);
      /* setTimeout(() => {
        window.location.reload();
      }, 3000); */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /* END - Cancelamento SITEF */
  //
  //
  //

  function changeContinua(cont: any) {
    continua = cont;
  }

  function reloadTable() {
    buscar(name, email, id, acessCode, status, actualDate, channel, actualAntifraude, codigoCaixa, uidOperador, pageCount);
  }

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">

          <Breadcrumb title={t("reservations.title") + "/ Listar Reservas"} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>Listar Reservas</h2>
          </div>

          <ReservationsFilter
            buscar={buscar}
          />

          <ReservationsTable
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            reservations={reservations}
            confirm={confirm}
            log={log}
            cancelReservation={cancelReservation}
            modalContent={modalContent}
            setConfirm={setConfirm}
            setLog={setLog}
            handleModal={handleModal}
            loading={loading}
            handleNext={handleNext}
            setReason={setReason}
            valReason={valReason}
            reloadTable={reloadTable}
          />
        </div>

        <ModalCancelSitef
          modalCancel={modalCancel}
          classModal="modal-confirm"
          infosMaquina={infosMaquina}
          management={management}
          changeContinua={changeContinua}
          continueReady={continueReady}
        />
      </DesktopDefault>
    </>
  );
}

export default ListReservations;