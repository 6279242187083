import React, { useState, useEffect, Key, useCallback } from "react";
import api from "../../../../services/api";

import { Container, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./TourList.scss";
import AccordionTour from "../Accordions/AccordionTour";
import AccordionTicket from "../Accordions/AccordionTicket";

import InfoProducts from "../InfoProducts/InfoProducts";
import InfoProductsTicket from "../InfoProducts/InfoProductsTicket";

export interface tourList {
  search: any;
  setSearch: any;
  setIsLoading: any;
}

const TourList = ({ search, setSearch, setIsLoading }: any) => {
  const [tourList, setTourList] = useState([]);
  const [tourDetails, setTourDetails] = useState<any>(null);
  const [modalShow, setModalShow] = React.useState(false);

  const [, setCategoories] = useState<any>(null);
  const [pagination, setPagination] = useState<any>(1);
  const [tourListSize, setTourListSize] = useState<any>(null);
  const [, setValue] = useState<any>({ min: 0, max: 1000 });
  const [preLoad, setPreLoad] = useState<any>(false);

  const getTourList = useCallback(async (search, pag) => {
    setIsLoading(true);
    setPreLoad(true);

    const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/Products/FetchByChannel`,
      {
        "page": pag,
        "rowsPerPage": 5,
        "term": "",
        "channel": search?.id,
        "lang": "BR",
        "ProductType": 0,
        "categories": [
        ],
        "stars": [
        ],
        "priceStart": 0,
        "priceEnd": 0,
        "SupplierId": 0
      });

    setTourList(data);

    if (data?.statusCode !== 400) {
      setIsLoading(false);
      if (data.data !== null) {
        setPreLoad(false);
        setTourList(data.data.rows);
        setTourListSize(data.data.rowsCount);
        setCategoories(data.data.categories);
        setValue({ min: Math.floor(data.data.minPrice), max: Math.ceil(data.data.maxPrice) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search != null) {
      getTourList(search, pagination);
    }
  }, [getTourList, search, pagination]);

  const TourDetailRender = async (productCode: any) => {
    setModalShow(true);
    try {
      const data = await api.post(
        `/Products/FetchSingleTourAsync`,
        {
          "productCode": productCode,
          "lang": "BR",
          "slug": "",
          "IdCanal": search?.id
        }
      );
      if (data.status !== 400) {
        setTourDetails(data.data.data);
      }
    } catch (error) { }

  }

  const TicketDetailRender = async (productCode: any) => {
    setModalShow(true);
    try {
      const data = await api.post(
        `/Products/FetchSingleTicketAsync`,
        {
          "productCode": productCode,
          "lang": "BR",
          "slug": "",
          "IdCanal": search?.id
        }
      );
      if (data.status !== 400) {
        setTourDetails(data.data.data);
      }
    } catch (error) { }
  }

  const paginateBack = () => {
    setPagination(pagination - 1);
  }

  const paginateFoward = () => {
    setPagination(pagination + 1);
  }

  //Estrelas dos comentários
  const SetStar = (index: any) => {
    let rating: any = tourList[index.index]['stars'];
    let arrayStars: any = [];


    if (rating > 0 && rating <= 0.75) {
      rating = 0.5;
    } else if (rating > 0.76 && rating <= 1.25) {
      rating = 1;
    } else if (rating > 1.26 && rating <= 1.75) {
      rating = 1.5;
    } else if (rating > 1.76 && rating <= 2.25) {
      rating = 2;
    } else if (rating > 2.26 && rating <= 2.75) {
      rating = 2.5;
    } else if (rating > 2.76 && rating <= 3.25) {
      rating = 3;
    } else if (rating > 3.26 && rating <= 3.75) {
      rating = 3.5;
    } else if (rating > 3.76 && rating <= 4.25) {
      rating = 4;
    } else if (rating > 4.26 && rating <= 4.75) {
      rating = 4.5;
    } else if (rating > 4.76 && rating <= 5) {
      rating = 5;
    }

    for (var i: any = 0; i < 5; i = i + 0.5) {
      if (i < rating) {
        arrayStars.push(1);
      } else {
        arrayStars.push(0);
      }
    }

    let aux: any = [];

    for (var j: any = 0; j < 10; j = j + 2) {
      if (arrayStars[j] === 1 && arrayStars[j + 1] === 1) {
        aux.push(1);
      } else if (arrayStars[j] === 1 && arrayStars[j + 1] === 0) {
        aux.push(0.5);
      } else if (arrayStars[j] === 0 && arrayStars[j + 1] === 0) {
        aux.push(0);
      }
    }

    arrayStars = aux;

    if (rating !== null) {
      return (
        <>
          {arrayStars.length > 0
            ? arrayStars.map((array: any, index: Key) => {
              return (
                array === 1 || array === 0 ?
                  <FontAwesomeIcon
                    key={index}
                    icon={["fas", "star"]}
                    size="2x"
                    style={{
                      margin: "0px 5px 5px 0px",
                      color: array === 1 ? "#ffC60A" : "#707070",
                      fontSize: "1.15rem"
                    }}
                  />
                  :
                  <FontAwesomeIcon
                    key={index}
                    icon={["fad", "star-half"]}
                    size="1x"
                    style={{
                      margin: "0px 5px 5px 0px",
                      color: array === 0.5 ? "#ffC60A" : "#707070",
                      fontSize: "1.15rem"
                    }}
                  />
              );
            })
            : ""}
        </>
      );
    } else {
      return (
        <></>
      )
    }
  };

  return (
    <Container className="mt-4" fluid>
      <Container className="my-3 py-0 px-2" fluid>
        <Row>
          <div className="col-12 col-md-12 side-list px-1 px-md-4">
            {preLoad === false
              ?
              <>
                {tourList?.length > 0
                  ? tourList.map((tour: any, index: Key) => (
                    <div key={index} className="card card-filter-list w-100 mb-4">
                      <Row className="mx-0 px-0">
                        <div className="col-12 col-md-3 px-0">
                          <div style={{ height: "100%" }} className="card-img card-img-custom p-3">
                            <div
                              className="bg-tour-list"
                              style={{
                                backgroundImage: `url(${tour.imagesBaseUrl}${tour.productImg})`
                              }}
                            >
                              <span className="badge rounded position-absolute badge-primary py-2 px-5 text-uppercase" style={{ display: "flex", alignItems: "center", margin: "5px" }}>
                                {tour.typeProduct === "TOUR" ? "Tours" : "Tickets"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-7 text-custom px-3 px-md-0 py-3" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                          <div>
                            <h5 className="tour-name mb-3">{tour.productName}</h5>
                            <div className="d-flex flex-column flex-md-row">
                              <div>
                                <p className="small-custom text-muted">
                                  <strong>Fornecedor:</strong><br />
                                  <span> {tour.supplierFantasyName}</span>
                                </p>
                              </div>
                              <div className="px-0 px-md-5">
                                <p className="small-custom text-muted">
                                  <strong>Localidade:</strong><br />
                                  <span> {tour.eventLocation}</span>
                                </p>
                              </div>
                            </div>
                            <div className="stars-tour-list d-none d-md-block">
                              <SetStar index={index} />
                            </div>
                            <div className="stars-tour-list"></div>
                            <div
                              className="text-tour-info"
                              dangerouslySetInnerHTML={{ __html: tour.productInfo }}
                            ></div>
                          </div>
                          <div className="d-flex justify-content-start btn-details d-none d-md-flex" >
                            <div className="d-flex justify-content-end">
                              {tour.typeProduct === "TOUR" ?
                                <button className="btn" type="button" onClick={() => TourDetailRender(tour.productCode)} >Detalhes do Produto</button>
                                :
                                <button className="btn" type="button" onClick={() => TicketDetailRender(tour.productCode)} >Detalhes do Ticket</button>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-2 text-end pb-3 pb-md-0 pt-3 px-3 px-md-4 d-flex d-md-block justify-content-between align-items-center">
                          <div className="btn-details d-block d-md-none" >
                            <div>
                              <div className="stars-tour-list d-flex d-md-none justify-content-start">
                                <SetStar index={index} />
                              </div>

                              <div className="d-flex justify-content-end">
                                {tour.typeProduct === "TOUR" ?
                                  <button className="btn" type="button" onClick={() => TourDetailRender(tour.productCode)} >Detalhes do Produto</button>
                                  :
                                  <button className="btn" type="button" onClick={() => TicketDetailRender(tour.productCode)} >Detalhes do Ticket</button>
                                }
                              </div>
                            </div>
                          </div>
                          <div>
                            <span className="small text-muted w-50">
                              A partir de:
                            </span>
                            <div className="price">
                              <h5>
                                {"R$ " +
                                  tour.price.toFixed(2).replace(".", ",")}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 px-0">
                          {
                            tour.typeProduct === "TOUR"
                              ?
                              <AccordionTour
                                productCode={tour.productCode}
                                search={search}
                              />
                              :
                              <AccordionTicket
                                productCode={tour.productCode}
                                search={search}
                              />
                          }
                        </div>
                      </Row>
                    </div>
                  ))
                  : ""}

                <div className="bg-pagination d-flex justify-content-end text-black">
                  {pagination > 1 ? <button className="paginate-btn" onClick={paginateBack}>{
                    <FontAwesomeIcon
                      icon={["fal", "chevron-left"]}
                      size="1x"
                      style={{ color: "#FFF", fontSize: "16px" }}
                    />
                  }</button> : ''}
                  <span>Página {pagination} - Mostrando ({(pagination - 1) * 5} - {pagination * 5 > tourListSize ? tourListSize : pagination * 5}) de {tourListSize}</span>
                  {(pagination < (tourListSize / 5)) ? <button className="paginate-btn" onClick={paginateFoward}>{
                    <FontAwesomeIcon
                      icon={["fal", "chevron-right"]}
                      size="1x"
                      style={{ color: "#FFF", fontSize: "16px" }}
                    />
                  }</button> : ''}
                </div>
              </>
              :
              tourList?.length !== undefined
                ?
                <>
                  {[1, 2, 3, 4, 5].map((tour: any, index: Key) => (

                    <div key={index} className="col-12 col-md-10 side-list w-100">
                      <div className="card card-filter-list w-100 mb-4" style={{ height: "342px" }}>

                        <Row>
                          <div className="col-5 col-md-3 img-mobile"><div className="card-img card-img-custom my-3 ml-3 animated-background" style={{ maxWidth: "480px", height: "249px" }}></div></div>
                          <div className="col-7 col-md-7 text-custom pt-3">
                            <h5 className="tour-name animated-background" style={{ height: "24px" }}> </h5>
                            <div className="dates d-flex">
                              <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                              <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                              <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                              <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                              <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                              <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                              <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                            </div>
                            <p className="small text-muted animated-background" style={{ height: "20px", marginBottom: "0.5rem" }}></p>
                            <p className="small text-muted animated-background" style={{ height: "20px", marginBottom: "0.5rem" }}></p>
                            <p className="small text-muted animated-background" style={{ height: "20px", marginBottom: "0.5rem" }}></p>
                            <div className="animated-background" style={{ height: "42px" }}></div>
                          </div>
                          <div className="col-2 col-md-2 m-auto text-center animated-background" style={{ height: "144px", maxWidth: "190px" }}>
                            <span className="small text-muted w-50"></span>
                            <div className="price"></div>
                          </div>
                          <div className="col-12 col-md-"></div>
                        </Row>
                      </div>
                    </div>
                  ))}
                </>
                :
                <>
                  <h4 className="text-center text-primary mt-4">Nenhum ticket encontrado.</h4>
                </>
            }
          </div>
        </Row>
      </Container>
      <Modal
        className="modal-custom modal-products-info"
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        {
          tourDetails?.typeProduct === "TOUR"
            ?
            <InfoProducts
              tourDetails={tourDetails}
              setModalShow={setModalShow}
              search={search}
            />
            :
            <InfoProductsTicket
              tourDetails={tourDetails}
              setModalShow={setModalShow}
              search={search}
            />
        }
      </Modal>
    </Container>
  );
}

export default TourList;
