import React, { Key, useEffect, useState } from 'react';
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Button from "@restart/ui/esm/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

//STYLES
import './Checkout.scss';

import { useAppSelector } from "../../store/hooks";

//ICONS
import BookingInformation from '../../components/Checkout/BookingInformation/BookingInformation';
import CartCheckout from '../../components/Checkout/CartCheckout/CartCheckout';
import DetailsCkeckout from '../../components/Checkout/DetailsCkeckout/DetailsCkeckout';
import PaymentCheckout from '../../components/Checkout/PaymentCheckout/PaymentCheckout';

import { Col, Modal, Row } from 'react-bootstrap';
import ModalContentPayment from '../../components/Checkout/ModalContentPayment/ModalContentPayment';
import CartHeader from '../Sales/components/CartHeader/CartHeader';
import api from '../../services/api';
import CupomCheckout from '../../components/Checkout/CupomCheckout/CupomCheckout';


function Checkout() {
    const authLogin: any = JSON.parse(localStorage.getItem("bpaSigAuth") || "{}");
    const idCanal: any = authLogin?.user?.perfil?.idCanal;

    const cartSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    const level = atob(JSON.parse(localStorage.getItem('bpaSigAuth') || '{}').user.perfil.nivel);

    const supplierIdentify = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}').user.perfil.supplierIdentity;

    // console.log(cartSIG)

    const [alertBool, setAlertBool] = useState<any>(false);
    const [modalResponse, setModalResponse] = useState<any>(null);
    const [checkoutResponse, setCheckoutResponse] = useState<any>('');
    const [modalContent, setModalContent] = useState<any>('loading');
    const alertMessage: any = '';

    const [paymentMethod, setPaymentMethod] = useState<any>((level === '19' || level === "18") ? '111' : '85'); // 85 - Faturado / 111 - Link Externo
    const [merchantPayMethod, setMerchantPayMethod] = useState<any>('0'); // Faturado

    const [mainContactObj, setMainContactObj] = useState<any>(null);
    const [isForeign, setIsForeign] = useState<boolean>(false);

    const resetCart = () => {
        //setAllowResetCart(true);
        localStorage.removeItem("cartSIG");
        window.location.href = "/sale";
    }

    const controlMethod = (newMethod: string, mPayMethod: string) => {
        setPaymentMethod(newMethod);
        setMerchantPayMethod(mPayMethod)
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
    } = useForm();

    useEffect(() => {
        if (isForeign === true) {
            setValue('city', '');
            setValue('state', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isForeign])

    const watchIsMain = watch(`isMain`, false);
    const watchFirstName = watch(`name`, false);
    const watchLastName = watch(`lastName`, false);
    const watchEmail = watch(`email`, false);
    const watchPhone = watch(`phone`, false);
    const watchDdi = watch(`ddi`, false);
    const watchState = watch(`state`, '');
    const watchLanguage = watch('language', false);

    useEffect(() => {
        if (isForeign === false) {
            setValue('city', '');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchState])

    useEffect(() => {
        if (watchIsMain === true) {
            setMainContactObj(
                {
                    "name": watchFirstName,
                    "lastName": watchLastName,
                    "email": watchEmail,
                    "phone": watchPhone,
                    "ddi": watchDdi,
                }
            )
        } else if (watchIsMain === false) {
            setMainContactObj(
                {
                    "name": '',
                    "lastName": '',
                    "email": '',
                    "phone": '',
                    "ddi": '',
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchIsMain])

    const onSubmit = (data: any) => {
        data.foreign = isForeign === false ? 2 : 1;
        data.paymentOptions = "billed";
        data.idCanal = idCanal;

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        let todayFoPayment = yyyy + '-' + mm + '-' + dd;

        let payments = [{
            brand: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? data.brand : undefined,
            cardNumber: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? data.number : undefined,
            codeVerifier: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? data.cvc : undefined,
            DocumentNumber: isForeign === false ? data.cpf : data.id,
            SessionIdKoin: '',
            datePayment: todayFoPayment,
            expiration: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? data.expiry : undefined,
            holder: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? data.titular : undefined,
            installments: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? parseInt(data.parcelas.split('x')[0]) : 1,
            MerchantPayMethod: merchantPayMethod, // Passar 0 para faturado
            // value: data.valor,
            payMethod: paymentMethod, //faturado = 85
            merchant: data.merchant,
            refCode: data.refCode, //NSU 
            CodAutorizacaoOffline: data.paymentOptions === 'cOffiline' ? data.CodAutorizacaoOffline : undefined,
        }];
        // data.payments = payments;
        cartSIG.payments = payments;

        let totalCart = 0;
        cartSIG.dados.map((info: any, index: Key) => {
            let priceProduct = (
                (Number(info.adults) * Number(info.priceAdults))
                + (Number(info.childs) * Number(info.priceChilds))
                + (Number(info.infants) * Number(info.priceInfants))
                + (Number(info.elders) * Number(info.priceElders))
                + (Number(info.student) * Number(info.priceStudent))
                + (Number(info.priceGlobalPeople))
            );
            totalCart = Number(totalCart) + (priceProduct - (priceProduct * (info.discount / 100)));

            if (index === 0) {
                data.dados[index].passengers[0].DocumentNumber = isForeign === false ? String(data.cpf).replaceAll('.', '').replaceAll('-', '') : String(data.id).replaceAll('.', '').replaceAll('-', '');
            }
            cartSIG.dados[index].passengers = data.dados[index].passengers;
            cartSIG.dados[index].pickup = data.dados[index].pickup;
            cartSIG.dados[index].internalNotes = data.dados[index].internalNotes;
            if (info.productType === 2) {
                cartSIG.dados[index].cia = data.cia;
                cartSIG.dados[index].voo = data.flight;
            }
            return true;/*  */
        })

        // cartSIG.agent = data.agent;
        cartSIG.foreign = isForeign === false ? 2 : 1;
        cartSIG.agent = supplierIdentify || "CPA3120";
        cartSIG.street = data.street;
        cartSIG.Country = data.country;
        cartSIG.city = isForeign === false && (data.paymentOptions === 'credit' || data.paymentOptions === undefined)/* && paymentMethod === '1' && merchantPayMethod === '1' */ ? data.city.description : (data.city || 'Rio de Janeiro');
        cartSIG.state = isForeign === false && (data.paymentOptions === 'credit' || data.paymentOptions === undefined)/* && paymentMethod === '1' && merchantPayMethod === '1' */ ? data.state.id : (data.state || 'RJ');
        cartSIG.source = "sig";
        cartSIG.resellerReference = data.reference;

        cartSIG.payments[0].value = totalCart;

        cartSIG.language = data.language;
        cartSIG.idCanal = data.idCanal;

        // console.log(paymentMethod)
        if (paymentMethod === '1' && merchantPayMethod === '1') {
            cartSIG.street = data.street;
            cartSIG.city = isForeign === false ? data.city.description : data.city;
            cartSIG.state = isForeign === false ? data.state.id : data.state;

            if (data.brand === undefined) {
                alert('Cartão Inválido');
            } else {
                createPayment();
            }
        } else {
            createPayment();
        }
    };

    const createPayment = async () => {
        setModalResponse(true);
        setModalContent('loading');
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        };
        try {
            const { data } = await api.post('/Checkout/EnviaCheckoutAuth', JSON.stringify(cartSIG), config);
            if (data.status !== 400) {

                if (data.data.log === '1' || data.data.log === 1) {
                    setCheckoutResponse(data);
                    setModalContent('success');
                } else {
                    setCheckoutResponse(data);
                    setModalContent('success');

                }
                //setLoading(false);
            }

        } catch (error: any) {
            if (error?.response?.status === 400) {
                setCheckoutResponse(error.response);
                setModalContent('error');
                //setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (cartSIG?.dados.length < 1) {
            window.location.href = "/sale";
        }
    }, [cartSIG])

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid container-checkout">
                    <Breadcrumb title="Checkout" />

                    <div className="checkout-title d-flex flex-column align-items-between">
                        <div className="d-flex justify-content-between w-100">
                            <div className="d-flex align-items-center">

                                <span className='back' onClick={() => { window.location.href = '/sale' }}>
                                    <FontAwesomeIcon
                                        icon={["fal", "angle-double-left"]}
                                        style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                                    />
                                    Voltar</span>
                            </div>
                            <div className="icon-tp">

                                <CartHeader></CartHeader>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start w-100">
                            <h1>
                                {/* <FontAwesomeIcon
                                className='icon-primary'
                                icon={["fal", "shopping-cart"]}
                                size="1x"
                                style={{ marginRight: "10px", width: "30px", fontSize: "2rem", color: "#707070" }}
                                /> */}
                                Checkout
                            </h1>
                        </div>
                    </div>

                    <Row>
                        <Col md={9} className="container-cards">
                            <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                <BookingInformation
                                    control={control}
                                    errors={errors}
                                    setValue={setValue}
                                    isForeign={isForeign}
                                    setIsForeign={setIsForeign}
                                />
                                <CartCheckout
                                    control={control}
                                    errors={errors}
                                    cart={cartSIG.dados}
                                    setValue={setValue}
                                    mainContactObj={mainContactObj}
                                />
                                <PaymentCheckout
                                    control={control}
                                    errors={errors}
                                    setValue={setValue}
                                    action={controlMethod}
                                    isForeign={isForeign}
                                    getValues={getValues}
                                />

                                <div className="d-flex justify-content-end">
                                    <Button className="btn btn-primary" type='submit'>
                                        EFETUAR PAGAMENTO
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                        <Col md={3} className="container-details">
                            <div>
                                <DetailsCkeckout
                                    cart={cartSIG.dados}
                                />
                                {
                                    (level === "19" || level === "18")
                                    &&
                                    <CupomCheckout />
                                }
                                {/* <CancelPolicyCheckout /> */}
                            </div>
                        </Col>
                    </Row>

                    <Modal
                        className="modal-custom modalCheckout modalPayment"
                        show={modalResponse !== null}
                        onHide={() => {
                            // setCheckoutResponse('');
                            setModalContent('loading');
                            setModalResponse(null)
                            resetCart();

                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                    >
                        <>
                            <Modal.Body>
                                <ModalContentPayment
                                    pix={['96', '100', '100', '111'].includes(paymentMethod)}
                                    modalContent={modalContent}
                                    checkoutResponse={checkoutResponse}
                                    resetCart={resetCart}
                                    setModalResponse={setModalResponse}
                                    getValues={getValues}
                                    watchLanguage={watchLanguage}
                                ></ModalContentPayment>
                            </Modal.Body>
                        </>
                    </Modal>

                    <Modal
                        className="modal-confirm"
                        show={alertBool}
                        onHide={() => {
                            setAlertBool(false)
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <div className='modal-body text-center'>
                            <div>
                                <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    style={{ fontSize: "7.5em", color: "#00632D" }}
                                />

                            </div>
                            <div className="px-md-5" style={{ padding: "0 3rem" }}>
                                {alertMessage}
                            </div>
                            <div>
                                <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlertBool(false)}>Fechar</Button>
                            </div>
                        </div>
                    </Modal>

                </div>
            </DesktopDefault>
        </>
    );
}

export default Checkout;