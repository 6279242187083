import React, { Key } from 'react';

import CartCheckoutTour from './CartCheckoutTour';
import CartCheckoutTickets from './CartCheckoutTickets';
import CartCheckoutTransfer from './CartCheckoutTransfer';

export interface cartInfo {
    cart: any;
    control: any,
    errors: any,
    setValue: any;
    mainContactObj?: any;
}
const CartCheckout: React.FC<cartInfo> = ({
    cart, control, errors, setValue, mainContactObj
}: cartInfo) => {

    return (
        <>
            <h2 className='cart-title'>Carrinho</h2>

            {cart.length > 0
                ? cart.map((info: any, index: Key) => {
                    if (info.productType === 1 /* info.typeProduct === 'TOUR' */) {
                        return (
                            <div key={index} style={{ marginBottom: "20px" }}>
                                <CartCheckoutTour
                                    data={index}
                                    control={control}
                                    errors={errors}
                                    info={info}
                                    setValue={setValue}
                                    mainContactObj={mainContactObj}
                                />
                            </div>
                        )
                    } else if (info.productType === 4 /* info.typeProduct === 'TICKET' */) {
                        return (
                            <div key={index} style={{ marginBottom: "20px" }}>
                                <CartCheckoutTickets
                                    data={index}
                                    control={control}
                                    errors={errors}
                                    info={info}
                                    setValue={setValue}
                                    mainContactObj={mainContactObj}
                                />
                            </div>
                        )
                    } else if (info.productType === 2 /* info.typeProduct === 'TRANSFER' */) {
                        return (
                            <div key={index} style={{ marginBottom: "20px" }}>
                                <CartCheckoutTransfer
                                    info={info}
                                    control={control}
                                    errors={errors}
                                    data={index}
                                    setValue={setValue}
                                    mainContactObj={mainContactObj}
                                />
                            </div>
                        )
                    } else {
                        return null;
                    }
                })
                : ''
            }
        </>
    );
}

export default CartCheckout;