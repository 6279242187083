import React, { useEffect, useState } from "react";
import api from "../../services/api";

//componentes
import DesktopDefault from "../../templates/DesktopDefault";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import CommissionsFilter from "./Components/CommissionsFilter/CommissionsFilter";
import CommissionsTable from "./Components/CommissionsTable/CommissionsTable";
import CommissionsBills from "./Components/CommissionsBills/CommissionsBills";

function Commissions(props: any) {
  const [commissions, setCommissions] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);

  //filtragem
  const [actualName, setActualName] = useState<any>("");
  const [actualDate, setActualDate] = useState<any>([]);
  const [actualStatus, setActualStatus] = useState<any>(0);
  //fim da filtragem

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  function clearActualValues() {
    setActualName("");
    setActualStatus(0);
    setActualDate([]);
  }

  useEffect(() => {
    setCommissions(null);
    setLoading(true);

    let beginDate: any = []
    let endDate: any = []

    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/')
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/')
      endDate = actualDate[1].split('/')
    }

    //let today: any = new Date();

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function listCommissions() {
      try {
        const { data } = await api.post(
          "/AgentStatement/GetAgentStatementByFilterAsync",
          {
            page: pageCount,
            rowsPerPage: rowsPerPage,
            groupId: 730,
            name: "",
            status: 0,
            beginDate: (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : "",
            endDate: (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : "",
            orderByField: "",
          },
          config
        );

        if (data.status !== 400) {
          setLoading(false);
          setCommissions(data.data.agentStatementGridDTO.rows);
          setTotalRows(data.data.agentStatementGridDTO.rowsCount);
          console.log(data.data);
        }
      } catch (error: any) {
        setLoading(false);
        /* if (error.response.status === 401) {
          window.location.href = window.location.origin + "/";
        } */
      }
    }
    listCommissions();
  }, [pageCount, rowsPerPage]);

  async function buscar(
    name: any,
    date: any,    
    status: any
  ) {
    setCommissions(null);
    setLoading(true);
    // setAllowLoadFromFilter(true);
    setActualName(name);
    setActualDate(date);
    setActualStatus(status);
    setPageCount(1);

    let beginDate:any = [];
    let endDate:any = [];

    if (date.length === 1) {
        beginDate = date[0].split('/')
    } else if (date.length === 2) {
        beginDate = date[0].split('/')
        endDate = date[1].split('/')
    }

    //let today: any = new Date();

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function listCommissions() {
      try {
        const res = await api.post(
          "/AgentStatement/GetAgentStatementByFilterAsync",
          {
            page: pageCount,
            rowsPerPage: rowsPerPage,
            groupId: 730,
            name: name,
            status: status,
            beginDate: (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : "",
            endDate: (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : "",
            orderByField: "",
          },
          config
        );

        if (res.status !== 400) {
          setLoading(false);
          setCommissions(res.data.data.agentStatementGridDTO.rows);
          setTotalRows(res.data.data.agentStatementGridDTO.rowsCount);
          console.log(res.data);
        }
      } catch (error: any) {
        setLoading(false);
        /* if (error.response.status === 401) {
          window.location.href = window.location.origin + "/";
        } */
      }
    }
    listCommissions();
  }

  //     const data = [
  //         {

  //         "GenericValue1": "0",
  //         "GenericValue2": "1",
  //         "GenericValue3": "2",
  //         "GenericValue4": "2022-04-28",
  //         "GenericValue5": "4",
  //         "GenericValue6": "5",
  //         "GenericValue7": "6",
  //         "GenericValue8": "7",
  //         "GenericValue9": "2022-04-28"
  //     }
  // ];

  // const [commissions, setCommissions] = useState <any>(data);

  
    return (
      <>
        <DesktopDefault>
          <div className="container-fluid content-dashboard">
            <Breadcrumb title={"Comissões"} />

            <div className="mt-4 d-flex justify-content-between align-items-center">
              <h2 className="title" style={{ color: "#707070" }}>
                Comissões
              </h2>
            </div>
            <CommissionsBills />

            <CommissionsFilter
              buscar={buscar}
              commissions={commissions}
              clearActualValues={clearActualValues}
            />           
            <CommissionsTable
                commissions={commissions}
                totalRows={totalRows}
                pageCount={pageCount}
                setPageCount={setPageCount}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                actualDate={actualDate}
                loading={loading}
                />         
            
          </div>
        </DesktopDefault>
      </>
    );
  }  
export default Commissions;
