import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Dropdown, Modal } from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";

import ModalDefault from '../../../../components/Modal/ModalDefault/ModalDefault';

import api from "../../../../services/api";

//COMPONENTS

import './SessionPassengers.scss';
import SessionChange from './components/SessionChange/SessionChange';

export interface propPassengers {
    items: any,
    sessionInfo?: any
};

const SessionPassengers: React.FC<propPassengers> = ({
    items, sessionInfo
}: propPassengers) => {

    /* Success Modal (Mudar Session) */
    const [modalSuccess, setModalSuccess] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>();
    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();
    const [modalShow, setModalShow] = useState<any>(false);
    /* END - Success Modal (Mudar Session) */

    const tourAuth: any = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    function SendEmail(row: any) {
        setModalContent("loading");
        const email = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Manifest/SendEmailToClient`,
                    {
                        id: row.newItemsId,
                        uid: uId,
                        usuario: `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    alert("Erro ao processar operação");
                    setModalShow(true);
                    setLog(1);
                    setModalContent("error");
                }
                if (error?.response?.status === 400) {
                    setModalShow(true);
                    alert("Erro ao processar operação");
                    setLog(1);
                    setModalContent("error");
                }
            }
        };

        email();
    }

    function SendWhatsapp(row: any) {
        setModalContent("loading");
        const whatsapp = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Manifest/SendWhatsAppToClient`,
                    {
                        id: row.newItemsId,
                        uid: uId,
                        usuario: `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    alert("Erro ao processar operação");
                    setModalShow(true);
                    setLog(1);
                    setModalContent("error");
                }
                if (error?.response?.status === 400) {
                    setModalShow(true);
                    alert("Erro ao processar operação");
                    setLog(1);
                    setModalContent("error");
                }
            }
        };

        whatsapp();
    }

    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="d-flex justify-content-center dropdown-custom">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="1" disabled={false}>
                                <ModalDefault
                                    title="Trocar Session"
                                    icon="yes"
                                    name={"Mudar de Session"}
                                    typeIcon="exchange"
                                    classBtn="px-0 w-100"
                                    classModal="modal-pos-tax modal-change-session"
                                >
                                    <SessionChange
                                        sessionInfo={sessionInfo}
                                        items={items}
                                        row={row}
                                        show={setModalSuccess}
                                        setResponseText={setResponseText}
                                        setModalContent={setModalContent}
                                        setLog={setLog}
                                    />
                                </ModalDefault>
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="2"
                                disabled={false}
                                onClick={() => {
                                    SendEmail(row)
                                    setModalSuccess(true);
                                }}>
                                <button className='px-0'>
                                    <FontAwesomeIcon
                                        icon={['fas', 'envelope']}
                                        style={{ fontSize: "16px" }}
                                        className="mr-2"
                                    />
                                    Enviar Email
                                </button>
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="2"
                                disabled={false}
                                onClick={() => {
                                    SendWhatsapp(row)
                                    setModalSuccess(true);
                                }}
                            >
                                <button className='d-flex align-items-center px-0'>
                                    <FontAwesomeIcon
                                        icon={['fab', 'whatsapp']}
                                        style={{ fontSize: "18px" }}
                                        className="mr-2"
                                    />
                                    Enviar Whatsapp
                                </button>
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                                eventKey="2"
                                disabled={true}
                            >
                                <button className='text-danger remove px-0'>
                                    <FontAwesomeIcon
                                        icon={['fal', 'trash-alt']}
                                        className="mr-2"
                                    />
                                    Cancelar Item
                                </button>
                            </Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    function addLocalizador(cell: any, row: any) {
        return (
            <>
                <div>
                    {cell}<br />
                    <div>
                        {
                            row.paxConfirmed === 2
                                ?
                                <span className="point gray"></span>
                                :
                                row.paxConfirmed === 1
                                    ?
                                    <span className="point green"></span>
                                    :
                                    <span className="point red"></span>
                        }
                        <span style={{ fontSize: "12px" }}> Check-in</span>
                    </div>
                </div>
            </>
        )
    }

    function addClientPhone(cell: any, row: any) {
        //console.log(row)
        return (
            <>
                {cell} <br /> <span style={{ fontSize: "12px" }}>Telefone: {row.telefone}</span>
            </>
        )
    }

    function addParticipantes(cell: any, row: any) {
        return (
            <>
                <div>
                    <span>{cell} Participantes</span> <br /> <span style={{ fontSize: "12px" }}>{row.qtdeAdult} Adultos - {row.qtdeChild} Crianças</span>
                </div>
            </>
        )
    }

    function addLanguage(cell: any, row: any) {
        return (
            <>
                <div>
                    <span>{cell}</span>
                </div>
            </>
        )
    }

    function addLocalizacao(cell: any, row: any) {
        return (
            <>
                <div>
                    <span>{cell}</span> <br /> <span style={{ fontSize: "12px" }}>Horário {row.horario}</span>
                </div>
            </>
        )
    }

    function addTour(cell: any, row: any) {
        return (
            <>
                <span className='text-wrap-custom'>{cell}</span>
            </>
        )
    }

    function addCompra(cell: any, row: any) {
        return (
            <>
                <div>
                    <span>{cell}</span> <br />
                    <div>
                        {
                            row.pago === 2
                                ?
                                <span className="point gray"></span>
                                :
                                row.pago === 1
                                    ?
                                    <span className="point green"></span>
                                    :
                                    <span className="point red"></span>
                        }
                        <span style={{ fontSize: "12px" }}> Status</span>
                    </div>
                </div>
            </>
        )
    }

    function addNote(cell: any, row: any) {
        return (
            <>
                <span className='text-wrap-custom'>{cell}</span>
            </>

        )
    }

    const columns = [
        {
            dataField: "localizador",
            text: "Localizador",
            formatter: addLocalizador,
        },
        {
            dataField: "cliente",
            text: "Cliente",
            formatter: addClientPhone,
        },
        {
            dataField: "qtdePax",
            text: "Quantidade",
            formatter: addParticipantes,
        },
        {
            dataField: "idiomas",
            text: "Idiomas",
            formatter: addLanguage,
        },
        {
            dataField: "localizacao",
            text: "Localização",
            formatter: addLocalizacao,
        },
        {
            dataField: "tour",
            text: "Tour",
            formatter: addTour,
        },
        {
            dataField: "vendedor",
            text: "Compra",
            formatter: addCompra,
        },
        {
            dataField: "notas",
            text: "Notas",
            formatter: addNote,
        },
        {
            dataField: "newItemsId",
            text: "Ação",
            formatter: addActionButton
        },
    ];

    function handleReload() {
        window.location.reload();
    }

    return (
        <>
            <div className="table-default passengers-table">
                <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={items}
                    columns={columns}
                    rowClasses={'border-analise'}
                    striped={true}
                />
                {/* Modal de Success */}
                <Modal
                    className={"modal-confirm loading-modal"}
                    show={modalSuccess}
                    onHide={() => setModalSuccess(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body className='modal-body text-center sucess-pos'>
                            {
                                modalContent !== "loading"
                                    ?
                                    <>
                                        <div>
                                            {
                                                log === 1 || log === "1"
                                                    ?
                                                    <FontAwesomeIcon
                                                        icon={["fal", "times-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em", color: "#6731FF" }}
                                                    />
                                                    :
                                                    log === 0 || log === "0"
                                                        ?
                                                        <FontAwesomeIcon
                                                            icon={["fal", "check-circle"]}
                                                            size="5x"
                                                            style={{ fontSize: "7.5em", color: "#6731FF" }}
                                                        />
                                                        :
                                                        <FontAwesomeIcon
                                                            icon={["fal", "question-circle"]}
                                                            size="5x"
                                                            style={{ fontSize: "7.5em", color: "#6731FF" }}
                                                        />
                                            }
                                        </div>
                                        <div>
                                            {responseText}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            {
                                                modalContent === 'success'
                                                    ?
                                                    <button
                                                        onClick={handleReload}
                                                        className="btn btn-primary mx-2 w-25"
                                                    >
                                                        Confirmar
                                                    </button>
                                                    :
                                                    <button
                                                        onClick={() => setModalShow(false)}
                                                        className="btn btn-primary mx-2 w-25"
                                                    >
                                                        Confirmar
                                                    </button>
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className="modal-body">
                                        <div className="text-center">
                                            <div className="load"></div>
                                        </div>
                                        <div>
                                            <span>Processando</span>
                                        </div>
                                        <div></div>
                                    </div>
                            }
                        </Modal.Body>
                    </>
                </Modal>
                {/* END - Modal de Success */}
            </div>
        </>
    );

}

export default SessionPassengers;