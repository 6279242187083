import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface propPlus {
    tourDetails: any,
    index: any

};

const RefundRules: React.FC<propPlus> = ({
    tourDetails, index
}: propPlus) => {
    const { t } = useTranslation();

    const [refundText, setRefundText] = useState<any>([]);

    useEffect(() => {
        let aux: any = [];

        console.log(tourDetails)

        tourDetails?.refundPolicies.map((item: any, i: any) => {
                if (item?.rules === 1) {
                    aux.push(`${t("refundRules.text01")}`);
                } else if (item?.rules === 2) {
                    aux.push(`${t("refundRules.text02")}`);
                } else {
                    if (item?.percentage === 0) {
                        aux.push(`${t("refundRules.text03")} ${item?.priorDays} ${t("refundRules.text04")}`);
                    } else {
                        aux.push(`${t("refundRules.text03")} ${item?.priorDays} ${t("refundRules.text05")} ${item?.percentage}${t("refundRules.text06")}`);
                    }
                }
            })

        setRefundText(aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourDetails]);

    if (tourDetails !== null && refundText?.length >= 1) {
        return (
            <ul className="mt-4">
                <h6 style={{ fontSize: "1rem", color: "#707070" }}>Política de Cancelamento</h6>
                <ul>
                    {
                        refundText.map((refund: any, indexR: any) => {
                            return (
                                <li key={indexR} style={{ fontSize: ".75rem", color: "#707070", lineHeight: "1.15rem" }}>
                                    {refund}
                                </li>
                            )
                        })
                    }
                </ul>
            </ul>
        )
    } else {
        return (
            <></>
        )
    }
}

export default RefundRules;