import React, { useEffect, useState, Key } from "react";
import api from '../../../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal, Dropdown } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";

//STYLES
import "./TableManifest.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";

import CreateSession from "./components/CreateSession/CreateSession";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

export interface propTable {
    manifestObj: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    exportManifest: any;
    printManifest: any;
    loading: any;
}

const TableManifest: React.FC<propTable> = ({
    manifestObj, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportManifest, printManifest, loading
}: propTable) => {
    /* VARIÁVEIS */
    const { t } = useTranslation();

    const [selectedSingleRow, setSelectedSingleRow] = useState<any>();
    const [modalShow, setModalShow] = useState<any>(false);

    /* Success Modal (Criar Session) */
    const [modalSuccess, setModalSuccess] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>();
    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();
    /* END - Success Modal (Criar Session) */

    /* FUNÇÕES */

    async function settleBills(row: any) {
        setSelectedSingleRow(row);  //recebe array vazio ([]) para liquidar consulta
        setModalShow(true);
    }

    function convertValue(cell: any) {
        //console.log(Math.sign(cell))
        return (
            <>
                <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
            </>
        )
    }

    function handleReload() {
        window.location.reload();
    }

    /*converte a data UTC para a data local do usuário*/
    function convertUTCDateToLocalDate(date: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString().split(' ')[0];
    }

    function convertDate(cell: any, row: any) {
        if (cell !== null) {
            //var date: any = convertUTCDateToLocalDate(new Date(cell));
            var aux: any = cell.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;

            return (
                <>
                    <div className="d-flex align-items-center" style={{ flexDirection: "column" }}>
                        <span>
                            {date}
                        </span>
                        <span>
                            {row.horaOperacao}
                        </span>
                    </div>
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    /* SOBRE A TABELA */

    function addActionButton(cell: any, row: any) {

        return (
            <>
                <div className="d-flex justify-content-center">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                row.tipo === "Tour"
                                    ?
                                    <Dropdown.Item
                                        eventKey="1"
                                        onClick={() => { window.open(`single-session?id=${cell}?operation=${row.operacao === "Própria" ? 1 : 2}?status=${row.status === "Pendente" ? 0 : row.status === "Em Operação" ? 1 : 2}`, '_blank') }}>
                                        <FontAwesomeIcon
                                            icon={['fal', 'edit']}
                                            className="mr-2"
                                        />
                                        Ver
                                    </Dropdown.Item>
                                    :
                                    row.tipo === "Transfer"
                                        ?
                                        <Dropdown.Item
                                            eventKey="1"
                                            onClick={() => { window.open(`single-session-transfer?id=${cell}`, '_blank') }}>
                                            <FontAwesomeIcon
                                                icon={['fal', 'edit']}
                                                className="mr-2"
                                            />
                                            Ver
                                        </Dropdown.Item>
                                        :
                                        <Dropdown.Item
                                            eventKey="1"
                                            onClick={() => { window.open(`single-session?id=${cell}`, '_blank') }}>
                                            <FontAwesomeIcon
                                                icon={['fal', 'edit']}
                                                className="mr-2"
                                            />
                                            Ver
                                        </Dropdown.Item>
                            }
                            <Dropdown.Item eventKey="2" disabled={true} className="text-danger remove"><FontAwesomeIcon icon={['fal', 'trash-alt']} className="mr-2" />  {t('suppliersTable.remove')}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    function addStatus(cell: any, row: any) {
        return (
            <div
                className={
                    cell === "Alocação Parcial"
                        ? "resumo bg-parcial w-100"
                        : cell === "Emitido"
                            ? "resumo bg-emitido"
                            : cell === "Pendente"
                                ? "resumo bg-pendente"
                                : cell === "Pendente Escala" || cell === "Pendente Aceite"
                                    ? "resumo bg-pendente-escala"
                                    : cell === "Confirmado"
                                        ? "resumo bg-emitido"
                                        : cell === "Em Operação"
                                            ? "resumo bg-emitido"
                                            : ""
                }
            >
                {cell}
                <div className="resumo-container">
                    <h6 className="">Resumo</h6>
                    <span>{row.horaOperacao} - {row.descricao}</span>
                    <div className="d-flex my-2">
                        {row.resourcesStatus?.length > 0
                            ? row.resourcesStatus.map((elem: any, index: Key) => {
                                if (elem.tipo === 1) {
                                    return (
                                        <>
                                            <p className={elem.status === 0 || elem.status === 1 ? "icon-inactive" : elem.status === 2 ? "icon-solicitado" : "icon-active"}>
                                                <FontAwesomeIcon
                                                    file-invoice
                                                    icon={["fal", "bus"]}
                                                    size="2x"
                                                    style={{ padding: "5px" }}
                                                />
                                            </p>
                                        </>
                                    )
                                } else if (elem.tipo === 2) {
                                    return (
                                        <>
                                            <p className={elem.status === 0 || elem.status === 1 ? "icon-inactive" : elem.status === 2 ? "icon-solicitado" : "icon-active"}>
                                                <FontAwesomeIcon
                                                    icon={["fal", "address-card"]}
                                                    size="2x"
                                                    style={{ padding: "5px" }}
                                                />
                                            </p>
                                        </>
                                    )
                                } else if (elem.tipo === 3) {
                                    return (
                                        <>
                                            <p className={elem.status === 0 || elem.status === 1 ? "icon-inactive" : elem.status === 2 ? "icon-solicitado" : "icon-active"}>
                                                <FontAwesomeIcon
                                                    icon={["fal", "users"]}
                                                    size="2x"
                                                    style={{ padding: "5px" }}
                                                />
                                            </p>
                                        </>
                                    )
                                } else if (elem.tipo === 4) {
                                    return (
                                        <>
                                            <p className={elem.status === 0 || elem.status === 1 ? "icon-inactive" : elem.status === 2 ? "icon-solicitado" : "icon-active"}>
                                                <FontAwesomeIcon
                                                    icon={["fal", "ticket-alt"]}
                                                    size="2x"
                                                    style={{ padding: "5px" }}
                                                />
                                            </p>
                                        </>
                                    )
                                } else if (elem.tipo === 5) {
                                    return (
                                        <>
                                            <p className={elem.status === 0 || elem.status === 1 ? "icon-inactive" : elem.status === 2 ? "icon-solicitado" : "icon-active"}>
                                                <FontAwesomeIcon
                                                    icon={["fal", "utensils"]}
                                                    size="2x"
                                                    style={{ padding: "5px" }}
                                                />
                                            </p>
                                        </>
                                    )
                                }
                            })
                            : <></>
                        }
                    </div>
                    <div className="table">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={row.items}
                            columns={columnsResumo}
                            //rowClasses={rowClasses}
                            striped={true}
                        />
                    </div>
                </div>
            </div>
        );
    }

    const columns = [
        {
            dataField: "dataOperacao",
            text: "Data Operação",
            formatter: convertDate
        },
        {
            dataField: "status",
            text: "Status",
            formatter: addStatus
        },
        {
            dataField: "tipo",
            text: "Tipo"
        },
        {
            dataField: "sessionReloc",
            text: "Session/Reloc"
        },
        {
            dataField: "operacao",
            text: "Operação"
        },
        {
            dataField: "descricao",
            text: "Tour/Descrição"
        },
        {
            dataField: "qtdePax",
            text: "Passageiros"
        },
        {
            dataField: "sessionReloc",
            text: "Ação",
            formatter: addActionButton
        },
    ];

    const columnsResumo = [
        {
            dataField: "localizador",
            text: "Localizador"
        },
        {
            dataField: "horario",
            text: "Hora"
        },
        {
            dataField: "nomePax",
            text: "Nome"
        },
        {
            dataField: "telefone",
            text: "Telefone"
        },
        {
            dataField: "localizacao",
            text: "Localização"
        }
    ];

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando Sessões", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "sessoes", text: "Sessões" }];

    const notFoundProducts = [{ sessoes: "Nenhuma sessão encontrada" }];
    /* [END] In case of empty table*/

    const rowClasses = (row: any, rowIndex: any) => {
        console.log(row, rowIndex)
        if (row.status === "Conciliado" || row.status === "Alocação Parcial") {
            return 'border-analise';
        } else if (row.status === "Confirmado" || row.status === "Emitido" || row.status === "Em Operação") {
            return 'border-ativo';
        } else if (row.status === "Pendente Escala" || row.status === "Pendente Aceite") {
            return 'border-pendente-escala';
        } else {
            return 'border-inativo';
        }
    };

    /* FORMULÁRIO */

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (modalShow === false) {
            reset({
                data: undefined
            })
        }
    }, [modalShow])

    const onSubmit = (data: any) => {
        //
    }

    /* RENDER */
    if (manifestObj !== null) {
        return (
            <>
                <div className="table-default manifest-table">
                    <div className="table-container">
                        <div>
                            <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                                <div>
                                    <a href="#" className="btn btn-bills" onClick={exportManifest}>
                                        <FontAwesomeIcon
                                            icon={["fal", "share"]}
                                            size="1x"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Exportar
                                    </a>
                                </div>
                                <div>
                                    <a href="#" className="btn btn-bills" onClick={printManifest}>
                                        <FontAwesomeIcon
                                            file-invoice
                                            icon={["fal", "print"]}
                                            size="1x"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Imprimir
                                    </a>
                                </div>
                                <div>
                                    <ModalDefault
                                        title="Criar Nova Session"
                                        name="Criar Session"
                                        icon="yes"
                                        typeIcon="plus-circle"
                                        classBtn="btn btn-bills"
                                        classModal="modal-large"
                                    >
                                        <CreateSession 
                                            show={setModalSuccess}
                                            setResponseText={setResponseText}
                                            setModalContent={setModalContent}
                                            setLog={setLog}
                                        />
                                    </ModalDefault>
                                </div>
                            </div>
                        </div>
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={manifestObj}
                            columns={columns}
                            rowClasses={rowClasses}
                        />
                        <Pagination
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            selectNumberRows="yes"
                        />

                        
                        {/* Modal de Success */}
                        <Modal
                            className={"modal-confirm loading-modal"}
                            show={modalSuccess}
                            onHide={() => setModalSuccess(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <>
                                <Modal.Body className='modal-body text-center sucess-pos'>
                                    {
                                        modalContent !== "loading"
                                            ?
                                            <>
                                                <div>
                                                    {
                                                        log === 1 || log === "1"
                                                            ?
                                                            <FontAwesomeIcon
                                                                icon={["fal", "times-circle"]}
                                                                size="5x"
                                                                style={{ fontSize: "7.5em", color: "#6731FF" }}
                                                            />
                                                            :
                                                            log === 0 || log === "0"
                                                                ?
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "check-circle"]}
                                                                    size="5x"
                                                                    style={{ fontSize: "7.5em", color: "#6731FF" }}
                                                                />
                                                                :
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "question-circle"]}
                                                                    size="5x"
                                                                    style={{ fontSize: "7.5em", color: "#6731FF" }}
                                                                />
                                                    }
                                                </div>
                                                <div>
                                                    {responseText}
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    {
                                                        modalContent === 'success'
                                                            ?
                                                            <button
                                                                onClick={handleReload}
                                                                className="btn btn-primary mx-2 w-25"
                                                            >
                                                                Confirmar
                                                            </button>
                                                            :
                                                            <button
                                                                onClick={() => setModalShow(false)}
                                                                className="btn btn-primary mx-2 w-25"
                                                            >
                                                                Confirmar
                                                            </button>
                                                    }
                                                </div>
                                            </>
                                            :
                                            <div className="modal-body">
                                                <div className="text-center">
                                                    <div className="load"></div>
                                                </div>
                                                <div>
                                                    <span>Processando</span>
                                                </div>
                                                <div></div>
                                            </div>
                                    }
                                </Modal.Body>
                            </>
                        </Modal>
                        {/* END - Modal de Success */}
                    </div>
                </div>
            </>
        );
    } else if (manifestObj === null && loading === true) {
        return (
            <>
                <div className="table-default manifest-table loading not-found">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default manifest-table not-found">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default TableManifest;
