/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';

//import SingleCalendar from '../../../../components/Calendar/SingleCalendar';
import RangeCalendar from '../../../../components/Calendar/RangeCalendar';
import GetPromoters from '../../../../components/C2Points/GetPromoters';
//import GetAffiliateClient from '../../../../components/C2Points/GetAffiliateClient';
import GetProductsListCod from '../../../../components/C2Points/GetProductsListCod';
//import GetAfiliatesType from '../../../../components/C2Points/GetAfiliatesType';
import GetCaixaBilheteria from '../../../../components/C2Points/GetCaixaBilheteria';
import GetOperadoresCaixa from '../../../../components/C2Points/GetOperadoresCaixa';
//import GetAllSource from '../../../../components/C2Points/GetAllSource';

export interface propFilter {
    buscar: any;
    setPageCount: any;
}

const FilterSalesReport: React.FC<propFilter> = ({
    buscar, setPageCount
}) => {
    const { t } = useTranslation();

    const [allowLoadFromFilter, setAllowLoadFromFilter] = useState<any>(false);

    const [filterType, setFilterType] = useState<any>(0);
    const [filterTypeDate, setFilterTypeDate] = useState<any>(1);
    const [filterDate, setFilterDate] = useState<any>([]);
    const [filterReservationStatus, setFilterReservationStatus] = useState<any>(1);
    const [filterPaymentStatus, setFilterPaymentStatus] = useState<any>(0);
    const [filterChannel, setFilterChannel] = useState<any>(0);
    const [filterSource, setFilterSource] = useState<any>('');
    const [filterCaixa, setFilterCaixa] = useState<any>('');
    const [filterOperator, setFilterOperator] = useState<any>('');


    /* const [filterPaymentStatus, setFilterPaymentStatus] = useState<any>(0);
    const [filterPaymentStatus, setFilterPaymentStatus] = useState<any>(0);
    const [filterPaymentStatus, setFilterPaymentStatus] = useState<any>(0); */
    const [filterBy, setFilterBy] = useState<any>(2);

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const handleClearClick = () => {
        setPageCount(1);

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if (selectValue[i].id === "rowsPerPage") {
                selectValue[i].value = "10";
            } else {
                selectValue[i].value = "";
            }
        }

        var today: any = new Date();

        setFilterType(0);
        setFilterTypeDate(1);
        setFilterDate([]/* [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear() - 1}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`] */);
        setFilterReservationStatus(1);
        setFilterPaymentStatus(0);
        setFilterSource('');
        setFilterCaixa("");
        setFilterOperator("");
        setFilterBy('');
        setValue('promoter', '');
        setValue('affiliate', '');
        setValue('user', '');
        setValue('product', '');
        buscar(0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`], 0, 0, 0, '', '', '', '', '', '', '', '');
        //clearActualValues();
    };

    const onSubmit = (data: any) => {
        setPageCount(1);

        data.promoter = data.promoter?.id || '';
        data.affiliate = data.affiliate?.id || '';
        data.product = data.product?.code || '';
        data.user = data.user || '';

        let caixa: string = filterSource === 'bilheteria' ? data.codigoCaixa : undefined;
        let operador: string = filterSource === 'bilheteria' ? data.uidOperator : undefined;

        buscar(filterType, filterTypeDate, filterDate, filterReservationStatus, filterPaymentStatus, filterChannel, data.promoter, data.affiliate, data.user, data.product, filterBy, filterSource, caixa, operador);
    }

    const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterType(newValue);
    }

    const handleChangeTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeDate(newValue);
    }

    const handleChangeReservationStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterReservationStatus(newValue);
    }

    const handleChangePaymentStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterPaymentStatus(newValue);
    }

    const handleChangeFilterBy = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterBy(newValue);
    }

    const handleChangeSource = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterSource(newValue);
    }

    return (
        <>
            <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Relatórios</span></Accordion.Header>
                        <Accordion.Body className="pt-0">
                            <Container className="p-0" fluid>
                                <Row>
                                    <div>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>Tipo</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeType(e)}
                                                                value={filterType}
                                                            >
                                                                <option value="0" selected>Todos</option>
                                                                <option value="1">Tour</option>
                                                                <option value="4">Ticket</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>Tipo de Data</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeTypeDate(e)}
                                                                value={filterTypeDate}
                                                            >
                                                                <option value="1" selected>Data da Reserva</option>
                                                                <option value="2">Data da Operação</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>Data</Form.Label>
                                                    <InputGroup hasValidation className="ranger-calendar">
                                                        <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                    </InputGroup>
                                                </Form.Group>

                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>Status da Reserva</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeReservationStatus(e)}
                                                            >
                                                                <option value="0">Todos</option>
                                                                <option selected value="1">Confirmado</option>
                                                                <option value="2">Cancelado</option>
                                                                <option value="7">Pre Reserva</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>Status da Pagamento</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangePaymentStatus(e)}
                                                            >
                                                                <option value="">Todos</option>
                                                                <option value="1">Pagos</option>
                                                                <option value="2">Pendentes</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>Filtrar por:</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeFilterBy(e)}
                                                            >
                                                                <option value="3">Dia</option>
                                                                <option value="" selected>Mês</option>
                                                                <option value="1">Ano</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                {/* <Form.Group as={Col} md="2" controlId="validationCustom01" className='mb-3'>
                                                    <Form.Label>Usuário:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="user"
                                                        rules={{ required: { value: false, message: 'Por favor, informe o nome.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.user ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                            />
                                                        )}
                                                    />
                                                </Form.Group> */}
                                                {/* <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label>Promotor:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="promoter"
                                                        rules={{ required: { value: false, message: 'Por favor, informe o nome.' } }}
                                                        render={({ field }: any) => (
                                                            <div className="input-select-custom">
                                                                <GetPromoters
                                                                    propsField={field}
                                                                    propsErrors={errors}
                                                                    propsLabel={t('accordionInfoCompany.promo')}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </Form.Group> */}
                                                {/*  <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label>Canal:</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                            //onChange={(e: any) => handleChangeChannel(e)}
                                                            >
                                                                <option value='0' selected>Todos</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group> */}
                                            </Row>
                                            <Row className='mb-3'>
                                                {/* <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label>Afiliado/Cliente:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="affiliate"
                                                        rules={{ required: { value: false, message: 'Por favor, informe o nome.' } }}
                                                        render={({ field }: any) => (
                                                            <div className="input-select-custom">
                                                                <GetAfiliatesType
                                                                    propsField={field}
                                                                    propsErrors={errors}
                                                                    propsLabel="supplierId"
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </Form.Group> */}
                                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                    <Form.Label>Produto:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="product"
                                                        rules={{ required: { value: false, message: 'Por favor, informe o nome.' } }}
                                                        render={({ field }: any) => (
                                                            <div className="input-select-custom">
                                                                <GetProductsListCod
                                                                    propsField={field}
                                                                    propsErrors={errors}
                                                                    propsLabel="valueCommission"
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label>Canal:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="source"
                                                        rules={{ required: { value: false, message: 'Por favor, informe o nome.' } }}
                                                        render={({ field }: any) => (
                                                            <div className='endpoint'>
                                                                <Form.Select
                                                                    {...field}
                                                                    label="Canal"
                                                                    as="select"
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    onChange={(e: any) => handleChangeSource(e)}
                                                                >
                                                                    <option value='' selected>Todos</option>
                                                                    <option value='site'>SITE</option>
                                                                    <option value='sig'>SIG</option>
                                                                    <option value='totem'>TOTEM</option>
                                                                    <option value='intranet'>INTRANET</option>
                                                                    <option value='bilheteria'>BILHETERIA</option>
                                                                    <option value='selling_platform'>SELLING PLATFORM</option>
                                                                </Form.Select>
                                                            </div>
                                                        )}
                                                    />
                                                </Form.Group>


                                                {filterSource === 'bilheteria'
                                                    ?
                                                    <>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Caixa</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="codigoCaixa"
                                                                render={({ field }: any) => (
                                                                    <div className='endpoint custom-select'>
                                                                        <GetCaixaBilheteria propsField={field} />
                                                                    </div>
                                                                )}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Operador</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="uidOperator"
                                                                render={({ field }: any) => (
                                                                    <div className='endpoint custom-select'>
                                                                        <GetOperadoresCaixa propsField={field} />
                                                                    </div>
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                    :
                                                    <>

                                                    </>
                                                }
                                            </Row>
                                            <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                                <Col xs lg="12" className="d-flex justify-content-md-end">
                                                    <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={() => { handleClearClick(); setAllowLoadFromFilter(false) }}>{t('partners.partnersfilter.btnClear')}</Button>
                                                    <Button type="submit" className="btn-default text-uppercase" onClick={() => { }}>{allowLoadFromFilter === true ? <p className="load"></p> : t('partners.partnersfilter.btnSearch')}</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}

export default FilterSalesReport;