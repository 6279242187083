import React, { Key, useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  getChangeFeeTermType: any,
  defaultValue: any
};

const GetFeeTermTypes: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, getChangeFeeTermType, defaultValue
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [defaultChecked, setDefaultChecked] = useState<string>(defaultValue ? defaultValue : "1");

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    }else{
      setIsMenuOpen(false);
    }    
  }

  const changeCallBack = (e: any): any => {
    getChangeFeeTermType(e.target.value);
    //console.log(e.target.value);
    setDefaultChecked(e.target.value);
  }

  useEffect(() => {
    // const token = localStorage.getItem('GroupId') || '{}';
    // const config = {
    //   headers: { 'Authorization': `Bearer ${token}` },
    // };
    async function listAccountTypes() {
      try {
        //const { data } = await api.get(`/DropDown/GetAccountingAccounts`, config);
        //if (data.status !== 400) {
          const dados = [
            {id:1,descricao:'Crédito'},
            {id:2,descricao:'Débito'},
            {id:3,descricao:'PIX'}
          ];
            setInfo(dados)
          /* console.log(data); */
        //}
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountTypes();
  }, []);

  if (info !== null) {
    return (
      <>
      <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
          <Form.Select
            {...propsField}
            aria-invalid={propsErrors?.tipo ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            onChange={changeCallBack}
            value={defaultChecked}
            required
          >
            <option value=''>Selecione</option>
            {info.length > 0 ? info.map((info: any, index: Key) => (
              <option key={index} value={info.id}>{info.descricao}</option>
            )) : ''}
          </Form.Select>
        </div>       
    </>
    );
  } else {
    return(
      <>        
        <Form.Select>
          <option value='' disabled></option>          
        </Form.Select>
      </>
    )
    
  }
}

export default GetFeeTermTypes;