import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";

import api from "../../services/api";
import configData from "../../config/config.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Calendar.scss";

const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
];

const numberMonths = window.innerWidth < 480 ? 1 : 2;

export interface productInfo {
    modalityID: any;
    product: any;
    productCode: any;
    changePriceDateTour: any;
    index: any;
    dateTour: any;
    startTimeLocal?: any;
    item?: any
}

const CustomMultipleInput = ({ openCalendar, value, modalityID, valueCalendar, startTimeLocal }: any) => {
    if (startTimeLocal) {
        if (valueCalendar.data === undefined) {
            var teste: any = document.getElementById(`date-${modalityID}`);
            if (teste !== null) {
                /* let receiveDate = startTimeLocal.props.children.split("/").reverse().join("-");
                let data = new Date(receiveDate);
                data.setDate(data.getDate()); */
                value = startTimeLocal.props.children;
            }
        }
    }

    return (
        <div className="d-flex">
            <FontAwesomeIcon
                icon={["fal", "calendar-alt"]}
                size="1x"
                style={{ color: "#000", position: "absolute", transform: "translate(10px, 10px)" }}
            />
            <input
                className={`form-control rounded-0 bg-white py-2 rounded calendar-input date-${modalityID}`}
                onFocus={openCalendar}
                value={value}
                data-object={JSON.stringify(valueCalendar)}
                readOnly
                id={`date-${modalityID}`}
                placeholder="Quando?"
                autoComplete="off"
            />
        </div>
    );
};

const DoubleCalendar: React.FC<productInfo> = ({
    modalityID,
    product,
    productCode,
    changePriceDateTour,
    dateTour,
    index,
    startTimeLocal = undefined,
    item
}: productInfo) => {
    const [dayProduct, setDayProduct] = React.useState<any>({});
    const [valueCalendar, setValueCalendar] = useState<any>({});

    console.log(item)

    function handleDateTour(objCalendar: any) {
        var aux: any = dateTour;
        aux[index].data = objCalendar.data;
        aux[index].idSellingType = objCalendar.idSellingType;
        aux[index].adult = objCalendar.priceAdultFinal;
        aux[index].child = objCalendar.priceChildFinal;
        aux[index].infant = objCalendar.priceInfantFinal;
        aux[index].student = null;
        aux[index].elders = null;
        aux[index].global = objCalendar.priceGlobalFinal;
        changePriceDateTour(aux);
    }

    useEffect(() => {
        let idChannel: number = 1;
        async function getDays() {

            var today: any = new Date();
            var endMonth: any = (today.getMonth() + 7);
            var endYear: any = today.getFullYear();

            if (endMonth > 11) {
                endMonth = endMonth - 11;
                endYear = parseInt(endYear) + 1;
            }

            try {
                const { data } = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/GetCalendarTourAsync`,
                    {
                        dateStart: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
                        dateEnd: endYear + '-' + endMonth + '-' + today.getDate(),
                        productCode: `${productCode}`,
                        tarCode: `${product.tarif.tarCode}`,
                        ProdModCode: `${product.prodModCode}`,
                        idCanal: idChannel,
                        newProduct: item.idCanal === 0 ? 1 : 0
                    }
                );
                if (data.status !== 400) {
                    data.data.dates.forEach((dayProduct: any) => {
                        let year = dayProduct.selectedDate.split("-")[0];
                        let month = dayProduct.selectedDate.split("-")[1];
                        let day = dayProduct.selectedDate.split("-")[2];

                        let controlObject: { [x: number]: { price: any; data: any, idTarif: any, priceAdultFinal: any, priceChildFinal: any, priceGlobalFinal: any, priceInfantFinal: any, idSellingType: any, } };
                        let sControl: any = year + "-" + month + "-" + day.split("T")[0];
                        controlObject = {
                            [sControl]: {
                                price:
                                    dayProduct.priceAdultFinal === 0.0
                                        ? dayProduct.priceGlobalFinal
                                        : dayProduct.priceAdultFinal,
                                idTarif: dayProduct.idTarifario,
                                priceAdultFinal: dayProduct.priceAdultFinal,
                                priceChildFinal: dayProduct.priceChildFinal,
                                priceInfantFinal: dayProduct.priceInfantFinal,
                                priceGlobalFinal: dayProduct.priceGlobalFinal,
                                idSellingType: dayProduct.idSellingType,
                                data: year + "-" + month + "-" + day.split("T")[0],
                            },
                        };
                        setDayProduct((curObjDeal: any) => ({
                            ...curObjDeal,
                            ...controlObject,
                        }));
                    });
                }
            } catch (error: any) {

                console.log(error)
            }
        }

        async function getIdChannel() {
            const config = {
                headers: { "ngrok-skip-browser-warning": "69420" },
            };
            
            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/${item.source}`, config);
                if (data.statusCode === 200) {
                    idChannel = data.data.data;
                    getDays();
                }
            } catch (error) { }
        }
        getIdChannel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (dayProduct !== null) {
        return (
            <DatePicker
                weekDays={weekDays}
                numberOfMonths={numberMonths}
                disableMonthPicker
                disableYearPicker
                months={months}
                format={configData.DATE.PT}
                render={<CustomMultipleInput modalityID={modalityID} valueCalendar={valueCalendar} startTimeLocal={startTimeLocal} />}
                className="rmdp-mobile"
                mapDays={({ date }): any => {
                    let controlNumber =
                        JSON.stringify(date.day).length === 1 ? "0" + date.day : date.day;
                    let controlMonth =
                        JSON.stringify(date.month.number).length === 1
                            ? "0" + date.month.number
                            : date.month.number;
                    let controlYear = date.year;

                    const objCalendar =
                        dayProduct[controlYear + "-" + controlMonth + "-" + controlNumber];
                    if (objCalendar !== undefined && objCalendar.price !== "null") {
                        /* console.log(dayProduct[controlYear + "-" + controlMonth + "-" + controlNumber]) */
                        if (
                            controlYear + "-" + controlMonth + "-" + controlNumber ===
                            objCalendar.data
                        ) {
                            return {
                                children: (
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            fontSize: "14px",
                                            height: "100%",
                                            justifyContent: "center"
                                        }}
                                        onClick={() => { setValueCalendar(objCalendar); handleDateTour(objCalendar) }}
                                    >
                                        <div style={{ textAlign: "center" }}>
                                            {date.format("D")}
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "9px",
                                                minHeight: "14px",
                                            }}
                                        >
                                            {" "}
                                            {objCalendar !== undefined
                                                ? objCalendar.price !== "null"
                                                    ? `R$ ${objCalendar.price
                                                        .toFixed(2)
                                                        .replace(".", ",")}`
                                                    : ""
                                                : ""}
                                        </div>
                                    </div>
                                ),
                            };
                        } else {
                            return {
                                disabled: true,
                            };
                        }
                    } else {
                        return {
                            disabled: true,
                        };
                    }
                }}
            ></DatePicker>
        );
    } else {
        return <></>;
    }
};

export default DoubleCalendar;
