import React, { Key, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../../../assets/sass/modal.scss";
import "./FormResourceVoucherViewer.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

export interface propModal {
  action: any,
  voucher: any, 
};

const FormResourceVoucherViewer: React.FC<propModal> = ({
  action, voucher
}: propModal, props: any) => {
  const { t } = useTranslation();
  //const [tipo, setTipo] = useState<string>(tipo);

  const onSubmitContact = (dataObj: any) => {
    action(dataObj);
  };

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <h5 className="contact-title mb-5">Visualizar Voucher</h5>
            <Form
              className="form-add-contact"
              noValidate
            >
              <div className="">
                <Row className="">
                  <img 
                    className="mx-auto" 
                    src={voucher} 
                  />
                </Row>
              </div>
            </Form>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {/* <Button className="form-button mr-3" onClick={props.onHide}>
            Cancelar
          </Button> */}
          <Button
            type="button"
            className="form-button"
            onClick={onSubmitContact}
          >
            Confirmar
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormResourceVoucherViewer;
