/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import { Accordion, Row, Col, Form, InputGroup, Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DoubleCalendarTicket from "../../../../components/Calendar/DoubleCalendarTicket5DaysBefore";
//import DropdownNumberPeople from "../../../../components/DropdownNumberPeople/DropdownNumberPeople";
import DropdownNumberPeopleIntegrated from "../../../../components/DropdownNumberPeopleIntegrated/DropdownNumberPeopleIntegrated";

import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { addItemCart } from "../../../../store/cart";
import { useTranslation } from "react-i18next";
import DropdownHours from "../../../../components/DropdownHours/DropdownHours";
import RefundRules from "../RefundRules/RefundRules";

export interface tourDetails {
    productCode: any;
}

const AccordionTicket = ({ productCode }: any) => {
    const authLogin: any = JSON.parse(localStorage.getItem("bpaSigAuth") || "{}");
    const idCanal: any = authLogin?.user?.perfil?.idCanal;

    const { t } = useTranslation();

    function ScrollTop() {
        window.scrollTo(0, 0);
    }

    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
    //const [allowCalendar, setAllowCalendar] = useState<any>(false);

    const [tourDetails, setTourDetails] = useState<any>(null);

    const [numberPeople, setNumberPeople] = useState<any>(null);
    const [dateTour, setDateTour] = useState<any>([]);

    const [indexador, setIndexador] = useState<any>([]); ///array que me diz quais dos tourOptions vai habilitar o dropdown
    const [productHours, setProductHours] = useState<any>([]);
    const [hour, setHour] = useState<any>(null);
    const [, setTariffs] = useState<any>({
        maxNumPeople: "0",

        minNumPeople: "0",
    });

    const cartSIG = useAppSelector((state) => state.cart);

    const [, setLoading] = useState<any>(false);

    const [isIntegration, setIsIntegration] = useState<any>(false);
    const [preloader, setPreloader] = useState<boolean>(false);

    const [ranges, setRanges] = useState<any>(null);

    const [peopleDisponible, setPeopleDisponible] = useState<boolean>(false);
    const [numberTotal, setNumberTotal] = useState<number>(0);

    const dispatch = useAppDispatch();

    const getTourInfo = async (productCode: any, idChannel: number) => {
        try {
            const data = await api.post(`/Products/FetchSingleTicketAsync`, {
                productCode: productCode,
                lang: "BR",
                IdCanal: idChannel
            });
            if (data.status !== 400) {
                setTourDetails(data.data.data);
            }
        } catch (error) { }
    };

    function activeCalendar() {
        if (accordionOpen === false) {
            setAccordionOpen(true);
            getTourInfo(productCode, idCanal);
        } else {
            setAccordionOpen(false);
        }
    }

    function changePriceDateTour(obj: any, hours: any) {
        setDateTour(obj);
        setProductHours(hours);
        setNumberPeople({
            adults: "1",
            childs: "0",
            infants: "0",
            elders: "0",
            student: "0",
            globalPeople: "1",
        });
    }

    function addIndex(index: any) {
        var aux: any = indexador;
        if (aux.includes(index) === false) {
            aux.push(index);
        }
        setIndexador(aux);
    }

    useEffect(() => {
        if (tourDetails !== null) {
            var aux: any = [];
            for (var i = 0; i < tourDetails.modalities.length; i++) {
                aux.push({
                    adult: 0,
                    child: 0,
                    infant: 0,
                });
            }
            setDateTour(aux);
        }
    }, [tourDetails]);

    const setNewNumberPeople = (peoples: any) => {
        setNumberPeople(peoples);
    };

    const selectHours = (e: any) => {
        setHour(e.target.value);
        setTariffs({
            maxNumPeople: e.target.options[e.target.selectedIndex].dataset.capacity,
            minNumPeople: 1,
        });

        setPeopleDisponible(true);
    };

    function keepDropdownOpen() {
        var elem: any = document.getElementById("authCartDropdown");

        if (elem && !elem.classList.contains("active")) {
            elem.classList.add("active");
        }
    }

    const addToCart = (itemOption: any, index: any) => {
        console.log(itemOption.idSellingType);
        
        const item = {
            productName: itemOption.productName,
            productNameEN: itemOption.productNameEN,
            productNameES: itemOption.productNameES,
            modalityName: itemOption.modalityName,
            imgCart: tourDetails.images[0],
            imagesBaseUrl: tourDetails.imagesBaseUrl,
            typeProduct: tourDetails.typeProduct,
            price: itemOption?.tarif?.price,

            idTarif: itemOption.idTarif,
            idPickup: itemOption.idPickup,

            priceAdults: itemOption.priceAdults,
            priceChilds: itemOption.priceChilds,
            priceInfants: itemOption.priceInfants,
            priceElders: itemOption.priceElders,
            priceStudent: itemOption.priceStudent,
            priceGlobalPeople: itemOption.priceGlobalPeople || 0,

            sellingType: itemOption.idSellingType,
            adults: itemOption.adults,
            childs: itemOption.childs,
            infants: itemOption.infants,
            elders: itemOption.elders,
            student: itemOption.student,

            globalPeople: itemOption.globalPeople,
            totalPeople: itemOption.totalPeople,

            productType: 4,
            productCode: productCode,
            time: itemOption.time === null ? "00:00:00" : itemOption.time,
            date: itemOption.date,
            supplier: tourDetails.supplier,
            supplierFantasyName: tourDetails.supplierFantasyName,
            typePickup: itemOption.embarkingType,
            meetingPoint:
                itemOption.embarkingType === "0" ? itemOption.meetingPoint : "",
            pickupListId:
                itemOption.embarkingType === "0" ? 0 : itemOption.pickupListId,
            pickup: 0,

            discount: 0,
            customValueNet: 0,
            customValueSell: 0,
            goingSource: "null",
            commingSource: "null",
            latOrigem: "null",
            lngOrigem: "null",
            latDestino: "null",
            lngDestino: "null",
            cia: "null",
            voo: "null",
            smallSuitcase: 0,
            bigSuitcase: 0,
            internalNotes: " ",
            idVeiculo: 0,
            passengers: [],
        };
        dispatch(addItemCart(item));
    };

    const auth = (option: any, index: any) => {
        if (numberPeople === null || dateTour.length < 1) {
            alert(t("sales.tourList.accordion.fillUpAll"));
        } else {
            option.productName = tourDetails.productName;
            option.productNameEN = tourDetails.productNameEN;
            option.productNameES = tourDetails.productNameES;
            option.adults = numberPeople.adults;
            option.childs = numberPeople.childs;
            option.infants = numberPeople.infants;
            option.elders = numberPeople.elders;
            option.student = numberPeople.student;
            option.globalPeople = dateTour[index].idSellingType === 1 ? 1 : numberPeople.adults + numberPeople.childs + numberPeople.infants + numberPeople.elders + numberPeople.student + numberPeople.globalPeople;
            option.totalPeople = dateTour[index].idSellingType === 1 ? numberPeople.globalPeople : undefined;

            option.date = (
                document.getElementById(`date-${option.id}`) as HTMLInputElement
            ).value
                .split("/")
                .reverse()
                .join("-");
            option.time = isIntegration === true ? hour : dateTour[index].time;
            const selectedDate = JSON.parse(
                (
                    document.getElementsByClassName(
                        `date-${option.id}`
                    )[0] as HTMLInputElement
                ).dataset.object || "{}"
            );
            option.idTarif = selectedDate.idTarif;

            option.priceAdults = dateTour[index].adult || 0;
            option.priceChilds = dateTour[index].child || 0;
            option.priceInfants = dateTour[index].infant || 0;
            option.priceElders = dateTour[index].elders || 0;
            option.priceStudent = dateTour[index].student || 0;
            option.idSellingType = dateTour[index].idSellingType;

            if (option.date !== "") {
                var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

                cartSIG.dados.forEach((elem: any) => {
                    if (elem.productCode === tourDetails.productCode) {
                        repeatedItem = true;
                    }
                });

                if (repeatedItem === true) {
                    alert(t("sales.tourList.accordion.productExists"));
                } else {
                    addToCart(option, index);
                    ScrollTop();
                    keepDropdownOpen();
                }
            } else {
                alert(t("sales.tourList.accordion.fillUpAll"));
            }
        }
    };

    return (
        <div className="prod-options">
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header onClick={activeCalendar}>
                        {t("sales.tourList.accordion.options")}
                    </Accordion.Header>
                    {tourDetails !== null ? (
                        tourDetails.modalities.map((tour: any, index: any) => {
                            console.log(dateTour[index])
                            return (
                                <Accordion.Body key={index}>
                                    <Row>
                                        <div className="col-12 col-md-9">
                                            <div className="d-flex flex-column justify-content-between h-100">
                                                <div>
                                                    <h4 className="tour-name">
                                                        {tour.modalityName}
                                                        {tour.rangeComissionLabel !== null &&
                                                            tour.rangeComissionLabel !== "DEFAULT" ? (
                                                            <span className="rangecomission">
                                                                <Badge
                                                                    className={tour.rangeComissionLabel
                                                                        .toString()
                                                                        .toLowerCase()}
                                                                ></Badge>{" "}
                                                                {tour.rangeComissionLabel}
                                                            </span>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </h4>
                                                    <div className="sub-info">
                                                        <div className="d-flex flex-wrap mt-2">
                                                            <div translate="no" style={{ height: 'unset' }} className={tour.daysOfWeek.dom === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.sun")}</div>
                                                            <div translate="no" style={{ height: 'unset' }} className={tour.daysOfWeek.seg === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.mon")}</div>
                                                            <div translate="no" style={{ height: 'unset' }} className={tour.daysOfWeek.ter === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.tue")}</div>
                                                            <div translate="no" style={{ height: 'unset' }} className={tour.daysOfWeek.qua === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.wed")}</div>
                                                            <div translate="no" style={{ height: 'unset' }} className={tour.daysOfWeek.qui === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.thu")}</div>
                                                            <div translate="no" style={{ height: 'unset' }} className={tour.daysOfWeek.sex === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.fri")}</div>
                                                            <div translate="no" style={{ height: 'unset' }} className={tour.daysOfWeek.sab === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.sat")}</div>
                                                        </div>
                                                    </div>
                                                    <div className="sub-info">
                                                        <small className="bg-sale-time">
                                                            {
                                                                tour.activityStart === undefined || tour.activityStart === null
                                                                    ?
                                                                    ''
                                                                    :
                                                                    <span>
                                                                        <FontAwesomeIcon
                                                                            icon={["fal", "clock"]}
                                                                            size="1x"
                                                                            className="mr-2"
                                                                        />
                                                                        {`${t("sales.tourList.accordion.tourStart")} ${tour.activityStart}`}
                                                                    </span>
                                                            }
                                                            <span>
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "clock"]}
                                                                    size="1x"
                                                                    className="mr-2"
                                                                />
                                                                {`${t("sales.tourList.accordion.duration")} ${tour.activityDurationHours >= 9 ? `${tour.activityDurationHour}` : `0${tour.activityDurationHours}`}:${tour.activityDurationMinutes >= 9 ? `${tour.activityDurationMinutes}` : `0${tour.activityDurationMinutes}`}`}
                                                            </span>
                                                            <span>
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "clock"]}
                                                                    size="1x"
                                                                    className="mr-2"
                                                                />
                                                                {`${t("sales.tourList.accordion.advancedPurchase")} ${tour.tarif.buyingAdvance} minutos`}
                                                            </span>
                                                        </small>
                                                    </div>
                                                    <Row className="my-2">
                                                        <div className="col-12 col-md-6">
                                                            <h5 className="tour-name">{t("sales.tourList.accordion.includes")}</h5>
                                                            <div className="session-not-yes yes">
                                                                <small>
                                                                    {tour.includedItems.map(
                                                                        (text: any, index: any) => {
                                                                            return (
                                                                                <p key={index}>
                                                                                    <FontAwesomeIcon
                                                                                        icon={["fal", "check"]}
                                                                                        size="1x"
                                                                                    />
                                                                                    {text.replace("•	", "")}
                                                                                </p>
                                                                            );
                                                                        }
                                                                    )}
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <h5 className="tour-name">{t("sales.tourList.accordion.notIncludes")}</h5>
                                                            <div className="session-not-yes not">
                                                                <small>
                                                                    {tour.excludedItems.map(
                                                                        (text: any, index: any) => {
                                                                            return (
                                                                                <p key={index}>
                                                                                    <FontAwesomeIcon
                                                                                        icon={["fal", "times"]}
                                                                                        size="1x"
                                                                                    />
                                                                                    {text.replace("•	", "")}
                                                                                </p>
                                                                            );
                                                                        }
                                                                    )}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                                <div className="bg-cancel-rules">
                                                    <RefundRules tourDetails={tour} index={index} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 m-auto text-center d-flex justify-content-end">
                                            <div style={{ maxWidth: "300px", width: "100%" }}>
                                                <span className="small text-muted w-50">
                                                    {t("sales.tourList.accordion.pricePerAdult")} <br /> {t("sales.tourList.accordion.from")}
                                                </span>
                                                <div className="price price-add">
                                                    <span className="h4">
                                                        R${" "}
                                                        {tour.tarif === null
                                                            ? "??"
                                                            : tour.tarif.price.toFixed(2).replace(".", ",")}
                                                    </span>
                                                </div>
                                                <div className="bg-accordion-options">
                                                    <Form>
                                                        <Form.Group as={Col}>
                                                            <InputGroup
                                                                hasValidation
                                                                className=""
                                                                placeholder="Quando?"
                                                                id="date-"
                                                                onClick={() => {
                                                                    addIndex(index);
                                                                }}
                                                            >
                                                                <DoubleCalendarTicket
                                                                    modalityID={tour.id}
                                                                    product={tour}
                                                                    productCode={productCode}
                                                                    changePriceDateTour={changePriceDateTour}
                                                                    dateTour={dateTour}
                                                                    index={index}
                                                                    setLoading={setLoading}
                                                                    setIsIntegration={setIsIntegration}
                                                                    setPreloader={setPreloader}
                                                                    setRanges={setRanges}
                                                                    setPeopleDisponible={setPeopleDisponible}
                                                                ></DoubleCalendarTicket>
                                                            </InputGroup>
                                                        </Form.Group>

                                                        {
                                                            isIntegration !== false
                                                                ?
                                                                <div>
                                                                    {numberPeople !== null &&
                                                                        indexador.includes(index) === true ? (
                                                                        preloader === false ? (
                                                                            <select
                                                                                className={
                                                                                    productHours.length > 0
                                                                                        ? productHours[0].startTime === null
                                                                                            ? "d-none"
                                                                                            : "panel-dropdown bg-white w-100"
                                                                                        : "d-none"
                                                                                }
                                                                                onChange={(e) => selectHours(e)}
                                                                            >
                                                                                <option value={"null"} disabled selected>
                                                                                    {t("sales.tourList.accordion.select")}
                                                                                </option>
                                                                                {productHours.map((x: any, index: any) => {
                                                                                    return (
                                                                                        <>
                                                                                            {x.capacity === 0 ? (
                                                                                                <></>
                                                                                            ) : (
                                                                                                <option
                                                                                                    key={index}
                                                                                                    value={x.startTime}
                                                                                                    data-capacity={x.capacity}
                                                                                                >
                                                                                                    {`${x.startTime} - ${x.capacity} ${t("sales.tourList.accordion.vacancies")}`}
                                                                                                </option>
                                                                                            )}
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        ) : (
                                                                            <div className="panel-dropdown bg-white d-flex">
                                                                                <div style={{ height: "20px" }}>
                                                                                    <div className="load"></div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    ) : (
                                                                        <div className="panel-dropdown bg-white d-flex">
                                                                            <div style={{ height: "20px" }}></div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                :
                                                                <div>
                                                                    {numberPeople !== null &&
                                                                        indexador?.includes(index) === true ? (
                                                                        preloader === false ? (
                                                                            <DropdownHours
                                                                                changePriceDateTour={changePriceDateTour}
                                                                                dateTour={dateTour}
                                                                                index={index}
                                                                                setPeopleDisponible={setPeopleDisponible}
                                                                                info={tourDetails}
                                                                            />
                                                                        ) : (
                                                                            <div className="panel-dropdown bg-white d-flex">
                                                                                <div style={{ height: "20px" }}>
                                                                                    <div className="load"></div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    ) : (
                                                                        <div className="panel-dropdown bg-white d-flex">
                                                                            <div style={{ height: "20px" }}></div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                        }
                                                        <div className="col-12 my-2">
                                                            <div className="panel-dropdown bg-white">
                                                                {
                                                                    peopleDisponible === true
                                                                        ?
                                                                        <DropdownNumberPeopleIntegrated
                                                                            actionPeople={setNewNumberPeople}
                                                                            info={tourDetails}
                                                                            dateTour={dateTour}
                                                                            index={index}
                                                                            ranges={ranges}
                                                                            isIntegration={isIntegration}
                                                                        />
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="btn btn-options btn-primary btn-block btn-add-cart link-add"
                                                            onClick={() => auth(tour, index)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={["fal", "cart-arrow-down"]}
                                                                size="1x"
                                                                className="mr-2"
                                                            />
                                                            {t("sales.tourList.accordion.add")}
                                                        </span>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </Accordion.Body>
                            );
                        })
                    ) : (
                        <Accordion.Body>
                            <Row>
                                <div className="col-12 col-md-9">
                                    <div
                                        className="animated-background"
                                        style={{ height: "35px", width: "100%" }}
                                    ></div>
                                    <div
                                        className="animated-background my-2"
                                        style={{ height: "25px", width: "100%" }}
                                    ></div>
                                    <Row className="my-2">
                                        <div className="col-12 col-md-6">
                                            <div
                                                className="animated-background mt-2"
                                                style={{ height: "30px", width: "100%" }}
                                            ></div>
                                            <div>
                                                <div
                                                    className="animated-background my-2"
                                                    style={{ height: "20px", width: "100%" }}
                                                ></div>
                                                <div
                                                    className="animated-background my-2"
                                                    style={{ height: "20px", width: "100%" }}
                                                ></div>
                                                <div
                                                    className="animated-background my-2"
                                                    style={{ height: "20px", width: "100%" }}
                                                ></div>
                                                <div
                                                    className="animated-background my-2"
                                                    style={{ height: "20px", width: "100%" }}
                                                ></div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div
                                                className="animated-background mt-2"
                                                style={{ height: "30px", width: "100%" }}
                                            ></div>
                                            <div>
                                                <div
                                                    className="animated-background my-2"
                                                    style={{ height: "20px", width: "100%" }}
                                                ></div>
                                                <div
                                                    className="animated-background my-2"
                                                    style={{ height: "20px", width: "100%" }}
                                                ></div>
                                                <div
                                                    className="animated-background my-2"
                                                    style={{ height: "20px", width: "100%" }}
                                                ></div>
                                                <div
                                                    className="animated-background my-2"
                                                    style={{ height: "20px", width: "100%" }}
                                                ></div>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <div className="col-12 col-md-3 m-auto text-center d-flex justify-content-end">
                                    <div style={{ maxWidth: "300px", width: "100%" }}>
                                        <div
                                            className="animated-background my-2"
                                            style={{ height: "40px", width: "100%" }}
                                        ></div>
                                        <div
                                            className="animated-background my-2"
                                            style={{ height: "30px", width: "100%" }}
                                        ></div>
                                        <div
                                            className="animated-background my-2"
                                            style={{ height: "30px", width: "100%" }}
                                        ></div>
                                        <div
                                            className="animated-background my-2"
                                            style={{ height: "30px", width: "100%" }}
                                        ></div>
                                        <div
                                            className="animated-background my-2"
                                            style={{ height: "30px", width: "100%" }}
                                        ></div>
                                    </div>
                                </div>
                            </Row>
                        </Accordion.Body>
                    )}
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default AccordionTicket;