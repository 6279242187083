import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useAppSelector } from "../../../store/hooks";

export interface propsInfo {
    setControlIntegrationModal: any;
    integrationPurchase: any;
    setIntegrationPurchase: any;
    getValues: any;
    isTicket: boolean;
}

const IntegrationPurchase: React.FC<propsInfo> = ({
    setControlIntegrationModal, integrationPurchase, setIntegrationPurchase, getValues, isTicket
}: propsInfo) => {

    /* Controle Redux  */
    const tourSIG: any = JSON.parse(
        JSON.stringify(useAppSelector((state: any) => state.tour))
    );
    /* Controle Redux  */

    const values: any = getValues();

    const integrationAccesLabelsTicket: string[] | boolean[] = isTicket ? [values.labelEldersEnabled ? values.labelEldersBR : false, values.labelStudentEnabled ? values.labelStudentBR : false] : []

    const integrationAccesLabels: string[] | boolean[] = tourSIG.tour.sellingType === 2 ?
        [values.labelAdultBR, values.labelChildEnabled ? values.labelChildBR : false, values.labelInfantEnabled ? values.labelInfantBR : false, ...integrationAccesLabelsTicket] :
        [values.labelPrivateBR];

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    //const watchintegrationPurchaseType = watch(`idPurchaseIntegration`, integrationPurchase !== null ? integrationPurchase.idPurchaseIntegration + '' : '');

    const onSubmit = (data: any) => {
        /* 
            Legenda:
            99 - Destinow
            1 - Eu não uso um sistema de reservas online
            2 - Meu sistema de reservas não está listado
            4 - Bokun
            6 - Multiclubes
            7 - Rezdy
            8 - Limber
            9 - NovaXS
            10 - PWI
        */

        switch (tourSIG.tour.integrationPurchaseType) {
            case '1':

                /* setIntegrationPurchase({
                    "idPurchaseIntegration": parseInt(watchintegrationPurchaseType),
                    "grupo": 1,
                    "chave": "Purchase Integration",
                    "valor": "1234"
                }); */


                break;
            case '9': //NOVAXS
                let novaXs: any = [];
                for (let i: number = 1; i < integrationAccesLabels.length + 1; i++) {
                    if (integrationAccesLabels[i - 1]) {
                        novaXs.push(
                            {
                                "idNovaXs": data[`idNovaXs-${i}`],
                                "productRange": i,
                                "supplierIdentity": tourSIG.tour.supplierIdentity,
                                "supplierId": tourSIG.tour.supplierId,
                                "sellingValueNovaXs": Number(data[`sellingValueNovaXs-${i}`]),
                                "costValueNovaXs": Number(data[`costValueNovaXs-${i}`])
                            }
                        )
                    }
                }

                setIntegrationPurchase({
                    "idPurchaseIntegration": 2,
                    "novaXs": novaXs
                });

                setControlIntegrationModal('');

                break;
            case '8': //LIMBER
                let limber: any = [];
                for (let i: number = 1; i < integrationAccesLabels.length + 1; i++) {
                    if (integrationAccesLabels[i - 1]) {
                        limber.push(
                            {
                                "skuLimber": Number(data[`skuLimber-${i}`]),
                                "categoryIdLimber": data[`categoryIdLimber-${i}`],
                                "revenueIdLimber": data[`revenueIdLimber-${i}`],
                                "boardingPlace": data[`boardingPlace-${i}`],
                                "productRange": i
                            }
                        )
                    }
                }

                setIntegrationPurchase({
                    "idPurchaseIntegration": 3,
                    "limber": limber
                });

                setControlIntegrationModal('');

                break;
            default:
                alert('Favor selecionar um tipo de integração')
                break;
        }
    }

    function limitDecimalPlaces(e: any, count: number) {
        if (e.target.value.indexOf('.') === -1) { return; }
        if ((e.target.value.length - e.target.value.indexOf('.')) > count) {
            e.target.value = parseFloat(e.target.value).toFixed(count);
        }
    }

    return (
        <>
            <div className="integration-type d-flex">
                <div className="ball outside"><div className='inside'></div></div>
                <span className="integration-name">Integração de Compra</span>
            </div>
            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                {
                    tourSIG.tour.integrationPurchaseType === '1' &&
                    <>
                        1
                    </>
                }

                {
                    tourSIG.tour.integrationPurchaseType === '9' &&
                    <>
                        <Row>
                            <div className="mt-4">
                                <strong>NOVA XS</strong>
                            </div>
                        </Row>

                        {integrationAccesLabels.map((elem: any, index: number) => {
                            return (
                                <>
                                    {elem &&
                                        <div key={index} className='mt-4'>
                                            <Row><div>{elem}</div></Row>
                                            <Row>
                                                <Form.Group as={Col} md="4" className="mt-2">
                                                    <Form.Label className="label-small">
                                                        ID
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name={`idNovaXs-${index + 1}`}
                                                        defaultValue={integrationPurchase?.idPurchaseIntegration === 2 ? integrationPurchase.novaXs[index]?.idNovaXs : ''}
                                                        rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                aria-invalid={errors?.detailsPT ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                required
                                                                autoComplete='off'
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`idNovaXs-${index + 1}`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                {/* <Col md={6}></Col> */}
                                                <Form.Group as={Col} md="4" className="mt-2">
                                                    <Form.Label className="label-small">
                                                        Custo
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name={`costValueNovaXs-${index + 1}`}
                                                        defaultValue={integrationPurchase?.idPurchaseIntegration === 2 ? integrationPurchase.novaXs[index]?.costValueNovaXs : ''}
                                                        rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="number"
                                                                aria-invalid={errors?.detailsPT ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                required
                                                                autoComplete='off'
                                                                onInput={(e: any) => limitDecimalPlaces(e, 2)}
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`costValueNovaXs-${index + 1}`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" className="mt-2">
                                                    <Form.Label className="label-small">
                                                        Valor
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name={`sellingValueNovaXs-${index + 1}`}
                                                        defaultValue={integrationPurchase?.idPurchaseIntegration === 2 ? integrationPurchase.novaXs[index]?.sellingValueNovaXs : ''}
                                                        rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="number"
                                                                aria-invalid={errors?.detailsPT ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                required
                                                                autoComplete='off'
                                                                onInput={(e: any) => limitDecimalPlaces(e, 2)}
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`sellingValueNovaXs-${index + 1}`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </Row>
                                        </div>
                                    }
                                </>
                            )
                        })}
                    </>
                }

                {
                    tourSIG.tour.integrationPurchaseType === '8' &&
                    <>
                        <Row>
                            <div className="mt-4">
                                <strong>LIMBER</strong>
                            </div>


                        </Row>

                        {integrationAccesLabels.map((elem: any, index: number) => {
                            return (
                                <>
                                    {elem &&
                                        <div key={index} className='mt-4'>
                                            <Row><div>{elem}</div></Row>
                                            <Row>
                                                <Form.Group as={Col} md="6" className="mt-2">
                                                    <Form.Label className="label-small">
                                                        SKU Limber
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name={`skuLimber-${index + 1}`}
                                                        defaultValue={integrationPurchase?.idPurchaseIntegration === 3 ? integrationPurchase.limber[index]?.skuLimber : ''}
                                                        rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                aria-invalid={errors?.detailsPT ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                required
                                                                autoComplete='off'
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`skuLimber-${index + 1}`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" className="mt-2">
                                                    <Form.Label className="label-small">
                                                        ID Categoria
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name={`categoryIdLimber-${index + 1}`}
                                                        defaultValue={integrationPurchase?.idPurchaseIntegration === 3 ? integrationPurchase.limber[index]?.categoryIdLimber : ''}
                                                        rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                aria-invalid={errors?.detailsPT ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`categoryIdLimber-${index + 1}`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} md="6" className="mt-2">
                                                    <Form.Label className="label-small">
                                                        ID da Compra
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name={`revenueIdLimber-${index + 1}`}
                                                        defaultValue={integrationPurchase?.idPurchaseIntegration === 3 ? integrationPurchase.limber[index]?.revenueIdLimber : ''}
                                                        rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                aria-invalid={errors?.detailsPT ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`revenueIdLimber-${index + 1}`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" className="mt-2">
                                                    <Form.Label className="label-small">
                                                        Boarding Place
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name={`boardingPlace-${index + 1}`}
                                                        defaultValue={integrationPurchase?.idPurchaseIntegration === 3 ? integrationPurchase.limber[index]?.boardingPlace : '0'}
                                                        rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                                        render={({ field }: any) => (
                                                            <div className="endpoint">
                                                                <Form.Select
                                                                    {...field}
                                                                    as="select"
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    className="form-control"
                                                                    required
                                                                >
                                                                    <option value='' disabled>Selecione</option>
                                                                    <option value='0'>Não tem local de embarque</option>
                                                                    <option value='1'>Tem local de embarque</option>
                                                                </Form.Select>
                                                            </div>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`boardingPlace-${index + 1}`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </Row>
                                        </div>
                                    }
                                </>
                            )
                        })}
                    </>
                }




                <div className="h-100 d-flex justify-content-end align-items-end">
                    <Row className="mx-0 mt-5">
                        <Button
                            variant="outline-primary"
                            className="form-button mr-3"
                            type="button"
                            onClick={() => setControlIntegrationModal('')}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            className="form-button"
                        >
                            Cadastrar
                        </Button>
                    </Row>

                </div>

            </Form>

        </>
    )
}

export default IntegrationPurchase;