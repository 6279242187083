import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import { Col } from 'react-bootstrap';

import Select from 'react-select';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
};

const GetTariffGrid: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`/DropDown/GetTariffGrid`, config);
        if (data.status !== 400) {
            setInfo(data.data)
          /* console.log(data); */
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);

  if (info !== null) {
    return (
      <>
        <div className={propsErrors.GradeCode ? "endpoint-error" : "endpoint"}>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors?.GradeCode ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={"Selecione"}
            options={Array.from(info)}
            getOptionLabel={(option: any) => `${option.description}`}
          />
        </div>
      </>
    );
  } else {
    return <>

    </>
  }
}

export default GetTariffGrid;