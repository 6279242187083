import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from "react-bootstrap";
import SessionPassengers from '../SessionPassengers/SessionPassengers';
import SessionResources from '../SessionResources/SessionResources';

//COMPONENTS


export interface propInfo {
    sessionInfo: any,
};

const SessionInfo: React.FC<propInfo> = ({
    sessionInfo
}: propInfo) => {
    const [insert, setInsert] = useState<any>(6);

    const statusTour = window.location.href.split('status=')[1];
    console.log(statusTour);

    return (
        <>
            <Card className="bg-white mt-4 p-4">
                <div className="suppliers d-flex justify-content-between flex-column">
                    <h2 className="text-primary">{`Session - ${sessionInfo.sessionId}`}</h2>
                    <span>{sessionInfo.descricao}</span>
                </div>
                {/* <hr style={{ height: "1px" }} /> */}

                <div className="d-flex pax">
                    {
                        statusTour === "0"
                            ?
                            <>
                                <div className="bg-pendente" style={{ width: "120px", maxWidth: "200px" }}>
                                    Pendente
                                </div>
                            </>
                            :
                            statusTour === "1"
                                ?
                                <>
                                    <div className="bg-ativo" style={{ width: "150px", maxWidth: "200px" }}>
                                        Em Operação
                                    </div>
                                </>
                                :
                                <>
                                    <div className="bg-analise" style={{ width: "180px", maxWidth: "200px" }}>
                                        Alocação Parcial
                                    </div>
                                </>
                    }
                    <div className="total-pax">
                        <p>{`${sessionInfo.totalPax} Participantes`}</p>
                    </div>
                    <div className="pax-units">
                        <p>{`${sessionInfo.totalAdult} Adulto`}</p>
                    </div>
                    <div className="pax-units">
                        <p>{`${sessionInfo.totalChild} Child`}</p>
                    </div>
                    <div className="pax-units">
                        <p>{`${sessionInfo.totalInfant} Infanto`}</p>
                    </div>
                </div>

                <div className="recursos">
                    <h6>Recursos</h6>

                    <SessionResources resources={sessionInfo.resources} sessionInfo={sessionInfo} insert={insert} />

                    <div className="new-resource d-flex justify-content-center my-4">
                        {
                            insert === 6
                                ?
                                <>
                                    <button className='btn' onClick={() => setInsert(7)}>Novo Recurso</button>
                                </>
                                :
                                <>
                                    <button className='btn' onClick={() => setInsert(6)}>Remover</button>
                                </>
                        }
                    </div>
                </div>

                <div className="passageiros">

                    <SessionPassengers items={sessionInfo.items} sessionInfo={sessionInfo} />

                </div>
            </Card>
        </>
    );

}

export default SessionInfo;