import React, { Key, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../assets/sass/modal.scss";
import "./FormBillReceiveEdit.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';

import GetAccountingAccountsFilter from "../../C2Points/GetAccountingAccountsFilter";
import GetSuppliersFilter from "../../C2Points/GetSuppliersFilter";

import TableBillReceiveEditItems from "./TableBillReceiveEditItems/TableBillReceiveEditItems";
import TableBillReceiveEditBills from "./TableBillReceiveEditBills/TableBillReceiveEditBills";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../services/api';
import { isTemplateSpan } from "typescript";
import FormBillReceivableLog from "./FormBillReceivableLog/FormBillReceivableLog";

export interface propModal {
  action: any,
  info: any,
  refreshData: any
};

const FormBillReceiveEdit: React.FC<propModal> = ({
  action, info, refreshData
}: propModal, props: any) => {
  const { t } = useTranslation();

  const [logModalShow, setLogModalShow] = React.useState(false);
  const [logReloc, setLogReloc] = React.useState('');

  const modalLogClick = (e: any) => {
    setLogReloc(info.localizador);
    setLogModalShow(true);
  }

  function handleActionLogHideModal() {
    //() => setBillEditModalShow(false)
    setLogModalShow(false);
    setLogReloc('');
  }


  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <Row className="contact-title mb-3">
              <div className="col-md-5 d-flex">
                <div className="col-md-3">
                  <h6>Localizador</h6>
                  <p>
                    {info.localizadorRezdy === '' ?
                    <a className="btn p-0" href={window.location.origin + "/reservations/" +info.localizador} target="_blank">{info.localizador}</a>
                    :
                    <span className="py-2">{info.localizador}</span>
                    }
                    <Button variant="link" className="btn p-1" onClick={modalLogClick}>
                      <FontAwesomeIcon
                        icon={["fal", "history"]}
                        size="1x"
                      />
                    </Button>
                  </p>
                </div>
                <div className="col-md-3">
                  <h6>Ref. Agente</h6>
                  <p>{info.referenciaAgente}</p>
                </div>
                {info.localizadorRezdy !== '' ?
                <div className="col-md-3">
                  <h6>Loc. Rezdy</h6>
                  <p><a className="btn p-0" href={"https://app.rezdy.com/orders/edit/" +info.localizadorRezdy} target="_blank">{info.localizadorRezdy}</a></p>
                </div>
                :
                <div className="col-md-3"></div>
                }
                <div className="col-md-3">
                  <h6>Reserva</h6>
                  <p>{convertDate(info.dataReserva)}</p>
                </div>
              </div>
              <div className="col-md-2">
                <h6>Agente</h6>
                <p>{info.agente}</p>
              </div>
              <div className="col-md-4 d-flex">
                <div className="col-md-8 mr-3">
                  <h6>Cliente</h6>
                  <p>{info.cliente}</p>
                </div>
                <div className="col-md-4">
                  <h6>Status</h6>
                  <p>
                    <div
                      className={"w-100 "+
                        (info.statusReserva === 'Confirmada'
                          ? "bg-ativo"
                          : info.statusReserva === 'Cancelada'
                            ? 'bg-inativo'
                            : info.statusReserva === 'Pendente'
                              ? "bg-yellow"
                              : "bg-blue")
                      }
                    >
                      {info.statusReserva}
                    </div>
                  </p>
                </div>
              </div>
              
            </Row>
            <Form
              className="form-add-contact"
              noValidate
            >
              <div className="">
                <Row className="border-bottom pb-3 mb-3">
                  <Form.Group as={Col} md="8">
                    {/* itens */}
                    <TableBillReceiveEditItems info={info.items} />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    {/* totais */}
                    <table className="table table-striped table-bordered">
                      <tbody>
                        <tr><th>Total Recebido:</th><th>R$ {convertValue(info.totalRecebido)}</th></tr>
                        <tr><th>Total Serviços:</th><th>R$ {convertValue(info.totalServicos)}</th></tr>
                        <tr><th>Saldo:</th><th>R$ {convertValue(info.totalSaldo)}</th></tr>
                      </tbody>
                    </table>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12">
                    {/* pagamentos */}
                    <TableBillReceiveEditBills info={info.bills} refreshData={refreshData} />
                  </Form.Group>
                </Row>
              </div>
            </Form>
          </Row>

          {logReloc !== null? 
           <Modal
           className="modalAuth"
           show={logModalShow}
           onHide={handleActionLogHideModal}
           size="lg"
           aria-labelledby="contained-modal-title-vcenter"
         >
           <>
             <Modal.Header className="p-3" closeButton></Modal.Header>
             <FormBillReceivableLog localizador={logReloc} />
           </>
         </Modal>: <></>}
        </Container>
      </Modal.Body>

      {/* <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          <Button
            type="button"
            className="form-button"
            onClick={onSubmitContact}
          >
            <FontAwesomeIcon
              icon={["fal", "save"]}
              size="1x"
              style={{ marginRight: "5px" }}
            /> Salvar
          </Button>
        </Row>
      </Modal.Footer> */}


    </>
  );
}

export default FormBillReceiveEdit;
