import React, { Key, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

import api from '../../../../../services/api';

import Modal from "react-bootstrap/Modal";

//STYLES
import "./MerchantsTableFeesTerms.scss";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";
import ModalDefault from "../../../../../components/Modal/ModalDefault/ModalDefault";
import FormAddFeeTerm from "../../../../../components/Forms/FormAddFeeTerm/FormAddFeeTerm";


export interface propModal {
  action: any
  info: any
};

const MerchantsTableFeesTerms: React.FC<propModal> = ({
  action, info
}: propModal) => {
  const { t } = useTranslation();
  const [feeTermModalShow, setFeeTermModalShow] = React.useState(false);
  const [feeTermEdit, setFeeTermEdit] = React.useState(null);
  // const suppliers = objectSuppliers.suppliers.default[0];

  const [controlObject, setControlObject] = useState<any>(info ? info : []);
  const [deletedObjects, setDeletedObjects] = useState<any>([]);

  const [merchantFlags, setMerchantFlags] = useState<any>([]);

  const handleActionModal = (objectModal: any) => {
    setControlObject([...controlObject, objectModal],);
  }

  const handleActionEditModal = (objectModal: any) => {
    setFeeTermEdit(null);

    var aux: any = [...controlObject];

    //console.log(objectModal);
    for (var i: any = 0; i < aux.length; i++) {
      if (aux[i].id === objectModal.id) {

        aux.splice(i, 1, objectModal);

        break;
      }
    }
    setControlObject(aux);
  }

  const getMerchantFlagById = (id: number): any => {
    for (var i = 0; i < merchantFlags.length; i++) {
      if (merchantFlags[i].id == id) {
        return merchantFlags[i].description;
      }
    }
    return '';
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listAccountTypes() {
      try {
        const { data } = await api.get(`/DropDown/GetMerchantFlags`, config);
        if (data.status !== 400) {
          const dados = data.data;
          setMerchantFlags(dados)
          /* console.log(data); */
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountTypes();

    action(controlObject, deletedObjects);
  }, [controlObject, deletedObjects])


  function removeRow(index: any) {

    //console.log(controlObject)

    var aux: any = [];

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== index) {
        aux.push(controlObject[i]);
      } else {
        if (controlObject[i].id > 0) setDeletedObjects([...deletedObjects, controlObject[i].id]);
      }
    }

    setControlObject(aux);
  }

  function editRow(index: any) {
    var aux: any = controlObject[index];
    // chama modal
    setFeeTermEdit(aux);
    setFeeTermModalShow(true);
  }

  return (
    <>
      <div className="table-add-default merchants-tax-table">
        <div className="table-container">
          {/* <BootstrapTable rowEvents={ rowEvents } bootstrap4 keyField='email' data={controlObject} hover={true} columns={columns} pagination={pagination} striped={true} /> */}
          <Table striped bordered>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Tipo Taxa</th>
                <th>Bandeira</th>
                <th>Taxa</th>
                <th>Prazo</th>
                <th>Taxa Min</th>
                <th>Recebimento</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {controlObject.length > 0
                ? controlObject.map((info: any, index: Key) => (
                  <tr key={index}>
                    <td>{info.tipo == '1' ? 'Crédito' : info.tipo == '2' ? 'Débito' : info.tipo == '3' ? 'PIX' : ''}</td>
                    <td>{info.tipoTaxa == '1' ? 'R$' : '%'}</td>
                    <td>{getMerchantFlagById(info.bandeira)}</td>
                    <td>{info.taxa}</td>
                    <td>{info.prazo} {info.prazo ? (info.prazo == 1 ? 'Dia' : 'Dias') : ''}</td>
                    <td>{info.temTaxaMin == '1' ? info.taxaMin : ''}</td>
                    <td>{info.recebimento} {info.recebimento === 1 ? 'Dia' : 'Dias'}</td>
                    <td>{
                      <div className="center-table options-table">
                        <Dropdown drop="down" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => editRow(index)} eventKey="1"><FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />{t('suppliersTable.edit')}</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => removeRow(index)} eventKey="2" className="text-danger removeRow"><FontAwesomeIcon icon={['fal', 'trash-alt']} className="mr-2" />{t('suppliersTable.remove')}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    }</td>
                  </tr>
                ))
                : ""
              }
              <div className="add-contact">
                <div className="text-center btn-add-contact">
                  <ModalDefault
                    title="Taxa e Prazo"
                    classModal="modal-pos-tax"
                    name={'+'}
                    class="btn-add"
                  >
                    <div className="form-add-contact">
                      <FormAddFeeTerm action={handleActionModal} info={null} />
                    </div>
                  </ModalDefault>
                </div>
              </div>
            </tbody>
          </Table>

          {
            feeTermEdit !== null
              ?
              <Modal
                className="modal-custom modalAuth modal-pos-tax"
                show={feeTermModalShow}
                onHide={() => setFeeTermModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton>
                    <h3>Taxa e Prazo</h3>
                  </Modal.Header>
                  <hr style={{ margin: "30px 0" }} />
                  <FormAddFeeTerm action={handleActionEditModal} info={feeTermEdit} />
                </>
              </Modal>
              :
              <></>
          }

        </div>
      </div>
    </>
  );
}

export default MerchantsTableFeesTerms;
