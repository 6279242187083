import React, { useState } from "react";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DesktopDefault from "../../templates/DesktopDefault";
import './Stock.scss';
import FilterStock from "./components/FilterStock/FilterStock";
import TableStock from "./components/TableStock/TableStock";
//import api from "../../services/api";

function Stock() {

  const [tarCode, setTarCode] = useState<string | null>(null);
  const [prodCode, setProdCode] = useState<string | null>(null);
  const [modCode, setModCode] = useState<string | null>(null);
  const [edit, setEdit] = useState<boolean>(false);

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard stock">
          <Breadcrumb title={edit === false ? "Cadastrar Estoque" : "Editar Estoque"} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>
              {edit === false ? "Cadastrar Estoque" : "Editar Estoque"}
            </h2>
          </div>

          <FilterStock setTarCode={setTarCode} setProdCode={setProdCode} setModCode={setModCode} setEdit={setEdit} />

          <TableStock tarCode={tarCode} prodCode={prodCode} modCode={modCode} />

        </div>
      </DesktopDefault>
    </>
  );
}

export default Stock;
