import React, { useEffect, useState } from "react";

import { Row, Col, Form, Modal } from "react-bootstrap";

import { Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import SingleCalendar from "../../Calendar/SingleCalendar";
import GetSuppliersFilter from "../../C2Points/GetSuppliersFilter";
import GetAccounts from "../../C2Points/GetAccounts";
import RequestResponseModal from "../../Modal/RequestResponseModal/RequestResponseModal";



export interface propTable {
    control: any;
    errors: any
    setDatapgto: any;
    children?: any;
    modalResponse?: any;
    setModalResponse?: any;
    billsReceive?: any;
}

const FormBillsToPay: React.FC<propTable> = ({
    control,
    errors,
    setDatapgto,
    children,
    modalResponse,
    setModalResponse,
    billsReceive
}: propTable) => {

    /* RENDER */
    return (
        <>
            <div className='d-flex flex-column justify-content-between h-100'>
                <div>
                    {billsReceive !== true
                        ?
                        <Row className="mb-3">
                            <Form.Group as={Col} md={6} controlId="formGridDesc">
                                <Form.Label>Pagar a:</Form.Label>
                                <Controller
                                    control={control}
                                    name="fornLiquidacao"
                                    rules={{ required: { value: true, message: 'Campo obrigatório' } }}
                                    render={({ field }: any) => (
                                        <GetSuppliersFilter propsField={field} propsLabel={"Pagar a:"} propsErrors={errors} />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="fornLiquidacao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                        :
                        <>
                        </>
                    }
                    <Row className="mb-3">
                        <Form.Group as={Col} md={3} controlId="formGridDesc">
                            <Form.Label>Juros / Multa</Form.Label>
                            <Controller
                                control={control}
                                name="jurosMulta"
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.jurosMulta ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        id="juros"
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={3} controlId="formGridDesc">
                            <Form.Label>Desconto (%)</Form.Label>
                            <Controller
                                control={control}
                                name="descTaxa"
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.descTaxa ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        id="desconto"
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={3} controlId="formGridDesc">
                            <Form.Label>Data Pagamento</Form.Label>
                            <Controller
                                control={control}
                                name="dataPgto"
                                rules={{ required: { value: true, message: 'Campo obrigatório' } }}
                                render={({ field }: any) => (
                                    <SingleCalendar setValue={setDatapgto} field={field} errors={errors} />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="dataPgto"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={3} controlId="formGridDesc">
                            <Form.Label>Conta Liquidação</Form.Label>
                            <Controller
                                control={control}
                                name="contaLiquidacao"
                                rules={{ required: { value: true, message: 'Campo obrigatório' } }}
                                render={({ field }: any) => (
                                    <GetAccounts propsField={field} propsLabel={"Conta Liquidação"} propsErrors={errors} />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="contaLiquidacao"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                </div>
            </div>
            {children
                ?
                children
                :
                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn-default text-uppercase btn btn-primary mt-3">Liquidar</button>
                </div>
            }

            <Modal
                className={"modal-custom modalAuth"}
                show={modalResponse !== null}
                onHide={() => setModalResponse(null)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <RequestResponseModal name={"Liquidar"} modalResponse={modalResponse} setModalResponse={setModalResponse} />
                </>
            </Modal>
        </>
    );
}


export default FormBillsToPay;
