import React, { useEffect, useState } from "react";
import { Card, Container, Row } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import { useTranslation } from "react-i18next";

import SuppliersTableUsers from './SuppliersTableUsers/SuppliersTableUsers';

import '../../../../assets/sass/accordion.scss';

export interface propAccordion {
    setChangeEdit: any,
    action: any,
    ContextAwareToggle: any,
    supplierControlObject: any,
    info: any,
    setStepCtrl: any,
    scrollTop?: any
};

const AccordionUsers: React.FC<propAccordion> = ({
    setChangeEdit, action, ContextAwareToggle, supplierControlObject, info, setStepCtrl, scrollTop
}: propAccordion) => {

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);
    const [emptyUserTable, setEmptyUserTable] = useState<boolean>(false);
    const [userObject, setUserObject] = useState<any>([]);
    const [userControlObject, setUserControlObject] = useState<any>([]);

    //const idSup = window.location.href.split('id=')[1];

    const { t } = useTranslation();
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (info === false) {
                /// ADD SUPPLIERS PAGE
                if (userObject.length > 0) {
                    setEmptyUserTable(false);
                    setCtrlEventKey(true);
                    action(userControlObject);
                } else {
                    setEmptyUserTable(true);
                }
                console.log(userObject)

            } else {
                /// EDIT SUPPLIERS PAGE
                console.log("edit")
            }
        }
        setValidated(true);
    };



    useEffect(() => {
        const ctrlUsersSupplierModel = {
            usersSupplierModel: userObject.map(({ id: userId }: any) => ({ userId }))
        }
        setUserControlObject(ctrlUsersSupplierModel);
    }, [userObject]);


    const handleObjectUser = (infoObject: any) => {
        if (infoObject.length > 0) {
            setEmptyUserTable(false);
        }
        setUserObject(infoObject);
    }

    function handleCancel() {
        window.location.reload();
        /* setChangeEdit(true) */
    }

    return (
        <Card className="bg-white mt-4">
            <Card.Header id="accordionPersonal">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>{t('accordionUsers.title')}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0 mt-3" fluid>
                        <Row>
                            <div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row style={{ border: emptyUserTable === true ? "1px solid red" : '' }}>
                                        <SuppliersTableUsers 
                                        isEdit={false} 
                                        info={info} 
                                        actionTableUser={handleObjectUser} 
                                        />
                                    </Row>
                                    {
                                        emptyUserTable === true
                                            ?
                                            <div style={{ color: "red", textAlign: "center" }}>
                                                Favor vincular algum usuário
                                            </div>
                                            :
                                            ''
                                    }
                                    <Row className="d-flex justify-content-end mx-0 mt-5">
                                        {
                                            info === false
                                                ?
                                                <Button
                                                    type="button"
                                                    className="form-button back-btn"
                                                    onClick={() => { 
                                                        setStepCtrl(3) 
                                                        scrollTop()
                                                    }}
                                                >
                                                    voltar
                                                </Button>
                                                :
                                                <Button
                                                    className="form-button cancel"
                                                    onClick={handleCancel}
                                                >
                                                    Cancelar
                                                </Button>
                                        }
                                        <Button
                                            type="submit"
                                            className="form-button"
                                            onClick={scrollTop}
                                        >
                                            {t('accordionInfoCompany.buttonContinue')}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionUsers;