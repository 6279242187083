import React, { useState } from 'react';
import api from "../../../../../../services/api";

import { Row, Col, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import SingleCalendar from '../../../../../../components/Calendar/SingleCalendar';
import GetCostCenter from '../../../../../../components/C2Points/GetCostCenter';
//import GetPartnersFilter from '../../../../../../components/C2Points/GetPartnersFilter';
import GetAccountingAccountsFilter from '../../../../../../components/C2Points/GetAccountingAccountsFilter';
import GetPaymentForms from '../../../../../../components/C2Points/GetPaymentForms';
import GetMerchantFlags from '../../../../../../components/C2Points/GetMerchantFlags';
import GetAgents from '../../../../../../components/C2Points/GetAgents';

export interface propInsert {
    show?: any;
    setResponseText?: any,
    setModalContent?: any,
    setLog?: any
}

const Insert: React.FC<propInsert> = ({
    show, setResponseText, setModalContent, setLog
}: propInsert) => {

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('');
    const [pagRecorrente, setPagRecorrente] = useState<any>(false);
    const [file, setFile] = useState<any>(null);
    const [fileName, setFileName] = useState<any>('');
    const [errorRateio, setErrorRateio] = useState<any>(false);
    const [date, setDate] = useState<any>();
    const [vencimento, setVencimento] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    const [client, setClient] = useState<any>();

    const validated = false;

    const tourAuth: any = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    const fileHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setFile(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    function verifyDate(now: any, due: any) {
        var nowArray: any = now.split('-');
        var dueArray: any = due.split('-');

        if (parseInt(dueArray[0]) < parseInt(nowArray[0])) {
            return false;
        }
        if (parseInt(dueArray[1]) < parseInt(nowArray[1])) {
            return false;
        }
        if (parseInt(dueArray[2]) < parseInt(nowArray[2])) {
            return false;
        }
        return true;
    }

    const onSubmit = (data: any) => {
        setLoading(true)
        if (data.rateio > 100) {
            setErrorRateio(true);
            setModalShow(true);
            setModalMessage("Rateio deve ser no máximo 100.");
        } else {
            setErrorRateio(false);

            console.log(data)
            data.data = fixDate(date);
            data.vencimento = fixDate(vencimento);
            data.arquivo = file;
            data.recorrente = pagRecorrente;
            data.fornecedor = client.supplierId;
            data.categoria = data.categoria.id;
            data.centroCusto = data.centroCusto.id

            console.log(data)

            setModalContent("loading");
            if (verifyDate(data.data, data.vencimento)) {
                const insertConta = async () => {
                    const token = localStorage.getItem('GroupId') || '{}';
                    const config = {
                        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                    };
                    try {
                        const res = await api.post(`/BillsPayable/InsertBill`,
                            {
                                "centrosCusto": [
                                    {
                                        "centroCustoId": data.centroCusto.id,
                                        "rateio": data.rateio
                                    }
                                ],
                                "contaContabil": data.centroCusto,
                                "data": data.data,
                                "descricao": data.descricao,
                                "fornecedor": data.fornecedor,
                                "notaDebito": 2, //fixo 2
                                "observacao": data.observacao,
                                "qtdeRecorrencia": data.qtdeRecorrencia,
                                "recorrente": data.recorrente === true ? 1 : 2, //1=sim;2=não
                                "tipoRecorrencia": data.tipoRecorrencia, //1=Diaria;2=Semanal;3=Mensal;4=Anual
                                "uid": uId,
                                "usuario": `${userName} ${userLastName}`,
                                "valor": data.valor,
                                "vencimento": data.vencimento,
                                "arquivo": data.arquivo  //arquivo em base64
                            }, config);
                        if (res.status !== 400) {
                            setResponseText(res.data.data.texto);
                            setLog(res.data.data.log);
                            setModalContent("success");
                            console.log(res)
                        } else {
                            setResponseText(res.data.data.texto);
                            setLog(res.data.data.log);
                            setModalContent("error");
                        }
                        setLoading(false);
                        closeModal();
                        show(true);
                    } catch (error: any) {
                        setLoading(false);
                        setModalContent("error");
                        console.log(error.response)
                    }
                };

                insertConta();
            } else {
                setModalShow(true);
                setModalContent("error");
                setModalMessage("Data de vencimento antes da data atual.");
                setLoading(false)
            }
        }
    }

    return (
        <>
            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridCat">
                                <Form.Label>Categoria</Form.Label>
                                <Controller
                                    control={control}
                                    name="categoria"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <GetAccountingAccountsFilter propsField={field} propsErrors={errors} propsLabel="Categoria" />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="categoria"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridDesc">
                                <Form.Label>Descrição</Form.Label>
                                <Controller
                                    control={control}
                                    name="descricao"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.descricao ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="descricao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} className="mb-3" controlId="formGridDate">
                                <Form.Label>Data</Form.Label>
                                <InputGroup
                                    hasValidation
                                    className=""
                                    size="sm"
                                    placeholder="Quando?"
                                >
                                    <SingleCalendar setValue={setDate} />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="formGridVenc">
                                <Form.Label>Vencimento</Form.Label>
                                <InputGroup
                                    hasValidation
                                    className=""
                                    size="sm"
                                    placeholder="Quando?"
                                >
                                    <SingleCalendar setValue={setVencimento} />
                                </InputGroup>
                            </Form.Group>
                        </Row>


                        <Row >
                            <Form.Group as={Col} md={3} controlId="formGridDesc">
                                <Form.Label>Forma de Pagamento</Form.Label>
                                <Controller
                                    control={control}
                                    name="descricao"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <GetPaymentForms propsField={field} propsErrors={errors} propsLabel="Forma de Pagamento" />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="descricao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={3} className="mb-3" controlId="formGridVenc">
                                <Form.Label>Operadora</Form.Label>
                                <Controller
                                    control={control}
                                    name="tipoRecorrencia"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <GetMerchantFlags propsField={field} propsErrors={errors} propsLabel="Forma de Pagamento" />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="tipoRecorrencia"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={3} className="mb-3" controlId="formGridVenc">
                                <Form.Label>MDR</Form.Label>
                                <Controller
                                    control={control}
                                    name="qtdeRecorrencia"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            type="number"
                                            min="0"
                                            aria-invalid={errors?.qtdeRecorrencia ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="qtdeRecorrencia"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={3} controlId="formGridCat">
                                <Form.Label>Valor</Form.Label>
                                {/* <Form.Control type="text" /> */}
                                <Controller
                                    control={control}
                                    name="valor"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.valor ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="valor"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>

                        <hr style={{ margin: "30px 0" }} />

                        <Row className="mb-3">
                            <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                <Form.Label>Cliente</Form.Label>
                                <Controller
                                    control={control}
                                    name="fornecedor"
                                    //rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <GetAgents
                                            propsField={field}
                                            propsErrors={errors}
                                            propsLabel="Cliente"
                                            setClient={setClient}
                                        />

                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="fornecedor"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={6} className="mb-3" controlId="formGridCenter">
                                <Form.Label>Centro de Custo</Form.Label>
                                <Controller
                                    control={control}
                                    name="centroCusto"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <GetCostCenter propsField={field} propsErrors={errors} propsLabel="Centros de Custo" />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="centroCusto"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>Observação</Form.Label>
                                <Controller
                                    control={control}
                                    name="observacao"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.observacao ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            as="textarea"
                                            rows={4}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="observacao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                    </div>
                    <div className='mt-4 d-flex justify-content-end'>
                        <button
                            className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase"
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                        <button
                            className="btn btn-default btn-primary px-4 text-uppercase"
                            type="submit"
/*                             onClick={() => {
                                setLoading(true)
                            }} */
                        >
                            {
                                loading === true
                                    ?
                                    <div className="load"></div>
                                    :
                                    "Cadastrar"
                            }
                        </button>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default Insert;