/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GetSuppliersFilter from "../../../../components/C2Points/GetSuppliersFilter";
import GetProductsListSubCategory from "../../../../components/C2Points/GetProductsListSubCategory";

export interface propFilter {
  buscar: any;
  products: any;
  clearActualValues: any;  
}

const ProductFilter: React.FC<propFilter> = ({
  buscar,
  products,
  clearActualValues
}) => {
  const [t] = useTranslation();
  
  const [filterCodProd, setFilterCodProd] = useState<any>("");
  const [filterCity, setFilterCity] = useState<any>("");
  const [filterSupplier, setFilterSupplier] = useState<any>(0);
  const [filterCadProd, setFilterCadProd] = useState<any>(0);
  const [filterStatProd, setFilterStatProd] = useState<any>(0);
  const [filterTypeProd, setFilterTypeProd] = useState<any>(0);

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({});

  const handleFilterClick = () => {
    buscar(
      filterCodProd,
      filterCity,
      filterSupplier,
      filterCadProd,
      filterStatProd,
      filterTypeProd
    );
  };

  const handleClearClick = () => {
    setValue("products", "");

    let inputValue = document.querySelectorAll("input");
    for (let i = 0; i < inputValue.length; i++) {
      inputValue[i].value = "";
    }

    let selectValue = document.querySelectorAll("select");
    for (let i = 0; i < selectValue.length; i++) {
      if (selectValue[i].id === "rowsPerPage") {
        selectValue[i].value = "10";
      } else {
        selectValue[i].value = "";
      }
    }

    setFilterCodProd("");
    setFilterCity("");
    setFilterSupplier(0);
    setFilterCadProd(0);
    setFilterStatProd(0);
    setFilterTypeProd(0);
    buscar("", "", 0, 0, 0, 0);
    clearActualValues();
  };

  const handleChangeCodProd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterCodProd(newValue);
  };

  const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterCity(newValue);
  };

  const handleChangeSupplier = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterSupplier(newValue);
  };

  const handleChangeCadProd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterCadProd(newValue);
  };

  const handleChangeStatProd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterStatProd(newValue);
  };

  const handleChangeTypeProd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterTypeProd(newValue);
  };

  return (
    <div
      className="suppliers-filter d-flex justify-content-center bg-white mt-4"
      style={{ textAlign: "left" }}
    >
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={["fal", "sliders-h"]}
              size="lg"
              className="mr-2"
            />
            <span className="h5 mb-0">Buscar produto</span>
          </Accordion.Header>
          <Accordion.Body className="pt-0">
            <Container className="p-0" fluid>
              <Row>
                <div>
                  <Form>
                    <Row className="mb-4">
                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>
                          Buscar por nome do produto ou código
                        </Form.Label>
                        <Controller
                          control={control}
                          name="name"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              onChange={handleChangeCodProd}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>Digite o nome da cidade</Form.Label>
                        <Controller
                          control={control}
                          name="city"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              onChange={handleChangeCity}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>Fornecedor</Form.Label>
                        <Controller
                          control={control}
                          name="supplier"
                          render={({ field }: any) => (
                            <div className="input-select-custom">
                              <GetSuppliersFilter
                                propsField={field}
                                propsErrors={errors}
                                propsLabel="Fornecedor"
                                setFilterSupplier={setFilterSupplier}
                              />
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-4">
                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>Tipo de produto</Form.Label>
                        <Controller
                          control={control}
                          name="tipo"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) => handleChangeTypeProd(e)}
                              >
                                <option value="0" selected>
                                  Tipo de produto
                                </option>
                                <option value="1">Privativo</option>
                                <option value="2">Regular</option>                               
                              </Form.Select>
                            </div>
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>Categoria</Form.Label>
                        <Controller
                          control={control}
                          name="categoria"
                          render={({ field }: any) => (
                            <div className="input-select-custom">
                              <GetProductsListSubCategory 
                                propsField={field}
                                propsErrors={errors}
                                propsLabel="Fornecedor"
                                setFilterSupplier={setFilterCadProd}
                              />
                            </div>
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>Status</Form.Label>
                        <Controller
                          control={control}
                          name="status"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) => handleChangeStatProd(e)}
                              >
                                <option value="0" selected>
                                  Status
                                </option>
                                <option value="1">Rascunho</option>
                                <option value="2">Em revisão</option>
                                <option value="3">Ativo</option>
                                <option value="4">Inativo</option>
                                <option value="5">Bloqueado</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Row>

                    <Row
                      as={Col}
                      md="12"
                      className="mt-5 justify-content-md-end"
                    >
                      <Col xs lg="12" className="d-flex justify-content-md-end">
                        <Button
                          variant="outline-primary"
                          className="btn-default mr-2 text-uppercase"
                          onClick={handleClearClick}
                        >
                          {t("reservations.btnClear")}
                        </Button>
                        <Button
                          className="btn-default text-uppercase"
                          onClick={handleFilterClick}
                        >
                          {t("reservations.btnSearch")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default ProductFilter;
