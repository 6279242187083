import React from 'react';

export interface propEdit {
    setChangeEdit: any,
    info: any,
};

const EditResources: React.FC<propEdit> = ({
    setChangeEdit, info
}: propEdit) => {

    return (
        <>
            <div className="border" id="editResources">
                <div style={{ backgroundColor: "#fff", paddingBottom: "60px" }}>
                    <div className="edit-title container-fluid">
                        <h3 className="">Recursos</h3>
                        <button onClick={() => {setChangeEdit(false)}}>EDITAR</button>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>TIPO DE RECURSOS:</p>
                            <p>{info.supplierByResourceDTO[0] ? info.supplierByResourceDTO[0] : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>TIPO DE VEÍCULO</p>
                            <div className="d-flex">
                            {info.supplierVehicleTypeDTO.length > 0 ? info.supplierVehicleTypeDTO.map((vehicle: any, index: any) => {
                                return(
                                    <>
                                    <span key={index}>[{vehicle.typeId}] </span>
                                    </>
                                )
                            }) : "não definido"}
                            </div>
                        </div>
                        <div className="data-unit data-unit-white-bg"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditResources;