import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import Select from 'react-select';

import Form from 'react-bootstrap/Form';

export interface propPlus {
    propsField: any,
    propsErrors: any;
    setSource: any;
    isMulti?: boolean;
    defaultValue?: any;
    setValue?: any;
    name?: string;
    isTicket?: boolean;
};

const GetAllSource: React.FC<propPlus> = ({
    propsField, propsErrors, setSource, isMulti = false, defaultValue = null, setValue, name, isTicket = false
}: propPlus) => {
    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [defaultItems, setDefaultItems] = useState<any>([]);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        async function listAllSources() {
            try {
                const { data } = await api.get(`/DropDown/GetAllSource`, config);
                if (data?.status !== 400) {
                    if (defaultValue !== null) {
                        let defaultIds: any = defaultValue.map((elem: any) => elem.source);
                        defaultIds = defaultIds.filter(function (e: any, i: number) { return defaultIds.indexOf(e) === i; });
                        let defaultValues: any = data.data.filter((e: any) => defaultIds.includes(e.id)).map(({ id, description }: any) => ({ value: id, label: description })) || [];
                        setDefaultItems(defaultValues);
                        setValue(name, defaultValues);

                        defaultValue.forEach((elem: any) => {
                            if (isTicket) {
                                let indice: string =
                                    elem.chave.includes("1") ? "1" :
                                        elem.chave.includes("2") ? "2" :
                                            elem.chave.includes("3") ? "3" :
                                                elem.chave.includes("4") ? "4" :
                                                    elem.chave.includes("5") ? "5" :
                                                        "6"
                                setValue(`valor-${elem.source}-${indice}`, elem.valor)
                            } else {
                                let indice: string =
                                    elem.chave.includes("1") ? "1" :
                                        elem.chave.includes("2") ? "2" :
                                            elem.chave.includes("3") ? "3" :
                                                "4"
                                setValue(`valor-${elem.source}-${indice}`, elem.valor)
                            }
                        });
                    }

                    setInfo(data.data);
                }
            } catch (error: any) {
                /* setTimeout(() => {
                  listAllSources();
                }, 5000) */
            }
        }
        listAllSources();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSource(newValue);
        setValue(name, newValue);
    }

    if (info !== null) {
        const transformed = info.map(({ id, description }: any) => ({ value: id, label: description }));
        return (
            <>
                <div className={propsErrors.coastCenterId || propsErrors.costCenter !== undefined ? "endpoint-error" : "endpoint"}>
                    {isMulti === false ?
                        <>
                            <Form.Control
                                as="select"
                                {...propsField}
                                required
                                autoComplete='off'
                                className="form-select"
                                onChange={(e: any) => handleChangeState(e)}
                            >
                                <option value='0'>Selecione</option>
                                {info.length > 0 ? info.map((info: any) => (
                                    <option value={info.id}>{info.description}</option>
                                )) : ''}
                            </Form.Control>
                        </>
                        :
                        <>
                            <Select
                                {...propsField}
                                aria-labelledby="aria-label"
                                inputId="aria-example-input"
                                name="aria-live-color"
                                onMenuOpen={onMenuOpen}
                                onMenuClose={onMenuOpen}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                as="select"
                                variant="standard"
                                margin="normal"
                                placeholder={"Selecione"}
                                isMulti
                                options={transformed}
                                getOptionLabel={(option: any) => `${option.label}`}
                                defaultValue={defaultItems}
                            />
                        </>
                    }
                </div>
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value='' disabled></option>
                </Form.Select>
            </>
        )
    }
}

export default GetAllSource;