import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";

import { useTranslation } from "react-i18next";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "./CategoriesTable.scss";
import Pagination from "../../../../../components/Pagination/Pagination";

export interface propTable {
  categories: any;
  loading: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
}

const CategoriesTable: React.FC<propTable> = ({
  categories, loading, totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const { t } = useTranslation();

  const columns = [
    { dataField: "categorieName", text: "Nome" },
    { dataField: "categorieStatus", text: "Status", formatter: addStatus },
    { dataField: "categorieCode", text: "Código da categoria" },
    { dataField: "categorieDescription", text: "Descrição" },
    { dataField: "categorieId", text: "Ações", formatter: addActionButton },
  ];

  // const [, setEditClick] = useState<any>(false);
  // const [, setDetailsModal] = useState<any>(false);
  // const [, setCurrentCategory] = useState<any>();

  // function seeDetailsEdit(cell: any) {
  //   setEditClick(true);
  //   setCurrentCategory(cell);
  //   setDetailsModal(true);
  // };

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    { dataField: "categories", text: "Carregando categorias", formatter: loadingFunc },
  ];

  const loadingCategories = [
    { categories: "" },
    { categories: "" },
    { categories: "" },
    { categories: "" },
    { categories: "" },
    { categories: "" },
    { categories: "" },
    { categories: "" },
    { categories: "" },
    { categories: "" }

  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "categories", text: "Categorias" }];

  const notFoundCategories = [{ categories: "Nenhuma categoria encontrada" }];
  /* [END] In case of empty table*/

  // formatter de status
  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Em análise"
            ? "bg-analise"
            : cell === "Ativo"
            ? "bg-ativo "
            : cell === "Inativo"
            ? "bg-inativo"
            : ""
        }
      >
        {cell}
      </div>
    );
  }

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.categorieStatus === "Em Análise") {
      return "border-analise";
    } else if (row.categorieStatus === "Ativo") {
      return "border-ativo";
    } else {
      return "border-inativo";
    }
  };


  //botao de ação
  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item eventKey="1" disabled={true}>
                <FontAwesomeIcon
                  icon={["fal", "comment-alt-dollar"]}
                  className="mr-2"
                />{" "}
                {t("suppliersTable.account")}
              </Dropdown.Item> */}

              <Dropdown.Item
                eventKey="2"
                href={"/editar-associacao?id=" + cell
                }
              >                
                <FontAwesomeIcon icon={["fal", "edit"]} className="mr-2" />{" "}
                {t("reservations.table.dropdown.edit")}{" Associação"}
                
              </Dropdown.Item>

              {/* <Dropdown.Item eventKey="3" disabled={true}>
                <FontAwesomeIcon icon={["fal", "circle"]} className="mr-2" />{" "}
                {t("suppliersTable.status")}
              </Dropdown.Item> */}
              {/* <Dropdown.Item eventKey="5" disabled={true}>
                <FontAwesomeIcon
                  icon={["fal", "file-contract"]}
                  className="mr-2"
                />{" "}
                {t("suppliersTable.contract")}
              </Dropdown.Item> */}
              {/* <Dropdown.Item
                eventKey="4"
                disabled={true}
                className="text-danger remove"
              >
                <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-2" />{" "}
                {t("suppliersTable.remove")}
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  };


  if (categories !== null) {
    return (
      <>
        <div className="table-default categories-table">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={categories}
                hover={true}
                columns={columns}
                striped={true}
                rowClasses={rowClasses}
              />

              <Pagination
                totalRows={totalRows}
                pageCount={pageCount}
                setPageCount={setPageCount}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                selectNumberRows="yes"
              />
            </div>
          </div>
        </div>
      </>
    );
  } else if (categories === null && loading === true) {
    return (
      <>
        <div className="table-default categories-table loading">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={loadingCategories}
                hover={true}
                columns={loadingColumns}
                striped={true}
              />

              <Pagination
                totalRows={totalRows}
                pageCount={pageCount}
                setPageCount={setPageCount}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                selectNumberRows="yes"
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default categories-table loading not-found">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={notFoundCategories}
                hover={true}
                columns={notFoundColumns}
                striped={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default CategoriesTable;
