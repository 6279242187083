import React, { useContext, useState } from 'react';
import { AccordionContext, Accordion, useAccordionButton } from 'react-bootstrap';
import { Row } from "react-bootstrap";
import api from '../../../../../services/api';
import Modal from "react-bootstrap/Modal";


import EditInfoCompany from './EditInfoCompany/EditInfoCompany'
import AccordionInfoCompany from '../../AccordionInfoCompany/AccordionInfoCompany'
import EditFinancial from './EditFinancial/EditFinancial'
import AccordionFinancial from '../../AccordionFinancial/AccordionFinancial';
import EditAccounting from './EditAccounting/EditAccounting'
import AccordionAccount from '../../AccordionAccount/AccordionAccount';
import EditResources from './EditResources/EditResources'
import AccordionResources from '../../AccordionResources/AccordionResources';
import EditAdditionalInfo from './EditAdditionalInfo/EditAdditionalInfo'
import AccordionInfoAdd from '../../AccordionInfoAdd/AccordionInfoAdd';
import EditUser from './EditUser/EditUser';
import AccordionUsers from '../../AccordionUsers/AccordionUsers';
import RequestResponseModal from "../../../../../components/Modal/RequestResponseModal/RequestResponseModal";
//import EditUser from './EditUser/EditUser'

export interface propInfo {
    info: any,
};

const EditSuppliersDataFields: React.FC<propInfo> = ({
    info
}: propInfo) => {

    const [stepCtrl, setStepCtrl] = useState<number>(0);
    const [infoCompanyEdit, setInfoCompanyEdit] = useState<boolean>(true);
    const [financialEdit, setFinancialEdit] = useState<boolean>(true);
    const [accountingEdit, setAccountingEdit] = useState<boolean>(true);
    const [resourcesEdit, setResourcesEdit] = useState<boolean>(true);
    const [usersEdit, setUsers] = useState<boolean>(true);
    const [additionalInfoEdit, setAdditionalInfoEdit] = useState<boolean>(true);

    const [modalResponse, setModalResponse] = useState<any>(null); /// ["loading","error","sucess"] para abrir o modal pós envio
    const [errors, setErrors] = React.useState({});

    const handleNext = () => {
        setStepCtrl(stepCtrl + 1);
    }

    const ContextAwareToggle = ({ children, eventKey, callback, status }: any) => {

        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <h2 className="accordion-custom accordion-header">
                <button
                    type="button"
                    aria-expanded="false"
                    className={isCurrentEventKey ? "accordion-button collapsed" : "accordion-button collapsed"}
                    onClick={decoratedOnClick}
                    disabled={status}>
                    {children}
                </button>
            </h2>
        );
    }

    const editSuppliers = (obj: any) => {
        setModalResponse("loading");
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function updateSuppliers() {
            try {
                const { data } = await api.post('Supplier/UpdateSupplierAsync',
                    obj
                    , config
                );

                if (data.status !== 400) {
                    console.log(data)
                    setModalResponse("success");
                    setTimeout(() => window.location.reload(), 1500);
                }
            } catch (error: any) {
                setErrors(error.response)
                setModalResponse("error");
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        updateSuppliers();
    }

    return (
        <>
            {infoCompanyEdit && financialEdit && accountingEdit && resourcesEdit && usersEdit && additionalInfoEdit
                ?
                <>
                    <EditInfoCompany setChangeEdit={setInfoCompanyEdit} info={info} />
                    <EditFinancial setChangeEdit={setFinancialEdit} info={info} />
                    <EditAccounting setChangeEdit={setAccountingEdit} info={info} />
                    <EditResources setChangeEdit={setResourcesEdit} info={info} />
                    <EditUser setChangeEdit={setUsers} info={info} />
                    <EditAdditionalInfo setChangeEdit={setAdditionalInfoEdit} info={info} />
                </>
                : ''
            }
            {!infoCompanyEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionInfoCompany editSuppliers={editSuppliers} info={info} setChangeEdit={setInfoCompanyEdit} action={handleNext} ContextAwareToggle={ContextAwareToggle} supplierControlObject={false} />
                </Accordion>
                :
                ''
            }
            {!financialEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionFinancial editSuppliers={editSuppliers} info={info} setChangeEdit={setFinancialEdit} action={handleNext} ContextAwareToggle={ContextAwareToggle} supplierControlObject={false} setStepCtrl={false} />
                </Accordion>
                :
                ''
            }
            {!accountingEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionAccount editSuppliers={editSuppliers} info={info} setChangeEdit={setAccountingEdit} action={handleNext} ContextAwareToggle={ContextAwareToggle} supplierControlObject={false} setStepCtrl={false} />
                </Accordion>
                :
                ''
            }
            {!resourcesEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionResources editSuppliers={editSuppliers} info={info} setChangeEdit={setResourcesEdit} action={handleNext} ContextAwareToggle={ContextAwareToggle} supplierControlObject={false} setStepCtrl={false} />
                </Accordion>
                :
                ''
            }
            {!usersEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionUsers info={info} setChangeEdit={setUsers} action={handleNext} ContextAwareToggle={ContextAwareToggle} supplierControlObject={false} setStepCtrl={false} />
                </Accordion>
                :
                ''
            }
            {!additionalInfoEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionInfoAdd editSuppliers={editSuppliers} info={info} setChangeEdit={setAdditionalInfoEdit} action={handleNext} ContextAwareToggle={ContextAwareToggle} setStepCtrl={false} />
                </Accordion>
                :
                ''
            }
            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                <div className="btn-sucess">
                    <Modal
                        className="modal-custom modalAuth"
                        show={modalResponse !== null}
                        onHide={() => setModalResponse(null)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <>
                            <Modal.Header closeButton></Modal.Header>
                            <RequestResponseModal name={"Fornecedor editado"} modalResponse={modalResponse} setModalResponse={setModalResponse} errors={errors} />
                        </>
                    </Modal>
                </div>
            </Row>
        </>
    );
}

export default EditSuppliersDataFields;