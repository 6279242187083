import React, { Key, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

//STYLES
import "./PartnersTableInfoClient.scss";

//JSON
// import objectPartners from "./locales/partners.json";
import ModalDefault from "../../../../../components/Modal/ModalDefault/ModalDefault";
import FormAddContact from "../../../../../components/Forms/FormAddContact/FormAddContact";

export interface propModal {
  action?: any;
  setMessageError?: any;
  messageError?: any;
  isEdit?: any;
  infoEdit?: any;
  disableAddContact?: any;
  hasSavedInputs?: any;
}

const PartnersTableInfoClient: React.FC<propModal> = ({
  action, setMessageError, messageError, isEdit = false, infoEdit, disableAddContact = false, hasSavedInputs = false
}: propModal) => {
  const { t } = useTranslation();

  const [controlObject, setControlObject] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit : []);
  const [modalEdit, setModalEdit] = useState<any>([]);
  const [closeModal, setCloseModal] = useState<any>(false);

  const handleActionModal = (objectModal: any) => {
    if (controlObject.length === 0) {
      objectModal.isMain = 1;
    } else {
      if (objectModal.isMain === 1) {     /// permite apenas 1 contato principal
        controlObject.forEach((elem: any) => {
          elem.isMain = 0;
        });
      }
    }
    setControlObject([...controlObject, objectModal]);
  };

  useEffect(() => {
    if (disableAddContact === false) { //evita erro no single do cliente
      if (controlObject?.length > 0) {
        setMessageError(false);
      } else {
      }
      action(controlObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlObject])

  function removeRow(index: any) {
    console.log(controlObject);

    var aux: any = [];

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== index) {
        aux.push(controlObject[i]);
      }
    }

    setControlObject(aux);
  }

  function editRow(index: any) {
    var aux: any = controlObject[index];

    aux.index = index;

    setModalEdit([aux])
  }

  function handleEdit(row: any) {
    console.log(row.index)

    var aux: any = [];

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== row.index) {
        aux.push(controlObject[i]);
      } else {
        aux.push(row);
      }
    }

    setControlObject(aux);
    setModalEdit([]);
  }

  return (
    <>
      <div className="table-add-default partners-contact-table">
        <div className="table-container" style={{ border: messageError === true ? '1px solid #dc3545' : '' }}>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Principal</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Celular</th>
                <th>Área</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {controlObject?.length > 0 ? (
                controlObject.map((info: any, index: Key) => (
                  <tr key={index}>
                    <td>
                      {info.isMain === 1 ? (
                        <FontAwesomeIcon
                          size="lg"
                          icon={["fal", "check-circle"]}
                          style={{ margin: "0 20px" }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{info.name}</td>
                    <td>{info.email}</td>
                    <td>{info.phone}</td>
                    <td>{info.cellPhone}</td>
                    <td>{info.area}</td>
                    <td>
                      {
                        <>
                          <div className="center-table options-table">
                            <Dropdown drop="down">
                              <Dropdown.Toggle variant="light ">
                                <FontAwesomeIcon
                                  size="lg"
                                  icon={["fal", "ellipsis-h"]}
                                />
                              </Dropdown.Toggle>

                              {disableAddContact === false
                                ?
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => editRow(index)} eventKey="1" className="removeRow"><FontAwesomeIcon icon={['fal', 'edit']} />Editar</Dropdown.Item>
                                  <Dropdown.Item onClick={() => removeRow(index)} eventKey="1" className="text-danger removeRow"><FontAwesomeIcon icon={['fal', 'trash-alt']} />{t('suppliersTable.remove')}</Dropdown.Item>
                                </Dropdown.Menu>
                                :
                                <></>
                              }
                            </Dropdown>
                          </div>
                        </>
                      }
                    </td>
                  </tr>
                ))
              ) : (
                ""
              )}
              {disableAddContact === false
                ?
                <div className="add-contact">
                  <div className="text-center btn-add-contact">
                    <ModalDefault
                      title="Adicionar Contato"
                      classModal="modal-pos-tax"
                      name="+"
                      class="btn-add"
                      closeModal={closeModal}
                      setCloseModal={setCloseModal}
                    >
                      <div className="form-add-contact">
                        <FormAddContact
                          action={handleActionModal}
                          setCloseModal={setCloseModal}
                          modalEdit={modalEdit}
                          setModalEdit={setModalEdit}
                          handleEdit={handleEdit}
                        />
                      </div>
                    </ModalDefault>
                    <p style={{ color: "#dc3545", marginTop: "10px", marginBottom: "0", fontSize: "14px" }}>
                      {
                        messageError === true
                          ?
                          'Por favor, adicione um contato'
                          :
                          ''
                      }
                    </p>
                  </div>

                </div>
                :
                <></>
              }
            </tbody>
          </Table>
          <Modal
            className="modal-custom modalAuth"
            show={(modalEdit.length > 0) === true}
            onHide={() => { setModalEdit([]) }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <div className="form-add-contact">
                <FormAddContact action={handleActionModal} setCloseModal={setCloseModal} modalEdit={modalEdit} setModalEdit={setModalEdit} handleEdit={handleEdit} />
              </div>

            </>
          </Modal>
          {/* <ModalDefault >
            <div className="form-add-contact">
              <FormAddContact action={handleActionModal} />
            </div>
          </ModalDefault> */}
        </div>
      </div>
    </>
  );
};

export default PartnersTableInfoClient;
