import React, { useState, useEffect, Key } from "react";
import { Card, Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import api from "../../../../services/api";
import { Controller, useForm } from "react-hook-form";

import "./TableStock.scss"
import SingleCalendarStock from "../CalendarStock/SingleCalendarStock";

// import RequestResponseModal from "../../../components/Modal/RequestResponseModal/RequestResponseModal";
import Modal from "react-bootstrap/Modal";
import RequestResponseModal from "../../../../components/Modal/RequestResponseModal/RequestResponseModal";
import { ErrorMessage } from "@hookform/error-message";

export interface propPlus {
    tarCode: any,
    prodCode: any,
    modCode: any
};

const TableStock: React.FC<propPlus> = ({
    tarCode, prodCode, modCode
}: propPlus) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [tariffsInfo, setTariffsInfo] = useState<any>(null)
    const [modalResponse, setModalResponse] = useState<any>(null); /// ["loading","error","sucess"] para abrir o modal pós envio
    const [modalText, setModalText] = useState<any>(null);
    const [log, setLog] = useState<any>();
    // const [hours, setHours] = useState<any>(null)
    // const [weekdays, setWeekdays] = useState<any>([])
    const [capacidades, setCapacidades] = useState<any>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const getStockByTarCode = async (tarifaId: any) => {
        setLoading(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        try {
            const { data } = await api.get(`/Products/GetTariffAndDaysAndHours?TarUniqueCode=${tarifaId}`, config);

            if (data.statusCode === 200) {
                setTariffsInfo(data.data);
                getStockByGeneral();
                // organizeData(data.data.data)
                // setModalShow(false);
                setLoading(false);
            } else {
                // setModalLog(1);
                // setModalMessage(data.errorMessage);
                // setModalShow(true);
            }
        } catch (error: any) {
            // setModalLog(1);
            // setModalMessage("Erro ao processar operação, favor tentar mais tarde");
            // setModalShow(true);
        }
    }

    const getStockByGeneral = async () => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        try {
            const { data } = await api.post(`Stock/GetStockGeneral`, {
                "productCode": prodCode,
                "prodModCodeUnique": modCode,
                "tarCodeUnique": tarCode,
                "productType": '1'
            }, config);

            if (data.data.item1 !== undefined) {
                listStockData(data.data.item1);
                setIsEdit(true);
            } else {
                setIsEdit(false);
            }
        } catch (error: any) {

        }
    }

    const listStockData = (data: any) => {
        let values: any = getValues();
        console.log(values)
        let indexSelectedHour: any;
        data.stockGeneralOnSale.forEach((tariff: any, arrayIndex: number) => {
            tariff.stockAmounts.forEach((hour: any, indexHour: number) => {
                indexSelectedHour = values.stockGeneralOnSale[0].stockAmounts.findIndex((elem: any) => elem.StartHour === hour.startHour)
                setValue(`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexSelectedHour}].Amount`, hour.amount);

                hour.stockAmountsByChannel.forEach((item: any, indexChannel: number) => {
                    setValue(`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexSelectedHour}].StockAmountsByChannel[${indexChannel}].Amount`, item.amount);
                    setValue(`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexSelectedHour}].StockAmountsByChannel[${indexChannel}].IdSalesChannel`, item.idSalesChannel);
                });
            });
        });
    }

    //setLoading(true);

    useEffect(() => {
        if (tarCode !== null) {
            getStockByTarCode(tarCode);
        } else {
            setTariffsInfo(null)
        }
        resetValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tarCode])

    function organizeData(data: any) {

        // Criar um objeto vazio para armazenar os horários divididos
        const horariosDivididos: any = {};
        // Iterar sobre os objetos de horários e canais
        data.hoursAndChannel.forEach((item: any) => {
            const hora = item.hora;

            // Se a hora ainda não estiver no objeto de horários divididos, crie um array vazio
            if (!horariosDivididos[hora]) {
                horariosDivididos[hora] = [];
            }

            // Adicionar o item atual ao array correspondente à hora
            horariosDivididos[hora].push(item);
        });

        // Converter o objeto de horários divididos em um array
        const horariosArray = Object.entries(horariosDivididos).map(([hora, items]) => ({ hora, items }));

        return horariosArray;
    }

    const generateWeekdaysForSubmit = (data: any) => {
        console.log(data)
        const dataNewWeekDays: any = [
            data.domainDaysOfWeek.dom === 1 ? { "idDomainDaysOfWeek": 1 } : false,
            data.domainDaysOfWeek.seg === 1 ? { "idDomainDaysOfWeek": 2 } : false,
            data.domainDaysOfWeek.ter === 1 ? { "idDomainDaysOfWeek": 3 } : false,
            data.domainDaysOfWeek.qua === 1 ? { "idDomainDaysOfWeek": 4 } : false,
            data.domainDaysOfWeek.qui === 1 ? { "idDomainDaysOfWeek": 5 } : false,
            data.domainDaysOfWeek.sex === 1 ? { "idDomainDaysOfWeek": 6 } : false,
            data.domainDaysOfWeek.sab === 1 ? { "idDomainDaysOfWeek": 7 } : false,
        ]

        // Filtra os valores falsos do array
        const filteredDataNewWeekDays = dataNewWeekDays.filter((item: boolean) => item !== false);

        return filteredDataNewWeekDays;
    }

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({});


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleInputChange = (e: any, capac: any, hora: any) => {
        // setNumber(e.target.value);
        // setValue('number', e.target.value);
        let teste = capacidades;
        teste[hora] = { 'capacidade': e.target.value };
        setCapacidades(teste)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleInputChangeChanel = (e: any, canal: any, hora: any) => {

        // setNumber(e.target.value);
        // setValue('number', e.target.value);
        let teste = capacidades;
        // let testeCnal = `{${canal}: ${e.target.value}}`
        // teste[hora].push(testeCnal);
        setCapacidades(teste)
    }

    const onSubmit = (data: any) => {
        const domainDays = generateWeekdaysForSubmit(data);
        let stockGeneral: any = data.stockGeneralOnSale;

        console.log()
        stockGeneral[0].stockAmounts = stockGeneral[0].stockAmounts.filter((elem: any) => elem !== null)

        const dataStock = {
            "productCode": prodCode,
            "prodModCodeUnique": modCode,
            "tarCodeUnique": tarCode,
            "domainDaysOfWeek": domainDays,
            "stockGeneralOnSale": stockGeneral//data.stockGeneralOnSale
        }

        sendStock(dataStock);
    }

    const sendStock = async (objectStock: object) => {
        setModalResponse("loading");

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const str = JSON.stringify(objectStock);
            const { data } = await api.post(isEdit ? 'Stock/EditStockGeneral' : 'Stock/InsertStockGeneral', str, config);
            if (data.statusCode !== 400) {
                if (data?.data?.log === 0) {
                    setModalResponse("success");
                    setModalText(data.data.texto);
                    setLog(data.data.log);
                } else {
                    setModalResponse("error");
                    setModalText(data.data[0].texto);
                    setLog(data.data[0].log);
                }

            } else {
                setModalText("Erro ao cadastrar estoque. Entre em contato com o suporte.");
                //setModalText(data.data.texto);
                setModalResponse("error");
                setLog(data.data.log);
            }

        } catch (error: any) {
            if (error?.response?.status === 400) {
                setModalResponse("error");
                // setErrors(error.response.data.errors)
            }
        }
    }

    function generateWeekdays(weeks: any) {

        const newWeekDays: any = [
            {
                state: weeks.dom === 1 ? 1 : 0,
                day: 'dom'
            },
            {
                state: weeks.seg === 1 ? 1 : 0,
                day: 'seg'
            },
            {
                state: weeks.ter === 1 ? 1 : 0,
                day: 'ter'
            },
            {
                state: weeks.qua === 1 ? 1 : 0,
                day: 'qua'
            },
            {
                state: weeks.qui === 1 ? 1 : 0,
                day: 'qui'
            },
            {
                state: weeks.sex === 1 ? 1 : 0,
                day: 'sex'
            },
            {
                state: weeks.sab === 1 ? 1 : 0,
                day: 'sab'
            }
        ];

        //Linhas abaixo usadas para resolver o problema de inconsistências nos dias da semana caso mude de tarifário várias vezes
        setValue(`domainDaysOfWeek.dom`, weeks.dom === 1 ? 1 : 0);
        setValue(`domainDaysOfWeek.seg`, weeks.seg === 1 ? 1 : 0);
        setValue(`domainDaysOfWeek.ter`, weeks.ter === 1 ? 1 : 0);
        setValue(`domainDaysOfWeek.qua`, weeks.qua === 1 ? 1 : 0);
        setValue(`domainDaysOfWeek.qui`, weeks.qui === 1 ? 1 : 0);
        setValue(`domainDaysOfWeek.sex`, weeks.sex === 1 ? 1 : 0);
        setValue(`domainDaysOfWeek.sab`, weeks.sab === 1 ? 1 : 0);

        return newWeekDays;
    }

    const handleBlurChannelAmount = (amount: number, arrayIndex: number, indexHour: number, indexChannel: any) => {
        clearErrors();

        let data: any = getValues();
        let somaCanais: number;

        somaCanais = data.stockGeneralOnSale[arrayIndex].stockAmounts[indexHour].StockAmountsByChannel.map((elem: any) => Number(elem.Amount || 0)).reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);

        setValue(`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].Amount`, somaCanais)
    }

    const resetValues = () => {
        let data: any = getValues();
        let thisHour: any;
        tariffsInfo?.data.forEach((tariff: any, arrayIndex: number) => {
            thisHour = organizeData(tariff);

            thisHour.forEach((hour: any, indexHour: number) => {
                setValue(`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].Amount`, '');

                hour.items.forEach((item: any, indexChannel: number) => {
                    setValue(`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].StockAmountsByChannel[${indexChannel}].Amount`, '')
                    setValue(`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].StockAmountsByChannel[${indexChannel}].IdSalesChannel`, '')
                });
            });

            thisHour.forEach((hour: any, indexHour: number) => {
                delete data.stockGeneralOnSale[arrayIndex].stockAmounts[indexHour];
            });
        });

        setValue(`domainDaysOfWeek.dom`, 0);
        setValue(`domainDaysOfWeek.seg`, 0);
        setValue(`domainDaysOfWeek.ter`, 0);
        setValue(`domainDaysOfWeek.qua`, 0);
        setValue(`domainDaysOfWeek.qui`, 0);
        setValue(`domainDaysOfWeek.sex`, 0);
        setValue(`domainDaysOfWeek.sab`, 0);
    }

    return (
        <>
            {tariffsInfo !== null &&
                <>
                    <Card className="bg-white my-4">
                        {
                            loading ?
                                <>
                                    <div className="py-5">
                                        <div className="load primary"></div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='p-4'>
                                        <h5 className="title_stock py-0">Controle de Estoque</h5>
                                        {
                                            tariffsInfo?.data.map((tariff: any, index: any) => {
                                                let thisHour = organizeData(tariff)
                                                let weekdays = generateWeekdays(tariff);
                                                let arrayIndex = index;

                                                console.log(tariffsInfo.data.length)
                                                return (
                                                    <div className="bg-stock-control">
                                                        <h6 className="sub_title_stock">Estoque {index + 1 > 9 ? (index + 1) : `0${index + 1}`}</h6>
                                                        <div>
                                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                                <Row className="mb-5">
                                                                    <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                                        <Form.Label>Data Início</Form.Label>
                                                                        <InputGroup hasValidation className="ranger-calendar">
                                                                            <Controller
                                                                                control={control}
                                                                                name={`stockGeneralOnSale[${arrayIndex}].stockGeneralRangeDates.startDate`}
                                                                                defaultValue={tariff.tarifStart.split("T")[0]}
                                                                                render={({ field }: any) => (
                                                                                    <SingleCalendarStock startDate={tariff.tarifStart} nameForm={`stockGeneralOnSale[${index}].startDate`} />
                                                                                )}
                                                                            />
                                                                        </InputGroup>

                                                                    </Form.Group>

                                                                    <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                                        <Form.Label>Data Fim</Form.Label>
                                                                        <InputGroup hasValidation className="ranger-calendar">
                                                                            <Controller
                                                                                control={control}
                                                                                name={`stockGeneralOnSale[${arrayIndex}].stockGeneralRangeDates.endDate`}
                                                                                defaultValue={tariff.tarifEnd.split("T")[0]}
                                                                                render={({ field }: any) => (
                                                                                    <SingleCalendarStock endDate={tariff.tarifEnd} nameForm={`stockGeneralOnSale[${index}].endDate`} />
                                                                                )}
                                                                            />
                                                                        </InputGroup>

                                                                    </Form.Group>
                                                                </Row>

                                                                <Row className="mb-5 ">
                                                                    <Col md={4}>
                                                                        <Form.Group className="pr-4">
                                                                            <div className="d-flex justify-content-between">
                                                                                <Form.Label className="label-small">
                                                                                    Horários de funcionamento
                                                                                </Form.Label>
                                                                            </div>
                                                                            <div className="d-flex justify-content-start gap-3">
                                                                                {
                                                                                    weekdays.map((elem: any, index: any) => {
                                                                                        setValue(`domainDaysOfWeek.${elem.day}`, elem.state)
                                                                                        if (elem.state === 1) {
                                                                                            return (
                                                                                                <div>
                                                                                                    <div className="d-flex w-auto">
                                                                                                        <Controller
                                                                                                            control={control}
                                                                                                            name={`domainDaysOfWeek.${elem.day}`}
                                                                                                            defaultValue={elem.state}
                                                                                                            render={({ field }: any) => (
                                                                                                                <Form.Control
                                                                                                                    {...field}
                                                                                                                    type="hidden"
                                                                                                                    variant="standard"
                                                                                                                    margin="normal"
                                                                                                                    autoComplete="off"
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                        <div>

                                                                                                            <Form.Label className={elem.value === false ? "btn btn-outline-primary label-small" : "btn btn-primary label-small"}>
                                                                                                                <span className="text-capitalize">{elem.day}</span>
                                                                                                            </Form.Label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            return <></>
                                                                                        }
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>

                                                                </Row>

                                                                {/* <Row>
                                                                <Form.Group
                                                                    as={Col}
                                                                    md="12"
                                                                    className="d-flex justify-content-start pb-4"
                                                                >
                                                                    <Controller
                                                                    control={control}
                                                                    name="bannerCheck"
                                                                    // rules={{ required: { value: true, message: "required" }, }}
                                                                    render={({ field }: any) => (
                                                                        <Form.Check
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        label="Compartilhar estoque entre canais"
                                                                        // onChange={(e: any) => handleCheck(e)}
                                                                        />
                                                                    )}
                                                                    />
                                                                </Form.Group>
                                                            </Row> */}


                                                                {thisHour !== null &&
                                                                    <Row>
                                                                        {thisHour.map((hour: any, index: any) => {
                                                                            let indexHour = index;
                                                                            setValue(`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].StartHour`, hour.hora);
                                                                            setValue(`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].EndHour`, '');
                                                                            return (
                                                                                <>
                                                                                    <div className="font-sub-title" >{hour.hora.split(':')[0] + ':' + hour.hora.split(':')[1]}</div>
                                                                                    <Form.Group className="mr-2 mb-3" as={Col} md="2">
                                                                                        <Form.Label className="">Capacidade Total</Form.Label>
                                                                                        <Controller
                                                                                            control={control}
                                                                                            name={`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].Amount`}
                                                                                            defaultValue={''}
                                                                                            render={({ field }: any) => (
                                                                                                <Form.Control
                                                                                                    {...field}
                                                                                                    type="text"
                                                                                                    variant="standard"
                                                                                                    margin="normal"
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={''}
                                                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    disabled
                                                                                                //onFocus={(e: any) => { setAmountOnFocus(e.target.value || 0)} }
                                                                                                //onBlur={(e: any) => { handleBlurTotalAmount(e.target.value, arrayIndex, indexHour) }}
                                                                                                // onChange={(e) => handleInputChange(e, 'capacidade total', hour.hora)}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            errors={errors}
                                                                                            name={`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].Amount`}
                                                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                                        />
                                                                                    </Form.Group>

                                                                                    <Controller
                                                                                        control={control}
                                                                                        name={`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].StartHour`}
                                                                                        defaultValue={hour.hora}
                                                                                        render={({ field }: any) => (
                                                                                            <Form.Control
                                                                                                {...field}
                                                                                                type="hidden"
                                                                                                variant="standard"
                                                                                                margin="normal"
                                                                                                autoComplete="off"
                                                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                    <Controller
                                                                                        control={control}
                                                                                        name={`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].EndHour`}
                                                                                        defaultValue={''}
                                                                                        render={({ field }: any) => (
                                                                                            <Form.Control
                                                                                                {...field}
                                                                                                type="hidden"
                                                                                                variant="standard"
                                                                                                margin="normal"
                                                                                                autoComplete="off"
                                                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                            />
                                                                                        )}
                                                                                    />

                                                                                    <span className="mb-2">Capacidade por canal:</span>
                                                                                    <div className="d-flex mb-5">
                                                                                        {hour.items.map((item: any, index: Key) => {
                                                                                            let indexChannel = index;
                                                                                            setValue(`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].StockAmountsByChannel[${indexChannel}].IdSalesChannel`, item.canal)
                                                                                            return (
                                                                                                <>
                                                                                                    <Form.Group className="d-flex mr-2">
                                                                                                        <Form.Label className="nome-canal text-wrap-custom wrap-1">{item.nomeCanal}</Form.Label>
                                                                                                        <div>
                                                                                                            <Controller
                                                                                                                control={control}
                                                                                                                name={`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].StockAmountsByChannel[${indexChannel}].Amount`}
                                                                                                                render={({ field }: any) => (
                                                                                                                    <Form.Control
                                                                                                                        {...field}
                                                                                                                        type="text"
                                                                                                                        variant="standard"
                                                                                                                        margin="normal"
                                                                                                                        autoComplete="off"
                                                                                                                        className="input-canal"
                                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                                        onBlur={(e: any) => handleBlurChannelAmount(e.target.value, arrayIndex, indexHour, indexChannel)}
                                                                                                                    // onChange={(e) => handleInputChangeChanel(e, item.canal, hour.hora)}
                                                                                                                    />
                                                                                                                )}
                                                                                                            />
                                                                                                            <ErrorMessage
                                                                                                                errors={errors}
                                                                                                                name={`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].StockAmountsByChannel[${indexChannel}].Amount`}
                                                                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </Form.Group>
                                                                                                    <Controller
                                                                                                        control={control}
                                                                                                        name={`stockGeneralOnSale[${arrayIndex}].stockAmounts[${indexHour}].StockAmountsByChannel[${indexChannel}].IdSalesChannel`}
                                                                                                        defaultValue={item.canal}
                                                                                                        render={({ field }: any) => (
                                                                                                            <Form.Control
                                                                                                                {...field}
                                                                                                                type="hidden"
                                                                                                                variant="standard"
                                                                                                                margin="normal"
                                                                                                                autoComplete="off"
                                                                                                            />
                                                                                                        )}
                                                                                                    />
                                                                                                </>
                                                                                            )
                                                                                        })}

                                                                                    </div>


                                                                                </>
                                                                            )
                                                                        })}

                                                                    </Row>
                                                                }

                                                                {
                                                                    tariffsInfo.data.length - 1 === index
                                                                    &&
                                                                    <Row className="mx-0 mt-5 text-right pb-4">
                                                                        <Button
                                                                            variant="outline-primary"
                                                                            className="form-button mr-3"
                                                                        // onClick={() => action('modalities')}
                                                                        >
                                                                            Voltar
                                                                        </Button>
                                                                        <Button
                                                                            type="submit"
                                                                            className="form-button"
                                                                        >
                                                                            Salvar Estoques
                                                                        </Button>
                                                                    </Row>
                                                                }
                                                            </Form>
                                                        </div>

                                                    </div>
                                                )
                                            })}

                                    </div>
                                </>
                        }
                    </Card>
                </>
            }
            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                <div className="btn-sucess">
                    <Modal
                        className="modal-custom modalAuth"
                        show={modalResponse !== null}
                        onHide={() => setModalResponse(null)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <>
                            {/* <Modal.Header closeButton></Modal.Header> */}
                            <RequestResponseModal
                                name={"Fornecedor"}
                                modalResponse={modalResponse}
                                setModalResponse={setModalResponse}
                                errors={errors}
                                modalText={modalText}
                                log={log}
                            />
                        </>
                    </Modal>
                </div>
            </Row>

        </>
    );
}



export default TableStock;
