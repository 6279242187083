import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import api from "../../../services/api";

import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { addDescriptionAndHigh } from "../../../store/product";
import "./DescriptionAndHigh.scss";
import DraggableProductImputs from "../../DraggableProductImputs/DraggableProductImputs";
import ReactQuillComponent from "../../ReactQuillComponent/ReactQuillComponent";

export interface operation {
  action: any;
  receiveObject: any;
  tourObject: any;
  isEdit: any;
  isTicket?: boolean;
  editFunction?: any;
}

const DescriptionAndHigh: React.FC<operation> = React.memo(({
  action, receiveObject, tourObject, isEdit, isTicket = false, editFunction = () => { }
}: operation) => {
  /* Controle Redux  */
  const dispatch = useAppDispatch();
  const tourSIG: any = JSON.parse(
    JSON.stringify(useAppSelector((state: any) => state.tour))
  );
  /* Controle Redux  */

  const [descriptionPT, setDescriptionPT] = useState<string>("");
  const [descriptionEN, setDescriptionEN] = useState<string>("");
  const [descriptionES, setDescriptionES] = useState<string>("");

  const [valNamePt, setValNamePt] = useState<boolean>(false);
  const [valNameEs, setValNameEs] = useState<boolean>(false);
  const [valNameEn, setValNameEn] = useState<boolean>(false);

  const [allowEditDescription, setAllowEditDescription] = useState<boolean>(false);
  const [allowEditHighlights, setAllowEditHighlights] = useState<boolean>(false);

  const [highlightsArray, setHighlightsArray] = useState<any>([
    {
      PT: "",
      EN: "",
      ES: "",
    },
  ]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    window.scrollTo(0, 0);
    if (tourSIG.tour.description !== undefined) {
      setDescriptionPT(tourSIG.tour.description[0].text);
      setValue("descriptionPT", tourSIG.tour.description[0].text);
      setDescriptionEN(tourSIG.tour.description[1].text);
      setValue("descriptionPT", tourSIG.tour.description[0].text);
      setDescriptionES(tourSIG.tour.description[2].text);
      setValue("descriptionPT", tourSIG.tour.description[0].text);
    }
    if (tourSIG.tour.ticketDescription !== undefined) {
      setDescriptionPT(tourSIG.tour.ticketDescription[0].text);
      setValue("descriptionPT", tourSIG.tour.ticketDescription[0].text);
      setDescriptionEN(tourSIG.tour.ticketDescription[1].text);
      setValue("descriptionPT", tourSIG.tour.ticketDescription[0].text);
      setDescriptionES(tourSIG.tour.ticketDescription[2].text);
      setValue("descriptionPT", tourSIG.tour.ticketDescription[0].text);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTicketObject = (data: any) => {
    data.isTicket = true;

    data.ticketDescription = [
      {
        text: descriptionPT,
        lang: 1,
      },
      {
        text: descriptionEN,
        lang: 2,
      },
      {
        text: descriptionES,
        lang: 3,
      },
    ];

    return data;
  }

  const createTourObject = (data: any) => {
    data.description = [
      {
        text: descriptionPT,
        lang: 1,
      },
      {
        text: descriptionEN,
        lang: 2,
      },
      {
        text: descriptionES,
        lang: 3,
      },
    ];

    data.highLight = highlightsArray
      .filter((e: any) => e !== null)
      .map((e: any) => [
        { text: e.PT, lang: 1 },
        { text: e.EN, lang: 2 },
        { text: e.ES, lang: 3 },
      ]);

    delete data.descriptionPT;
    delete data.descriptionEN;
    delete data.descriptionES;

    delete data.highlightsPT;
    delete data.highlightsEN;
    delete data.highlightsES;

    return data;
  }

  const onSubmit = (data: any) => {
    let newTourObject: any = { ...tourObject, ...data };
    let dataToInsert: any;

    if (isTicket) {
      dataToInsert = createTicketObject(data);
    } else {
      dataToInsert = createTourObject(data);
    }

    if (isEdit !== true) {
      if (valNamePt === true && valNameEn === true && valNameEs === true) {
        dispatch(addDescriptionAndHigh(dataToInsert));
        action("photo", newTourObject);
      }
    } else {
      editFunction(dataToInsert);
      //receiveObject(data);
    }
  };

  const handleBlurDescription = (e: any) => {
    if (tourObject.isTranslate === 1 && allowEditDescription === false) {
      let ptText: any = getValues().descriptionPT;
      TranslateTextEN(ptText);
      TranslateTextES(ptText);
    }
  };

  const TranslateTextEN = async (name: string) => {
    if (name !== undefined) {
      try {
        const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
          "q": name,
          "source": "pt-BR",
          "target": "en",
          "format": "text"
        });
        if (res.status !== 400) {
          setDescriptionEN(res?.data?.data?.translations[0]?.translatedText);
          setValue("descriptionEN", res?.data?.data?.translations[0]?.translatedText);
        }
      } catch (error: any) { }
    }
  };

  const TranslateTextES = async (name: string) => {
    if (name !== undefined) {
      try {
        const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
          "q": name,
          "source": "pt-BR",
          "target": "es",
          "format": "text"
        });
        if (res.status !== 400) {
          setDescriptionES(res?.data?.data?.translations[0]?.translatedText);
          setValue("descriptionES", res?.data?.data?.translations[0]?.translatedText);
        }
      } catch (error: any) { }
    }
  };

  let numberPT: any = descriptionPT.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;
  let numberES: any = descriptionES.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;
  let numberEN: any = descriptionEN.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;

  useEffect(() => {
    if (descriptionPT !== undefined) {
      if (numberPT < 100) {
        setValNamePt(false);
      } else {
        setValNamePt(true);
      }
    }

    if (descriptionES !== undefined) {
      if (numberES < 100) {
        setValNameEs(false);
      } else {
        setValNameEs(true);
      }
    }

    if (descriptionEN !== undefined) {
      if (numberEN < 100) {
        setValNameEn(false);
      } else {
        setValNameEn(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionPT, descriptionES, descriptionEN]);

  return (
    <>
      {
        (isEdit === false || tourSIG?.tour?.productName) ?
          <Col md={9} lg={10}>
            <Card className="pt-content summary">
              <Form
                className="h-100"
                noValidate
                validated={false}
                onSubmit={handleSubmit(onSubmit)}
              >
                <h3 className="text-primary pt-title mb-0">
                  {isTicket ? "Descrição" : "Descrição e Destaques"}
                </h3>

                <div className="pt-content-info">
                  <Row>
                    <div>
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-2 pt-subtitle">A Experiência</h5>
                        {tourObject.isTranslate === 1 && (
                          <>
                            <div
                              className="btn-edit"
                              onClick={() =>
                                setAllowEditDescription(!allowEditDescription)
                              }
                            >
                              <FontAwesomeIcon
                                icon={["fal", "edit"]}
                                size="1x"
                                className="mr-2"
                              />
                              Editar
                            </div>
                          </>
                        )}
                      </div>

                      <p className="small mb-0">
                        Descreva o produto de maneira clara e atraente.
                      </p>
                      <p className="small mb-0">
                        Acrescentar detalhes específicos, ajudando o viajante a
                        entender o itinerário e ordem de atrações. Mais
                        importante: faça-o divertido!
                      </p>
                    </div>

                    <ReactQuillComponent
                      setValue={setValue}
                      control={control}
                      handleBlur={handleBlurDescription}
                      name="descriptionPT"
                      title="Descrição do Tour (Português)"
                      errors={errors}
                      setState={setDescriptionPT}
                      state={descriptionPT}
                      isEdit={isEdit}
                      size={12}
                      numberOfCaracters={numberPT}
                      maxLength={2100}
                      minLength={100}
                      id="portugueseText"
                    />

                    <ReactQuillComponent
                      setValue={setValue}
                      control={control}
                      name="descriptionEN"
                      title="Descrição do Tour (Inglês)"
                      errors={errors}
                      setState={setDescriptionEN}
                      state={descriptionEN}
                      isEdit={isEdit}
                      size={6}
                      numberOfCaracters={numberEN}
                      maxLength={2100}
                      minLength={100}
                      id="englishText"
                      style={
                        tourObject.isTranslate === 1 &&
                          allowEditDescription === false
                          ? { backgroundColor: "#e9ecef", opacity: "1" }
                          : {}
                      }
                    />

                    <ReactQuillComponent
                      setValue={setValue}
                      control={control}
                      name="descriptionES"
                      title="Descrição do Tour (Espanhol)"
                      errors={errors}
                      setState={setDescriptionES}
                      state={descriptionES}
                      isEdit={isEdit}
                      size={6}
                      numberOfCaracters={numberES}
                      maxLength={2100}
                      minLength={100}
                      id="spanishText"
                      style={
                        tourObject.isTranslate === 1 &&
                          allowEditDescription === false
                          ? { backgroundColor: "#e9ecef", opacity: "1" }
                          : {}
                      }
                    />
                  </Row>
                </div>

                {!isTicket &&
                  <div className="pt-content-info" style={{ border: "none" }}>
                    <Row>
                      <div>
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-2 pt-subtitle">Destaques</h5>
                          {tourObject.isTranslate === 1 && (
                            <>
                              <div
                                className="btn-edit ml-2"
                                style={{ position: "unset" }}
                                onClick={() =>
                                  setAllowEditHighlights(!allowEditHighlights)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={["fal", "edit"]}
                                  size="1x"
                                  className="mr-2"
                                />
                                Editar
                              </div>
                            </>
                          )}
                        </div>
                        <p className="small mb-0">
                          Insira tudo que um viajante precisa saber antes de comprar
                          seu produto.
                        </p>
                        <p className="small mb-0">
                          Pense em logística, restições e requisitos.
                        </p>
                      </div>

                      <DraggableProductImputs
                        items={highlightsArray}
                        setItems={setHighlightsArray}
                        tourObject={tourObject}
                        allowEditItems={allowEditHighlights}
                        portuguese={`highlightsPT`}
                        english={"highlightsEN"}
                        spanish={"highlightsES"}
                        address={"highLight"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        minLength={10}
                        maxLength={100}
                        setError={setError}
                        clearErrors={clearErrors}
                      />
                    </Row>
                  </div>
                }

                <div className="h-100 d-flex justify-content-end align-items-end">
                  {isEdit !== true ? (
                    <Row className="mx-0 mt-5">
                      <Button
                        variant="outline-primary"
                        className="form-button mr-3"
                        onClick={() => action("summaryAndDetails")}
                      >
                        Voltar
                      </Button>
                      <Button type="submit" className="form-button">
                        Continuar
                      </Button>
                    </Row>
                  ) : (
                    <Row className=" mx-0 mt-5">
                      <Button
                        type="submit"
                        className="form-button"
                      >
                        Salvar
                      </Button>
                    </Row>
                  )}
                </div>
              </Form>
            </Card>
          </Col>
          :
          <>
            <Col md={9} lg={10}>
              <Card className="pt-content h-100">
                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                  <div className="load mb-4"></div>
                  <div>Carregando dados do produto</div>
                </div>
              </Card>
            </Col>
          </>
      }
    </>
  );
}
);

export default DescriptionAndHigh;
