import React, { useState, useEffect } from "react";
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";
import Toolbar from "react-multi-date-picker/plugins/toolbar"

import './Calendar.scss';

const numberMonths = window.innerWidth < 480 ? 1 : 2;


const CustomMultipleInput = ({ openCalendar, value, date, setDate }: any) => {
    /* useEffect(()=>{
        setDate(value)
    },[value]) */

    value[0] = date[0]
    value[1] = date[1]

    return (
        <input
            className="form-control"
            onFocus={openCalendar}
            value={value}
            autoComplete="off"
            //readOnly
        />
    )
}

function handleChange(e: any, setDate: any) {
    if (e.length === 1) {
        setDate([e[0].day + "/" + e[0].month + "/" + e[0].year])
    } else if (e.length === 2) {
        setDate([e[0].day + "/" + e[0].month + "/" + e[0].year, e[1].day + "/" + e[1].month + "/" + e[1].year])
    }
}


const RangeCalendar = ({ date, setDate }: any) => {
    const { t } = useTranslation();

    function clear(e: any) {
        if (e.target.classList.length === 0 && e.target.innerHTML === "Limpar") {
            setDate('');
        }
    }

    return (
        <DatePicker
            numberOfMonths={numberMonths}
            disableMonthPicker
            disableYearPicker
            weekDays={t('calendar.weekDays').split(",")}
            months={t('calendar.months').split(",")}
            format={configData.DATE.PT}
            range
            onChange={(e: any) => { handleChange(e, setDate) }}
            render={<CustomMultipleInput date={date} setDate={setDate} />}
            plugins={[
                <Toolbar
                    position="bottom"
                    className="bottons-calendar"
                    sort={["deselect", "close"]}
                    names={{
                        today: "select today",
                        deselect: "Limpar",
                        close: "Ok"
                    }}
                    onClick={(e: any) => { clear(e) }}
                />
            ]}
        />
    )
}

export default RangeCalendar;



