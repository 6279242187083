import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './PictureOfTheDay.scss'
import { Container, Row, Col } from 'react-bootstrap';
//import { useTranslation } from 'react-i18next';

//COMPONENTS

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import FilterPictureOfTheDay from '../components/FilterPictureOfTheDay/FilterPictureOfTheDay';
import TablePictureOfTheDay from '../components/TablePictureOfTheDay/TablePictureOfTheDay';
import SumOfTheMonth from '../components/SumOfTheMonth/SumOfTheMonth';
import ModalQuestion from '../../../components/Modal/ModalQuestion/ModalQuestion';
import DesktopDefault from '../../../templates/DesktopDefault';
//import { Link } from 'react-router-dom';
//import GlobalAlert from '../../../components/Alert/GlobalAlert/GlobalAlert';

//ICONS
/* import iconGlobe from '../../assets/icons/fa-globe-americas.svg'; */

//import objectPartners from '../../../components/Partners/PartnersTable/locales/partners.json'


function PictureOfTheDay() {
    //const { t } = useTranslation();
    const [sessions, setSessions] = useState<any>(null);
    const [totalRecebimentos, setTotalRecebimentos] = useState<any>(0);
    const [totalPagamentos, setTotalPagamentos] = useState<any>(0);
    const [totalMargem, setTotalMargem] = useState<any>(0);
    const [taxaMargem, setTaxaMargem] = useState<any>(0);
    const [loading, setLoading] = useState<any>(true);

    /* sum of the month */
    const [totalRecebimentosMes, setTotalRecebimentosMes] = useState<any>(0);
    const [totalPagamentosMes, setTotalPagamentosMes] = useState<any>(0);
    const [totalMargemMes, setTotalMargemMes] = useState<any>(0);
    const [taxaMargemMes, setTaxaMargemMes] = useState<any>(0);

    const [totalNotasEmitidasMes, setTotalNotasEmitidasMes] = useState<any>(0);
    const [totalNotasEmitidasDia, setTotalNotasEmitidasDia] = useState<any>(0);
    const [totalNotasEmitirDia, setTotalNotasEmitirDia] = useState<any>(0);
    const [loadingSum, setLoadingSum] = useState<any>(true);
    /* END - sum of the month */

    const [issuePendInvoicesModalShow, setIssuePendInvoicesModalShow] = React.useState(false);

    const [error, setError] = useState<any>();

    /* Filter */
    const [filterDate, setFilterDate] = useState<any>('');
    /* END - Filter */

    const hoje = new Date();
    const ontem = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()-1); // hoje - 1 dia = ontem
    const dataPadrao = ontem.toISOString().substring(0,10);

    function calcula(data: any) {
        var recebe = 0; 
        var paga = 0;

        for(var i = 0; i < data.length; i++) {
            recebe += data[i].totalRecebimentos;
            paga += data[i].totalPagamentos;
        }

        const margem = recebe - paga;

        setTotalRecebimentos(recebe);
        setTotalPagamentos(paga);
        setTotalMargem(margem);

        if(recebe == 0) {
            setTaxaMargem(0);
        } else {
            setTaxaMargem((margem / recebe) * 100);
        }
    }

    useEffect(() => {
        setFilterDate(dataPadrao);
        buscar(dataPadrao);
    }, [//pageCount, rowsPerPage
    ]);

    async function buscar(date: any) {
        console.log(date);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        setLoading(true);
        setLoadingSum(true);
        try {
            const res = await api.post('SessionClosure/GetPictureOfTheDayReportAsync',
                {
                    "date": date,
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                setSessions(res.data.data);

                calcula(res.data.data);
            
                setLoading(false);

                const res2 = await api.post('SessionClosure/GetSumOfTheMonthAsync',
                    {
                        "date": date,
                        //"orderByField": ""
                    }, config
                );

                if (res2.status !== 400) {
                    const dados = res2.data.data;
                    
                    //console.log(dados.totalRecebimentos, "recebimentos");
                    setTotalRecebimentosMes(dados.totalRecebimentos);
                    setTotalPagamentosMes(dados.totalPagamentos);
                    setTotalMargemMes(dados.totalMargem);
                    setTaxaMargemMes(dados.taxaMargem);

                    setTotalNotasEmitidasMes(dados.totalNotasEmitidasMes);
                    setTotalNotasEmitidasDia(dados.totalNotasEmitidasDia);
                    setTotalNotasEmitirDia(dados.totalNotasEmitirDia);

                    setLoadingSum(false);
                }
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function refreshData() {
        await buscar(filterDate);
    }

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
//console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
          let link = document.createElement('a');
          link.href = blob;//window.URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
          link.remove();
          window.URL.revokeObjectURL(link.href);
        //}
    };

    async function exportReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/ExportPictureOfTheDayReportAsync',
                {
                    "date": filterDate,
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                //download file
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/PrintPictureOfTheDayReportAsync',
                {
                    "date": filterDate,
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    function issuePendInvoices() {
        setIssuePendInvoicesModalShow(true);
    }
    
    async function issuePendInvoices1() {
        setIssuePendInvoicesModalShow(false);

        let userData = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');
        const user = userData.user;

        const uid = user.uId;
        const usuario = `${user.nome} ${user.lastName}`;

        const config = {
            headers: { 'Authorization': `Bearer ${userData.token.token}` },
        };

        try {
            const res = await api.post('SessionClosure/IssuePendInvoicesByDateAsync',
                {
                    "date": filterDate,
                    "uid": uid,
                    "usuario": usuario
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                
                alert(dados.texto);

                if(dados.log != 1) {
                    refreshData();
                }
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    }

    if (sessions !== null) {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard">
                        <Breadcrumb title={'Financeiro / Fechamento / Foto do Dia'} />
                        <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                            <h2 className="title" style={{ color: "#707070" }}>Foto do Dia</h2>
                        </div>
                        <FilterPictureOfTheDay
                            buscar={buscar}
                            sessions={sessions}
                            dataPadrao={dataPadrao}
                            error={error}
                            setFilterDate={setFilterDate}
                        />
                        <Row>
                            <Col md={8}>
                                <TablePictureOfTheDay
                                    sessions={sessions}
                                    totalRecebimentos={totalRecebimentos}
                                    totalPagamentos={totalPagamentos}
                                    totalMargem={totalMargem}
                                    taxaMargem={taxaMargem}
                                    exportReport={exportReport}
                                    printReport={printReport}
                                    loading={loading}
                                />
                            </Col>
                            <Col md={4}>
                                <SumOfTheMonth
                                    totalRecebimentos={totalRecebimentosMes}
                                    totalPagamentos={totalPagamentosMes}
                                    totalMargem={totalMargemMes}
                                    taxaMargem={taxaMargemMes}
                                    totalNotasEmitidasMes={totalNotasEmitidasMes}
                                    totalNotasEmitidasDia={totalNotasEmitidasDia}
                                    totalNotasEmitirDia={totalNotasEmitirDia}
                                    diaFechado={(totalNotasEmitirDia==0) ? 1 : 2}
                                    issuePendInvoices={issuePendInvoices}
                                    loading={loadingSum}
                                />
                            </Col>
                        </Row>
                    </div>
                    
                    <ModalQuestion 
                        modalShow={issuePendInvoicesModalShow}
                        setModalShow={setIssuePendInvoicesModalShow}
                        titulo="Emitir Nota Fiscal"
                        mensagem="Emitir Notas Pendentes e Encerrar o Faturamento do dia?"
                        handleActionConfirmModal={(e: any) => issuePendInvoices1()}
                        //handleActionCancelModal={handleActionCancelDeleteClick}//1=cliente;2=passageiro
                    />
                </DesktopDefault>
            </>
        );
    } else {
        return (
            <>
            </>
        )
    }
}

export default PictureOfTheDay;