import React, { Key, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../../../assets/sass/modal.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';
import NewUser from "../../../../../pages/Users/NewUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import api from '../../../../../services/api';

//import GetPaymentMethodsFilter from "../../../../C2Points/GetPaymentMethodsFilter";
//import GetAccountOperatorsFilter from "../../../../C2Points/GetAccountOperatorsFilter";
//import infoProducts from "../../../../InfoProducts/InfoProducts";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

export interface propModal {
  action: any,
  info: any
};

const FormBillReceivableItemEdit: React.FC<propModal> = ({
  action, info
}: propModal, props: any) => {
  const { t } = useTranslation();

  function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if (cell && cell != '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }

  }

  const [valorAtual, setValorAtual] = useState<any>(info.valor ? formatValue(info.valor) : 0);


  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: info.newItemsId,
      centroCusto: info.centroCustoId ? info.centroCustoId : null,
      valor: info.valor ? formatValue(info.valor) : '',
    },
  });

  const onSubmitForm = (dataObj: any) => {
    dataObj.valor = valorAtual;

    if (dataObj.valor != '') dataObj.valor = parseFloat(dataObj.valor.replace(',', '.'));

    let userData = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');
    const user = userData.user;

    dataObj.uid = user.uId;
    dataObj.usuario = `${user.nome} ${user.lastName}`;

    const config = {
      headers: { 'Authorization': `Bearer ${userData.token.token}` },
    };

    const salvar = async () => {
      try {
        const { data } = await api.post('BillsReceivable/UpdateItem', dataObj, config);
        /* console.log(data.data); */
        if (data.status !== 400) {
          var response = data.data;

          alert(response.texto);

          if (response.log != 1) {
            action(data);
          }
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
        if (error?.response?.status === 400) {
          //window.location.href = window.location.origin + '/session-closure';              
        }
        //console.log(error);
      }
    }
    salvar();
  };

  function handleValorChange(e: any) {
    const newValue = e.target.value;
    setValorAtual(newValue);
  }

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <h5 className="contact-title mb-5">Alterar Item</h5>
            <Form
              className="form-add-contact"
              noValidate
            >
              <div className="">
                <Row className="mb-3">
                  <Form.Group as={Col} md="3">
                    <Form.Label>Data:</Form.Label>
                    <Form.Control
                      type="date"
                      value={info.dataReserva}
                      autoComplete="off"
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="8">
                    <Form.Label>Serviço:</Form.Label>
                    <Form.Control
                      type="text"
                      value={info.tour}
                      autoComplete="off"
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="3">
                    <Form.Label>Valor:</Form.Label>
                    <Controller
                      control={control}
                      name="valor"
                      //rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          type="text"
                          aria-invalid={errors?.valor ? "true" : ""}
                          variant="standard"
                          margin="normal"
                          required
                          onChange={handleValorChange}
                          value={valorAtual}
                          autoComplete="off"
                        />
                      )}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>Nº:</Form.Label>
                    <Form.Control
                      type="text"
                      value={info.qtdePax}
                      autoComplete="off"
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Centro Custo:</Form.Label>
                    <Form.Control
                      type="text"
                      value={info.centroCusto}
                      autoComplete="off"
                      disabled
                    />
                  </Form.Group>
                </Row>
              </div>
            </Form>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(onSubmitForm)}
          >
            <FontAwesomeIcon
              icon={["fal", "save"]}
              size="1x"
              style={{ marginRight: "5px" }}
            /> Salvar
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormBillReceivableItemEdit;
