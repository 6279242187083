import React, { useEffect, useState } from "react";

//import { useTranslation } from 'react-i18next';

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import api from "../../services/api";
import DesktopDefault from "../../templates/DesktopDefault";
import TransactionAuditFilter from "./components/TransactionAuditFilter/TransactionAuditFilter";
import TransactionAuditTable from "./components/TransactionAuditTable/TransactionAuditTable";

function TransactionAudit() {
    //const { t } = useTranslation();
    const [transactions, setTransactions] = useState<any>(null);
    const [searchLog, setSearchLog] = useState<any>('');
    const [searchDate, setSearchDate] = useState<any>('');
    const [searchReference, setSearchReference] = useState<any>('');
    const [searchLevel, setSearchLevel] = useState<any>(0);

    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    useEffect(() => {

        async function initial() {
            setLoading(true);
            //setPageCount(1);


            let beginDate: any = []
            let endDate: any = []


            if (searchDate.length === 1) {
                beginDate = searchDate[0].split('/')
            } else if (searchDate.length === 2) {
                beginDate = searchDate[0].split('/')
                endDate = searchDate[1].split('/')
            }

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            try {
                const res = await api.post(`/Log/GetLogsByFilterAsync`,
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "timestampStartFind": searchDate.length > 0 ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : '',
                        "timestampEndFind": searchDate.length === 1 ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0]) + 1}` : searchDate.length === 2 ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : '',
                        "logGroupFind": searchLog,  // qual grupo de log é a ocorrencia (dropdown de log group)
                        "logLevelFind": searchLevel,
                        "referenceFind": searchReference
                    }, config
                );
                if (res.status !== 400) {
                    setLoading(false);
                    setTransactions(res.data.data.rows);
                    setTotalRows(res.data.data.rowsCount);
                }
            } catch (error: any) {
                setLoading(false);
                if (error?.response?.status === 401) {
                    /* window.location.href = window.location.origin + '/'; */
                }
            }
        };

        initial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage])

    async function buscar(log: string, date: any, reference: any, level: any) {
        setSearchLog(log);
        setSearchDate(date);
        setSearchReference(reference);
        setSearchLevel(level);
        setLoading(true);
        setPageCount(1);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = [];
        let endDate = [];

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        try {
            const res = await api.post(`/Log/GetLogsByFilterAsync`,
                {
                    "page": pageCount,
                    "rowsPerPage": rowsPerPage,
                    "timestampStartFind": date.length > 0 ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : '',
                    "timestampEndFind": date.length === 1 ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0]) + 1}` : date.length === 2 ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : '',
                    "logGroupFind": log,  // qual grupo de log é a ocorrencia (dropdown de log group)
                    "logLevelFind": level,
                    "referenceFind": reference
                }, config
            );
            if (res.status !== 400) {
                setLoading(false);
                setTransactions(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            setLoading(false);
            if (error?.response?.status === 401) {
                /* window.location.href = window.location.origin + '/'; */
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={"Auditoria de Transações"} />

                    <div className="mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Auditoria de Transações</h2>
                    </div>

                    <TransactionAuditFilter
                        buscar={buscar}
                    />

                    <TransactionAuditTable
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        transactions={transactions}
                        loading={loading}
                    />

                </div>
            </DesktopDefault>
        </>
    )
}

export default TransactionAudit;