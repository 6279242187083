import React, { useEffect, useState } from 'react';

import api from '../../../../../services/api';

import EditSuppliersDataFields from '../EditSuppliersDataFields/EditSuppliersDataFields';
//import { Link } from 'react-router-dom';


//STYLE
import './EditSuppliersData.scss'

export interface propInfo {
    info?: any;
};

const EditSuppliersData: React.FC<propInfo> = ({
    
}: propInfo) => {

    const [editSuppliers, setEditSuppliers] = useState<any>(null);
    const idSup = window.location.href.split('id=')[1];

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        const EditSupplier = async () => {
            try {
                const { data } = await api.get(`Supplier/GetSuppliersByIdAsync/${idSup}`, config);
                if (data.status !== 400) {
                    setEditSuppliers(data.data);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
                if (error?.response?.status === 400) {
                    window.location.href = window.location.origin + '/list-suppliers';
                }
            }
        }
        EditSupplier();
    }, [idSup])

    if (editSuppliers !== null) {
        return (
            <>
                <div className="edit-suppliers-data">
                    <div className="observation">
                        <div className="observation-content">
                            <p>Observação:</p>
                            <p>Dados inválidos</p>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex">
                            <div className="edit">
                                <EditSuppliersDataFields info={editSuppliers} />
                            </div>
                        </div>
                    </div>


                </div>
            </>
        );
    } else {
        return (
            <></>
        )
    }

}

export default EditSuppliersData;