import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {Dropdown} from 'react-bootstrap';

import Pagination from "../../../../components/Pagination/Pagination";

//STYLES
import "./BlackoutTable.scss";
import '../../../../assets/sass/accordion.scss';
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

export interface propTable {
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    blackouts: any;
    loading: any;
}

const BlackoutTable: React.FC<propTable> = ({
    totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, blackouts, loading
}: propTable) => {

    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <Dropdown drop="start" className="dropdown-custom">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                                size="lg"
                                icon={["fal", "ellipsis-h"]}
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey="2"
                                onClick={() => { window.location.href = `/blackout/edit-blackout?id=${cell}` }}
                            >
                                <FontAwesomeIcon
                                    icon={['fal', 'edit']}
                                />
                                <span>Editar</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    function addDate(cell: any, row: any) {
        return (
            <div>
                {`${new Date(row.blackoutDateStart).toLocaleDateString()} a ${new Date(row.blackoutDateEnd).toLocaleDateString()}`}
            </div>
        );
    }

    function addStatus(cell: any, row: any) {
        var status: string = (cell === 1 ? "Ativo" : "Inativo");

        return (
            <div
                className={status === "Ativo" ? "bg-reservation-ativo" : "bg-reservation-inativo"}
            >
                {status}
            </div>
        );
    }

    const columns = [
        { dataField: "blackoutName", text: "Nome do Blackout" },
        /* { dataField: "tour", text: "Produtos", formatter: addProducts }, */
        { dataField: "blackoutDateStart", text: "Período", formatter: addDate },
        { dataField: "blackoutStatus", text: "Status", formatter: addStatus },
        { dataField: "blackoutId", text: "Ação", formatter: addActionButton },
    ];

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando Blackouts", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "clientes", text: "Blackouts" }];

    const notFoundProducts = [{ clientes: "Nenhum blackout encontrado" }];
    /* [END] In case of empty table*/

    const rowClasses = (row: any) => {
        if (row.blackoutStatus === 3) {
            return 'border-analise';
        } else if (row.blackoutStatus === 1) {
            return 'border-ativo';
        } else if (row.blackoutStatus === 2) {
            return 'border-inativo';
        } else {
            return '';
        }
    };

    if (blackouts !== null) {
        //console.log(reservations)
        return (
            <>
                <div className="table-default blackout-table">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="mb-4">Blackouts</h3>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="blackoutId"
                            data={blackouts}
                            columns={columns}
                            rowClasses={rowClasses}
                        />

                        <Pagination
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            selectNumberRows="yes"
                        />
                    </div>
                </div>

            </>
        );
    } else if (blackouts === null && loading === true) {
        return (
            <>
                <div className="table-default reservations-table loading not-found">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default reservations-table not-found">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="">Blackouts</h3>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default BlackoutTable;
