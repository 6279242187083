import React, { useState, useEffect } from 'react';
import api from '../../services/api';

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import DesktopDefault from '../../templates/DesktopDefault';
import SingleUser from './components/SingleUser/SingleUser';
import RegisterUsers from './components/RegisterUsers/RegisterUsers';


function EditUser() {
    const [userInfo, setUserInfo] = useState<any>(null);
    const [isEdit, setIsEdit] = useState<any>(false);

    useEffect(() => {
        const idUser: any = window.location.href.split('?id=')[1];

        async function singleUser() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            try {
                const { data } = await api.post('/UserPermission/GetListUserPermissionById',
                    {
                        "IdUser": idUser,
                    }, config
                );
                if (data.statusCode !== 400) {
                    console.log(data.data.data[0])
                    setUserInfo(data.data.data[0]);
                    //setProfileImg(data.data.data[0].photoUrl)
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    //window.location.href = window.location.origin + '/';
                }
            }
        }
        singleUser();

    }, [])

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">

                    <Breadcrumb title={'Usuários / Editar Usuários'} />

                    <div className="register-users mt-4">
                        <h2 className="title">Editar Usuário</h2>
                    </div>

                    {isEdit === false
                        ?
                        <SingleUser userInfo={userInfo} setIsEdit={setIsEdit} />
                        :
                        <RegisterUsers isEdit={isEdit} userInfo={userInfo} setIsEdit={setIsEdit} />
                    }

                </div>

            </DesktopDefault>
        </>

    );
}

export default EditUser;