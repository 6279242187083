import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// import ProductOptions from "../ProductOptions/ProductOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ProductSlider from "../ProductCarrousel/ProductCarrousel";
import TicketOptions from "../ProductOptions/TicketOptions";

import "./InfoProducts.scss";

export interface tourList {
  tourDetails: any;
  setModalShow?: any;
}

const InfoProductsTicket = ({ tourDetails, setModalShow }: any) => {
  function scrollView(id: any) {
    var scrollElement = document.getElementById(id);
    scrollElement?.scrollIntoView({ behavior: "smooth" });
  }

  if (tourDetails !== null) {
    return (
      <div className="info-products pb-4">
        <div
          style={{
            backgroundImage: `url(${tourDetails.imagesBaseUrl}${tourDetails.images[0]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 style={{ color: "#FFF" }}>
            {tourDetails.productName.split("-", 1)}
          </h1>
          <div className="jumbotron">
            <Row>
              {/* <div className="col-12 col-md-6 subtitle-product pb-2">
                <h3 className="m-0">
                  {tourDetails.modalities[0].activityDuration}
                </h3>
                <span className="m-0 pb-3">duração total</span>
              </div>
              <div className="col-12 col-md-6 subtitle-product pb-2">
                <h3 className="m-0">{tourDetails.maxNumPeople}</h3>
                <span className="m-0">capacidade total</span>
              </div> */}
            </Row>
          </div>
        </div>
        <nav id="navbar-destino" className="navbar navbar-light bg-white">
          <div
            className="container text-center px-5"
            style={{ display: "block" }}
          >
            <ul className="nav-pills scroll-nav">
              <Row>
                <Col md={7} className="flex-column">
                  <Row>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-experience")}
                        >
                          {" "}
                          <p className="d-md-inline d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "grin-wink"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                              Experiência
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-options")}
                        >
                          {" "}
                          <p className="d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "list"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                              Opções
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-info")}
                        >
                          {" "}
                          <p className="d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "info-circle"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                              Informações
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-contact")}
                        >
                          {" "}
                          <p className="d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "phone-rotary"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                              Contato
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                  </Row>
                </Col>
                <Col md={5}>
                  <li className="nav-item">
                    <span
                      className="bg-primary nav-link link-tour nav-tour"
                      onClick={() => scrollView("session-options")}
                      style={{
                        width: "135px",
                        marginLeft: "auto",
                      }}
                    >
                      {" "}
                      <p
                        className="d-lg-inline text-md-uppercase"
                        style={{ color: "#FFF" }}
                      >
                        Ver Opções
                      </p>
                    </span>
                  </li>
                </Col>
              </Row>
            </ul>
          </div>
        </nav>
        <Container
          className="scroll-product"
          fluid
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <Row>
            <div className="col-12 col-md-12 text-info-products px-0 py-4">
              <ProductSlider arrayImages={tourDetails} />
              <h4>Outras Informações</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: tourDetails.productDescription,
                }}
              ></div>
              <div
                id="session-experience"
                className="col-12 mt-4 text-info-products"
              >
                <h4>A Experiência</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: tourDetails.productInfo,
                  }}
                ></div>
              </div>
            </div>
            <div id="session-options" className="col-12 mt-4 text-info-products">
              <h4>Opções</h4>
              <div>
                {/* <ProductOptions tourOptions={tourOptions} productCode={props.productCode} tourDetails={tourDetails}/> */}
                <div className="modal-prod-options">
                  {tourDetails.modalities.map((tour: any, index: any) => {
                    return (
                      <TicketOptions modality={tour} tourDetails={tourDetails} indexMod={index} setModalShow={setModalShow} isModal={true} />
                    )
                  })
                  }
                </div>
              </div>
            </div>
            {/* <div
              className="col-12 col-md-12 text-info-products mt-5"
              id="session-info"
            >
              <div>
                <h4>Informações Adicionais</h4>
                <div>
                  <DiscountRulesCaracol tourResponse={tourDetails} />
                </div>
              </div>
            </div> */}
            <div className="col-12 col-md-12 text-info-products mt-5">
              <div>
                <h4>Política de Cancelamento</h4>
                <ul>
                  <li>
                    <p>Fique tranquilo, pois seu ingresso tem validade de 7 dias a partir da data escolhida.</p>
                    {/* <p className="mb-0">Caso, ainda assim, deseje fazer o cancelamento, ele é gratuito se realizado em até 7 dias da data de confirmação do pagamento por meio do seu login e de sua senha de acesso no site.</p> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row m-0 rounded overflow-hidden my-4">
              <div className="col-12 col-sm-6 col-md- col-lg- col-xl-8 align-items-center d-flex bg-white px-3 py-2">
                <span id="questoes" className="py-0 m-0">
                  Código do Produto?
                </span>
              </div>

              <div className="col-12 col-sm-6 col-xl-4 p-0 bg-primary text-white align-items-center justify-content-center d-flex">
                <span id="product-code">
                  Código do Produto: {tourDetails.productCode}
                </span>
              </div>
            </div>
            <div className="row text-center" id="session-contact">
              <div>
                <div className="col-12 text-center">
                  <p className="my-2">
                    Por favor use um dos contatos abaixo caso você tenha uma
                    pergunta ou emergência.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <strong className="d-none">Telefone:</strong>
                <span className="d-block py-2" id="tel-info">
                  {process.env.REACT_APP_INFO_PHONE}
                </span>
                <a
                  href={`tel:${process.env.REACT_APP_INFO_PHONE}`}
                  title=""
                  className="btn btn-sm btn-outline-primary w-50"
                >
                  Telefone
                </a>
              </div>
              {/* <div className="col-12 col-md-4">
                <strong className="d-none">WhatsApp:</strong>
                <span className="d-block py-2" id="whats-info">
                  {process.env.REACT_APP_INFO_WHATSAPP}
                </span>
                <a
                  href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_INFO_WHATSAPP}`}
                  title=""
                  className="btn btn-sm btn-outline-primary w-50"
                >
                  Whatsapp
                </a>
              </div> */}
              <div className="col-12 col-md-6">
                <strong className="d-none">Email:</strong>
                <span className="d-block py-2" id="mail-info">
                  {" "}
                  {process.env.REACT_APP_INFO_EMAIL}
                </span>
                <a
                  href={`mailto:${process.env.REACT_APP_INFO_EMAIL}`}
                  title=""
                  className="btn btn-sm btn-outline-primary w-50"
                >
                  Email
                </a>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <>
        <div style={{ width: "100%", height: "150%" }}>
          <div className="animated-background" style={{ width: "100%", height: "361px", marginBottom: "10px" }}></div>
          <div className="d-flex justify-content-between" style={{ padding: "0px 42px" }}>
            <div className="d-flex">
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
            </div>
            <div className="animated-background" style={{ width: "150px", height: "60px" }}></div>
          </div>
          <div className="d-flex" style={{ padding: "24px 42px", backgroundColor: "#fff" }}>
            <div>
              <div className="animated-background" style={{ width: "300px", height: "198px", marginRight: "15px", marginBottom: "10px" }}></div>
              <div className="animated-background" style={{ width: "300px", height: "198px", marginRight: "15px", marginBottom: "10px" }}></div>
              <div className="animated-background" style={{ width: "300px", height: "198px", marginRight: "15px", marginBottom: "10px" }}></div>
            </div>
            <div>
              <div className="animated-background" style={{ width: "880px", height: "625px" }}></div>
            </div>
          </div>
          <div style={{ backgroundColor: "#fff", height: "300px" }}>
            <div className="animated-background" style={{ width: "50%", height: "20px", marginBottom: "5px" }}></div>
            <div className="animated-background" style={{ width: "100%", height: "100px", marginBottom: "15px" }}></div>
            <div className="animated-background" style={{ width: "50%", height: "20px", marginBottom: "5px" }}></div>
            <div className="animated-background" style={{ width: "100%", height: "100px", marginBottom: "15px" }}></div>
          </div>
        </div>
      </>
    )
  }
}

export default InfoProductsTicket;