import React, { useEffect, useState } from "react";
import api from "../../services/api";
import configData from "../../config/config.json";

import DatePicker from "react-multi-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-multi-date-picker/styles/layouts/mobile.css";

import "./Calendar.scss";
import i18next from "i18next";

const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
];

const numberMonths = window.innerWidth < 768 ? 1 : 2;

export interface productInfo {
    modalityID?: any;
    product?: any;
    productCode?: any;
    changePriceDateTour?: any;
    index?: any;
    dateTour?: any;
    startTimeLocal?: any;
    setLoading?: any;
    setIsIntegration?: any;
    setPreloader?: any;
    setRanges?: any;
    setPeopleDisponible?: any;
    isCorp?: any;
    item?: any
}

const CustomMultipleInput = ({
    openCalendar,
    value,
    modalityID,
    valueCalendar,
    startTimeLocal,
    setNewGetDate,
}: any) => {
    if (startTimeLocal) {
        if (valueCalendar?.data === undefined) {
            var teste: any = document.getElementById(`date-${modalityID}`);
            if (teste !== null) {
                value = startTimeLocal.props.children;
            }
        }
    }

    function getArrowLimit() {
        var today: any = new Date();
        var monthControl = today.getMonth() + 1;
        //var actual: any = monthControl;

        let timer1 = setTimeout(() => {
            var teste: any = document.getElementsByClassName("rmdp-arrow-container");

            if (teste.length >= 1) {
                teste[0].addEventListener(
                    "click",
                    (e: any) => {
                        monthControl = monthControl - 1;
                    },
                    false
                );

                teste[1].addEventListener(
                    "click",
                    (e: any) => {
                        monthControl = monthControl + 1;
                        setNewGetDate(monthControl);
                    },
                    false
                );
            }
        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }

    return (
        <span className="response-modal">
            <b>{startTimeLocal}</b>
        </span>
    );
};

const DoubleCalendarTourExtra: React.FC<productInfo> = ({
    modalityID,
    product,
    productCode,
    changePriceDateTour,
    dateTour,
    index,
    startTimeLocal = undefined,
    setLoading,
    setIsIntegration,
    setPreloader,
    setRanges,
    setPeopleDisponible,
    isCorp = false,
    item
}: productInfo) => {
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : "BR";

    const date:any = startTimeLocal.props.children.split("/").reverse().join("-");

    const [dayProduct, setDayProduct] = React.useState<any>(null);
    const [hoursProduct, setHoursProduct] = React.useState<any>();
    const [valueCalendar, setValueCalendar] = useState<any>({});
    const [valueIntegrate, setValueIntegrate] = useState<any>(false);
    const [newGetDate, setNewGetDate] = React.useState<any>(null);

    const [width, setWidth] = useState<any>(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", function () {
            var newWidth = window.innerWidth;
            setWidth(newWidth);
        });
    }, []);

    //Função que controla se vamos fazer a integração externa ou não
    const optionGo = (objCalendar: any) => {
        if (valueIntegrate === true) {
            integrateRequest(objCalendar);
        } else {
            handleDateTour(objCalendar, false);
        }
    };

    //Função que faz a integração
    const integrateRequest = async (objCalendar: any) => {
        setLoading(true);
        setPreloader(true);

        try {
            const { data } = await api.post(
                `${process.env.REACT_APP_SERVER_URL_API}/Products/GetTicketActivitiesIntegrationAsync`,
                {
                    dateStart: objCalendar.data,
                    productCode: `${productCode}`,
                    ProdModCode: `${product.prodModCode}`,
                }
            );
            if (data.status !== 400) {
                setIsIntegration(true);
                handleDateTour(objCalendar, true);
                setLoading(true);
                setPreloader(false);
            }
        } catch (error) { }
    };

    //Função que roda no clique
    function handleDateTour(objCalendar: any, integrate: boolean) {
        console.log(objCalendar)
        setRanges(objCalendar.ranges);

        var aux: any = dateTour;
        aux[index].data = objCalendar?.data;
        aux[index].idSellingType = objCalendar?.idSellingType;
        aux[index].daysUniqueTarCode = objCalendar?.daysUniqueTarCode;
        aux[index].prodModUniqueCode = objCalendar?.prodModUniqueCode;
        aux[index].tarUniqueCode = objCalendar?.tarUniqueCode;
        aux[index].adultText = objCalendar.adultText;
        aux[index].childText = objCalendar.childText;
        aux[index].infantText = objCalendar.infantText;
        aux[index].eldersText = objCalendar.eldersText;
        aux[index].studentText = objCalendar.studentText;
        aux[index].globalText = objCalendar.globalText;

        if (integrate === true) {
            aux[index].adult = objCalendar.priceAdultFinal;
            aux[index].child = objCalendar.priceChildFinal;
            aux[index].infant = objCalendar.priceInfantFinal;
            aux[index].student = objCalendar.priceStudentFinal;
            aux[index].elders = objCalendar.priceEldersFinal;
            aux[index].global = objCalendar.priceGlobalFinal;
            changePriceDateTour(aux, hoursProduct);

            if (hoursProduct.length === 0) {
                setPeopleDisponible(true);
            }
        } else {
            changePriceDateTour(aux, hoursProduct);
        }
    }

    useEffect(() => {
        let idChannel: number = 1;
        async function getDays(info: any) {
            //var today: any = new Date('Thu Jan 04 2022 14:32:14 GMT-0300 (Horário Padrão de Brasília)');
            var today: any = new Date();
            var todayDay: any;
            var todayMonth: any;
            var todayYear: any;
            var endMonth: any;
            var endYear: any;

            if (newGetDate === null) {
                if (today.getDate() - 5 >= 1) {
                    ///Mesmo mês
                    todayDay = today.getDate() - 5;
                    todayMonth = today.getMonth() + 1;
                    todayYear = today.getFullYear();
                    endMonth = today.getMonth() + 3;
                    endYear = today.getFullYear();
                } else {
                    ///Volta 1 mês
                    if (today.getMonth() + 1 !== 1) {
                        ///Mesmo ano

                        if ([4, 6, 9, 11].includes(today.getMonth() + 1)) {
                            todayDay = 30 + (today.getDate() - 5);
                        } else if ([1, 3, 5, 7, 8, 10, 12].includes(today.getMonth() + 1)) {
                            todayDay = 31 + (today.getDate() - 5);
                        } else if (today.getMonth() + 1 === 2) {
                            todayDay = 28 + (today.getDate() - 5);
                        }

                        todayMonth = today.getMonth();
                        todayYear = today.getFullYear();
                        endMonth = todayMonth + 3;
                        endYear = today.getFullYear();
                    } else {
                        ///Volta 1 ano
                        todayDay = 30 + (today.getDate() - 5);
                        todayMonth = 12;
                        todayYear = today.getFullYear() - 1;
                        endMonth = 3;
                        endYear = today.getFullYear();
                    }
                }

                if (endMonth > 11) {
                    endMonth = endMonth - 11;
                    endYear = parseInt(endYear) + 1;
                }
            } else {
                todayDay = today.getDate();
                todayMonth = newGetDate - 1;
                todayYear = today.getFullYear();
                endMonth = newGetDate + 3;
                endYear = today.getFullYear();

                if (todayMonth > 12) {
                    todayMonth = todayMonth - 12;
                    todayYear = parseInt(todayYear) + 1;
                }
                if (endMonth > 12) {
                    endMonth = endMonth - 12;
                    endYear = parseInt(endYear) + 1;
                }
            }

            todayDay = todayDay >= 28 ? 28 : todayDay;

            const token = localStorage.getItem("GroupId") || "{}";
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/GetCalendarTourAuthSigAsync `,
                    {
                        dateStart: date,
                        dateEnd: date,
                        productCode: `${productCode}`,
                        tarCode: `${info.tarCode}`,
                        ProdModCode: `${product.prodModCode}`,
                        lang: "BR",
                        idCanal: idChannel,
                    },
                    config
                );
                if (data.status !== 400) {
                    if (data.data.isIntegrationSystem === true) {
                        setValueIntegrate(true);
                    } else {
                        setHoursProduct(data.data.activities);
                    }
                    data.data.dates.forEach((dayProduct: any) => {
                        let year = dayProduct.selectedDate.split("-")[0];
                        let month = dayProduct.selectedDate.split("-")[1];
                        let day = dayProduct.selectedDate.split("-")[2];

                        let controlObject: {
                            [x: number]: {
                                price: any;
                                data: any;
                                idTarif: any;
                                priceAdultFinal: any;
                                priceChildFinal: any;
                                priceEldersFinal: any;
                                priceStudentFinal: any;
                                priceGlobalFinal: any;
                                priceInfantFinal: any;
                                idSellingType: any;
                                daysUniqueTarCode: any;
                                prodModUniqueCode: any;
                                tarUniqueCode: any;
                                ranges: any;
                                adultText: string;
                                childText: string;
                                infantText: string;
                                eldersText: string;
                                studentText: string;
                                globalText: string;
                            };
                        };
                        let sControl: any = year + "-" + month + "-" + day.split("T")[0];
                        controlObject = {
                            [sControl]: {
                                price:
                                    dayProduct.priceAdultFinal === 0.0
                                        ? dayProduct.priceGlobalFinal
                                        : dayProduct.priceAdultFinal,
                                idTarif: dayProduct.idTarifario,
                                priceAdultFinal: dayProduct.priceAdultFinal,
                                priceChildFinal: dayProduct.priceChildFinal,
                                priceInfantFinal: dayProduct.priceInfantFinal,
                                priceStudentFinal: dayProduct.priceStudentFinal,
                                priceEldersFinal: dayProduct.priceEldersFinal,
                                priceGlobalFinal: dayProduct.priceGlobalFinal,
                                // Essa loucura é por causa de butcha e havier
                                // Muda o selling type do ticket por está invertido
                                idSellingType: dayProduct.idSellingType,
                                daysUniqueTarCode: dayProduct.daysUniqueTarCode,
                                prodModUniqueCode: dayProduct.prodModUniqueCode,
                                tarUniqueCode: dayProduct.tarUniqueCode,
                                ranges: data.data.ranges,
                                data: year + "-" + month + "-" + day.split("T")[0],
                                adultText: data.data.tariffs[0][`tariffAgeAdultText${lng !== "BR" ? lng : ''}`],
                                childText: data.data.tariffs[0][`tariffAgeChildText${lng !== "BR" ? lng : ''}`],
                                infantText: data.data.tariffs[0][`tariffAgeInfantText${lng !== "BR" ? lng : ''}`],
                                eldersText: data.data.tariffs[0][`tariffAgeElderText${lng !== "BR" ? lng : ''}`],
                                studentText: data.data.tariffs[0][`tariffAgeStudentText${lng !== "BR" ? lng : ''}`],
                                globalText: data.data.tariffs[0][`tariffAgeGlobalText${lng !== "BR" ? lng : ''}`],
                            },
                        };
                        setDayProduct((curObjDeal: any) => ({
                            ...curObjDeal,
                            ...controlObject,
                        }));
                    });
                }
            } catch (error) { }
        }

        async function getIdChannel() {
            const config = {
                headers: { "ngrok-skip-browser-warning": "69420" },
            };

            try {
                const { data } = await api.get(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/${item.source}`,
                    config
                );
                if (data.statusCode === 200) {
                    idChannel = data.data.data;

                    for (let i = 0; i < product.allTarif.length; i++) {
                        getDays(product.allTarif[i]);
                    }
                }
            } catch (error) { }
        }
        getIdChannel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        product,
        productCode,
        newGetDate,
        item
    ]);

    useEffect(() => {
        if (dayProduct !== null) {
            setValueCalendar(dayProduct[date]);
            optionGo(dayProduct[date]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dayProduct, item])

    if (dayProduct !== null) {
        return (
            <DatePicker
                weekDays={weekDays}
                numberOfMonths={numberMonths}
                disableMonthPicker
                disableYearPicker
                months={months}
                format={configData.DATE.PT}
                // plugins={[<MyPlugin  hours={hoursProduct} />]}
                render={
                    <CustomMultipleInput
                        modalityID={modalityID}
                        valueCalendar={valueCalendar}
                        startTimeLocal={startTimeLocal}
                        setNewGetDate={setNewGetDate}
                    />
                }
                className={width > 767 ? "multi-locale-days" : "rmdp-mobile"}
            ></DatePicker>
        );
    } else {
        return <></>;
    }
};

export default DoubleCalendarTourExtra;