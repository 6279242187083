import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";

import api from "../../services/api";
import configData from "../../config/config.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Calendar.scss";

const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
];

const numberMonths = window.innerWidth < 480 ? 1 : 2;

export interface productInfo {
    modalityID: any;
    product: any;
    productCode: any;
    changePriceDateTour: any;
    index: any;
    dateTour: any;
    startTimeLocal?: any;
    item?: any;
}

const CustomMultipleInput = ({ openCalendar, value, modalityID, valueCalendar, startTimeLocal, setNewGetDate }: any) => {
    const [arrow, setArrow] = useState<any>(false);

    if (startTimeLocal) {
        if (valueCalendar.data === undefined) {
            var teste: any = document.getElementById(`date-${modalityID}`);
            if (teste !== null) {
                /* let receiveDate = startTimeLocal.props.children.split("/").reverse().join("-");
                let data = new Date(receiveDate);
                data.setDate(data.getDate()); */
                value = startTimeLocal.props.children;
            }
        }
    }

    useEffect(() => {
        let arrowBody = document.querySelector('body');
        if (arrowBody?.className === "modal-open") {
            setArrow(true);
        } else {
            setArrow(false);
        }
    }, []);

    function getArrowLimit() {
        var today: any = new Date();
        var monthControl = (today.getMonth() + 1);
        //var actual: any = monthControl;

        let timer1 = setTimeout(() => {

            var teste: any = document.getElementsByClassName('rmdp-arrow-container');


            if (teste.length >= 1) {
                teste[0].addEventListener("click", (e: any) => {
                    monthControl = monthControl - 1;
                    setNewGetDate(monthControl);
                }, false)

                teste[1].addEventListener("click", (e: any) => {
                    monthControl = monthControl + 1;
                    setNewGetDate(monthControl);
                }, false)
            }

        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }

    const onChangeInput = () => {
        return true;
    }

    return (
        <div className="d-flex">
            <FontAwesomeIcon
                icon={["fal", "calendar-alt"]}
                size="1x"
                style={{ color: "#000", position: "absolute", transform: "translate(10px, 10px)" }}
            />
            <input
                className={`form-control rounded-0 bg-white py-2 rounded calendar-input date-${modalityID}`}
                onFocus={() => { openCalendar(); getArrowLimit(); }}
                value={value}
                data-object={JSON.stringify(valueCalendar)}
                onChange={onChangeInput}
                readOnly
                id={`date-${modalityID}`}
                placeholder="Quando?"
                autoComplete="off"
            />
            {
                arrow === true
                    ?
                    <FontAwesomeIcon icon={["fal", "chevron-down"]} size="1x" style={{ marginRight: "10px", color: "#707070" }} />
                    :
                    ""
            }
        </div>
    );
};

const Test: React.FC<productInfo> = ({
    modalityID,
    product,
    productCode,
    changePriceDateTour,
    dateTour,
    index,
    startTimeLocal = undefined,
    item
}: productInfo) => {
    const [dayProduct, setDayProduct] = React.useState<any>({});
    const [hoursProduct, setHoursProduct] = React.useState<any>();
    const [valueCalendar, setValueCalendar] = useState<any>({});

    const [newGetDate, setNewGetDate] = React.useState<any>(null);

    const [auxTarif, setAuxTarif] = useState<any>(null);
    
    function handleDateTour(objCalendar: any) {
        let auxTar: any = [];

        for (let i = 0; i < auxTarif.length; i++) {
            if (auxTarif[i].selectedDate.split("T")[0] === objCalendar.data) {
                auxTar.push(auxTarif[i])
            }
        }

        var aux: any = dateTour;
        aux[index].data = objCalendar.data;
        aux[index].idSellingType = objCalendar.idSellingType;
        aux[index].auxTarif = auxTar;

        aux[index].adult = objCalendar.priceAdultFinal;
        aux[index].child = objCalendar.priceChildFinal;
        aux[index].infant = objCalendar.priceInfantFinal;
        aux[index].student = objCalendar.priceStudentFinal;
        aux[index].elders = objCalendar.priceEldersFinal;
        aux[index].global = objCalendar.priceGlobalFinal;
        changePriceDateTour(aux, hoursProduct);
    }

    useEffect(() => {
        let aux: any = [];

        /* let idChannel: number = 1; */
        async function getDays(info: any) {

            var today: any = new Date();
            var todayDay: any;
            var todayMonth: any;
            var todayYear: any;
            var endMonth: any;
            var endYear: any;

            if (newGetDate === null) {

                if (today.getDate() - 5 >= 1) { ///Mesmo mês
                    todayDay = today.getDate() - 5;
                    todayMonth = (today.getMonth() + 1);
                    todayYear = today.getFullYear();
                    endMonth = (today.getMonth() + 7);
                    endYear = today.getFullYear();

                } else { ///Volta 1 mês
                    if (today.getMonth() + 1 !== 1) { ///Mesmo ano

                        if ([4, 6, 9, 11].includes(today.getMonth() + 1)) {
                            todayDay = 30 + (today.getDate() - 5);
                        } else if ([1, 3, 5, 7, 8, 10, 12].includes(today.getMonth() + 1)) {
                            todayDay = 31 + (today.getDate() - 5);
                        } else if (today.getMonth() + 1 === 2) {
                            todayDay = 28 + (today.getDate() - 5);
                        }

                        todayMonth = (today.getMonth());
                        todayYear = today.getFullYear();
                        endMonth = todayMonth + 3;
                        endYear = today.getFullYear();
                    } else { ///Volta 1 ano
                        todayDay = 30 + (today.getDate() - 5);
                        todayMonth = 12;
                        todayYear = today.getFullYear() - 1;
                        endMonth = 3;
                        endYear = today.getFullYear();
                    }
                }

                if (endMonth > 11) {
                    endMonth = endMonth - 11;
                    endYear = parseInt(endYear) + 1;
                }
            } else {
                todayDay = today.getDate();
                todayMonth = newGetDate - 1;
                todayYear = today.getFullYear();
                endMonth = newGetDate + 3;
                endYear = today.getFullYear();

                if (todayMonth > 12) {
                    todayMonth = todayMonth - 12;
                    todayYear = parseInt(todayYear) + 1;
                }
                if (endMonth > 12) {
                    endMonth = endMonth - 12;
                    endYear = parseInt(endYear) + 1;
                }
            }

            todayDay = todayDay >= 28 ? 28 : todayDay;

            try {
                const { data } = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/GetCalendarTicketAsync`,
                    {
                        dateStart: todayYear + '-' + todayMonth + '-' + todayDay,
                        dateEnd: endYear + '-' + endMonth + '-' + todayDay,
                        productCode: `${productCode}`,
                        tarCode: `${info?.tarCode}`,
                        ProdModCode: `${product.prodModCode}`,
                        idCanal: item.idCanal,
                        newProduct: item.idCanal === 0 ? 1 : 0
                    }
                );
                if (data.status !== 400) {
                    setHoursProduct(data.data.activities);

                    aux.push(...data.data.dates);

                    data.data.dates.forEach((dayProduct: any) => {
                        let year = dayProduct.selectedDate.split("-")[0];
                        let month = dayProduct.selectedDate.split("-")[1];
                        let day = dayProduct.selectedDate.split("-")[2];

                        let controlObject: { [x: number]: { price: any; data: any, idTarif: any, priceAdultFinal: any, priceChildFinal: any, priceEldersFinal: any, priceStudentFinal: any, priceGlobalFinal: any, priceInfantFinal: any, idSellingType: any } };
                        let sControl: any = year + "-" + month + "-" + day.split("T")[0];
                        controlObject = {
                            [sControl]: {
                                price:
                                    dayProduct.priceAdultFinal === 0.0
                                        ? dayProduct.priceGlobalFinal
                                        : dayProduct.priceAdultFinal,
                                idTarif: dayProduct.idTarifario,
                                priceAdultFinal: dayProduct.priceAdultFinal,
                                priceChildFinal: dayProduct.priceChildFinal,
                                priceInfantFinal: dayProduct.priceInfantFinal,
                                priceStudentFinal: dayProduct.priceStudentFinal,
                                priceEldersFinal: dayProduct.priceEldersFinal,
                                priceGlobalFinal: dayProduct.priceGlobalFinal,
                                // Essa loucura é por causa de butcha e havier
                                // Muda o selling type do ticket por está invertido
                                idSellingType: dayProduct.idSellingType === 1 ? 2 : 1,
                                data: year + "-" + month + "-" + day.split("T")[0],
                            },
                        };
                        setDayProduct((curObjDeal: any) => ({
                            ...curObjDeal,
                            ...controlObject,
                        }));

                        setAuxTarif(aux);
                    });
                }
            } catch (error) { }
        }

        for (let i = 0; i < product?.allTarif.length; i++) {
            getDays(product?.allTarif[i]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product?.tarif?.tarCode, newGetDate]);

    if (dayProduct !== null) {
        return (
            <DatePicker
                weekDays={weekDays}
                numberOfMonths={numberMonths}
                disableMonthPicker
                disableYearPicker
                months={months}
                format={configData.DATE.PT}
                // plugins={[<MyPlugin  hours={hoursProduct} />]}
                render={<CustomMultipleInput modalityID={modalityID} valueCalendar={valueCalendar} startTimeLocal={startTimeLocal} setNewGetDate={setNewGetDate} />}
                className="rmdp-mobile"
                mapDays={({ date }): any => {
                    let controlNumber =
                        JSON.stringify(date.day).length === 1 ? "0" + date.day : date.day;
                    let controlMonth =
                        JSON.stringify(date.month.number).length === 1
                            ? "0" + date.month.number
                            : date.month.number;
                    let controlYear = date.year;

                    const objCalendar =
                        dayProduct[controlYear + "-" + controlMonth + "-" + controlNumber];
                    if (objCalendar !== undefined && objCalendar.price !== "null") {
                        if (
                            controlYear + "-" + controlMonth + "-" + controlNumber ===
                            objCalendar.data
                        ) {
                            return {
                                children: (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            fontSize: "14px",
                                        }}
                                        onClick={() => { setValueCalendar(objCalendar); handleDateTour(objCalendar) }}
                                    >
                                        <div style={{ textAlign: "center" }}>
                                            {date.format("D")}
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "9px",
                                                minHeight: "14px",
                                            }}
                                        >
                                            {" "}
                                            {objCalendar !== undefined
                                                ? objCalendar.price !== "null"
                                                    ? `R$ ${objCalendar.price
                                                        .toFixed(2)
                                                        .replace(".", ",")}`
                                                    : ""
                                                : ""}
                                        </div>
                                    </div>
                                ),
                            };
                        } else {
                            return {
                                disabled: true,
                            };
                        }
                    } else {
                        return {
                            disabled: true,
                        };
                    }
                }}
            ></DatePicker>
        );
    } else {
        return <></>;
    }
};

export default Test;
