import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import './ListSuppliers.scss'
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

//COMPONENTS
import Menu from '../../../components/Menu/Menu';
import SuppliersFilter from '../components/ListSuppliers/SuppliersFilter/SuppliersFilter';
import SuppliersTable from '../components/ListSuppliers/SuppliersTable/SuppliersTable';
import Header from '../../../components/Header/Header';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import DesktopDefault from '../../../templates/DesktopDefault';
//import GlobalAlert from '../../../components/Alert/GlobalAlert/GlobalAlert';

function ListSuppliers() {
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState<any>(null);
  const [error, setError] = useState<any>();
  const [alert, setAlert] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);

  const groupId: any = localStorage.getItem("GroupId");

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.post('Supplier/GetSuppliersByFilterAsync',
          {
            "page": 0,
            "rowsPerPage": 0,
            "name": "",
            "state": "",
            "status": 0,
            "type": 0,
            "country": 0,
            "beginDate": "",
            "endDate": "",
            "orderByField": "",
            "groupId": parseInt(groupId)
          }, config
        );

        if (data.status !== 400) {
          setLoading(false);
          setSuppliers(data.data.rows);
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);

  async function buscar(name: string, state: string, type: any, status: any, date: any) {
    setLoading(true);
    setSuppliers(null)
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    let beginDate = []
    let endDate = []


    if (date.length === 1) {
      beginDate = date[0].split('/')
    } else if (date.length === 2) {
      beginDate = date[0].split('/')
      endDate = date[1].split('/')
    }

    try {
      const res = await api.post('Supplier/GetSuppliersByFilterAsync',
        {
          "page": 0,
          "rowsPerPage": 0,
          "name": name,
          "state": state,
          "status": status,
          "type": parseInt(type),
          "country": 0,
          "beginDate": date.length > 0 ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : '',
          "endDate": date.length === 1 ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0]) + 1}` : date.length === 2 ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : '',
          "orderByField": "",
          "groupId": parseInt(groupId)
        }, config
      );
      if (res.status !== 400) {
        setLoading(false);
        setSuppliers(res.data.data.rows);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error.response)
      setError(true)
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
    }
  };

  return (
    <>
      <DesktopDefault>
          <div className="container-fluid content-dashboard">
            <Breadcrumb title={`${t("listSuppliers.breadSubtitle")} / ${t("listSuppliers.breadTitle")}`} />
            <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
              <h2 className="title" style={{ color: "#707070" }}>
                {t("listSuppliers.title")}
              </h2>
              {alert}
              <Link to={'/suppliers/add-suppliers'} className='btn btn-primary'>
                {t("listSuppliers.btnNew")}
              </Link>
            </div>
            <SuppliersFilter
              buscar={buscar}
              setAlert={setAlert}
              suppliers={suppliers}
              error={error}
            />
            <SuppliersTable
              suppliers={suppliers}
              loading={loading}
            />
          </div>
      </DesktopDefault>
    </>
  );
}

export default ListSuppliers;