import React, { useEffect, useState, Key } from 'react';
//import Select from 'react-select';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField?: any,
  propsLabel?: any,
  propsErrors?: any,
  actionAgents?: any
};

const GetAgents: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, actionAgents
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);

  //const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  /* const [check, setCheck] = useState<any>(true); */

  /* const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  } */

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420"  },
    };
    async function listAgents() {
      try {
        const { data } = await api.get(`/BillsReceivable/GetAgents`, config);
        if (data.status !== 400) {
          setInfo(data.data);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + "/";
        }
      }
    }
    listAgents();
  }, []);

  const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if(actionAgents){
      actionAgents(newValue);
    }
  }

  if (info !== null) {
    /* console.log(check) */
    return (
      <>
        <div className='endpoint'>
        <Form.Select
          {...propsField}
          label={propsLabel}
          as="select"
          variant="standard"
          margin="normal"
          onChange={(e: any) => handleChangeState(e)}
        >
          <option value='0' selected>Todos</option>
          {info.length > 0 ? info.map((info: any, index: Key) => {
            return (
              <option key={index} value={info.supplierIdentity}>
                {info.compName}
              </option>
            )
          }) : ''}
        </Form.Select>
      </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
};

export default GetAgents;
