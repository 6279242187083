import React from 'react';
import { Row } from "react-bootstrap";
import FinancialTable from '../../AccordionFinancial/FinancialTable';
//import logo from '../../logo.svg';

export interface propInfo {
    info: any;
    setChangeEdit: any;
};

const EditFinancial: React.FC<propInfo> = ({
    info, setChangeEdit
}: propInfo) => {

    return (
        <>
            <div id="financialData" className="d-flex">
                <div className="border bg-white">
                    <div className="edit-title container-fluid">
                        <h3 className="">Financeiro</h3>
                        <button onClick={() => { setChangeEdit(false) }}>EDITAR</button>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>BANCO:</p>
                            <p>{info.bancDesc ? info.bancDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>AGÊNCIA:</p>
                            <p>{info.agency ? info.agency : "não definido"}</p>
                        </div>
                        <div className="data-unit col-20">
                            <p>CONTA:</p>
                            <p>{info.account ? info.account : "não definido"}</p>
                        </div>
                        <div className="data-unit col-10">
                            <p>DIV:</p>
                            <p>{info.verificadorDiv !== undefined ? info.verificadorDiv : "não definido"}</p>
                        </div>
                        {/* <div className="data-unit">
                            <p>FILIAL:</p>
                            <p>{info.filialDesc ? info.filialDesc : "não definido"}</p>
                        </div> */}

                        {/* <div className="data-unit data-unit-white-bg"></div>
                        <div className="data-unit data-unit-white-bg"></div> */}
                    </div>

                    <hr style={{ margin: "1rem 24px" }} />

                    <div className="edit-title container-fluid">
                        <h3 className="">A Definir</h3>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>LIMITE DE CRÉDITO:</p>
                            <p>{info.creditLimitSupplier ? info.creditLimitSupplier : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>DIAS PARA FATURAMENTO:</p>
                            <p>{info.diasParaFaturamentoAgent ? info.diasParaFaturamentoAgent : "não definido"}</p>
                        </div>
                        <div className="data-unit data-unit-white-bg"></div>
                    </div>

                    <hr style={{ margin: "1rem 24px" }} />

                    <div className="edit-title container-fluid">
                        <h3 className="">Grupo Econômico</h3>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>É UM GRUPO?:</p>
                            <p>{info.groupDesc ? info.groupDesc : "não definido"}</p>
                        </div>
                        {info.grupo === 1
                            ?
                            <>
                                <div className="data-unit">
                                    <p>MASTER OU FILHO?:</p>
                                    <p>{info.masterFilhoDesc ? info.masterFilhoDesc : "não definido"}</p>
                                </div>
                                {info.masterFilho === '2'
                                    ?
                                    <>
                                        <div className="data-unit">
                                            <p>MASTER DO GRUPO:</p>
                                            <p>{info.comissaoMasterId ? info.comissaoMasterId : "não definido"}</p>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="data-unit data-unit-white-bg"></div>
                                    </>
                                }
                            </>
                            :
                            <>
                                <div className="data-unit data-unit-white-bg"></div>
                                <div className="data-unit data-unit-white-bg"></div>
                            </>
                        }
                    </div>

                    <hr style={{ margin: "1rem 24px" }} />

                    <div className="edit-title container-fluid">
                        <h3 className="">Tarifário e Comissões</h3>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>CANAL:</p>
                            <p>{info.idCanal ? info.descCanal.toUpperCase() : "não definido"}</p>
                        </div>
                        <div className="data-unit col-20 data-unit-white-bg"></div>
                        <div className="data-unit col-20 data-unit-white-bg"></div>
                        <div className="data-unit">
                            <p>TIPO TARIFÁRIO:</p>
                            <p>{info.tarTipoDesc ? info.tarTipoDesc : "não definido"}</p>
                        </div>
                        {info.comissaoTour ?
                            <div className="data-unit col-20">
                                <p>COMISSÃO TOUR:</p>
                                <p>{info.comissaoTour ? info.comissaoTour : "não definido"}</p>
                            </div>
                            : <div className="data-unit col-20 data-unit-white-bg"></div>
                        }
                        {info.comissaoTransfer ?
                            <div className="data-unit col-20">
                                <p>COMISSÃO TRANSFERS:</p>
                                <p>{info.comissaoTransfer ? info.comissaoTransfer : "não definido"}</p>
                            </div>
                            : <div className="data-unit col-20 data-unit-white-bg"></div>
                        }
                        {info.comissaoTicket ?
                            <div className="data-unit col-20">
                                <p>COMISSÃO TICKETS:</p>
                                <p>{info.comissaoTicket ? info.comissaoTicket : "não definido"}</p>
                            </div>
                            : <div className="data-unit col-20 data-unit-white-bg"></div>
                        }
                        <div className="data-unit">
                            <p>GRADE DE TARIFÁRIO:</p>
                            <p>{info.gradeCodeDesc ? info.gradeCodeDesc : "não definido"}</p>
                        </div>
                        {info.comissaoTourFilho ?
                            <div className="data-unit col-20">
                                <p>COMISSÃO TOUR FILHO:</p>
                                <p>{info.comissaoTourFilho ? info.comissaoTourFilho : "não definido"}</p>
                            </div>
                            : <div className="data-unit col-20 data-unit-white-bg"></div>
                        }
                        {info.comissaoTransferFilho ?
                            <div className="data-unit col-20">
                                <p>COMISSÃO TRANSFERS FILHO:</p>
                                <p>{info.comissaoTransferFilho ? info.comissaoTransferFilho : "não definido"}</p>
                            </div>
                            : <div className="data-unit col-20 data-unit-white-bg"></div>
                        }
                        {info.comissaoTicketFilho ?
                            <div className="data-unit col-20">
                                <p>COMISSÃO TICKETS FILHO:</p>
                                <p>{info.comissaoTicketFilho ? info.comissaoTicketFilho : "não definido"}</p>
                            </div>
                            : <div className="data-unit col-20 data-unit-white-bg"></div>
                        }
                        {info.rezdyDesc ?
                            <div className="data-unit">
                                <p>API REZDY:</p>
                                <p>{info.rezdyDesc ? info.rezdyDesc : "não definido"}</p>
                            </div>
                            : <div className="data-unit data-unit-white-bg"></div>
                        }
                        {info.tarImportaPlan ?
                            <div className="data-unit">
                                <p>IMPORTAÇÃO PLANILHA:</p>
                                <p>{info.tarImportaPlan ? info.tarImportaPlan : "não definido"}</p>
                            </div>
                            : <div className="data-unit data-unit-white-bg"></div>
                        }
                        <div className="data-unit data-unit-white-bg"></div>
                    </div>

                    <hr style={{ margin: "1rem 24px" }} />

                    <div className="edit-title container-fluid">
                        <h3 className="">Braspag</h3>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>CADASTRAR NA BRASPAG?:</p>
                            <p>{info.braspagDesc ? info.braspagDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>FEEBRASPAG:</p>
                            <p>{info.feeBraspag !== null ? info.feeBraspag : "não definido"}</p>
                        </div>
                        <div className="data-unit col-20">
                            <p>MDR BRASPAG %:</p>
                            <p>{info.mdrBraspag !== null ? `${info.mdrBraspag}%` : "não definido"}</p>
                        </div>
                        <div className="data-unit col-10">
                            <p>STATUS:</p>
                            <p>
                                {
                                    info.trocar
                                        ?
                                        info.trocar
                                        :
                                        "não definido"
                                }
                            </p>
                        </div>
                    </div>

                    <hr style={{ margin: "1rem 24px" }} />

                    <div className="edit-title container-fluid">
                        <h3 className="">Lio</h3>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>PODE VENDER FATURADO?:</p>
                            <p>{info.cashModePermissionDesc}</p>
                        </div>
                    </div>

                    <hr style={{ margin: "1rem 24px" }} />

                    <div className="edit-title container-fluid">
                        <h3 className="">Pagamento</h3>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>CONCILIAÇÃO AUTOMÁTICA?:</p>
                            <p>{info.conciliacaoAutomaticaDesc ? info.conciliacaoAutomaticaDesc : "não definido"}</p>
                        </div>

                        <div className="data-unit data-unit-white-bg"></div>
                        <div className="data-unit data-unit-white-bg"></div>

                        <div className="data-unit" style={{ maxWidth: "15%" }}>
                            <p>MOEDA:</p>
                            <p>{info.currency ? info.currency : "não definido"}</p>
                        </div>
                        <div className="data-unit" style={{ maxWidth: "15%" }}>
                            <p>PAGAMENTO:</p>
                            <p>{info.paymentTypeDesc ? info.paymentTypeDesc : "não definido"}</p>
                        </div>
                        {
                            info.paymentType !== "At_sight"
                                ?
                                <>
                                    {info.reserveDesc ?
                                        <div className="data-unit col-10" style={{ maxWidth: "15%" }}>
                                            <p>A CONTAR DE:</p>
                                            <p>{info.reserveDesc ? info.reserveDesc : "não definido"}</p>
                                        </div>
                                        : <div className="data-unit col-10 data-unit-white-bg"></div>
                                    }
                                    {info.ciclo ?
                                        <div className="data-unit col-10" style={{ maxWidth: "15%" }}>
                                            <p>CICLO:</p>
                                            <p>{info.ciclo ? info.ciclo : "não definido"}</p>
                                        </div>
                                        : <div className="data-unit col-10 data-unit-white-bg"></div>
                                    }
                                    {info.diaDeCorte && info.diaDeCorte !== "no-contrato-empresa.png" ?
                                        <div className="data-unit col-10" style={{ maxWidth: "15%" }}>
                                            <p>CORTE:</p>
                                            <p>{info.diaDeCorte ? info.diaDeCorte : "não definido"}</p>
                                        </div>
                                        : <div className="data-unit col-10 data-unit-white-bg"></div>
                                    }
                                    {info.faturamento60Dias ?
                                        <div className="data-unit col-10" style={{ maxWidth: "15%" }}>
                                            <p>FATURAMENTO:</p>
                                            <p>{info.faturamento60Dias ? info.faturamento60Dias : "não definido"}</p>
                                        </div>
                                        : <div className="data-unit col-10 data-unit-white-bg"></div>
                                    }
                                </>
                                :
                                <>
                                    <div className="data-unit data-unit-white-bg"></div>
                                    <div className="data-unit data-unit-white-bg"></div>
                                </>
                        }
                    </div>

                    <hr style={{ margin: "1rem 24px" }} />

                    <div className="edit-title container-fluid">
                        <h3 className="">Centros de custo</h3>
                    </div>

                    <div style={{ margin: "0 24px 10px" }}>
                        <Row>
                            <FinancialTable
                                isEdit={true}
                                infoEdit={info.costCenterSupplierDTO}
                                disableAddContact={true}
                            />
                        </Row>
                    </div>

                </div>
            </div>
        </>
    );
}

export default EditFinancial;