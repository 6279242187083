import React, { Key, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

import Modal from "react-bootstrap/Modal";

//STYLES
import "./TableBillReceiveEditBills.scss";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";
import FormBillReceivableEdit from "./FormBillReceivableEdit/FormBillReceivableEdit";
import ModalQuestion from "../../../Modal/ModalQuestion/ModalQuestion";

export interface propModal {
  //action: any
  info: any,
  refreshData: any
};

const TableBillReceiveEditBills: React.FC<propModal> = ({
  //action, 
  info,
  refreshData
}: propModal) => {
  const { t } = useTranslation();
  const [billEditModalShow, setBillEditModalShow] = React.useState(false);
  const [billEdit, setBillEdit] = React.useState(null);

  const [billDeleteModalShow, setBillDeleteModalShow] = React.useState(false);
  const [billDelete, setBillDelete] = React.useState(null);

  //const [controlObject, setControlObject] = useState<any>(info ? info : []);


  function handleBillDeleteClick(row: any) {
    // confirma antes de excluir
    // chama api
    setBillDelete(row);
    setBillDeleteModalShow(true);
  }

  function handleActionBillDeleteModal(e: any) {
    // do delete
  }

  function handleBillEditClick(row: any) {
    
    // chama modal
    setBillEdit(row);
    setBillEditModalShow(true);
  }

  function handleActionBillEditModal(data: any) {
    setBillEdit(null);
    refreshData();
  }

  function handleActionBillEditHideModal() {
    //() => setBillEditModalShow(false)
    setBillEditModalShow(false);
    setBillEdit(null);
  }

  function handleRegisterCardClick(row: any) {

  }

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === 2) {
      return 'border-analise';
    } else if (row.statusPagamento === 3 || row.statusPagamento === 4) {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  return (
    <>
      <div className="table-default bill-receive-bills-table">
        <div className="table-container">
          <div className="table-title mb-3">
            <h3>Pagamentos</h3>
          </div>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Data</th>
                <th>Método Pagamento</th>
                <th>Valor</th>
                <th>MDR</th>
                <th>Operadora / Bandeira</th>
                <th>NSU</th>
                <th>Vencimento</th>
                <th>Observação</th>
                <th></th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {info.length > 0
                ? info.map((info: any, index: Key) => (
                  <tr key={index} className={rowClasses(info, index)}>
                    <td>{convertDate(info.data)}</td>
                    <td>{info.formaPagamento}</td>
                    <td>{convertValue(info.valor)}</td>
                    <td>{convertValue(info.mdr)}</td>
                    <td>{info.operadora}</td>
                    <td>{info.nsu}</td>
                    <td>{convertDate(info.vencimento)}</td>
                    <td>{info.observacao}</td>
                    <td>
                    {
                      info.apiCheck == 1 ?
                      <FontAwesomeIcon
                        icon={["fal", "cloud"]}
                        size="1x"
                        style={{ marginRight: "1px" }}
                        className="fg-ativo"
                        title="Validado na API"
                      />
                      :
                      <FontAwesomeIcon
                        icon={["fal", "cloud"]}
                        size="1x"
                        style={{ marginRight: "1px" }}
                        className="fg-gray"
                      />
                    }
                    {
                      info.statusPagamento == 2 ?
                        <FontAwesomeIcon
                          icon={["fal", "check"]}
                          size="1x"
                          style={{ marginRight: "1px" }}
                          className="fg-blue"
                        />
                      : info.statusPagamento == 3 || info.statusPagamento == 4 ?
                        <FontAwesomeIcon
                          icon={["fal", "check"]}
                          size="1x"
                          style={{ marginRight: "1px" }}
                          className="fg-ativo"
                        />
                      : <></>
                    }
                    </td>
                    <td>{info.notaAvulsa != 4 ?
                      <div className="center-table options-table">
                        <Dropdown drop="start" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={()=>handleBillEditClick(info)} eventKey="1" disabled={info.statusPagamento == 3 || info.statusPagamento == 4}><FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />Editar</Dropdown.Item>
                            {/* <Dropdown.Divider />
                            <Dropdown.Item onClick={()=>handleBillDeleteClick(info)} eventKey="2" className="text-danger removeRow" disabled={info.tipo == 2 || info.statusPagamento != 1}><FontAwesomeIcon icon={['fal', 'trash-alt']} className="mr-2" />Excluir</Dropdown.Item> */}
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={()=>handleRegisterCardClick(info)} eventKey="3" disabled={info.statusPagamento != 2 || info.formaPagamentoId != 85}><FontAwesomeIcon icon={['fal', 'credit-card']} className="mr-2" />Registrar Cartão</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    : <></>}</td>
                  </tr>
                ))
                : <tr style={{ border: "none" }}><td colSpan={6} className="text-center">Nenhum item encontrado</td></tr>}
            </tbody>
          </Table>

          {billEdit !== null? 
          <Modal
            className="modalAuth"
            show={billEditModalShow}
            onHide={handleActionBillEditHideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Header className="p-3" closeButton></Modal.Header>
              <FormBillReceivableEdit action={handleActionBillEditModal} info={billEdit} />
            </>
          </Modal>: <></>}

          <ModalQuestion
            modalShow={billDeleteModalShow}
            setModalShow={setBillDeleteModalShow}
            titulo="Excluir Pagamento"
            mensagem="Deseja excluir o Item?"
            handleActionConfirmModal={handleActionBillDeleteModal}
            //handleActionCancelModal={handleActionCancelDeleteClick}
          />
        </div>
      </div>
    </>
  );
}

export default TableBillReceiveEditBills;
