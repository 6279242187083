import React, { useState } from 'react';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";

import './Calendar.scss';

const CustomMultipleInput = ({ openCalendar, value, date, setValue, errors, startTimeLocal }: any) => {

  function convertDate(cell: any) {
    if (cell !== null && cell !== undefined) {
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return date;
    }
  }

  setValue(value);

  return (
    <input
      className={errors?.dataPgto ? "form-control bg-white frm-sm w-100 rounded endpoint-error" : "form-control bg-white frm-sm w-100 rounded"}
      onFocus={openCalendar}
      value={value}
      readOnly
      id={'singleCalendarId'}
      placeholder={startTimeLocal ? convertDate(startTimeLocal) : "Quando?"}
      autoComplete='off'
    />
  )
}



const SingleCalendarEdit = ({ date, setValue = () => { }, field, errors = undefined, startTimeLocal = undefined, defaultDate = undefined }: any) => {
  const { t } = useTranslation();
  const [aux] = useState<any>(date);

  return (
    <DatePicker
      {...field}
      //value={defaultDate !== null ? new Date(defaultDate) : undefined}
      weekDays={t('calendar.weekDays').split(',')}
      months={t('calendar.months').split(',')}
      format={configData.DATE.PT}
      render={<CustomMultipleInput date={aux} setValue={setValue} errors={errors} startTimeLocal={defaultDate} />}
    />
  )
}

export default SingleCalendarEdit;



