/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import api from '../../../../services/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import BootstrapTable from 'react-bootstrap-table-next';

import '../../../../assets/sass/accordion.scss';
import Export from '../../../../assets/icons/Frame.svg';

//STYLES
import './CorporateTicketTable.scss';
import Pagination from '../../../../components/Pagination/Pagination';
import { useForm } from 'react-hook-form';
import CorporateDetailsTicket from '../CorporateDetailsTicket/CorporateDetailsTicket';
import CorporateExtraTicket from '../CorporateExtraTicket/CorporateExtraTicket';
import VoucherDownload from '../../components/VoucherDownload/VoucherDownload';
import InstructionsPrint from '../../../../components/InstructionsPrint/InstructionsPrint';

export interface propTable {
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  corporateTicket: any;
  confirm: any;
  log: any;
  modalContent: any;
  setConfirm: any;
  setLog: any;
  handleModal: any;
  loading: any;
  reloadTable: any;
  pdfBase64: any;
}

const CorporateTable: React.FC<propTable> = ({
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
  corporateTicket,
  confirm,
  log,
  modalContent,
  setConfirm,
  setLog,
  handleModal,
  loading,
  reloadTable,
  pdfBase64,
}: propTable) => {
  const {
    setValue,
  } = useForm();

  const { t } = useTranslation();
  const [editClick, setEditClick] = useState<any>(false);
  const [detailsModal, setDetailsModal] = useState<any>(false);
  const [voucherModal, setVoucherModal] = useState<any>(false);
  const [extraTicket, setExtraTicket] = useState<any>(false);
  const [currentReservation, setCurrentReservation] = useState<any>();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalLog, setModalLog] = useState<any>(null);
  const [modalMessage, setModalMessage] = useState<string>('Loading');

  const [modalPrint, setModalPrint] = useState<any>(false);
  const [infoNWAY, setInfoNWAY] = useState<any>(null);

  const level = atob(
    JSON.parse(localStorage.getItem('bpaSigAuth') || '{}').user.perfil.nivel,
  );

  function seeDetails(cell: any, row: any) {
    setEditClick(false);
    setCurrentReservation(row);
    setDetailsModal(true);
  }

  function seeDetailsEdit(cell: any, row: any) {
    setEditClick(true);
    setCurrentReservation(row);
    setDetailsModal(true);
  }

  function seeVoucherDownload(cell: any, row: any) {
    //setEditClick(true);
    setCurrentReservation(row);
    setVoucherModal(true);
  }

  function issueTicket(row: any) {
    setModalShow(true);
    setModalLog(null);
    setModalMessage('Loading');

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420"
      },
    };

    async function ticket() {
      try {
        const res = await api.post(`/Checkout/FinalizeCheckoutCorp/${row.booking || row.bookingLoc}`, {}, config);
        if (res.status === 200) {
          setModalLog(res.data.data.log);
          setModalMessage(res.data.data.texto);

          if (row.tipoBilhete === "Eventos" || row.tipoBilhete === "Reagendamento Com Pax" || row.tipoBilhete === "Reagendamento Sem Pax") {
            setInfoNWAY(res.data.data.data.data);

            if (res.data.data.log === 0) {
              setTimeout(() => {
                setModalShow(false);
                setModalPrint(true);
              }, 3000);
            }
          }
        } else {
          setModalLog(1);
          setModalMessage("Erro desconhecido ao emitir reserva!");
        }
      } catch (error: any) {
        setModalLog(1);
        setModalMessage("Erro desconhecido ao emitir reserva!");
      }
    }
    ticket();
  };

  function reprintTicket(booking: string) {
    setModalLog(null);
    setModalMessage('Loading');

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420"
      },
    };
    async function ticket() {
      try {
        const { data } = await api.post(`/SpecialTickets/GenerateSpecialTicketPapel`, {
          "bookingLoc": booking,
          "language": 1
        }, config);
        if (data.status !== 400) {
          setModalLog(data.data.log);
          setModalMessage(data.data.texto);
          setInfoNWAY(data.data.data);

          setTimeout(() => {
            setModalPrint(true);
          }, 250);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          setModalLog(1);
          setModalMessage("Erro desconhecido ao emitir reserva!");
        }
      }
    }
    ticket();
  };

  function HandleExtraTicket(cell: any, row: any) {
    setExtraTicket(true);
    setCurrentReservation(row);
  }

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                onClick={() => {
                  seeDetails(cell, row);
                }}
              >
                <FontAwesomeIcon icon={['fal', 'file-alt']} className="mr-2" />
                Detalhes
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={() => {
                  seeDetailsEdit(cell, row);
                }}
              >
                <FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />
                Editar
              </Dropdown.Item>
              <Dropdown.Item eventKey="3" onClick={() => { seeVoucherDownload(cell, row) }} disabled={row.tipoBilhete === "Eventos" || row.tipoBilhete === "Reagendamento Com Pax" || row.tipoBilhete === "Reagendamento Sem Pax"}>
                <FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />
                Voucher
              </Dropdown.Item>
              <Dropdown.Item eventKey="3" onClick={() => reprintTicket(row.booking)} disabled={(row.tipoBilhete !== "Eventos" && row.tipoBilhete !== "Reagendamento Com Pax" && row.tipoBilhete !== "Reagendamento Sem Pax") || row.statusItem === 7 || level !== "33"}>
                <FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />
                Reimpressão
              </Dropdown.Item>
              <Dropdown.Item eventKey="3" onClick={() => issueTicket(row)} disabled={row.statusItem === 1 || level !== "33"}>
                <FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />
                Emitir Reserva
              </Dropdown.Item>
              <Dropdown.Item eventKey="3" onClick={() => HandleExtraTicket(cell, row)} disabled={row.tipoBilhete !== "Eventos" || level !== "32"}>
                <FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />
                Bilhete Extra
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  function convertDate(cell: any) {
    let data = new Date(cell);
    let dataFormatada = data.toLocaleDateString('pt-BR');

    return <>{dataFormatada}</>;
  }

  function convertAmount(cell: any) {
    return cell.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  function addStatus(cell: any, row: any) {
    if (cell <= 2) {
      cell = row.statusItem;
    }

    var status =
      cell === 1
        ? 'Ativo'
        : cell === 2
          ? 'Cancelado'
          : cell === 3
            ? 'Processando'
            : cell === 4
              ? 'Carrinho abandonado'
              : cell === 5
                ? 'Fornecedor pendente'
                : cell === 6
                  ? 'On_hold'
                  : cell === 7
                    ? 'Pré reserva'
                    : cell === 9
                      ? 'Pagamento não aprovado'
                      : '';
    return (
      <div
        className={
          status === 'Pré reserva'
            ? 'bg-yellow'
            : status === 'Ativo'
              ? 'bg-ativo'
              : status === 'Cancelado'
                ? 'bg-inativo'
                : status === 'Pagamento não aprovado'
                  ? 'bg-notApproved'
                  : ''
        }
      >
        {status}
      </div>
    );
  }

  const exportReport = () => {
    setModalLog(0);
    setModalShow(true);
    setModalMessage('Exportação do relatório realizado com sucesso!');

    const bytes = atob(pdfBase64);
    const arrayBuffer = new ArrayBuffer(bytes.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < bytes.length; i++) {
      uint8Array[i] = bytes.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = 'Relatório_Solicitações_Bilhetes_Corporativo.xlsx';
    downloadLink.click();

    downloadLink?.parentNode?.removeChild(downloadLink);
  }

  const columns = [
    {
      dataField: 'booking',
      text: 'Nº Solicitação',
    },
    {
      dataField: 'companyName',
      text: 'Cliente'
    },
    {
      dataField: 'tipoBilhete',
      text: 'Tipo de Bilhete'
    },
    {
      dataField: 'dataCompra',
      text: 'Data de Compra',
      formatter: convertDate,
    },
    {
      dataField: 'dataVisita',
      text: 'Data de Passeio',
      formatter: convertDate,
    },
    {
      dataField: 'createdBy',
      text: 'Solicitante'
    },
    {
      dataField: 'amount',
      text: 'Total Geral',
      formatter: convertAmount
    },
    {
      dataField: 'statusItem',
      text: 'Status',
      formatter: addStatus,
    },
    {
      dataField: 'id',
      text: 'Ações',
      formatter: addActionButton,
    },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    {
      dataField: 'contasd',
      text: 'Carregando Bilhetes',
      formatter: loadingFunc,
    },
  ];

  const loadingProducts = [
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: 'clientes', text: 'Bilhetes' }];

  const notFoundProducts = [{ clientes: 'Bilhetes não encontrados.' }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any) => {
    if (row.statusItem === 7) {
      return 'border-pendente-escala';
    } else if (row.statusItem === 1) {
      return 'border-ativo';
    } else if (row.statusItem === 2) {
      return 'border-inativo';
    } else {
      return 'border-normal';
    }
  };

  function handleModalShow() {
    setValue('reason', '0');
  }

  if (corporateTicket !== null) {
    return (
      <>
        <div className="table-default corporate-table">
          <div className="table-container">
            <div className="table-title">
              <button className="btn-export" onClick={exportReport}>
                <img src={Export} alt="" />
                Exportar
              </button>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={corporateTicket}
              columns={columns}
              rowClasses={rowClasses}
            />

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>

        <Modal
          className="modal-custom reservation-details"
          show={detailsModal !== false}
          onHide={() => {
            setDetailsModal(false);
            setCurrentReservation(null);
            setEditClick(false);
            handleModalShow();
            reloadTable();
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <CorporateDetailsTicket
              id={currentReservation?.id}
              setDetailsModal={setDetailsModal}
              action={() => { handleModal(false); }}
              editClick={editClick}
              handleNext={() => { }}
              seeVoucherDownload={seeVoucherDownload}
              issueTicket={issueTicket}
            />
          </>
        </Modal>

        <Modal
          className="modal-custom voucher-download"
          show={voucherModal !== false}
          onHide={() => { setDetailsModal(false)/* ; setCurrentReservation(null); setEditClick(false); */ }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <VoucherDownload
              setVoucherModal={setVoucherModal}
              currentReservation={currentReservation}
              setModalShow={setModalShow}
              setModalLog={setModalLog}
              setModalMessage={setModalMessage}
            />
          </>
        </Modal>

        <Modal
          size="lg"
          show={modalShow}
          onHide={() => setModalShow(true)}
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-confirm loading-modal"
        >
          <Modal.Body className="modal-body text-center sucess-pos px-5">
            {modalLog !== null ? (
              <>
                <div>
                  {modalLog === 1 || modalLog === '1' ? (
                    <FontAwesomeIcon
                      icon={['fal', 'times-circle']}
                      size="5x"
                      style={{ fontSize: '7.5em' }}
                      className="text-primary"
                    />
                  ) : modalLog === 0 || modalLog === '0' ? (
                    <FontAwesomeIcon
                      icon={['fal', 'check-circle']}
                      size="5x"
                      style={{ fontSize: '7.5em' }}
                      className="text-primary"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={['fal', 'question-circle']}
                      size="5x"
                      style={{ fontSize: '7.5em' }}
                      className="text-primary"
                    />
                  )}
                </div>
                <div>
                  {modalMessage}
                  {
                    (modalLog === 0 || modalLog === '0') && infoNWAY?.nway
                      ?
                      <div className='pt-0 w-100 mx-auto'>
                        <p className='text-center' style={{ fontWeight: 600 }}>Por favor, aguarde a impressão dos bilhetes!</p>
                      </div>
                      :
                      <></>
                  }
                </div>
                <div className="d-flex justify-content-center">
                  {
                    !infoNWAY?.nway
                      ?
                      <button
                        onClick={() => {
                          setModalShow(false);
                          reloadTable();
                        }}
                        className="btn btn-primary mx-2 w-25"
                      >
                        Confirmar
                      </button>
                      :
                      <></>
                  }
                </div>
              </>
            ) : (
              <div className="modal-body">
                <div className="text-center">
                  <div className="load"></div>
                </div>
                <div>
                  <span>Carregando</span>
                </div>
                <div></div>
              </div>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          className="modal-custom event-details"
          show={extraTicket !== false}
          onHide={() => {
            setExtraTicket(false);
            setCurrentReservation(null);
            //setEditClick(false);
            //handleModalShow();
            //reloadTable();
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <CorporateExtraTicket
              id={currentReservation?.id}
              setExtraTicket={setExtraTicket}
              row={currentReservation}
              reloadTable={reloadTable}
            />
          </>
        </Modal>
        <Modal
          className={"modal-custom modalAuth modal-validation"}
          show={modalPrint}
          onHide={
            () => setModalPrint(true)
          }
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {/* {
                    action === 0
                        ?
                        <ChoiceTicket />
                        : */}
          <InstructionsPrint
            printer={infoNWAY}
            setModalShow={setModalPrint}
            checkoutError={""}
            printerInfo={infoNWAY}
            reloadTable={reloadTable}
            setInfoNWAY={setInfoNWAY}
          />
          {/* } */}
        </Modal>
      </>
    );
  } else if (corporateTicket === null && loading === true) {
    return (
      <>
        <div className="table-default corporate-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default corporate-table not-found">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">Reagendamentos</h3>
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
};

export default CorporateTable;
