import React, { useState } from 'react';

import { Container, Row, Col, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import Button from 'react-bootstrap/Button';

import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';

import MerchantsTablePosDevices from '../MerchantsTable/MerchantsTablePosDevices/MerchantsTablePosDevices';
import MerchantsTableFeesTerms from '../MerchantsTable/MerchantsTableFeesTerms/MerchantsTableFeesTerms';

import '../../../../assets/sass/accordion.scss';
import GetAccountStatus from '../../../../components/C2Points/GetAccountStatus';

import api from '../../../../services/api';

export interface propMerchantsForm {
    action: any,
    info: any,
};

function MerchantsForm({
    action, info
}: propMerchantsForm) {

    const { t } = useTranslation();
    const [validated, setValidated] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            id: info ? info.id : 0,
            nomeFantasia: info ? info.nomeFantasia : '',
            razaoSocial: info ? info.razaoSocial : '',
            cnpj: info && (info.cnpj !== null) ? info.cnpj : '',
            nomeGerente: info && (info.nomeGerente !== null) ? info.nomeGerente : '',
            email: info && (info.email !== null) ? info.email : '',
            telefone: info && (info.telefone !== null) ? info.telefone : '',
            status: info ? info.status : '1'
        },
    });

    const [posDevices, setPosDevices] = useState<any>(info ? info.posDevices : []);
    const [posDevicesDeleted, setPosDevicesDeleted] = useState<any>([]);
    const getPosDevices = (objPosDevices: any, deletedObjects: any) => {
        setPosDevices(objPosDevices);
        setPosDevicesDeleted(deletedObjects);
    }
    const [feesTerms, setFeesTerms] = useState<any>(info ? info.feesTerms : []);
    const [feesTermsDeleted, setFeesTermsDeleted] = useState<any>([]);
    const getFeesTerms = (objFeesTerms: any, deletedObjects: any) => {
        setFeesTerms(objFeesTerms);
        setFeesTermsDeleted(deletedObjects);
    }

    const strToFloat = (str: string): any => {
        if(!str || str === '') return 0.0;
        str = '' + str;
        return parseFloat(str.replace(/[^0-9,]*/g, '').replace(',', '.'));
    }

    const strToInt = (str: string): any => {
        if(!str || str === '') return 0;
        return parseInt(str);
    }

    const onSubmit = (dataObj: any) => {
        var route = '';

        dataObj.posDevices = [];
        dataObj.posDevicesDeleted = posDevicesDeleted;
        dataObj.feesTerms = [];
        dataObj.feesTermsDeleted = feesTermsDeleted;

        for(var i = 0; i < posDevices.length; i++) {
            const posDevice = {...posDevices[i]};
            posDevice.aluguel = strToFloat(posDevices[i].aluguel);
            posDevice.insencaoMin = strToFloat(posDevices[i].insencaoMin);
            posDevice.feeGateway = strToFloat(posDevices[i].feeGateway);
            posDevice.feeAntifraude = strToFloat(posDevices[i].feeAntifraude);
            
            dataObj.posDevices.push(posDevice);
        }

        for(var i = 0; i < feesTerms.length; i++) {
            const feeTerm = {...feesTerms[i]};
            feeTerm.tipo = strToInt(feesTerms[i].tipo);
            feeTerm.tipoTaxa = strToInt(feesTerms[i].tipoTaxa);
            feeTerm.bandeira = strToInt(feesTerms[i].bandeira);
            feeTerm.taxa = strToFloat(feesTerms[i].taxa);
            feeTerm.taxaMin = strToFloat(feesTerms[i].taxaMin);
            feeTerm.prazo = strToInt(feesTerms[i].prazo);
            feeTerm.recebimento = strToInt(feesTerms[i].recebimento);

            dataObj.feesTerms.push(feeTerm);
        }

        //console.log(dataObj, "objeto da conta");

        if(action == 1) {
            //console.log('insert');
            route = 'InsertMerchantAsync';
        } else {
            //console.log('edit');
            route = 'UpdateMerchantAsync';
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const EditMerchants = async () => {
            try {

                const { data } = await api.post(`MerchantAccount/${route}`, dataObj, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    var response = data.data;

                    alert(response.texto);

                    if(response.log != 1) {
                        window.location.href = window.location.origin + '/list-merchants';
                    }
                }   
            }catch(error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';              
                }
                if (error?.response?.status === 400) {
                    window.location.href = window.location.origin + '/list-merchants';              
                }
                //console.log(error);
            }
        }
        EditMerchants();
    };

    return (
        <>
            <Card className="bg-white mt-4">
                <Accordion >
                    <Card.Body>
                        <Container className="p-0 mt-3" fluid>
                            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('merchants.merchantsform.name')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="nomeFantasia"
                                            rules={{ required: { value: true, message: 'Por favor, informe o nome' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.nomeFantasia ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete='off'
                                                    required
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="nomeFantasia"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('merchants.merchantsform.companyName')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="razaoSocial"
                                            rules={{ required: { value: true, message: 'Por favor, informe a Razão Social' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.razaoSocial ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete='off'
                                                    required
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="razaoSocial"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('merchants.merchantsform.companyId')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="cnpj"
                                            rules={{ required: { value: true, message: 'Por favor, informe o cnpj' } }}
                                            render={({ field }: any) => (
                                                <InputMask mask="99.999.999/9999-99" value={field?.value} onChange={field?.onChange}>
                                                    {(field: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            aria-invalid={errors?.cnpj ? "true" : ""}
                                                            label={t('merchants.merchantsform.companyId')}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete='off'
                                                            required
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cnpj"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('merchants.merchantsform.managerName')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="nomeGerente"
                                            rules={{ required: { value: true, message: 'Por favor, informe o gerente' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.nomeGerente ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete='off'
                                                    required
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="nomeGerente"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('merchants.merchantsform.email')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="email"
                                            rules={{ required: { value: true, message: 'Por favor, informe o email' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.email ? "true" : ""}
                                                    type="email"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete='off'
                                                    required
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="email"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('merchants.merchantsform.phone')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="telefone"
                                            rules={{ required: { value: true, message: 'Por favor, informe o telefone' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.telefone ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete='off'
                                                    required
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="telefone"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('merchants.merchantsform.status')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="status"
                                            rules={{ required: { value: true, message: 'Por favor, informe o status' } }}
                                            render={({ field }: any) => (
                                                <GetAccountStatus propsField={field} propsErrors={errors} propsLabel={t('merchants.merchantsform.status')} />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="status"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <hr className="my-5" />
                                <h5 className="text-uppercase mb-3">{t('merchants.merchantsform.posDevices')}</h5>
                                <Row>
                                    <MerchantsTablePosDevices action={getPosDevices} info={posDevices} />
                                </Row>
                                <hr className="my-5" />
                                <h5 className="text-uppercase mb-3">{t('merchants.merchantsform.feesTerms')}</h5>
                                <Row>
                                    <MerchantsTableFeesTerms action={getFeesTerms} info={feesTerms} />
                                </Row>
                                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                    <Button type="submit" className="form-button">{t('merchants.merchantsform.buttonSave')}</Button>
                                </Row>
                            </Form>
                        </Container>
                    </Card.Body>
                </Accordion>

            </Card>
        </>
    );
}

export default MerchantsForm;