import { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './infra/PrivateRoute';
//import RedirectLogin from './infra/RedirectLogin';
// import { parseCookies } from 'nookies';

import NotFound from './pages/NotFound/NotFound/NotFound';
import PreLoader from './components/PreLoader/PreLoader';

import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import NewPassword from './pages/NewPassword/NewPassword';

import Users from './pages/Users/Users';
import NewUser from './pages/Users/NewUser';
import EditUser from './pages/Users/EditUser';

//import Dashboard from './pages/Dashboard/Dashboard';

import Checkout from './pages/Checkout/Checkout';

/* Reservation */
import ListReservations from './pages/Reservations/ListReservations/ListReservations';
import Sales from './pages/Sales/Sales';
/* END - Reservation */

import ListPartners from './pages/Partners/ListPartners/ListPartners';
import AddPartners from './pages/Partners/AddPartners/AddPartners';
import EditPartners from './pages/Partners/EditPartners/EditPartners';

import AddSuppliers from './pages/Suppliers/AddSuppliers/AddSuppliers';
import ListSuppliers from './pages/Suppliers/ListSuppliers/ListSuppliers';
import EditSuppliers from './pages/Suppliers/EditSuppliers/EditSuppliers';

import AddAccounts from './pages/Accounts/AddAccounts/AddAccounts';
import EditAccounts from './pages/Accounts/EditAccounts/EditAccounts';
import ListAccounts from './pages/Accounts/ListAccounts/ListAccounts';

//Financial
import AddMerchantFlags from './pages/MerchantFlags/AddMerchantFlags/AddMerchantFlags';
import ListMerchantFlags from './pages/MerchantFlags/ListMerchantFlags/ListMerchantFlags';
import EditMerchantFlags from './pages/MerchantFlags/EditMerchantFlags/EditMerchantFlags';

import AddMerchants from './pages/Merchants/AddMerchants/AddMerchants';
import ListMerchants from './pages/Merchants/ListMerchants/ListMerchants';
import EditMerchants from './pages/Merchants/EditMerchants/EditMerchants';

import BillsToPay from './pages/BillsToPay/BillsToPay';
import SettleBillsToPay from './pages/SettleBillsToPay/SettleBillsToPay';
import BillsToReceive from './pages/BillsToReceive/BillsToReceive';
import SettleBillsToReceive from './pages/SettleBillsToReceive/SettleBillsToReceive';
import Manifest from './pages/Manifest/Manifest';
import SingleSession from './pages/SingleSession/SingleSession';

import SessionClosure from './pages/SessionClosure/SessionClosure';
import SessionBillsToReceive from './pages/SessionClosure/SessionBillsToReceive/SessionBillsToReceive';
import SessionBillsToPay from './pages/SessionClosure/SessionBillsToPay/SessionBillsToPay';
import SessionBilling from './pages/SessionClosure/SessionBilling/SessionBilling';
import PictureOfTheDay from './pages/SessionClosure/PictureOfTheDay/PictureOfTheDay';

import Coupons from './pages/Coupons/Coupons';
import Commissions from './pages/Commissions/Commissions';
import SingleSessionTransfer from './pages/Manifest/components/TableManifest/components/SingleSessionTransfer/SingleSessionTransfer';

import SalesReport from './pages/SalesReport/SalesReport';
import DownloadVisitReport from './pages/DownloadReport/DownloadVisitReport';
import DownloadMarketingReport from './pages/DownloadReport/DownloadMarketingReport';

import TransactionAudit from './pages/TransactionAudit/TransactionAudit';

import Quotation from './pages/Quotation/Quotation';
import BDBReport from './pages/BDBReport/BDBReport';
import DownloadDateTourReport from './pages/DownloadReport/DownloadDateTourReport';
import Products from './pages/Products/Products';
import ProductList from './pages/Products/components/ProductList/ProductList';
import Stock from './pages/Stock/Stock';
import AddTour from './pages/ProductTour/AddTour';
import AddTicket from './pages/ProductTicket/AddTicket';
import EditTour from './pages/ProductTour/EditTour';
import EditTicket from './pages/ProductTicket/EditTicket';
import StockList from './pages/StockList/StockList';
import ListCategories from './pages/Categories/ListCategories/ListCategories';
import AddCategory from './pages/AddCategory/AddCategory';
import AssociateCategories from './pages/Categories/AssociateCategories/AssociateCategories';
import EditAssociation from './pages/Categories/EditAssociation/EditAssociation';

import EditCategory from './pages/Categories/ListCategories/components/EditCategory/EditCategory';
import ListAssociation from './pages/Categories/ListAssociation/ListAssociation';
import RevokeCache from './pages/RevokeCache/RevokeCache';
import ListBlackout from './pages/Blackout/ListBlackout/ListBlackout';
import AddBlackout from './pages/Blackout/AddBlackout';
import EditBlackout from './pages/Blackout/EditBlackout';

import ListChannels from './pages/Channel/ListChannels';
import ListSources from './pages/Source/ListSources';

import CorporateTicket from './pages/Reservations/CorporateTicket/CorporateTicket';
import SalesCorporate from './pages/SalesCorporate/SalesCorporate';

import CheckoutCorporate from './pages/CheckoutCorporate/CheckoutCorporate';
import CorporateClient from './pages/Reservations/CorporateClient/CorporateClient';
import AddCorporateClient from './pages/AddCorporateClient/AddCorporateClient';
import EditCorporateClient from './pages/EditCorporateClient/EditCorporateClient';

function Routes() {
  const [authentication, setAuthState] = useState({
    authenticated: false,
    initializing: true
  });

  // const cookies = parseCookies();

  useEffect(() => {
    const objAuth: any = localStorage.getItem('bpaSigAuth');

    if (objAuth !== null/*  && cookies.authCookie */) {
      setAuthState({
        authenticated: true,
        initializing: false
      });
    } else {
      setAuthState({
        authenticated: false,
        initializing: false
      });

    }

  }, [setAuthState]);

  if (authentication.initializing) {
    return <div><PreLoader /></div>;
  }


  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route exact path="/profile" component={Profile} />
      {/* <RedirectLogin exact path="/" isAuthenticated={authentication.authenticated} component={Login} /> */} {/* Teste renan */}

      <Route exact path="/forgot_password" component={ForgotPassword} />
      <Route exact path="/new_password/:token" component={NewPassword} />

      <PrivateRoute exact path="/dashboard" isAuthenticated={authentication.authenticated} component={ListReservations} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18/* , 23, 24, 25, 26*/, 29]} />

      <PrivateRoute exact path="/sale" isAuthenticated={authentication.authenticated} component={Sales} level={[18, 19, 29]} />
      <PrivateRoute exact path="/checkout" isAuthenticated={authentication.authenticated} component={Checkout} level={[18, 19, 4, 5, 29]} />
      
      <PrivateRoute exact path="/reservations" isAuthenticated={authentication.authenticated} component={ListReservations} level={[18, 19, 1, 2, 3, 10, 11, 12, 13, 14, 16, 17, 29]} />

      <PrivateRoute exact path="/corporate-ticket" isAuthenticated={authentication.authenticated} component={CorporateTicket} level={[0]} />
      <PrivateRoute exact path="/corporate-client" isAuthenticated={authentication.authenticated} component={CorporateClient} level={[0]} />
      <PrivateRoute exact path="/add-corporate-client" isAuthenticated={authentication.authenticated} component={AddCorporateClient} level={[0]} />
      <PrivateRoute exact path="/edit-corporate-client" isAuthenticated={authentication.authenticated} component={EditCorporateClient} level={[0]} />
      <PrivateRoute exact path="/corporate-sale" isAuthenticated={authentication.authenticated} component={SalesCorporate} level={[0]} />
      <PrivateRoute exact path="/corporate-checkout" isAuthenticated={authentication.authenticated} component={CheckoutCorporate} level={[0]} />

      <PrivateRoute exact path="/partners" isAuthenticated={authentication.authenticated} component={ListPartners} level={[19, 4, 5]} />
      <PrivateRoute exact path="/partners/add-partners" isAuthenticated={authentication.authenticated} component={AddPartners} level={[19, 4, 5]} />
      <PrivateRoute exact path="/partners/edit-partners" isAuthenticated={authentication.authenticated} component={EditPartners} level={[19, 4, 5]} />

      <PrivateRoute exact path="/suppliers" isAuthenticated={authentication.authenticated} component={ListSuppliers} level={[0]} />
      <PrivateRoute exact path="/suppliers/add-suppliers" isAuthenticated={authentication.authenticated} component={AddSuppliers} level={[0]} />
      <PrivateRoute exact path="/suppliers/edit-suppliers" isAuthenticated={authentication.authenticated} component={EditSuppliers} level={[0]} />

      <PrivateRoute exact path="/accounts" isAuthenticated={authentication.authenticated} component={ListAccounts} level={[0]} />
      <PrivateRoute exact path="/accounts/add-accounts" isAuthenticated={authentication.authenticated} component={AddAccounts} level={[0]} />
      <PrivateRoute exact path="/accounts/edit-accounts" isAuthenticated={authentication.authenticated} component={EditAccounts} level={[0]} />

      <PrivateRoute exact path="/merchants" isAuthenticated={authentication.authenticated} component={ListMerchants} level={[0]} />
      <PrivateRoute exact path="/merchants/add-merchants" isAuthenticated={authentication.authenticated} component={AddMerchants} level={[0]} />
      <PrivateRoute exact path="/merchants/edit-merchants" isAuthenticated={authentication.authenticated} component={EditMerchants} level={[0]} />

      <PrivateRoute exact path="/merchantflags" isAuthenticated={authentication.authenticated} component={ListMerchantFlags} level={[0]} />
      <PrivateRoute exact path="/merchantflags/add-merchantflags" isAuthenticated={authentication.authenticated} component={AddMerchantFlags} level={[0]} />
      <PrivateRoute exact path="/merchantflags/edit-merchantflags" isAuthenticated={authentication.authenticated} component={EditMerchantFlags} level={[0]} />

      <PrivateRoute exact path="/bills-to-pay" isAuthenticated={authentication.authenticated} component={BillsToPay} level={[0]} />
      <PrivateRoute exact path="/settle-bills-to-pay" isAuthenticated={authentication.authenticated} component={SettleBillsToPay} level={[0]} />
      <PrivateRoute exact path="/bills-to-receive" isAuthenticated={authentication.authenticated} component={BillsToReceive} level={[0]} />
      <PrivateRoute exact path="/settle-bills-to-receive" isAuthenticated={authentication.authenticated} component={SettleBillsToReceive} level={[0]} />

      <PrivateRoute exact path="/manifest" isAuthenticated={authentication.authenticated} component={Manifest} level={[0]} />
      <PrivateRoute exact path="/single-session" isAuthenticated={authentication.authenticated} component={SingleSession} level={[0]} />
      <PrivateRoute exact path="/single-session-transfer" isAuthenticated={authentication.authenticated} component={SingleSessionTransfer} level={[0]} />

      <PrivateRoute exact path="/session-closure" isAuthenticated={authentication.authenticated} component={SessionClosure} level={[0]} />
      <PrivateRoute exact path="/session-closure/bills-to-receive" isAuthenticated={authentication.authenticated} component={SessionBillsToReceive} level={[0]} />
      <PrivateRoute exact path="/session-closure/bills-to-pay" isAuthenticated={authentication.authenticated} component={SessionBillsToPay} level={[0]} />
      <PrivateRoute exact path="/session-closure/billing" isAuthenticated={authentication.authenticated} component={SessionBilling} level={[0]} />
      <PrivateRoute exact path="/session-closure/picture-of-the-day" isAuthenticated={authentication.authenticated} component={PictureOfTheDay} level={[0]} />

      <PrivateRoute exact path="/users" isAuthenticated={authentication.authenticated} component={Users} level={[19, 4, 5]} />
      <PrivateRoute exact path="/users/new-users" isAuthenticated={authentication.authenticated} component={NewUser} level={[19, 4, 5]} />
      <PrivateRoute exact path="/users/edit-users" isAuthenticated={authentication.authenticated} component={EditUser} level={[19, 4, 5]} />

      <PrivateRoute exact path="/coupons" isAuthenticated={authentication.authenticated} component={Coupons} level={[18, 19, 4, 5]} />
      <PrivateRoute exact path="/commissions" isAuthenticated={authentication.authenticated} component={Commissions} level={[0]} />

      <PrivateRoute exact path="/quotation" isAuthenticated={authentication.authenticated} component={Quotation} level={[0]} />

      <PrivateRoute exact path="/sales-report" isAuthenticated={authentication.authenticated} component={SalesReport} level={[19, 1, 7, 8, 9, 10, 16]} />
      <PrivateRoute exact path="/bdb-report" isAuthenticated={authentication.authenticated} component={BDBReport} level={[19, 1, 7, 8, 9, 10, 16]} />
      <PrivateRoute exact path="/download-visit-report" isAuthenticated={authentication.authenticated} component={DownloadVisitReport} level={[19, 1, 7, 8, 9, 10, 16]} />
      <PrivateRoute exact path="/download-date-tour-report" isAuthenticated={authentication.authenticated} component={DownloadDateTourReport} level={[19, 1, 7, 8, 9, 10, 16]} />
      <PrivateRoute exact path="/download-marketing-report" isAuthenticated={authentication.authenticated} component={DownloadMarketingReport} level={[19, 1, 7, 8, 9, 10, 16]} />
      {/* <PrivateRoute exact path="/chart" isAuthenticated={authentication.authenticated} component={Chart} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} /> */}

      <PrivateRoute exact path="/products" isAuthenticated={authentication.authenticated} component={Products} level={[18, 19]} />
      <PrivateRoute exact path="/products/list-products" isAuthenticated={authentication.authenticated} component={ProductList} level={[18, 19]} />

      <PrivateRoute exact path="/products/add-tour" isAuthenticated={authentication.authenticated} component={AddTour} level={[18, 19]} />
      <PrivateRoute exact path="/products/edit-tour" isAuthenticated={authentication.authenticated} component={EditTour} level={[18, 19]} />

      <PrivateRoute exact path="/products/add-ticket" isAuthenticated={authentication.authenticated} component={AddTicket} level={[18, 19]} />
      <PrivateRoute exact path="/products/edit-ticket" isAuthenticated={authentication.authenticated} component={EditTicket} level={[18, 19]} />

      <PrivateRoute exact path="/products/stock" isAuthenticated={authentication.authenticated} component={StockList} level={[18, 19]} />
      <PrivateRoute exact path="/products/add-stock" isAuthenticated={authentication.authenticated} component={Stock} level={[18, 19]} />

      <PrivateRoute exact path="/blackout/listar-blackouts" isAuthenticated={authentication.authenticated} component={ListBlackout} level={[18, 19]} />
      <PrivateRoute exact path="/blackout/add-blackout" isAuthenticated={authentication.authenticated} component={AddBlackout} level={[18, 19]} />
      <PrivateRoute exact path="/blackout/edit-blackout" isAuthenticated={authentication.authenticated} component={EditBlackout} level={[18, 19]} />

      <PrivateRoute exact path="/auditoria" isAuthenticated={authentication.authenticated} component={TransactionAudit} level={[19, 1, 7, 8, 9, 10, 16]} />

      <PrivateRoute exact path="/listar-categorias" isAuthenticated={authentication.authenticated} component={ListCategories} level={[18, 19]} />
      <PrivateRoute exact path="/listar-categorias/adicionar-categorias" isAuthenticated={authentication.authenticated} component={AddCategory} level={[18, 19]} />
      <PrivateRoute exact path="/editar-categoria" isAuthenticated={authentication.authenticated} component={EditCategory} level={[18, 19]} />

      <PrivateRoute exact path="/listar-associar-categorias" isAuthenticated={authentication.authenticated} component={ListAssociation} level={[18, 19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} />
      <PrivateRoute exact path="/associar-categorias" isAuthenticated={authentication.authenticated} component={AssociateCategories} level={[18, 19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} />

      <PrivateRoute exact path="/editar-associacao" isAuthenticated={authentication.authenticated} component={EditAssociation} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} />

      <PrivateRoute exact path="/revogar-cache" isAuthenticated={authentication.authenticated} component={RevokeCache} level={[18, 19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} />

      <PrivateRoute exact path="/channel/list-channels" isAuthenticated={authentication.authenticated} component={ListChannels} level={[19]} />
      <PrivateRoute exact path="/source/list-sources" isAuthenticated={authentication.authenticated} component={ListSources} level={[19]} />

      <Route path="/404" exact component={NotFound} />
      <Route component={NotFound} />

      {
        process.env.REACT_APP_PRODUCTION === "false"
          ?
          <PrivateRoute exact path="/sale" isAuthenticated={authentication.authenticated} component={Sales} level={[19, 29]} />
          :
          <PrivateRoute exact path="/sale" isAuthenticated={authentication.authenticated} component={Sales} level={[29]} />
      }

      {
        process.env.REACT_APP_PRODUCTION === "false"
          ?
          <PrivateRoute exact path="/checkout" isAuthenticated={authentication.authenticated} component={Sales} level={[19, 29]} />
          :
          <PrivateRoute exact path="/checkout" isAuthenticated={authentication.authenticated} component={Sales} level={[29]} />
      }
      
    </Switch>
  );
}

export default Routes;