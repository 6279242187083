import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';
import Select from 'react-select';

export interface propPlus {
    propsField: any,
    isMulti?: any,
    modalitiesCodes: any,
    setValue: any,
    watchSchedules?: any,
};

const GetSchedulesBySalesChannel: React.FC<propPlus> = ({
    propsField, isMulti = false, modalitiesCodes, setValue, watchSchedules
}: propPlus) => {
    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        setValue("schedules", "")
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        async function ListSchedules(canais: any) {
            //let auxC: any = [];
            // canais?.map((canal: any) => {
            //     auxC.push(canal.value);

            //     return auxC
            // });
            
            try {
                const { data } = await api.post(`/Blackout/FetchHorarioByTariffAsync`,
                    {
                        "modalities": [canais]
                    },
                    config);
                if (data.status !== 400) {

                    let aux: any = []
                    data.data.data.forEach((elem: any) => {
                        aux.push(elem)
                        return aux
                    });

                    let newSchedules: any = []
                    if (isMulti) {
                        aux?.forEach((elem: any, index: number) => {
                            newSchedules.push(elem)
                            //         if(aux.map((elem: any) => elem).includes(elem)){
                            //             newSchedules.push(watchSchedules[index]);
                            //         }
                        });
                    }

                    setInfo(aux);
                    //setValue('schedules', newSchedules);
                   
                }
            } catch (error: any) {
                /* setTimeout(() => {
                    ListSchedules();
                }, 5000); */
            }
        }
        ListSchedules(modalitiesCodes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalitiesCodes]);

    if (info !== null) {
        const transformed = info.map((elem: any) => ({ value: elem, label: elem }));
        return (
            <>
                <div className="endpoint">
                    {isMulti === false ?
                        <>
                            <Form.Control
                                as="select"
                                {...propsField}
                                required
                                autoComplete='off'
                                className="form-select"
                            >
                                <option value='0'>Selecione</option>
                                {info.length > 0 ? info.map((info: any) => (
                                    <option value={info}>{info}</option>
                                )) : ''}
                            </Form.Control>
                        </>
                        :
                        <>
                            <Select
                                {...propsField}
                                aria-labelledby="aria-label"
                                inputId="aria-example-input"
                                name="aria-live-color"
                                onMenuOpen={onMenuOpen}
                                onMenuClose={onMenuOpen}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                as="select"
                                variant="standard"
                                margin="normal"
                                placeholder={"Selecione"}
                                isMulti
                                options={transformed}
                                getOptionLabel={(option: any) => `${option.label}`}
                            />
                        </>}
                </div>
            </>
        );
    } else {
        return (
            <div className="endpoint">
                <Select
                    {...propsField}
                    aria-labelledby="aria-label"
                    inputId="aria-example-input"
                    name="aria-live-color"
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuOpen}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    as="select"
                    variant="standard"
                    margin="normal"
                    placeholder={"Selecione"}
                    isMulti
                    options={[]}
                    getOptionLabel={(option: any) => `${option.label}`}
                />
            </div>
        )
    }
}

export default GetSchedulesBySalesChannel;