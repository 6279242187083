import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

import Select from 'react-select';

import { useTranslation } from "react-i18next";
import { useAppSelector } from '../../store/hooks';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  isSelected: boolean
};

const GetCategorySpecialTicket: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, isSelected
}: propPlus) => {
  const { t } = useTranslation();
  const [info, setInfo] = useState<any>(null);

  const cartSIG = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listCostCenter() {
      try {
        const { data } = await api.get(`/DropDown/GetCategorySpecialTicket`, config);
        if (data.status !== 400) {
          setInfo(data.data);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listCostCenter();
  }, []);


  useEffect(() => {

  }, [])

  if (info !== null) {
    return (
      <div className='endpoint'>
        {/*  <div className={propsErrors.coastCenterId || propsErrors.costCenter !== undefined ? "endpoint-error" : "endpoint"}> */}
        <Select
          {...propsField}
          aria-labelledby="aria-label"
          inputId="aria-example-input"
          name="aria-live-color"
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuOpen}
          classNamePrefix={isSelected === false ? "react-error" : "react-special-ticket"}
          label={propsLabel}
          as="select"
          variant="standard"
          margin="normal"
          placeholder="Escolha o tipo de bilhete"
          options={Array.from(info)}
          getOptionLabel={(option: any) => `${option.description}`}
          getOptionValue={(option: any) => `${option.id}`}
          isDisabled={true}
          defaultValue={
            {
              "id": "36",
              "uid": "rescheduling_pax",
              "cpf": null,
              "description": "Reagendamento Com Pax"
            }
          }
        />
        {/* </div> */}
      </div>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetCategorySpecialTicket;