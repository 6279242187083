import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import './BillsToReceive.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterBillsReceive from './components/FilterBills/FilterBillsReceive';
import TableBillsReceive from './components/TableBills/TableBillsReceive';

function BillsToReceive() {

    const [actualLocalizador, setActualLocalizador] = useState<any>("");
    const [actualStatusReserve, setActualStatusReserve] = useState<any>(null);
    const [actualStatusPagamento, setActualStatusPagamento] = useState<any>(null);
    const [actualStatusFormaPagamento, setActualStatusFormaPagamento] = useState<any>(null);
    const [actualSupplier, setActualSupplier] = useState<any>("");
    const [actualAdquirente, setActualAdquirente] = useState<any>(null);
    const [actualToken, setActualToken] = useState<any>("");
    const [actualAgent, setActualAgent] = useState<any>("");
    const [actualRefAgent, setActualRefAgent] = useState<any>("");
    const [actualClient, setActualClient] = useState<any>("");
    const [actualType, setActualType] = useState<any>(null);
    const [actualTypeDate, setActualTypeDate] = useState<any>(3);
    const [actualDate, setActualDate] = useState<any>([]);

    const [billsReceive, setBillsReceive] = useState<any>(null);
    const [parameters, setParameters] = useState<any>(null);
    const [totalBillsReceive, setTotalBillsReceive] = useState<any>(0);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    useEffect(() => {
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        async function listBills() {
            try {
                const { data } = await api.post('BillsReceivable/GetReport',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": "",
                        "idDecolarFenix": 0,
                        "tokenNsu": "",
                        "agenteRef": "",
                        "statusReserva": null,
                        "statusPagamento": null,
                        "formaPagamento": null,
                        "adquirente": null,
                        "fornecedor": "",
                        "agenteIndividual": -1,
                        "agente": "",
                        "cliente": "",
                        "tipoData": 3,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    },
                );
                setLoading(false);
                if (data.status !== 400) {
                    setParameters({
                        "page": 0,
                        "rowsPerPage": 0,
                        "item": "",
                        "idDecolarFenix": 0,
                        "tokenNsu": "",
                        "agenteRef": "",
                        "statusReserva": null,
                        "statusPagamento": null,
                        "formaPagamento": null,
                        "adquirente": null,
                        "fornecedor": "",
                        "agenteIndividual": -1,
                        "agente": "",
                        "cliente": "",
                        "tipoData": 3,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    });
                    setBillsReceive(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                    setTotalBillsReceive(data.data.valorTotal);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listBills();
    }, [pageCount, rowsPerPage]);

    async function buscar(
        localizador: any,
        statusReserva: any,
        statusPagamento: any,
        statusFormaPagamento: any,
        supplier: any,
        adquirente: any,
        tokenNsu: any,
        agent: any,
        refAgent: any,
        client: any,
        type: any,
        typeDate: any,
        date: any
    ) {
        setActualLocalizador(localizador);
        setActualStatusReserve(statusReserva);
        setActualStatusPagamento(statusPagamento);
        setActualStatusFormaPagamento(statusFormaPagamento);
        setActualSupplier(supplier);
        setActualAdquirente(adquirente);
        setActualToken(tokenNsu);
        setActualAgent(agent);
        setActualRefAgent(refAgent);
        setActualClient(client);
        setActualType(type);
        setActualTypeDate(typeDate);
        setActualDate(date);
        setPageCount(1)

        setLoading(true);
        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []
        let endDate = []


        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const res = await api.post('/BillsReceivable/GetReport',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "item": localizador ? localizador : '',
                    "idDecolarFenix": 0,
                    "tokenNsu": tokenNsu ? tokenNsu : '',
                    "agenteRef": refAgent ? refAgent : '',
                    "statusReserva": statusReserva ? parseInt(statusReserva) : null,
                    "statusPagamento": statusPagamento ? parseInt(statusPagamento) : null,
                    "formaPagamento": statusFormaPagamento ? parseInt(statusFormaPagamento) : null,
                    "adquirente": adquirente ? adquirente : null,
                    "fornecedor": supplier ? supplier : '',
                    "agenteIndividual": -1,
                    "agente": agent ? agent : '',
                    "cliente": client ? client : '',
                    "tipoData": typeDate ? parseInt(typeDate) : 3,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            setLoading(false);
            if (res.status !== 400) {
                setParameters({
                    "page": 0,
                    "rowsPerPage": 0,
                    "item": localizador ? localizador : '',
                    "idDecolarFenix": 0,
                    "tokenNsu": tokenNsu ? tokenNsu : '',
                    "agenteRef": refAgent ? refAgent : '',
                    "statusReserva": statusReserva ? parseInt(statusReserva) : null,
                    "statusPagamento": statusPagamento ? parseInt(statusPagamento) : null,
                    "formaPagamento": statusFormaPagamento ? parseInt(statusFormaPagamento) : null,
                    "adquirente": adquirente ? adquirente : null,
                    "fornecedor": supplier ? supplier : '',
                    "agenteIndividual": -1,
                    "agente": agent ? agent : '',
                    "cliente": client ? client : '',
                    "tipoData": typeDate ? parseInt(typeDate) : 3,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                });
                setBillsReceive(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
                setTotalBillsReceive(res.data.data.valorTotal);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function exportBills(layout: any) {
        console.log(layout, actualStatusFormaPagamento)
        let allowRequest: boolean = true;

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        if (layout === 4 && parseInt(actualStatusFormaPagamento) !== 4) { ///quando for cartão de credito so deixa exportar quando for filtro forma pagamento igual a cartão
            allowRequest = false;
        }

        if (allowRequest === true) {
            try {
                const exp = await api.post('/BillsReceivable/ExportReport',
                    {
                        "page": 1,
                        "rowsPerPage": rowsPerPage,
                        "layout": layout,
                        "item": actualLocalizador ? actualLocalizador : '',
                        "idDecolarFenix": 0,
                        "tokenNsu": actualToken ? actualToken : '',
                        "agenteRef": actualRefAgent ? actualRefAgent : '',
                        "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
                        "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
                        "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
                        "adquirente": actualAdquirente ? actualAdquirente : null,
                        "fornecedor": actualSupplier ? actualSupplier : '',
                        "agenteIndividual": -1,
                        "agente": actualAgent ? actualAgent : '',
                        "cliente": actualClient ? actualClient : '',
                        "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 3,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "*/*"
                            //"Access-Control-Allow-Origin": "*"

                        },
                        responseType: 'blob'
                    },

                );
                if (exp.status !== 400) {
                    const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                    const objectUrl = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute(
                        'download',
                        'contas_receber.xlsx',
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);
                }
            } catch (error: any) {
                setError(true)
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
    };

    async function printBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        try {
            const print = await api.post('/BillsReceivable/PrintReport',
                {
                    "page": pageCount,
                    "rowsPerPage": rowsPerPage,
                    "item": actualLocalizador ? actualLocalizador : '',
                    "idDecolarFenix": 0,
                    "tokenNsu": actualToken ? actualToken : '',
                    "agenteRef": actualRefAgent ? actualRefAgent : '',
                    "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
                    "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
                    "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
                    "adquirente": actualAdquirente ? actualAdquirente : null,
                    "fornecedor": actualSupplier ? actualSupplier : '',
                    "agenteIndividual": -1,
                    "agente": actualAgent ? actualAgent : '',
                    "cliente": actualClient ? actualClient : '',
                    "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 3,
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                        //"Access-Control-Allow-Origin": "*"

                    },
                    responseType: 'blob'
                },

            );
            if (print.status !== 400) {
                const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'contas_receber.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);


            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Financeiro / Contas a Receber'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Contas a Receber</h2>
                    </div>
                    <FilterBillsReceive
                        buscar={buscar}
                        billsReceive={billsReceive}
                        error={error}
                    />
                    <TableBillsReceive
                        billsReceive={billsReceive}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportBills={exportBills}
                        printBills={printBills}
                        loading={loading}
                        totalBillsReceive={totalBillsReceive}
                        actualDate={actualDate}
                        parameters={parameters}
                    />
                </div>
            </DesktopDefault>
        </>
    );

}

export default BillsToReceive;