import React, { Key, useEffect, useState } from 'react';
//import Select from 'react-select';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  setPaymentMethod: any
};

const GetPaymentMethodsFilter: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, setPaymentMethod
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [value, setValue] = useState<any>(propsField.value);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  const handleChangeMethod = (e: any) => {
    
    const newValue = e.target.value;
    setPaymentMethod(info.find((item: any) => item.id == newValue));
    setValue(newValue);
    //console.log(newValue);
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listAccountingAccounts() {
      try {
        const { data } = await api.get(`/BillsReceivable/GetPaymentMethods`, config);
        if (data.status !== 400) {
          setInfo(data.data)
          /* console.log(data); */
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountingAccounts();
  }, []);

  if (info !== null) {
    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
          {/* <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            onChange={(e:any) => console.log(e)}
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={t("accordionFinancial.select.select")}
            options={Array.from(info)}
            getOptionValue={(option: any) => `${option.id}`}
            getOptionLabel={(option: any) => `${option.descricao}`}
            value={info.find((a:any) => a.id == propsField.value)}
          /> */}
          <Form.Select
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            required
            onChange={handleChangeMethod}
            defaultValue={propsField.value}
            value={value}
          >
            <option value='' disabled>Selecione</option>
            {info.length > 0 ? info.map((info: any, index: Key) => (
              <option key={index} value={info.id}>{info.descricao}</option>
            )) : ''}
          </Form.Select>  
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetPaymentMethodsFilter;