import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../../components/Pagination/Pagination";
import api from "../../../../../services/api";

export interface propFilter {
    setCategorie: any; setModalCategorieShow: any; categorie: any;
}

const ModalAssociateCategorie: React.FC<propFilter> = ({
    setCategorie, setModalCategorieShow, categorie
}) => {
    const [t] = useTranslation();
    const [foundProducts, setFoundProducts] = useState<any>([]);
    const [actualName, setActualName] = useState<any>("");
    const [actualCode, setActualCode] = useState<any>("");
    const [actualStatus, setActualStatus] = useState<any>(0);

    const [itemToBeListed, setItemToBeListed] = useState<any>(categorie === null ? [] : categorie);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    function clearActualValues() {
        setActualName("");
        setActualCode("");
        setActualStatus(0);

        buscar('', '', 0);
    }

    const handleToLink = () => {
        setCategorie(itemToBeListed);
        setModalCategorieShow(false);
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        async function filter() {
            try {
                const { data } = await api.post(`/Categories/GetCategoriesByFilterAsync`,
                    {
                        page: pageCount,
                        rowsPerPage: rowsPerPage,
                        categorieNameFind: actualName,
                        categorieCodeFind: actualCode,
                        categorieStatusFind: actualStatus,
                        orderByField: ""
                    }, config
                );
                if (data.status !== 400) {
                    //console.log(data.data.data)
                    setFoundProducts(data.data.data.rows);
                    setTotalRows(data.data.data.rowsCount);
                }

            } catch (error) { }
        }
        filter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);


    async function buscar(name: any, code: any, status: any) {
        //console.log(name, code, status)
        setActualName(name);
        setActualCode(code);
        setActualStatus(status);
        setPageCount(1);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        try {
            const { data } = await api.post('/Categories/GetCategoriesByFilterAsync',
                {
                    page: pageCount,
                    rowsPerPage: rowsPerPage,
                    categorieNameFind: name,
                    categorieCodeFind: code,
                    categorieStatusFind: status,
                    orderByField: ""
                }
                , config
            );

            if (data.status !== 400) {
                setFoundProducts(data.data.data.rows);
                setTotalRows(data.data.data.rowsCount);
            }
        } catch (error: any) {
            console.log(error.response)

        }
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setActualName(newValue);
    };

    const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setActualCode(newValue);
    };

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setActualStatus(newValue);
    };


    const {
        control,
    } = useForm();

    const handleFilterClick = () => {
        buscar(actualName, actualCode, actualStatus)
    }


    const selectRow: SelectRowProps<any> = {
        mode: 'radio',
        clickToSelect: true,
        onSelect: (row) => {
            setItemToBeListed([row])
        }
    }

    const rowClasses = (row: any, rowIndex: any) => {
        if (row.categorieStatus === "Em Análise") {
            return 'border-analise';
        } else if (row.categorieStatus === "Ativo") {
            return 'border-ativo';
        } else {
            return 'border-inativo';
        }
    };

    const columns = [
        //{ dataField: "product_code", text: "" },
        { dataField: "categorieName", text: "Nome da Categoria" },
        { dataField: "categorieCode", text: "Código da Categoria" },
        { dataField: "categorieStatus", text: "Status da Categoria" },
    ];

    useEffect(() => {
        //console.log(foundProducts)
    }, [foundProducts])

    return (
        <>
            <div>
                <Card className="p-4">
                    <Form className="h-100">
                        <div className='d-flex flex-column justify-content-between h-100'>
                            <div>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>Nome da Categoria</Form.Label>
                                        <Controller
                                            control={control}
                                            name="searchProduct"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className="input-select-custom">
                                                    <Form.Control
                                                        {...field}
                                                        label="product"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        value={actualName}
                                                        onChange={(e: any) => handleChangeName(e)}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>Código da Categoria</Form.Label>
                                        <Controller
                                            control={control}
                                            name="searchCity"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className="input-select-custom">
                                                    <Form.Control
                                                        {...field}
                                                        label="city"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        value={actualCode}
                                                        onChange={(e: any) => handleChangeCode(e)}
                                                    />
                                                </div>
                                            )}
                                        />

                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Status</Form.Label>
                                        <Controller
                                            control={control}
                                            name="searchSupplier"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className="input-select-custom endpoint">
                                                    <Form.Select
                                                        value={actualStatus}
                                                        onChange={(e: any) => handleChangeStatus(e)}
                                                    >
                                                        <option value='0' disabled>Selecione</option>
                                                        <option value='1'>Ativo</option>
                                                        <option value='2'>Inativo</option>
                                                    </Form.Select>
                                                </div>

                                            )}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row
                                    as={Col}
                                    md="12"
                                    className="mt-5 justify-content-md-end"
                                >
                                    <Col xs lg="12" className="d-flex justify-content-md-end">

                                        <button className="btn btn-default btn-outline-primary px-4 mr-3" type='button'
                                            onClick={clearActualValues}
                                        >
                                            LIMPAR
                                        </button>

                                        <button className="btn btn-default btn-primary" type='button'
                                            onClick={handleFilterClick}
                                        >
                                            PESQUISAR
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </Card>

            </div>

            {/* TABELA */}
            <div className="mt-4">
                <div className="table-default ModalAssociateCategorieFound-table">
                    <div className="table-container">
                        <div>
                            <BootstrapTable
                                bootstrap4
                                keyField="categorieCode"
                                data={foundProducts}
                                columns={columns}
                                selectRow={selectRow}
                                rowClasses={rowClasses}
                            />
                            <Pagination
                                totalRows={totalRows}
                                pageCount={pageCount}
                                setPageCount={setPageCount}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                selectNumberRows={"yes"}
                            />
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <div className="btn btn-default btn-outline-primary px-4 mr-3" /* type='button' */ onClick={() => { setModalCategorieShow(false) }}>Cancelar</div>
                            <div className="btn btn-default btn-primary" onClick={handleToLink}>Associar</div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default ModalAssociateCategorie;