import React, { useEffect, useState } from 'react';
import api from '../../services/api';

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ChartSalesReport from './components/ChartSalesReport/ChartSalesReport';
import FilterSalesReport from './components/FilterSalesReport/FilterSalesReport';
import TableSalesReport from './components/TableSalesReport/TableSalesReport';

//Style
import './SalesReport.scss';

function SalesReport() {
    var today: any = new Date();
    const bilheteria: string = 'bilheteria';

    const [info, setInfo] = useState<any>(null);
    const [infoChart, setInfoChart] = useState<any>(null);
    const [filterBySelected, setFilterBySelected] = useState<any>('Mês');
    const [loading, setLoading] = useState<any>(false);

    const [actualType, setActualType] = useState<any>(0);
    const [actualDateType, setActualDateType] = useState<any>(1);
    const [actualDate, setActualDate] = useState<any>([`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`]);
    const [actualReservationStatus, setActualReservationStatus] = useState<any>(1);
    const [actualPaymentStatus, setActualPaymentStatus] = useState<any>(0);
    const [actualChannel, setActualChannel] = useState<any>(0);
    const [actualPromoter, setActualPromoter] = useState<any>("");
    const [actualClient, setActualClient] = useState<any>("");
    const [actualUser, setActualUser] = useState<any>("");
    const [actualProduct, setActualProduct] = useState<any>("");
    const [actualFilter, setActualFilter] = useState<any>(2);
    const [actualSource, setActualSource] = useState<any>(bilheteria);
    const [actualCaixa, setActuaCaixa] = useState<any>('');
    const [actualOperator, setActualOperator] = useState<any>('');

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    /* useEffect(() => {
        var today: any = new Date();
        let calendarToday: any = [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`];

        if (calendarToday[0] === calendarToday[1]) {
            buscar(1, 1, calendarToday, 0, 0, 0, '', '', '', '', '', '', '', '');
        } else {
            buscar(1, 1, actualDate, 0, 0, 0, '', '', '', '', '', '', '', '');
        }


        console.log(actualDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]) */

    useEffect(() => {
        /* setReservations(null);
        setLoading(true); */
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        ;
        async function listarReservas() {
            try {
                const { data } = await api.post('/Report/ManagementReport',
                    {
                        "type": actualType,//1 = tour | 2 = transfer | 4 = ticket,
                        "dateType": actualDateType === '' ? 1 : parseInt(actualDateType),//1 = Data da Reserva | 2 = Data da Operação,
                        "dateI": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : "",
                        "dateF": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0]) + 1}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : "",
                        "reservationStatus": Number(actualReservationStatus),//"'' = Todas|1 = confirmado|2 = cancelado|7 = pre reserva",
                        "paymentStatus": actualPaymentStatus === '' ? 0 : actualPaymentStatus,//1 = Pagos | 2 = Pendentes,
                        "channel": actualChannel, //1,
                        "promoter": actualPromoter,//user_code tab promoter, ''
                        "affiliate": actualClient,//supplier_identity, ''
                        "user": actualUser, //''
                        "product": actualProduct,//"product_cod tab prod_modalidades"
                        "filter": actualFilter === '' ? 2 : actualFilter,//"1 = Ano, 2 = Mês, 3 = Dia"
                        "source": actualSource, //Origem da compra, bilheteria, sig, site api/ DropDown / GetAllSource
                        "caixa": actualSource === 'bilheteria' ? actualCaixa : undefined, //Caso o source seja Bilheteria envie o codigo do caixa api / DropDown / GetCaixaBilheteria
                        "operadorUid": actualSource === 'bilheteria' ? actualOperator : undefined, //Caso o source seja Bilheteria envie o uid do Usuário api / DropDown / GetOperadoresCaixa
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                    }, config);

                if (data.status !== 400) {
                    setLoading(false);
                    setInfoChart(data.data.managementReportG);
                    setInfo(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                setLoading(false);
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listarReservas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function buscar(type: any, dateType: any, date: any, reservationStatus: any, paymentStatus: any, channel: any, promoter: any, client: any, user: any, product: any, filter: any, source: any, caixa: any, operator: any) {
        setLoading(true);
        setFilterBySelected(filter === '' ? 'Mês' : filter === '1' ? 'Ano' : 'Dia')

        setActualType(type);
        setActualDateType(dateType);
        setActualDate(date);
        setActualReservationStatus(reservationStatus);
        setActualPaymentStatus(paymentStatus);
        setActualChannel(channel);
        setActualPromoter(promoter);
        setActualClient(client);
        setActualUser(user);
        setActualProduct(product);
        setActualFilter(filter);
        setActualSource(source);
        setActuaCaixa(caixa);
        setActualOperator(operator);

        let beginDate = []
        let endDate = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const { data } = await api.post('/Report/ManagementReport',
                {
                    "type": type,//1 = tour | 2 = transfer | 4 = ticket,
                    "dateType": dateType === '' ? 1 : parseInt(dateType),//1 = Data da Reserva | 2 = Data da Operação,
                    "dateI": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dateF": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "reservationStatus": Number(reservationStatus),//"'' = Todas|1 = confirmado|2 = cancelado|7 = pre reserva",
                    "paymentStatus": paymentStatus === '' ? 0 : paymentStatus,//1 = Pagos | 2 = Pendentes,
                    "channel": channel, //1,
                    "promoter": promoter,//user_code tab promoter, ''
                    "affiliate": client,//supplier_identity, ''
                    "user": user, //''
                    "product": product,//"product_cod tab prod_modalidades"
                    "filter": filter === '' ? 2 : filter,//"1 = Ano, 2 = Mês, 3 = Dia"
                    "source": source, //Origem da compra, bilheteria, sig, site api/ DropDown / GetAllSource
                    "caixa": source === 'bilheteria' ? caixa : undefined, //Caso o source seja Bilheteria envie o codigo do caixa api / DropDown / GetCaixaBilheteria
                    "operadorUid": source === 'bilheteria' ? operator : undefined, //Caso o source seja Bilheteria envie o uid do Usuário api / DropDown / GetOperadoresCaixa
                    "page": pageCount,
                    "rowsPerPage": rowsPerPage,
                }, config);

            if (data.status !== 400) {
                setLoading(false);
                setInfoChart(data.data.managementReportG);
                setInfo(data.data.rows);
                setTotalRows(data.data.rowsCount);
            }
        } catch (error: any) {
            setLoading(false);
        }
    };

    const exportReport = () => {
        async function exporting() {
            let beginDate = []
            let endDate = []

            if (actualDate.length === 1) {
                beginDate = actualDate[0].split('/')
            } else if (actualDate.length === 2) {
                beginDate = actualDate[0].split('/')
                endDate = actualDate[1].split('/')
            }

            let today: any = new Date();

            const token = localStorage.getItem('GroupId') || '{}';

            try {
                const exp = await api.post('/Report/ManagementReportExcel',
                    {
                        "type": actualType,//1 = tour | 2 = transfer | 4 = ticket,
                        "dateType": actualDateType,//1 = Data da Reserva | 2 = Data da Operação,
                        "dateI": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dateF": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "reservationStatus": actualReservationStatus,//"'' = Todas|1 = confirmado|2 = cancelado|7 = pre reserva",
                        "paymentStatus": actualPaymentStatus,//1 = Pagos | 2 = Pendentes,
                        "channel": actualChannel, //1,
                        "promoter": actualPromoter,//user_code tab promoter, ''
                        "affiliate": actualClient,//supplier_identity, ''
                        "user": actualUser, //''
                        "product": actualProduct,//"product_cod tab prod_modalidades"
                        "filter": actualFilter === '' ? 2 : actualFilter,//"1 = Ano, 2 = Mês, 3 = Dia"
                        "source": actualSource, //Origem da compra, bilheteria, sig, site api/ DropDown / GetAllSource
                        "caixa": actualCaixa, //Caso o source seja Bilheteria envie o codigo do caixa api / DropDown / GetCaixaBilheteria
                        "operadorUid": actualOperator, //Caso o source seja Bilheteria envie o uid do Usuário api / DropDown / GetOperadoresCaixa
                        "page": 0,
                        "rowsPerPage": 0,
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            "Content-Type": "application/json",
                            "Accept": "*/*"
                            //"Access-Control-Allow-Origin": "*"

                        },
                        responseType: 'blob'
                    });

                if (exp.status !== 400) {
                    console.log(exp)
                    const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                    const objectUrl = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute(
                        'download',
                        'relatório_de_vendas.xlsx',
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);
                }
            } catch (error: any) {

            }
        }
        exporting();
    }

    const printReport = () => {
        async function printing() {
            let beginDate = []
            let endDate = []

            if (actualDate.length === 1) {
                beginDate = actualDate[0].split('/')
            } else if (actualDate.length === 2) {
                beginDate = actualDate[0].split('/')
                endDate = actualDate[1].split('/')
            }

            let today: any = new Date();

            const token = localStorage.getItem('GroupId') || '{}';

            try {
                const exp = await api.post('/Report/ManagementReportPDF',
                    {
                        "type": actualType,//1 = tour | 2 = transfer | 4 = ticket,
                        "dateType": actualDateType,//1 = Data da Reserva | 2 = Data da Operação,
                        "dateI": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dateF": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "reservationStatus": actualReservationStatus,//"'' = Todas|1 = confirmado|2 = cancelado|7 = pre reserva",
                        "paymentStatus": actualPaymentStatus,//1 = Pagos | 2 = Pendentes,
                        "channel": actualChannel, //1,
                        "promoter": actualPromoter,//user_code tab promoter, ''
                        "affiliate": actualClient,//supplier_identity, ''
                        "user": actualUser, //''
                        "product": actualProduct,//"product_cod tab prod_modalidades"
                        "filter": actualFilter === '' ? 2 : actualFilter,//"1 = Ano, 2 = Mês, 3 = Dia"
                        "source": actualSource, //Origem da compra, bilheteria, sig, site api/ DropDown / GetAllSource
                        "caixa": actualCaixa, //Caso o source seja Bilheteria envie o codigo do caixa api / DropDown / GetCaixaBilheteria
                        "operadorUid": actualOperator, //Caso o source seja Bilheteria envie o uid do Usuário api / DropDown / GetOperadoresCaixa
                        "page": 0,
                        "rowsPerPage": 0,
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            "Content-Type": "application/json",
                            "Accept": "*/*"
                            //"Access-Control-Allow-Origin": "*"

                        },
                        responseType: 'blob'
                    });

                if (exp.status !== 400) {
                    console.log(exp)
                    const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                    const objectUrl = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute(
                        'download',
                        'relatório_de_vendas.pdf',
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);
                }
            } catch (error: any) {

            }
        }
        printing();
    }

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard sales-report">
                    <Breadcrumb title={'Relatório de vendas'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Relatório de vendas</h2>
                    </div>

                    <FilterSalesReport buscar={buscar} setPageCount={setPageCount} />

                    {
                        info !== null && info?.length > 0
                            ?
                            <ChartSalesReport infoChart={infoChart} filter={filterBySelected} loading={loading} />
                            :
                            <></>
                    }

                    <TableSalesReport
                        info={info}
                        loading={loading}
                        exportReport={exportReport}
                        printReport={printReport}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />

                </div>
            </DesktopDefault>
        </>

    );
}

export default SalesReport;