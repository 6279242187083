import React, { useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import { useTranslation } from 'react-i18next';

import api from '../../../../services/api';

function AuthForgotPass() {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<any>(null);

    const [modalResponse, setModalResponse] = useState<any>(null);
    const [showModal, setShowModal] = useState<any>(false);
    const [log, setLog] = useState<any>(0);

    const onSubmit = (e: any) => {
        e.preventDefault();
        const getAuthenticate = async () => {
            try {
                const { data } = await api.post('/UserPermission/ResetPassword', {
                    "email": e.target.email.value,
                    "source": "sig"
                });
                
                if (data.status !== 400) {
                    setModalResponse(data.data.texto);
                    setShowModal(true);
                    setLog(data.data.log);
                    /* localStorage.setItem('bpaSigAuth', JSON.stringify(data.data));
                    window.location.href = window.location.origin + '/dashboard'; */
                }

            } catch (error: any) {
                if (error.response.status === 400) {
                    console.log(error.response.data.errorMessage);
                    setErrorMessage(error.response.data.errorMessage)
                }
            }
        }
        getAuthenticate();
    };

    const GetError = () => {
        console.log(errorMessage === null);
        if (errorMessage === null) {
            return (
                <div className="form-group"></div>
            );
        } else {
            return (
                <div className="invalid form-group">
                    <div className="invalid-feedback">{errorMessage}</div>
                </div>
            );
        }
    }

    return (
        <>
            <form className="user" name="login_form" onSubmit={(e) => onSubmit(e)}>

                <GetError />
                <div className="form-group">
                    <label htmlFor="exampleInputEmail">{t("login.forgot.digitEmail")}</label>
                    <input type="email" name="email" className="form-control" id="exampleInputEmail" aria-describedby="emailHelp" autoComplete="off" />
                </div>

                <input type="submit" value={`${t("login.forgot.reset")}`} className="btn btn-primary btn-block btn-forgot" autoComplete="off" />
            </form>

            <Modal
                className="modalAuth"
                show={showModal}
                onHide={() => { setModalResponse(''); setShowModal(false); }}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <div className="d-flex flex-column justify-content-evenly align-items-center" style={{ height: '650px' }}>
                        <div className="mb-4">

                            {log === 0
                                ?
                                <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    style={{ fontSize: "7.5em" }}
                                />
                                :
                                <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    style={{ fontSize: "7.5em" }}
                                />
                            }
                        </div>
                        {modalResponse}
                            <Row className="d-flex justify-content-end mx-0">
                                <Button
                                    type="button"
                                    className="form-button"
                                    onClick={()=>{setShowModal(false);}}
                                >
                                    Confirmar
                                </Button>
                            </Row>
                    </div>
                </>
            </Modal>
        </>
    )
}

export default AuthForgotPass;

