/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import GetAgents from '../../C2Points/GetAgents';
import GetCountrysCheckout from '../../C2Points/GetCountrysCheckout';
import GetClientsCorp from '../../C2Points/GetClientsCorp';
import { useAppSelector } from '../../../store/hooks';
//import GetDDI from '../../C2Points/GetDDI';

export interface propForm {
    control: any,
    errors: any,
    setValue: any,
    setEmail?: any;
    isQuotation?: any;
    isForeign: boolean;
    setIsForeign: any;
};

const BookingInformation: React.FC<propForm> = ({
    control, errors, setValue, setEmail, isQuotation = false, setIsForeign, isForeign
}: propForm) => {
    const bpaSigAuth: any = JSON.parse(localStorage.getItem("bpaSigAuth") || "{}");

    const cartSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    const [state, setState] = useState<any>({
        value: '',
        mask: '(99)99999-9999',
    });

    const addAgents = (objAgents: any) => {
        setValue(`agent`, objAgents.supplierIdentity);
    };

    useEffect(() => {
        if (bpaSigAuth.user.perfil.isAgent === 1) {
            setValue(`agent`, null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addCountrys = (objCountrys: any) => {
        //console.log(objCountrys);
        setValue(`country`, objCountrys.id);
    };

    const handleMaskChange = (event: any, onChange: any) => {
        onChange(event);
        var value = event.target.value;
        var newState = {
            mask: '(99)99999-9999',
            value: value
        };

        setState(newState);
    }

    const handleMaskBlur = (event: any, onChange: any) => {
        onChange(event);
        var value = event.target.value;

        var newState: any;

        if (event.target.value.split('_').join('').split('(').join('').split(')').join('').split('-').join('').length === 11) {
            newState = {
                mask: '(99)99999-9999',
                value: value
            };
        } else {
            newState = {
                mask: '(99)9999-9999',
                value: value
            };
        }

        setState(newState);
    }

    const handleMaskFocus = (event: any, onChange: any) => {
        onChange(event);
        var value = event.target.value;

        var newState: any;

        newState = {
            mask: '(99)99999-9999',
            value: value
        };

        setState(newState);
    }

    return (
        <>
            <div className="card-title text-primary">
                <h5>Detalhes da Solicitação</h5>
            </div>
            <Card className="card-unit bg-white mt-2">
                <Card.Body>
                    {/* <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false"> */}
                    <Row>
                        <Form.Group as={Col} md="4" controlId="formCheckoutName" className='mb-3'>
                            <Form.Label>Nome</Form.Label>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ required: { value: true, message: 'Por favor, informe o nome.' } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.compName ? "true" : ""}
                                        label="Nome"
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="name"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutLastName" className='mb-3'>
                            <Form.Label>Sobrenome</Form.Label>
                            <Controller
                                control={control}
                                name="lastName"
                                rules={{ required: { value: true, message: 'Por favor, informe o sobrenome.' } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.compFantasia ? "true" : ""}
                                        label="Sobrenome"
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="lastName"
                                render={({ message }) => (
                                    <small style={{ color: "red" }}>{message}</small>
                                )}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutEmail" className='mb-3'>
                            <Form.Label>Email</Form.Label>
                            <Controller
                                control={control}
                                name="email"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Por favor, informe o email.",
                                    },
                                }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        type="email"
                                        aria-invalid={errors?.website ? "true" : ""}
                                        label="Email"
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="formCheckoutPhone" className='mb-3'>
                            <Form.Label>Telefone</Form.Label>
                            <Row>
                                <Col md="12">

                                    <Controller
                                        control={control}
                                        name="phone"
                                        rules={{ required: { value: true, message: 'Por favor, informe o telefone.' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.compName ? "true" : ""}
                                                label="Telefone"
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="phone"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutLanguage" className='mb-3'>
                            <Form.Label>Idioma</Form.Label>
                            <Controller
                                control={control}
                                name="language"
                                render={({ field }: any) => (
                                    <Form.Select
                                        {...field}
                                        as="select"
                                        variant="standard"
                                        margin="normal"
                                        style={{ border: "1px solid #ced4da" }}
                                        required
                                    >
                                        <option value='' selected disabled>-Selecione-</option>
                                        <option value='1'>Português</option>
                                        <option value='2'>Inglês</option>
                                        <option value='3'>Espanhol</option>

                                    </Form.Select>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="language"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="1" controlId="">
                            <div className="d-flex align-items-center pt-3 px-3 h-100">
                                <Controller
                                    control={control}
                                    name="foreign"
                                    render={({ field }: any) => (
                                        <Form.Check
                                            {...field}
                                            defaultChecked={isForeign === false}
                                            onClick={() => { setIsForeign(false) }}
                                            type="radio"
                                            variant="standard"
                                            margin="normal"
                                            className="mx-2"
                                            required
                                        />
                                    )}
                                />
                                <Form.Label className="mb-0">
                                    Brasileiro
                                </Form.Label>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md="1" controlId="">
                            <div className="d-flex align-items-center pt-3 px-5 h-100">
                                <Controller
                                    control={control}
                                    name="foreign"
                                    render={({ field }: any) => (
                                        <Form.Check
                                            {...field}
                                            defaultChecked={isForeign === true}
                                            onClick={() => { setIsForeign(true) }}
                                            type="radio"
                                            variant="standard"
                                            margin="normal"
                                            className="mx-2"
                                            required
                                        />
                                    )}
                                />
                                <Form.Label className="mb-0">
                                    Estrangeiro
                                </Form.Label>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Controller
                            control={control}
                            name="isMain"
                            rules={{ required: { value: true, message: '' } }}
                            render={({ field }: any) => (
                                <label htmlFor={"isMainContact"} className="d-flex">
                                    <Form.Check
                                        {...field}
                                        type="checkbox"
                                        id="isMainContact"
                                        value=' '
                                        required
                                    />
                                    <small className="mx-2">Visitante principal</small>
                                </label>
                            )}
                        />
                    </Row>

                    {
                        bpaSigAuth.user.perfil.isAgent !== 1 &&
                        <>
                            <hr />

                            <Row className="mb-3">
                                {/*  <Form.Group as={Col} md="4" controlId="formCheckoutAgent">
                                    <Form.Label>Agente</Form.Label>
                                    <GetAgents actionAgents={addAgents} />
                                    <Controller
                                        control={control}
                                        name="agent"
                                        render={({ field }) => {
                                            // sending integer instead of string.
                                            return <input {...field} type="hidden" />;
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="agent"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formCheckoutReference">
                                    <Form.Label>Cliente</Form.Label>
                                    <Controller
                                        control={control}
                                        name="idCoporation"
                                        rules={{ required: { value: true, message: 'Por favor, informe o cliente.' } }}
                                        render={({ field }: any) => (
                                            <GetClientsCorp
                                                propsField={field}
                                                propsLabel={"Cliente"}
                                                propsErrors={errors}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="idCoporation"
                                        render={({ message }) => (
                                            <small style={{ color: "red" }}>{message}</small>
                                        )}
                                    />
                                </Form.Group>

                                {
                                    cartSIG?.dados[0]?.source === "rescheduling_pax"
                                        ?
                                        <Form.Group as={Col} md="4" controlId="formCheckoutReference">
                                            <Form.Label>Reserva Original</Form.Label>
                                            <Controller
                                                control={control}
                                                name="bookingloc"
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'Por favor, informe o nª da reserva original.'
                                                    },
                                                    minLength: {
                                                        value: 7,
                                                        message: `Por favor, insira no mínimo ${7} caracteres`,
                                                    }
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.bookingloc ? "true" : ""}
                                                        label="Nª da Reserva"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        maxLength={7}
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="bookingloc"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        :
                                        <></>
                                }

                                {isQuotation === true
                                    ?
                                    <Form.Group as={Col} md="4" controlId="formCheckoutLanguage" className='mb-3'>
                                        <Form.Label>Expiração</Form.Label>
                                        <Controller
                                            control={control}
                                            name="expiration"
                                            rules={{ required: { value: true, message: 'Por favor, informe um tempo de expiração.' } }}
                                            render={({ field }: any) => (
                                                <Form.Select
                                                    {...field}
                                                    as="select"
                                                    variant="standard"
                                                    margin="normal"
                                                    style={{ border: "1px solid #ced4da" }}
                                                    required
                                                >
                                                    <option value='' selected disabled>-Selecione-</option>
                                                    <option value='1'>1 hora</option>
                                                    <option value='2'>2 horas</option>
                                                    <option value='4'>4 horas</option>
                                                    <option value='6'>6 horas</option>
                                                    <option value='8'>8 horas</option>
                                                    <option value='12'>12 horas</option>

                                                </Form.Select>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="expiration"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    :
                                    <></>
                                }

                            </Row>

                        </>
                    }
                </Card.Body>
            </Card>
        </>
    );
}

export default BookingInformation;