/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
    propsField: any,
    propsErrors: any,
    setValue: any;
    setSalesChannelObj?: any;
    infoEdit?: any;
    setInfoChange?: any;
    status?: number;
};

const GetSalesChannelCategory: React.FC<propPlus> = ({
    propsField, propsErrors, setValue, setSalesChannelObj = () => {}, setInfoChange = () => {}, infoEdit = null, status = 1
}: propPlus) => {
    const { t } = useTranslation();

    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [defaultItems, setDefaultItems] = useState<any>([]);

    const [selectedOption, setSelectedOption] = useState<any>([]);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        async function listSales() {
            try {
                const { data } = await api.get(`/SalesChannel/GetSalesChannel?StatuSalesChannel=${status}`, config);
                if (data.status !== 400) {

                    setSalesChannelObj(data.data.data);
                    setInfo(data.data.data);
                }
            } catch (error: any) {}
        }
        listSales();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (infoEdit !== null && info !== null) {
            let defaultIds: any = infoEdit.map((e: any) => e?.idSalesChannel || e?.value);
            let defaultValues: any = info?.filter((e: any) => defaultIds.includes(e.idSalesChannel)).map(({ idSalesChannel, salesChannelName }: any) => ({ value: idSalesChannel, label: salesChannelName })) || [];
            setValue('salesChannel', defaultValues);
            setInfoChange(() => [...defaultValues]);
            setSelectedOption(() => [...defaultValues]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInfoChange(e);
        setValue('salesChannel', e);
        setSelectedOption(e)
    }

    if (info !== null) {
        const transformed = info.map(({ idSalesChannel, salesChannelName }: any) => ({ value: idSalesChannel, label: salesChannelName }));

        return (
            <>
                <div className={propsErrors.salesChannel !== undefined ? "endpoint-error" : "endpoint"}>
                    <Select
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuOpen}
                        {...propsField}
                        onChange={handleChange}
                        // value={selectedOption}
                        aria-invalid={propsErrors?.salesChannel ? "true" : ""}
                        as="select"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        margin="normal"
                        placeholder={t('accordionFinancial.select.select')}
                        isMulti
                        options={transformed}
                        getOptionLabel={(option: any) => `${option.label}`}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value='' disabled></option>
                </Form.Select>
            </>
        )
    }
}

export default GetSalesChannelCategory;