import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
/* import PaymentCheckoutCredit from './PaymentCheckoutCredit';
import PaymentCheckoutCreditOffline from './PaymentCheckoutCreditOffline'; */
import PaymentCheckoutBilled from './PaymentCheckoutBilled';
import PaymentCheckoutStripeQrCode from './PaymentCheckoutStripeQrCode';
//import PaymentCheckoutStripeQrCode from './PaymentCheckoutStripeQrCode';
/* import PaymentCheckoutDebit from './PaymentCheckoutDebit';
import PaymentCheckoutPix from './PaymentCheckoutPix';
import PaymentCheckoutDirect from './PaymentCheckoutDirect';
import PaymentCheckoutCash from './PaymentCheckoutCash';
import PaymentCheckoutBankTransfer from './PaymentCheckoutBankTransfer';
import PaymentCheckoutPaypal from './PaymentCheckoutPaypal'; */


export interface propForm {
    control: any,
    errors: any,
    setValue: any,
    action: any,
    isForeign: any,
    getValues: any
};

const PaymentCheckout: React.FC<propForm> = ({
    control, errors, setValue, action, isForeign, getValues
}: propForm) => {
    const bpaSigAuth: any = JSON.parse(localStorage.getItem("bpaSigAuth") || "{}");

    const level = atob(JSON.parse(localStorage.getItem('bpaSigAuth') || '{}').user.perfil.nivel);

    const [paymentType, setPaymentType] = useState<any>(level === "19" || level === "18" ? "link" : "billed");

    useEffect(() => {
        setValue('city', '');
        setValue('state', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentType])

    function handleChangePaymentOption(e: any) {
        action(e.id, e.merchantPayMethod)
        setPaymentType(e.value)
        setValue('paymentOptions', e.value);
    }

    const admnistrador: any = [
        /*         { value: "credit", label: "Cartão de Crédito", id: '1' , merchantPayMethod: '1'},
                { value: "cOffiline", label: "Cartão de Crédito Offline", id: '1', merchantPayMethod: '0' }, */
        { value: "link", label: "Pagamento externo", id: '111', merchantPayMethod: '0' },
        /*         { value: "pix", label: "Pix", id: '96', merchantPayMethod: '2' },
                { value: "cash", label: "Dinheiro", id: '2', merchantPayMethod: '0'  }, */
    ]

    const agent: any = [
        /* { value: "credit", label: "Cartão de Crédito", id: '1' , merchantPayMethod: '1'},
        { value: "pix", label: "Pix", id: '96', merchantPayMethod: '2' }, */
    ]

    const agentWithBills: any = [
        /*         { value: "credit", label: "Cartão de Crédito", id: '1' , merchantPayMethod: '1'},
                { value: "pix", label: "Pix", id: '96', merchantPayMethod: '2' }, */
        { value: "billed", label: "Venda Faturada", id: '85', merchantPayMethod: '0' },
    ]

    enum paymentTypeSelected {
        /*         "credit" = <PaymentCheckoutCredit control={control} errors={errors} setValue={setValue} isForeign={isForeign} getValues={getValues} /> as any,
                "cOffiline" = <PaymentCheckoutCreditOffline control={control} errors={errors} setValue={setValue} /> as any, */
        "billed" = <PaymentCheckoutBilled control={control} errors={errors} /> as any,
        "link" = <PaymentCheckoutStripeQrCode control={control} errors={errors} /* t={t} */ /> as any,
        /*         "debit" = <PaymentCheckoutDebit control={control} errors={errors} setValue={setValue} /> as any,
                "pix" = <PaymentCheckoutPix control={control} errors={errors} /> as any,
                "direct" = <PaymentCheckoutDirect control={control} errors={errors} setValue={setValue} /> as any,
                "cash" = <PaymentCheckoutCash control={control} errors={errors} /> as any,
                "bankTransfer" = <PaymentCheckoutBankTransfer control={control} errors={errors} /> as any,
                "paypal" = <PaymentCheckoutPaypal control={control} errors={errors} /> as any */
    }

    const paymentOption = bpaSigAuth.user.perfil.isAgent === 0 && (level === '19' || level === "18") ? [...admnistrador] : bpaSigAuth.user.perfil.canSeeBills === true || (level !== '19' && level !== "18") ? [...agentWithBills] : [...agent];


    /* const paymentOption = [
        { value: "credit", label: "Cartão de Crédito", id: '1' , merchantPayMethod: '1'},
        { value: "cOffiline", label: "Cartão de Crédito Offline", id: '1', merchantPayMethod: '0' },
        { value: "billed", label: "Venda Faturada", id: '85', merchantPayMethod: '0' },
        { value: "pix", label: "Pix", id: '96', merchantPayMethod: '2' },
        { value: "cash", label: "Dinheiro", id: '2', merchantPayMethod: '0'  },
        // { value: "debit", label: "Débito" },
        // { value: "direct", label: "Direto ao fornecedor", id: '92' },
        // { value: "bankTransfer", label: "Transferência bancária" },
        // { value: "paypal", label: "Paypal" },
    ] */

    return (
        <>
            <h2 className='payment-title'>Pagamento</h2>

            {/* <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false"> */}
            <Card className="card-unit bg-white mt-4">
                <Card.Body>
                    <div className="card-title">
                        <h4>Efetuar pagamento</h4>
                    </div>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="formCheckoutPaymentOptions">
                            <Form.Label>Opções</Form.Label>
                            <Controller
                                control={control}
                                name="paymentOptions"
                                /* rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }} */
                                render={({ field }: any) => (
                                    <div className="endpoint">
                                        <Select
                                            placeholder="Selecione"
                                            onChange={handleChangePaymentOption}
                                            // value={paymentType}
                                            defaultValue={paymentOption[0]}
                                            options={paymentOption}
                                        />
                                    </div>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="paymentOptions"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        {/* {paymentType === "credit"
                                ?
                                <Form.Group as={Col} md="4" controlId="formCheckoutBilled">
                                    <Form.Label>Faturado</Form.Label>
                                    <Controller
                                        control={control}
                                        name="billed"
                                        rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.compName ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="billed"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                : ''} */}
                    </Row>


                    {paymentTypeSelected[paymentType]}

                    <hr />

                    {/* <Button className="btn">
                            Adicionar outro método
                        </Button> */}
                </Card.Body>
            </Card>

            {/* <div className="d-flex justify-content-end">
                <Button className="btn-primary" type='submit'>
                    EFETUAR PAGAMENTO
                </Button>
            </div> */}
            {/* </Form> */}
        </>
    );
}

export default PaymentCheckout;