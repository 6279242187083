import React, { Key, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
//import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';

import { useTranslation } from 'react-i18next';

//STYLES
import './SuppliersTableUsers.scss';

import ModalDefault from '../../../../../components/Modal/ModalDefault/ModalDefault';
import FormNewUsersModal from '../../../../../components/Forms/FormNewUsersModal/FormNewUsersModal';

export interface propModal {
  actionTableUser: any,
  info: any,
  isEdit: any,
};

const SuppliersTableUsers: React.FC<propModal> = ({
  actionTableUser, info, isEdit
}: propModal) => {
  const { t } = useTranslation();

  const [userObject, setUserObject] = useState<any>(info.usersSupplierDTO ? info.usersSupplierDTO : []);
  const [closeModal, setCloseModal] = useState<any>(false);

  function verificaId(arr: any, elem: any) {
    for (var i: any = 0; i < arr.length; i++) {
      if (arr[i].id === elem || arr[i].userId === elem) return true;
    }
    return false;
  }

  const handleAction = (objectUserModal: any) => {
    var aux: any = userObject;

    objectUserModal.forEach((element: any) => {
      if (!verificaId(userObject, element?.id)) {
        aux.push(element);
      }
    });
    setUserObject([...aux]);
  };

  function removeUser(id: any) {
    var aux: any = [];

    userObject.forEach((element: any) => {
      if (!(element.id === id)) {
        aux.push(element);
      }
    });

    setUserObject(aux);
  }

  useEffect(() => {
    actionTableUser(userObject);
  }, [userObject])

  if (userObject) {
    return (
      <>
        <div className="table-add-default suppliers-users-table">
          <div className="table-container">

            <Table striped bordered>
              <thead>
                <tr>
                  <th>{t("accordionUsers.tableUsers.profile")}</th>
                  <th>{t("accordionUsers.tableUsers.name")}</th>
                  <th>{t("accordionUsers.tableUsers.lastname")}</th>
                  <th>{t("accordionUsers.tableUsers.email")}</th>
                  <th>{t("accordionUsers.tableUsers.phone")}</th>
                  <th>{t("accordionUsers.tableUsers.cpf")}</th>
                  <th>{t("accordionUsers.tableUsers.level")}</th>
                  <th>{t("accordionUsers.tableUsers.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {userObject.length > 0
                  ? userObject.map((info: any, index: Key) => (
                    <tr key={index}>
                      <td>{info?.isMain}</td>
                      <td>{info?.nome ? info.nome : info.name}</td>
                      <td>{info?.lastName}</td>
                      <td>{info?.email}</td>
                      <td>{info?.telefone}</td>
                      <td>{info?.cpf}</td>
                      <td>{info.nivel}</td>
                      <td>{
                        <div className="center-table options-table">
                          <Dropdown drop="down" >
                            <Dropdown.Toggle variant="light ">
                              <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item disabled={isEdit} eventKey="1" className="text-danger removeRow" onClick={() => removeUser(info?.id)}><FontAwesomeIcon icon={['fal', 'trash-alt']} className="mr-2" />{t("accordionFinancial.tableFinancial.remove")}</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      }</td>
                    </tr>
                  ))
                  : ""
                  }
                {!isEdit
                  ?
                  <div className="add-contact">
                    <div className="text-center pt-3 pb-4">
                      <ModalDefault
                        title="Vincular usuário"
                        name={'+'}
                        class="btn-add"
                        closeModal={closeModal}
                        setCloseModal={setCloseModal}
                        classModal="modal-pos-tax"
                      >
                        <FormNewUsersModal actionModalUser={handleAction} setCloseModal={setCloseModal} />
                      </ModalDefault>
                    </div>
                  </div>
                  : ''
                }
                {/* <tr>
                <td colSpan={7} className="text-center btn-add-contact">
                <ModalDefault name={'+'} class="btn-add">
                <FormNewUsersModal actionModalUser={handleAction} />
                </ModalDefault>
                </td>
              </tr> */}
                {/* <p className="add-contact">
                <td colSpan={12} className="text-center btn-add-contact">
                  <ModalDefault name={'+'} class="btn-add">
                  <FormNewUsersModal actionModalUser={handleAction} />
                  </ModalDefault>
                </td>
              </p> */}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="users-table d-flex w-100 justify-content-center">
          <div className="table-container">

            <Table striped bordered>
              <thead>
                <tr>
                  <th>{t("accordionUsers.tableUsers.profile")}</th>
                  <th>{t("accordionUsers.tableUsers.name")}</th>
                  <th>{t("accordionUsers.tableUsers.lastname")}</th>
                  <th>{t("accordionUsers.tableUsers.email")}</th>
                  <th>{t("accordionUsers.tableUsers.phone")}</th>
                  <th>{t("accordionUsers.tableUsers.cpf")}</th>
                  <th>{t("accordionUsers.tableUsers.level")}</th>
                  <th>{t("accordionUsers.tableUsers.actions")}</th>
                </tr>
              </thead>
              <tbody>

                {/* <tr>
                <td colSpan={7} className="text-center btn-add-contact">
                <ModalDefault name={'+'} class="btn-add">
                <FormNewUsersModal actionModalUser={handleAction} />
                </ModalDefault>
                </td>
              </tr> */}
                <p className="add-contact">
                  <td colSpan={12} className="text-center btn-add-contact">
                    <ModalDefault name={'+'} class="btn-add" closeModal={closeModal} setCloseModal={setCloseModal}>
                      <FormNewUsersModal actionModalUser={handleAction} setCloseModal={setCloseModal} />
                    </ModalDefault>
                  </td>
                </p>
              </tbody>
            </Table>


          </div>
        </div>
      </>
    )
  }
}

export default SuppliersTableUsers;