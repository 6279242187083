import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";

import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

//STYLES
import "./TableSessionClosure.scss";
import Pagination from "../../../../components/Pagination/Pagination";

export interface propTable {
  sessions: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportReport: any;
  printReport: any;
  loading: any;
}

const TableSessionClosure: React.FC<propTable> = ({
  sessions, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportReport, printReport, loading
}: propTable) => {
  const { t } = useTranslation();
  //console.log(props.suppliers[0].dataCadastro.split('T')[0])

  const handleExportClick = (inc: any) => {
    exportReport();
  }

  const handlePrintClick = (inc: any) => {
    printReport();
  }

  const handleRecebimentosClick = (session: any) => {
    window.location.href = window.location.origin + '/session-closure/bills-to-receive?session=' + session;
  }

  const handlePagamentosClick = (session: any) => {
    window.location.href = window.location.origin + '/session-closure/bills-to-pay?session=' + session;
  }

  const handleFaturamentoClick = (session: any) => {
    window.location.href = window.location.origin + '/session-closure/billing?session=' + session;
  }

  function addActionButton(cell: any, row: any) {
    if (row.tipo == 'Tour') {
      var session = `S${cell}`;
    } else {
      var session = `I${cell}`;
    }

    return (
      <>
        <div className="d-flex justify-content-center">
          {/* <button className="btn p-0 pb-1" onClick={(e: any) => handleRecebimentosClick(cell)}>
            <FontAwesomeIcon
              icon={["fal", "edit"]}
              size="1x"
              style={{ marginRight: "1px" }}
            />
          </button> */}
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" onClick={(e: any) => handleRecebimentosClick(session)}><FontAwesomeIcon icon={['fal', 'receipt']} className="mr-2" /> Recebimentos</Dropdown.Item>
              <Dropdown.Item eventKey="2" onClick={(e: any) => handlePagamentosClick(session)}><FontAwesomeIcon icon={['fal', 'hand-holding-usd']} className="mr-2" /> Pagamentos</Dropdown.Item>
              <Dropdown.Item eventKey="3" onClick={(e: any) => handleFaturamentoClick(session)}><FontAwesomeIcon icon={['fal', 'file-invoice']} className="mr-2" /> Faturamento</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }


  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any, row: any) {
    var date = convertUTCDateToLocalDate(new Date(cell));
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  function addStatusRecebimentos(cell: any, row: any) {
    return (
      <div
        className={"w-100 " +
          (cell === 'Conciliado'
            ? "bg-blue"
            : cell === 'Faturado' || cell === 'Recebido'
              ? "bg-ativo"
              : cell === 'Pendente'
                ? "bg-inativo"
                : "")
        }
      >
        {cell}
      </div>
    );
  }

  function addStatusPagamentos(cell: any, row: any) {
    return (
      <div
        className={"w-100 " +
          (cell === 'Conciliado'
            ? "bg-blue"
            : cell === 'Agendado' || cell === 'Autorizado' || cell === 'Pago'
              ? "bg-ativo"
              : cell === 'Pendente' || cell === 'Cancelado'
                ? "bg-inativo"
                : "")
        }
      >
        {cell}
      </div>
    );
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={"w-100 " +
          (cell === 'Conciliado'
            ? "bg-blue"
            : cell === 'Pendente'
              ? "bg-inativo"
              : "")
        }
      >
        {cell}
      </div>
    );
  }



  const columns = [
    {
      dataField: "dataOperacao",
      text: "Data Operação",
      
      formatter: convertDate,
    },
    { dataField: "tipo", text: "Tipo",  },
    { dataField: "sessionReloc", text: "Session / Reloc",  },
    { dataField: "descricao", text: "Tour / Descrição",  },
    {
      dataField: "totalRecebimentos",
      text: "Recebimentos R$",
      
      formatter: convertValue,
    },
    {
      dataField: "totalPagamentos",
      text: "Pagamentos R$",
      
      formatter: convertValue,
    },
    {
      dataField: "totalMargem",
      text: "Margem R$",
      
      formatter: convertValue,
    },
    {
      dataField: "taxaMargem",
      text: "Margem %",
      
      formatter: convertValue,
    },
    {
      dataField: "statusRecebimentos",
      text: "Recebimentos",
      
      formatter: addStatusRecebimentos,
    },
    {
      dataField: "statusPagamentos",
      text: "Pagamentos",
      
      formatter: addStatusPagamentos,
    },
    {
      dataField: "status",
      text: "Status da Session",
      
      formatter: addStatus,
    },
    { dataField: "sessionReloc", text: " ", formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando sessions", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "sessions", text: "Sessions" }];

  const notFoundProducts = [{ sessions: "Nenhuma session encontrada" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.status === "Conciliado") {
      return 'border-analise';
    } else {
      return 'border-inativo';
    }
  };

  if (sessions !== null) {
    return (
      <>
        <div className="table-default session-closure-table">
          <div className="table-container">
            <div>
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills" onClick={handleExportClick}>
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills" onClick={handlePrintClick}>
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={sessions}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
      </>
    );
  } else if (sessions === null && loading === true) {
    return (
      <>
        <div className="table-default session-closure-table loading not-found">
          <div className="table-container">
          <div>
            <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
              <div>
                <button className="btn btn-bills" >
                  <FontAwesomeIcon
                    icon={["fal", "share"]}
                    size="1x"
                    style={{ marginRight: "5px" }}
                  />
                  Exportar
                </button>
              </div>
              <div>
                <button className="btn btn-bills" >
                  <FontAwesomeIcon
                    file-invoice
                    icon={["fal", "print"]}
                    size="1x"
                    style={{ marginRight: "5px" }}
                  />
                  Imprimir
                </button>
              </div>
            </div>
          </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default session-closure-table not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableSessionClosure;
