import React, { Key, useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import { useForm } from "react-hook-form";
import api from '../../../../services/api';

import './FilterPictureOfTheDay.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import GlobalAlert from "../../../../components/Alert/GlobalAlert/GlobalAlert";

function FilterPictureOfTheDay(props: any) {

    const { t } = useTranslation();

    const [filterDate, setFilterDate] = useState<any>(props.dataPadrao);
    const [sessions, setSessions] = useState<any>([]);

    const [alert, setAlert] = useState<any>();
    const [checked, setChecked] = useState<boolean>(true);

    const {
        control,
        //handleSubmit,
        //register,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = (event: any) => {
        props.buscar(filterDate);
        //console.log(filterName, filterState, filterType, filterStatus, filterDate)
    };

    function handleChecked() {
        if (checked === true) {
            setChecked(false);
        } else {
            setChecked(true);
        }
    }


    const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterDate(newValue);
        props.setFilterDate(newValue);
    }

    const handleDateClick = (inc: any) => {
        const newDate = new Date(filterDate);
        newDate.setDate(newDate.getDate()+inc);
        const newDateFmt = newDate.toISOString().substring(0,10);
        setFilterDate(newDateFmt);
        props.setFilterDate(newDateFmt);
        
        props.buscar(newDateFmt);
    }

    
    const updateDropdowns = (date: any, type: any) => {
        // const token = localStorage.getItem('GroupId') || '{}';
        // const config = {
        // headers: { 'Authorization': `Bearer ${token}` },
        // };
        // async function listSessions() {
        //     try {
        //         const params = {
        //             date: date,
        //             type: type !== '' ? type : null
        //         };

        //         const { data } = await api.post(`/SessionClosure/GetSessionsByDateAndTypeAsync`, params, config);
        //         if (data.status !== 400) {
        //         const dados = data.data;
        //             setSessions(dados)
        //         /* console.log(data); */
        //         }
        //     } catch (error: any) {
        //         if (error.response.status === 401) {
        //             window.location.href = window.location.origin + '/';
        //             //console.log(error);
        //         }
        //     }
        // }
        // listSessions();
    }

    useEffect(() => {
        if (props.sessions.length === 0 || props.error === true) {
            setAlert(
                <GlobalAlert alertType={"danger"}>
                    Não foi possível encontrar nenhuma session.
                </GlobalAlert>
            )
            setTimeout(() => setAlert(null), 2000);
        } else {
            setAlert(null);
        }

        //updateDropdowns(filterDate,filterType);
    }, [props.sessions, props.error])

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Body className="py-5">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            {alert}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('sessionClosure.sessionclosure.dataTour')}</Form.Label>
                                                <div className="d-flex">
                                                    <Button variant="link" className="btn p-1" onClick={(e: any) => handleDateClick(-1)}>
                                                        <FontAwesomeIcon
                                                            icon={["fal", "arrow-left"]}
                                                            size="1x"
                                                        />
                                                    </Button>
                                                    <Form.Control
                                                        type="date"
                                                        value={filterDate}
                                                        onChange={handleChangeDate}
                                                        autoComplete="off"
                                                    />
                                                    <Button variant="link" className="btn p-1" onClick={(e: any) => handleDateClick(1)}>
                                                        <FontAwesomeIcon
                                                            icon={["fal", "arrow-right"]}
                                                            size="1x"
                                                        />
                                                    </Button>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2" className="d-flex flex-column justify-content-end">
                                                <div className="d-flex justify-content-md-start">
                                                    {/* <Button variant="outline-primary" className="px-3 mr-2 text-uppercase" onClick={handleClearClick}>Cancelar</Button> */}
                                                    <Button className="px-3 text-uppercase" onClick={handleFilterClick}>
                                                        <FontAwesomeIcon
                                                            icon={["fal", "search"]}
                                                            size="1x"
                                                            className="mr-2"
                                                        />
                                                        {t('sessionClosure.sessionclosure.find')}
                                                    </Button>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default FilterPictureOfTheDay;