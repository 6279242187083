
import React, { useState } from "react";
import { Container, Row, Col, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import { Controller, useForm } from "react-hook-form";

import '../../Reservations/components/ReservationsFilter/ReservationsFilter.scss';

//Components
import RangeCalendar from "../../../components/Calendar/RangeCalendar";
import GetCaixaBilheteria from "../../../components/C2Points/GetCaixaBilheteria";
import GetOperadoresCaixa from "../../../components/C2Points/GetOperadoresCaixa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface prop {
    donwloadReport: any,
};

const DownloadReport: React.FC<prop> = ({
    donwloadReport
}: prop) => {

    const [filterDate, setFilterDate] = useState<any>('');
    const [filterChannel, setFilterChannel] = useState<any>('');
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');

    const {
        control,
        handleSubmit,
    } = useForm({});


    const handleFilterClick = (data: any) => {

        if (filterDate.length === 0) {
            setModalShow(true);
            setModalMessage("Favor preencher uma data!");
            return;
        }

        /* if (filterChannel === '') {
            setModalShow(true);
            setModalMessage("Favor selecionar um canal!");
            return;
        } */

        /* if (filterChannel === 'BILHETERIA' && data.codigoCaixa === undefined) {
            setModalShow(true);
            setModalMessage("Favor selecionar um caixa!");
            return;
        }

        if (filterChannel === 'BILHETERIA' && data.uidOperator === undefined) {
            setModalShow(true);
            setModalMessage("Favor selecionar um operador de caixa!");
            return;
        } */

        let dateIni: string = filterDate[0].split('/').reverse().join('-');
        let dateFim: string = filterDate.length === 2 ? filterDate[1].split('/').reverse().join('-') : dateIni;
        let source: string = filterChannel.toLowerCase();
        let caixa: string = filterChannel === 'BILHETERIA' ? data.codigoCaixa : '';
        let operador: string = filterChannel === 'BILHETERIA' ? data.uidOperator : '';

        let path: string = `?dateIni=${dateIni}&dateFim=${dateFim}&source=${source}${source === 'bilheteria' ? `&operadorUid=${operador || ''}&caixa=${caixa || ''}` : ''}`;

        donwloadReport(path);
    };

    const handleChangeChannel = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterChannel(newValue);
    }

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{/* <FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /> */}<span className="h5 mb-0">Download</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form onSubmit={handleSubmit(handleFilterClick)}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data:{/* {t("reservations.filter.date")} */}</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Canal</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="channel"
                                                    render={({ field }: any) => (
                                                        <div className='endpoint'>
                                                            <Form.Select
                                                                {...field}
                                                                label="Canal"
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => handleChangeChannel(e)}
                                                            >
                                                                <option value='' selected>Todos</option>
                                                                <option value='SITE'>SITE</option>
                                                                <option value='SIG'>SIG</option>
                                                                <option value='TOTEM'>TOTEM</option>
                                                                <option value='INTRANET'>INTRANET</option>
                                                                <option value='BILHETERIA'>BILHETERIA</option>
                                                                <option value='SELLING_PLATFORM'>SELLING PLATFORM</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>

                                            {filterChannel === 'BILHETERIA'
                                                ?
                                                <>
                                                    <Form.Group as={Col} md="2">
                                                        <Form.Label>Caixa</Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="codigoCaixa"
                                                            render={({ field }: any) => (
                                                                <div className='endpoint custom-select'>
                                                                    <GetCaixaBilheteria propsField={field} />
                                                                </div>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="2">
                                                        <Form.Label>Operador</Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="uidOperator"
                                                            render={({ field }: any) => (
                                                                <div className='endpoint custom-select'>
                                                                    <GetOperadoresCaixa propsField={field} />
                                                                </div>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </>
                                                :
                                                <>

                                                </>
                                            }
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                {/* <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('reservations.btnClear')}</Button> */}
                                                <Button type='submit' className="btn-default text-uppercase" /* onClick={handleFilterClick} */>Baixar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Modal
                size="lg"
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                className="modal-confirm loading-modal"
            >
                <Modal.Body className='modal-body text-center sucess-pos'>
                            <>
                                <div>

                                    <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="5x"
                                        style={{ fontSize: "7.5em" }}
                                        className='text-primary'
                                    />
                                </div>
                                <div>
                                    {modalMessage}
                                </div>
                                <div className="d-flex justify-content-center">

                                    <button
                                        onClick={() => setModalShow(false)}
                                        className="btn btn-primary mx-2 w-25"
                                    >
                                        Confirmar
                                    </button>

                                </div>
                            </>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DownloadReport;