
import React, { useState } from "react";
import '../Reservations/components/ReservationsFilter/ReservationsFilter.scss';

//Components
import DesktopDefault from "../../templates/DesktopDefault";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import api from "../../services/api";
import DownloadReport from "./components/DownloadReport";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DownloadDateTourReport() {
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<string>('Loading');

    function downloadBase64(exp: any) { // file: bytes/string, name: string
        const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
        const objectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute(
            'download',
            'relatorio_data_passeio.xlsx',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);
    }

    const handleDonwloadClick = (path: string) => {
        setModalLog(null);
        setModalShow(true);

        const token = localStorage.getItem('GroupId') || '{}';

        async function donwloadReport() {
            try {
                const res = await api.get(`/Report/GenerateSalesByTourDate${path}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "*/*",
                            'Authorization': `Bearer ${token}`,
                            "ngrok-skip-browser-warning": "69420"
                            //"Access-Control-Allow-Origin": "*"

                        },
                        responseType: 'blob'
                    });
                if (res.status !== 400) {
                    /* Faz a verificação se o valor recebido é uma instância blob. Se for uma instância blob faço o download direto. Caso não seja uma instância, acesso a resposta da api como um JSON para pegar a mensagem de erro */
                    const isJsonBlob = (data: any) => data instanceof Blob && data.type === "application/json";
                    const responseData = isJsonBlob(res?.data) ? await (res?.data)?.text() : res?.data || {};
                    /*  */

                    if (typeof responseData === "string") {
                        setModalLog(JSON.parse(responseData).log);
                        setModalMessage(JSON.parse(responseData).texto);
                    } else {
                        downloadBase64(res);
                        setModalLog(0);
                        setModalMessage("Download efetuado com sucesso");
                    }
                } else {
                    setModalLog(1);
                    setModalMessage("Erro ao processar operação");
                }
            } catch (error: any) {
                setModalLog(1);
                setModalMessage("Erro ao processar operação");
            }
        }
        donwloadReport();
    };

    return (
        <DesktopDefault>
            <div className="container-fluid content-dashboard">

                <Breadcrumb title={"Relatórios / Download do Relatório por data do passeio"} />

                <div className="mt-4 d-flex justify-content-between align-items-center">
                    <h2 className="title" style={{ color: "#707070" }}>Relatório por data do passeio</h2>
                </div>

                <DownloadReport donwloadReport={handleDonwloadClick} />

                <Modal
                    size="lg"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    className="modal-confirm loading-modal"
                >
                    <Modal.Body className='modal-body text-center sucess-pos'>
                        {
                            modalLog !== null
                                ?
                                <>
                                    <div>
                                        {
                                            modalLog === 1 || modalLog === "1"
                                                ?
                                                <FontAwesomeIcon
                                                    icon={["fal", "times-circle"]}
                                                    size="5x"
                                                    style={{ fontSize: "7.5em" }}
                                                    className='text-primary'
                                                />
                                                :
                                                modalLog === 0 || modalLog === "0"
                                                    ?
                                                    <FontAwesomeIcon
                                                        icon={["fal", "check-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em" }}
                                                        className='text-primary'
                                                    />
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={["fal", "question-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em" }}
                                                        className='text-primary'
                                                    />
                                        }
                                    </div>
                                    <div>
                                        {modalMessage}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button
                                            onClick={() => setModalShow(false)}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                </>
                                :
                                <div className="modal-body">
                                    <div className="text-center">
                                        <div className="load"></div>
                                    </div>
                                    <div>
                                        <span>Carregando</span>
                                    </div>
                                    <div></div>
                                </div>
                        }
                    </Modal.Body>
                </Modal>
            </div>
        </DesktopDefault>
    )
}

export default DownloadDateTourReport;