import React, { Key, useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  date: any,
  operationType: any,
  setSessionReloc: any,
  defaultValue: any
};

const GetSessionsByDateAndType: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, date, operationType, setSessionReloc, defaultValue
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [value, setValue] = useState<any>(defaultValue);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    }else{
      setIsMenuOpen(false);
    }    
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
    headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listSessions() {
      try {
        const params = {
          date: date,
          operationType: operationType
        };

        const { data } = await api.post(`/Manifest/GetSessionsByDate`, params, config);
        if (data.status !== 400) {
          const dados = data.data;
          
          setInfo(dados)
          /* console.log(data); */
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
          //console.log(error);
        }
      }
    }
    listSessions();
  }, [date,operationType]);

  const handleChangeSession = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSessionReloc(newValue);
    setValue(newValue);
  }

  if (info !== null) {
    return (
      <>        
        <Form.Select
          {...propsField}
          aria-invalid={propsErrors?.promotor ? "true" : ""}
          label={propsLabel}
          as="select"
          variant="standard"
          margin="normal"
          value={value}
          required
          onChange={(e: any) => handleChangeSession(e)}
        >
          <option value="">Selecione</option>
          {info.length > 0 ? info.map((info: any, index: Key) => (
              <option key={index} value={info.sessionId}>{info.sessionId} — {info.hora} — {info.produto} — {info.vagasUsadas} PAX</option>
          )) : ''}
        </Form.Select>
      </>
    );
  } else {
    return(
      <>        
        <Form.Select>
          <option value='' disabled></option>          
        </Form.Select>
      </>
    )
    
  }
}

export default GetSessionsByDateAndType;