import React from "react";
import { Container } from "react-bootstrap";

import HeaderNew from "../components/Header/HeaderNew";
import Menu from "../components/Menu/Menu";

function DesktopDefault(props: any) {
    return (
        <div className="d-flex">
                <Container className="w-100 m-0 p-0" fluid>
                    <HeaderNew />
                    <div>
                        <Menu />
                        <div className="contentSIG"> 
                            {props.children}
                        </div>
                        
                    </div>
                    
                </Container>

            </div>
    )
}

export default DesktopDefault;