import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';

import { Controller, useForm } from 'react-hook-form';

import './CorporateClientFilter.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CorporateClientFilter(props: any) {
  const { t } = useTranslation();

  const [filterClient, setFilterClient] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('');
  const [filterStatusRequest, setFilterStatusRequest] = useState<string>('0');

  const {
    control,
  } = useForm({});

  const handleFilterClick = () => {

    props.buscar(
      filterClient,
      filterType,
      filterStatusRequest,
    );
  };

  const handleClearClick = (event: any) => {
    let inputValue = document.querySelectorAll('input');
    for (let i = 0; i < inputValue.length; i++) {
      inputValue[i].value = '';
    }

    let selectValue = document.querySelectorAll('select');
    for (let i = 0; i < selectValue.length; i++) {
      selectValue[i].value = '0';
    }

    setFilterClient('');
    setFilterType('');
    setFilterStatusRequest('0');
    props.buscar('', '', '0');
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterClient(newValue);
  };

  const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterType(newValue);
  };

  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterStatusRequest(newValue);
  };

  return (
    <div
      className="suppliers-filter d-flex justify-content-center bg-white mt-4"
      style={{ textAlign: 'left' }}
    >
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['fal', 'sliders-h']}
                size="lg"
                className="mr-2"
              />
              <span className="h5 mb-0">Buscar Clientes Corporativos</span>
            </div>
          </Accordion.Header>
          <Accordion.Body className="pt-0">
            <Container className="p-0" fluid>
              <Row>
                <div>
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="3">
                        <Form.Label>Nome / Empresa</Form.Label>
                        <Controller
                          control={control}
                          name="name"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              onChange={handleChangeName}
                              value={filterClient}
                            />
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3">
                        <Form.Label>Tipo de Cliente</Form.Label>
                        <Controller
                          control={control}
                          name="type"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                label="Tipo de Cliente"
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) => handleChangeType(e)}
                              >
                                <option value="0" selected>Todos</option>
                                <option value="1">Pessoa Física</option>
                                <option value="2">Pessoa Jurídica</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3">
                        <Form.Label>Status</Form.Label>
                        <Controller
                          control={control}
                          name="status"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                label="Status"
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) => handleChangeStatus(e)}
                              >
                                <option value="0" selected>Todos</option>
                                <option value="1">Ativo</option>
                                <option value="2">Inativo</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Row>
                    <Row
                      as={Col}
                      md="12"
                      className="mt-5 justify-content-md-end"
                    >
                      <Col xs lg="12" className="d-flex justify-content-md-end">
                        <Button
                          variant="outline-primary"
                          className="btn-default mr-2 text-uppercase"
                          onClick={handleClearClick}
                        >
                          {t('reservations.btnClear')}
                        </Button>
                        <Button
                          className="btn-default text-uppercase"
                          onClick={handleFilterClick}
                        >
                          {t('reservations.btnSearch')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default CorporateClientFilter;
