import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import GetSuppliersTypeFilter from "../../../../../components/C2Points/GetSuppliersTypeFilter";

import { Controller, useForm } from "react-hook-form";

import './SuppliersFilter.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../../components/Calendar/RangeCalendar";
import GetStatesFilter from "../../../../../components/C2Points/GetStatesFilter";
import GlobalAlert from "../../../../../components/Alert/GlobalAlert/GlobalAlert";

function SuppliersFilter(props: any) {

    const { t } = useTranslation();

    const [filterName, setFilterName] = useState<any>('');
    const [filterState, setFilterState] = useState<any>('');
    const [filterDate, setFilterDate] = useState<any>('');
    const [filterType, setFilterType] = useState<any>(0);
    const [filterStatus, setFilterStatus] = useState<any>(0);

    const {
        control,
        //handleSubmit,
        //register,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = () => {
        props.buscar(filterName, filterState, filterType, filterStatus, filterDate);
        //console.log(filterName, filterState, filterType, filterStatus, filterDate)
    };

    const handleClearClick = (event: any) => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterName("");
        setFilterState("");
        setFilterDate("");
        setFilterType(0)
        setFilterStatus(0);
        props.buscar("", "", 0, 0, []);
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterName(newValue);
    }

    // const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newValue = e.target.value;
    //     setFilterState(newValue);
    // }

    // const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newValue = e.target.value;
    //     setFilterDate(newValue);
    // }

    // const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newValue = e.target.value;
    //     setFilterType(newValue);
    // }

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatus(newValue);
    }

    useEffect(() => {
        if (props.suppliers?.length === 0 || props.error === true) {
            props.setAlert(
                <GlobalAlert alertType={"danger"}>
                    {t("listSuppliers.globalAlert")}
                </GlobalAlert>
            )
            setTimeout(() => props.setAlert(null), 2000);
        } else {
            props.setAlert(null);
        }
    }, [props.suppliers, props.error])

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">{t('listSuppliers.filterTitle')}</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>{t('listSuppliers.filterNameCod')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="name"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeName}
                                                            value={filterName}
                                                        />
                                                    )}
                                                />
                                                {/* <Form.Control
                                                    type="text"
                                                    onChange={handleChangeName}
                                                /> */}

                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('listSuppliers.filterState')}</Form.Label>
                                                {/* <Form.Control
                                                    type="text"
                                                    onChange={handleChangeState}
                                                /> */}
                                                <Controller
                                                    control={control}
                                                    name="state"
                                                    render={({ field }: any) => (
                                                        <GetStatesFilter propsField={field} propsLabel={t('accordionInfoCompany.state')} propsErrors={errors} setFilterState={setFilterState} />
                                                    )}
                                                />


                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('listSuppliers.filterDate')}</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('listSuppliers.filterClientType')}</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Controller
                                                        control={control}
                                                        name="filterType"
                                                        render={({ field }: any) => (
                                                            <GetSuppliersTypeFilter propsField={field} propsErrors={errors} propsLabel={'Filter Type'} setFilterType={setFilterType} />
                                                        )}
                                                    />
                                                    {/* <Form.Control
                                                        type="text"
                                                        aria-describedby="inputGroupPrepend"
                                                        onChange={handleChangeType}
                                                    /> */}
                                                    {/* {<Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback>} */}
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('listSuppliers.filterStatus')}</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatus(e)}
                                                        >
                                                            <option value="0">{t('listSuppliers.statusAll')}</option>
                                                            <option value="1">{t('listSuppliers.statusAnalysis')}</option>
                                                            <option value="2">{t('listSuppliers.statusActive')}</option>
                                                            <option value="3">{t('listSuppliers.statusInactive')}</option>
                                                        </Form.Select>
                                                        {/* <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback> */}
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('listSuppliers.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('listSuppliers.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default SuppliersFilter;