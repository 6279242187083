import React, { Key, useState, useEffect } from 'react';
import { Row, Col, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//import Select from "react-select";
//import SingleCalendar from '../../../../components/Calendar/SingleCalendar';
import deleteIcon from '../../../assets/icons/icon-garbage.png';

//import DropdownNumberPeople from "../../../../components/DropdownNumberPeople/DropdownNumberPeople";
import GetPickups from '../../C2Points/GetPickups';

import { useAppDispatch } from "../../../store/hooks";
import { removeItemCart } from "../../../store/cart";
import SingleCalendarTime from '../../Calendar/SingleCalendarTime';
import SingleCalendarTimeEvent from '../../Calendar/SingleCalendarTimeEvent';
//import GetDDI from '../../C2Points/GetDDI';

export interface propCart {
    info: any,
    control: any,
    errors: any,
    setValue: any,
    data: any
    mainContactObj?: any;
};

const CartCheckoutTourEvent: React.FC<propCart> = ({
    info, control, errors, setValue, data, mainContactObj
}: propCart) => {
    const [numAdultos, setNumAdultos] = useState<any>([]);
    const [pickupSelect, setPickupSelect] = useState<any>(null);
    const [allowOthers, setAllowOthers] = useState<boolean>(false);

    const [beginDate, setBeginDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");

    const dispatch = useAppDispatch();

    useEffect(() => {
        var aux: any = [];
        for (var i: any = 0; i < info.adults; i++) {
            aux.push(i)
        }
        setNumAdultos(aux)
    }, [info])

    const addPickup = (objPickup: any) => {
        setPickupSelect(objPickup.id);
        setValue(`dados[${data}].pickup`, objPickup.id);
    };

    useEffect(() => {
        setValue(`dados[${data}].passengers.0.firstName`, "Venda Evento");
        setValue(`dados[${data}].passengers.0.lastName`, "Evento");
        setValue(`dados[${data}].passengers.0.email`, "Eventos@bondinho.local");
        setValue(`dados[${data}].passengers.0.phone`, "00000000000");
        setValue(`eventDateBegin`, beginDate[0]);
        setValue(`eventDateEnd`, endDate[0]);
    }, [data, setValue,/*  */ mainContactObj, beginDate, endDate])

    // useEffect(() => {

    //   }, [pickupSelect])

    /* const cartSIG: any = localStorage.getItem("cartSIG"); */
    /* const [cartItemJSON, setCartItemJSON] = useState<any>((cartSIG)); */

    const handleClickRemove = (productE: any) => {
        dispatch(removeItemCart(productE))
        /* cartSIG.dados.forEach((cItem: any, index: any) => {
            if (productE.productCode === cItem.productCode) {
                cartItemJSON.dados.splice(index, 1);
                setCartItemJSON(cartItemJSON);
                localStorage.setItem("cartSIG", JSON.stringify(cartItemJSON));
                window.location.reload();
            }
        }); */
    };

    /* const setNewNumberPeople = (peoples: any) => {
        setNumberPeople(peoples)
        setValue('numberOfPeople', peoples)
    } */


    return (
        <>
            <Card className="cart-card-unit card-unit bg-white mt-4">
                <Card.Body>
                    <div className="card-title">
                        <div className="logo-button">
                            <FontAwesomeIcon
                                className='icon-primary'
                                icon={["fad", "map-marked-alt"]}
                                size="1x"
                                style={{ marginBottom: "5px", width: "25px", fontSize: "1.5rem" }}
                            />
                            <button onClick={() => { handleClickRemove(info) }}><img src={deleteIcon} alt="delete" /></button>
                        </div>
                        <div className="mb-2" style={{ borderBottom: "1px solid rgb(112 112 112 / 19%)", paddingBottom: ".875rem" }}>
                            <h4 className='mb-0'>{info.productName}</h4>
                            <p>{info.modalityName}</p>
                        </div>
                        <p>Fornecedor: <span>{info.supplierFantasyName}</span></p>
                    </div>

                    {/* <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false"> */}
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="formCheckoutDate">
                            <Form.Label>Data:</Form.Label>
                            <p>{info.date.split('-').reverse().join('/')}</p>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutHour">
                            <Form.Label>Hora:</Form.Label>
                            <p>{info.time}</p>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutNumberOfPeople">
                            <Form.Label>Pessoas:</Form.Label>
                            {info.sellingType === 2 ?
                                <p> <small>Adultos: {info.adults}</small></p>
                                :
                                <p> <small>Total: {info.globalPeople}</small></p>
                            }
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="4" controlId="formCheckoutHour">
                            <Form.Label>Vencimento:</Form.Label>
                            <Controller
                                control={control}
                                name="daysValidity"
                                rules={{ required: { value: true, message: 'Por favor, informe o vencimento.' } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.daysValidity ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`daysValidity`}
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutHour" className='d-flex align-items-end'>
                            <Form.Label></Form.Label>
                            <div className='d-flex align-items-center' style={{ height: "38px" }}>
                                <Controller
                                    control={control}
                                    name="isStock"
                                    defaultValue={false}
                                    //rules={{ required: { value: true, message: '' } }}
                                    render={({ field }: any) => (
                                        <label htmlFor={"isStock"} className="d-flex">
                                            <Form.Check
                                                {...field}
                                                type="checkbox"
                                                id="isStock"
                                                value=' '
                                            />
                                            <small className="mx-2">Consome do Estoque?</small>
                                        </label>
                                    )}
                                />
                            </div>
                        </Form.Group>
                    </Row>

                    <hr />

                    {info.typePickup === '0'
                        ?
                        <>
                            <Row className="mb-3">
                                <div><h3 className='mt-0'>Ponto de encontro</h3></div>
                                <div /* className='col-md-8' style={{boxShadow: "0px 4px 2.5px 0px #70707025", border: "1px solid #ced4da", padding: "0.375rem 0.75rem", borderRadius: "0.25rem", width: "100%"}} */><span>{info.meetingPoint}</span></div>
                            </Row>
                            <hr />
                        </>
                        :
                        <>

                            <Row className="mb-3">
                                <Form.Group as={Col} md="8" controlId="formCheckoutPickup">
                                    <Form.Label>Pickup:</Form.Label>
                                    <GetPickups
                                        propsId={info.pickupListId}
                                        actionPickup={addPickup}
                                        alert={alert}
                                    />
                                    <Controller
                                        control={control}
                                        name="pickup"
                                        render={({ field }) => {
                                            // sending integer instead of string.
                                            return <input {...field} value={pickupSelect} type="hidden" autoComplete='off' />;
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="pickup"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
                        </>
                    }

                    <hr />

                    <div className="participants">
                        <h3>Informações do Evento</h3>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="formCheckoutPersonName">
                                <Form.Label>Nome:</Form.Label>
                                <Controller
                                    control={control}
                                    name={`eventName`}
                                    rules={{ required: { value: false, message: 'Por favor, informe esse campo.' } }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.eventName ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            required
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`eventName`}
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="formCheckoutPersonEmail">
                                <Form.Label>Data Inicial:</Form.Label>
                                <Controller
                                    control={control}
                                    name={`eventDateBegin`}
                                    rules={{ required: { value: false, message: 'Por favor, informe esse campo.' } }}
                                    render={({ field }: any) => (
                                        <SingleCalendarTimeEvent setTime={setBeginDate} />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`eventDateBegin`}
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="formCheckoutPersonPhone">
                                <Form.Label>Data Final:</Form.Label>
                                <Controller
                                    control={control}
                                    name={`eventDateEnd`}
                                    rules={{ required: { value: false, message: 'Por favor, informe esse campo.' } }}
                                    render={({ field }: any) => (
                                        <SingleCalendarTimeEvent setTime={setEndDate} />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`eventDateEnd`}
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="formCheckoutPersonLastname" className="form-textarea">
                                <Form.Label>Descrição:</Form.Label>
                                <Controller
                                    control={control}
                                    name={`eventDescription`}
                                    rules={{ required: { value: false, message: 'Por favor, informe esse campo.' } }}
                                    render={({ field }: any) => (
                                        <textarea
                                            {...field}
                                            aria-invalid={errors?.eventDescription ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            className='form-control'
                                            required
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`eventDescription`}
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                    </div>
                    <hr />
                </Card.Body>
            </Card>
        </>
    );
}

export default CartCheckoutTourEvent;