import React from 'react';

export interface propEdit {
    setChangeEdit: any,
    info: any,
};

const EditAccounting: React.FC<propEdit> = ({
    setChangeEdit, info
}: propEdit) => {

    console.log(info)

    return (
        <>
            <div className="border" id="editAccounting">
                <div style={{ backgroundColor: "#fff", paddingBottom: "60px" }}>
                    <div className="edit-title container-fluid">
                        <h3 className="">Contábil</h3>
                        <button onClick={() => { setChangeEdit(false) }}>EDITAR</button>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>CONTA CONTÁBIL</p>
                            <p>{info.accountingAccounts ? info.accountingAccounts : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>POSSUI CONTRATO?</p>
                            <p>{
                                parseInt(info.haveContract) === 1
                                    ? "Sim"
                                    : parseInt(info.haveContract) === 2
                                        ? "Não"
                                        : "não definido"
                            }
                            </p>
                        </div>
                        <div className="data-unit col-20">
                            <p>VALIDADE:</p>
                            <p>{info.validade ? info.validade : "não definido"}</p>
                        </div>
                        <div className="data-unit col-10">
                            <p>CONTRATO:</p>
                            <p>{info.contractName ? info.contractName : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>CONTA CORRENTE</p>
                            <p>{info.contaCorrente ? info.contaCorrente : "não definido"}</p>
                        </div>
                        <div className="data-unit ">
                            <p>CENTRO DE CUSTO</p>
                            <p>{info.costCenterSupplierDTO[0]?.coastCenterId ? info.costCenterSupplierDTO[0].coastCenterId : "não definido"}</p>
                        </div>
                        <div className="data-unit col-20 data-unit-white-bg"></div>
                        <div className="data-unit col-10 data-unit-white-bg"></div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}

export default EditAccounting;