import React from 'react';
import { Row } from "react-bootstrap";

import ImageDefault from "../../../../../assets/img/image-default.png";

import PartnersTableInfoClient from '../../AccordionPersonal/PartnersTableInfoClient/PartnersTableInfoClient';
//import logo from '../../logo.svg';

export interface propInfo {
    info: any;
    setChangeEdit: any;
    setShowLog: any;
};

const EditPersonalData: React.FC<propInfo> = ({
    info, setChangeEdit, setShowLog
}: propInfo) => {
    //const [messageError, setMessageError] = useState<boolean>(false);

    return (
        <>
            <div id="personalData" className="d-flex">
                <div className="border bg-white">
                    <div className="edit-title container-fluid">
                        <h3 className="">Dados Pessoais</h3>
                        <div className='d-flex gap-4'>
                            <button className="btn btn-primary btn-primary-log" onClick={() => { setShowLog(true) }}>Ver Log</button>
                            <button onClick={() => { setChangeEdit(false) }}>EDITAR</button>
                        </div>
                    </div>
                    <div className="" style={{ width: "100%" }}>
                        <div className="logo-user mb-2 mt-2"
                            style={{ backgroundImage: `url(${info.compLogoDoc !== '' ? info.compLogoDoc?.split(",").join("base64,") : ImageDefault}})`, backgroundSize: 'cover', cursor: '', marginLeft: "24px" }}>
                        </div>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>TIPO DE PESSOA:</p>
                            <p>{info.compTypeDesc ? info.compTypeDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit data-unit-white-bg">

                        </div>
                        <div className="data-unit data-unit-white-bg">

                        </div>
                        <div className="data-unit">
                            <p>NOME:</p>
                            <p>{info.compName ? info.compName : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>NOME FANTASIA:</p>
                            <p>{info.compFantasia}</p>
                        </div>
                        {
                            info.website !== null && info.website !== ""
                                ?
                                <div className="data-unit">
                                    <p>WEBSITE:</p>
                                    <p>{info.website}</p>
                                </div>
                                :
                                <div className="data-unit data-unit-white-bg"></div>
                        }
                        <div className="data-unit">
                            <p>TIPO DE CLIENTE:</p>
                            <p>{info.clientTypDesc ? info.clientTypDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>PROMOTOR:</p>
                            <p>{info.promotor ? `${info.promotorDescName} ${info.promotorDescSurName}` : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>FILIAL:</p>
                            <p>{info.filialDesc ? info.filialDesc : "não definido"}</p>
                        </div>
                        {info.compType === 1
                            ?
                            <>
                                <div className="data-unit">
                                    <p>CPF:</p>
                                    <p>{info.cpf ? info.cpf : "não definido"}</p>
                                </div>
                            </>
                            :
                            info.compType === 2
                                ?
                                <>
                                    <div className="data-unit">
                                        <p>CNPJ:</p>
                                        <p>{info.cnpj ? info.cnpj : "não definido"}</p>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="data-unit">
                                        <p>Tax Number:</p>
                                        <p>{info.cnpj ? info.cnpj : "não definido"}</p>
                                    </div>
                                </>
                        }
                        {info.compType === 2
                            ?
                            <>
                                <div className="data-unit">
                                    <p>INSCRIÇÃO MUNICIPAL:</p>
                                    <p>{info.inscricaoMunicipal !== null ? info.inscricaoMunicipal/* .toString() */ : "não definido"}</p>
                                </div>
                                <div className="data-unit">
                                    <p>INSCRIÇÃO ESTADUAL:</p>
                                    <p>{info.inscricaoEstadual !== null ? info.inscricaoEstadual/* .toString() */ : "não definido"}</p>
                                </div>
                            </>
                            :
                            <>
                            </>
                        }
                    </div>


                    <hr style={{ margin: "1rem 24px" }} />

                    <div className="edit-title container-fluid">
                        <h3 className="">Endereço</h3>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>PAÍS:</p>
                            <p>{info.countryDesc ? info.countryDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>CEP:</p>
                            <p>{info.zipCode ? info.zipCode : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>UF:</p>
                            <p>{info.stateDesc ? info.stateDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>CIDADE:</p>
                            <p>{info.city ? info.city : "não definido"}</p>
                        </div>
                        <div className="data-unit" style={{ maxWidth: "10%" }}>
                            <p>TIPO DE BAIRRO:</p>
                            <p>{info.districtCodeDesc ? info.districtCodeDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit" style={{ maxWidth: "20%" }}>
                            <p>BAIRRO:</p>
                            <p>{info.compBairro ? info.compBairro : "não definido"}</p>
                        </div>
                        <div className="data-unit" style={{ maxWidth: "10%" }}>
                            <p>TIPO DE RUA:</p>
                            <p>{info.streetCodeDesc ? info.streetCodeDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit" style={{ maxWidth: "20%" }}>
                            <p>ENDEREÇO:</p>
                            <p>{info.address ? info.address : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>NÚMERO:</p>
                            <p>{info.numero ? info.numero : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>COMPLEMENTO:</p>
                            <p>{info.compComplem ? info.compComplem : "não definido"}</p>
                        </div>
                    </div>

                    <hr style={{ margin: "1rem 24px" }} />

                    <div className="edit-title container-fluid">
                        <h3 className="">Contatos</h3>
                    </div>

                    <div style={{ margin: "0 24px 10px" }}>
                        <Row>
                            <PartnersTableInfoClient
                                isEdit={true}
                                infoEdit={info.affiliateContact}
                                disableAddContact={true}
                            />
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditPersonalData;