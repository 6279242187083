import React, { useState } from 'react';
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import './RevokeCache.scss';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../services/api';

export default function RevokeCache() {

    const [openModal, setOpenModal] = useState<any>(false);
    const [info, setInfo] = useState<any>(null);
    const [firstPar, setFirstPar] = useState<string>("Esta ação é irreversível!");
    const [secondPar, setSecondPar] = useState<string>("Deseja mesmo revogar os dados de cache?");

    //https://backend.c2.tours/api/products/revokecache   api get de revogar cache 

    const handleRevokeCache = async () => {
        setInfo("aguardando");
        setFirstPar("Um momento");
        setSecondPar("Aguardando resposta!");

        const config = {
            headers: { "ngrok-skip-browser-warning": "69420" },
        };

        try {
            const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/products/revokecache`, config);
            if (data.status !== 400) {
                setInfo(data);
                setFirstPar(data.data);
                setSecondPar("Dados de cache eliminados com sucesso!");
            } else {
                setInfo("erro");
                setFirstPar(data.data);
                setSecondPar("Erro ao realizar limpeza de cache!");
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                setInfo("erro");
                setFirstPar("Erro");
                setSecondPar("Erro ao realizar limpeza de cache!");
            }
        }
    }


    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={"Revogar Cache"} />

                    <div className="my-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>
                            Revogar Cache
                        </h2>

                        <Button onClick={() => setOpenModal(true)}>Revogar Cache</Button>
                    </div>

                    <section className="bg-white section-cache">
                        <p>
                            A revogação de cache é um processo essencial para garantir a
                            segurança e a integridade dos dados armazenados em cache. O
                            cache é uma área de armazenamento temporário usada para acelerar
                            o acesso a dados frequentemente utilizados.
                        </p>

                        <p>
                            Ao revogar o cache, as informações armazenadas se tornam
                            obsoletas e são removidas, resultando em uma perda temporária da
                            vantagem de desempenho que o cache proporciona. Isso significa
                            que, quando ocorre a revogação do cache, os dados precisarão ser
                            buscados novamente na fonte de dados original.
                        </p>
                    </section>

                    <Modal
                        className="modal-custom modalAuth modal-cache"
                        show={openModal}
                        onHide={() => { setOpenModal(false); setInfo(null) }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header className="" closeButton>
                            <h3>Revogar Cache</h3>
                        </Modal.Header>

                        <Modal.Body>
                            {info === null ?
                                <>
                                    <FontAwesomeIcon
                                        size='4x' className='mb-4' color='#ff0202' icon={["fal", "exclamation-triangle"]}
                                    />
                                    <p style={{ textTransform: "uppercase" }}>{firstPar}</p>
                                    <p>{secondPar}</p>
                                </>
                                :
                                info === "aguardando" ?
                                    <>
                                        <div style={{ width: "64px", height: "64px" }} className="spinner-border text-primary mb-4" role="status">

                                        </div>
                                        <p>{firstPar}</p>
                                        <p>{secondPar}</p>
                                    </>
                                    :
                                    info === "erro"
                                        ?
                                        <>
                                            <FontAwesomeIcon
                                                size='4x' className='mb-4' color='#ff0202' icon={["fal", "times"]}
                                            />
                                            <p>{firstPar}</p>
                                            <p>{secondPar}</p>
                                        </>
                                        :
                                        <>
                                            <FontAwesomeIcon
                                                size="4x" className='mb-4' color='#6731ff' icon={["fal", "check-circle"]}
                                            />
                                            <p>{firstPar}</p>
                                            <p>{secondPar}</p>
                                        </>
                            }
                        </Modal.Body>
                        {info === null &&
                            <Modal.Footer>
                                <Button
                                    className='mr-3'
                                    variant="btn btn-outline-danger"
                                    onClick={() => setOpenModal(false)}
                                >
                                    Cancelar
                                </Button>
                                <Button onClick={() => handleRevokeCache()}>Aceitar</Button>
                            </Modal.Footer>
                        }
                    </Modal>
                </div>
            </DesktopDefault>
        </>
    );
};
