import pt from './default.json';
import en from './default.en.json';
import es from './default.es.json';

//Pages
// import homePt from '../../pages/Home/locales/default.json';
// import homeEn from '../../pages/Home/locales/default.en.json';
// import homeEs from '../../pages/Home/locales/default.es.json';

import addPartnersPt from '../../pages/Partners/AddPartners/locales/default.json';
import addPartnersEn from '../../pages/Partners/AddPartners/locales/default.en.json';
import addPartnersEs from '../../pages/Partners/AddPartners/locales/default.es.json';

import addSuppliersPt from '../../pages/Suppliers/AddSuppliers/locales/default.json';
import addSuppliersEn from '../../pages/Suppliers/AddSuppliers/locales/default.en.json';
import addSuppliersEs from '../../pages/Suppliers/AddSuppliers/locales/default.es.json';

import listSuppliersPt from '../../pages/Suppliers/ListSuppliers/locales/default.json';
import listSuppliersEn from '../../pages/Suppliers/ListSuppliers/locales/default.en.json';
import listSuppliersEs from '../../pages/Suppliers/ListSuppliers/locales/default.es.json';


//Components

/* import translateOptionPt from '../../../components/TranslateOption/locales/default.json';
import translateOptionEn from '../../../components/TranslateOption/locales/default.en.json';
import translateOptionEs from '../../../components/TranslateOption/locales/default.es.json'; */

import calendarPt from '../../components/Calendar/locales/default.json';
import calendarEn from '../../components/Calendar/locales/default.en.json';
import calendarEs from '../../components/Calendar/locales/default.es.json';

import cartHeaderPt from '../../components/Cart/locales/default.json';
import cartHeaderEn from '../../components/Cart/locales/default.en.json';
import cartHeaderEs from '../../components/Cart/locales/default.es.json';

import partnersPt from '../../pages/Partners/components/locales/default.json';
import partnersEn from '../../pages/Partners/components/locales/default.en.json';
import partnersEs from '../../pages/Partners/components/locales/default.es.json';

import accordionPersonalPt from '../../pages/Partners/components/AccordionPersonal/locales/default.json';
import accordionPersonalEn from '../../pages/Partners/components/AccordionPersonal/locales/default.en.json';
import accordionPersonalEs from '../../pages/Partners/components/AccordionPersonal/locales/default.es.json';

import legalPersonPt from '../../pages/Partners/components/AccordionPersonal/LegalPerson/locales/default.json';
import legalPersonEn from '../../pages/Partners/components/AccordionPersonal/LegalPerson/locales/default.en.json';
import legalPersonEs from '../../pages/Partners/components/AccordionPersonal/LegalPerson/locales/default.es.json';

import physicalPersonPt from '../../pages/Partners/components/AccordionPersonal/PhysicalPerson/locales/default.json';
import physicalPersonEn from '../../pages/Partners/components/AccordionPersonal/PhysicalPerson/locales/default.en.json';
import physicalPersonEs from '../../pages/Partners/components/AccordionPersonal/PhysicalPerson/locales/default.es.json';

import accordionAddressPt from '../../pages/Partners/components/AccordionAddress/locales/default.json';
import accordionAddressEn from '../../pages/Partners/components/AccordionAddress/locales/default.en.json';
import accordionAddressEs from '../../pages/Partners/components/AccordionAddress/locales/default.es.json';

import accordionDocumentsPt from '../../pages/Partners/components/AccordionDocuments/locales/default.json';
import accordionDocumentsEn from '../../pages/Partners/components/AccordionDocuments/locales/default.en.json';
import accordionDocumentsEs from '../../pages/Partners/components/AccordionDocuments/locales/default.es.json';

import accordionBankPt from '../../pages/Partners/components/AccordionBank/locales/default.json';
import accordionBankEn from '../../pages/Partners/components/AccordionBank/locales/default.en.json';
import accordionBankEs from '../../pages/Partners/components/AccordionBank/locales/default.es.json';

import suppliersPt from '../../pages/Suppliers/components/locales/default.json';
import suppliersEn from '../../pages/Suppliers/components/locales/default.en.json';
import suppliersEs from '../../pages/Suppliers/components/locales/default.es.json';

import accordionInfoAddPt from '../../pages/Suppliers/components/AccordionInfoAdd/locales/default.json';
import accordionInfoAddEs from '../../pages/Suppliers/components/AccordionInfoAdd/locales/default.es.json';
import accordionInfoAddEn from '../../pages/Suppliers/components/AccordionInfoAdd/locales/default.en.json';

import accordionUsersPt from '../../pages/Suppliers/components/AccordionUsers/locales/default.json';
import accordionUsersEs from '../../pages/Suppliers/components/AccordionUsers/locales/default.es.json';
import accordionUsersEn from '../../pages/Suppliers/components/AccordionUsers/locales/default.en.json';

import accordionResourcesPt from '../../pages/Suppliers/components/AccordionResources/locales/default.json';
import accordionResourcesEs from '../../pages/Suppliers/components/AccordionResources/locales/default.es.json';
import accordionResourcesEn from '../../pages/Suppliers/components/AccordionResources/locales/default.en.json';

import accordionFinancialPt from '../../pages/Suppliers/components/AccordionFinancial/locales/default.json';
import accordionFinancialEs from '../../pages/Suppliers/components/AccordionFinancial/locales/default.es.json';
import accordionFinancialEn from '../../pages/Suppliers/components/AccordionFinancial/locales/default.en.json';

import accordionAccountPt from '../../pages/Suppliers/components/AccordionAccount/locales/default.json';
import accordionAccountEs from '../../pages/Suppliers/components/AccordionAccount/locales/default.es.json';
import accordionAccountEn from '../../pages/Suppliers/components/AccordionAccount/locales/default.en.json';

import accordionInfoCompanyPt from '../../pages/Suppliers/components/AccordionInfoCompany/locales/default.json';
import accordionInfoCompanyEs from '../../pages/Suppliers/components/AccordionInfoCompany/locales/default.es.json';
import accordionInfoCompanyEn from '../../pages/Suppliers/components/AccordionInfoCompany/locales/default.en.json';

import suppliersTablePt from '../../pages/Suppliers/components/ListSuppliers/locales/default.json';
import suppliersTableEs from '../../pages/Suppliers/components/ListSuppliers/locales/default.es.json';
import suppliersTableEn from '../../pages/Suppliers/components/ListSuppliers/locales/default.en.json';

import accountsPt from '../../pages/Accounts/locales/default.json';
import accountsEn from '../../pages/Accounts/locales/default.en.json';
import accountsEs from '../../pages/Accounts/locales/default.es.json';

import addAccountsPt from '../../pages/Accounts/AddAccounts/locales/default.json';
import addAccountsEn from '../../pages/Accounts/AddAccounts/locales/default.en.json';
import addAccountsEs from '../../pages/Accounts/AddAccounts/locales/default.es.json';

import editAccountsPt from '../../pages/Accounts/EditAccounts/locales/default.json';
import editAccountsEn from '../../pages/Accounts/EditAccounts/locales/default.en.json';
import editAccountsEs from '../../pages/Accounts/EditAccounts/locales/default.es.json';

import merchantsPt from '../../pages/Merchants/components/locales/default.json';
import merchantsEn from '../../pages/Merchants/components/locales/default.en.json';
import merchantsEs from '../../pages/Merchants/components/locales/default.es.json';

import addMerchantsPt from '../../pages/Merchants/AddMerchants/locales/default.json';
import addMerchantsEn from '../../pages/Merchants/AddMerchants/locales/default.en.json';
import addMerchantsEs from '../../pages/Merchants/AddMerchants/locales/default.es.json';

import editMerchantsPt from '../../pages/Merchants/EditMerchants/locales/default.json';
import editMerchantsEn from '../../pages/Merchants/EditMerchants/locales/default.en.json';
import editMerchantsEs from '../../pages/Merchants/EditMerchants/locales/default.es.json';

import merchantFlagsPt from '../../pages/MerchantFlags/components/locales/default.json';
import merchantFlagsEn from '../../pages/MerchantFlags/components/locales/default.en.json';
import merchantFlagsEs from '../../pages/MerchantFlags/components/locales/default.es.json';

import addMerchantFlagsPt from '../../pages/MerchantFlags/AddMerchantFlags/locales/default.json';
import addMerchantFlagsEn from '../../pages/MerchantFlags/AddMerchantFlags/locales/default.en.json';
import addMerchantFlagsEs from '../../pages/MerchantFlags/AddMerchantFlags/locales/default.es.json';

import editMerchantFlagsPt from '../../pages/MerchantFlags/EditMerchantFlags/locales/default.json';
import editMerchantFlagsEn from '../../pages/MerchantFlags/EditMerchantFlags/locales/default.en.json';
import editMerchantFlagsEs from '../../pages/MerchantFlags/EditMerchantFlags/locales/default.es.json';

import transferPt from '../../pages/Dashboard/components/Transfers/locales/default.json';
import transferEn from '../../pages/Dashboard/components/Transfers/locales/default.en.json';
import transferEs from '../../pages/Dashboard/components/Transfers/locales/default.es.json';

import tabSearchPt from '../../pages/Dashboard/components/TabSearchs/locales/default.json';
import tabSearchEn from '../../pages/Dashboard/components/TabSearchs/locales/default.en.json';
import tabSearchEs from '../../pages/Dashboard/components/TabSearchs/locales/default.es.json';

import modalPaymentPt from '../../components/Checkout/ModalContentPayment/locales/default.json';
import modalPaymentEn from '../../components/Checkout/ModalContentPayment/locales/default.en.json';
import modalPaymentEs from '../../components/Checkout/ModalContentPayment/locales/default.es.json';

import loginPt from '../../pages/Login/locales/default.json';
import loginEn from '../../pages/Login/locales/default.en.json';
import loginEs from '../../pages/Login/locales/default.es.json';

import reservationsPt from '../../pages/Reservations/ListReservations/locales/default.json';
import reservationsEn from '../../pages/Reservations/ListReservations/locales/default.en.json';
import reservationsEs from '../../pages/Reservations/ListReservations/locales/default.es.json';

import sessionClosurePt from '../../pages/SessionClosure/components/locales/default.json';
import sessionClosureEn from '../../pages/SessionClosure/components/locales/default.en.json';
import sessionClosureEs from '../../pages/SessionClosure/components/locales/default.es.json';

import salesPt from '../../pages/Sales/locales/default.json';
import salesEn from '../../pages/Sales/locales/default.en.json';
import salesEs from '../../pages/Sales/locales/default.es.json';

import RefundRulesPt from '../../pages/Sales/components/RefundRules/locales/default.json';
import RefundRulesEn from '../../pages/Sales/components/RefundRules/locales/default.en.json';
import RefundRulesEs from '../../pages/Sales/components/RefundRules/locales/default.es.json';

// pt['pt-BR'].home = homePt['pt-BR'];
// en['en'].home = homeEn['en'];
// es['es'].home = homeEs['es'];

pt['pt-BR'].addPartners = addPartnersPt['pt-BR'];
en['en'].addPartners = addPartnersEn['en'];
es['es'].addPartners = addPartnersEs['es'];

pt['pt-BR'].addSuppliers = addSuppliersPt['pt-BR'];
en['en'].addSuppliers = addSuppliersEn['en'];
es['es'].addSuppliers = addSuppliersEs['es'];

pt['pt-BR'].listSuppliers = listSuppliersPt['pt-BR'];
en['en'].listSuppliers = listSuppliersEn['en'];
es['es'].listSuppliers = listSuppliersEs['es'];

/* pt['pt-BR'].translateOption = translateOptionPt['pt-BR'];
en['en'].translateOption = translateOptionEn['en'];
es['es'].translateOption = translateOptionEs['es']; */

pt['pt-BR'].calendar = calendarPt['pt-BR'];
en['en'].calendar = calendarEn['en'];
es['es'].calendar = calendarEs['es'];

pt['pt-BR'].cartHeader = cartHeaderPt['pt-BR'];
en['en'].cartHeader = cartHeaderEn['en'];
es['es'].cartHeader = cartHeaderEs['es'];

pt['pt-BR'].partners = partnersPt['pt-BR'];
en['en'].partners = partnersEn['en'];
es['es'].partners = partnersEs['es'];

pt['pt-BR'].accordionPersonal = accordionPersonalPt['pt-BR'];
en['en'].accordionPersonal = accordionPersonalEn['en'];
es['es'].accordionPersonal = accordionPersonalEs['es'];

pt['pt-BR'].legalPerson = legalPersonPt['pt-BR'];
en['en'].legalPerson = legalPersonEn['en'];
es['es'].legalPerson = legalPersonEs['es'];

pt['pt-BR'].physicalPerson = physicalPersonPt['pt-BR'];
en['en'].physicalPerson = physicalPersonEn['en'];
es['es'].physicalPerson = physicalPersonEs['es'];

pt['pt-BR'].accordionAddress = accordionAddressPt['pt-BR'];
en['en'].accordionAddress = accordionAddressEn['en'];
es['es'].accordionAddress = accordionAddressEs['es'];

pt['pt-BR'].accordionDocuments = accordionDocumentsPt['pt-BR'];
en['en'].accordionDocuments = accordionDocumentsEn['en'];
es['es'].accordionDocuments = accordionDocumentsEs['es'];

pt['pt-BR'].accordionBank = accordionBankPt['pt-BR'];
en['en'].accordionBank = accordionBankEn['en'];
es['es'].accordionBank = accordionBankEs['es'];

pt['pt-BR'].suppliers = suppliersPt['pt-BR'];
en['en'].suppliers = suppliersEn['en'];
es['es'].suppliers = suppliersEs['es'];

pt['pt-BR'].accordionInfoAdd = accordionInfoAddPt['pt-BR'];
en['en'].accordionInfoAdd = accordionInfoAddEn['en'];
es['es'].accordionInfoAdd = accordionInfoAddEs['es'];

pt['pt-BR'].accordionUsers = accordionUsersPt['pt-BR'];
en['en'].accordionUsers = accordionUsersEn['en'];
es['es'].accordionUsers = accordionUsersEs['es'];

pt['pt-BR'].accordionResources = accordionResourcesPt['pt-BR'];
en['en'].accordionResources = accordionResourcesEn['en'];
es['es'].accordionResources = accordionResourcesEs['es'];

pt['pt-BR'].accordionFinancial = accordionFinancialPt['pt-BR'];
en['en'].accordionFinancial = accordionFinancialEn['en'];
es['es'].accordionFinancial = accordionFinancialEs['es'];

pt['pt-BR'].accordionAccount = accordionAccountPt['pt-BR'];
en['en'].accordionAccount = accordionAccountEn['en'];
es['es'].accordionAccount =accordionAccountEs['es'];

pt['pt-BR'].accordionInfoCompany = accordionInfoCompanyPt['pt-BR'];
en['en'].accordionInfoCompany = accordionInfoCompanyEn['en'];
es['es'].accordionInfoCompany = accordionInfoCompanyEs['es'];

pt['pt-BR'].suppliersTable = suppliersTablePt['pt-BR'];
en['en'].suppliersTable = suppliersTableEn['en'];
es['es'].suppliersTable = suppliersTableEs['es'];

pt['pt-BR'].accounts = accountsPt['pt-BR'];
en['en'].accounts = accountsEn['en'];
es['es'].accounts = accountsEs['es'];

pt['pt-BR'].addAccounts = addAccountsPt['pt-BR'];
en['en'].addAccounts = addAccountsEn['en'];
es['es'].addAccounts = addAccountsEs['es'];

pt['pt-BR'].editAccounts = editAccountsPt['pt-BR'];
en['en'].editAccounts = editAccountsEn['en'];
es['es'].editAccounts = editAccountsEs['es'];

pt['pt-BR'].merchants = merchantsPt['pt-BR'];
en['en'].merchants = merchantsEn['en'];
es['es'].merchants = merchantsEs['es'];

pt['pt-BR'].addMerchants = addMerchantsPt['pt-BR'];
en['en'].addMerchants = addMerchantsEn['en'];
es['es'].addMerchants = addMerchantsEs['es'];

pt['pt-BR'].editMerchants = editMerchantsPt['pt-BR'];
en['en'].editMerchants = editMerchantsEn['en'];
es['es'].editMerchants = editMerchantsEs['es'];

pt['pt-BR'].merchantFlags = merchantFlagsPt['pt-BR'];
en['en'].merchantFlags = merchantFlagsEn['en'];
es['es'].merchantFlags = merchantFlagsEs['es'];

pt['pt-BR'].addMerchantFlags = addMerchantFlagsPt['pt-BR'];
en['en'].addMerchantFlags = addMerchantFlagsEn['en'];
es['es'].addMerchantFlags = addMerchantFlagsEs['es'];

pt['pt-BR'].editMerchantFlags = editMerchantFlagsPt['pt-BR'];
en['en'].editMerchantFlags = editMerchantFlagsEn['en'];
es['es'].editMerchantFlags = editMerchantFlagsEs['es'];

pt['pt-BR'].transfer = transferPt['pt-BR'];
en['en'].transfer = transferEn['en'];
es['es'].transfer = transferEs['es'];

pt['pt-BR'].tabSearch = tabSearchPt['pt-BR'];
en['en'].tabSearch = tabSearchEn['en'];
es['es'].tabSearch = tabSearchEs['es'];

pt['pt-BR'].modalPayment = modalPaymentPt['pt-BR'];
en['en'].modalPayment = modalPaymentEn['en'];
es['es'].modalPayment = modalPaymentEs['es'];

pt['pt-BR'].login = loginPt['pt-BR'];
en['en'].login = loginEn['en'];
es['es'].login = loginEs['es'];

pt['pt-BR'].reservations = reservationsPt['pt-BR'];
en['en'].reservations = reservationsEn['en'];
es['es'].reservations = reservationsEs['es'];

pt['pt-BR'].sessionClosure = sessionClosurePt['pt-BR'];
en['en'].sessionClosure = sessionClosureEn['en'];
es['es'].sessionClosure = sessionClosureEs['es'];

pt['pt-BR'].sales = salesPt['pt-BR'];
en['en'].sales = salesEn['en'];
es['es'].sales = salesEs['es'];

pt['pt-BR'].refundRules = RefundRulesPt['pt-BR'];
en['en'].refundRules = RefundRulesEn['en'];
es['es'].refundRules = RefundRulesEs['es'];

export {
  pt,
  en,
  es,
};