import React, { useEffect, useState, Key } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField?: any;
  propsLabel?: any;
  propsErrors?: any;
}

const GetSpecialTicketsCorp: React.FC<propPlus> = ({
  propsField,
  propsErrors
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '69420',
      },
    };

    async function listSpecialTicketsCorp() {
      try {
        const { data } = await api.get(`/DropDown/GetSpecialTicketsTypes`, config);
        if (data.status !== 400) {
          setInfo(data.data);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSpecialTicketsCorp();
  }, []);

  if (info !== null) {
    return (
      <div className={propsErrors.type ? 'endpoint-error' : 'endpoint'}>
        <Form.Select
          {...propsField}
          aria-invalid={propsErrors?.type ? 'true' : ''}
          as="select"
          variant="standard"
          margin="normal"
          required
        >
          <option value="" selected disabled>
            Selecione
          </option>
          {info?.length > 0
            ? info.map((info: any, index: Key) => (
                <option key={index} value={info.id}>
                  {info.description}
                </option>
              ))
            : ''}
        </Form.Select>
      </div>
    );
  } else {
    return <></>;
  }
};

export default GetSpecialTicketsCorp;
