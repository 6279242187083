import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import GetProductsListCod from "../../../components/C2Points/GetProductsListCod";
import RangeCalendar from "../../../components/Calendar/RangeCalendar";
import api from "../../../services/api";
import GetModalitiesListCod from "../../../components/C2Points/GetModalitiesListCod";
import GetSalesChannel from "../../../components/C2Points/GetSalesChannel";
import GetSchedulesBySalesChannel from "../../../components/C2Points/GetSchedulesBySalesChannel";
import GetBlackoutTypes from "../../../components/C2Points/GetBlackoutTypes";
import GetSuppliersFilter from "../../../components/C2Points/GetSuppliersFilter";
import GetProductsListCodBlackout from "../../../components/C2Points/GetProductsListCodBlackout";
import GetSalesChannelBlackout from "../../../components/C2Points/GetSalesChannelBlackout";
import GetSuppliersFilterBlackout from "../../../components/C2Points/GetSuppliersFilterBlackout";
import GetModalitiesListCodBlackout from "../../../components/C2Points/GetModalitiesListCodBlackout";

export interface blackout {
  action: any;
  isEdit?: boolean;
  infoEdit?: any;
  setSalesChannels?: any
}

const BlackoutComponent: React.FC<blackout> = React.memo(({
  action, isEdit = false, infoEdit = null, setSalesChannels
}: blackout) => {
  let today: any = new Date();
  const todayStr = [
    `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`,
    `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`
  ];

  const [, setProducts] = useState<any>(null);
  const [, setSelected] = useState<number[]>([]);
  const [date, setDate] = useState<any>(todayStr);
  const [, setLoadingSuppliers] = useState<boolean>(false);
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [salesChannelObj, setSalesChannelObj] = useState<any>(null);
  const [schedulesList, setSchedulesList] = useState<any>(null);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({});

  const watchProducts = watch(`products`, undefined);
  const watchModalities = watch(`modalities`, "");
  const watchSuppliers = watch(`supplier`, undefined);
  const watchSchedules = watch(`schedules`, []);
  const watchTypes = watch(`blackoutType`, {});

  useEffect(() => {
    if (isEdit === true) {
      setValue('name', infoEdit.blackoutName)
      setValue('description', infoEdit.blackoutDescription)
      setValue('status', infoEdit.blackoutStatus)
      setValue('date', [new Date(infoEdit.blackoutDateStart).toLocaleDateString(), new Date(infoEdit.blackoutDateEnd).toLocaleDateString()])
      setDate([new Date(infoEdit.blackoutDateStart).toLocaleDateString(), new Date(infoEdit.blackoutDateEnd).toLocaleDateString()])

      if ([1, 2].includes(Number(infoEdit.blackoutType || 0))) {
        let auxProducts: any = infoEdit.productList.map((elem: any) => { return { "label": elem.productName, "code": elem.productCode } });
        // auxProducts = auxProducts.filter((elem: any, index: number) => { return auxProducts.indexOf(elem) === index && elem !== null });
        setValue('products', auxProducts);
      }

      if ([1].includes(Number(infoEdit.blackoutType || 0))) {
        let auxModalities: any = infoEdit.productList.map((elem: any) => { return { "label": elem.modalityName, "value": elem.modalityCode, "productCode": elem.productCode } });
        setValue('modalities', auxModalities);
      }
      // setValue('schedules', [...infoEdit.productList[0].horarioList])   
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isEdit === true) {
      if (salesChannelObj !== null) {
        let auxSalesChannel: object[] = [];
        salesChannelObj?.forEach((elem: any) => {
          infoEdit.productList[0]?.salesChannelList?.forEach((item: any) => {
            if (elem.idSalesChannel === item) {
              auxSalesChannel.push({ "label": elem.salesChannelName, "value": elem.idSalesChannel });
            }
          })
        })
        setValue('salesChannel', auxSalesChannel)
      }
      setSalesChannels(salesChannelObj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesChannelObj])

  useEffect(() => {
    if (isEdit === true && infoEdit.blackoutType === 1) {
      if (schedulesList === null) {
        let auxSchedules: any = [];
        infoEdit.productList[0]?.horarioList.forEach((item: string) => {
          auxSchedules.push({ "label": item, "value": item })
        })
        setSchedulesList(auxSchedules)
      }
      setValue("schedules", schedulesList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulesList])

  useEffect(() => {
    if (watchProducts) {
      setSelected(watchProducts)
    }
  }, [watchProducts])

  useEffect(() => {
    setLoadingSuppliers(true);
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listarProductsBySupplier() {
      try {
        const { data } = await api.get(`/DropDown/GetProductsBySupplierId/${watchSuppliers?.id}`/* ${watchSuppliers?.groupId} */, config);

        if (data.status !== 400) {
          setProducts(data.data);
        }
        setLoadingSuppliers(false);
      } catch (error: any) {
        setLoadingSuppliers(false);
      }
    }

    if (watchSuppliers !== undefined) {
      listarProductsBySupplier();
    }
  }, [watchSuppliers])

  const onSubmit = (data: any) => {
    if (!data.description) {
      setDescriptionError(true)

      return;
    }

    if (!data.description) {
      setDescriptionError(true)

      return;
    }

    data.blackoutDateStart = date[0]?.split('/').reverse().join('-');
    data.blackoutDateEnd = date[1]?.split('/').reverse().join('-') || data.blackoutDateStart;


    setDescriptionError(false)

    action(data);
  }

  return (
    <>
      <Card className="card-body">
        <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-4">
            <Col md={12}>
              <Row>
                <Col md={12} className='h-100'>
                  <Card className="d-flex justify-content-center align-items-center p-4">
                    <div>
                      <FontAwesomeIcon size="5x" icon={["fad", "ticket"]} />
                    </div>
                    <div>
                      <h5>Novo Blackout</h5>
                    </div>
                    <div>
                      <p className="text-center">
                        Aqui você poderá selecionar a atração / atrações em
                        que será aplicado o Blackout
                      </p>
                    </div>

                    <div className="w-100">
                      <Row className="mb-4">
                        <Col md="12">
                          <Row className='mt-2'>
                            <Form.Group as={Col} md="12">
                              <Form.Label>Nome*</Form.Label>
                              <Controller
                                control={control}
                                name="name"
                                rules={{ required: { value: true, message: 'Por favor, informe o nome do blackout' } }}
                                render={({ field }: any) => (
                                  <Form.Control
                                    {...field}
                                    type="text"
                                    variant="standard"
                                    margin="normal"
                                    autoComplete="off"
                                  />
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="name"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                              />
                            </Form.Group>
                          </Row>

                          <Row className='mt-3'>
                            <Form.Group as={Col} md="6">
                              <Form.Label>Período*</Form.Label>
                              <InputGroup hasValidation className="ranger-calendar">
                                <RangeCalendar date={date} setDate={setDate} defaultValues={isEdit ? [infoEdit?.blackoutDateStart, infoEdit?.blackoutDateEnd] : [`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`, `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`]} />
                              </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                              <Form.Label>Status</Form.Label>
                              <Controller
                                control={control}
                                name="status"
                                rules={{ required: { value: isEdit, message: 'Por favor, informe o status do blackout' } }}
                                render={({ field }: any) => (
                                  <div className="endpoint">

                                    <Form.Select
                                      {...field}
                                      as="select"
                                      variant="standard"
                                      margin="normal"

                                      disabled={!isEdit}
                                    >
                                      <option value='1'>Ativo</option>
                                      <option value='2'>Inativo</option>

                                    </Form.Select>
                                  </div>
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="status"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                              />
                            </Form.Group>
                          </Row>

                          <Row className='mt-3'>
                            <Form.Group as={Col} md="12">
                              <Form.Label>Descrição</Form.Label>
                              <Controller
                                control={control}
                                name="description"
                                rules={{ required: { value: false, message: 'Por favor, informe uma descição do blackout' } }}
                                render={({ field }: any) => (
                                  <textarea
                                    {...field}
                                    className={descriptionError === false ? 'form-control' : 'form-control  border-danger'}
                                    variant="standard"
                                    margin="normal"
                                    autoComplete="off"
                                    required
                                  />
                                )}
                              />
                              {descriptionError === true ?
                                <small style={{ color: "red" }}>{'Por favor, informe uma descição do blackout'}</small>
                                :
                                <></>
                              }
                              <ErrorMessage
                                errors={errors}
                                name="description"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                              />
                            </Form.Group>
                          </Row>

                          <Row className='mt-3'>
                            <Form.Group as={Col} md="12">
                              <Form.Label>Tipo de blackout</Form.Label>
                              <Controller
                                control={control}
                                name="blackoutType"
                                rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                render={({ field }: any) => (
                                  <div className="">
                                    <GetBlackoutTypes
                                      propsField={field}
                                      propsErrors={errors}
                                      defaultValue={infoEdit?.blackoutType}
                                      name={'blackoutType'}
                                      setValue={setValue}
                                    />
                                  </div>
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="blackoutType"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                              />
                            </Form.Group>
                          </Row>

                          {[1, 2].includes(Number(watchTypes?.value || 0)) &&
                            <Row className='mt-3'>
                              <Form.Group as={Col} md="12">
                                <Form.Label>Produtos*</Form.Label>
                                <Controller
                                  control={control}
                                  name="products"
                                  rules={{ required: { value: true, message: 'Por favor, informe ao menos 1 produto' } }}
                                  render={({ field }: any) => (
                                    <div className="">
                                      <GetProductsListCodBlackout
                                        propsField={field}
                                        propsErrors={errors}
                                        isMulti={true}
                                      />
                                    </div>
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="products"
                                  render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                              </Form.Group>
                            </Row>
                          }

                          {[1].includes(Number(watchTypes?.value || 0)) &&
                            <>
                              {(watchProducts !== undefined) &&
                                <Row className='mt-3'>
                                  <Form.Group as={Col} md="12">
                                    <Form.Label>Modalidades*</Form.Label>
                                    <Controller
                                      control={control}
                                      name="modalities"
                                      rules={{ required: { value: true, message: 'Por favor, informe ao menos 1 modalidade' } }}
                                      render={({ field }: any) => (
                                        <div className="">
                                          <GetModalitiesListCodBlackout
                                            propsField={field}
                                            isMulti={true}
                                            productsCode={watchProducts}
                                            setValue={setValue}
                                            watchModalities={watchModalities}
                                          />
                                        </div>
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="modalities"
                                      render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                  </Form.Group>
                                </Row>
                              }
                            </>
                          }

                          {[1, 4].includes(Number(watchTypes?.value || 0)) &&
                            <Row className='mt-3'>
                              <Form.Group as={Col} md="12">
                                <Form.Label>Canais</Form.Label>
                                <Controller
                                  control={control}
                                  name="salesChannel"
                                  rules={{ required: { value: true, message: "Por favor, informe esse campo" } }}
                                  render={({ field }: any) => (
                                    <GetSalesChannelBlackout propsField={field} propsErrors={errors} setValue={setValue} setSalesChannelObj={setSalesChannelObj} infoEdit={infoEdit?.productList[0]?.salesChannelList?.map((elem: any) => { return ({ idSalesChannel: elem.id }) })} status={0} />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="salesChannel"
                                  render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                              </Form.Group>
                            </Row>
                          }

                          {[1].includes(Number(watchTypes?.value || 0)) &&
                            <>
                              {(watchModalities !== undefined) &&
                                <Row className='mt-3'>
                                  <Form.Group as={Col} md="12">
                                    <Form.Label>Horários</Form.Label>
                                    <Controller
                                      control={control}
                                      name="schedules"
                                      rules={{ required: { value: true, message: "Por favor, informe esse campo" } }}
                                      render={({ field }: any) => (
                                        <GetSchedulesBySalesChannel
                                          propsField={field}
                                          setValue={setValue}
                                          watchSchedules={watchSchedules}
                                          modalitiesCodes={watchModalities}
                                          isMulti={true}
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="schedules"
                                      render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                  </Form.Group>
                                </Row>
                              }
                            </>
                          }

                          {[3].includes(Number(watchTypes?.value || 0)) &&
                            <>
                              <Row className='mt-3'>
                                <Form.Group as={Col} md="12">
                                  <Form.Label>Fornecedores</Form.Label>
                                  <Controller
                                    control={control}
                                    name="suppliers"
                                    rules={{ required: { value: true, message: "Por favor, informe esse campo" } }}
                                    render={({ field }: any) => (
                                      <div className="input-select-custom">
                                        <GetSuppliersFilterBlackout
                                          propsField={field}
                                          propsErrors={errors}
                                          setFilterSupplier={() => { }}
                                          setValue={setValue}
                                          isMulti={true}
                                          defaultId={isEdit ? infoEdit.supplierList.map((elem: any) => { return (elem.id) }) : null}
                                        />
                                      </div>
                                    )}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name="suppliers"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                  />
                                </Form.Group>
                              </Row>
                            </>
                          }

                        </Col>
                      </Row>
                      <div className="d-flex justify-content-center mt-3">
                        <Button type="submit" onClick={() => { }} variant="primary">Confirmar</Button>
                      </div>
                    </div>
                  </Card>
                </Col>

                {/* <Col md={6} className='h-100'>
                  <Card className="d-flex justify-content-center">
                    <Row style={{ padding: '10px 25px' }}>
                      <Form.Group as={Col} md="12" controlId="">
                        <Form.Label className="label-small">
                          Pesquisar produtos por fornecedor
                        </Form.Label>

                        <Controller
                          control={control}
                          name="supplier"
                          rules={{ required: { value: false, message: 'Por favor, informe o grupo sessão' } }}
                          render={({ field }: any) => (
                            <div className="input-select-custom">
                              <GetSupplierId
                                propsField={field}
                                propsErrors={errors}
                                setFilterSupplier={() => { }}
                                setValue={setValue}
                              />
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Row>

                    {
                      products === null ?
                        <>
                        </>
                        : loadingSuppliers === true ?
                          <>
                            <hr />
                            <div className="blackout-load primary mb-4"></div>
                          </>
                          : products.length === 0 ?
                            <>
                              <hr />
                              <div className="text-center pt-2 pb-4">
                                Nenhum produto vinculado a esse fornecedor
                              </div>
                            </>
                            :
                            <>
                              <hr />
                              <div className="table-default blackout-suppliers-table">
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  selectRow={selectRow}
                                  data={products}
                                  columns={columns}
                                  rowClasses={rowClasses}
                                />
                              </div>
                            </>
                    }
                  </Card>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}
);

export default BlackoutComponent;
