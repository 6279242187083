import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  watchCountry?: any;
  country?: any;
};


const GetCountrys: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, watchCountry, country
}: propPlus) => {
  
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listCountrys() {
      try {
        const { data } = await api.get(`/DropDown/GetCountrys`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }

      }
    }
    listCountrys();
  }, []);

  /* function handleChange(e: any){
    if(watchCountry){
        watchCountry(e);
    }
  } */

  if (info !== null) {
    return (
      <>
        <div className={propsErrors.country !== undefined ? "endpoint-error" : "endpoint"}>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors?.country ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={!country ? t('accordionInfoCompany.endpoint') : country}
            options={Array.from(info)}
            getOptionLabel={(option: any) => `${option.description}`}
            isDisabled={true}
            /* onChange={handleChange}
            value={info.description} */
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetCountrys;