import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
//import { Container, Row, Col } from "react-bootstrap";
//import Button from "@restart/ui/esm/Button";

import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import SingleCalendar from "../../Calendar/SingleCalendar";

import { ModalBody } from "react-bootstrap";

import "./ModalMaps.scss";

function ModalMaps(props: any) {
    const [modalShow, setModalShow] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
    })

    return (
        <div>
            <Modal
                {...props}
                className={
                    props.classModal
                        ? "modalAuth " + props.classModal
                        : "modalAuth"
                }
                show={modalShow}
                onHide={() => {
                    setModalShow(false);
                    props.closeAction ? props.closeAction() : <></>;
                }}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ModalBody className="p-0" style={{ width: "100%", height: "660px" }}>
                    <GoogleMap
                        mapContainerStyle={{ width: "100%", height: "100%" }}
                        center={
                            {
                                lat: props.obj.latOrigem,
                                lng: props.obj.lngOrigem
                            }
                        }
                        zoom={12}
                    >
                        <Marker
                            position={
                                {
                                    lat: props.obj.latOrigem,
                                    lng: props.obj.lngOrigem
                                }
                            }
                            options={{
                                label: {
                                    text: "Origem",
                                    className: "label-map"
                                }
                            }}
                        />
                        <Marker
                            position={
                                {
                                    lat: props.obj.latDestino,
                                    lng: props.obj.lngDestino
                                }
                            }
                            options={{
                                label: {
                                    text: "Destino",
                                    className: "label-map"
                                }
                            }}
                        />
                    </GoogleMap>
                </ModalBody>
            </Modal >

            <div className="w-100" onClick={() => setModalShow(true)}>
                <span
                    style={{ cursor: "pointer" }}
                    className="w-100"
                >
                    <FontAwesomeIcon
                        icon={["fas", "map-marker-alt"]}
                        size="1x"
                        style={{ marginRight: "10px" }}
                    />
                    <span>Localização</span>
                </span>
            </div>
        </div >
    );
}

export default ModalMaps;
