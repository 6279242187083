import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import { Controller, useForm } from "react-hook-form";

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterBillsReceive.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import GetAgents from "../../../../components/C2Points/GetAgentsFilter";
/* import GlobalAlert from "../../../../components/Alert/GlobalAlert/GlobalAlert"; */

export interface propFilter {
    buscar: any,
    billsReceive: any,
    error: any,
    clearActualValues?: any
}

const FilterBillsReceive: React.FC<propFilter> = ({
    buscar, billsReceive, error, clearActualValues
}) => {

    const { t } = useTranslation();

    const [payment, setPayment] = useState<any>("");

    const [filterLocalizador, setFilterLocalizador] = useState<any>("");
    const [filterStatusReserve, setFilterStatusReserve] = useState<any>(null);
    const [filterStatusPagamento, setFilterStatusPagamento] = useState<any>(null);
    const [filterStatusFormaPagamento, setFilterStatusFormaPagamento] = useState<any>(null);
    const [filterSupplier, setFilterSupplier] = useState<any>("");
    const [filterAdquirente, setFilterAdquirente] = useState<any>(null);
    const [filterToken, setFilterToken] = useState<any>("");
    const [filterAgent, setFilterAgent] = useState<any>("");
    const [filterRefAgent, setFilterRefAgent] = useState<any>("");
    const [filterClient, setFilterClient] = useState<any>("");
    const [filterType, setFilterType] = useState<any>(null);
    const [filterTypeDate, setFilterTypeDate] = useState<any>(3);
    const [filterDate, setFilterDate] = useState<any>([]);
    
    const [alert, setAlert] = useState<any>();

    const {
        control,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = () => {
        buscar(filterLocalizador, filterStatusReserve, filterStatusPagamento, filterStatusFormaPagamento, filterSupplier, filterAdquirente, filterToken, filterAgent, filterRefAgent, filterClient, filterType, filterTypeDate, filterDate);
    };

    const handleClearClick = (event: any) => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterLocalizador("");
        setFilterStatusReserve(null);
        setFilterStatusPagamento(null);
        setFilterStatusFormaPagamento(null)
        setFilterSupplier('');
        setFilterAdquirente(null);
        setFilterToken('');
        setFilterAgent('');
        setFilterRefAgent('');
        setFilterClient('');
        setFilterType(null);
        setFilterTypeDate(3);
        setFilterDate([]);
        buscar("", null, null, null, '', null, '', '', '', '', null, 3, []);
    };

    const handleChangeLocalizador = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterLocalizador(newValue);
    }

    const handleChangeStatusReserve = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatusReserve(newValue);
    }

    const handleChangeStatusPagamento = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatusPagamento(newValue);
    }

    const handleChangeStatusFormaPagamento = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatusFormaPagamento(newValue);
    }

    const handleChangeSupplier = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterSupplier(newValue);
    }

    const handleChangeAdquirente = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterAdquirente(newValue);
    }

    const handleChangeToken = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterToken(newValue);
    }

    const handleChangeAgent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterAgent(newValue);
    }

    const handleChangeRefAgent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterRefAgent(newValue);
    }

    const handleChangeClient = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterClient(newValue);
    }

    const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterType(newValue);
    }

    const handleChangeTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeDate(newValue);
    }

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Contas</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            {alert}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Localizador</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChangeLocalizador}
                                                    autoComplete="off"
                                                />
                                                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Status Reserva</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatusReserve(e)}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Confirmada</option>
                                                            <option value="2">Cancelada</option>
                                                            <option value="3">Pendente</option>
                                                        </Form.Select>
                                                        {/* <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback> */}
                                                    </InputGroup>
                                                </div>
                                                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Status Pagamento</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatusPagamento(e)}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Pendente</option>
                                                            <option value="2">Conciliado</option>
                                                            <option value="3">Faturado</option>
                                                            <option value="4">Recebido</option>
                                                        </Form.Select>
                                                        {/* <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback> */}
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Forma de Pagamento</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatusFormaPagamento(e)}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Cash</option>
                                                            <option value="2">PIX</option>
                                                            <option value="3">Transferência</option>
                                                            <option value="4">Cartão</option>
                                                            <option value="5">Faturado</option>
                                                            <option value="6">Comissão a Receber</option>
                                                        </Form.Select>
                                                        {/* <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback> */}
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            {
                                                payment === "1"
                                                    ?
                                                    <>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Adquirente</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                onChange={handleChangeAdquirente}
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                    </>
                                                    :
                                                    payment === "2"
                                                    ?
                                                    <>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Adquirente</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                onChange={handleChangeAdquirente}
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                    </>
                                                    :
                                                    payment === "3"
                                                    ?
                                                    <>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Adquirente</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                onChange={handleChangeAdquirente}
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Token/NSU</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                onChange={handleChangeToken}
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                    </>
                                                    :
                                                    payment === "4"
                                                    ?
                                                    <></>
                                                    :
                                                    payment === "5"
                                                    ?
                                                    <>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Fornecedor</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                onChange={handleChangeSupplier}
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Token/NSU</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                onChange={handleChangeToken}
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </Row>
                                        <Row className="mb-3">
                                            {alert}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Agente</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="agente"
                                                    render={({ field }: any) => (
                                                        <GetAgents propsField={field} propsErrors={errors} propsLabel="Agentes" actionAgents={setFilterAgent} />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Referência do Agente</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChangeRefAgent}
                                                    autoComplete="off"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Cliente</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChangeClient}
                                                    autoComplete="off"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Tipo de Data</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeTypeDate(e)}
                                                        >
                                                            <option value="1">Vencimento</option>
                                                            <option value="2">Operação</option>
                                                            <option value="3">Reserva</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Data</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default FilterBillsReceive;