import React, { useState, useEffect } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterBillsPay from '../BillsToPay/components/FilterBills/FilterBillsPay';
import TableSettleBills from './components/TableSettleBills/TableSettleBills';

import './SettleBillsToPay.scss';

function BillsToPay() {

    const [billsPay, setBillsPay] = useState<any>(null);
    const [parameters, setParameters] = useState<any>({});
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);
    const [allowLoadFromFilter, setAllowLoadFromFilter] = useState<any>(false);

    /* Filtragem */
    const [actualLocal, setActualLocal] = useState<any>("");
    const [actualStatus, setActualStatus] = useState<any>(null);
    const [actualType, setActualType] = useState<any>(null);
    const [actualSupplier, setActualSupplier] = useState<any>("");
    const [actualTypeDate, setActualTypeDate] = useState<any>(3);
    const [actualDate, setActualDate] = useState<any>([]);

    /* END - Filtragem */

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    function clearActualValues() {
        setActualLocal("");
        setActualStatus(null);
        setActualType(null);
        setActualSupplier("");
        setActualTypeDate(3);
        setActualDate([]);
    }

    useEffect(() => {
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsPay(null);
        }

        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        async function billTopPay() {
            try {
                const { data } = await api.post('/BillsPayable/GetReport',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": actualLocal,
                        "statusPagamento": "2",
                        "fornecedor": actualSupplier,
                        "tipoConta": actualType,
                        "tipoData": actualTypeDate,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    },
                );
                setLoading(false);
                if (data.status !== 400) {
                    setParameters({
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": actualLocal,
                        "statusPagamento": "2",
                        "fornecedor": actualSupplier,
                        "tipoConta": actualType,
                        "tipoData": actualTypeDate,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    });
                    setBillsPay(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        billTopPay();
    }, [pageCount, rowsPerPage/* , actualStatus */]);

    async function buscar(local: any, status: any, type: any, supplier: any, typeDate: any, date: any) {
        setAllowLoadFromFilter(true);
        setActualLocal(local);
        setActualStatus(status);
        setActualType(type);
        setActualSupplier(supplier);
        setActualTypeDate(typeDate);
        setActualDate(date);
        setPageCount(1);

        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsPay(null);
        }

        let beginDate = []
        let endDate = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const res = await api.post('/BillsPayable/GetReport',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "item": "",
                    "statusPagamento": status,
                    "fornecedor": supplier,
                    "tipoConta": type,
                    "tipoData": typeDate,
                    "dataInicial": (date.length > 0) ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : (date !== null && date.length === 2) ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                },
            );
            setLoading(false);
            if (res.status !== 400) {
                setAllowLoadFromFilter(false);
                setParameters({
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "item": actualLocal,
                    "statusPagamento": actualStatus,
                    "fornecedor": actualSupplier,
                    "tipoConta": actualType,
                    "tipoData": actualTypeDate,
                    "dataInicial": (date.length > 0) ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : (date !== null && date.length === 2) ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                });
                setBillsPay(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            setAllowLoadFromFilter(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function exportBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        try {
            const exp = await api.post('/BillsPayable/ExportReport',
                {
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : "2022-01-01T12:19:07.105Z",
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : "2023-02-17T12:19:07.105Z",
                    "fornecedor": actualSupplier,
                    "item": actualLocal,
                    "page": pageCount,
                    "rowsPerPage": 0,
                    "statusPagamento": actualStatus,
                    "tipoconta": actualType,
                    "tipodata": actualTypeDate
                },
            );
            if (exp.status !== 400) {
                const link = document.createElement('a');
                link.href = `data:${exp.headers["content-type"]};base64,${exp.data}`;
                link.setAttribute(
                    'download',
                    'contas_pagar.xlsx',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();
                
                // Buffer.from(encodeURIComponent(exp.data)).toString('base64');

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);


                //console.log((encodeURIComponent(exp.data)));
                //console.log(link.href);
                ///onsole.log(btoa(unescape(encodeURIComponent(exp.data))));

                //console.log(exp.request.response)
                //setExportFile(exp.data)
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        try {
            const print = await api.post('/BillsPayable/PrintReport',
                {
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : "2022-01-01T12:19:07.105Z",
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : "2023-02-17T12:19:07.105Z",
                    "fornecedor": actualSupplier,
                    "item": actualLocal,
                    "page": pageCount,
                    "rowsPerPage": 0,
                    "statusPagamento": actualStatus,
                    "tipoconta": actualType,
                    "tipodata": actualTypeDate
                },
            );
            if (print.status !== 400) {
                const link = document.createElement('a');
                link.href = `data:${print.headers["content-type"]};${JSON.stringify(print.data)}`;
                link.setAttribute(
                    'download',
                    'contas_pagar.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);

                console.log(print)
                //setExportFile(exp.data)
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Financeiro / Liquidar em Lote'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Liquidar em Lote</h2>
                    </div>
                    <FilterBillsPay
                        buscar={buscar}
                        billsPay={billsPay}
                        error={error}
                        clearActualValues={clearActualValues}
                        allowLoadFromFilter={allowLoadFromFilter}
                    />
                    <TableSettleBills
                        billsPay={billsPay}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportBills={exportBills}
                        printBills={printBills}
                        parameters={parameters}
                        loading={loading}
                    />
                </div>
            </DesktopDefault>
        </>
    )
}

export default BillsToPay;