import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from '../../../components/Pagination/Pagination';

//STYLES
/* import './SuppliersTable.scss';
 */ //import { faTrumpet } from '@fortawesome/pro-duotone-svg-icons';

export interface propChannel {
    sources: any;
    channels: any;
    totalRows: any;
    pageCount: number;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    loading: any;
    setSelectedSource: any;
}

const ChannelTable: React.FC<propChannel> = ({
    sources, channels, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loading, setSelectedSource
}: propChannel) => {

    function addChannelName(cell: any, row: any) {
        var channelName: any = channels?.find(((elem: any) => elem.idSalesChannel === cell));
        return ( 
            <div>
                {channelName?.salesChannelName}
            </div>
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function addStatus(cell: any, row: any) {
        var status: string = (cell === 1 ? "Ativo" : "Inativo");

        return (
            <div
                className={status === "Ativo" ? "bg-reservation-ativo" : "bg-reservation-inativo"}
            >
                {status}
            </div>
        );
    }
    
    function addChannelStatus(cell: any, row: any) {
        var channelObj: any = channels?.find(((elem: any) => elem.idSalesChannel === row.idSalesChannel));
        var status: string = (channelObj.statusSalesChannel === 1 ? "Ativo" : "Inativo");

        return (
            <div
                className={status === "Ativo" ? "bg-reservation-ativo" : "bg-reservation-inativo"}
            >
                {status}
            </div>
        );
    }

    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="center-table options-table">
                    <Dropdown drop="down" >
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => { setSelectedSource(() => {return({sourceCode: row.sourceCode, idSourceSalesChannel: cell, idSalesChannel: row.idSalesChannel})}); }}
                            >
                                <FontAwesomeIcon icon={['fal', 'edit']} />
                                Editar
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    const columns = [
        {
            dataField: 'sourceCode',
            text: `Nome do source`,
        },
        {
            dataField: 'idSalesChannel',
            text: `Canal`,
            formatter: addChannelName,
        },
        {
            dataField: '',
            text: `Status do Canal`,
            formatter: addChannelStatus,
        },
        /* {
            dataField: 'asd',
            text: ``,
        },
        {
            dataField: 'status',
            text: `Status do Source`,
            formatter: addStatus,
        }, */
        {
            dataField: 'id',
            text: `Ações`,
            formatter: addActionButton,
        },
    ];

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando Sources", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "*", text: "Canais" }];

    const notFoundProducts = [{ clientes: "Nenhum source encontrado" }];
    /* [END] In case of empty table*/

    if (sources !== null && channels !== null) {
        return (
            <>
                <div className="table-default sources-table d-flex w-100 justify-content-center mt-4">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={sources}
                            columns={columns}
                        />

                        <Pagination
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            selectNumberRows="yes"
                        />
                    </div>
                </div>
            </>
        );
    } else if (sources === null && loading === true) {
        return (
            <>
                <div className="table-default reservations-table loading not-found">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="idSalesChannel"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default reservations-table not-found">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="">Canais</h3>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="idSalesChannel"
                            data={notFoundProducts}
                            columns={notFoundColumns}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default ChannelTable;
