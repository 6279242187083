import React, { useState, useEffect, Key } from 'react';
import api from "../../../../services/api";

import { Row, Col, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SingleCalendar from '../../../Calendar/SingleCalendar';
import GetCostCenterPay from '../../../C2Points/GetCostCenterPay';
import GetSuppliersFilter from '../../../C2Points/GetSuppliersFilter';
import GetAccountingAccountsFilter from '../../../C2Points/GetAccountingAccountsFilter';

import ModalConfirm from '../../../../components/Modal/ModalConfirm/ModalConfirm';

function Payment() {
    const [responseText, setResponseText] = useState<any>();
    const [modalResponse, setModalResponse] = useState<any>();
    const [log, setLog] = useState<any>();

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('');
    const [pagRecorrente, setPagRecorrente] = useState<any>(false);
    const [file, setFile] = useState<any>(null);
    const [fileName, setFileName] = useState<any>('');
    const [errorRateio, setErrorRateio] = useState<any>(false);
    const [date, setDate] = useState<any>();
    const [vencimento, setVencimento] = useState<any>();

    const [maxRateio, setMaxRateio] = useState<number>(100);
    const [value, setValue] = useState<any>([0]);
    const [controlObject, setControlObject] = useState<any>([1]);
    const [somaRateio, setSomaRateio] = useState<any>(0);
    //const [test, setTest] = useState<any>();
    const [rateioBlocked, setRateioBlocked] = useState<any>(false);
    //const [coastId, setCoastId] = useState<any>([]);
    const [arrayCoast, setArrayCoast] = useState<any>([""]);

    const validated = false;

    const tourAuth: any = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    const fileHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setFile(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    console.log(errors)

    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    function verifyDate(now: any, due: any) {
        var nowArray: any = now.split('-');
        var dueArray: any = due.split('-');

        if (parseInt(dueArray[0]) < parseInt(nowArray[0])) {
            return false;
        }
        if (parseInt(dueArray[1]) < parseInt(nowArray[1])) {
            return false;
        }
        if (parseInt(dueArray[2]) < parseInt(nowArray[2])) {
            return false;
        }
        return true;
    }

    const onSubmit = (data: any) => {
        console.log(data)
        let auxCenter: any = [];

        for (let i: any = 0; i < controlObject.length; i++) {
            auxCenter.push(
                {
                    "centroCustoId": arrayCoast[i],
                    "rateio": value[i]
                }
            )
        }

        setErrorRateio(false);
        data.data = fixDate(date);
        data.vencimento = fixDate(vencimento);
        data.arquivo = file;
        data.recorrente = pagRecorrente;
        data.fornecedor = data.fornecedor?.supplierId;
        data.categoria = data.categoria?.id;
        data.centroCusto = auxCenter;
        data.qtdeRecorrencia = data.qtdeRecorrencia === undefined ? "" : data.qtdeRecorrencia
        data.tipoRecorrencia = data.tipoRecorrencia === undefined ? "" : data.tipoRecorrencia

        setModalResponse('loading');

        if (verifyDate(data.data, data.vencimento)) {
            const insertConta = async () => {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };
                try {
                    const res = await api.post(`/BillsPayable/InsertBill`,
                        {
                            "centrosCusto": data.centroCusto,
                            "contaContabil": data.categoria,
                            "data": data.data,
                            "descricao": data.descricao,
                            "fornecedor": data.fornecedor,
                            "notaDebito": 2, //fixo 2
                            "observacao": data.observacao,
                            "qtdeRecorrencia": data.qtdeRecorrencia,
                            "recorrente": data.recorrente === true ? 1 : 2, //1=sim;2=não
                            "tipoRecorrencia": data.tipoRecorrencia, //1=Diaria;2=Semanal;3=Mensal;4=Anual
                            "uid": uId,
                            "usuario": `${userName} ${userLastName}`,
                            "valor": data.valor,
                            "vencimento": data.vencimento,
                            "arquivo": data.arquivo  //arquivo em base64
                        }, config);
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setModalResponse('success');
                        setLog(res.data.data.log)
                    } else {
                        setResponseText(res.data.data.texto);
                        setModalResponse('error');
                        setLog(res.data.data.log);
                    }
                } catch (error: any) {
                    setResponseText("Adicione um centro de custo");
                    setModalResponse('error');
                    setLog(1);
                }
            };

            insertConta();
        } else {
            setModalShow(true);
            setModalMessage("Data de vencimento antes da data atual.");
        }
    }

    const addRow = () => {
        if (rateioBlocked === false) {
            var aux: any = [];

            if (value.length > 0) {
                for (var i: any = 0; i < value.length; i++) {
                    aux.push(value[i]);
                }
                aux.push(0)
            } else {
                aux.push(0)
            }

            setValue(aux)
            setArrayCoast([...arrayCoast, ""])
            setControlObject([...controlObject, 1]);
        }

    };

    function removeRow(e: any, index: any) {
        setRateioBlocked(false);
        let aux: any = [];
        let auxValue: any = [];
        let auxCoast: any = [];

        for (let i: any = 0; i < controlObject.length; i++) {
            if (index !== i) {
                aux.push(controlObject[i]);
            }
        }

        for (let i: any = 0; i < arrayCoast.length; i++) {
            if (index !== i) {
                auxCoast.push(arrayCoast[i]);
            }
        }

        for (let i: any = 0; i < value.length; i++) {
            if (index !== i) {
                auxValue.push(value[i]);
            }
        }
        setControlObject(aux);
        setValue(auxValue);
        setArrayCoast(auxCoast);

        let somaValue: any = 0
        for (let i = 0; i < auxValue.length; i++) {
            somaValue += auxValue[i]
            console.log(auxValue);
            setSomaRateio(somaValue);
            //setTest(maxRateio - somaRateio)
        }
    }

    function handleChangeValue(valueRateio: any, index: any) {
        var auxRateio: any = [];
        var soma: any = 0;

        if (value.length > 1) {
            for (var i: any = 0; i < value.length; i++) {
                auxRateio.push(value[i]);

                console.log(value.length)
                if (i !== index) {
                    soma += value[i];
                }
            }

            if (soma + Number(valueRateio) >= 100) {
                auxRateio[index] = 100 - soma;
                setRateioBlocked(true);
            } else {
                auxRateio[index] = Number(valueRateio);
                setRateioBlocked(false);
            }

            setValue(auxRateio);
        } else {
            if (Number(valueRateio) >= 100) {
                setValue([100]);
                setRateioBlocked(true);
            } else {
                setValue([Number(valueRateio)]);
                setRateioBlocked(false);
            }
        }
    }

    function handleChangeCoast(auxCoastId: any, index: any) {
        let auxCoast: any = arrayCoast;
        for (let i: any = 0; i < arrayCoast.length; i++) {
            if (i === index) {
                auxCoast[i] = auxCoastId;
            }
        }

        setArrayCoast(auxCoast);
        console.log(auxCoast);
    }

    useEffect(() => {
        if (errors) {
            setResponseText("Preencha os campos!");
            setModalResponse('error');
            setLog(1);
        }
    }, [errors])

    return (
        <>
            <Form className="h-100 form-inserir" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridCat">
                                <Form.Label>Categoria</Form.Label>
                                <Controller
                                    control={control}
                                    name="categoria"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <GetAccountingAccountsFilter propsField={field} propsErrors={errors} propsLabel="Categoria" />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="categoria"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridDesc">
                                <Form.Label>Descrição</Form.Label>
                                <Controller
                                    control={control}
                                    name="descricao"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.descricao ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="descricao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} className="mb-3" controlId="formGridDate">
                                <Form.Label>Data</Form.Label>
                                <InputGroup
                                    hasValidation
                                    className=""
                                    size="sm"
                                    placeholder="Quando?"
                                >
                                    <SingleCalendar setValue={setDate} />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="formGridVenc">
                                <Form.Label>Vencimento</Form.Label>
                                <InputGroup
                                    hasValidation
                                    className=""
                                    size="sm"
                                    placeholder="Quando?"
                                >
                                    <SingleCalendar setValue={setVencimento} />
                                </InputGroup>
                            </Form.Group>
                        </Row>


                        {/*                         <Row >
                            <Form.Group as={Col} md={6}>
                                <Controller
                                    control={control}
                                    name="recorrente"
                                    //rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <div className='d-flex align-items-center' style={{ height: "86px" }}>
                                            <Form.Check
                                                {...field}
                                                type="radio"
                                                label="Pagamento Recorrente"
                                                name="formHorizontalRadios"
                                                id="formHorizontalRadios3"
                                                onClick={() => { setPagRecorrente(!pagRecorrente) }}
                                                checked={pagRecorrente}
                                            />
                                        </div>
                                    )}
                                />
                            </Form.Group>
                            {
                                pagRecorrente === true
                                    ?
                                    <>
                                        <Form.Group as={Col} md={3} className="mb-3" controlId="formGridVenc">
                                            <Form.Label>Recorrência</Form.Label>
                                            <Controller
                                                control={control}
                                                name="tipoRecorrencia"
                                                rules={{ required: { value: true, message: "required" }, }}
                                                render={({ field }: any) => (
                                                    <div className='endpoint'>
                                                        <Form.Select
                                                            {...field}
                                                            as="select"
                                                            variant="standard"
                                                            margin="normal"
                                                        >
                                                            <option value='0' selected disabled>Selecione</option>
                                                            <option value='1'>Diária</option>
                                                            <option value='2'>Semanal</option>
                                                            <option value='3'>Mensal</option>
                                                            <option value='4'>Anual</option>
                                                        </Form.Select>
                                                    </div>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="tipoRecorrencia"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={3} className="mb-3" controlId="formGridVenc">
                                            <Form.Label>Nº Ocorrencias</Form.Label>
                                            <Controller
                                                control={control}
                                                name="qtdeRecorrencia"
                                                rules={{ required: { value: true, message: "required" }, }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="number"
                                                        min="0"
                                                        aria-invalid={errors?.qtdeRecorrencia ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="qtdeRecorrencia"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </>
                                    :
                                    <></>
                            }
                        </Row> */}

                        <Row className="mb-3 d-flex align-items-center">
                            <Form.Group as={Col} md={6} controlId="formGridCat">
                                <Form.Label>Valor</Form.Label>
                                {/* <Form.Control type="text" /> */}
                                <Controller
                                    control={control}
                                    name="valor"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.valor ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="valor"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>

                        <hr style={{ margin: "30px 0" }} />

                        <Row className="mb-3">
                            <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                <Form.Label>Fornecedor</Form.Label>
                                <Controller
                                    control={control}
                                    name="fornecedor"
                                    //rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <GetSuppliersFilter
                                            propsField={field}
                                            propsErrors={errors}
                                            propsLabel="Fornecedor"
                                        />

                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="fornecedor"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={3} controlId="formGridFile">
                                <Form.Label>Anexar Documento</Form.Label>
                                <Controller
                                    control={control}
                                    name="arquivo"
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            type="file"
                                            label="arquivo"
                                            aria-invalid={errors?.arquivo ? "true" : ""}
                                            fullWidth
                                            variant="standard"
                                            margin="normal"
                                            id="arquivo"
                                            accept=".jpg"
                                            onChange={fileHandler}
                                            autoComplete='off'
                                            required 
                                            />
                                    )}
                                />
                                <label className="btn-file w-100" htmlFor="arquivo">Escolha um arquivo</label>
                            </Form.Group>
                            <div className="col-12 col-md-3 d-flex align-items-end">
                                <small style={{ lineHeight: "38px" }}>{fileName}</small>
                            </div>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md={6} className="mb-3" controlId="formGridCenter">
                                <Form.Label className="text-primary">CENTRO DE CUSTO</Form.Label>
                                {
                                    controlObject.map((obj: any, index: Key) => {
                                        return (
                                            <div className='mb-3'>
                                                <Controller
                                                    control={control}
                                                    name={`centroCusto${index}`}
                                                    //rules={{ required: { value: true, message: "required" }, }}
                                                    render={({ field }: any) => (
                                                        <GetCostCenterPay
                                                            propsField={field}
                                                            propsErrors={errors}
                                                            propsLabel="Centros de Custo"
                                                            handleChangeCoast={handleChangeCoast}
                                                            index={index}
                                                            arrayCoast={arrayCoast}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )
                                    })
                                }

                            </Form.Group>

                            <Form.Group as={Col} md={3} className="mb-3 input-rateio" controlId="formGridRat">
                                <Form.Label className="text-primary">RATEIO <span style={{ color: errorRateio === true ? "#F00" : "#6731FF" }}> (MAX: {maxRateio})</span></Form.Label>
                                {
                                    controlObject.map((obj: any, index: Key) => {
                                        return (
                                            <div className="mb-3">
                                                <Controller
                                                    control={control}
                                                    name={`rateio${index}`}
                                                    //rules={{ required: { value: true, message: "required" }, }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            aria-invalid={errors?.rateio ? "true" : ""}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                            onChange={(e: any) => handleChangeValue(e.target.value, index)}
                                                            value={value[index]}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`rateio-${index}`}
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </Form.Group>

                            <div className="mb-3 action-btn col-md-3" >
                                <label className="form-label text-primary d-flex justify-content-end">AÇÕES</label>
                                {
                                    controlObject.map((obj: any, index: Key) => {
                                        return (
                                            /* (controlObject.length - 1) !== index
                                                ? */
                                            (controlObject.length > 1)
                                                ?
                                                <>
                                                    <div className="px-2 mb-3">
                                                        <div className="d-flex justify-content-end">
                                                            <span className='btn' style={{ height: "40px" }} onClick={(e: any) => removeRow(e, index)}>
                                                                <FontAwesomeIcon
                                                                    size="lg"
                                                                    icon={["fal", "trash-alt"]}
                                                                    style={{ color: "#707070" }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            /* :
                                            <>
                                                <div className="px-2 mb-3">
                                                    <div className="d-flex justify-content-end">
                                                        <span className='btn' style={{ height: "40px" }} onClick={(e: any) => addRow()}>
                                                            <FontAwesomeIcon
                                                                size="lg"
                                                                icon={["fal", "plus"]}
                                                                style={{ color: "#707070" }}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </> */
                                        )
                                    })
                                }
                            </div>
                        </Row>

                        <Row>
                            <>
                                <div className="px-2 mb-3">
                                    <div className="d-flex justify-content-center">
                                        <span className='btn' style={{ height: "40px" }} onClick={(e: any) => addRow()}>
                                            <FontAwesomeIcon
                                                size="lg"
                                                icon={["fal", "plus"]}
                                                style={{ color: "#707070", margin: "0 12px" }}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>Observação</Form.Label>
                                <Controller
                                    control={control}
                                    name="observacao"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.observacao ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            as="textarea"
                                            rows={4}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="observacao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                    </div>
                    <ModalConfirm
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        payment="yes"
                        confirm={responseText}
                        modalContent={modalResponse}
                        log={log}
                        closeModal={closeModal}
                    />
                </div>
            </Form>
        </>
    )
}

export default Payment;