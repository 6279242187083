import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

//import bg01 from "../../assets/img/backgrounds/Bg-Login.jpg";
import AuthLogin from "./components/AuthLogin/AuthLogin";
import { Link } from "react-router-dom";

import "./Login.scss";

function Login() {
  const { t } = useTranslation();

  return (
    <Container fluid className="container-login p-0">
      <Row className="h-100 w-100 m-0">
        <Col
          sm={12}
          className="bg-login-image p-0"
          id="random"
          style={{ backgroundImage: `url(${process.env.REACT_APP_LOGIN_BANNER})` }}
        >
          <div className="split-screen">
            <Row className="h-100 w-100 m-0 content-split">
              <Col className="logo-login" sm={12} md={6}>
                <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                  <img src={process.env.REACT_APP_SERVER_LOGO_WHITE} alt="C2SIG" />
                </div>
              </Col>
              <Col sm={12} md={6} className="bg-white valign left p-0 my-auto rounded">
                <div className="inner d-flex flex-column justify-content-between h-100">
                  <div>
                    <h1>{t("login.title")}</h1>
                    <p>
                      {t("login.subTitle")}
                    </p>
                  </div>
                  <div>
                    <AuthLogin />
                    <hr style={{ backgroundColor: "#ced4da", opacity: "1" }} />
                    <div className="text-center link-login">
                      <Link className="small text-primary" to="/forgot_password">
                        {t("login.forgotPass")}
                      </Link>
                    </div>
                  </div>
                  <div className="text-center" style={{ fontSize: "10px" }}>
                    <small>{t("login.development")}</small>
                    <div className="login-brand d-flex align-items-center justify-content-center mt-1">
                      <img
                        src={process.env.REACT_APP_SERVER_LOGO}
                        alt="C2SIG"
                        className="float-left"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
