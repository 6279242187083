import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import store from './store/reducer';
import { Provider } from 'react-redux';

import ScrollToTop from "./infra/ScrollToTop/ScrollToTop";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import './assets/sass/global.scss'

import Routes from './routes';
import { Helmet } from 'react-helmet';


function App() {
  library.add(fas, fal, fad, fab);

  useEffect(() => {
    let root: any = document.querySelector(":root");
    root.style.setProperty('--primary', process.env.REACT_APP_COLOR_PRIMARY);
    root.style.setProperty('--secondary', process.env.REACT_APP_COLOR_SECONDARY);
  }, [])

  return (
    <div className="App">
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <Helmet>
              <link rel="icon" href={`${process.env.REACT_APP_FAVICON}`}></link>
            </Helmet>
            <ScrollToTop />
            <Routes />
          </BrowserRouter>
        </I18nextProvider>
      </Provider>

    </div>
  );
}

export default App;