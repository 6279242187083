import React, { useState, useEffect, Key } from 'react';
import api from '../../../../services/api';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';

import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import NumberOfPeople from '../NumberOfPeople/NumberOfPeople';
//import NumberOfPeopleTicket from '../NumberOfPeople/NumberOfPeopleTicket';

import './CorporateExtraTicket.scss';
import DoubleCalendarExtra from '../../../../components/Calendar/DoubleCalendarExtra';
import DoubleCalendarTicketExtra from '../../../../components/Calendar/DoubleCalendarTicketExtra';
import DropdownHoursEvent from '../../../../components/DropdownHours/DropdownHoursEvent';
import DropdownNumberPeopleIntegrated from '../../../../components/DropdownNumberPeopleIntegrated/DropdownNumberPeopleIntegrated';

export interface propInfo {
  id: any;
  setExtraTicket: any;
  row: any;
  reloadTable: any;
}

const CorporateExtraTicket: React.FC<propInfo> = ({
  id, setExtraTicket, row, reloadTable
}: propInfo) => {
  const supplierIdentify = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}').user.perfil.supplierIdentity;

  const [item, setItem] = useState<any>(null);
  const [, setTexts] = useState<any>('Pesquisando');
  const [resetTimer, setResetTimer] = useState<any>(1);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [, setAllowCalendar] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [date, setDate] = useState<any>();
  const [dateTour, setDateTour] = useState<any>([]);
  const [tourOptions, setTourOptions] = useState<any>(null);

  const [editSuccess, setEditSuccess] = useState<any>(false);
  const [currentEditResponse, setCurrentEditResponse] = useState<any>(null);
  const [editErrorText, setEditErrorText] = useState<any>('');

  const [loading, setLoading] = useState<any>(false);

  const [ranges, setRanges] = useState<any>(null);
  const [numberPeople, setNumberPeople] = useState<any>(null);
  const [total, setTotal] = useState<number>(0);

  const [info, setInfo] = useState<any>(null);
  const [cupomInfo, setCupomInfo] = useState<any>(null);

  const validated = false;

  useEffect(() => {
    let timer1 = setTimeout(() => setTexts('Pesquisando'), 500);
    let timer2 = setTimeout(() => setTexts('Pesquisando.'), 1000);
    let timer3 = setTimeout(() => setTexts('Pesquisando..'), 1500);
    let timer4 = setTimeout(() => setTexts('Pesquisando...'), 2000);
    let timer5 = setTimeout(() => setResetTimer(resetTimer + 1), 2000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
      clearTimeout(timer5);
    };
  }, [resetTimer]);

  const getDetails = async () => {
    setItem(null);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': '69420',
      },
    };
    try {
      const res = await api.get(
        `/NewReservations/GetNewReservationsByIdAsync/${id}`,
        config,
      );
      if (res.status !== 400) {
        setItem(res.data.data);
      }
    } catch (error: any) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let endP = '';

    if (item !== null) {
      endP =
        item.productType === 1
          ? `/Products/FetchSingleTourAsync`
          : `/Products/FetchSingleTicketAsync`;

      getIdChannel();
    }

    const getProdModCode = async (idChannel: number) => {
      try {
        const { data } = await api.post(endP, {
          productCode: item.productCode,
          lang: 'BR',
          slug: '',
          IdCanal: idChannel,
          newProduct: item.idCanal === 0 ? 1 : 0,
        });
        if (data.status !== 400) {
          setTourOptions(data.data.modalities);
          setInfo(data.data);
          setAllowCalendar(true);
          setEditProfile(true);
        }
      } catch (error) { }
    };

    async function getIdChannel() {
      const config = {
        headers: { 'ngrok-skip-browser-warning': '69420' },
      };
      try {
        const { data } = await api.get(
          `${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/${item.source}`,
          config,
        );
        if (data.statusCode === 200) {
          getProdModCode(data.data.data);
        }
      } catch (error) { }
    }
  }, [item]);

  useEffect(() => {
    var aux: any = [];
    if (tourOptions !== null) {
      for (var i = 0; i < tourOptions.length; i++) {
        aux.push({
          adult: 0,
          child: 0,
          infant: 0,
        });
      }
    }
    setDateTour(aux);
  }, [tourOptions]);

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any, isHour: any) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000,
    );

    //var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours /*  - offset */);

    if (isHour === true) {
      return newDate.toLocaleString().split(' ')[1];
    } else {
      return newDate.toLocaleString().split(' ')[0];
    }
  }

  function convertDate(cell: any, isHour: any = false) {
    var date = convertUTCDateToLocalDate(new Date(cell), false).replaceAll(
      ',',
      '',
    );
    var hours = convertUTCDateToLocalDate(new Date(cell), true);
    if (isHour === true) {
      return <>{hours}</>;
    } else {
      return <>{date}</>;
    }
  }
  /*converte a data UTC para a data local do usuário*/

  function changePriceDateTour(obj: any) {
    setDateTour(obj);
    setDate(obj[0].data);
  }

  const {
    handleSubmit,
    //formState: { errors },
  } = useForm();

  const onSubmit = (res: any) => {
    setLoading(true);
    setEditSuccess(true);
    setCurrentEditResponse('loading');

    const date: any = new Date();
    const today: string = date.toISOString()
    const todayFormatter: string = today.split("T")[0];

    let body: object = {
      "agent": supplierIdentify || "CPA3120",
      "discount": "0",
      "foreign": 2,
      "language": 1,
      "resellerReference": item.resellerReference,
      "source": item.source.toLowerCase(),
      "idCanal": item?.idCanal,
      "event": {
        "id": item?.event?.id || 0,
        "idBooking": 0,
        "eventName": item?.event?.eventName,
        "eventDescription": item.event?.eventDescription,
        "dateStart": item?.event?.dateStart,
        "dateEnd": item?.event?.dateEnd,
        "useStock": item?.event?.useStock
      },
      "dados": [
        {
          "productName": info.productNameBR,
          "productNameEN": info.productNameEN,
          "productNameES": info.productNameES,
          "modalityName": info.modalityBR || "null",
          "typeProduct": info?.typeProduct,
          "price": dateTour[0]?.adult,
          "idTarif": +item.idTarifario,
          "priceAdults": dateTour[0]?.adult,
          "priceChilds": dateTour[0]?.child,
          "priceInfants": dateTour[0]?.infant,
          "priceElders": dateTour[0]?.elders,
          "priceStudent": dateTour[0]?.student,
          "priceGlobalPeople": dateTour[0]?.global,
          "sellingType": item?.sellingType,
          "adults": numberPeople?.adults,
          "childs": numberPeople?.childs,
          "infants": numberPeople?.infants,
          "elders": numberPeople?.elders,
          "student": numberPeople?.student,
          "globalPeople": numberPeople?.globalPeople,
          "productType": item?.productType,
          "productCode": item?.productCode,
          "time": item?.startTimeLocal.split("T")[1],
          "date": item?.startTimeLocal.split("T")[0],
          "supplier": info.supplier,
          "supplierFantasyName": info?.supplierFantasyName,
          "typePickup": info.modalities[0].embarkingType,
          "meetingPoint": info.modalities[0].embarkingType === "0" ? info.meetingPoint : '',
          "pickupListId": info.modalities[0].embarkingType === "0" ? 0 : info.pickupListId,
          "discount": 0,
          "customValueNet": 0,
          "customValueSell": 0,
          "goingSource": "null",
          "commingSource": "null",
          "latOrigem": "null",
          "lngOrigem": "null",
          "latDestino": "null",
          "lngDestino": "null",
          "cia": "null",
          "voo": "null",
          "smallSuitcase": 0,
          "bigSuitcase": 0,
          "idVeiculo": 0,
          "passengers": [
            {
              "firstName": "Venda Evento",
              "lastName": "Evento",
              "email": "Eventos@bondinho.local",
              "phone": "00000000000",
              "DocumentNumber": "75180475007" // Alterar após ajuste do BackEnd
            }
          ],
          "isEvent": true,
          "daysValidity": item.daysValidity,
        }
      ],
      "payments": [
        {
          "DocumentNumber": "751.804.750-07", // Alterar após ajuste do BackEnd
          "SessionIdKoin": "",
          "datePayment": todayFormatter,
          "installments": 1,
          "MerchantPayMethod": "0",
          "payMethod": 97,
          "value": total
        }
      ],
      "cupom": cupomInfo === null ? { type: 0 } : cupomInfo,
      "city": "Rio de Janeiro",
      "state": "RJ",
      "idCoporation": row.idCoporation, // Falta o backend mandar essa informação na API.
      "totalCart": total,
    }

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '69420',
      },
    };

    const createReservation = async () => {
      try {
        const { data } = await api.post(`/Checkout/EnviaCheckoutAuth`, body, config);

        if (data.status !== 400) {
          if (data.data.log === 1 || data.data.log === '1') {
            setCurrentEditResponse('error');
            setEditErrorText(data.data.texto);
            setLoading(false);
          } else {
            setCurrentEditResponse('success');
            setEditErrorText(data.data.texto);
          }
          // window.location.reload();
        }
      } catch (error: any) {

        setCurrentEditResponse('error');
        setEditErrorText(error?.response?.data?.errorMessage);
        setLoading(false);
      }
    };
    createReservation();
  };

  const setNewNumberPeople = (peoples: any) => {
    setNumberPeople(peoples);
  };

  useEffect(() => {
    if (item?.cupom !== null) {
      const cupomDescount = async () => {
        try {
          const { data } = await api.get(`/TourCode/ValidateCoupon/${item.cupom}`);

          if (data.status !== 400) {
            if (data.data.log === 1 || data.data.log === '1') {
              setCurrentEditResponse('error');
              setEditErrorText(data.data.texto);
              setLoading(false);
            } else {
              setCupomInfo(data.data);
              setCurrentEditResponse('success');
              setEditErrorText(data.data.texto);
            }
          }
        } catch (error: any) { }
      }
      cupomDescount();
    }

  }, [item])

  useEffect(() => {
    let priceTotal: number = 0;

    if (numberPeople !== null && dateTour[0]) {
      priceTotal = (Number(numberPeople?.adults || 0) * Number(dateTour[0]?.adult || 0)) + (Number(numberPeople?.childs || 0) * Number(dateTour[0]?.child || 0)) + (Number(numberPeople?.infants || 0) * Number(dateTour[0]?.infant || 0)) + (Number(numberPeople?.elders || 0) * Number(dateTour[0]?.elders || 0)) + (Number(numberPeople?.student || 0) * Number(dateTour[0]?.student || 0)) + (Number(numberPeople?.globalPeople || 0) * Number(dateTour[0]?.global || 0));

      if (cupomInfo?.typeDiscount === 2) {
        priceTotal = Number(priceTotal) - (cupomInfo?.valueDiscount);
      } else if (cupomInfo?.typeDiscount === 4) {
        priceTotal = Number(priceTotal) - ((priceTotal * (cupomInfo?.valueDiscount / 100)));
      }

      setTotal(priceTotal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberPeople, dateTour]);

  if (item !== null && tourOptions !== null) {
    if (item?.event !== null) {
      return (
        <>
          <div>
            <Form
              className="modal-content"
              noValidate
              validated={validated}
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="false"
            >
              <Modal.Body>
                <div className="modal-header rounded-0" style={{ backgroundImage: `url(${info?.imagesBaseUrl}${info?.images[0]})` }}>
                  <div className='d-flex flex-column justify-content-between w-100 h-100' style={{ zIndex: 9999 }}>
                    <div className='d-flex justify-content-between w-100'>
                      <div>
                        <h4 className="pt-0 title-reservation mb-0" style={{ color: "#FFFFFF" }}>
                          {item.productName}
                        </h4>
                        <small>{tourOptions[0]?.modalityName}</small>
                      </div>
                      <div className="buttons-options" onClick={() => setExtraTicket(false)}>
                        <div>
                          <FontAwesomeIcon
                            icon={['fal', 'times']}
                            className="mx-3 text-info"
                            size="lg"
                            data-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                      </div>
                    </div>
  
                    <Row className="p-0 m-0 mt-0">
                      <span className="subtitle-modal">Cliente</span>
                      <span className="response-modal">
                        <b>{row?.companyName}</b>
                      </span>
                    </Row>
                  </div>
                </div>
  
                <div className="w-100 d-flex flex-column justify-content-between h-100" style={{ padding: "2rem 2rem 0" }}>
                  <div>
                    <Row className="p-0 m-0 mt-2">
                      <h6 className='mb-3'>Detalhes da Solicitação</h6>
                      <Col md={4}>
                        <span className="subtitle-modal">Data</span>
                        {editProfile === false ? (
                          <span className="response-modal">
                            <b>
                              {item.startTimeLocal
                                .split('T')[0]
                                .split('-')
                                .reverse()
                                .join('/')}
                            </b>
                          </span>
                        ) : (
                          <>
                            <Form.Group as={Col} md="12">
                              <InputGroup
                                hasValidation
                                className=""
                                size="sm"
                                placeholder="Quando?"
                                id="date-"
                              >
                                {item.productType === 1 ? (
                                  <DoubleCalendarExtra
                                    modalityID={item.idModalidade}
                                    product={tourOptions[0]}
                                    productCode={item.productCode}
                                    changePriceDateTour={changePriceDateTour}
                                    dateTour={dateTour}
                                    index={0}
                                    startTimeLocal={convertDate(
                                      item.startTimeLocal,
                                    )}
                                    item={item}
                                    setRanges={setRanges}
                                  ></DoubleCalendarExtra>
                                ) : (
                                  <DoubleCalendarTicketExtra
                                    modalityID={item.idModalidade}
                                    product={tourOptions[0]}
                                    productCode={item.productCode}
                                    changePriceDateTour={changePriceDateTour}
                                    dateTour={dateTour}
                                    index={0}
                                    startTimeLocal={convertDate(
                                      item.startTimeLocal,
                                    )}
                                    item={item}
                                    setRanges={setRanges}
                                  ></DoubleCalendarTicketExtra>
                                )}
                              </InputGroup>
                            </Form.Group>
                          </>
                        )}
                      </Col>
                      <Col md={4}>
                        <span className="subtitle-modal">Hora</span>
                        <DropdownHoursEvent
                          changePriceDateTour={changePriceDateTour}
                          dateTour={dateTour}
                          isCorp={true}
                          item={item}
                        />
                      </Col>
                      <Col md={4}>
                        <span className="subtitle-modal">Bilhetes Extras</span>
                        {
                          ranges !== null
                            ?
                            <div className="panel-dropdown bg-white">
                              <DropdownNumberPeopleIntegrated
                                actionPeople={setNewNumberPeople}
                                //info={tourDetails}
                                dateTour={dateTour}
                                //index={index}
                                ranges={ranges}
                              //isIntegration={isIntegration}
                              />
                            </div>
                            :
                            ""
                        }
                      </Col>
                      <Col md={4} className='mt-3'>
                        <span className="subtitle-modal">Validade</span>
                        <span className="response-modal">
                          <b>{item?.daysValidity} dias</b>
                        </span>
                      </Col>
                      <Col md={4} className='mt-3'>
                        <span className="subtitle-modal">Consome do Estoque?</span>
                        <span className="response-modal">
                          <b>{item?.event?.useStock ? "Sim" : "Não"}</b>
                        </span>
                      </Col>
                    </Row>
  
                    {
                      item.event !== null
                        ?
                        <Row className="p-0 m-0 mt-5">
                          <h6 className="mb-3">Informações do Evento</h6>
                          <Col md={4}>
                            <span className="subtitle-modal">Nome</span>
                            <span className="response-modal">
                              <b>{item?.event?.eventName}</b>
                            </span>
                          </Col>
                          <Col md={4}>
                            <span className="subtitle-modal">Data Inicial</span>
                            <span className="response-modal">
                              <b>{item?.event?.dateStart.split("T")[0].split("-").reverse().join("/") + " " + item?.event?.dateStart.split("T")[1]}</b>
                            </span>
                          </Col>
                          <Col md={4}>
                            <span className="subtitle-modal">Data Final</span>
                            <span className="response-modal">
                              <b>{item?.event?.dateEnd.split("T")[0].split("-").reverse().join("/") + " " + item?.event?.dateStart.split("T")[1]}</b>
                            </span>
                          </Col>
                          <Col md={4} className='mt-4'>
                            <span className="subtitle-modal">Descrição</span>
                            <span className="response-modal">
                              <b>{item?.event?.eventDescription}</b>
                            </span>
                          </Col>
                        </Row>
                        :
                        <Row className="p-0 m-0 mt-5">
                          <span className="mb-3">Não há informações sobre o evento</span>
                        </Row>
                    }
                  </div>
  
                  <Row className="p-0 m-0 mt-5">
                    <div className='d-flex justify-content-between align-items-center'>
                      <h6 className='mb-0'>SubTotal</h6>
                      <span className="response-modal d-flex flex-column text-end">
                        {cupomInfo !== null && total > 0 ? <small>Desconto <span>{cupomInfo?.valueDiscount}%</span></small> : <></>}
                        <b>
                          {total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </b>
                      </span>
                    </div>
                  </Row>
                </div>
              </Modal.Body>
  
              {editProfile === false ? (
                <></>
              ) : (
                <Modal.Footer>
                  <div className="modal-footer rounded-0 border-0">
                    <button
                      type="button"
                      className="btn btn-outline-primary rounded-0"
                      data-dismiss="modal"
                      onClick={() => setExtraTicket(false)}
                    >
                      <i className="fas fa-times fa-fw"></i>{' '}
                      <span data-vouchercampo="close">Cancelar</span>
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary rounded-0 details-reservations-submit-button"
                    >
                      {loading === true ? (
                        <div className="load"></div>
                      ) : (
                        'Confirmar'
                      )}
                    </button>
                  </div>
                </Modal.Footer>
              )}
            </Form>
          </div>
          <Modal
            className="modal-custom-sm modal-confirm bg-modal-custom modal-cancel-reservation"
            show={editSuccess !== false}
            onHide={() => {
              setEditSuccess(false);
              setEditProfile(false);
              getDetails();
              currentEditResponse === 'success' ? (
                <></>
              ) : (
                setCurrentEditResponse(null)
              );
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <div className="">
              {currentEditResponse === 'success' ? (
                <Modal.Body className="center">
                  <div className="sucess-pos">
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={['fal', 'check-circle']}
                        size="5x"
                        style={{ fontSize: '7.5em' }}
                        className='mb-5'
                      />
                      <h5>Parabéns</h5>
                      <p>{editErrorText}</p>
                    </div>
                    <Button
                      className="my-3 mx-auto"
                      onClick={() => {
                        setEditSuccess(false);
                        setEditProfile(false);
                        getDetails();
                        setExtraTicket(false);
                        reloadTable();
                      }}
                    >
                      Fechar
                    </Button>
                  </div>
                </Modal.Body>
              ) : currentEditResponse === 'error' ? (
                <Modal.Body className="center">
                  <div className="sucess-pos">
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={['fal', 'times-circle']}
                        size="5x"
                        style={{ fontSize: '7.5em' }}
                        className='mb-5'
                      />
                      <h5>Error</h5>
                      <p>{editErrorText}</p>
                    </div>
                    <Button
                      className="my-5 mx-auto"
                      onClick={() => setEditSuccess(false)}
                    >
                      Fechar
                    </Button>
                  </div>
                </Modal.Body>
              ) : (
                <Modal.Body className="center">
                  <div className="loading">
                    <div className="text-center">
                      <div className="load-big mb-3"></div>
                      <h5>Carregando</h5>
                    </div>
                  </div>
                </Modal.Body>
              )}
            </div>
          </Modal>
        </>
      );
    } else {
      return (
        <>
          <div>
            <Form
              className="modal-content"
              noValidate
              validated={validated}
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="false"
            >
              <Modal.Body>
                <div className="modal-header rounded-0" style={{ backgroundImage: `url(${info?.imagesBaseUrl}${info?.images[0]})` }}>
                  <div className='d-flex flex-column justify-content-between w-100 h-100' style={{ zIndex: 9999 }}>
                    <div className='d-flex justify-content-between w-100'>
                      <div>
                        <h4 className="pt-0 title-reservation mb-0" style={{ color: "#FFFFFF" }}>
                          {item.productName}
                        </h4>
                        <small>{tourOptions[0]?.modalityName}</small>
                      </div>
                      <div className="buttons-options" onClick={() => setExtraTicket(false)}>
                        <div>
                          <FontAwesomeIcon
                            icon={['fal', 'times']}
                            className="mx-3 text-info"
                            size="lg"
                            data-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                      </div>
                    </div>
  
                    <Row className="p-0 m-0 mt-0">
                      <span className="subtitle-modal">Cliente</span>
                      <span className="response-modal">
                        <b>{row?.companyName}</b>
                      </span>
                    </Row>
                  </div>
                </div>
  
                <div className="w-100 d-flex flex-column justify-content-center align-items-center h-100" style={{ padding: "2rem 2rem 0" }}>
                    <h4>Não há informações do evento!</h4>
                    <p>Por favor, entre em contato com o nosso suporte técnico.</p>
                </div>
              </Modal.Body>
  
              {editProfile === false ? (
                <></>
              ) : (
                <Modal.Footer>
                  <div className="modal-footer rounded-0 border-0">
                    <button
                      type="button"
                      className="btn btn-outline-primary rounded-0"
                      data-dismiss="modal"
                      onClick={() => setExtraTicket(false)}
                    >
                      <i className="fas fa-times fa-fw"></i>{' '}
                      <span data-vouchercampo="close">Cancelar</span>
                    </button>
                  </div>
                </Modal.Footer>
              )}
            </Form>
          </div>
        </>
      );
    }
  } else {
    return (
      <>
        <Modal.Body>
          <div className="modal-header rounded-0">
            <div>
              <div
                className="pt-1 text-primary title-reservation animated-background"
                style={{ height: '30px', width: '500px' }}
              ></div>
              <small></small>
            </div>

            <div className="buttons-options">
              <div></div>
              <div>
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  className="mx-3 text-info"
                  size="lg"
                  data-dismiss="modal"
                  aria-label="Close"
                />
              </div>
            </div>
          </div>

          <hr className="w-100 my-4" style={{ borderBottom: '1px solid' }} />

          <div className="w-100 p-4">
            <Row className="p-0 m-0">
              <Col md={2}>
                <div
                  className=" animated-background"
                  style={{ height: '18px', width: '80%' }}
                ></div>
              </Col>
              <Col md={3}>
                <div
                  className=" animated-background"
                  style={{ height: '18px', width: '80%' }}
                ></div>
              </Col>
              <div className="divider"></div>
              <Col md={6} className="p-0 m-0 pl-4">
                <div
                  className=" animated-background"
                  style={{ height: '18px', width: '80%' }}
                ></div>
              </Col>
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  className="mb-3 animated-background"
                  style={{ height: '24px', width: '200px' }}
                ></div>
                <div className="d-flex">
                  <Col
                    md={3}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                  <Col md={3}>
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                  <Col md={3}>
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                </div>
              </div>
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  className="mb-3 animated-background"
                  style={{ height: '24px', width: '200px' }}
                ></div>
                <div className="d-flex">
                  <Col
                    md={3}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                  <Col md={3}>
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                  <Col md={3}>
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                  <Col md={3}>
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                </div>
              </div>
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div
                className="mb-3 animated-background"
                style={{ height: '24px', width: '200px' }}
              ></div>
              {[1, 2, 3, 4, 5].length > 0
                ? [1, 2, 3, 4, 5].map((unit: any, index: Key) => {
                  return (
                    <div
                      className="my-3"
                      key={index}
                      style={{ display: 'flex' }}
                    >
                      <span className=" mr-2">
                        <div
                          className="mb-3 animated-background"
                          style={{
                            height: '50px',
                            width: '50px',
                            borderRadius: '50%',
                          }}
                        ></div>
                      </span>
                      <span>
                        <div
                          className="mb-3 animated-background"
                          style={{ height: '24px', width: '100px' }}
                        ></div>
                        <div
                          className=" animated-background"
                          style={{ height: '12px', width: '200px' }}
                        ></div>
                      </span>
                    </div>
                  );
                })
                : ''}
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div
                className="mb-3 animated-background"
                style={{ height: '24px', width: '200px' }}
              ></div>
              <div className="d-flex">
                <Col
                  md={3}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
                <Col md={3}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
                <Col md={3}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
                <Col md={3}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
              </div>
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div
                className="mb-3 animated-background"
                style={{ height: '24px', width: '200px' }}
              ></div>
              <div className="d-flex">
                <Col
                  md={3}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
                <Col md={3}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
                <Col md={3}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
              </div>
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div
                className="mb-3 animated-background"
                style={{ height: '24px', width: '200px' }}
              ></div>
              <div className="d-flex">
                <Col
                  md={6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '120px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '300px' }}
                  ></div>
                </Col>
                <Col md={6}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '120px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '300px' }}
                  ></div>
                </Col>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </>
    );
  }
};

export default CorporateExtraTicket;
