import React, { useState } from "react";

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import GetBilheteriaLogGroup from "../../../../components/C2Points/GetBilheteriaLogGroup";

export interface propFilter {
    buscar: any,
};

const TransactionAuditFilter: React.FC<propFilter> = ({
    buscar
}: propFilter) => {
    const { t } = useTranslation();
    const [filterLog, setFilterLog] = useState<any>('')
    const [filterDate, setFilterDate] = useState<any>('');
    const [filterReference, setFilterReference] = useState<any>('');
    const [filterLevel, setFilterLevel] = useState<any>(0);

    const {
        control,
        //handleSubmit,
        //register,
        //getValues,
        //formState: { errors },
    } = useForm({});

    const handleFilterClick = () => {
        //let data: any = getValues();

        buscar(filterLog, filterDate, filterReference, filterLevel);
        //console.log(filterName, filterState, filterType, filterStatus, filterDate)
    };

    const handleClearClick = (event: any) => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "";
        }

        setFilterLog("");
        setFilterDate("");
        setFilterReference("");
        setFilterLevel(0);
        buscar("", "", "", 0);
    };

    const handleChangeReference = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterReference(newValue);
    }

    const handleChangeLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterLevel(newValue);
    }

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <FontAwesomeIcon
                            icon={['fal', 'sliders-h']}
                            size="lg"
                            className="mr-2"
                        />
                        <span className="h5 mb-0">
                            Buscar transações
                        </span>
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Form>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Log</Form.Label>
                                    <Controller
                                        control={control}
                                        name="log"
                                        render={({ field }: any) => (
                                            <div className='endpoint'>
                                                <GetBilheteriaLogGroup propsField={field} setFilterLog={setFilterLog} />
                                            </div>
                                        )}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="2">
                                    <Form.Label>Referência</Form.Label>
                                    <Controller
                                        control={control}
                                        name="reference"
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="text"
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                onChange={handleChangeReference}
                                                value={filterReference}
                                            />
                                        )}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="2">
                                    <Form.Label>Data da Consulta</Form.Label>
                                    <InputGroup hasValidation className="ranger-calendar">
                                        <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                    </InputGroup>
                                </Form.Group>


                                <Form.Group as={Col} md="2">
                                    <Form.Label>Tipo</Form.Label>
                                    <Controller
                                        control={control}
                                        name="type"
                                        render={({ field }: any) => (
                                            <div className='endpoint'>
                                                <Form.Select
                                                    {...field}
                                                    label="Tipo"
                                                    as="select"
                                                    variant="standard"
                                                    margin="normal"
                                                    onChange={(e: any) => handleChangeLevel(e)}
                                                >
                                                    <option value='' selected>Todos</option>
                                                    <option value='1'>Informação</option>
                                                    <option value='2'>Erro</option>
                                                </Form.Select>
                                            </div>
                                        )}
                                    />
                                </Form.Group>
                            </Row>
                            <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                <Col xs lg="12" className="d-flex justify-content-md-end">
                                    <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('reservations.btnClear')}</Button>
                                    <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('reservations.btnSearch')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default TransactionAuditFilter;