import React, { Key } from "react";
import Table from "react-bootstrap/Table";
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";

import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./SumOfTheMonth.scss";

export interface propTable {
  totalRecebimentos: any;
  totalPagamentos: any;
  totalMargem: any;
  taxaMargem: any;
  totalNotasEmitidasMes: any;
  totalNotasEmitidasDia: any;
  totalNotasEmitirDia: any;
  diaFechado: any;
  issuePendInvoices: any;
  loading: any;
}

const SumOfTheMonth: React.FC<propTable> = ({
  totalRecebimentos, totalPagamentos, totalMargem, taxaMargem,
  totalNotasEmitidasMes, totalNotasEmitidasDia, totalNotasEmitirDia,
  diaFechado, issuePendInvoices, loading
}: propTable) => {
  const { t } = useTranslation();
  //console.log(props.suppliers[0].dataCadastro.split('T')[0])

  function handleIssuePendInvoices(e: any) {
    issuePendInvoices();
  }

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any, row: any) {
    var date = convertUTCDateToLocalDate(new Date(cell));
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any, casasDecimais: any=2) {

    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(casasDecimais).replace(".", ",")}</span>
      </>
    )
  }

  return (
    <>
      <div className="mb-5">
        <Row className="suppliers mt-4 d-flex justify-content-between align-items-center">
          <h3 className="" style={{ color: "#707070" }}>Acumulado do Mês</h3>
        </Row>
        <Row className="mt-3">
          <div className="table-default sum-of-the-month-table">
            <div className="table-container">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Total Recebimentos</th>
                    <th>Total Pagamentos</th>
                    <th>Margem</th>
                    <th>Margem / Receita (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {loading === false ?
                  <tr>
                    <td>{convertValue(totalRecebimentos,2)}</td>
                    <td>{convertValue(totalPagamentos,2)}</td>
                    <td>{convertValue(totalMargem,2)}</td>
                    <td>{convertValue(taxaMargem)}</td>
                  </tr>
                  :
                  <tr>
                    <td><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></td>
                    <td><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></td>
                    <td><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></td>
                    <td><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></td>
                  </tr>
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
        <Row className="mt-3 pt-4 border-top">
          <h4>
            <div className="d-flex">
              <div className="mr-2">Notas Fiscais Emitidas no Mês:</div>
              {loading === false ? <div className="font-weight-bold">{convertValue(totalNotasEmitidasMes)}</div> : <div className="animated-background" style={{height: "2rem", width: "8rem", float: "right"}}></div>}
            </div>
          </h4>
        </Row>
        <Row className="mt-3 pt-4 border-top">
          {loading === false ?
            (diaFechado === 2 ?
            <h4>Notas Fiscais a Emitir: <span className="font-weight-bold">{convertValue(totalNotasEmitirDia)}</span></h4>
            :
            <h4>Notas Fiscais Emitidas no Dia: <span className="font-weight-bold">{convertValue(totalNotasEmitidasDia)}</span></h4>
            )
            :
            <h4><div className="animated-background" style={{height: "2rem", width: "100%"}}></div></h4>
          }
        </Row>
        {loading === false ?
        <Row className="mt-4 pt-4 text-center border-top">
          {diaFechado === 2 ?
          <div>
            <Button onClick={handleIssuePendInvoices}>Emitir Notas Pendentes</Button>
            {/* <h5>Emitir Notas Pendentes e Encerrar o Faturamento do dia?</h5>
                          <div>
                              <Button className="m-2">Sim</Button><Button className="m-2">Não</Button>
                          </div> */}
          </div>
          :
          <h5>Faturamento Encerrado!</h5>
          }
        </Row>
        : <></>}
      </div>
    </>
  );
}

export default SumOfTheMonth;
