import React, { useState, useEffect, useRef } from "react";

// import { useTranslation } from "react-i18next";

import "./DropdownNumberPeopleIntegrated.scss";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import people from "../../assets/icons/majesticons_users-line.svg";
import iconArrow from '../../assets/icons/arrow_white.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import SimpleAlert from "../Alert/SimpleAlert/SimpleAlert";
//import SimpleAlert from "../Alert/SimpleAlert/SimpleAlert";

export interface propsR {
    tariffs?: any;
    actionPeople?: any;
    info?: any;
    dateTour?: any;
    index?: any;
    isIntegration?: any;
    ranges?: any
}

const DropdownNumberPeopleIntegrated: React.FC<propsR> = ({
    tariffs = null,
    actionPeople,
    info,
    dateTour,
    index = 0,
    isIntegration = false,
    ranges
}: propsR) => {
    const [numberAdults, setNumberAdults] = useState<number>(0);
    const [numberChilds, setNumberChilds] = useState<number>(0);
    const [numberInfants, setNumberInfants] = useState<number>(0);
    const [numberStudent, setNumberStudent] = useState<number>(0);
    const [numberElders, setNumberElders] = useState<number>(0);
    const [numberPeople, setNumberPeople] = useState<number>(1);
    const [numberStockTotal, setNumberStockTotal] = useState<number>(1);
    const [numberTotal, setNumberTotal] = useState<number>(1);
    const [numberTotalPeople, setNumberTotalPeople] = useState<number>(1);

    const [isOpen, setIsOpen] = useState<any>(false);

    const { t } = useTranslation();

    /* Detecta clique fora da div#wrapper para fechar*/

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* Detecta clique fora da div#wrapper para fechar\\ */

    const handleClickPeoples = () => {
        isOpen === false ? setIsOpen(true) : setIsOpen(false);
    };

    const handleClick = (controll: string, faixa: string) => {
        let stateNumber: any;

        if (faixa === "Adult") {
            stateNumber = numberAdults;
        } else if (faixa === "Child") {
            stateNumber = numberChilds;
        } else if (faixa === "Infant") {
            stateNumber = numberInfants;
        } else if (faixa === "Elders") {
            stateNumber = numberElders;
        } else if (faixa === "Student") {
            stateNumber = numberStudent;
        } else {
            stateNumber = numberPeople;
        }

        let newNumber = numberAdults;

        if (controll === "sub") {
            if (stateNumber === 0) {
                return false;
            } else {
                newNumber = stateNumber - ranges[`increment${faixa}`];
                if (ranges[`flagStock${faixa}`] === 1) {
                    setNumberStockTotal(numberStockTotal - ranges[`increment${faixa}`]);
                }
            }
        }
        if (controll === "plus") {
            if (ranges[`flagStock${faixa}`] === 1) {
                if (numberStockTotal + ranges[`increment${faixa}`] <= (dateTour[index]?.maxStockNumber)) {
                    newNumber = stateNumber + ranges[`increment${faixa}`];
                    setNumberStockTotal(numberStockTotal + ranges[`increment${faixa}`]);
                } else {
                    return false;
                }
            } else {
                newNumber = stateNumber + ranges[`increment${faixa}`];
            }
        }

        if (faixa === "Adult") {
            return setNumberAdults(newNumber);
        } else if (faixa === "Child") {
            return setNumberChilds(newNumber);
        } else if (faixa === "Infant") {
            return setNumberInfants(newNumber);
        } else if (faixa === "Elders") {
            return setNumberElders(newNumber);
        } else if (faixa === "Student") {
            return setNumberStudent(newNumber);
        } else {
            return setNumberPeople(newNumber);
        }
    };

    useEffect(() => {
        setNumberTotal(numberAdults + numberChilds + numberInfants + numberStudent + numberElders)
        setNumberTotalPeople(numberPeople)
        const numberSend = {
            adults: dateTour[index]?.idSellingType === 1 ? 0 : numberAdults,
            childs: numberChilds,
            infants: numberInfants,
            elders: numberElders,
            student: numberStudent,
            globalPeople: dateTour[index]?.idSellingType === 2 ? 0 : numberPeople,
        };
        actionPeople(numberSend, index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberAdults, numberChilds, numberInfants, numberPeople, numberStudent, numberElders]);

    useEffect(() => {
        setNumberAdults(0);
        setNumberChilds(0);
        setNumberInfants(0);
        setNumberStudent(0);
        setNumberElders(0);
        setNumberPeople(0);
        setNumberStockTotal(0)
        setNumberTotal(0);
        setNumberTotalPeople(0);

        if (isIntegration === true) {
            dateTour[index].maxStockNumber = 9999999999;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTour[index]?.time, dateTour[index]?.data])

    const handleClickLabels = (e: any, faixa: string) => {
        if ((Number(e.target.value) <= (dateTour[index]?.maxStockNumber - (
            (ranges.flagStockAdult === 1 && faixa !== 'Adult' ? numberAdults : 0) +
            (ranges.flagStockChild === 1 && faixa !== 'Child' ? numberChilds : 0) +
            (ranges.flagStockInfant === 1 && faixa !== 'Infant' ? numberInfants : 0) +
            (ranges.flagStockElders === 1 && faixa !== 'Elders' ? numberElders : 0) +
            (ranges.flagStockStudent === 1 && faixa !== 'Student' ? numberStudent : 0)))) || ranges[`flagStock${faixa}`] === 2) {

            if (faixa === "Adult") {
                setNumberAdults(Number(e.target.value));
            } else if (faixa === "Child") {
                setNumberChilds(Number(e.target.value));
            } else if (faixa === "Infant") {
                setNumberInfants(Number(e.target.value));
            } else if (faixa === "Elders") {
                setNumberElders(Number(e.target.value));
            } else if (faixa === "Student") {
                setNumberStudent(Number(e.target.value));
            } else {
                setNumberPeople(Number(e.target.value));
            }
        } else {
            let funcNumberTotal: any = dateTour[index]?.maxStockNumber - (
                (ranges.flagStockAdult === 1 && faixa !== 'Adult' ? numberAdults : 0) +
                (ranges.flagStockChild === 1 && faixa !== 'Child' ? numberChilds : 0) +
                (ranges.flagStockInfant === 1 && faixa !== 'Infant' ? numberInfants : 0) +
                (ranges.flagStockElders === 1 && faixa !== 'Elders' ? numberElders : 0) +
                (ranges.flagStockStudent === 1 && faixa !== 'Student' ? numberStudent : 0));

            if (faixa === "Adult") {
                setNumberAdults(funcNumberTotal);
            } else if (faixa === "Child") {
                setNumberChilds(funcNumberTotal);
            } else if (faixa === "Infant") {
                setNumberInfants(funcNumberTotal);
            } else if (faixa === "Elders") {
                setNumberElders(funcNumberTotal);
            } else if (faixa === "Student") {
                setNumberStudent(funcNumberTotal);
            } else {
                setNumberPeople(funcNumberTotal);
            }
        }
    }

    if (isOpen === true) {
        console.log(dateTour[index]?.idSellingType)
        return (
            <div id="wrapper" ref={wrapperRef} className="input-people" translate="no">
                <div style={{ cursor: "pointer" }} onClick={() => handleClickPeoples()}>
                    <FontAwesomeIcon
                        icon={["fal", "male"]}
                        size="1x"
                        className="mr-2"
                        style={{ marginLeft: "3.25px" }}
                    />
                    <span style={{ marginLeft: "20px" }}>
                        {t("sales.tourList.accordion.people")}{" "}
                    </span>
                    <span
                        className="qtyTotal"
                        data-min="2"
                        data-max="19"
                        style={{ marginLeft: "5px" }}
                    >
                        {dateTour[index]?.idSellingType === 2
                            ? numberTotal
                            : dateTour[index]?.idSellingType === 1
                                ? numberTotalPeople
                                : ""}
                    </span>
                    <span style={{ marginLeft: "5px", color: "#FFF" }}>
                        {dateTour[index]?.idSellingType === 2
                            ? t("sales.tourList.accordion.passengers")
                            : dateTour[index]?.idSellingType === 1
                                ? t("sales.tourList.accordion.people")
                                : t("sales.tourList.accordion.passengers")}
                    </span>
                    <span className="input-calendar-custom arrow py-0 py-md-2" id="basic-addon1">
                        <img src={iconArrow} alt="pesquisar" className="py-1" />
                    </span>
                </div>
                <div className="panel-dropdown-content right active">
                    <div translate="no">
                        {
                            ranges.labelsRealSorted.map((faixa: any, indexRange: any) => {
                                let stateNumber: any;

                                if (faixa === "Adult") {
                                    stateNumber = numberAdults;
                                } else if (faixa === "Child") {
                                    stateNumber = numberChilds;
                                } else if (faixa === "Infant") {
                                    stateNumber = numberInfants;
                                } else if (faixa === "Elders") {
                                    stateNumber = numberElders;
                                } else if (faixa === "Student") {
                                    stateNumber = numberStudent;
                                } else {
                                    stateNumber = numberPeople
                                }

                                return (
                                    <div key={indexRange}>
                                        {ranges[`isActive${faixa}`] === true
                                            ?
                                            <div className="qtyButtons">
                                                <label>
                                                    {ranges[`label${faixa}`]}
                                                    <small id="idade-crianca" style={{ fontSize: '9px' }}> {(dateTour[index][`${faixa.toLowerCase()}Text`])}</small>
                                                    {
                                                        faixa === "Private"
                                                            ?
                                                            <span
                                                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                                                id="valor-adulto-sm"
                                                            >
                                                                R$ {(dateTour[index].global).toFixed(2).split(".").join(",")}
                                                            </span>
                                                            :
                                                            <span
                                                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                                                id="valor-adulto-sm"
                                                            >
                                                                R$ {(dateTour[index][`${faixa.toLowerCase()}`]).toFixed(2).split(".").join(",")}
                                                            </span>
                                                    }
                                                </label>
                                                <div className="d-flex">
                                                    <div className="qtyDec" onClick={() => handleClick("sub", faixa)}>
                                                        -
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="qtyInput"
                                                        value={stateNumber}
                                                        onChange={(e: any) => handleClickLabels(e, faixa)}
                                                        id="numero-people"
                                                        autoComplete='off'
                                                    />
                                                    <div className="qtyInc" onClick={() => handleClick("plus", faixa)}>
                                                        +
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                )
                            })
                        }

                        {numberStockTotal >= dateTour[index]?.maxStockNumber
                            ?
                            <SimpleAlert alertType="danger text-center mt-2">
                                Limite do estoque atingido
                            </SimpleAlert>
                            :
                            <></>
                        }

                    </div>
                    <p id="alertNumber"></p>
                </div>
            </div>
        );
    } else {
        return (
            <>
                <div className="input-people">
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickPeoples()}>
                        <span className="input-calendar-custom icon py-0 py-md-2" id="basic-addon1">

                            <FontAwesomeIcon
                                icon={["fal", "male"]}
                                size="1x"
                                className="mr-2"
                                style={{ marginLeft: "3.25px" }}
                            />

                            <span style={{ marginLeft: "20px" }}>
                                {t("sales.tourList.accordion.people")}{" "}
                            </span>

                        </span>
                        <span
                            className="qtyTotal"
                            data-min="2"
                            data-max="19"
                            style={{ marginLeft: "5px" }}
                        >
                            {dateTour[index]?.idSellingType === 2
                                ? numberTotal
                                : dateTour[index]?.idSellingType === 1
                                    ? numberTotalPeople
                                    : ""}
                        </span>
                        <span style={{ marginLeft: "5px", color: "#FFF" }}>
                            {dateTour[index]?.idSellingType === 2
                                ? t("sales.tourList.accordion.passengers")
                                : dateTour[index]?.idSellingType === 1
                                    ? t("sales.tourList.accordion.people")
                                    : t("sales.tourList.accordion.passengers")}
                        </span>
                        <span className="input-calendar-custom arrow py-0 py-md-2" id="basic-addon1">
                            <img src={iconArrow} alt="pesquisar" className="py-1" />
                        </span>
                    </div>
                </div>
            </>
        );
    }


};


export default DropdownNumberPeopleIntegrated;