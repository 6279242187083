import React from 'react';

import SuppliersTableUsers from '../../../AccordionUsers/SuppliersTableUsers/SuppliersTableUsers'

export interface propEdit {
    setChangeEdit: any,
    info: any,
};

const EditUser: React.FC<propEdit> = ({
    setChangeEdit, info
}: propEdit) => {
    
    const handleObjectUser = () => {
        
    }
    return (
        <>
            <div className="border" id="editUsers">
                <div className="edit-title container-fluid">
                    <h3 className="">Usuários</h3>
                    <button onClick={() => {setChangeEdit(false)}}>EDITAR</button>
                </div>
                <div className="data">
                    <SuppliersTableUsers isEdit={true}  info={info} actionTableUser={handleObjectUser} />
                </div>
                <br />
            </div>
        </>
    );
}

export default EditUser;