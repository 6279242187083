import React, { useEffect, useState } from "react";


import { Link } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import api from "../../../services/api";
import DesktopDefault from "../../../templates/DesktopDefault";
import CategoriesFilter from "./components/CategoriesFilter/CategoriesFilter";
import CategoriesTable from "./components/CategoriesTable/CategoriesTable";

function ListCategories() {

  const [categories, setCategories] = useState<any>([]);

  const [, setActualName] = useState<any>("");
  const [, setActualCode] = useState<any>("");
  const [, setActualStatus] = useState<any>(0);

  //loading
  const [loading, setLoading] = useState<any>(false);
  //loading

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */



  useEffect(() => {
    setLoading(true);
    setCategories(null);

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function listCategories() {
      try {
        const { data } = await api.post(
          "Categories/GetCategoriesByFilterAsync",
          {
            page: pageCount,
            rowsPerPage: rowsPerPage,
            categorieNameFind: "",
            categorieCodeFind: "",
            categorieStatusFind: 0,
            orderByField: ""
          },
          config
        );

        if (data.status !== 400) {
          console.log(data.data.data.rows)
          setLoading(false);
          setCategories(data.data.data.rows);
          setTotalRows(data.data.data.rowsCount);
        }
      } catch (error: any) {
        setLoading(false);
        // if (error.response.status === 401) {
        //   window.location.href = window.location.origin + "/";
        // }
      }
    }
    listCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pageCount, rowsPerPage]);


  function clearActualValues() {
    setActualName("");
    setActualCode("");
    setActualStatus(0);
  }


  async function buscar(name: any, code: any, status: any) {
    setCategories(null);
    setActualName(name);
    setActualCode(code);
    setActualStatus(status);
    setPageCount(1);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const res = await api.post('Categories/GetCategoriesByFilterAsync',
        {
          page: pageCount,
          rowsPerPage: rowsPerPage,
          categorieNameFind: name,
          categorieCodeFind: code,
          categorieStatusFind: status,
          orderByField: ""
        }
        , config
      );

      if (res.status !== 400) {
        console.log(res.data.data.data.rows)
        setLoading(false);
        setCategories(res.data.data.data.rows);
        setTotalRows(res.data.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
      //   if (error.response.status === 401) {
      //     window.location.href = window.location.origin + '/';
      //   }

    }
  };

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={"Listar Categorias"} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>
              Categorias{""}
            </h2>
            <Link to={'/listar-categorias/adicionar-categorias'} className='btn btn-primary'>
              Adicionar categoria
            </Link>
          </div>
          <CategoriesFilter
            buscar={buscar}
            categories={categories}
            clearActualValues={clearActualValues}
          />
          <CategoriesTable
            categories={categories}
            loading={loading}
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />

        </div>
      </DesktopDefault>
    </>
  );
}

export default ListCategories;