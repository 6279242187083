import React, { useState, useEffect } from "react";
import Button from "@restart/ui/esm/Button";

import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SingleCalendar from "../../Calendar/SingleCalendar";

import { Row, Col, InputGroup, Form, Dropdown } from "react-bootstrap";

/* const ModalDefault: FC<{}> = ({ children }: any) => { */
function ModalDefault(props: any) {
  const [modalShow, setModalShow] = useState(false);
  const [localizador, setLocalizador] = useState<any>("");

  useEffect(() => {
    if (props.closeModal === true) {
      setModalShow(false);
      props.setCloseModal(false);
    }
  }, [props.closeModal]);

  //console.log(props.allowEdit)

  return (
    <div>
      <Modal
        {...props}
        className={
          props.classModal
            ? "modal-custom modalAuth " + props.classModal
            : "modal-custom modalAuth"
        }
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          props.closeAction ? props.closeAction() : <></>;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <>
          <Modal.Header closeButton>
            <div className="d-flex justify-content-between w-100">
              <h3>{props.title ? props.title : ""}</h3>
              
              {props.allowEdit === true ? (
                <Dropdown drop="down">
                  <Dropdown.Toggle className=" options-reservations mt-2">
                    <FontAwesomeIcon
                      icon={["fal", "angle-down"]}
                      className="mx-2 text-info"
                      size="2x"
                      style={{ marginTop: "-3px" }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>                  

                    <Dropdown.Item eventKey="1" onClick={() => {}}>
                      <FontAwesomeIcon
                        icon={["fal", "edit"]}
                        className="mr-2"
                      />{" "}
                      Editar
                    </Dropdown.Item>

                    <Dropdown.Item
                      eventKey="1"
                      className="text-danger"
                      onClick={() => {}}
                    >
                      <FontAwesomeIcon
                        icon={["fal", "ban"]}
                        className="mr-2 "
                      />
                      <span> Cancelar</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <></>
              )}
            </div>
            {props.localation === "yes" ? (
              <div className="d-flex mx-auto w-50">
                <input
                  type="text"
                  className="form-control"
                  name="localation"
                  id="localation"
                  placeholder="Localizador"
                  onChange={(e: any) => {
                    setLocalizador(e.target.value);
                  }}
                  autoComplete="off"
                />
                <button
                  type="submit"
                  className="btn btn-outline-primary ml-3"
                  onClick={() => {
                    props.getLocalizador(localizador);
                  }}
                >
                  Buscar
                </button>
              </div>
            ) : props.vencimento === "yes" ? (
              <>
                <SingleCalendar setValue={props.setValue} />
              </>
            ) : (
              ""
            )}
          </Modal.Header>
          <hr style={{ margin: "30px 0" }} />
          {props.children}
        </>
      </Modal>

      <div className={props.class ? props.class : ""}>
        {props.icon === "yes" ? (
          <Button
            className={props.classBtn ? props.classBtn : ""}
            onClick={() => setModalShow(true)}
          >
            <FontAwesomeIcon
              icon={["fal", props.typeIcon !== undefined ? props.typeIcon : ""]}
              size="1x"
              style={{ marginRight: "5px" }}
            />
            {props.name ? props.name : "Default Button"}
          </Button>
        ) : (
          <Button
            className={props.classBtn ? props.classBtn : "btn"}
            onClick={() => setModalShow(true)}
          >
            {props.name ? props.name : "Default Button"}
          </Button>
        )}
      </div>
    </div>
  );
}

export default ModalDefault;
