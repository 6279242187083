import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Modal } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";

import { useTranslation } from "react-i18next";

import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { ErrorMessage } from "@hookform/error-message";

import GetCountrys from "../../components/C2Points/GetCountrys";
import GetStates from "../../components/C2Points/GetStates";
import GetCitysByState from "../../components/C2Points/GetCitysByState";
/* import GetDistrictType from "../../components/C2Points/GetDistrictType";
import GetStreetType from "../../components/C2Points/GetStreetType"; */

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DesktopDefault from "../../templates/DesktopDefault";
import GetCompanyTypeCorporate from "../../components/C2Points/GetCompanyTypeCorporate";

import api from "../../services/api";
import "../../assets/sass/accordion.scss";

function AddCorporateClient() {
    const { t } = useTranslation();

    const [idCompType, setIdCompType] = useState<string>(/* (isEdit === true || hasSavedInputs === true) ? infoEdit.compType?.toString() : '1' */'1');
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [messageError, setMessageError] = useState<boolean>(false);
    const [country, setCountry] = useState<any>(/* (isEdit === true || hasSavedInputs === true) ? { description: infoEdit.countryDesc, id: `${infoEdit.country}` } : { description: 'Brazil', id: `30` } */{ description: 'Brazil', id: `30` });
    //const [contact, setContact] = useState<any>(/* (isEdit === true || hasSavedInputs === true) ? infoEdit.supplierContact : '' */);

    const [, setAddressError] = useState<any>(false);
    const [countryError, setCountryError] = useState<any>(false);
    const [cpfError, setCpfError] = useState<any>(false);
    const [controlZipCode, setControlZipCode] = useState<any>(0);

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<string>('Loading');

    /* const idPartner = window.location.href.split('id=')[1];

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false); */

    const validated = false;

    const getChangeCompType = (idCT: any) => {
        setIdCompType(idCT);
    };

    const SetCompType = () => {
        if (idCompType === "1") {
            return (
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="formInfoCompanyCPF">
                        <Form.Label>CPF:</Form.Label>
                        <Controller
                            control={control}
                            name="cpf"
                            rules={{ required: { value: true, message: 'Por favor, informe o CPF.' } }}
                            render={({ field }: any) => (
                                <InputMask mask="999.999.999-99" value={field?.value} onChange={field?.onChange}/*  disabled={isEdit} */>
                                    {(inputProps: any) => (
                                        <Form.Control
                                            {...inputProps}
                                            aria-invalid={errors?.cpf ? "true" : ""}
                                            label={t("accordionInfoCompany.subM")}
                                            variant="standard"
                                            margin="normal"
                                            type="text"
                                            autoComplete="off"
                                            required
                                        //disabled={isEdit}
                                        />
                                    )}
                                </InputMask>
                            )}
                        />
                        {cpfError === true
                            ? <small style={{ color: "red" }}>Por favor, informe um CPF válido.</small>
                            : <></>
                        }
                        <ErrorMessage
                            errors={errors}
                            name="cpf"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </Row>
            );
        } else if (idCompType === "2") {
            return (
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="formInfoCompanyCNPJ">
                        <Form.Label>CNPJ:</Form.Label>
                        <Controller
                            control={control}
                            name="cnpj"
                            rules={{ required: { value: true, message: 'Por favor, informe o cnpj.' } }}
                            render={({ field }: any) => (
                                <InputMask mask="99.999.999/9999-99" value={field?.value} onChange={field?.onChange}>
                                    {(inputProps: any) => (
                                        <Form.Control
                                            {...inputProps}
                                            aria-invalid={errors?.cnpj ? "true" : ""}
                                            label={t('accordionInfoCompany.cnpj')}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            required
                                        //disabled={isEdit}
                                        />
                                    )}
                                </InputMask>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="cnpj"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formInfoCompanySubscription">
                        <Form.Label>{t('accordionInfoCompany.subE')}</Form.Label>
                        <Controller
                            control={control}
                            name="inscricaoEstadual"
                            rules={{ required: { value: false, message: 'Por favor, informe o número de Inscrição Estadual.' } }}
                            render={({ field }: any) => (
                                <Form.Control
                                    {...field}
                                    aria-invalid={errors?.inscricaoEstadual ? "true" : ""}
                                    label={t('accordionInfoCompany.subE')}
                                    variant="standard"
                                    margin="normal"
                                    type="text"
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                    autoComplete="off"
                                    required
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="inscricaoEstadual"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </Row>
            )
        } else {
            return (<></>);
        }
    };

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm({});

    const watchZipCode = watch(`zipCode`, false);
    const watchState = watch(`state`, '');
    const watchSCountry: any = watch(`country`, '');

    useEffect(() => {
        setControlZipCode(watchZipCode?.length)
        if (watchZipCode?.length === 5) {
            if (watchZipCode?.length > controlZipCode) {
                setValue('zipCode', `${watchZipCode}-`)
            }
        }


        if (watchZipCode?.length > 9) {
            var controlLength: any = watchZipCode;
            controlLength = controlLength.split('');
            controlLength.pop();
            controlLength = controlLength.join('');
            setValue('zipCode', `${controlLength}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchZipCode])

    function cpfValidator(strCPF: any) {
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === "00000000000") return false;

        for (var i: any = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    function validateSubmit(data: any) {
        var cpf: any = data.cpf.replace(/\./g, '').replace(/-/g, '').replace(/\//g, '');

        if ((idCompType === '1' ? cpfValidator(cpf) === false : false) || data.address.length < 4) {
            if (data.address.length < 4) {
                setAddressError(true);
            } else {
                setAddressError(false);
            }
            //
            if (cpfValidator(cpf) === false) {
                setCpfError(true);
            } else {
                setCpfError(false);
            }

            return false;
        } else {
            setCountryError(false);
            setMessageError(false);
            setCpfError(false);
            setAddressError(false);
            return true;
        }
    }

    const onSubmit = (data: any) => {
        let aux: any = {};

        aux.id = 0; // Não sei de onde pegar o id
        //aux.tipoTicket = 0;  // Não sei de onde pegar o tipoTicket
        aux.ativo = data.ativo === undefined ? true : data.ativo === '0' ? true : false;
        aux.tipoCliente = Number(idCompType);
        aux.nomeRepresentante = data.nomeRepresentante;
        aux.cargoRepresentante = data.cargoRepresentante;

        aux.companyDataCorporation = {
            razaoSocial: data.razaoSocial,
            documento: aux.tipoCliente === 1 ? data.cpf.replace(/\./g, '').replace(/-/g, '').replace(/\//g, '') : data.cnpj.replace(/\./g, '').replace(/-/g, '').replace(/\//g, ''),
            nomeFantasia: data.nomeFantasia,
            inscricaoEstadual: data.inscricaoEstadual === undefined ? "" : data.inscricaoEstadual,
            pais: Number(country.id),
            cep: data.zipCode,
            estado: data.state.description,
            cidade: data.city.description,
            bairro: data.compBairro,
            endereco: data.address,
            numero: data.numero,
            complemento: data.compComplem
        };

        const auxNotification: any = { ...aux.companyDataCorporation };

        aux.notificationDataCorporation = { ...auxNotification };

        aux.notificationDataCorporation.email = data.email;
        aux.notificationDataCorporation.nomeEmpresa = data.nomeRepresentante;
        aux.notificationDataCorporation.telefone = data.telefone;

        delete aux.notificationDataCorporation.razaoSocial;
        delete aux.notificationDataCorporation.documento;
        delete aux.notificationDataCorporation.nomeFantasia;
        delete aux.notificationDataCorporation.inscricaoEstadual;

        if (aux.tipoCliente === 1) {
            if (validateSubmit(data) !== false) {
                AddCorpClient(aux);
            }
        } else {
            AddCorpClient(aux);
        }
    };

    useEffect(() => {
        setValue('city', '');
        setValue('state', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchSCountry])

    function AddCorpClient(data: any) {
        setModalShow(true);
        setModalMessage('loading');

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        async function corpClient() {
            try {
                const res = await api.post(`/SpecialTickets/CreateNewUser`, data, config);

                if (res.status !== 400) {
                    setModalLog(res.data.data.log);
                    setModalMessage(res.data.data.texto);
                } else {
                    setModalLog(1);
                    setModalMessage("Por favor, entre em contato com nosso suporte técnico.");
                }
            } catch (error: any) {
                setModalLog(1);
                setModalMessage(error.response.data.errorMessage);
            }
        }
        corpClient();
    }

    return (
        <DesktopDefault>
            <div className="container-fluid content-dashboard ">
                <Breadcrumb title={"Clientes / Novo Cliente Corporativo"} />
                <div className="partners mt-4">
                    <div className="d-flex align-items-center">
                        <span className='back mb-1' style={{ cursor: "pointer" }} onClick={() => { window.location.href = '/corporate-client' }}>
                            <FontAwesomeIcon
                                icon={["fal", "angle-double-left"]}
                                style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                            />
                            Voltar
                        </span>
                    </div>

                    <h2 className="title text-primary">Novo Cliente Corporativo</h2>

                    <Accordion>
                        <Card className="bg-white mt-4" id="editInfoCompanyForms">
                            <Card.Header id="accordionPersonal">
                                <h2 className="accordion-custom accordion-header">
                                    <button type="button" aria-expanded="false" className="accordion-button">
                                        Dados da Empresa Contratante
                                    </button>
                                </h2>
                            </Card.Header>
                            <div>
                                <Card.Body>
                                    <Container className="p-0" fluid>
                                        <Row>
                                            <div>
                                                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyName">
                                                            <Form.Label>Razão Social</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="razaoSocial"
                                                                rules={{ required: { value: true, message: 'Por favor, informe o nome do cliente.' } }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        aria-invalid={errors?.razaoSocial ? "true" : ""}
                                                                        label="Razão Social"
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="razaoSocial"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyNameFantasy">
                                                            <Form.Label>Nome Fantasia</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="nomeFantasia"
                                                                rules={{ required: { value: idCompType === "2", message: 'Por favor, informe o nome fantasia.' } }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        aria-invalid={errors?.nomeFantasia ? "true" : ""}
                                                                        label={t("accordionInfoCompany.nameFant")}
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        autoComplete="off"
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="nomeFantasia"
                                                                render={({ message }) => (
                                                                    <small style={{ color: "red" }}>{message}</small>
                                                                )}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group
                                                            as={Col}
                                                            md="4"
                                                            controlId="formInfoCompanyWebsite"
                                                        >
                                                            <Form.Label>Email</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="email"
                                                                rules={{
                                                                    required: {
                                                                        value: true, 
                                                                        message: "Por favor, informe o email.",
                                                                    },
                                                                    pattern: {
                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                        message: 'Por favor, informe um email válido.',
                                                                    }
                                                                }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        aria-invalid={errors?.email ? "true" : ""}
                                                                        label="Email"
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="email"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyNameFantasy">
                                                            <Form.Label>Telefone</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="telefone"
                                                                rules={{ required: { value: true, message: 'Por favor, informe o telefone.' } }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        aria-invalid={errors?.telefone ? "true" : ""}
                                                                        label="Telefone"
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="telefone"
                                                                render={({ message }) => (
                                                                    <small style={{ color: "red" }}>{message}</small>
                                                                )}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyPromoter">
                                                            <Form.Label>Representante da Empresa</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="nomeRepresentante"
                                                                rules={{ required: { value: true, message: 'Por favor, informe o nome do representante.' } }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        aria-invalid={errors?.nomeRepresentante ? "true" : ""}
                                                                        label="Representante da Empresa"
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="nomeRepresentante"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyNameFantasy">
                                                            <Form.Label>Cargo</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="cargoRepresentante"
                                                                rules={{ required: { value: true, message: 'Por favor, informe o cargo do representante.' } }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        aria-invalid={errors?.cargoRepresentante ? "true" : ""}
                                                                        label="Cargo do Representante"
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="cargoRepresentante"
                                                                render={({ message }) => (
                                                                    <small style={{ color: "red" }}>{message}</small>
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyNameFantasy">
                                                            <Form.Label>Status</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="ativo"
                                                                render={({ field }: any) => (
                                                                    <div className={errors.ativo !== undefined ? "endpoint-error" : "endpoint"}>
                                                                        <Form.Select
                                                                            {...field}
                                                                            aria-invalid={errors?.ativo ? "true" : ""}
                                                                            label="Ativo"
                                                                            as="select"
                                                                            variant="standard"
                                                                            margin="normal"
                                                                            required
                                                                        >
                                                                            <option value='0' selected>Ativo</option>
                                                                            <option value='1'>Inativo</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                    <div>
                                                        <small>Para começar o seu cadastro indique se o cliente corporativo é pessoa física ou jurídica</small>
                                                        <Row>
                                                            <Controller
                                                                control={control}
                                                                name="compType"
                                                                render={({ field }: any) => (
                                                                    <GetCompanyTypeCorporate
                                                                        propsField={field}
                                                                        getChangeCompType={getChangeCompType}
                                                                        compType={'1'}
                                                                    />
                                                                )}
                                                            />
                                                        </Row>
                                                    </div>

                                                    <SetCompType />

                                                    <hr className="my-5" />
                                                    <h5 className="text-uppercase mb-3">{t('accordionInfoCompany.address')}</h5>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyCountry">
                                                            <Form.Label>{t('accordionInfoCompany.country')}</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="country"
                                                                rules={{ required: { value: false, message: 'Por favor, selecione o país.' } }}
                                                                render={({ field }: any) => (
                                                                    <GetCountrys
                                                                        propsField={field}
                                                                        propsLabel={t('accordionInfoCompany.country')}
                                                                        propsErrors={errors}
                                                                        watchCountry={setCountry}
                                                                        country={country.description}
                                                                    />
                                                                )}
                                                            />
                                                            {countryError === true
                                                                ? <small style={{ color: "red" }}>{t('accordionInfoCompany.validation.country')}</small>
                                                                : <></>
                                                            }
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="country"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyCEP">
                                                            <Form.Label>{t('accordionInfoCompany.cep')}</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="zipCode"
                                                                rules={{ required: { value: true, message: 'Por favor, informe o CEP.' } }}
                                                                render={({ field }: any) => (
                                                                    <Controller
                                                                        control={control}
                                                                        name="zipCode"
                                                                        rules={{ required: { value: true, message: t('accordionInfoCompany.validation.cep') } }}
                                                                        render={({ field }: any) => (
                                                                            <Form.Control
                                                                                {...field}
                                                                                aria-invalid={errors?.zipCode ? "true" : ""}
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                autoComplete="off"
                                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                required
                                                                            />
                                                                        )}
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="zipCode"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyState">
                                                            <Form.Label>{t('accordionInfoCompany.state')}</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="state"
                                                                rules={{ required: { value: true, message: t('accordionInfoCompany.validation.state') } }}
                                                                render={({ field }: any) => (
                                                                    <GetStates
                                                                        propsField={field}
                                                                        propsLabel={t('accordionInfoCompany.state')}
                                                                        propsErrors={errors}
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="state"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyCity">
                                                            <Form.Label>{t('accordionInfoCompany.city')}</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="city"
                                                                rules={{ required: { value: true, message: t('accordionInfoCompany.validation.city') } }}
                                                                render={({ field }: any) => (
                                                                    <GetCitysByState propsField={field} propsErrors={errors} uf={watchState?.id} />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="city"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyDistrict">
                                                            <Form.Label>{t('accordionInfoCompany.distr')}</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="compBairro"
                                                                rules={{ required: { value: true, message: 'Por favor, informe o bairro.' } }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        aria-invalid={errors?.compBairro ? "true" : ""}
                                                                        label={t('accordionInfoCompany.distr')}
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="compBairro"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyAddress" className="col-custom-2">
                                                            <Form.Label>{t('accordionInfoCompany.address')}</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="address"
                                                                rules={{ required: { value: true, message: 'Por favor, informe o endereço.' } }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        aria-invalid={errors?.address ? "true" : ""}
                                                                        label={t("accordionInfoCompany.address")}
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="address"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyNumber">
                                                            <Form.Label>{t('accordionInfoCompany.num')}</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="numero"
                                                                rules={{ required: { value: true, message: 'Por favor, informe o número.' } }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        aria-invalid={errors?.numero ? "true" : ""}
                                                                        label={t("accordionInfoCompany.num")}
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="numero"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyComplement">
                                                            <Form.Label>{t('accordionInfoCompany.comp')}</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="compComplem"
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        label={t("accordionInfoCompany.comp")}
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        autoComplete="off"
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="d-flex justify-content-end mx-0 mt-5">
                                                        <div className="data" style={{ width: 'unset' }}>
                                                            <div className="btn-default mr-2 text-uppercase btn btn-outline-primary" onClick={() => { window.location.reload() }}>Cancelar</div>
                                                        </div>
                                                        <Button
                                                            type="submit"
                                                            className="form-button"
                                                        >
                                                            {t('accordionInfoCompany.buttonContinue')}
                                                        </Button>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </div>
                        </Card>
                    </Accordion>

                    <Modal
                        size="lg"
                        show={modalShow}
                        onHide={() => {
                            setModalShow(false);
                            window.location.href = '/corporate-client';
                        }}
                        aria-labelledby="contained-modal-title-vcenter"
                        className="modal-confirm loading-modal"
                    >
                        <Modal.Body className="modal-body text-center sucess-pos">
                            {modalLog !== null ? (
                                <>
                                    <div>
                                        {modalLog === 1 || modalLog === '1' ? (
                                            <FontAwesomeIcon
                                                icon={['fal', 'times-circle']}
                                                size="5x"
                                                style={{ fontSize: '7.5em' }}
                                                className="text-primary"
                                            />
                                        ) : modalLog === 0 || modalLog === '0' ? (
                                            <FontAwesomeIcon
                                                icon={['fal', 'check-circle']}
                                                size="5x"
                                                style={{ fontSize: '7.5em' }}
                                                className="text-primary"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={['fal', 'question-circle']}
                                                size="5x"
                                                style={{ fontSize: '7.5em' }}
                                                className="text-primary"
                                            />
                                        )}
                                    </div>
                                    <div>{modalMessage}</div>
                                    <div className="d-flex justify-content-center">
                                        <button
                                            onClick={() => {
                                                setModalShow(false);
                                                window.location.href = '/corporate-client';
                                            }}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div className="modal-body">
                                    <div className="text-center">
                                        <div className="load"></div>
                                    </div>
                                    <div>
                                        <span>Carregando</span>
                                    </div>
                                    <div></div>
                                </div>
                            )}
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </DesktopDefault>
    )
};

export default AddCorporateClient;