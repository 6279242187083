import React, { useEffect, useRef } from "react";

import "../../../assets/sass/modal.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from "react-bootstrap/Button";

import './RequestResponseModal.scss';

function RequestResponseModal({ name = '', modalResponse, setModalResponse, errors = {}, modalText, log }: any) {

  /* Detecta clique fora da div#wrapper para fechar o modal */
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setModalResponse(null);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function handleClose() {
    setModalResponse(null);
    window.location.reload();
  }

  function handleCloseError() {
    setModalResponse(null);
    //window.location.href = "/partners";
  }

  /* //Detecta clique fora da div#wrapper para fechar o modal\\ */


  if (modalResponse === "loading") {
    return (
      <>
        <div className="sucess-pos loading-modal mt-5" id="wrapper" ref={wrapperRef} style={{ display: "flex", justifyContent: "space-around", height: "545px" }}>
          <div className="text-center">

            <div className="sucess-pos" id="wrapper" >
              <div className="text-center">
                <div className="load"></div>
                <h5 className="mt-5">Carregando</h5>
              </div>
            </div>

          </div>
          <Button className="my-5 mx-auto" onClick={() => { setModalResponse(null) }}>
            Fechar
          </Button>
        </div>
      </>
    )
  } else if (modalResponse === 'success') {
    return (
      <>
        <div className="sucess-pos" id="wrapper" ref={wrapperRef} style={{ display: "flex", justifyContent: "space-around", height: "545px" }}>
          <div className="text-center">
            {
              log === 1
                ?
                <FontAwesomeIcon
                  icon={["fal", "times-circle"]}
                  size="5x"
                  style={{ fontSize: "7.5em" }}
                />
                :
                <FontAwesomeIcon
                  icon={["fal", "check-circle"]}
                  size="5x"
                  style={{ fontSize: "7.5em" }}
                />
            }
          </div>
          <div className="text-center">
            {modalText}
          </div>
          <Button className="my-5 mx-auto" onClick={log === 1 ? handleCloseError : handleClose}>
            Fechar
          </Button>
        </div>
      </>
    )
  } else if (modalResponse === 'error') {
    return (
      <>
        <div className="error-pos" id="wrapper" ref={wrapperRef} style={{ display: "flex", justifyContent: "space-around", height: "545px" }}>
          <div className="text-center">
            <FontAwesomeIcon
              icon={["fal", "times-circle"]}
              size="5x"
              style={{ fontSize: "7.5em" }}
            />
          </div>
          <div className="text-center">
            {modalText}
          </div>
          <Button className="my-5 mx-auto" onClick={handleCloseError}>
            Fechar
          </Button>
        </div>
      </>
    )
  } else {
    return (
      <>

      </>
    )
  }
}

export default RequestResponseModal;
