import React, { useEffect, useState } from "react";
import api from '../../../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal, Dropdown } from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableBillsPay.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import DebitNotes from "../../../../components/Modal/ModalDefault/DebitNotes/DebitNotes";
import Payment from "../../../../components/Modal/ModalDefault/Payment/Payment";
import FormBillsToPay from "../../../../components/Forms/FormBillsToPay/FormBillsToPay";
import Expiration from "../../../../components/Modal/ModalDefault/Expiration/Expiration";

export interface propTable {
  billsPay: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportBills: any;
  printBills: any;
  parameters: any;
  totalPay: any;
  actualDate: any;
  loading: any;
}

const TableBillsPay: React.FC<propTable> = ({
  billsPay, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportBills, printBills, parameters, totalPay, actualDate, loading
}: propTable) => {
  /* VARIÁVEIS */
  const { t } = useTranslation();

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>();
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalResponse, setModalResponse] = useState<any>(null);
  const [datapgto, setDatapgto] = useState<any>(undefined);
  const [allowReload, setAllowReload] = useState<any>(false);


  const [expiration, setExpiration] = useState<any>();

  const tourAuth: any = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;

  const validated = false;


  /* FUNÇÕES */

  async function settleBills(row: any) {
    setSelectedSingleRow(row);  //recebe array vazio ([]) para liquidar consulta
    setModalShow(true);
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  /*converte a data UTC para a data local do usuário*/
  /* function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  } */

  function convertDate(cell: any, row: any) {
    if (cell !== null) {
      //var date = convertUTCDateToLocalDate(new Date(cell));
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return (
        <>
          {date}
        </>
      );
    } else {
      return (
        <>
        </>
      );
    }
  }

  /* SOBRE A TABELA */

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" onClick={() => { settleBills(row) }} ><FontAwesomeIcon icon={['fal', 'dollar-sign']} className="mr-2" style={{ width: '18px' }} />Liquidar</Dropdown.Item>
              <Dropdown.Item eventKey="3" disabled={true}><FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />   {t('suppliersTable.edit')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Conciliado"
            ? "bg-analise"
            : cell === "Pago"
              ? "bg-ativo"
              : cell === "Pendente"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  const columns = [
    {
      dataField: "vencimento",
      text: "Vencimento",
      formatter: convertDate,
    },
    { dataField: "fornecedor", text: "Fornecedor" },
    {
      dataField: "dataOperacao",
      text: "Data Operação",
      formatter: convertDate,
    },
    { dataField: "descricao", text: "Tour/Descrição" },
    { dataField: "contaContabil", text: "Categoria" },
    {
      dataField: "valor",
      text: "Valor",
      formatter: convertValue,
    },
    {
      dataField: "statusPagamento",
      text: "Status Pagamento",
      formatter: addStatus,
    },
    {
      dataField: "dataPagamento",
      text: "Data Pagamento",
      formatter: convertDate
    },
    { dataField: "contasaPagarId", text: "Ação", formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando Contas", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "contas", text: "Contas" }];

  const notFoundProducts = [{ contas: "Nenhuma conta encontrada" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === "Conciliado") {
      return 'border-analise';
    } else if (row.statusPagamento === "Pago") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  /* FORMULÁRIO */

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (modalShow === false) {
      reset({
        data: undefined
      })
    }
  }, [modalShow])

  const onSubmit = (data: any) => {
    setModalResponse('loading');

    var fixDate = datapgto.split('/');
    data.dataPgto = fixDate.length === 3 ? `${fixDate[2]}-${fixDate[1]}-${fixDate[0]}` : undefined;
    data.fornLiquidacao = data.fornLiquidacao ? data.fornLiquidacao.supplierId : 0;
    data.contaLiquidacao = data.contaLiquidacao ? data.contaLiquidacao.id : 0;

    //console.log(data)

    async function settle() {
      try {
        const res = await api.post('/BillsPayable/SettleBills',
          {
            "parametros": parameters, // objeto com os parametros da consulta
            "items": selectedSingleRow.length > 0 ?
              [{
                "contasaPagarId": selectedSingleRow.contasaPagarId,
                "id": selectedSingleRow.manifestId,
                "tipo": selectedSingleRow.tipo
              }]
              : [], // array com os itens a serem liquidados
            "fornLiquidacao": data.fornLiquidacao ? parseInt(data.fornLiquidacao) : 0, //
            "descTaxa": data.descTaxa ? parseInt(data.descTaxa) : 0, // valor taxa desconto
            "jurosMulta": data.jurosMulta ? parseInt(data.jurosMulta) : 0, // valor taxa juros
            "contaLiquidacao": data.contaLiquidacao ? parseInt(data.contaLiquidacao) : 0, // conta liquidação
            "dataPgto": data.dataPgto, // data do pagamento
            "uid": uId,
            "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
          },
        );
        if (res.status !== 400) {
          if (res.data.data.log === 0) {
            setModalResponse('success');
            setAllowReload(true);
          }
          else if (res.data.data.log === 1) {
            setModalResponse('error');
          }
        }
      } catch (error: any) {
        console.log(error.response)
        if (error?.response?.status === 401) {
        }
      }
    }
    settle();
  }

  useEffect(() => {
    if (modalResponse === null && allowReload === true) {
      window.location.reload();
    }
  }, [modalResponse])


  /* RENDER */
  if (billsPay !== null) {
    /* console.log(props.suppliers) */
    return (
      <>
        <div className="table-default bills-pay-table">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <ModalDefault
                      title="Inserir Pagamento"
                      name="Inserir"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <Payment />
                    </ModalDefault>
                  </div>
                  <div>
                    <ModalDefault
                      title="Inserir Nota de Débito"
                      name="Nota de Débito"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <DebitNotes />
                    </ModalDefault>
                  </div>
                  <div>
                    <a href="#" className="btn btn-bills" onClick={exportBills}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </a>
                  </div>
                  <div>
                    <a href="#" className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </a>
                  </div>
                  <div>
                    <Dropdown drop="down" >
                      <Dropdown.Toggle className="menu-profile p-0">
                        <button className="btn btn-bills">
                          <FontAwesomeIcon
                            icon={["fal", "dollar-sign"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          Liquidar em Lote
                        </button>
                      </Dropdown.Toggle>

                      <Dropdown.Menu id="dropdown-menu-liquidar">
                        <Dropdown.Item eventKey="1" disabled={false} onClick={() => { window.location.href = "/settle-bills-to-pay" }}> Liquidar Parcial</Dropdown.Item>
                        <Dropdown.Item eventKey="2" disabled={false} onClick={() => { settleBills([]) }}> Liquidar Consulta</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <ModalDefault
                      title="Vencimento"
                      name="Alterar Vencimento em Lote"
                      icon="yes"
                      typeIcon="calendar"
                      classBtn="btn btn-bills"
                      classModal="modal-large modal-large-custom"
                      vencimento="yes"
                      setValue={setExpiration}
                    >
                      <Expiration
                        billsPay={billsPay}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        setExpiration={setExpiration}
                        expiration={expiration}
                        actualDate={actualDate}
                        parameters={parameters}
                      />
                    </ModalDefault>
                  </div>
                  {/*                   <div>
                    <button className="btn btn-bills">
                      <FontAwesomeIcon
                        icon={["fal", "calendar"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Alterar Vencimento em Lote
                    </button>
                  </div> */}
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="mb-0">Total: <span className={Math.sign(totalPay) === -1 ? "negative" : ""}>{totalPay.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></h4>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsPay}
              columns={columns}
              rowClasses={rowClasses}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
        <Modal
          className={"modal-custom modalAuth modal-large"}
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
              <h3>
                Liquidar
              </h3>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
              <FormBillsToPay control={control} errors={errors} setDatapgto={setDatapgto} modalResponse={modalResponse} setModalResponse={setModalResponse} />
            </Form>
          </>
        </Modal>
      </>
    );
  } else if (billsPay === null && loading === true) {
    return (
      <>
        <div className="table-default bills-pay-table loading not-found">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <ModalDefault
                      title="Inserir Pagamento"
                      name="Inserir"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <Payment />
                    </ModalDefault>
                  </div>
                  <div>
                    <ModalDefault
                      title="Inserir Nota de Débito"
                      name="Nota de Débito"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <DebitNotes />
                    </ModalDefault>
                  </div>
                  <div>
                    <a href="#" className="btn btn-bills" onClick={exportBills}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </a>
                  </div>
                  <div>
                    <a href="#" className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </a>
                  </div>
                  <div>
                    <Dropdown drop="down" >
                      <Dropdown.Toggle className="menu-profile p-0">
                        <button className="btn btn-bills">
                          <FontAwesomeIcon
                            icon={["fal", "dollar-sign"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          Liquidar em Lote
                        </button>
                      </Dropdown.Toggle>

                      <Dropdown.Menu id="dropdown-menu-liquidar">
                        <Dropdown.Item eventKey="1" disabled={false} onClick={() => { window.location.href = "/settle-bills-to-pay" }}> Liquidar Parcial</Dropdown.Item>
                        <Dropdown.Item eventKey="2" disabled={false} onClick={() => { settleBills([]) }}> Liquidar Consulta</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <ModalDefault
                      title="Vencimento"
                      name="Alterar Vencimento em Lote"
                      icon="yes"
                      typeIcon="calendar"
                      classBtn="btn btn-bills"
                      classModal="modal-large modal-large-custom"
                      vencimento="yes"
                      setValue={setExpiration}
                    >
                      <Expiration
                        billsPay={billsPay}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        setExpiration={setExpiration}
                        expiration={expiration}
                        actualDate={actualDate}
                        parameters={parameters}
                      />
                    </ModalDefault>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="mb-0">Total: <span className={Math.sign(totalPay) === -1 ? "negative" : ""}>{totalPay.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></h4>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default bills-pay-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableBillsPay;
