import React, { Key, useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import { useForm } from "react-hook-form";
import api from '../../../../services/api';

import Select from 'react-select';
import './FilterSessionClosure.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import SingleCalendar from "../../../../components/Calendar/SingleCalendar";
import GlobalAlert from "../../../../components/Alert/GlobalAlert/GlobalAlert";

function FilterSessionClosure(props: any) {

    const { t } = useTranslation();

    const [filterSession, setFilterSession] = useState<any>('');
    const [filterDate, setFilterDate] = useState<any>([]);
    const [filterType, setFilterType] = useState<any>(0);
    const [filterStatus, setFilterStatus] = useState<any>(0);
    const [sessions, setSessions] = useState<any>([]);

    const [alert, setAlert] = useState<any>();
    const [checked, setChecked] = useState<boolean>(true);

    const [info, setInfo] = useState<any>(null);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    const {
        control,
        //handleSubmit,
        //register,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = () => {
        props.buscar(filterSession, filterType, filterStatus, filterDate);
        //console.log(filterName, filterState, filterType, filterStatus, filterDate)
    };

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterSession("");
        setFilterDate(props.dataPadrao);
        setFilterType(0)
        setFilterStatus(0);
        props.buscar("", 0, 0, filterDate);
    };

    function handleChecked() {
        if (checked === true) {
            setChecked(false);
        } else {
            setChecked(true);
        }
    }

    const handleChangeSession = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterSession(newValue);
        props.setFilterSession(newValue);
    }

    const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterDate(newValue);
        props.setFilterDate(newValue);
    }

    // evento altera tipo
    // filtra sessions pelo tipo
    // filtra status pelo tipo (todos mostra todos?)
    const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterType(newValue);
        props.setFilterType(newValue);

        updateDropdowns(filterDate, newValue);
    }

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatus(newValue);
        props.setFilterStatus(newValue);
    }

    const handleDateClick = (inc: any) => {
        const newDate = new Date(filterDate);
        newDate.setDate(newDate.getDate() + inc);
        const newDateFmt = newDate.toISOString().substring(0, 10);
        setFilterDate(newDateFmt);
        props.setFilterDate(newDateFmt);

        props.buscar(filterSession, filterType, filterStatus, newDateFmt);
    }


    const updateDropdowns = (date: any, type: any) => {
        var today: any = new Date();
        if (date.length !== 0) date = date.split('/');
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listSessions() {
            try {
                const params = {
                    date: date.length === 0 ? `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}` : `${date[2]}-${date[1]}-${date[0]}`,
                    type: type !== '' ? type : null
                };

                const { data } = await api.post(`/SessionClosure/GetSessionsByDateAndTypeAsync`, params, config);
                if (data.status !== 400) {
                    const dados = data.data;
                    setSessions(dados)
                    /* console.log(data); */
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                    //console.log(error);
                }
            }
        }
        listSessions();
    }

    useEffect(() => {
        if (props.sessions?.length === 0 || props.error === true) {
            setAlert(
                <GlobalAlert alertType={"danger"}>
                    Não foi possível encontrar nenhuma session.
                </GlobalAlert>
            )
            setTimeout(() => setAlert(null), 2000);
        } else {
            setAlert(null);
        }

        updateDropdowns(filterDate, filterType);
    }, [props.sessions, props.error])

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Fechamento</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            {alert}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <SingleCalendar setValue={props.setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('sessionClosure.sessionclosure.tipo')}</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeType(e)}
                                                        >
                                                            <option value="">{t('sessionClosure.sessionclosure.filterAll')}</option>
                                                            <option value="1">{t('sessionClosure.sessionclosure.transfer')}</option>
                                                            <option value="2">{t('sessionClosure.sessionclosure.tour')}</option>
                                                            <option value="3">{t('sessionClosure.sessionclosure.ticket')}</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>{t('sessionClosure.sessionclosure.session')}</Form.Label>
                                                <InputGroup hasValidation>
                                                    {/* <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeSession(e)}
                                                        >
                                                            <option value="">{t('sessionClosure.sessionclosure.filterAll')}</option>
                                                            {sessions.length > 0 ? sessions.map((info: any, index: Key) => (
                                                                <option key={index} value={info.sessionReloc}>{info.sessionReloc} - {info.descricao}</option>
                                                            )) : ''}
                                                        </Form.Select> */}
                                                    <div className="endpoint input-select-custom w-100">
                                                        <Select
                                                            aria-labelledby="aria-label"
                                                            inputId="aria-example-input"
                                                            name="aria-live-color"
                                                            onMenuOpen={onMenuOpen}
                                                            onMenuClose={onMenuOpen}
                                                            placeholder={t('accordionInfoCompany.endpoint')}
                                                            options={Array.from(sessions)}
                                                            getOptionLabel={(option: any) => `${option.descricao}`}
                                                        />
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('sessionClosure.sessionclosure.sessionStatus')}</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatus(e)}
                                                        >
                                                            <option value="">{t('sessionClosure.sessionclosure.filterAll')}</option>
                                                            <option value="1">{t('sessionClosure.sessionclosure.pending')}</option>
                                                            <option value="2">{t('sessionClosure.sessionclosure.conciliated')}</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            {/* <Form.Group as={Col} md="2" className="d-flex flex-column justify-content-end">
                                                <div className="d-flex justify-content-md-start">
                                                    <Button className="px-3 text-uppercase" onClick={handleFilterClick}>
                                                        <FontAwesomeIcon
                                                            icon={["fal", "search"]}
                                                            size="1x"
                                                            className="mr-2"
                                                        />
                                                        {t('sessionClosure.sessionclosure.find')}
                                                    </Button>
                                                </div>
                                            </Form.Group> */}
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default FilterSessionClosure;