/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { Col, Form, Modal } from 'react-bootstrap';

import DetailsReservation from '../../components/DetailsReservation/DetailsReservation';

import { useTranslation } from 'react-i18next';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import BootstrapTable from 'react-bootstrap-table-next';

import '../../../../assets/sass/accordion.scss';

//STYLES
import './CorporateClientTable.scss';
import Pagination from '../../../../components/Pagination/Pagination';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import api from '../../../../services/api';

export interface propTable {
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  clientCorp: any;
  confirm: any;
  log: any;
  modalContent: any;
  setConfirm: any;
  setLog: any;
  handleModal: any;
  loading: any;
  setReason: any;
  valReason: any;
  reloadTable: any;
}

const CorporateClientTable: React.FC<propTable> = ({
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
  clientCorp,
  confirm,
  log,
  modalContent,
  setConfirm,
  setLog,
  handleModal,
  loading,
  setReason,
  valReason,
  reloadTable,
}: propTable) => {
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const watchReason = watch('reason', false);
  setReason(watchReason);

  const { t } = useTranslation();
  const [editClick, setEditClick] = useState<any>(false);
  const [detailsModal, setDetailsModal] = useState<any>(false);
  //const [voucherModal, setVoucherModal] = useState<any>(false);
  const [currentReservation, setCurrentReservation] = useState<any>();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalLog, setModalLog] = useState<any>(null);
  const [modalMessage, setModalMessage] = useState<string>('Loading');

  const [modalUpdateStatus, setModalUpdateStatus] = useState<any>(false);
  const [userId, setUserId] = useState<any>(null);
  const [supplierId, setSupplierId] = useState<any>(null);

  /* const level = atob(
    JSON.parse(localStorage.getItem('bpaSigAuth') || '{}').user.perfil.nivel,
  ); */

  /*   function seeDetails(cell: any) {
      setEditClick(false);
      setCurrentReservation(cell);
      setDetailsModal(true);
    } */

  function seeDetailsEdit(cell: any) {
    setEditClick(true);
    setCurrentReservation(cell);
    setDetailsModal(true);
  }

  /*   function seeVoucherDownload(cell: any) {
      setCurrentReservation(cell);
      setVoucherModal(true);
    } */

  const onSubmit = () => {
    setModalShow(true);

    let status: any = getValues('updateStatus');

    async function updateStatus() {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {
        const res = await api.post('/SpecialTickets/ChangeStatus',
          {
            "idUser": userId,
            "statusAtual": Number(status)
          }
          , config);

        if (res.status !== 400) {
          if (res.data.log === 0) {
            setModalLog(0);
            setModalUpdateStatus(false);
            setModalMessage(res.data.texto);
          } else {
            setModalLog(1);
            setModalMessage("Error ao processar operação");
          }
        }
      } catch (error: any) {

      }
    }
    updateStatus();
  }

  const handleClickStatus = (row: any) => {
    setUserId(row.id);
    setSupplierId(row.supplierId);
    setModalUpdateStatus(true);
    setValue("updateStatus", row.ativo === true ? "1" : "0")
  }

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="2"
                href={"/edit-corporate-client?id=" + cell}
                onClick={() => {
                  seeDetailsEdit(cell);
                }}
              >
                <FontAwesomeIcon
                  icon={['fal', 'edit']}
                  className="mr-2"
                />
                {t('reservations.table.dropdown.edit')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={() => { handleClickStatus(row); }}
              >
                <FontAwesomeIcon icon={['fal', 'circle']} className="mr-2" />
                {t('suppliersTable.status')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  function addStatus(cell: any, row: any) {
    var status = cell === true ? 'Ativo' : 'Inativo';

    return (
      <div
        className={
          status === 'Ativo'
            ? 'bg-ativo'
            : status === 'Inativo'
              ? 'bg-inativo'
              : 'bg-notApproved'
        }
      >
        {status}
      </div>
    );
  };

  function addFormattedDoc(cell: string, row: any) {

    const formatCPF = (cpf: string) => {
      cpf = cpf.replace(/\D/g, '');
      cpf = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
      return cpf;
    }

    function formatCNPJ(cnpj: string) {
      cnpj = cnpj.replace(/\D/g, '');
      cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
      return cnpj;
    }

    return (
      <>
        {row.tipoCliente === 1 || row.tipoCliente === '1' ? formatCPF(cell) : formatCNPJ(cell)}
      </>
    )
  }

  const columns = [
    {
      dataField: 'nomeCliente',
      text: 'Nome/Empresa',
    },
    {
      dataField: 'documento',
      text: 'CPF/CNPJ',
      formatter: addFormattedDoc
    },
    {
      dataField: 'ativo',
      text: 'Status',
      formatter: addStatus
    },
    {
      dataField: 'id',
      text: 'Ações',
      formatter: addActionButton
    },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    {
      dataField: 'contasd',
      text: 'Carregando Clientes',
      formatter: loadingFunc,
    },
  ];

  const loadingProducts = [
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: 'clientes', text: 'Clientes Corporativos' }];

  const notFoundProducts = [{ clientes: 'Nenhum cliente corporativo encontrado' }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any) => {
    if (row.ativo === true) {
      return 'border-ativo';
    } else if (row.ativo === false) {
      return 'border-inativo';
    } else {
      return 'border-normal';
    }
  };

  function handleModalShow() {
    setValue('reason', '0');
  }

  if (clientCorp !== null) {
    return (
      <>
        <div className="table-default reservations-table corporate-client-table">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={clientCorp}
              columns={columns}
              rowClasses={rowClasses}
            />

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>

        <Modal
          size="lg"
          show={modalShow}
          onHide={() => {
            setModalShow(false)
          }}
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-confirm loading-modal"
        >
          <Modal.Body className="modal-body text-center sucess-pos">
            {modalLog !== null ? (
              <>
                <div>
                  {modalLog === 1 || modalLog === '1' ? (
                    <FontAwesomeIcon
                      icon={['fal', 'times-circle']}
                      size="5x"
                      style={{ fontSize: '7.5em' }}
                      className="text-primary"
                    />
                  ) : modalLog === 0 || modalLog === '0' ? (
                    <FontAwesomeIcon
                      icon={['fal', 'check-circle']}
                      size="5x"
                      style={{ fontSize: '7.5em' }}
                      className="text-primary"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={['fal', 'question-circle']}
                      size="5x"
                      style={{ fontSize: '7.5em' }}
                      className="text-primary"
                    />
                  )}
                </div>
                <div>{modalMessage}</div>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => {
                      setModalShow(false)
                      reloadTable()
                    }}
                    className="btn btn-primary mx-2 w-25"
                  >
                    Confirmar
                  </button>
                </div>
              </>
            ) : (
              <div className="modal-body">
                <div className="text-center">
                  <div className="load"></div>
                </div>
                <div>
                  <span>Carregando</span>
                </div>
                <div></div>
              </div>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          className="modalAuth"
          show={modalUpdateStatus}
          onHide={() => { setUserId(null); setSupplierId(null); setModalUpdateStatus(false); }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <div className='status-modal'>
              <Form className="modal-content" noValidate validated={false} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <Modal.Body>

                  <div className="modal-header rounded-0">
                    <div>
                      <h4 className="pt-1 text-primary title-reservation">Editar Status</h4>
                      <small>

                      </small>
                    </div>

                    <div className='buttons-options'>
                      <div>

                      </div>
                      <div>
                        <FontAwesomeIcon
                          icon={['fal', 'times']}
                          className="mx-3 text-info"
                          size="lg"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => { setUserId(null); setSupplierId(null); setModalUpdateStatus(false) }}
                        />
                      </div>
                    </div>
                  </div>

                  <hr className='w-100 my-1' style={{ borderBottom: '1px solid' }} />

                  <div>
                    <Form.Group as={Col} md="5" controlId="formInfoCompanySubscription">
                      <Form.Label>Tem certeza que deseja alterar o status?</Form.Label>
                      <Controller
                        control={control}
                        name="updateStatus"
                        rules={{ required: { value: true, message: 'Por favor, escolha uma opção.' } }}
                        defaultValue={currentReservation?.ativo === true ? "1" : "0"}
                        render={({ field }: any) => (
                          <Form.Select
                            {...field}
                            as="select"
                            variant="standard"
                            margin="normal"
                            style={{ border: "1px solid #ced4da" }}
                            required
                          >
                            <option value='0'>Ativar</option>
                            <option value='1'>Desativar</option>
                          </Form.Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="updateStatus"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                      />
                    </Form.Group>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="modal-footer rounded-0 border-0">
                    <button type="button" className="btn-default mr-2 text-uppercase btn btn-outline-primary" data-dismiss="modal" onClick={() => { setUserId(null); setSupplierId(null); setModalUpdateStatus(false) }}><i className="fas fa-times fa-fw"></i> <span data-vouchercampo="close">Cancelar</span></button>
                    <button type="submit" className="btn-default text-uppercase btn btn-primary">
                      {loading === true ? <div className="load"></div> : "Confirmar"}
                    </button>
                  </div>
                </Modal.Footer>
              </Form>
            </div>
          </>
        </Modal>
      </>
    );
  } else if (clientCorp === null && loading === true) {
    return (
      <>
        <div className="table-default reservations-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default reservations-table not-found">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">Clientes</h3>
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
};

export default CorporateClientTable;
