import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import './SessionBillsToReceive.scss'

//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import TableSessionBillsReceive from '../components/TableSessionBillsReceive/TableSessionBillsReceive';
import DesktopDefault from '../../../templates/DesktopDefault';


function SessionBillsToReceive() {
    //const { t } = useTranslation();
    const [bills, setBills] = useState<any>(null);
    const [error, setError] = useState<any>();

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    /* Filter */
    const [sessionId, setSessionId] = useState<any>('');
    const [sessionType, setSessionType] = useState<any>(1);
    /* END - Filter */

    /* session data */
    const [sessionReloc, setSessionReloc] = useState<any>('');
    const [sessionDesc, setSessionDesc] = useState<any>('');
    const [sessionDate, setSessionDate] = useState<any>('');
    const [sessionOperationType, setSessionOperationType] = useState<any>(1);
    /* END - session data */

    useEffect(() => {
        const session = window.location.href.split('session=')[1].replace('#', '');

        const sessionIdTemp = session.substring(1);

        const sessionTypeTemp = session.substring(0,1);

        const sessionTypeTemp2 = (sessionTypeTemp == 'S')? 1 : 2;

        setSessionId(sessionIdTemp);
        setSessionType(sessionTypeTemp2);
        
        
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        async function listSuppliers() {
            try {
                const { data } = await api.post('SessionClosure/GetSessionBillsToReceive',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "sessionId": sessionIdTemp,
                        "sessionType": sessionTypeTemp2
                    }, config
                );

                if (data.status !== 400) {
                    setBills(data.data.rows);
                    setSessionReloc(data.data.sessionReloc);
                    setSessionDesc(data.data.descricao);
                    setSessionDate(data.data.data);
                    setSessionOperationType(data.data.operationType);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listSuppliers();
    }, [pageCount, rowsPerPage]);

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
//console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
          let link = document.createElement('a');
          link.href = blob;//window.URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
          link.remove();
          window.URL.revokeObjectURL(link.href);
        //}
    };

    async function refreshData() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const { data } = await api.post('SessionClosure/GetSessionBillsToReceive',
                {
                    "page": pageCount,
                    "rowsPerPage": rowsPerPage,
                    "sessionId": sessionId,
                    "sessionType": sessionType
                }, config
            );

            if (data.status !== 400) {
                setBills(data.data.rows);
                setSessionReloc(data.data.sessionReloc);
                setSessionDesc(data.data.descricao);
                setSessionDate(data.data.data);
                setSessionOperationType(data.data.operationType);
                setTotalRows(data.data.rowsCount);
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    }

    async function exportReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/ExportSessionBillsToReceive',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "sessionId": sessionId,
                    "sessionType": sessionType
                }, config
            );
            if (res.status !== 400) {
                //download file
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/PrintSessionBillsToReceive',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "sessionId": sessionId,
                    "sessionType": sessionType
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    if (bills !== null) {
        return (
            <>
                <DesktopDefault>
                        <div className="container-fluid content-dashboard">
                            <Breadcrumb title={'Financeiro / Fechamento / Contas a Receber'} />
                            <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                                <h2 className="title" style={{ color: "#707070" }}>{`Session - ${sessionReloc} - ${sessionDesc}`}</h2>
                            </div>
                            <TableSessionBillsReceive
                                bills={bills}
                                totalRows={totalRows}
                                pageCount={pageCount}
                                setPageCount={setPageCount}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                exportReport={exportReport}
                                printReport={printReport}
                                sessionReloc={sessionReloc}
                                sessionDate={sessionDate}
                                sessionOperationType={sessionOperationType}
                                refreshData={refreshData}
                            />
                        </div>
                </DesktopDefault>
            </>
        );
    } else {
        return (
            <>
            </>
        )
    }
}

export default SessionBillsToReceive;