import React from 'react';

import { Row, Col } from 'react-bootstrap';

import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import TableInvoice from './TableInvoice/TableInvoice';

function Invoice() {

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    return (
        <>
            <div className='modal-content-note pt-1 pb-4'>
                <Row>
                    <Col md={3}>
                        <p className='mb-1'><strong>Agente</strong></p>
                        <span>Richard Salles</span>
                    </Col>
                    <Col md={3}>
                        <p className='mb-1'><strong>Vencimento</strong></p>
                        <span>24/03/2022</span>
                    </Col>
                </Row>
            </div>
            <div>
                <TableInvoice />
            </div>
        </>
    )
}

export default Invoice;