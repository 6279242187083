import React, { useState, useEffect } from 'react';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";

import TimePicker from "react-multi-date-picker/plugins/time_picker";

import './Calendar.scss';


const CustomMultipleInput = ({ openCalendar, value, date, setValue, errors }: any) => {

  setValue(value);

  return (
    <input
      className={errors?.dataPgto ? "form-control bg-white frm-sm w-100 rounded endpoint-error" : "form-control bg-white frm-sm w-100 rounded"}
      onFocus={openCalendar}
      value={value}
      readOnly
      placeholder="Quando?"
      autoComplete='off'
    />
  )
}


const SingleCalendarTimeCupom = ({date, setValue = () => { }, field, errors = undefined }: any) => {
  const { t } = useTranslation();
  const [aux, setAux] = useState<any>(date);


  /* useEffect(()=>{
    setValue('date',aux);
  },[aux]) */

  return (
    <DatePicker
      {...field}
      value={aux}
      //onChange={(e: any) => {setAux(e.year + "-" + e.month + "-" + e.day) }}
      weekDays={t('calendar.weekDays').split(',')}
      months={t('calendar.months').split(',')}
      format={`${configData.DATE.PT} HH:mm`}
      render={<CustomMultipleInput date={aux} setValue={setValue} errors={errors} />}
      plugins={[
        <TimePicker
          hideSeconds
          style={{ minWidth: "100px" }}
        />
      ]}
    />
  )
}

export default SingleCalendarTimeCupom;



