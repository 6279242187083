import React, { Key, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../../../assets/sass/modal.scss";
import "./FormBillPayEdit.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';

import GetAccountingAccountsFilter from "../../../../../components/C2Points/GetAccountingAccountsFilter";
import GetSuppliersFilter from "../../../../../components/C2Points/GetSuppliersFilter";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../../../services/api';

export interface propModal {
  action: any,
  info: any,
  sessionId: any,
  sessionDate: any
};

const FormBillPayEdit: React.FC<propModal> = ({
  action, info, sessionId, sessionDate
}: propModal, props: any) => {
  const { t } = useTranslation();
  //const [tipo, setTipo] = useState<string>(tipo);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contasaPagarId: info.contasaPagarId ? info.contasaPagarId : 0,
      manifestId: info.manifestId ? info.manifestId : 0,
      tipo: info.tipo ? info.tipo : 3,
      sessionId: sessionId,
      contaContabil: info.contaContabilId ? info.contaContabilId : '',
      descricao: info.descricao ? info.descricao : '',
      data: sessionDate.substring(0, 10),
      vencimento: info.vencimento ? info.vencimento.substring(0, 10) : '',
      valor: info.valor ? info.valor.toFixed(2).replace('.', ',') : '',
      fornecedor: info.fornecedor ? info.fornecedorId : '',
      observacao: info.observacao ? info.observacao : '',
      totalPax: info.totalPax ? info.totalPax : '',
      totalAdult: info.totalAdult ? info.totalAdult : '',
      totalChild: info.totalChild ? info.totalChild : '',
      totalInfant: info.totalInfant ? info.totalInfant : ''
    },
  });

  const handleSaveClick = (dataObj: any) => {
    var route = '';
    //console.log(dataObj.fornecedor, "fornecedor");
    if (dataObj.fornecedor && dataObj.fornecedor.supplierId) dataObj.fornecedor = dataObj.fornecedor.supplierId;
    if (dataObj.contaContabil && dataObj.contaContabil.id) dataObj.contaContabil = dataObj.contaContabil.id;

    if (dataObj.valor) dataObj.valor = parseFloat(dataObj.valor.replace(",", "."));

    dataObj.totalPax = (dataObj.totalPax) ? parseInt(dataObj.totalPax) : null;
    dataObj.totalAdult = (dataObj.totalAdult) ? parseInt(dataObj.totalAdult) : null;
    dataObj.totalChild = (dataObj.totalChild) ? parseInt(dataObj.totalChild) : null;
    dataObj.totalInfant = (dataObj.totalInfant) ? parseInt(dataObj.totalInfant) : null;

    dataObj.recorrente = 2;
    dataObj.notaDebito = 2;
    dataObj.arquivo = null;

    if (info.manifestId <= 0) {
      //console.log('insert');
      route = 'InsertBill';
    } else {
      //console.log('edit');
      route = 'UpdateBill';
    }

    let userData = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');
    const user = userData.user;

    dataObj.uid = user.uId;
    dataObj.usuario = `${user.nome} ${user.lastName}`;

    const config = {
      headers: { 'Authorization': `Bearer ${userData.token.token}` },
    };

    const salvar = async () => {
      try {

        const { data } = await api.post(`BillsPayable/${route}`, dataObj, config);
        /* console.log(data.data); */
        if (data.status !== 400) {
          var response = data.data;

          alert(response.texto);

          if (response.log != 1) {
            action(dataObj);
          }
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
        if (error?.response?.status === 400) {
          //window.location.href = window.location.origin + '/session-closure';              
        }
        //console.log(error);
      }
    }
    salvar();
  };

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <h5 className="contact-title mb-5">Altera Conta Pagar</h5>
            <Form
              className="form-add-contact"
              noValidate
            >
              <div className="">
                <Row className="">
                  <Form.Group as={Col} md="8" className="border-right">
                    <Row className="mb-3">
                      <Form.Group as={Col} md="5">
                        <Form.Label>Categoria:</Form.Label>
                        {info.tipo == 3 ?
                          <Controller
                            control={control}
                            name="contaContabil"
                            rules={{ required: { value: true, message: 'Por favor, informe o tipo.' } }}
                            render={({ field }: any) => (
                              <GetAccountingAccountsFilter propsField={field} propsErrors={errors} propsLabel='Categoria' />
                            )}
                          />
                          : <Form.Group as={Col} md="12"><span className="p-2">{info.contaContabil}</span></Form.Group>}
                      </Form.Group>
                      <Form.Group as={Col} md="7">
                        <Form.Label>Descrição:</Form.Label>
                        <Controller
                          control={control}
                          name="descricao"
                          rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              aria-invalid={errors?.descricao ? "true" : ""}
                              variant="standard"
                              margin="normal"
                              disabled={info.tipo !== 3}
                              autoComplete="off"
                            />
                          )}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      {sessionId == '' ?
                        <Form.Group as={Col} md="3">
                          <Form.Label>Data Operação:</Form.Label>
                          <Controller
                            control={control}
                            name="data"
                            rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                type="date"
                                aria-invalid={errors?.data ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                              />
                            )}
                          />
                        </Form.Group>
                        : <></>}
                      <Form.Group as={Col} md="3">
                        <Form.Label>Vencimento:</Form.Label>
                        <Controller
                          control={control}
                          name="vencimento"
                          rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="date"
                              aria-invalid={errors?.vencimento ? "true" : ""}
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              required
                            />
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3">
                        <Form.Label>Valor:</Form.Label>
                        <Controller
                          control={control}
                          name="valor"
                          rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              aria-invalid={errors?.valor ? "true" : ""}
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              required
                            />
                          )}
                        />
                      </Form.Group>
                    </Row>
                    {sessionId != '' ?
                      <Row className="mb-3">
                        <Form.Group as={Col} md="2">
                          <Form.Label>QTD:</Form.Label>
                          <Controller
                            control={control}
                            name="totalPax"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                type="text"
                                aria-invalid={errors?.totalPax ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                disabled={info.tipo !== 3}
                                autoComplete="off"
                              />
                            )}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                          <Form.Label>ADT:</Form.Label>
                          <Controller
                            control={control}
                            name="totalAdult"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                type="text"
                                aria-invalid={errors?.totalAdult ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                disabled={info.tipo !== 3}
                                autoComplete="off"
                              />
                            )}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                          <Form.Label>CHD:</Form.Label>
                          <Controller
                            control={control}
                            name="totalChild"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                type="text"
                                aria-invalid={errors?.totalChild ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                disabled={info.tipo !== 3}
                                autoComplete="off"
                              />
                            )}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                          <Form.Label>INF:</Form.Label>
                          <Controller
                            control={control}
                            name="totalInfant"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                type="text"
                                aria-invalid={errors?.totalInfant ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                disabled={info.tipo !== 3}
                                autoComplete="off"
                              />
                            )}
                          />
                        </Form.Group>
                      </Row>
                      : <></>}
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12">
                        <Form.Label>Fornecedor:</Form.Label>
                        {info.tipo == 3 ?
                          <Controller
                            control={control}
                            name="fornecedor"
                            rules={{ required: { value: true, message: 'Por favor, informe o tipo.' } }}
                            render={({ field }: any) => (
                              <GetSuppliersFilter propsField={field} propsErrors={errors} propsLabel='Fornecedor' />
                            )}
                          />
                          : <Form.Group as={Col} md="12"><span className="p-2">{info.fornecedor}</span></Form.Group>}
                      </Form.Group>
                    </Row>
                    {/* <Row className="mb-3">
                      <Form.Group as={Col} md="12">
                        <Form.Label>Centro Custo:</Form.Label>
                        
                      </Form.Group>
                    </Row> */}
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12">
                        <Form.Label>Observação:</Form.Label>
                        <Controller
                          control={control}
                          name="observacao"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              aria-invalid={errors?.observacao ? "true" : ""}
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              required
                            />
                          )}
                        />
                      </Form.Group>
                    </Row>
                  </Form.Group>
                </Row>
              </div>
            </Form>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {/* <Button className="form-button mr-3" onClick={props.onHide}>
            Cancelar
          </Button> */}
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(handleSaveClick)}
          >
            <FontAwesomeIcon
              icon={["fal", "save"]}
              size="1x"
              style={{ marginRight: "5px" }}
            /> Salvar
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormBillPayEdit;
