import React, { Key, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

import Modal from "react-bootstrap/Modal";

//STYLES
import "./MerchantsTablePosDevices.scss";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";
import ModalDefault from "../../../../../components/Modal/ModalDefault/ModalDefault";
import FormAddPosDevice from "../../../../../components/Forms/FormAddPosDevice/FormAddPosDevice";


export interface propModal {
  action: any
  info: any
};

const MerchantsTablePosDevices: React.FC<propModal> = ({
  action, info
}: propModal) => {
  const { t } = useTranslation();
  const [posDeviceModalShow, setPosDeviceModalShow] = React.useState(false);
  const [posDeviceEdit, setPosDeviceEdit] = React.useState(null);
  // const suppliers = objectSuppliers.suppliers.default[0];

  const [controlObject, setControlObject] = useState<any>(info ? info : []);
  const [deletedObjects, setDeletedObjects] = useState<any>([]);

  const handleActionModal = (objectModal: any) => {
    setControlObject([...controlObject, objectModal],);
  }

  const handleActionEditModal = (objectModal: any) => {
    setPosDeviceEdit(null);

    var aux: any = [...controlObject];

    //console.log(objectModal);
    for (var i: any = 0; i < aux.length; i++) {
      if (aux[i].id === objectModal.id) {

        aux.splice(i, 1, objectModal);

        break;
      }
    }
    setControlObject(aux);
  }

  useEffect(() => {
    action(controlObject, deletedObjects);
  }, [controlObject, deletedObjects])


  function removeRow(index: any) {

    //console.log(controlObject)

    var aux: any = [];

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== index) {
        aux.push(controlObject[i]);
      } else {
        if (controlObject[i].id > 0) setDeletedObjects([...deletedObjects, controlObject[i].id]);
      }
    }

    setControlObject(aux);
  }

  function editRow(index: any) {
    var aux: any = controlObject[index];
    // chama modal
    setPosDeviceEdit(aux);
    setPosDeviceModalShow(true);
  }

  return (
    <>
      <div className="table-add-default merchants-pos-table">
        <div className="table-container">
          <Table striped bordered>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Nº Lógico</th>
                <th>Aluguel</th>
                <th>Alocação</th>
                <th>Responsavel</th>
                <th>Telefone</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {controlObject.length > 0
                ? controlObject.map((info: any, index: Key) => (
                  <tr key={index}>
                    <td>{info.tipo}</td>
                    <td>{info.numeroLogico}</td>
                    <td>{info.aluguel}</td>
                    <td>{info.alocacao}</td>
                    <td>{info.responsavel}</td>
                    <td>{info.telefoneResp}</td>
                    <td>{
                      <div className="center-table options-table">
                        <Dropdown drop="down" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => editRow(index)} eventKey="1"><FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />{t('suppliersTable.edit')}</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => removeRow(index)} eventKey="2" className="text-danger removeRow"><FontAwesomeIcon icon={['fal', 'trash-alt']} className="mr-2" />{t('suppliersTable.remove')}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    }</td>
                  </tr>
                ))
                : ""
                }
              <div className="add-contact">
                <div className="text-center btn-add-contact">
                  <ModalDefault
                    title="Máquina POS"
                    classModal="modal-pos-tax"
                    name={'+'}
                    class="btn-add"
                  >
                    <div className="form-add-contact">
                      <FormAddPosDevice
                        action={handleActionModal}
                        info={null}
                      />
                    </div>
                  </ModalDefault>
                </div>
              </div>
            </tbody>
          </Table>

          {
            posDeviceEdit !== null
              ?
              <Modal
                className="modal-custom modalAuth modal-pos-tax"
                show={posDeviceModalShow}
                onHide={() => setPosDeviceModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header closeButton>
                    <h3>Máquina POS</h3>
                  </Modal.Header>
                  <hr style={{ margin: "30px 0" }} />
                  <FormAddPosDevice
                    action={handleActionEditModal}
                    info={posDeviceEdit}
                  />
                </>
              </Modal>
              :
              <></>
          }
        </div>
      </div>
    </>
  );
}

export default MerchantsTablePosDevices;
