import React from 'react';
/* import { Row, Col } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"; */

export interface propForm {
    control: any,
    errors: any,
};

const PaymentCheckoutBilled: React.FC<propForm> = ({
    control, errors
}: propForm) => {


    return (
        <>
            {/* <h3>Dinheiro:</h3>

            <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentCashAmount">
                    <Form.Label>Valor pago:</Form.Label>
                    <Controller
                        control={control}
                        name="amount"
                        rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="amount"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentCashDate">
                    <Form.Label>Data:</Form.Label>
                    <Controller
                        control={control}
                        name="CashDate"
                        rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="CashDate"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />

                </Form.Group>
            </Row> */}
        </>
    );
}

export default PaymentCheckoutBilled;