import React from 'react';
import { Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface propCard {
    info: any,
};

const DetailsCkeckoutTickets: React.FC<propCard> = ({
    info
}: propCard) => {
    return (
        <>
            <Card className="card-unit bg-white">
                <div className="container-fluid">
                    <FontAwesomeIcon
                        className='icon-primary'
                        icon={["fad", "ticket"]}
                        size="1x"
                        style={{ marginBottom: "5px", width: "25px", fontSize: "1.5rem" }}
                    />
                    <h3 className="details-title">{info.productName}</h3>
                    <div className="details-date d-flex justify-content-between">
                        <small>Data: {info.date.split('-').reverse().join('/')}</small>
                        {/* <small>
                            {
                                info.time !== undefined ? `Hora: ${info.time}` : ""
                            }
                        </small> */}
                    </div>

                    {/* <div className='details-boarding'>
                        <p><strong>Embarque:</strong>{info.tourEmbarque}</p>
                        <p><strong>Endereço:</strong>{info.meetingPoint}</p>
                        <p><strong>Hora do Embarque:</strong>{info.tourHoraEmbarque}</p>
                    </div> */}

                    <hr />

                    {info.sellingType === 1 ?
                        <div className="details-price">
                            <div className="price-per">
                                <p>Pessoas:</p>
                                <p>{info.totalPeople}x</p>
                                <p>R$ {(info.priceGlobalPeople).toFixed(2).replace('.', ',')}</p>
                            </div>
                        </div>
                        :
                        <div className="details-price">
                            {info.adults > 0
                                ?
                                <div className="price-per">
                                    <p>Adultos:</p>
                                    <p>{info.adults}x</p>
                                    <p>R$ {(info.priceAdults).toFixed(2).replace('.', ',')}</p>
                                </div>
                                :
                                <></>
                            }
                            {info.childs > 0
                                ?
                                <div className="price-per">
                                    <p>Crianças:</p>
                                    <p>{info.childs}x</p>
                                    <p>R$ {(info.priceChilds).toFixed(2).replace('.', ',')}</p>
                                </div>
                                :
                                <></>
                            }
                            {info.infants > 0
                                ?
                                <div className="price-per">
                                    <p>Infantos:</p>
                                    <p>{info.infants}x</p>
                                    <p>R$ {(info.priceInfants).toFixed(2).replace('.', ',')}</p>
                                </div>
                                :
                                <></>
                            }
                            {info.elders > 0
                                ?
                                <div className="price-per">
                                    <p>Idosos:</p>
                                    <p>{info.elders}x</p>
                                    <p>R$ {(info.priceElders).toFixed(2).replace('.', ',')}</p>
                                </div>
                                :
                                <></>
                            }
                            {info.student > 0
                                ?
                                <div className="price-per">
                                    <p>Estudantes:</p>
                                    <p>{info.student}x</p>
                                    <p>R$ {(info.priceStudent).toFixed(2).replace('.', ',')}</p>
                                </div>
                                :
                                <></>
                            }
                        </div>

                    }


                    <hr />

                    <div className='total-price'>
                        <p><strong>R${((info.adults * info.priceAdults) + (info.childs * info.priceChilds) + (info.infants * info.priceInfants) + (info.elders * info.priceElders) + (info.student * info.priceStudent) + ((info.globalPeople || 0) * info.priceGlobalPeople)).toFixed(2).replace('.', ',')}</strong></p>
                    </div>
                </div>
            </Card>
        </>
    );

}

export default DetailsCkeckoutTickets;