import React from "react";

import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";

import iconMachine from '../../../../assets/icons/credit_card_machine_dataphone.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './ModalCancelSitef.scss';

function ModalCancelSitef(props: any) {

    return (
        <div>
            <Modal
                {...props}
                className={
                    props.classModal
                        ? "modal-custom modalAuth " + props.classModal
                        : "modal-custom modalAuth"
                }
                show={props.modalCancel}
                /* onHide={() => {
                    props.setModalCancel(false);
                    //props.createSession(null);
                }} */
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalBody className="cancel-sitef text-center d-flex align-items-center justify-content-between py-4 px-2">
                    {
                        props.management === "aprovado"
                            ?
                            <>
                                <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    style={{ fontSize: "7.5em" }}
                                />
                                <div>
                                    <span style={{ position: "relative", bottom: "35px" }}>
                                        {/* Cancelamento realizado com sucesso!
                                        <br /> */}
                                        <div dangerouslySetInnerHTML={{ __html: props.infosMaquina }}></div>
                                    </span>
                                </div>
                                <div>
                                    <button className="btn btn-outline-primary mx-2" onClick={() => window.location.reload()}>
                                        Sair
                                    </button>
                                </div>
                            </>
                            :
                            props.management === "error"
                                ?
                                <>
                                    <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="5x"
                                        style={{ fontSize: "7.5em" }}
                                    />
                                    <div>
                                        <span style={{ position: "relative", bottom: "35px" }}>
                                            <div dangerouslySetInnerHTML={{ __html: props.infosMaquina }}></div>
                                        </span>
                                    </div>
                                    <div>
                                        <button className="btn btn-outline-primary mx-2" onClick={() => window.location.reload()}>
                                            Sair
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    {props.infosMaquina === "Insira ou passe ou aproxime o cartao na leitora" ? <img src={iconMachine} alt="icon machine" style={{ width: "120px" }} /> : <div className="cancel-load"></div>}
                                    <div>
                                        <span style={{ position: "relative", bottom: "35px" }}>{props.infosMaquina}</span>
                                    </div>
                                    <div>
                                        {
                                            props.continueReady === true
                                                ?
                                                <button className="btn btn-outline-primary" onClick={() => props.changeContinua(-1)}>
                                                    Cancelar
                                                </button>
                                                :
                                                <></>
                                        }
                                    </div>
                                </>
                    }
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalCancelSitef;
