import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './reducer';
// import type { RootState, AppDispatchProduct } from './product';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// export const useAppDispatchProduct = () => useDispatch<AppDispatchProduct>()
// export const useAppSelectorProduct: TypedUseSelectorHook<RootStateProduct> = useSelector

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

