import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
//import { useTranslation } from 'react-i18next';
//import Select from 'react-select/dist/declarations/src/Select';
import api from '../../services/api';

export interface propPlus {
    propsField: any,
    setFilterLog: any
};

const GetBilheteriaLogGroup: React.FC<propPlus> = ({
    propsField, setFilterLog
}: propPlus) => {

    const [info, setInfo] = useState<any>(null);

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        
        async function listSuppliers() {
            try {
                const { data } = await api.get(`/DropDown/GetBilheteriaLogGroup`, config);
                if (data.status !== 400) {
                    setInfo(data.data)
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listSuppliers();
    }, []);

    const handleChangeLog = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterLog(newValue);
    }

    if (info !== null) {
        return (
            <>
                <Form.Select
                    {...propsField}
                    label="Canal"
                    as="select"
                    variant="standard"
                    margin="normal"
                    onChange={(e: any) => handleChangeLog(e)}
                >
                    <option value='' selected>Todos</option>
                    {info.length > 0
                        ? info.map((elem: any, index: number) => {
                            return (
                                <option key={index} value={elem.id}>{elem.description}</option>
                            );
                        })
                        :
                        <></>
                    }
                </Form.Select>
            </>
        );
    } else {
        return (
            <></>
        )

    }
}

export default GetBilheteriaLogGroup;