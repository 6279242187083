import React, { Key, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../../../assets/sass/modal.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';
import NewUser from "../../../../../pages/Users/NewUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import api from '../../../../../services/api';

import GetPaymentMethodsFilter from "../../../../C2Points/GetPaymentMethodsFilter";
import GetAccountOperatorsFilter from "../../../../C2Points/GetAccountOperatorsFilter";
import ModalQuestion from "../../../../Modal/ModalQuestion/ModalQuestion";
//import infoProducts from "../../../../InfoProducts/InfoProducts";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

export interface propModal {
  action: any,
  info: any
};

const FormBillReceivableEdit: React.FC<propModal> = ({
  action, info
}: propModal, props: any) => {
  const { t } = useTranslation();

  function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if (cell && cell != '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }

  }

  const [formaPagamento, setFormaPagamento] = useState<any>({
    id: info.formaPagamentoId,
    descricao: info.formaPagamento,
    possuiMdr: 2,
    possuiOperador: 2,
    tipo: 2,
    alias: 'CASH'
  });

  const [valorAtual, setValorAtual] = useState<any>(info.valor ? formatValue(info.valor) : 0);
  const [mdr, setMdr] = useState<any>(info.mdr ? formatValue(info.mdr) : 0);
  const [loading, setLoading] = useState<any>(false);

  const [billConciliateModalShow, setBillConciliateModalShow] = useState<any>(false);
  const [billToConciliate, setBillToConciliate] = useState<any>(null);
  const [conciliateMsg, setConciliateMsg] = useState<any>('');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: info.id,
      tipo: info.tipo, // 1 = pagamento, 2 = parcela
      formaPagamento: info.formaPagamento ? info.formaPagamentoId : '',
      operadora: info.operadora ? info.operadoraId : '',
      valor: info.valor ? formatValue(info.valor) : '',
      mdr: info.mdr ? formatValue(info.mdr) : '',
      vencimento: info.vencimento ? info.vencimento.substring(0, 10) : '',
      nsu: info.nsu ? info.nsu : '',
      observacao: info.observacao ? info.observacao : '',
    },
  });

  const onSubmitForm = (dataObj: any) => {
    dataObj.valor = valorAtual;
    dataObj.mdr = mdr;

    if (formaPagamento.possuiMdr == 1 && mdr == '') {
      alert('preencha o valor do mdr');
      return;
    }

    if (dataObj.formaPagamento && dataObj.formaPagamento.id) dataObj.formaPagamento = dataObj.formaPagamento.id;
    if (dataObj.operadora && dataObj.operadora.id) dataObj.operadora = dataObj.operadora.id;
    if (!dataObj.operadora) dataObj.operadora = 0;

    if (dataObj.valor != '') {
      dataObj.valor = parseFloat(dataObj.valor.replace(',', '.'));
    } else {
      dataObj.valor = 0;
    }
    if (dataObj.mdr != '') {
      dataObj.mdr = parseFloat(dataObj.mdr.replace(',', '.'));
    } else {
      dataObj.mdr = 0;
    }


    let userData = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');
    const user = userData.user;

    dataObj.uid = user.uId;
    dataObj.usuario = `${user.nome} ${user.lastName}`;

    const config = {
      headers: { 'Authorization': `Bearer ${userData.token.token}` },
    };

    setLoading(true);

    const salvar = async () => {
      try {
        const { data } = await api.post('BillsReceivable/UpdateBill', dataObj, config);
        /* console.log(data.data); */
        if (data.status !== 400) {
          var response = data.data;

          alert(response.texto);

          if (response.log != 1) {
            action(data);
          }
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
        if (error?.response?.status === 400) {
          //window.location.href = window.location.origin + '/session-closure';              
        }
        //console.log(error);
      }

      setLoading(false);
    }
    salvar();
  };

  const onConciliate = (data: any) => {

    const agora = new Date();
    const agoraStr = convertUTCDateToLocalDate(agora);

    let userData = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');
    const user = userData.user;

    const usuario = `${user.nome} ${user.lastName}`;

    setConciliateMsg('"Conciliado por ' + usuario + ' em ' + agoraStr + '" confirma?');
    setBillToConciliate(data);
    setBillConciliateModalShow(true);
  };

  function handleActionBillConciliateModal(e: any) {
    setBillConciliateModalShow(false);

    billToConciliate.conciliate = 1;

    onSubmitForm(billToConciliate);
  }

  function handleSetOperadora(operadora: any) {

    var mdr = 0;
    var valorAtualF = valorAtual && valorAtual != '' ? valorAtual.replace(',', '.') : 0;

    if (operadora.tipoTaxa == 1) { // 
      mdr = operadora.taxa;
    } else {
      mdr = valorAtualF * operadora.taxa / 100;
    }
    console.log(mdr);
    if (mdr) setMdr(formatValue(mdr));
    else setMdr('');
  }

  function handleValorChange(e: any) {
    const newValue = e.target.value;
    setValorAtual(newValue);
  }

  function handleMdrChange(e: any) {
    const newValue = e.target.value;
    setMdr(newValue);
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };

    async function listSuppliers() {
      try {
        const { data } = await api.get(`BillsReceivable/GetPaymentMethodById/${info.formaPagamentoId}`, config);

        if (data.status !== 400) {
          setFormaPagamento(data.data);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
      try {
        if (info.operadorId) {
          const { data } = await api.get(`BillsReceivable/GetAccountOperatorById/${info.operadorId}`, config);

          if (data.status !== 400) {
            handleSetOperadora(data.data);
          }
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <h5 className="contact-title mb-5">Alterar Pagamento</h5>
            <Form
              className="form-add-contact"
              noValidate
            >
              <div className="">
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Forma Pagamento:</Form.Label>
                    {info.tipo != 2 ?
                      <Controller
                        control={control}
                        name="formaPagamento"
                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <GetPaymentMethodsFilter propsField={field} propsErrors={errors} propsLabel='Forma Pagamento' setPaymentMethod={setFormaPagamento} />
                        )}
                      />
                      :
                      <span className="p-2">{info.formaPagamento}</span>
                    }
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>Valor:</Form.Label>
                    <Controller
                      control={control}
                      name="valor"
                      //rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          type="text"
                          aria-invalid={errors?.valor ? "true" : ""}
                          variant="standard"
                          margin="normal"
                          onChange={handleValorChange}
                          value={valorAtual}
                          autoComplete="off"
                          required
                        />
                      )}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>MDR:</Form.Label>
                    {formaPagamento.possuiMdr == 1 ?
                      <Controller
                        control={control}
                        name="mdr"
                        //rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}
                            type="text"
                            aria-invalid={errors?.mdr ? "true" : ""}
                            onChange={handleMdrChange}
                            value={mdr}
                            variant="standard"
                            margin="normal"
                            autoComplete="off"
                            required
                          />
                        )}
                      />
                      :
                      <Controller
                        control={control}
                        name="mdr"
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}
                            type="text"
                            variant="standard"
                            margin="normal"
                            disabled={true}
                            autoComplete="off"
                          />
                        )}
                      />}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Operadora / Bandeira:</Form.Label>
                    {info.tipo != 2 ?
                      (formaPagamento.possuiOperador == 1 ?
                        <Controller
                          control={control}
                          name="operadora"
                          rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                          render={({ field }: any) => (
                            <GetAccountOperatorsFilter propsField={field} propsErrors={errors} propsLabel='Operadora / Bandeira' contaAdquirente={(formaPagamento.tipo == 1) ? 2 : 1} setAccountOperator={handleSetOperadora} />
                          )}
                        />
                        :
                        <Controller
                          control={control}
                          name="operadora"
                          render={({ field }: any) => (
                            <select className="form-control" disabled={true}></select>
                          )}
                        />)
                      :
                      <span className="p-2">{info.operadora}</span>
                    }
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>NSU:</Form.Label>
                    {info.tipo != 2 ?
                      <Controller
                        control={control}
                        name="nsu"
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}
                            type="text"
                            variant="standard"
                            margin="normal"
                            disabled={formaPagamento.possuiMdr !== 1 || info.tipo === 2}
                            autoComplete="off"
                          />
                        )}
                      />
                      :
                      <span className="p-2">{info.nsu}</span>
                    }
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Vencimento:</Form.Label>
                    <Controller
                      control={control}
                      name="vencimento"
                      rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          type="date"
                          aria-invalid={errors?.vencimento ? "true" : ""}
                          variant="standard"
                          margin="normal"
                          required
                          autoComplete="off"
                        />
                      )}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12">
                    <Form.Label>Observação:</Form.Label>
                    <Controller
                      control={control}
                      name="observacao"
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          type="text"
                          variant="standard"
                          margin="normal"
                          autoComplete="off"
                          required
                        />
                      )}
                    />
                  </Form.Group>
                </Row>
              </div>
            </Form>

            <ModalQuestion
              modalShow={billConciliateModalShow}
              setModalShow={setBillConciliateModalShow}
              titulo="Conciliar Pagamento"
              mensagem={conciliateMsg}
              handleActionConfirmModal={handleActionBillConciliateModal}
            //handleActionCancelModal={handleActionCancelDeleteClick}
            />
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {info.statusPagamento == 1 ?
            <Button
              type="button"
              className="form-button mr-2"
              onClick={handleSubmit(onConciliate)}
              disabled={loading == true}
            >
              <FontAwesomeIcon
                icon={["fal", "check-circle"]}
                size="1x"
                style={{ marginRight: "5px" }}
              /> Conciliar
            </Button>
            : <></>}
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(onSubmitForm)}
            disabled={loading == true}
          >
            <FontAwesomeIcon
              icon={["fal", "save"]}
              size="1x"
              style={{ marginRight: "5px" }}
            /> Salvar
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormBillReceivableEdit;
