import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ModalContentPayment.scss';
import api from '../../../services/api';

export interface propContent {
    modalContent: any,
    checkoutResponse: any;
    resetCart: any;
    setModalResponse: any;
    pix: boolean;
    getValues: any;
    watchLanguage?: any;
}


const ModalContentPayment: React.FC<propContent> = ({
    modalContent, checkoutResponse, resetCart, setModalResponse, pix, getValues, watchLanguage
}: propContent) => {
    const { t } = useTranslation();

    const [downloadTicketIntegration, setDownloadTicketIntegration] = useState<boolean>(false);
    const [urlDownload, setUrlDownload] = useState<string>('');
    const [fileName, setFileName] = useState<string>('Voucher');
    const [texts, setTexts] = useState<any>();
    const [scanResponse, setScanResponse] = useState<any>(null); // pixSuccess / pixFailed / pixExpired

    const [minutes, setMinutes] = useState<any>(10);
    const [seconds, setSeconds] = useState<any>(0);

    const downloadVou = () => {
        const link = document.createElement('a');
        link.href = urlDownload;
        link.setAttribute(
            'download',
            fileName,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);
    }

    const getVou = async () => {
        //setDownloadTicketIntegration(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
        };
        try {
            const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/checkout/PdfSecondWayHaveNovaXs/${checkoutResponse.data.data.reservationLoc}`, config);
            if (data.status !== 400) {
                if (data.data.log !== 1) {
                    setUrlDownload(`data:${data.data.data.voucherFile?.contentType};base64, ${data.data.data.voucherFile?.content}`);
                    setFileName(data.data.data.voucherFile?.fileName);
                    setDownloadTicketIntegration(false);
                }
            }
        } catch (error: any) {

        }
    };

    useEffect(() => {
        if (checkoutResponse !== '' && checkoutResponse?.data?.log === 0) {
            setUrlDownload(`data:${checkoutResponse.data.data.voucherFile?.contentType};base64, ${checkoutResponse.data.data.voucherFile?.content}`);
            setDownloadTicketIntegration(checkoutResponse.data.data.novaXsMultiClubs);
            setFileName(checkoutResponse.data.data.voucherFile?.fileName);

            if (checkoutResponse?.data?.data?.novaXsMultiClubs === true) {
                let myInterval = setInterval(() => {
                    getVou();
                }, 45000)

                return () => {
                    clearInterval(myInterval);
                };

                /* setTimeout(() => {
                    getVou();
                }, (checkoutResponse.data.data.awaitTime * 1000)); */
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkoutResponse])

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    useEffect(() => {
        let timer1 = setTimeout(() => setTexts(t("modalPayment.loading01")), 1000);
        let timer2 = setTimeout(() => setTexts(t("modalPayment.loading02")), 2000);
        let timer3 = setTimeout(() => setTexts(t("modalPayment.loading03")), 3000);
        let timer4 = setTimeout(() => setTexts(t("modalPayment.loading04")), 4000);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
            clearTimeout(timer4);
        };
    }, [t])

    useEffect(() => {
        if (modalContent === 'success' && pix === true) {
            if (checkoutResponse.data.log === 1 || checkoutResponse.data.log === '1') {
                setScanResponse("pixFailed");
            } else {
                setScanResponse(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalContent])


    function copyText(text: string | null = null) {
        if (text === null) {
            var text_to_copy = (document.getElementById("qrcodelink") as HTMLElement)
                .innerHTML;

            if (!navigator.clipboard) {
            } else {
                navigator.clipboard.writeText(text_to_copy);
            }
        } else {
            const urlOrigin: any = text.split("?")[0]
            const token: any = text.split("?")[1];
            let lang: any = "";

            if (watchLanguage === '1') {
                lang = "?";
            } else if (watchLanguage === '2') {
                lang = "/en?";
            } else {
                lang = "/es?";
            }

            const link: any = urlOrigin + lang + token;

            if (!navigator.clipboard) {
            } else {
                navigator.clipboard.writeText(link);
            }
        }
    }

    function whatsappFunction(link: string | null = null) {
        if (link !== null) {
            link = link.replaceAll(":", "%3A");
            link = link.replaceAll("/", "%2F");
            link = link.replaceAll("?", "%3F");
            let whatsappLink: string = `https://wa.me/${getValues("phone").replaceAll('+', '').replaceAll(' ', '')}?text=${link}`;
            return (whatsappLink)
        } else {
            return '';
        }
    }

    if (modalContent === 'loading') {
        return (
            <div className="sucess-pos" id="wrapper" >
                <div className="text-center">
                    <div className="load"></div>
                    <h5>{t("modalPayment.finalizingPurchase")}</h5>
                    <p>{texts}</p>
                </div>
            </div>
        );
    } else if (modalContent === 'success') {
        if (checkoutResponse.data.log === 0 && !pix) { //sucesso cartão
            return (
                <div className="sucess-pos" id="wrapper" >
                    <div className="text-center">
                        {/* <img className="mb-3" src={IconSucess} alt="Sucesso" /> */}
                        <FontAwesomeIcon
                            icon={["fal", "check-circle"]}
                            size="5x"
                            className='color-primary'
                        />
                        <h4 className='mt-4'>{t("modalPayment.creditCard.success.newOrder")}</h4>
                        <p className="color-success">{t("modalPayment.creditCard.success.purchaseSuccess")}</p>
                        <p>{t("modalPayment.creditCard.success.locNumber")} <strong>{checkoutResponse.data.data.reservationLoc}  </strong></p>
                        <p className="mb-0"><small>{t("modalPayment.creditCard.success.voucher")}</small></p>
                        {/* <p><small>{t("modalPayment.creditCard.success.contact")}</small></p> */}
                        {/* <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a> */}

                        <p><small><em>{t("modalPayment.creditCard.success.spam")}</em></small></p>

                    </div>
                    <div className='modal-btn-controller'>
                        {
                            checkoutResponse.data.data.voucherFile === null
                                ?
                                <></>
                                :
                                <>
                                    {
                                        downloadTicketIntegration === false
                                            ?
                                            <>
                                                <div
                                                    onClick={downloadVou}
                                                    className='btn btn-primary'
                                                // href={urlDownload}
                                                //download={checkoutResponse.data.data.voucherFile.fileName}
                                                >
                                                    Download de bilhete
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="load small primary"></div>
                                                <small>Gerando Voucher </small>
                                            </>
                                    }
                                </>
                        }
                        <Button variant="outline-primary" className="mt-5 mx-auto w-25" style={{ border: "1px solid" }} onClick={() => { resetCart() }}>
                            {t("modalPayment.creditCard.success.closeBtn")}
                        </Button>
                    </div>
                </div>
            );
        } else if (checkoutResponse.data.log === 1 || checkoutResponse.data.log === '1') { // Erro
            return (
                <div className="sucess-pos" id="wrapper" >
                    <div className="text-center">
                        {/* <img className="mb-3" src={IconSucess} alt="Sucesso" /> */}
                        <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                            className='color-danger'
                        />
                        <h4 className='mt-4'>{t("modalPayment.creditCard.error")}</h4>
                        <p>{checkoutResponse.data.texto}</p>
                        {/* <p>Tente novamente com outros dados de pagamento.<br />
                            Se persistir, entre em contato pelo e-mail <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a></p> */}
                    </div>
                    <div className='modal-btn-controller'>
                        <Button className="my-5 mx-auto w-25" style={{ border: "1px solid" }} onClick={() => { setModalResponse(null) }}>
                            {t("modalPayment.creditCard.success.closeBtn")}
                        </Button>
                    </div>
                </div>
            );
        } else if (pix === true) { //sucesso pix
            //const pixInfo = checkoutResponse.data.data[0].data;
            const isQRCode: boolean =
                checkoutResponse.data?.data.paymentLink !== undefined;
            const qrCodeInfo = isQRCode ? checkoutResponse?.data?.data : null;

            if (scanResponse === null && isQRCode) {
                return (
                    <>
                        <div className="pix-container bg-link-external pb-2">
                            <img className='logo-main' src={process.env.REACT_APP_SERVER_LOGO_SHORT} alt="Marca SIG" style={{ width: "140px", marginBottom: "1rem", padding: 0, cursor: 'pointer' }} />

                            <h6>Pague agora com um link externo</h6>
                            <p>Faça o pagamento em uma página externa. O pedido será enviado para uma página externa para a confirmação do pagamento a ser efetuado.</p>
                            <h4>Pague com o QR Code</h4>

                            <img
                                src={"data:image/png;base64," + (qrCodeInfo?.qrCode).toString()}
                                className="qr-code"
                                alt="QR Code"
                            />

                            <a className="whatsapp-link" href={whatsappFunction(qrCodeInfo?.paymentLink)} target="_blank" rel="noopener noreferrer">
                                <button className="btn whatsapp-btn text-uppercase d-flex">
                                    <div style={{ height: "18px" }}>
                                        <FontAwesomeIcon
                                            icon={['fab', 'whatsapp']}
                                        />
                                    </div>
                                    &nbsp;
                                    <strong>Whatsapp</strong>
                                </button>
                            </a>

                            <div className="modal-btn-controller">
                                <Button
                                    variant="outline-primary"
                                    className="mt-4 mx-auto"
                                    type="button"
                                    style={{ width: 'unset' }}
                                    onClick={() => { copyText(qrCodeInfo?.paymentLink) }}
                                >
                                    Copiar link
                                </Button>
                            </div>
                        </div>
                    </>
                );
            } else if (scanResponse === "pixFailed") {
                return (
                    <>
                        <div className="sucess-pos" id="wrapper" >
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    className='color-danger'
                                />
                                <h4 className='mt-4'>{t("modalPayment.pix.pixFailed.text01")}</h4>
                                <p>{t("modalPayment.pix.pixFailed.text02")}<br />
                                    {t("modalPayment.pix.pixFailed.text03")} {/* <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a> */}</p>
                            </div>
                            <div className='modal-btn-controller'>
                                <Button className="my-5 mx-auto" onClick={() => { setModalResponse(null) }}>
                                    {t("modalPayment.creditCard.success.closeBtn")}
                                </Button>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (<></>);
            }
        } else {
            return (<></>);
        }

    } else if (modalContent === 'error') {
        return (
            <div className="sucess-pos" id="wrapper" >
                <div className="text-center">
                    <FontAwesomeIcon
                        icon={["fal", "times-circle"]}
                        size="5x"
                        className='color-danger'
                    />
                    <h4 className='mt-4'>{t("modalPayment.pix.pixFailed.text01")}</h4>
                    <p>{t("modalPayment.pix.pixFailed.text02")}<br />
                        {t("modalPayment.pix.pixFailed.text03")} {/* <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a> */}</p>
                </div>
                <div className='modal-btn-controller'>
                    <Button className="my-5 mx-auto" onClick={() => { setModalResponse(null) }}>
                        {t("modalPayment.creditCard.success.closeBtn")}
                    </Button>
                </div>
            </div>
        );
    } else {
        return (<></>)
    }
}

export default ModalContentPayment;