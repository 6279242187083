import React, { Key, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../assets/sass/modal.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';

import GetFeeTermTypes from "../../C2Points/GetFeeTermTypes";
import GetMerchantFeeTerms from "../../C2Points/GetMerchantFeeTerms";
import GetFeeOrTax from "../../C2Points/GetFeeOrTax";
import GetMerchantFlags from "../../C2Points/GetMerchantFlags";


//import IconSucess from "../../../assets/icons/modal-sucess.svg";

export interface propModal {
  action: any,
  info: any
};

const FormAddFeeTerm: React.FC<propModal> = ({
  action, info
}: propModal, props: any) => {
  const { t } = useTranslation();
  const [feeTermType, setFeeTermType] = useState<string>(info ? info.tipo : '1');
  const [hasMinTax, setHasMinTax] = useState<string>(info ? info.temTaxaMin : '2');

  const [receiptDays, setReceiptDays] = useState<any>(Array.from({ length: 32 }, (_, i) => i));

  const getChangeFeeTermType = (feeTermType: any) => {
    setFeeTermType(feeTermType);

    if (feeTermType == 1) {
      setReceiptDays(Array.from({ length: 32 }, (_, i) => i));
    } else {
      setReceiptDays([0, 1]);
    }
  };

  const getChangeMinFeeYesNo = (e: any): any => {
    setHasMinTax(e.target.value);
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tipo: info ? info.tipo : '1',
      tipoTaxa: info ? info.tipoTaxa : '2',
      bandeira: info ? info.bandeira : '',
      taxa: info ? info.taxa : '',
      prazo: info ? info.prazo : '',
      temTaxaMin: info ? info.temTaxaMin : '2',
      taxaMin: info ? info.taxaMin : '',
      recebimento: info ? info.recebimento : '',
      id: info ? info.id : -1
    },
  });

  const onSubmitContact = (data: any) => {
    //data.id = id;
    //setId(id - 1);

    if (!info) {
      data.id = (Math.floor(Math.random() * 100000) + 1) * (-1);
    }

    data.tipo = feeTermType;
    data.temTaxaMin = hasMinTax;

    if (feeTermType == '3') {
      data.bandeira = '';
      data.prazo = '';
    }
    if (feeTermType == '1') {
      data.tipoTaxa = 2;
    }

    //console.log(data);

    action(data);
  };

  return (
    <>
      <Container className="bg-modal-contact p-0" fluid>
        <Row>
          {/* <h5 className="contact-title mb-5">Taxa e Prazo</h5> */}
          <Form
            className="form-add-contact"
            noValidate
          >
            <div className="">
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Tipo:</Form.Label>
                  <Controller
                    control={control}
                    name="tipo"
                    rules={{ required: { value: true, message: 'Por favor, informe o tipo.' } }}
                    render={({ field }: any) => (
                      <GetFeeTermTypes propsField={field} propsErrors={errors} propsLabel={t('merchants.merchantsform.feeTermType')} getChangeFeeTermType={getChangeFeeTermType} defaultValue={feeTermType} />
                    )}
                  />
                </Form.Group>
              </Row>
              {(feeTermType !== '3') ?
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Bandeira:</Form.Label>
                    <Controller
                      control={control}
                      name="bandeira"
                      rules={{ required: { value: true, message: 'Por favor, informe o Bandeira.' } }}
                      render={({ field }: any) => (
                        <GetMerchantFlags propsField={field} propsErrors={errors} propsLabel={t('merchants.merchantsform.flag')} />
                      )}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Prazo:</Form.Label>
                    <Controller
                      control={control}
                      name="prazo"
                      rules={{ required: { value: true, message: 'Por favor, informe o Prazo.' } }}
                      render={({ field }: any) => (
                        <GetMerchantFeeTerms propsField={field} propsErrors={errors} propsLabel={t('merchants.merchantsform.feeTerm')} />
                      )}
                    />
                  </Form.Group>
                </Row>
                :
                <></>
              }
              {(feeTermType != '1') ?
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Tipo Taxa:</Form.Label>
                    <Controller
                      control={control}
                      name="tipoTaxa"
                      rules={{ required: { value: true, message: 'Por favor, informe o Tipo Taxa.' } }}
                      render={({ field }: any) => (
                        <GetFeeOrTax propsField={field} propsErrors={errors} propsLabel={t('merchants.merchantsform.feeOrTax')} />
                      )}
                    />
                  </Form.Group>
                </Row>
                :
                <></>
              }
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Taxa:</Form.Label>
                  <Controller
                    control={control}
                    name="taxa"
                    rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        type="text"
                        aria-invalid={errors?.taxa ? "true" : ""}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        required />
                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Label>Taxa Minima ?:</Form.Label>
                  <Controller
                    control={control}
                    name="temTaxaMin"
                    rules={{ required: { value: true, message: 'Por favor, informe se tem taxa min.' } }}
                    render={({ field }: any) => (
                      <div className="endpoint">
                        <Form.Select
                          {...field}
                          aria-invalid={errors?.temTaxaMin ? "true" : ""}
                          label={t('merchants.merchantsform.hasMinTax')}
                          as="select"
                          variant="standard"
                          margin="normal"
                          onChange={getChangeMinFeeYesNo}
                          value={hasMinTax}
                          required
                        >
                          <option key={0} value={1}>Sim</option>
                          <option key={1} value={2}>Não</option>
                        </Form.Select>
                      </div>
                    )}
                  />
                </Form.Group>
                {hasMinTax === '1' ?
                  <Form.Group as={Col} md="6" controlId="validationCustom03">
                    <Form.Label>Taxa Min.:</Form.Label>
                    <Controller
                      control={control}
                      name="taxaMin"
                      rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          type="text"
                          aria-invalid={errors?.taxaMin ? "true" : ""}
                          variant="standard"
                          margin="normal"
                          autoComplete="off"
                          required />
                      )}
                    />
                  </Form.Group>
                  : <></>}
              </Row>
              {/* <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Recebimento:</Form.Label>
                    <Controller
                      control={control}
                      name="recebimento"
                      rules={{ required: { value: true, message: 'Por favor, informe o Recebimento.' } }}
                      render={({ field }: any) => (
                        <Form.Select
                          {...field}
                          aria-invalid={errors?.recebimento ? "true" : ""}
                          label={t('merchants.merchantsform.receipt')}
                          as="select"
                          variant="standard"
                          margin="normal"
                          required
                        >
                          <option value=''>Selecione</option>
                          {receiptDays.length > 0 ? receiptDays.map((info: any, index: Key) => (
                            <option key={index} value={info}>{info} {info == 1 ? 'Dia' : 'Dias' }</option>
                          )) : ''}
                        </Form.Select>
                      )}
                    />
                  </Form.Group>
                </Row> */}
            </div>
          </Form>
        </Row>
      </Container>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {/* <Button className="form-button mr-3" onClick={props.onHide}>
            Cancelar
          </Button> */}
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(onSubmitContact)}
          >
            Salvar
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormAddFeeTerm;
