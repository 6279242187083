import React, { useEffect, useState } from "react";

import api from "../../services/api";

import DesktopDefault from "../../templates/DesktopDefault";
import BannerHome from "./components/BannerHome/BannerHome";
import Transfers from "./components/Transfers/Transfers";
import TourList from "./components/TourList/TourList";
import TabSearchs from "./components/TabSearchs/TabSearchs";

function SalesCorporate() {
    const [search, setSearch] = useState<any>(null);
    const [selected, setSelected] = useState<any>(1);
    const [isLoading, setIsLoading] = useState<any>(false);

    useEffect(() => {
        async function getIdChannel() {
            const config = {
                headers: { "ngrok-skip-browser-warning": "69420" },
            };

            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/corporativo`, config);
                if (data.statusCode === 200) {
                    //searchTours('', data.data.data);
                }
            } catch (error) { }
        }

        getIdChannel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <DesktopDefault>
                <div className="navTabHome">
                    <BannerHome corporate={true} />
                    <TabSearchs
                        setSearch={setSearch}
                        setSelected={setSelected}
                        isLoading={isLoading}
                    />
                    {
                        selected === 1 && search !== null
                            ?
                            <TourList
                                search={search}
                                setSearch={setSearch}
                                setIsLoading={setIsLoading}
                            />
                            :
                            selected === 2 && search !== null
                                ?
                                <Transfers
                                    search={search}
                                    setSearch={setSearch}
                                />
                                :
                                <></>
                    }
                </div>
            </DesktopDefault>
        </>
    )
}

export default SalesCorporate;