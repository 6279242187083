import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import { Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//COMPONENTS
//import Menu from '../../components/Menu/Menu';
//import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../templates/DesktopDefault';
import CouponsFilter from './components/CouponsFilter/CouponsFilter';
import CouponsTable from './components/CouponsTable/CouponsTable';


import ModalDefault from '../../components/Modal/ModalDefault/ModalDefault';
import InsertCoupom from './components/InsertCoupom/InsertCoupom';

function Coupons() {
  const [coupons, setCoupons] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);

  /* Filtragem */
  //const [actualName, setActualName] = useState<any>("");
  const [actualDate, setActualDate] = useState<any>([]);
  //const [actualTypeDiscount, setActualTypeDiscount] = useState<any>(0);
  //const [actualTypeCommission, setActualTypeCommission] = useState<any>(0);
  //const [actualSupplier, setActualSupplier] = useState<any>(0);
  /* END - Filtragem */

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  function clearActualValues() {

    //setActualName("");
    setActualDate([]);
    //setActualTypeDiscount(0);
    //setActualTypeCommission(0);
    //setActualSupplier(0);
  }

  useEffect(() => {
    setLoading(true);
    setCoupons(null);

    let beginDate: any = []
    let endDate: any = []

    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/')
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/')
      endDate = actualDate[1].split('/')
    }

    //let today: any = new Date();

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listCoupons() {
      try {
        const { data } = await api.post('TourCode/GetTourCodeCupomByFilterAsync',
          {
            "page": pageCount,
            "rowsPerPage": rowsPerPage,
            "code": "",
            "typeDiscount": 0,
            "typeCommission": 0,
            "supplierId": 0,
            "beginDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : "",
            "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : "",
            "orderByField": ""
          }
          , config
        );

        if (data.status !== 400) {
          setLoading(false);
          setCoupons(data.data.rows);
          setTotalRows(data.data.rowsCount);
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }

      }
    }
    listCoupons();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  async function buscar(name: any, date: any, typeD: any, typeC: any, supplier: any) {
    setCoupons(null);
    setLoading(true);
    setActualDate(date);
    setPageCount(1);

    let beginDate = []
    let endDate = []

    if (date.length === 1) {
      beginDate = date[0].split('/')
    } else if (date.length === 2) {
      beginDate = date[0].split('/')
      endDate = date[1].split('/')
    }

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    try {
      const res = await api.post('TourCode/GetTourCodeCupomByFilterAsync',
        {
          "page": pageCount,
          "rowsPerPage": rowsPerPage,
          "code": name,
          "typeDiscount": typeD,
          "typeCommission": typeC,
          "supplierId": supplier,
          "beginDate": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : "",
          "endDate": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : "",
          "orderByField": ""
        }
        , config
      );

      if (res.status !== 400) {
        setLoading(false);
        setCoupons(res.data.data.rows);
        setTotalRows(res.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response.status === 401) {
        window.location.href = window.location.origin + '/';
      }

    }
  };

  function handleReload() {
    window.location.reload();
  }

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">

          <Breadcrumb title={'Cupons'} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>Cupons </h2>

            <ModalDefault
              title="Inserir Novo Cupom"
              name="Novo Cupom"
              icon="no"
              typeIcon="plus-circle"
              classBtn="btn btn-primary"
              classModal="modal-large"
            >
              <InsertCoupom
                show={setModalSuccess}
                setResponseText={setResponseText}
                setModalContent={setModalContent}
                setLog={setLog}
              />
            </ModalDefault>
          </div>
          <CouponsFilter
            buscar={buscar}
            coupons={coupons}
            clearActualValues={clearActualValues}
          />

          <CouponsTable
            coupons={coupons}
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            actualDate={actualDate}
            loading={loading}
            setModalSuccess={setModalSuccess}
            setResponseText={setResponseText}
            setModalContent={setModalContent}
            setLog={setLog}
          />


          {/* Modal de Success */}
          <Modal
            className={"modal-confirm loading-modal"}
            show={modalSuccess}
            onHide={() => setModalSuccess(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Body className="modal-body text-center sucess-pos">
                {modalContent !== "loading" ? (
                  <>
                    <div>
                      {log === 1 || log === "1" ? (
                        <FontAwesomeIcon
                          icon={["fal", "times-circle"]}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: "7.5em"}}
                        />
                      ) : log === 0 || log === "0" ? (
                        <FontAwesomeIcon
                          icon={["fal", "check-circle"]}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: "7.5em"}}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={["fal", "question-circle"]}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: "7.5em"}}
                        />
                      )}
                    </div>
                    <div>{responseText}</div>
                    <div className="d-flex justify-content-center">
                      {modalContent === "success" ? (
                        <button
                          onClick={handleReload}
                          className="btn btn-primary mx-2 w-25"
                        >
                          Confirmar
                        </button>
                      ) : (
                        <button
                          onClick={() => { setModalSuccess(false) }}
                          className="btn btn-primary mx-2 w-25"
                        >
                          Confirmar
                        </button>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
                )}
              </Modal.Body>
            </>
          </Modal>
          {/* END - Modal de Success */}
        </div>

      </DesktopDefault>
      {/* <div className="d-flex">
            <Menu /> 
                <Header />
                

                
            </div> */}

    </>

  );
}

export default Coupons;