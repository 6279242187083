import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import './PartnersFilter.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Controller, useForm } from "react-hook-form";

//Components
import RangeCalendar from "../../../../../components/Calendar/RangeCalendar"
import GlobalAlert from "../../../../../components/Alert/GlobalAlert/GlobalAlert";
import GetStatesFilter from "../../../../../components/C2Points/GetStatesFilter";
import GetSuppliersTypeFilter from "../../../../../components/C2Points/GetSuppliersTypeFilter";

function PartnersFilter(props: any) {

    const { t } = useTranslation();

    const [filterName, setFilterName] = useState<any>('');
    const [filterState, setFilterState] = useState<any>('');
    const [filterDate, setFilterDate] = useState<any>('');
    const [filterType, setFilterType] = useState<any>(0);
    const [filterStatus, setFilterStatus] = useState<any>(0);

    const [alert, setAlert] = useState<any>();

    const {
        control,
        //handleSubmit,
        //register,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = () => {
        props.buscar(filterName, filterState, filterType, filterStatus, filterDate);
    };

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterName("");
        setFilterState("");
        setFilterDate("");
        setFilterType(0)
        setFilterStatus(0);
        props.buscar("", "", 0, 0, []);
        /* console.log(filterName, filterState, filterType, filterStatus, filterDate) */
    };

    console.log(filterType)
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterName(newValue);
    }

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatus(newValue);
    }

    useEffect(() => {
        if (props.partners === 0 || props.error === true) {
            setAlert(
                <GlobalAlert alertType={"danger"}>
                    Não foi possível encontrar nenhum cliente.
                </GlobalAlert>
            )
            setTimeout(() => setAlert(null), 2000);
        } else {
            setAlert(null);
        }
    }, [props.partners, props.error])


    return (
        <div className="partners-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">{t('partners.partnersfilter.title')}</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                { alert }
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>{t('partners.partnersfilter.nameCod')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="name"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeName}
                                                            value={filterName}
                                                        />
                                                    )}
                                                />
                                                {/* <Form.Control
                                                    type="text"
                                                    onChange={handleChangeName}
                                                /> */}
                                                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('partners.partnersfilter.state')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="state"
                                                    render={({ field }: any) => (
                                                        <GetStatesFilter propsField={field} propsLabel={t('accordionInfoCompany.state')} propsErrors={errors} setFilterState={setFilterState} />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('partners.partnersfilter.entryDate')}</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('partners.partnersfilter.clientType')}</Form.Label>
                                                <InputGroup hasValidation>
                                                <Controller
                                                        control={control}
                                                        name="filterType"
                                                        render={({ field }: any) => (
                                                            <GetSuppliersTypeFilter propsField={field} propsErrors={errors} propsLabel={'Filter Type'} setFilterType={setFilterType} />
                                                        )}
                                                    />
                                                    {/* <Form.Control
                                                        type="text"
                                                        aria-describedby="inputGroupPrepend"
                                                        onChange={handleChangeType}
                                                    /> */}
                                                    {/* {<Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback>} */}
                                                </InputGroup>
                                            </Form.Group>
                                            
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('partners.partnersfilter.status')}</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatus(e)}
                                                        >
                                                            <option value="" selected disabled>Selecione status</option>
                                                            <option value="1">Em Análise</option>
                                                            <option value="2">Ativo</option>
                                                            <option value="3">Inativo</option>
                                                        </Form.Select>
                                                        {/* <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback> */}
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>


                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default PartnersFilter;