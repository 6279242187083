import React, { Key, useState, useEffect } from 'react';
import { Row, Col, Card } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//import Select from "react-select";
import deleteIcon from '../../../assets/icons/icon-garbage.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownNumberLuggage from '../../DropdownNumberLuggage/DropdownNumberLuggage';
//import GetPickups from '../../../../components/C2Points/GetPickups';

import { useAppDispatch } from "../../../store/hooks";
import { removeItemCart } from "../../../store/cart";
//import GetDDI from '../../C2Points/GetDDI';

export interface propCart {
    info: any,
    control: any,
    errors: any,
    data: any;
    setValue: any;
    mainContactObj?: any;
};

const CartCheckoutTransfer: React.FC<propCart> = ({
    info, control, errors, data, setValue, mainContactObj
}: propCart) => {
    const [numAdultos, setNumAdultos] = useState<any>([]);
    const [allowOthers, setAllowOthers] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        var aux: any = [];
        for (var i: any = 0; i < info.globalPeople; i++) {
            aux.push(i)
        }
        setNumAdultos(aux)
    }, [info])

    useEffect(() => {
        setValue(`dados[${data}].passengers.0.firstName`, mainContactObj?.name);
        setValue(`dados[${data}].passengers.0.lastName`, mainContactObj?.lastName);
        setValue(`dados[${data}].passengers.0.email`, mainContactObj?.email);
        setValue(`dados[${data}].passengers.0.phone`, mainContactObj?.phone);
    }, [data, setValue,/*  */ mainContactObj])

    const handleClickRemove = (productE: any) => {
        dispatch(removeItemCart(productE))
    };

    return (


        <>
            <Card className="cart-card-unit card-unit bg-white mt-4">
                <Card.Body>
                    <div className="card-title">
                        <div className="logo-button">
                            <FontAwesomeIcon
                                className='icon-primary'
                                icon={["fad", "car-bus"]}
                                size="1x"
                                style={{ marginBottom: "5px", width: "25px", fontSize: "1.5rem" }}
                            />
                            <button onClick={() => { handleClickRemove(info) }}><img src={deleteIcon} alt="delete" /></button>
                        </div>
                        <div className="mb-2" style={{ borderBottom: "1px solid rgb(112 112 112 / 19%)", paddingBottom: ".875rem" }}>
                            <h4>{info.productName}</h4>
                            <p><strong>Origem:</strong> {info.commingSource}</p>
                            <p><strong>Destino:</strong> {info.goingSource}</p>
                        </div>

                        <p>Fornecedor: <span>{info.supplierFantasyName}</span></p>
                    </div>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="formCheckoutCia">
                            <Form.Label>Cia Aéria:</Form.Label>
                            <Controller
                                control={control}
                                name="cia"
                                rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.cia ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="cia"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutFlight">
                            <Form.Label>Vôo:</Form.Label>
                            <Controller
                                control={control}
                                name="flight"
                                rules={{ required: { value: true, message: 'Por favor, informe o vôo.' } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.flight ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="flight"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutLuggagee">
                            <Form.Label>Malas:</Form.Label>
                            <Controller
                                control={control}
                                name={`dados[${data}].luggage`}
                                rules={{ required: { value: false, message: 'Por favor, informe o número de pessoas.' } }}
                                render={({ field }: any) => (
                                    <div className="panel-dropdown bg-white">
                                        <DropdownNumberLuggage index={data} setValue={setValue} />
                                    </div>
                                )}
                            />
                        </Form.Group>

                    </Row>

                    <hr />

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="formCheckoutDate">
                            <Form.Label>Data:</Form.Label>
                            <p>{info.date.split('-').reverse().join('/')}</p>
                            {/* <Controller
                    control={control}
                    name="date"
                    render={({ field }: any) => (
                      <SingleCalendar date={info.date} setValue={setValue} />
  
                    )}
                  /> */}
                            <ErrorMessage
                                errors={errors}
                                name="date"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutHour">
                            <Form.Label>Hora:</Form.Label>
                            <p>{info.time}</p>
                            {/* <Controller
                    control={control}
                    name="hour"
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors?.hour ? "true" : ""}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        required
                      />
                    )}
                  /> */}
                            <ErrorMessage
                                errors={errors}
                                name="hour"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutNumberOfPeople">
                            <Form.Label>Pessoas:</Form.Label>
                            {info.productType !== 2 ?
                                <p> <small>Adultos: {info.adults}</small></p>
                                :
                                <p> <small>Total: {info.globalPeople}</small></p>
                            }
                            {/* <Controller
                    control={control}
                    name="numberOfPeople"
                    render={({ field }: any) => (
                      <div className="col-6 col-sm-6 col-md-12">
                        <div className="panel-dropdown bg-white">
                          <DropdownNumberPeople
                            actual={1}
                            info={{ idSellingType: "2" }}
                            actionPeople={setNewNumberPeople}
                          ></DropdownNumberPeople>
  
                        </div>
                      </div>
                    )}
                  /> */}
                            <ErrorMessage
                                errors={errors}
                                name="numberOfPeople"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>

                    <hr />


                    <div className="participants">
                        <h3>Participantes das atrações</h3>

                        {numAdultos.length > 0
                            ? numAdultos.slice(0, allowOthers === false ? 1 : numAdultos.length).map((person: any, index: Key) => {
                                return (
                                    <>
                                        <div className='person-form' key={index}>
                                            <h4>Participante (Adulto)</h4>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonName">
                                                    <Form.Label>Nome:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.firstName`}
                                                        rules={{ required: { value: index === 0, message: 'Por favor, informe esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.firstName`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonLastname">
                                                    <Form.Label>Sobrenome:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.lastName`}
                                                        rules={{ required: { value: index === 0, message: 'Por favor, informe esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.lastName`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonEmail">
                                                    <Form.Label>Email:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.email`}
                                                        rules={{ required: { value: index === 0, message: 'Por favor, informe esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.email`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPhone" className='mb-3'>
                                                    <Form.Label>Telefone</Form.Label> 
                                                        <Controller
                                                            control={control}
                                                            name={`dados[${data}].passengers.${index}.phone`}
                                                            rules={{ required: { value: true, message: 'Por favor, informe o telefone.' } }}
                                                            render={({ field }: any) => (  
                                                                <Form.Control
                                                                    {...field}
                                                                    aria-invalid={errors?.compName ? "true" : ""}
                                                                    label="Telefone"
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    autoComplete="off"
                                                                    required
                                                                />   
                                                            )}
                                                        />                                           
                                            
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="phone"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                                </Form.Group>
                                            </Row>

                                        </div>
                                    </>
                                )
                            })
                            : ''}

                        <div>
                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { setAllowOthers(!allowOthers) }}>
                                {allowOthers === false ? "Adicionar informações dos outros adultos" : "Ocultar informações dos outros adultos"}
                            </span>
                        </div>
                    </div>

                    <hr />

                    <Row className="mb-3">
                        <Form.Group as={Col} md="8" className="form-textarea" controlId="formCheckoutNotes">
                            <Form.Label>Notas Internas:</Form.Label>
                            <Controller
                                control={control}
                                name="notes"
                                //rules={{ required: { value: true, message: 'Por favor, informe esse campo.' } }}
                                render={({ field }: any) => (
                                    <textarea
                                        {...field}
                                        className='form-control'
                                        aria-invalid={errors?.pickup ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="notes"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>

                </Card.Body>
            </Card>
        </>
    );
}

export default CartCheckoutTransfer;