
import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";


import { Controller, useForm } from "react-hook-form";

import './ReportBDBFilter.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
//import GetStatusNewReservations from "../../../../components/C2Points/GetStatusNewReservations";
import GetCaixaBilheteria from "../../../../components/C2Points/GetCaixaBilheteria";
import GetOperadoresCaixa from "../../../../components/C2Points/GetOperadoresCaixa";

function ReportBDBFilter(props: any) {

    const { t } = useTranslation();
    const [filterDate, setFilterDate] = useState<any>('');

    const {
        control,
        //handleSubmit,
        //register,
        getValues,
        //formState: { errors },
    } = useForm({});


    const handleFilterClick = () => {
        let data: any = getValues();
        console.log(data)

        props.buscar(filterDate, data.codigoCaixa, data.uidOperator);
        //console.log(filterName, filterState, filterType, filterStatus, filterDate)
    };

    const handleClearClick = (event: any) => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        props.buscar("", "", "");
    };

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Relatórios</span>
                        </Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t("reservations.filter.date")}</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>    
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Caixa</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="codigoCaixa"
                                                    render={({ field }: any) => (
                                                        <div className='endpoint custom-select'>
                                                            <GetCaixaBilheteria propsField={field} />
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Operador</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="uidOperator"
                                                    render={({ field }: any) => (
                                                        <div className='endpoint custom-select'>
                                                            <GetOperadoresCaixa propsField={field} />
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('reservations.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('reservations.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default ReportBDBFilter;