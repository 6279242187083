import React, { Key, useState, useEffect } from "react";
import api from '../../../../services/api';
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface propInfo {
    item: any;
    setAccessBtnLoad: any;
    setAccessBtnError: any;
    setAllowAccessCodeComponent: any;
    cancelAccessCode: any;
};

const AccessCodeComponent: React.FC<propInfo> = ({
    item, setAccessBtnLoad, setAccessBtnError, setAllowAccessCodeComponent, cancelAccessCode
}: propInfo) => {

    const [info, setInfo] = useState<any>([]);

    useEffect(() => {
        setAccessBtnLoad(true);
        const getAccessCode = async () => {
            const config = {
                headers: { "ngrok-skip-browser-warning": "69420" },
            };

            try {
                const { data } = await api.get(`/NewReservations/UpdateStatusSkidataReservationsByIdAsync/${item.idItem}`, config);

                if (data.status !== 400) {
                    setInfo(data.data);
                    setAccessBtnLoad(false);
                    setAccessBtnError(false);
                }
            } catch (error: any) {
                setAccessBtnLoad(false);
                setAllowAccessCodeComponent(false);
                setAccessBtnError(true);
            }
        }
        getAccessCode();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /*converte a data UTC para a data local do usuário*/
    function convertUTCDateToLocalDate(date: any, isHour: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        //var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours/*  - offset */);

        if (isHour === true) {
            return newDate.toLocaleString().split(' ')[1];
        } else {
            return newDate.toLocaleString().split(' ')[0];
        }
    }

    function convertDate(cell: any, isHour: any = false) {
        var date = convertUTCDateToLocalDate(new Date(cell), false);
        var hours = convertUTCDateToLocalDate(new Date(cell), true);
        if (isHour === true) {
            return (
                <>
                    {hours}
                </>
            );
        } else {
            return (
                <>
                    {date}
                </>
            );
        }
    }
    /*converte a data UTC para a data local do usuário*/



    return (
        <>
            {
                info.map((unit: any, index: Key) => {
                    return (
                        <>
                            <div className="d-flex justify-content-between access-code">
                                <div className={unit.activationTimestamp === null ? 'code-waiting' : 'code-used'} key={index} style={{ display: 'flex', justifyContent: "space-between", width: "100%" }}>
                                    <div className="d-flex">
                                        <span className='icon-code mr-2'>
                                            <FontAwesomeIcon
                                                icon={['fad', 'ticket']}
                                                className={unit.activationTimestamp === null ? "mx-2 text-info" : "mx-2 text-success"}
                                                size="lg"
                                            />
                                        </span>
                                        <span>
                                            <h6 className='w-auto'>
                                                Código
                                                {/* <div className="d-flex"> */}
                                                <small style={{ marginLeft: "0.5rem" }} >
                                                    <Badge className=" ml-2" bg={unit.activationTimestamp === null ? 'info' : 'success'}>{unit.activationTimestamp === null ? 'Em aberto' : 'Usado'}</Badge>
                                                </small>
                                                {unit.activationTimestamp !== null
                                                    ?
                                                    <>
                                                        <small style={{ marginLeft: "0.5rem" }} >
                                                            <Badge className=" ml-2" bg={unit.activationTimestamp === null ? 'info' : unit.partial === false ? 'blue' : 'warning'}>Skidata</Badge>
                                                        </small>
                                                        {unit.partial === false
                                                            ?
                                                            <>
                                                                <small style={{ marginLeft: "0.5rem" }} >
                                                                    <Badge className=" ml-2" bg={'blue'}>SIG</Badge>
                                                                </small>
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }
                                                {/* </div> */}
                                            </h6>
                                            <span className="response-modal">Nº do código: {unit.accessCode}</span>
                                            {
                                                unit.activationTimestamp === null
                                                    ?
                                                    <></>
                                                    :
                                                    <>
                                                        <span className="ml-3 response-modal text-strong">Data da visita:</span> <span className="response-modal">{convertDate(unit.activationTimestamp)} - {convertDate(unit.activationTimestamp, true)}</span>
                                                    </>
                                            }
                                        </span>
                                    </div>
                                    <div className='d-flex align-items-center h-100 gap-4'>
                                        <h6 className='mb-0'>
                                            {unit.faixaLabel}
                                        </h6>
                                        <h6 className='mb-0'>
                                            {unit.valorTicket.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </h6>
                                    </div>

                                </div>
                                {item.status === 1
                                    ?
                                    <>
                                        <div>
                                            {/* <Dropdown drop="start">
                                                <Dropdown.Toggle variant="light ">
                                                    <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="3" disabled={false} className="text-danger remove" onClick={() => { cancelAccessCode(index) }}>
                                                        <FontAwesomeIcon
                                                            icon={['fal', 'ban']}
                                                            className="mr-2"
                                                        />
                                                        <span>Cancelar código de acesso</span>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    )
                })
            }
        </>
    );
}

export default AccessCodeComponent;
