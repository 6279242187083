import React from 'react';
import { Row } from "react-bootstrap";
import PartnersTableUsers from '../../AccordionUsers/PartnersTableUsers/PartnersTableUsers';
//import logo from '../../logo.svg';

export interface propInfo {
    info: any;
    setChangeEdit: any;
    usersEdit?: any;
};

const EditUsers: React.FC<propInfo> = ({
    info, setChangeEdit, usersEdit
}: propInfo) => {

    return (
        <>
            <div id="usersData" className="d-flex">
                <div className="border bg-white">
                    <div className="edit-title container-fluid">
                        <h3 className="">Usuários</h3>
                        <button onClick={() => { setChangeEdit(false) }}>EDITAR</button>
                    </div>

                    <div style={{ margin: "0 24px 10px" }}>
                        <Row>
                            <PartnersTableUsers
                                infoEdit={info.usersSupplierDTO}
                                isEdit={true}
                                disableAddContact={true}
                                actionTableUser={()=>{}}
                                usersEdit={usersEdit}
                            />
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditUsers;