import React, { useState } from "react";
import api from "../../../../services/api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal } from 'react-bootstrap';

import DetailsReservation from '../DetailsReservation/DetailsReservation';

import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import '../../../../assets/sass/accordion.scss';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

//STYLES
import "./ReservationsTable.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import GetReasonCancel from "../../../../components/C2Points/GetReasonCancel";

import { Controller, useForm } from "react-hook-form";

export interface propTable {
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  reservations: any;
  confirm: any;
  log: any;
  cancelReservation: any;
  modalContent: any;
  setConfirm: any;
  setLog: any;
  handleModal: any;
  loading: any;
  handleNext: any;
  setReason: any;
  valReason: any;
  reloadTable: any;
}

const ReservationsTable: React.FC<propTable> = ({
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
  reservations,
  confirm,
  log,
  cancelReservation,
  modalContent,
  setConfirm,
  setLog,
  handleModal,
  loading,
  handleNext,
  setReason,
  valReason,
  reloadTable
}: propTable) => {

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const watchReason = watch("reason", false);
  setReason(watchReason);

  const { t } = useTranslation();
  const [editClick, setEditClick] = useState<any>(false);
  const [detailsModal, setDetailsModal] = useState<any>(false);
  const [currentReservation, setCurrentReservation] = useState<any>();

  const level = atob(JSON.parse(localStorage.getItem('bpaSigAuth') || '{}').user.perfil.nivel)

  function seeDetails(cell: any) {
    setEditClick(false);
    setCurrentReservation(cell);
    localStorage.setItem("idDetails", cell);
    setDetailsModal(true);
  }

  function seeDetailsEdit(cell: any) {
    setEditClick(true);
    setCurrentReservation(cell);
    localStorage.setItem("idDetails", cell);
    setDetailsModal(true);
  }

  function getVoucher(idItem: any) {
    // setCancelamentoModal(true);

    const getVou = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {
        const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/checkout/PdfSecondWay/${idItem}`, {

        }, config);
        if (data.status !== 400) {
          if (data.data.log === 0 || data.data.log === '0') {

            const link = document.createElement('a');
            link.href = `data:${data.data.data.voucherFile.contentType};base64, ${data.data.data.voucherFile.content}`;
            link.setAttribute(
              'download',
              data.data.data.voucherFile.fileName,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // // Clean up and remove the link
            link?.parentNode?.removeChild(link);
          } else if (data.data.log === 1 || data.data.log === '1') {
            alert(data.data.data.texto)
          }
        }
      } catch (error: any) {
      }
    };

    getVou();

  }

  function EmailTotemSecond(idItem: any, ticket: any) {
    // setCancelamentoModal(true);
    let lng = reservations.idioma === "PT" ? "BR" : reservations.idioma;

    const EmailTotem = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {
        const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/Checkout/EmailTotemSecondWay?IdItem=${idItem}&language=${lng}`, {

        }, config);
        if (data.status !== 400) {
          if (data.data.log === 0 || data.data.log === '0') {

            const link = document.createElement('a');
            link.href = `data:${data.data.data.voucherFile.contentType};base64, ${data.data.data.voucherFile.content}`;
            link.setAttribute(
              'download',
              data.data.data.voucherFile.fileName,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // // Clean up and remove the link
            link?.parentNode?.removeChild(link);
          } else if (data.data.log === 1 || data.data.log === '1') {
            alert(data.data.data.texto)
          }
        }
      } catch (error: any) {
      }
    };

    EmailTotem();
  }

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" onClick={() => { seeDetails(cell) }}><FontAwesomeIcon icon={["fal", "file-alt"]} className="mr-2" />{t('reservations.table.dropdown.details')}</Dropdown.Item>
              {parseInt(level) !== 26
                ?
                <>
                  {
                    row.source === "INTRANET" //ou INTRANET
                      ?
                      <>
                        {/* <Dropdown.Item eventKey="1" onClick={() => { seeDetails(cell) }}><FontAwesomeIcon icon={["fal", "file-alt"]} className="mr-2" />{t('reservations.table.dropdown.details')}</Dropdown.Item> */}
                        <Dropdown.Item eventKey="3" onClick={() => { getVoucher(cell) }}><FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />Download Voucher</Dropdown.Item>
                      </>
                      :
                      row.source === "TOTEM" && row.idMetodoPagamento !== 96
                        ?
                        <>
                          {/* <Dropdown.Item eventKey="1" onClick={() => { seeDetails(cell) }}><FontAwesomeIcon icon={["fal", "file-alt"]} className="mr-2" />{t('reservations.table.dropdown.details')}</Dropdown.Item> */}
                          {/* <Dropdown.Item eventKey="2" onClick={() => { seeDetailsEdit(cell) }}><FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />{t('reservations.table.dropdown.edit')}</Dropdown.Item> */}
                          <Dropdown.Item eventKey="3" onClick={() => { getVoucher(cell) }}><FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />Download Voucher</Dropdown.Item>
                          <Dropdown.Item eventKey="3" onClick={() => { EmailTotemSecond(cell, row) }}><FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />Download Recibo</Dropdown.Item>
                          {
                            row.status === 1 && level !== "29" && (row.source !== "BILHETERIA" && row.source !== "TOTEM")
                              ?
                              <Dropdown.Item eventKey="3" disabled={false} className="text-danger remove" onClick={() => {
                                handleModal(false)
                                setCurrentReservation(row)
                              }
                              }>
                                <FontAwesomeIcon
                                  icon={['fal', 'ban']}
                                  className="mr-2"
                                />
                                <span>{t('reservations.table.dropdown.remove')}</span>
                              </Dropdown.Item>
                              :
                              <></>
                          }
                        </>
                        :
                        row.source === "SIG"
                          ?
                          <>
                            <Dropdown.Item eventKey="3" onClick={() => { getVoucher(cell) }}><FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />Download Voucher</Dropdown.Item>
                            {/* <Dropdown.Item eventKey="3" onClick={() => { EmailTotemSecond(cell, row) }}><FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />Download Recibo</Dropdown.Item> */}
                            <Dropdown.Item eventKey="2" onClick={() => { seeDetailsEdit(cell) }}><FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />{t('reservations.table.dropdown.edit')}</Dropdown.Item>

                            {
                              row.status === 1 /* && level !== "29" */ && (row.source !== "BILHETERIA" && row.source !== "TOTEM")
                                ?
                                <Dropdown.Item eventKey="3" disabled={false} className="text-danger remove" onClick={() => {
                                  handleModal(false)
                                  setCurrentReservation(row)
                                }
                                }>
                                  <FontAwesomeIcon
                                    icon={['fal', 'ban']}
                                    className="mr-2"
                                  />
                                  <span>{t('reservations.table.dropdown.remove')}</span>
                                </Dropdown.Item>
                                :
                                <></>
                            }
                          </>
                          :
                          <>
                            {/* <Dropdown.Item eventKey="1" onClick={() => { seeDetails(cell) }}><FontAwesomeIcon icon={["fal", "file-alt"]} className="mr-2" />{t('reservations.table.dropdown.details')}</Dropdown.Item> */}
                            {
                              level !== "29"
                                ?
                                <Dropdown.Item eventKey="2" onClick={() => { seeDetailsEdit(cell) }}><FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />{t('reservations.table.dropdown.edit')}</Dropdown.Item>
                                :
                                <></>
                            }
                            <Dropdown.Item eventKey="3" onClick={() => { getVoucher(cell) }}><FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />Download Voucher</Dropdown.Item>
                            <Dropdown.Item eventKey="3" onClick={() => { EmailTotemSecond(cell, row) }}><FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />Download Recibo</Dropdown.Item>
                            {
                              row.status === 1 && ((row.source !== "BILHETERIA" || row.idMetodoPagamento === 96) && row.source !== "TOTEM")
                                ?
                                <Dropdown.Item eventKey="3" disabled={false} className="text-danger remove" onClick={() => {
                                  handleModal(false)
                                  setCurrentReservation(row)
                                }}>
                                  <FontAwesomeIcon
                                    icon={['fal', 'ban']}
                                    className="mr-2"
                                  />
                                  <span>{t('reservations.table.dropdown.remove')}</span>
                                </Dropdown.Item>
                                :
                                <></>
                            }
                          </>
                  }
                </>
                :
                <></>
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  function convertDate(cell: any) {
    var date = cell.split('T')[0].split('-').reverse().join('/');
    return (
      <>
        {date}
      </>
    );
  }

  function textReduce(cell: any, row: any) {
    if (cell !== null) {
      var aux = cell.split(' ')
      if (aux.length >= 4) {
        aux = aux[0] + ' ' + aux[1] + ' ' + aux[2] + ' ' + aux[3] + '...';
      } else {
        aux = cell;
      }

      return (
        <>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip">{cell}</Tooltip>
            }>
            <span className="text-wrap-custom">
              {cell}
            </span>
          </OverlayTrigger>
        </>
      )
    } else {
      return (
        <>

        </>
      )
    }
  }

  function addStatus(cell: any, row: any) {
    if (cell <= 2) {
      cell = row.status;
    }

    var status = (
      cell === 1 ? "Ativo" :
        cell === 2 ? "Cancelado" :
          cell === 3 ? "Processando" :
            cell === 4 ? "Carrinho abandonado" :
              cell === 5 ? "Fornecedor pendente" :
                cell === 6 ? "On_hold" :
                  cell === 7 ? "Pré reserva" :
                    cell === 9 ? "Pagamento não aprovado" :
                      ''
    );
    return (
      <div
        className={
          status === "Pré reserva"
            ? "bg-yellow"
            : status === "Ativo"
              ? "bg-ativo"
              : status === "Cancelado"
                ? "bg-inativo"
                : status === "Pagamento não aprovado"
                  ? "bg-notApproved"
                  : ""
        }
      >
        {status}
      </div>
    );
  }

  const columns = [
    { dataField: "dataEmissao", text: "Emissão", sort: true, formatter: convertDate },
    { dataField: "reservaC2", text: "Nº Reserva", sort: true },
    { dataField: "startTimeLocal", text: "Data Oper.", sort: true, formatter: convertDate },
    { dataField: "productName", text: "Produto", sort: true, formatter: textReduce },
    { dataField: "nomeTodoRevelado", text: "Titular", sort: true },
    { dataField: "quantidadePax", text: "Qtd Pax", sort: true },
    { dataField: "compName", text: "Cliente", sort: true, formatter: textReduce },
    { dataField: "source", text: "Canal", sort: true/* , formatter: textReduce */ },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: addStatus,
    },
    { dataField: "idNewItems", text: "Ação", formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando Reservas", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Reservas" }];

  const notFoundProducts = [{ clientes: t("reservations.table.notFound") }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row.status, rowIndex) */
    if (row.status === 7 && row.statusBooking === 7) {
      return 'border-pendente-escala';
    } else if (row.status === 1) {
      return 'border-ativo';
    } else if (row.status === 2) {
      return 'border-inativo';
    } else {
      return 'border-normal'
    }
  };

  function handleModalShow() {
    setValue('reason', '0');
  }

  if (reservations !== null) {
    return (
      <>
        <div className="table-default reservations-table">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">Reservas</h3>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={reservations}
              columns={columns}
              rowClasses={rowClasses}
            />

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>

        <Modal
          className="modal-custom reservation-details"
          show={detailsModal !== false}
          onHide={() => { setDetailsModal(false); setCurrentReservation(null); setEditClick(false); handleModalShow(); reloadTable() }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <DetailsReservation
              id={currentReservation}
              setDetailsModal={setDetailsModal}
              action={() => { handleModal(false) }}
              editClick={editClick}
              handleNext={cancelReservation}
              seeVoucherDownload={getVoucher}
            />
          </>
        </Modal>

        {/* Modal de Cancelamento de Reservas */}
        <div className='bg-modal-custom modal-cancel-reservation modal-close'>
          <div className='modal-confirm'>
            <div className='modal-content modal-small'>
              <div className="modal-body text-center px-5">
                {
                  modalContent !== "loading"
                    ?
                    <>
                      <div>
                        {
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              style={{ fontSize: "7.5em" }}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                style={{ fontSize: "7.5em" }}
                              />
                        }
                      </div>
                      {
                        (log === 0 || log === "0") || (log === 1 || log === "1")
                          ?
                          <div dangerouslySetInnerHTML={{ __html: confirm }}></div>
                          :
                          <div>
                            <p>{confirm}</p>
                            { }
                            <Controller
                              control={control}
                              name={`reason`}
                              //rules={{ required: { value: true, message: 'Por favor, informe esse campo.' } }}
                              render={({ field }: any) => (
                                <div className={valReason === true ? "endpoint-error" : "endpoint"} style={{ width: "75%", margin: "auto" }}>
                                  <GetReasonCancel propsField={field} propsErrors={errors} propsLabel="Motivo de Cancelamento" />
                                </div>
                              )}
                            />
                            {
                              valReason === true
                                ?
                                <small style={{ color: "red" }}>Por favor, informe o motivo do cancelamento</small>
                                :
                                ""
                            }
                          </div>
                      }
                      {
                        log === 1 || log === "1" || log === 0 || log === "0"
                          ?
                          <div>
                            <button className="btn btn-outline-primary mx-2" onClick={() => {
                              handleModal(false)
                              setConfirm("Qual é o motivo do cancelamento?");
                              setLog(undefined)
                              handleModalShow()
                              reloadTable()
                            }}>
                              Sair
                            </button>
                          </div>
                          :
                          <div className='space-modal'>
                            <button className="btn btn-outline-primary mx-2"
                              onClick={() => {
                                handleModal(false)
                                setConfirm("Qual é o motivo do cancelamento?");
                                setLog(undefined)
                                handleModalShow()
                                //setDetailsModal(true)
                                //setCurrentReservation(localStorage.getItem("idDetails"))
                              }}>
                              Recusar
                            </button>
                            <button
                              onClick={() => {
                                cancelReservation(currentReservation, currentReservation.idItem === undefined ? 1 : 2);
                                setConfirm("Qual é o motivo do cancelamento?");
                                setLog(undefined)
                                handleModalShow()
                              }}
                              data-btn="cancelItem"
                              title=""
                              id="73910"
                              data-controler="CDN2382"
                              data-toggle="modal"
                              className="btn btn-primary mx-2"
                            >
                              Confirmar
                            </button>

                          </div>
                      }
                    </>
                    :
                    <div className="modal-body">
                      <div className="loading-modal">
                        <div className="load"></div>
                      </div>
                      <div>
                        <span>Processando</span>
                      </div>
                      <div>

                      </div>

                    </div>
                }
              </div>
            </div>
          </div>
        </div>
        {/* END - Modal de Cancelamento de Reservas */}
      </>
    );
  } else if (reservations === null && loading === true) {
    return (
      <>
        <div className="table-default reservations-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default reservations-table not-found">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">Reservas</h3>
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ReservationsTable;
