import React, { useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import '../../../../assets/sass/accordion.scss';
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';


import GetCostCenter from '../../../../components/C2Points/GetCostCenter';
import GetAccountingAccounts from "../../../../components/C2Points/GetAccountingAccounts";
import GetYesOrNot from "../../../../components/C2Points/GetYesOrNot";
import DatePicker from "react-multi-date-picker";
import GetMovimentAccounting from "../../../../components/C2Points/GetMovimentAccounting";

export interface propAccordion {
    setChangeEdit: any,
    action: any,
    ContextAwareToggle: any,
    supplierControlObject: any,
    info: any,
    setStepCtrl: any,
    editSuppliers: any,
    scrollTop?: any
};

const AccordionAccount: React.FC<propAccordion> = ({
    setChangeEdit, action, ContextAwareToggle, supplierControlObject, info, setStepCtrl, editSuppliers, scrollTop
}: propAccordion) => {

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);
    const { t } = useTranslation();
    const validated = false;
    const hasContractControl = { value: true, message: 'Por favor, preencher esse campo.' };

    const [contract, setContrat] = useState<any>(null);
    const [contratName, setContratName] = useState<any>('');

    const [values, setValues] = useState<any>('null')

    const idSup = window.location.href.split('id=')[1]?.replace(/\#/g, '');

    const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
    const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

    const contractHandler = (e: any) => {
        console.log(errors)
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setContrat(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setContratName(e.target.files[0].name);
    }

    const CustomMultipleInput = ({ openCalendar, value }: any) => {
        return (
            <>
                <FontAwesomeIcon
                    icon={["fal", "calendar-alt"]}
                    size="1x"
                    style={{ color: "#000", position: "absolute", transform: "translate(10px, 10px)" }}
                />
                <input
                    className="form-control bg-white frm-sm w-100 pl-5 rounded"
                    onFocus={openCalendar}
                    value={value}
                    readOnly
                    name="richard"
                    autoComplete="off"
                />
            </>
        )
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            accountingAccounts: info ? info.accountingAccounts : supplierControlObject ? supplierControlObject.accountingAccounts : '',
            haveContract: info ? info.haveContract : supplierControlObject ? supplierControlObject.haveContract : '',
            validade: info ? info.validade : supplierControlObject ? supplierControlObject.validade : '',
            contractBase64: info ? info.contractBase64 : '',
            contaCorrente: info ? info.contaCorrente : supplierControlObject ? supplierControlObject.contaCorrente : '',
            costCenter: info ? info.costCenter : supplierControlObject ? supplierControlObject.costCenter : '',
        },
    });

    const onSubmit = (data: any) => {
        if (values === 'null') {

        } else {
            data.validade = values.format?.("YYYY-MM-DDTHH:mm:ss.SSZ");

            if (info === false) {
                /// ADD SUPPLIERS PAGE
                console.log(data)
                data.contractBase64 = contract;
                data.contractName = contract === null ? "sem_contrato.jpg" : contratName;
                data.accountingAccounts = data.accountingAccounts.id;
                data.contaCorrente = data.contaCorrente.id;
                data.haveContract = data.haveContract.id;
                data.costCenter = data.costCenter.id;
                setCtrlEventKey(true);
                action(data);

            } else {
                /// EDIT SUPPLIERS PAGE

                const aux: any = data;  //"aux" usado para garantir que todos os outros campos de informação do fornecedor sejam mantidos
                data = info;

                data.accountingAccounts = aux.accountingAccounts.id;
                data.contaCorrente = aux.contaCorrente.id;
                data.contractBase64 = contract;
                data.contractName = contract === null ? "sem_contrato.jpg" : contratName;
                data.costCenter = aux.costCenter.id;
                data.haveContract = parseInt(aux.haveContract.id);
                data.supplierId = idSup;
                data.validade = aux.validade;

                editSuppliers(data);
            }
        }

    };

    function handleCancel() {
        window.location.reload();
        /* setChangeEdit(true) */
    }

    return (
        <Card className="bg-white mt-4" id="editAccountingForms">
            <Card.Header id="accordionPersonal">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>{t('accordionAccount.title')}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0 mt-3" fluid>
                        <Row>
                            <div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="formAccount">
                                            <Form.Label>{t('accordionAccount.account')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="accountingAccounts"
                                                rules={{ required: { value: true, message: t('accordionAccount.validation.account') } }}
                                                render={({ field }: any) => (
                                                    <GetAccountingAccounts propsField={field} propsErrors={errors} propsLabel={t('accordionAccount.account')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="accountingAccounts"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" controlId="formAccountContract?">
                                            <Form.Label>{t('accordionAccount.hasCont')}?</Form.Label>
                                            <Controller
                                                control={control}
                                                name="haveContract"
                                                rules={{ required: { value: true, message: t('accordionAccount.validation.hasCont') } }}
                                                render={({ field }: any) => (
                                                    <GetYesOrNot propsField={field} propsErrors={errors} propsLabel={t('accordionAccount.hasCont')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="haveContract"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formAccountValid">
                                            <Form.Label>{t('accordionAccount.val')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="validade"
                                                render={({ field }: any) => (
                                                    <DatePicker
                                                        weekDays={t('accordionAccount.calendarPT') === 'pt' || t('accordionAccount.calendarPT') === 'es' ? weekDays : []}
                                                        months={t('accordionAccount.calendarPT') === 'pt' || t('accordionAccount.calendarPT') === 'es' ? months : []}
                                                        render={<CustomMultipleInput />}
                                                        onChange={setValues}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="validade"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2">
                                            <Form.Label>{t('accordionAccount.cont')}</Form.Label><br />
                                            <Controller
                                                control={control}
                                                name="contractBase64"
                                                //rules={{ required: { value: hasContractControl.value, message: 'Por favor, anexar o contrato' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        type="file"
                                                        {...field}
                                                        label={t('accordionAccount.cont')}
                                                        aria-invalid={errors?.contractBase64 ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        id="contract"
                                                        accept=".jpg"
                                                        disabled={!hasContractControl.value}
                                                        onChange={contractHandler}
                                                        autoComplete="off"
                                                        required />
                                                )}
                                            />
                                            {/* <Form.Control id="contract" type="file" accept=".jpg" required /> */}
                                            <label className="btn-file" htmlFor="contract">{t('accordionAccount.anex')}</label>
                                            <small>{contratName}</small>
                                            {/* <ErrorMessage
                                                errors={errors}
                                                name="contractBase64"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            /> */}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="formAccountCC">
                                            <Form.Label>{t('accordionAccount.c/c')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="contaCorrente"
                                                rules={{ required: { value: true, message: t('accordionAccount.validation.c/c') } }}
                                                render={({ field }: any) => (
                                                    <GetMovimentAccounting propsField={field} propsErrors={errors} propsLabel={"Conta Movimento"} />
                                                )}
                                            />
                                            {/* <Form.Control type="text"
                                                    {...field}
                                                    label={t('accordionAccount.c/c')}
                                                    aria-invalid={errors?.contaCorrente ? "true" : ""}
                                                    fullWidth
                                                    variant="standard"
                                                    margin="normal"
                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    required /> */}
                                            <ErrorMessage
                                                errors={errors}
                                                name="contaCorrente"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formAccountCenter">
                                            <Form.Label>{t('accordionAccount.coastCenter')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="costCenter"
                                                rules={{ required: { value: true, message: t('accordionAccount.validation.coastCenter') } }}
                                                render={({ field }: any) => (
                                                    <GetCostCenter propsField={field} propsErrors={errors} propsLabel={t('accordionAccount.coastCenter')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="costCenter"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                        {
                                            info === false
                                                ?
                                                <Button
                                                    type="button"
                                                    className="form-button back-btn"
                                                    onClick={() => { 
                                                        setStepCtrl(1)
                                                        scrollTop()
                                                    }}
                                                >
                                                    voltar
                                                </Button>
                                                :
                                                <Button
                                                    className="form-button cancel"
                                                    onClick={handleCancel}
                                                >
                                                    Cancelar
                                                </Button>}
                                        <Button
                                            type="submit"
                                            className="form-button"
                                            onClick={scrollTop}
                                        >
                                            {t('accordionInfoCompany.buttonContinue')}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionAccount;