import { Link } from "react-router-dom";

//STYLES
import "./TabSearchs.scss";
import GetCategorySpecialTicket from "../../../../components/C2Points/GetCategorySpecialTicket";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";

const TabSearchs = (props: any) => {

  const [isSelected, setIsSelected] = useState<boolean>(true);

  const cartSIG = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  if (localStorage.getItem("transferSIG") === null) {
    localStorage.setItem("transferSIG", '[]');
  }

  const handleClickSearch = () => {
    setIsSelected(true);
    const categorySpecial: any = getValues('categorySpecialTicket');
    
    if (categorySpecial) {
      props.setSearch(categorySpecial);
    } else {
      setIsSelected(false);
      setTimeout(() => {
        setIsSelected(true);
      }, 2000);
    }
  };

  useEffect(() => {
      setValue("categorySpecialTicket",
        {
          description: "Reagendamento Com Pax",
          id: "36",
          uid: "rescheduling_pax"
        }
      )
      handleClickSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tab-search-custom py-3 d-none">
      <nav className="px-3">
        <div className="row m-0 bg-white">
          <div
            className="nav nav-tabs col-12 d-flex justify-content-between border-bottom-0"
            id="nav-tab"
            role="tablist"
          >
            <div className="d-flex pt-2">
              <Link
                className="nav-item nav-link rounded-top active text-center display-6 text-uppercase text-muted nav-tab-choice"
                id="nav-1-tab"
                data-toggle="tab"
                data-btn="tab"
                data-linkcontent="nav-content-3"
                to="#"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                <span id="passeio">
                  Reagendamento
                </span>
              </Link>
            </div>
          </div>
        </div>
      </nav>
      {/* <!-- Barra de pesquisas--> */}
      <div
        className="tab-content"
        id="nav-tabContent"
        style={{ borderLeft: "1px solid #fff" }}
      >
        {/* <!--Nav Atividades --> */}
        <div
          className="tab-pane fade show active p-3 py-2 py-md-3"
          id="nav-3"
          role="tabpanel"
          aria-labelledby="nav-3-tab"
        >
          <div className="row d-flex bd-highlight">
            <div className="col col-12 col-md-8 col-lg-10 ">
              <Controller
                control={control}
                name="categorySpecialTicket"
                render={({ field }: any) => (
                  <GetCategorySpecialTicket propsField={field} propsErrors={errors} propsLabel="Bilhetes Especiais" isSelected={isSelected} />
                )}
              />
            </div>
            <div className="col col-12 col-md-4 col-lg-2 mt-3 mt-lg-0">
              <button
                className="btn btn-search text-white form-control py-0 rounded btn-lg"
                data-btn="search"
                type="button"
                id="button-search"
                onClick={handleClickSearch}
              >
                <span id="buscar-tour">Pesquisar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TabSearchs;
