import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

/* import './SuppliersFilter.scss';
 */import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function UsersFilter(props: any) {

    const { t } = useTranslation();

    const [filterName, setFilterName] = useState<any>('');
    const [filterLastName, setFilterLastName] = useState<any>('');
    const [filterEmail, setFilterEmail] = useState<any>('');
    const [filterStatus, setFilterStatus] = useState<any>(0);

    const handleFilterClick = () => {
        props.buscar(filterName, filterLastName, filterEmail, filterStatus);
    };

    const handleFilterClear = () => {
        setFilterName("");
        setFilterLastName("");
        setFilterEmail("");
        setFilterStatus(0)
        props.buscar("", "", "", 0);
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterName(newValue);
    }

    const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterLastName(newValue);
    }

    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterEmail(newValue);
    }

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatus(newValue)
    };

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Usuários</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Primeiro nome do usuário:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChangeName}
                                                    value={filterName}
                                                    autoComplete="off"
                                                />
                                                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Sobrenome do usuário:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChangeLastName}
                                                    value={filterLastName}
                                                    autoComplete="off"
                                                />
                                                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>E-mail do usuário:</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <Form.Control
                                                        type="text"
                                                        onChange={handleChangeEmail}
                                                        value={filterEmail}
                                                        autoComplete="off"
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Status:</Form.Label>
                                                <div className="endpoint">
                                                    <Form.Select
                                                        defaultValue={0}
                                                        onChange={(e: any) => { handleChangeStatus(e) }}
                                                        value={filterStatus}
                                                    >
                                                        <option selected value='0'>Todos</option>
                                                        <option value='1'>Ativo</option>
                                                        <option value='2'>Inativo</option>
                                                    </Form.Select>
                                                </div>


                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleFilterClear}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>


                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default UsersFilter;