import React, { useState, Key, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './Menu.scss';
import objectMenu from './locales/menu.json';
import objectMenuDev from './locales/menuDev.json';

// import logo from `../../assets/img/logo-c2tours.svg`;

import MenuPrimary from './MenuPrimary/MenuPrimary';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function Menu() {

    const [open, setOpen] = useState(false);
    const [menuName, setMenuName] = useState('.');

    /* Detecta clique fora da div#wrapper para fechar o menu */
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setMenuName('.');
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* //Detecta clique fora da div#wrapper para fechar o menu\\ */
    const level = atob(JSON.parse(localStorage.getItem('bpaSigAuth') || '{}').user.perfil.nivel);

    const allowedMenus: any = ['Reservas'];

    if(process.env.REACT_APP_PRODUCTION === "true") {
        return (
            <div id="wrapper" ref={wrapperRef}>
                <ul className="navbar-nav sidebar sidebar-dark toggled" id="accordionSidebar" style={{ zIndex: 11 }}>
                    {
                        objectMenu.menu.default.length > 0
                            ?
                            objectMenu.menu.default.map((menu: any, index: Key) => (
                                <>
                                    {
                                        (menu.level.find((element: any) => element === Number(level)) !== undefined) && !(parseInt(level) === 26 && !allowedMenus.includes(menu.name)) ?
                                            <li key={index} className="nav-item" title={menu.name}>
                                                {menu.href === "#"
                                                    ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        overlay={
                                                            <Tooltip id="tooltip">{menu.name}</Tooltip>
                                                        }>
                                                        <div style={{ cursor: "pointer" }} className="nav-link select" id="collapseDashboard" onClick={() => { setOpen(!open); setMenuName(menu.name) }} aria-controls={menu.id} aria-expanded={open}>
                                                            <FontAwesomeIcon icon={['fad', menu.icon]} />
                                                            <span className="menu-name">{menu.name}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        overlay={
                                                            <Tooltip id="tooltip">{menu.name}</Tooltip>
                                                        }>
                                                        <Link className="nav-link select" to={menu.href} id="collapseDashboard" onClick={() => { setOpen(!open); setMenuName(menu.name) }} aria-controls={menu.id} aria-expanded={open}>
                                                            <FontAwesomeIcon icon={['fad', menu.icon]} />
                                                            <span className="menu-name">{menu.name}</span>
                                                        </Link>
                                                    </OverlayTrigger>
    
                                                }
    
                                                {
                                                    menu.submenu.length > 0
                                                        ?
                                                        <MenuPrimary menu={menu} menuName={menuName} setMenuName={setMenuName} />
                                                        :
                                                        ''
                                                }
    
                                            </li>
                                            :
                                            <></>
                                    }
                                </>
                            ))
                            :
                            ''
                    }
                </ul>
            </div>
        );
    } else {
        return (
            <div id="wrapper" ref={wrapperRef}>
                <ul className="navbar-nav sidebar sidebar-dark toggled" id="accordionSidebar" style={{ zIndex: 11 }}>
                    {
                        objectMenuDev.menu.default.length > 0
                            ?
                            objectMenuDev.menu.default.map((menu: any, index: Key) => (
                                <>
                                    {
                                        (menu.level.find((element: any) => element === Number(level)) !== undefined) && !(parseInt(level) === 26 && !allowedMenus.includes(menu.name)) ?
                                            <li key={index} className="nav-item" title={menu.name}>
                                                {menu.href === "#"
                                                    ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        overlay={
                                                            <Tooltip id="tooltip">{menu.name}</Tooltip>
                                                        }>
                                                        <div style={{ cursor: "pointer" }} className="nav-link select" id="collapseDashboard" onClick={() => { setOpen(!open); setMenuName(menu.name) }} aria-controls={menu.id} aria-expanded={open}>
                                                            <FontAwesomeIcon icon={['fad', menu.icon]} />
                                                            <span className="menu-name">{menu.name}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        overlay={
                                                            <Tooltip id="tooltip">{menu.name}</Tooltip>
                                                        }>
                                                        <Link className="nav-link select" to={menu.href} id="collapseDashboard" onClick={() => { setOpen(!open); setMenuName(menu.name) }} aria-controls={menu.id} aria-expanded={open}>
                                                            <FontAwesomeIcon icon={['fad', menu.icon]} />
                                                            <span className="menu-name">{menu.name}</span>
                                                        </Link>
                                                    </OverlayTrigger>
    
                                                }
    
                                                {
                                                    menu.submenu.length > 0
                                                        ?
                                                        <MenuPrimary menu={menu} menuName={menuName} setMenuName={setMenuName} />
                                                        :
                                                        ''
                                                }
    
                                            </li>
                                            :
                                            <></>
                                    }
                                </>
                            ))
                            :
                            ''
                    }
                </ul>
            </div>
        );
    }
}

export default Menu;