import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Dropdown, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../components/Pagination/Pagination";

import './TransactionAuditTable.scss';

export interface propTable {
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    transactions: any;
    loading: any;
};

const TransactionAuditTable: React.FC<propTable> = ({
    totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, transactions, loading
}: propTable) => {
    const { t } = useTranslation();

    const [modalShow, setModalShow] = useState<any>(false);
    const [infoDetails, setInfoDetails] = useState<any>({});
    const [keys, setKeys] = useState<any>([]);

    const DetailsTransaction = (logId: any, info: any) => {
        setInfoDetails(info.propertiesJson);
        setModalShow(true);
    }

    function addActionButton(cell: any, row: any) {
        if (row.propertiesJson.LogGroup !== undefined) {
            return (
                <>
                    <div className="d-flex justify-content-center">
                        <Dropdown drop="start">
                            <Dropdown.Toggle variant="light ">
                                <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    eventKey="1"
                                    onClick={() => DetailsTransaction(cell, row)}
                                >
                                    <FontAwesomeIcon
                                        icon={["fal", "file-alt"]}
                                        className="mr-2"
                                    />
                                    {t('reservations.table.dropdown.details')}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div>
                        -
                    </div>
                </>
            )
        }
    }

    /*converte a data UTC para a data local do usuário*/
    function convertUTCDateToLocalDate(date: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString().split(' ')[0];
    }

    function convertDate(cell: any, row: any) {
        var date = convertUTCDateToLocalDate(new Date(cell));

        return (
            <>
                {
                    date === '01/01/1970'
                        ?
                        "-"
                        :
                        date
                }
            </>
        );
    }

    const columns = [
        { dataField: "logGroup", text: "Log", sort: true, formatter: textReduce },
        { dataField: "reference", text: "Referência", sort: true },
        { dataField: "timestamp", text: "Data", sort: true, formatter: convertDate },
        { dataField: "message", text: "Mensagem", sort: true },
        { dataField: "logId", text: "Ação", formatter: addActionButton },
    ];

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando Reservas", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "clientes", text: "Transações" }];

    const notFoundProducts = [{ clientes: t("reservations.table.notFound") }];
    /* [END] In case of empty table*/

    const rowClasses = (row: any, rowIndex: any) => {
        /* console.log(row.status, rowIndex) */
        if (row.status === 3) {
            return 'border-analise';
        } else if (row.status === 1) {
            return 'border-ativo';
        } else if (row.status === 2) {
            return 'border-inativo';
        } else {
            return '';
        }
    };

    const getKeysFromObject = () => {
        for (const key in infoDetails) {
            if (typeof infoDetails[key] !== 'object') {
                setKeys((prev: any) => [...prev, { chave: key, valor: infoDetails[key] }]);
            }
        }
    }

    useEffect(() => {
        if (modalShow === true && keys.length === 0) {
            getKeysFromObject();
        } else {
            setKeys([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow])

    function textReduce(cell: any, row: any) {
        if (cell !== null) {
            var aux = cell.split(' ')
            if (aux.length >= 4) {
                aux = aux[0] + ' ' + aux[1] + ' ' + aux[2] + ' ' + aux[3] + '...';
            } else {
                aux = cell;
            }

            return (
                <>
                    <OverlayTrigger
                        overlay={
                            <Tooltip id="tooltip">{cell}</Tooltip>
                        }>
                        <span className="text-wrap-custom text-wrap-custom-audit">
                            {cell}
                        </span>
                    </OverlayTrigger>
                </>
            )
        } else {
            return (
                <>

                </>
            )
        }
    }

    if (transactions !== null) {
        return (
            <>
                <div className="table-default audit-table">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="">Transações</h3>
                        </div>
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={transactions}
                            columns={columns}
                            rowClasses={rowClasses}
                        />

                        <Pagination
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            selectNumberRows="yes"
                        />

                        <Modal
                            className="modal-custom reservation-details transaction-details"
                            show={modalShow !== false}
                            onHide={() => { setModalShow(false) }}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <Modal.Header closeButton>
                                {
                                    infoDetails?.EntityLevel === 'Error'
                                        ?
                                        <div className="bg-inativo py-1">Erro</div>
                                        :
                                        infoDetails?.EntityLevel === 'Success'
                                            ?
                                            <div className="bg-ativo">Sucesso</div>
                                            :
                                            ""
                                }
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="w-100 mb-4 pb-2">
                                    {keys.length > 0 ?
                                        keys.map((elem: any, index: number) => {
                                            return (
                                                <>
                                                    <Col xs={6} key={index} className='mb-4'>
                                                        <h6>{elem.chave}</h6>
                                                        <b>{elem.valor}</b>
                                                    </Col>
                                                </>
                                            )
                                        })
                                        :
                                        <>
                                        </>
                                    }
                                </Row>
                                {/* <Row className="w-100 mb-4 pb-2">
                                    <Col xs={6}>
                                        <h6>Referência</h6>
                                        <b>{infoDetails?.Reference}</b>
                                    </Col>
                                    <Col xs={6}>
                                        <h6>Log</h6>
                                        <b>{infoDetails?.Entity}</b>
                                    </Col>
                                </Row>
                                <Row className="w-100 mb-4 pb-2">
                                    <Col xs={6}>
                                        <h6>Data</h6>
                                        <b>{infoDetails?.Horario?.split(" ")[0]}</b>
                                    </Col>
                                    <Col xs={6}>
                                        <h6>Horário</h6>
                                        <b>{infoDetails?.Horario?.split(" ")[1]}</b>
                                    </Col>
                                </Row>
                                <Row className="w-100">
                                    <Col xs={12}>
                                        <h6>Mensagem</h6>
                                        <b>{infoDetails?.Message}</b>
                                    </Col>
                                </Row> */}
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </>
        );
    } else if (transactions === null && loading === true) {
        return (
            <>
                <div className="table-default reservations-table loading not-found">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default reservations-table not-found">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="">Transações</h3>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default TransactionAuditTable;