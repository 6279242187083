import React from "react";
import { Card, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import sales from '../../../../assets/icons/salesTotal.svg';
import handHolding from '../../../../assets/icons/handHolding.svg';
import creditCard from '../../../../assets/icons/creditCard.svg';
import totalPaid from '../../../../assets/icons/totalPaid.svg';


import "./CommissionsBills.scss";

function CommissionsBills(props: any) {
  return (
    <>
      <div className="commissions-report">
        <Row className="cards">
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-8">
                <Row>
                  <span className="card-title">Total de Vendas</span>
                </Row>
                <Row>
                  <span className="cart-content">R$ XXXXX,XX</span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
                <FontAwesomeIcon
                  icon={["fad", "coins"]}
                  className="text-primary p-1"
                  size="3x"
                />
              </div>
            </Card>
          </div>
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-8">
                <Row>
                  <span className="card-title">Total a Receber</span>
                </Row>
                <Row>
                  <span className="cart-content">R$ XXXXX,XX</span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
                <img src={handHolding} alt="Total de vendas" />
              </div>
            </Card>
          </div>
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-8">
                <Row>
                  <span className="card-title">Total Pago</span>
                </Row>
                <Row>
                  <span className="cart-content">R$ XXXXX,XX</span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
                <img src={totalPaid} alt="Total de vendas" />
              </div>
            </Card>
          </div>
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-8">
                <Row>
                  <span className="card-title">Total de Comissão</span>
                </Row>
                <Row>
                  <span className="cart-content">R$ XXXXX,XX</span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
                <img src={creditCard} alt="Total de vendas" />
              </div>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
}

export default CommissionsBills;
