import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';

import { Controller, useForm } from 'react-hook-form';

import './CorporateTicketFilter.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Components
import RangeCalendar from '../../../../components/Calendar/RangeCalendar';
import GetClientsCorp from '../../../../components/C2Points/GetClientsCorp';
import GetSpecialTicketsCorp from '../../../../components/C2Points/GetSpecialTicketsCorp';

function CorporateFilter(props: any) {
  const { t } = useTranslation();

  const [filterRequester, setFilterRequester] = useState<any>('');
  const [filterId, setFilterId] = useState<any>('');
  const [filterDate, setFilterDate] = useState<any>('');
  const [filterStatusRequest, setFilterStatusRequest] = useState<any>(0);
  const [filterTypeDate, setFilterTypeDate] = useState<any>(1);

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({});

  const handleFilterClick = () => {
    let data: any = getValues();
    
    props.buscar(
      filterId,
      filterRequester,
      data.name,
      filterDate,
      filterStatusRequest,
      filterTypeDate,
      1,
      data.type
    );
  };

  const handleClearClick = (event: any) => {

    let inputValue = document.querySelectorAll('input');
    for (let i = 0; i < inputValue.length; i++) {
      inputValue[i].value = '';
    }

    let selectValue = document.querySelectorAll('select');
    for (let i = 0; i < selectValue.length; i++) {
      selectValue[i].value = '0';
    }

    setValue('name', '');
    setFilterRequester('');
    setFilterId('');
    setFilterDate('');
    setFilterStatusRequest(0);
    setFilterTypeDate(1);
    setValue('type', '');
    props.buscar('', '', 0, "", 0, 1, 1/* , '' */);
  };

  const handleRequester = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterRequester(newValue);
  };

  const handleChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterId(newValue);
  };

  const handleChangeTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterTypeDate(newValue);
  };

  return (
    <div
      className="suppliers-filter d-flex justify-content-center bg-white mt-4"
      style={{ textAlign: 'left' }}
    >
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['fal', 'sliders-h']}
                size="lg"
                className="mr-2"
              />
              <span className="h5 mb-0">Buscar Reagendamentos</span>
            </div>
          </Accordion.Header>
          <Accordion.Body className="pt-0">
            <Container className="p-0" fluid>
              <Row>
                <div>
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="3" className="mb-3">
                        <Form.Label>Cliente</Form.Label>
                        <Controller
                          control={control}
                          name="name"
                          render={({ field }: any) => (
                            <GetClientsCorp
                              propsField={field}
                              propsErrors={errors}
                            />
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3" className="mb-3">
                        <Form.Label>Solicitante</Form.Label>
                        <Controller
                          control={control}
                          name="text"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              onChange={handleRequester}
                              value={filterRequester}
                            />
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3" className="mb-3">
                        <Form.Label>Tipo de Data</Form.Label>
                        <div className="endpoint">
                          <InputGroup hasValidation>
                            <Form.Select
                              aria-describedby="inputGroupPrepend"
                              onChange={(e: any) => handleChangeTypeDate(e)}
                              value={filterTypeDate}
                            >
                              <option value="1" selected>
                                Data de Compra
                              </option>
                              <option value="2">Data de Passeio</option>
                            </Form.Select>
                          </InputGroup>
                        </div>
                      </Form.Group>
                      <Form.Group as={Col} md="3" className="mb-3">
                        <Form.Label>Data</Form.Label>
                        <InputGroup hasValidation className="ranger-calendar">
                          <RangeCalendar
                            date={filterDate}
                            setDate={setFilterDate}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="3">
                        <Form.Label>Status de Solicitação</Form.Label>
                        <Controller
                          control={control}
                          name="state"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(event: any) => {
                                  setFilterStatusRequest(event.target.value);
                                }}
                              >
                                <option value="0">Todos</option>
                                <option value="1">Ativo</option>
                                <option value="7">Pré-Reserva</option>
                                <option value="2">Cancelado</option>
                                <option value="4">Finalizado</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3" className="mb-3">
                        <Form.Label>Nº Solicitação</Form.Label>
                        <Controller
                          control={control}
                          name="id"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              onChange={handleChangeId}
                              value={filterId}
                            />
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3" className="mb-3">
                        <Form.Label>Tipo de Bilhete</Form.Label>
                        <Controller
                          control={control}
                          name="type"
                          render={({ field }: any) => (
                            <GetSpecialTicketsCorp
                              propsField={field}
                              propsErrors={errors}
                            />
                          )}
                        />
                          </Form.Group>
                    </Row>
                    <Row
                      as={Col}
                      md="12"
                      className="mt-5 justify-content-md-end"
                    >
                      <Col xs lg="12" className="d-flex justify-content-md-end">
                        <Button
                          variant="outline-primary"
                          className="btn-default mr-2 text-uppercase"
                          onClick={handleClearClick}
                        >
                          {t('reservations.btnClear')}
                        </Button>
                        <Button
                          className="btn-default text-uppercase"
                          onClick={handleFilterClick}
                        >
                          {t('reservations.btnSearch')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default CorporateFilter;
