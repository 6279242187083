import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import { Form } from 'react-bootstrap';

export interface propPlus {
    propsField: any,
};

const GetOperadoresCaixa: React.FC<propPlus> = ({
    propsField
}: propPlus) => {
    const [info, setInfo] = useState<any>(null);

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        async function listSuppliers() {
            try {
                const { data } = await api.get(`/DropDown/GetOperadoresCaixa `, config);
                if (data.status !== 400) {
                    setInfo(data.data)
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listSuppliers();
    }, []);

    if (info !== null) {
        return (
            <>
                <Form.Select
                    {...propsField}
                    as="select"
                    variant="standard"
                    margin="normal"
                >
                    <option value='' selected>Todos</option>
                    {info.length > 0
                        ? info.map((elem: any, index: number) => {
                            return (
                                <option key={index} value={elem.uid}>{elem.description}</option>
                            );
                        })
                        :
                        <></>
                    }
                </Form.Select>
            </>
        );
    } else {
        return <>

        </>
    }
}

export default GetOperadoresCaixa;