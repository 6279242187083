import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

import "./DropdownHours.scss";
import { Col, Row } from "react-bootstrap";
import api from "../../services/api";

export interface propsR {
    changePriceDateTour: any,
    dateTour: any,
    index?: any,
    setPeopleDisponible?: any,
    edit?: any,
    item?: any,
    info?: any,
    isCorp?: any,
    search?: any
}

const DropdownHours: React.FC<propsR> = ({
    changePriceDateTour, dateTour, index = 0, setPeopleDisponible, edit = false, item, info, isCorp = false, search = false
}: propsR) => {
    const authLogin: any = JSON.parse(localStorage.getItem("bpaSigAuth") || "{}");
    const idCanal: any = authLogin?.user?.perfil?.idCanal;

    const [isOpen, setIsOpen] = useState<any>(false);
    const [hours, setHours] = useState<any>(null);
    const [select, setSelect] = useState<any>(null);
    const [loading, setLoading] = useState<any>(true);

    /* Detecta clique fora da div#wrapper para fechar*/
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const handleClickHours = () => {
        isOpen === false ? setIsOpen(true) : setIsOpen(false);
    };

    function handleSelectHours(hour: any, price: any, hours: any) {
        setSelect(hour);

        var aux: any = dateTour;
        //aux[index].data = dateTour.data;
        //aux[index].idSellingType = objCalendar.idSellingType;
        aux[index].time = hour;
        aux[index].adult = price.sellingTarifAdultFinal;
        aux[index].child = price.sellingTarifChildFinal;
        aux[index].infant = price.sellingTarifInfantFinal;
        aux[index].student = price.sellingTarifStudentFinal;
        aux[index].elders = price.sellingTarifEldersFinal;
        aux[index].global = price.sellingTarifGlobalFinal;
        aux[index].maxStockNumber = price.balanceAvailableByChannel;
        aux[index].hours = hours;
        aux[index].idTarifario = price.idTarifario;

        setIsOpen(false);
        if (edit !== true) {
            setPeopleDisponible(true);
        }
        changePriceDateTour(aux, hour);
    }

    useEffect(() => {
        let aux: any = [];

        if (edit !== true) {
            setSelect(null);
            setPeopleDisponible(false);
        } else {
            if (dateTour[0].time === undefined) {
                dateTour[0].time = item.startTimeLocal.split("T")[1];
            }
        }
        setLoading(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };

        async function listHours(info: any) {
            try {
                const { data } = await api.post(`/Products/GetTicketAndTourHoursStockAsync`,
                    {
                        "page": 1,
                        "rowsPerPage": 10,
                        "dateStart": info.selectedDate.split("T")[0],
                        "tarUniqueCode": info.tarUniqueCode,
                        "prodModUniqueCode": info.prodModUniqueCode/* 'MOA5357' */,
                        "daysUniqueTarCode": info.daysUniqueTarCode,
                        "idCanal": search === false ? idCanal : search?.id
                    }, config);
                if (data.status !== 400) {
                    aux.push(...data.data);
                    setLoading(false);
                    setHours(aux);

                    //caso só exista um horário, já seleciona automático
                    if (data.data.length === 1) {
                        handleSelectHours(data.data[0].hora, data.data[0], data.data);
                    }
                } else {

                }
            } catch (error: any) {
                /* if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                } */
            }
        }
        for (let i = 0; i < dateTour[index]?.auxTarif?.length; i++) {
            listHours(dateTour[index]?.auxTarif[i]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTour[index]?.data]);

    useEffect(() => {
        if (edit === true) {
            setSelect(item?.startTimeLocal.split("T")[1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (dateTour[index]?.hours?.length !== 1) {
        if (isOpen === true) {
            return (
                <div className="panel-dropdown bg-white">
                    <div translate="no">
                        <div id="wrapper" ref={wrapperRef}>
                            <div className="d-flex align-items-center" style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClickHours()}>
                                <FontAwesomeIcon
                                    icon={["fal", "clock"]}
                                    className="size-icon-hours mr-2"
                                />
                                <span style={{ marginLeft: "24px" }}>
                                    {select === null ? "Escolha o horário" : select.split(":")[0] + ":" + select.split(":")[1]}
                                </span>
                            </div>

                            <div className="panel-dropdown-content panel-hours right active">
                                <Row className="mx-0">
                                    {hours?.map((x: any, index: any) => {
                                        if (x.balanceAvailableByChannel <= 5) {
                                            return (
                                                <>
                                                    {
                                                        index === 0
                                                            ?
                                                            <h6 className="hours-title">
                                                                Últimas Vagas
                                                            </h6>
                                                            :
                                                            ""
                                                    }
                                                    <Col
                                                        md={4}
                                                        key={index}
                                                        /* value={x.startTime} */
                                                        data-capacity={x.balanceAvailableByChannel}
                                                        className="p-1 mb-2"
                                                    >
                                                        <div className="hours-options" onClick={() => handleSelectHours(x.hora, x, hours)}>
                                                            <p>{`${x.hora.split(":")[0]}:${x.hora.split(":")[1]}`}</p>
                                                            {
                                                                dateTour[0]?.idSellingType === 1
                                                                    ?
                                                                    <small>R$ {x?.sellingTarifGlobalFinal.toFixed(2).replace(".", ",")}</small>
                                                                    :
                                                                    <small>R$ {x?.sellingTarifAdultFinal.toFixed(2).replace(".", ",")}</small>
                                                            }
                                                            <span>{`${x.balanceAvailableByChannel} vagas`}</span>
                                                        </div>
                                                    </Col>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <></>
                                            )
                                        }
                                    })}
                                </Row>
                                <h6 className="hours-title">
                                    {hours?.length > 0 ? "Horários Disponíveis" : "Não há horários disponíveis"}
                                </h6>
                                <Row className="mx-0">
                                    {hours?.map((x: any, index: any) => {
                                        if (x.balanceAvailableByChannel > 5) {
                                            return (
                                                <>
                                                    <Col
                                                        md={4}
                                                        key={index}
                                                        /* value={x.startTime} */
                                                        data-capacity={x?.balanceAvailableByChannel}
                                                        className="p-1"
                                                    >
                                                        <div className="hours-options" onClick={() => handleSelectHours(x.hora, x, hours)}>
                                                            <p>{`${x.hora.split(":")[0]}:${x.hora.split(":")[1]}`}</p>
                                                            {
                                                                dateTour[0]?.idSellingType === 1
                                                                    ?
                                                                    <small>R$ {x?.sellingTarifGlobalFinal.toFixed(2).replace(".", ",")}</small>
                                                                    :
                                                                    <small>R$ {x?.sellingTarifAdultFinal.toFixed(2).replace(".", ",")}</small>
                                                            }
                                                        </div>
                                                    </Col>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <></>
                                            )
                                        }
                                    })}
                                </Row>
                                {/* <p id="alertNumber"></p> */}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            if (loading !== true) {
                return (
                    <div className="panel-dropdown bg-white">
                        <div className="d-flex align-items-center" style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClickHours()}>
                            <FontAwesomeIcon
                                icon={["fal", "clock"]}
                                className="size-icon-hours mr-2"
                            />
                            <span style={{ marginLeft: "24px" }}>{select === null ? "Escolha o horário" : select.split(":")[0] + ":" + select.split(":")[1]}</span>
                            <span
                                className="qtyTotal"
                                data-min="2"
                                data-max="19"
                            >
                                {/* {dateTour[actual]?.idSellingType === 2
                            ? numberTotal
                            : dateTour[actual]?.idSellingType === 1
                                ? numberTotalPeople
                                : 0} */}
                            </span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="panel-dropdown bg-white">
                        <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer", color: "#666", position: "relative" }} onClick={() => handleClickHours()}>
                            <div className="load" style={{ position: "absolute", top: "50%" }}></div>
                        </div>
                    </div>
                );
            }
        }
    } else {
        if (loading === true) {
            return (
                <div className="panel-dropdown bg-white">
                    <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer", color: "#666", position: "relative" }} onClick={() => handleClickHours()}>
                        <div className="load" style={{ position: "absolute", top: "50%" }}></div>
                    </div>
                </div>
            );
        } else {
            return (
                <></>
            )
        }
    }
}

export default DropdownHours;