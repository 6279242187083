import React, { useEffect, useState } from 'react';
import { destroyCookie } from 'nookies'
/* import { Link } from 'react-router-dom'; */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//STYLE
import './HeaderNew.scss'
// import TranslateOption from '../TranslateOption/TranslateOption';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { useAppDispatch } from '../../store/hooks';
import { updateMenu } from "../../store/cart";
import api from '../../services/api';

// import { useAppSelector, useAppDispatch } from '../../store/hooks'


function HeaderNew() {
    /* var seila : any = localStorage.getItem("menuState")
    console.log(seila === 'false') */
    const [saldo, setSaldo] = useState(0)

    const [controlMenu, setControlMenu] = useState(localStorage.getItem("menuState") === 'false');
    const dispatch = useAppDispatch();

    let user = JSON.parse(localStorage.getItem('bpaSigAuth') || '');
    const userName = user.user;

    const level = atob(JSON.parse(localStorage.getItem('bpaSigAuth') || '{}').user.perfil.nivel)

    const handleLogout = () => {
        localStorage.removeItem('bpaSigAuth');
        destroyCookie(null, 'authCookie');
        window.location.href = ('/');
    }

    const handleShowClose = () => {
        dispatch(updateMenu(!controlMenu));
        if (controlMenu === false) {
            setControlMenu(true);
            (document.querySelector('body') as HTMLElement).classList.add('menu-show');
        } else {
            setControlMenu(false);
            (document.querySelector('body') as HTMLElement).classList.remove('menu-show');
        }

    }

    useEffect(() => {
        handleShowClose();
        getSaldo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getSaldo = async () => {
        console.log(user)
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        try {

            const { data } = await api.post('/Supplier/ConsultaSaldoRM', {
                "ownerIdentity": user.user.perfil.supplierIdentity
            }, config);
            if (data.statusCode !== 400) {
                console.log(data.data)
                setSaldo(data.data)
            } else {

            }

        } catch (error: any) {

        }

    }



    // const handleClose = () => {
    //     (document.querySelector('body') as HTMLElement).classList.remove('menu-show')
    // }

    return (
        <div className="header bg-white">
            <div style={{ display: "flex" }}>
                <div className='p-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px', cursor: 'pointer' }} onClick={() => { handleShowClose() }}>
                    <FontAwesomeIcon icon={['fad', 'bars']} size="2x" style={{ color: "#707070" }} />
                </div>
                <img className='logo-main' src={process.env.REACT_APP_SERVER_LOGO_SHORT} alt="Marca SIG" style={{ height: '84px', padding: '10px' }} />
                <div className='divisor'></div>
                <h2 className="titleHeader"><b>S</b>istema <b>I</b>ntegrado de <b>G</b>estão</h2>
            </div>


            <div className="d-flex align-items-center">
                {
                    level === '29'
                        ?
                        <>
                            <ul className='navbar-nav ml-auto w-100'>
                                <Row className="">
                                    <Col className="col-md-2 d-flex justify-content-center align-items-center">
                                        <FontAwesomeIcon
                                            icon={["fad", "dollar-sign"]}
                                            className="text-primary p-1"
                                            size="3x"
                                        />
                                    </Col>
                                    <Col className="col-md-10 pt-1">
                                        <Row>
                                            <span className='card-title mb-0'>
                                                Saldo
                                            </span>
                                            <span className='cart-content'>
                                                {/* {totalSales.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} */}
                                                {saldo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </span>
                                        </Row>
                                    </Col>
                                </Row>
                            </ul>
                            <div className='divisor'></div>
                        </>
                        :
                        <></>

                }

                <ul className="navbar-nav ml-auto" style={{ maxHeight: "90px" }}>
                    <li className="nav-item dropdown nav-item-c2" style={{ marginRight: "20px" }}>

                        <Dropdown drop="down" >
                            <Dropdown.Toggle className="menu-profile">
                                <div className="profile">
                                    <FontAwesomeIcon icon={['fad', 'user-circle']} size="2x" className='mr-2' />
                                    <span className="user-name">{`${userName.nome} ${userName.lastName}`}</span>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="1" disabled={true} > <FontAwesomeIcon icon={['fal', 'user-alt']} className="mr-2" /> Minha Conta</Dropdown.Item>
                                <Dropdown.Item eventKey="1" disabled={true} > <FontAwesomeIcon icon={['fal', 'users']} className="mr-2" /> Alterar Perfil</Dropdown.Item>
                                <Dropdown.Item eventKey="1" disabled={true} > <FontAwesomeIcon icon={['fal', 'cog']} className="mr-2" /> Preferências</Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={handleLogout}> <FontAwesomeIcon icon={['fal', 'sign-out']} className="mr-2" /> Sair</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>

                </ul>

            </div>
        </div>
    );
}

export default HeaderNew;