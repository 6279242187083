/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key, useState, useEffect } from 'react';
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Button from "@restart/ui/esm/Button";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

//STYLES
import './Quotation.scss';

import { useAppSelector } from "../../store/hooks";

//ICONS
import BookingInformation from '../../components/Checkout/BookingInformation/BookingInformation';
import CartCheckout from '../../components/Checkout/CartCheckout/CartCheckout';
import DetailsCkeckout from '../../components/Checkout/DetailsCkeckout/DetailsCkeckout';
//import PaymentCheckout from './components/PaymentCheckout/PaymentCheckout';
import api from '../../services/api';
import { Col, Modal, Row } from 'react-bootstrap';
import CartHeader from '../Sales/components/CartHeader/CartHeader';


function Quotation() {
    const { t } = useTranslation();
    //const tempCart = localStorage.getItem('cartSIG') || '{}';
    //const cart: any = JSON.parse(tempCart).dados;
    const cartSIG = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)))
    // console.log(cartSIG)

    const [alertBool, setAlertBool] = useState<any>(false);
    const [log, setLog] = useState<any>();
    const [bookingLoc, setBookingLoc] = useState<any>();
    const [dateLimit, setDateLimit] = useState<any>();
    //const [modalResponse, setModalResponse] = useState<any>(null);
    //const [checkoutResponse, setCheckoutResponse] = useState<any>('');
    //const [modalContent, setModalContent] = useState<any>('loading');
    const [alertMessage, setAlertMessage] = useState<any>("");
    const [mainContactObj, setMainContactObj] = useState<any>(null);

    //const [paymentMethod, setPaymentMethod] = useState<any>('1');
    //const [allowResetCart, setAllowResetCart] = useState<boolean>(false);

    const [loading, setLoading] = useState<any>(true);

    const [emailQuotation, setEmailQuotation] = useState<any>("");
    const [isForeign, setIsForeign] = useState<boolean>(false);
    

    /* const resetCart = () => {
        setAllowResetCart(true);
        localStorage.removeItem("cartSIG");
        window.location.href = "/dashboard";
    } */

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm();

    const watchIsMain = watch(`isMain`, false);
    const watchFirstName = watch(`name`, false);
    const watchLastName = watch(`lastName`, false);
    const watchEmail = watch(`email`, false);
    const watchPhone = watch(`phone`, false);

    useEffect(() => {
        if (watchIsMain === true) {
            setMainContactObj(
                {
                    "name": watchFirstName,
                    "lastName": watchLastName,
                    "email": watchEmail,
                    "phone": watchPhone,
                }
            )
        } else if (watchIsMain === false) {
            setMainContactObj(
                {
                    "name": '',
                    "lastName": '',
                    "email": '',
                    "phone": '',
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchIsMain])

    const onSubmit = (data: any) => {
        cartSIG.dados.map((info: any, index: Key) => {
            if (index === 0) {
                data.dados[index].passengers[0].DocumentNumber = data.cpf;
            }
            cartSIG.dados[index].passengers = data.dados[index].passengers;
            cartSIG.dados[index].pickup = data.dados[index].pickup;
            cartSIG.dados[index].internalNotes = data.dados[index].internalNotes;

            return true;
        })

        // cartSIG.agent = data.agent;
        cartSIG.agent = data.agent;
        cartSIG.street = data.street;
        cartSIG.Country = "BR";
        cartSIG.city = data.city;
        cartSIG.state = data.state;
        cartSIG.CotationEmail = data.email;
        cartSIG.CotationHourLimit = 24;


        //console.log(cartSIG);
        // 

        createQuotation();
        // 
    };

    const createQuotation = async () => {
        /* console.log(dataQuotation.email) */
        /* setModalResponse(true);
        setModalContent('loading'); */
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const data = await api.post('/Cotation/EnviaCotacaoAuth', cartSIG, config);
            if (data.status !== 400) {                
                if (data.data.data.log === '1' || data.data.data.log === 1) {                    
                    setAlertBool(true);
                    setLog(1);
                    //setCheckoutResponse(data);
                    setAlertMessage(data.data.data.texto);
                    
                } else {
                    setAlertBool(true);
                    setLog(0);
                    setBookingLoc(data.data.data.data.data.bookingLoc)
                    setDateLimit(data.data.data.data.data.dateLimit)
                    //setCheckoutResponse(data);
                    setAlertMessage(data.data.data.texto);
                    

                }
                setLoading(false);
            }
            
        } catch (error: any) {
            if (error?.response?.status === 400) {
                /* setCheckoutResponse(error.response);
                setModalContent('error');
                setLoading(false); */               
            }
        }
    }

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid container-checkout">
                    <Breadcrumb title="Cotação" />

                    <div className="checkout-title  d-flex flex-column align-items-between">
                        <div className="d-flex justify-content-between w-100">
                            <div className="d-flex align-items-center">
                                <span className='back' onClick={() => { window.location.href = '/sale' }}>
                                    <FontAwesomeIcon
                                        icon={["fal", "angle-double-left"]}
                                        style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                                    />
                                    Voltar</span>
                            </div>
                            <div className="icon-tp">

                                <CartHeader></CartHeader>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start w-100">
                            <h1>
                                {/* <FontAwesomeIcon
                                className='icon-primary'
                                icon={["fal", "envelope"]}
                                size="1x"
                                style={{ marginRight: "10px", width: "30px", fontSize: "2rem", color: "#707070" }}
                            /> */}
                                Cotação
                            </h1>
                        </div>
                    </div>

                    <Row>
                        <Col md={9} className="container-cards">
                            <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                <BookingInformation
                                    control={control}
                                    errors={errors}
                                    setValue={setValue}
                                    setEmail={setEmailQuotation}
                                    isForeign={isForeign}
                                    setIsForeign={setIsForeign}
                                    isQuotation={true}
                                />
                                <CartCheckout
                                    control={control}
                                    errors={errors}
                                    cart={cartSIG.dados}
                                    setValue={setValue}
                                    mainContactObj={mainContactObj}
                                />
                                {/* <PaymentCheckout
                                    control={control}
                                    errors={errors}
                                    setValue={setValue}
                                /> */}

                                <div className="d-flex justify-content-end">
                                    <Button className="btn-primary" type='submit'>
                                        EFETUAR COTAÇÃO
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                        <Col md={3} className="container-details">
                            <div>
                                <DetailsCkeckout
                                    cart={cartSIG.dados}
                                />
                                {/* <CupomCheckout />
                                <CancelPolicyCheckout /> */}
                            </div>
                        </Col>
                    </Row>

                    <Modal
                className="modal-confirm"
                show={alertBool}
                onHide={() => {
                    setAlertBool(false)
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <div className='modal-body text-center d-flex flex-column justify-content-evenly' style={{ height: '600px' }}>
                    {loading === true
                        ?
                        <>
                            <div className="load-big"></div>
                        </>
                        :
                        log === '1' || log === 1 ?
                            <div>
                                <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    className='text-primary'
                                    style={{ fontSize: "7.5em"}}
                                />
                            </div>
                            :
                            <div>
                                <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    className='text-primary'
                                    style={{ fontSize: "7.5em"}}
                                />
                            </div>
                    }
                    <div className="px-md-5" style={{ padding: "0 3rem" }}>
                        {alertMessage}
                    </div>
                    <div>
                        <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlertBool(false)}>Fechar</Button>
                    </div>
                </div>
            </Modal>


                    {/* <Modal
                        className="modal-confirm"
                        show={alertBool}
                        onHide={() => {
                            setAlertBool(false)
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <div className="sucess-pos" id="wrapper" >
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em" }}
                                />
                                <h4 className='mt-4'>Nova Cotação</h4>
                                <p className="color-success">{alertMessage}</p>
                                <p>{t("modalPayment.creditCard.success.locNumber")} <strong>{bookingLoc}</strong></p>
                                <p>Data limite: <strong>{dateLimit?.split('T')[0].split('-').reverse().join('/')} </strong>às <strong>{dateLimit?.split('T')[1].split('.')[0]} </strong></p>
                                {/* <p>Data limite: <strong>{dateLimit?.split('T')[1]} </strong>às <strong>{dateLimit?.split('T')[1].split('.')[0]} </strong></p> */}
                                {/* <p className="mb-0"><small>Você receberá os dados da cotação por email</small></p>
                                <p><small><em>{t("modalPayment.creditCard.success.spam")}</em></small></p>

                            <button className="btn btn-primary" onClick={() => { setAlertBool(false) }}>Confirmar</button>
                            </div>
                        </div> */}
                        {/* <div className='modal-body text-center'>
                            <div>
                                <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em" }}
                                />

                                <div className='mt-4'><h4>Parabéns!</h4></div>
                            </div>

                            <div className="px-md-5" style={{ padding: "0 3rem" }}>
                                {alertMessage}
                            </div>
                            <div>
                                <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlertBool(false)}>Fechar</Button>
                            </div>
                        </div>
                    </Modal> */}

                </div>
            </DesktopDefault>
        </>

    );
}

export default Quotation;