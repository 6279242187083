import React from 'react';

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import RegisterUsers from './components/RegisterUsers/RegisterUsers';
import DesktopDefault from '../../templates/DesktopDefault';


function NewUser() {

    return (
        <>
            <DesktopDefault>
                    <div className="container-fluid content-dashboard">

                        <Breadcrumb title={'Usuários / Cadastrar Usuários'} />

                        <div className="register-users mt-4">
                            <h2 className="title">Cadastrar Usuário</h2>
                        </div>

                        <RegisterUsers />

                    </div>

            </DesktopDefault>
        </>

    );
}

export default NewUser;