import React, { Key, useEffect, useState } from 'react';
import { Card, Modal, Row } from 'react-bootstrap';
import RequestResponseModal from '../../../../../components/Modal/RequestResponseModal/RequestResponseModal';
import api from '../../../../../services/api';

import EditPartnersDataFields from '../EditPartnersDataFields/EditPartnersDataFields';

//STYLE
import './EditPartnersData.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function EditPartnersData() {
    const [modalResponse, setModalResponse] = useState<any>(null); /// ["loading","error","sucess"] para abrir o modal pós envio
    const [modalText, setModalText] = useState<any>(null);
    const [editPartners, setEditPartners] = useState<any>(null);
    const [saldo, setSaldo] = useState(0)
    const [faturas, setFaturas] = useState(0)
    const idPartner = window.location.href.split('id=')[1];

    /* function handleClick(index: any) {
        const addActive = document.querySelectorAll('.step-unit')
        for (let i = 0; i < 4; i++) {
            addActive[i].classList.remove('active')
        }
        for (let i = 0; i <= index; i++) {
            addActive[i].classList.add('active')
        }
    } */

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        const EditSupplier = async () => {
            try {
                const { data } = await api.get(`Affiliate/GetAffiliatesByIdAsync/${idPartner}`, config);
                if (data.status !== 400) {
                    if (data.data.log === 1) {
                        setModalResponse('error')
                        setModalText(data.data.texto)
                    } else {
                        setEditPartners(data.data.data);
                        console.log(data.data.data)
                    }


                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
                if (error?.response?.status === 400) {
                    //window.location.href = window.location.origin + '/list-suppliers';
                }
            }
        }



        EditSupplier();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idPartner])

    useEffect(() => {

        const getSaldo = async () => {
            console.log(editPartners)
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            try {
                const { data } = await api.post('/Supplier/ConsultaSaldoRM', {
                    "ownerIdentity": editPartners.supplierIdentity
                }, config);
                if (data.statusCode !== 400) {
                    setSaldo(data.data)
                } else {

                }

            } catch (error: any) {

            }

        }


        const getFaturas = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            try {
                const { data } = await api.post('/Supplier/ConsultFaturasRM', {
                    "ownerIdentity": editPartners.supplierIdentity
                }, config);
                if (data.statusCode !== 400) {
                    var dateObj = new Date();
                    var month = dateObj.getUTCMonth() + 1; //months from 1-12
                    var year = dateObj.getUTCFullYear();

                    var newdate = year + "-" + month;

                    console.log(newdate)

                    data.data.map((fat: any, index: Key) => {
                        if (fat === newdate) {
                            setFaturas(data.pago)
                        }

                    })


                } else {

                }

            } catch (error: any) {

            }

        }


        if (editPartners !== null) {
            getSaldo();
            getFaturas();
        }


    }, [
        editPartners
    ])

    if (editPartners !== null) {
        return (
            <>
                <div className="edit-partners-data edit-page">
                    <Row className="cards my-5">
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Saldo
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {saldo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "dollar-sign"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Faturas Pagas
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {/* {totalReservations} */}
                                            {faturas}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "ballot-check"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>

                    </Row>
                    <div className="d-flex">
                        <div style={{ width: "100%" }}>
                            <div className="edit" >
                                <EditPartnersDataFields info={editPartners} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                    <div className="btn-sucess">
                        <Modal
                            className="modal-custom modalAuth"
                            show={modalResponse !== null}
                            onHide={() => setModalResponse(null)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <>
                                <Modal.Header closeButton></Modal.Header>
                                <RequestResponseModal modalResponse={modalResponse} setModalResponse={setModalResponse} modalText={modalText} log={1} />
                            </>
                        </Modal>
                    </div>
                </Row>
            </>
        )
    }
}

export default EditPartnersData;