import React, { Key, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

import api from '../../../../services/api';

import Modal from "react-bootstrap/Modal";

//STYLES
import "./TableBillReceiveEditItems.scss";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";
import ModalDefault from "../../../Modal/ModalDefault/ModalDefault";

import FormBillReceivableItemEdit from "./FormBillReceivableItemEdit/FormBillReceivableItemEdit";


export interface propModal {
  //action: any
  info: any
};

const TableBillReceiveEditItems: React.FC<propModal> = ({
  //action, 
  info
}: propModal) => {
  const { t } = useTranslation();
  const [itemEditModalShow, setItemEditModalShow] = React.useState(false);
  const [itemEdit, setItemEdit] = React.useState(null);

  //const [controlObject, setControlObject] = useState<any>(info ? info : []);


  function handleItemEditClick(row: any) {

    // chama modal
    setItemEdit(row);
    setItemEditModalShow(true);
  }

  function handleActionItemEditModal(data: any) {
    setItemEdit(null);
  }

  function handleActionItemEditHideModal() {
    //() => setBillEditModalShow(false)
    setItemEditModalShow(false);
    setItemEdit(null);
  }

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  return (
    <>
      <div className="table-default bill-receive-items-table mt-0">
        <div className="table-container">
          <div className="table-title mb-3">
            <h3>Produtos</h3>
          </div>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Data</th>
                <th>Serviço</th>
                <th>Valor</th>
                <th>Nº</th>
                <th>Centro Custo</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {info.length > 0
                ? info.map((info: any, index: Key) => (
                  <tr key={index}>
                    <td>{convertDate(info.dataOperacao)}</td>
                    <td>{info.tour}</td>
                    <td>{convertValue(info.valor)}</td>
                    <td>{info.qtdePax}</td>
                    <td>{info.centroCusto}</td>
                    <td>{
                      <div className="center-table options-table">
                        <Dropdown drop="start" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleItemEditClick(info)} eventKey="1"><FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />Editar</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    }</td>
                  </tr>
                ))
                : <tr style={{ border: "none" }}><td colSpan={6} className="text-center">Nenhum item encontrado</td></tr>}
            </tbody>
          </Table>

          {itemEdit !== null ?
            <Modal
              className="modalAuth"
              show={itemEditModalShow}
              onHide={handleActionItemEditHideModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Header className="p-3" closeButton></Modal.Header>
                <FormBillReceivableItemEdit action={handleActionItemEditModal} info={itemEdit} />
              </>
            </Modal> : <></>}
        </div>
      </div>
    </>
  );
}

export default TableBillReceiveEditItems;
