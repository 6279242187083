import React, { useEffect, useState } from 'react';

//COMPONENTS
// import NewDesktop from '../../templates/NewDesktop';
import DesktopDefault from '../../templates/DesktopDefault';
import BannerHome from './components/BannerHome/BannerHome';

import TourList from './components/TourList/TourList';
import Transfers from './components/Transfers/Transfers';


function Sales() {
    const [search, setSearch] = useState<any>(null);
    const [selected] = useState<any>(1);
    const [, setIsLoading] = useState<any>(false);

    /* const authLogin: any = JSON.parse(localStorage.getItem("bpaSigAuth") || "{}");
    const idCanal: any = authLogin?.user?.perfil?.idCanal; */

/*     const searchTours = async (cat: any, idChannel: number) => {
        const response = await api.post(
            "/Products/SearchByChannel",
            {
                "term": cat,
                "channel": idChannel,
                "lang": "BR",
                "ProductType": 0,
                "SupplierId": 0
            }
        );

        if (response.data.data !== null) {
            setSearch('');
        }
    }; */

    useEffect(() => {
        setSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <DesktopDefault>
            <div className="navTabHome">
                <BannerHome></BannerHome>

                {/* <TabSearchs
                    setSearch={setSearch}
                    setSelected={setSelected}
                    isLoading={isLoading}
                /> */}

                {
                    selected === 1 && search !== null
                        ?
                        <TourList
                            search={search}
                            setSearch={setSearch}
                            setIsLoading={setIsLoading}
                        />
                        :
                        selected === 2 && search !== null
                            ?
                            <Transfers
                                search={search}
                                setSearch={setSearch}
                            />
                            :
                            <></>
                }

            </div>

        </DesktopDefault>
    );
}

export default Sales;