
import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";


import { Controller, useForm } from "react-hook-form";

import './ReservationsFilter.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import GetStatusNewReservations from "../../../../components/C2Points/GetStatusNewReservations";
import GetCaixaBilheteria from "../../../../components/C2Points/GetCaixaBilheteria";
import GetOperadoresCaixa from "../../../../components/C2Points/GetOperadoresCaixa";

function ReservationsFilter(props: any) {

    const { t } = useTranslation();

    const [filterBookingName, setFilterBookingName] = useState<any>('');
    const [filterName, setFilterName] = useState<any>('');
    const [filterEmail, setFilterEmail] = useState<any>('');
    const [filterId, setFilterId] = useState<any>('');
    const [filterAcessCode, setFilterAcessCode] = useState<any>('');
    const [filterDate, setFilterDate] = useState<any>('');
    const [filterStatus, setFilterStatus] = useState<any>(0);
    const [filterChannel, setFilterChannel] = useState<any>('');
    const [filterAntifraude, setFilterAntifraude] = useState<any>(0);

    const {
        control,
        //handleSubmit,
        //register,
        getValues,
        formState: { errors },
    } = useForm({});


    const handleFilterClick = () => {
        let data: any = getValues();
        let caixa: string = filterChannel === 'BILHETERIA' ? data.codigoCaixa : '';
        let operador: string = filterChannel === 'BILHETERIA' ? data.uidOperator : '';

        props.buscar(filterBookingName, filterName, filterEmail, filterId, filterAcessCode, filterStatus, filterDate, filterChannel, filterAntifraude, caixa, operador);
        //console.log(filterName, filterState, filterType, filterStatus, filterDate)
    };

    const handleClearClick = (event: any) => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterBookingName("");
        setFilterName("");
        setFilterEmail("");
        setFilterId("");
        setFilterAcessCode("");
        setFilterDate("");
        setFilterStatus(0);
        setFilterChannel('');
        setFilterAntifraude(0);
        props.buscar("", "", "", "", "", 0, [], "", 0, '', '');
    };

    const handleChangeBookingName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterBookingName(newValue);
    }

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterName(newValue);
    }

    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterEmail(newValue);
    }

    const handleChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterId(newValue);
    }

    const handleChangeAcessCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterAcessCode(newValue);
    }

    const handleChangeChannel = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterChannel(newValue);
    }

    /*  const handleChangeAntifraude = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterAntifraude(newValue);
    } */

    // const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newValue = e.target.value;
    //     setFilterDate(newValue);
    // }

    // const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newValue = e.target.value;
    //     setFilterStatus(newValue);
    // }


    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar reservas</span>
                        </Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="2" className="mb-3">
                                                <Form.Label>Nª Reserva</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="name"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeBookingName}
                                                            value={filterBookingName}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2" className="mb-3">
                                                <Form.Label>Nome</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="name"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeName}
                                                            value={filterName}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2" className="mb-3">
                                                <Form.Label>Email</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="email"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeEmail}
                                                            value={filterEmail}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2" className="mb-3">
                                                <Form.Label>CPF/Passaporte</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="id"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeId}
                                                            value={filterId}
                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                            maxLength={20}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2" className="mb-3">
                                                <Form.Label>Código de Acesso</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="acessCode"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeAcessCode}
                                                            value={filterAcessCode}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2" className="mb-3">
                                                <Form.Label>{t("reservations.filter.date")}</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2" className="mb-3">
                                                <Form.Label>{t('reservations.filter.status')}</Form.Label>
                                                {/* <Form.Control
                                                    type="text"
                                                    onChange={handleChangeState}
                                                /> */}
                                                <Controller
                                                    control={control}
                                                    name="state"
                                                    render={({ field }: any) => (
                                                        <GetStatusNewReservations propsField={field} propsLabel={t('reservations.filter.status')} propsErrors={errors} setFilterStatus={setFilterStatus} />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Canal</Form.Label>
                                                {/* <Form.Control
                                                    type="text"
                                                    onChange={handleChangeState}
                                                /> */}
                                                <Controller
                                                    control={control}
                                                    name="channel"
                                                    render={({ field }: any) => (
                                                        <div className='endpoint'>
                                                            <Form.Select
                                                                {...field}
                                                                label="Canal"
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => handleChangeChannel(e)}
                                                            >
                                                                <option value='' selected>Todos</option>
                                                                <option value='SITE'>SITE</option>
                                                                <option value='SIG'>SIG</option>
                                                                <option value='TOTEM'>TOTEM</option>
                                                                <option value='INTRANET'>INTRANET</option>
                                                                <option value='BILHETERIA'>BILHETERIA</option>
                                                                <option value='selling_platform'>SELLING PLATFORM</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>

                                            {filterChannel === 'BILHETERIA'
                                                ?
                                                <>
                                                    <Form.Group as={Col} md="2">
                                                        <Form.Label>Caixa</Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="codigoCaixa"
                                                            render={({ field }: any) => (
                                                                <div className='endpoint custom-select'>
                                                                    <GetCaixaBilheteria propsField={field} />
                                                                </div>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="2">
                                                        <Form.Label>Operador</Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="uidOperator"
                                                            render={({ field }: any) => (
                                                                <div className='endpoint custom-select'>
                                                                    <GetOperadoresCaixa propsField={field} />
                                                                </div>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </>
                                                :
                                                <></>
                                            }
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('reservations.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('reservations.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default ReservationsFilter;