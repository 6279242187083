/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import { useTranslation } from 'react-i18next';

//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../../templates/DesktopDefault';
import CorporateClientFilter from './CorporateClientFilter/CorporateClientFilter';
import CorporateClientTable from './CorporateClientTable/CorporateClientTable';
import { Link } from 'react-router-dom';

function CorporateClient() {
  const { t } = useTranslation();

  const [clientCorp, setClientCorp] = useState<any>(null);
  const [modalContent, setModalContent] = useState<any>();
  const [confirm, setConfirm] = useState<any>(
    'Tem certeza que deseja remover esse item?',
  );
  const [nameClient, setNameClient] = useState<string>('');
  const [typeClient, setTypeClient] = useState<string>('');
  const [statusClient, setStatusClient] = useState<string>('0');
  const [loading, setLoading] = useState<any>(false);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  const [reason, setReason] = useState<any>('0');
  const [valReason, setValReason] = useState<any>(false);
  const [log, setLog] = useState<any>(1);

  useEffect(() => {
    setClientCorp(null);
    setLoading(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '69420',
      },
    };
    async function listarClientesCorp() {
      try {
        const { data } = await api.post(
          `/SpecialTickets/ListUsers`, //Trocar a API para hml.backend.bondinho
          {
            page: pageCount,
            rowsPerPage: rowsPerPage,
            nomeCliente: "",
            tipoCliente: "",
            ativo: ""
          },
          config,
        );

        if (data.status !== 400) {
          setLoading(false);
          setClientCorp(data.data.data.rows);
          setTotalRows(data.data.data.rowsCount);
        }
      } catch (error: any) {
        setLoading(false);

        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listarClientesCorp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  async function buscar(name: string, type: string, status: string) {
    setClientCorp(null);
    setLoading(true);

    setNameClient(name);
    setTypeClient(type);
    setStatusClient(status);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '69420',
      },
    };

    try {
      const res = await api.post(
        `/SpecialTickets/ListUsers`, //Trocar a API para hml.backend.bondinho
        {
          page: pageCount,
          rowsPerPage: rowsPerPage,
          nomeCliente: name,
          tipoCliente: type === "0" ? "" : type,
          ativo: status === '1' ? true : status === '2' ? false : status === "0" ? "" : status
        }, config,
      );
      if (res.status !== 400) {
        setLoading(false);
        setClientCorp(res.data.data.data.rows);
        setTotalRows(res.data.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
    }
  }

  function handleModal() {
    let modal = document.querySelector('.bg-modal-custom');
    modal?.classList.toggle('modal-close');
  }

  useEffect(() => {
    reloadTable();
  }, [rowsPerPage]);

  function reloadTable() {
    buscar(
      nameClient,
      typeClient,
      statusClient,
    );
  };

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb
            title={t('reservations.title') + ' / Listar Clientes Corporativos'}
          />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: '#707070' }}>
              Listar Clientes Corporativos
            </h2>
            <Link to={'/add-corporate-client'} className="btn btn-primary">
              Adicionar Cliente
            </Link>
          </div>

          <CorporateClientFilter buscar={buscar} />

          <CorporateClientTable
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            clientCorp={clientCorp}
            confirm={confirm}
            log={log}
            modalContent={modalContent}
            setConfirm={setConfirm}
            setLog={setLog}
            handleModal={handleModal}
            loading={loading}
            setReason={setReason}
            valReason={valReason}
            reloadTable={reloadTable}
          />
        </div>
      </DesktopDefault>
    </>
  );
}

export default CorporateClient;
