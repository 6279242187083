import React, { Key, useEffect, useState } from 'react';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import { Row } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';


export interface propPlus {
  propsField: any,
  getChangeCompType: any,
  compType: any
};

const GetCompanyTypeCorporate: React.FC<propPlus> = ({
  propsField, getChangeCompType, compType
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  const [defaultChecked, setDefaultChecked] = useState<string>(compType);

  const text: any = {
    1: "O cliente é uma entidade e possui CPF",
    2: "O cliente é uma entidade e possui CNPJ"
  }

  const changeCallBack = (e: any): any => {
    console.log()
    getChangeCompType(e.target.value);
    setDefaultChecked(e.target.value);
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`/DropDown/GetCompanyType`, config);
        if (data.status !== 400) {
          setInfo(data.data.splice(0,2))
          /* console.log(data); */
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);


  if (info !== null) {
    
    return (
      <>
        <Row>
          {info.length > 0 ? info.map((info: any, index: Key) => (
            <label key={index} htmlFor={"radio" + info.id} className="col-md-2 radioPersonal my-4 mx-3 p-3">
              <Form.Check
                {...propsField}
                type="radio"
                id={"radio" + info.id}
                value={`${info.id}`}
                required
                label={
                  info.description === "Pessoa Física"
                  ?
                  t('accordionInfoCompany.physics')
                  :
                  info.description === "Pessoa Jurídica"
                  ?
                  t('accordionInfoCompany.jud')
                  :
                  t('accordionInfoCompany.inter')
                }
                onChange={changeCallBack}
                checked={Number(info.id) === Number(defaultChecked)}
              />
              <small>{text[info.id]}</small>
            </label>
          )) : ''}
        </Row>
      </>

    );
  } else {
    return <>

    </>
  }
}

export default GetCompanyTypeCorporate;