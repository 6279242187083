import React, { useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import { useTranslation } from "react-i18next";

import GetYesOrNot from "../../../../components/C2Points/GetYesOrNot";

import '../../../../assets/sass/accordion.scss';
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GetCostCenter from '../../../../components/C2Points/GetCostCenter';
//import GetAccountingAccounts from "../../../../components/C2Points/GetAccountingAccounts";
//import GetMovimentAccounting from "../../../../components/C2Points/GetMovimentAccounting";

import DatePicker from "react-multi-date-picker";

export interface propAccordion {
    action: any,
    ContextAwareToggle: any,
    setStepCtrl: any;
    setChangeEdit?: any,
    infoEdit?: any,
    isEdit?: any,
    editPartners?: any;
    hasSavedInputs?: any;
};

const AccordionAccount: React.FC<propAccordion> = ({
    action, ContextAwareToggle, setStepCtrl, setChangeEdit, infoEdit, isEdit = false, editPartners, hasSavedInputs = false
}: propAccordion) => {

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);
    const { t } = useTranslation();
    const validated = false;
    const hasContractControl = { value: true, message: 'Por favor, preencher esse campo.' };

    const [contract, setContrat] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.contractDoc : '');
    const [contratName, setContratName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.contractName : '');

    const [contractError, setContractError] = useState<any>(false);

    const [val, setVal] = useState<any>('');

    const [values, setValues] = useState<any>('null');

    const idPartner = window.location.href.split('id=')[1];

    const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
    const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

    const contractHandler = (e: any) => {
        //
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                let docImage: any = reader.result;
                let changeDoc: any = docImage.split("base64");
                const finishDoc: any = changeDoc[0] + changeDoc[1];
                setContrat(finishDoc);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setContratName(e.target.files[0].name);
    }

    const CustomMultipleInput = ({ openCalendar, value }: any) => {

        function convertDate(cell: any) {
            if (cell !== null && cell !== undefined) {
                var aux: any = cell.split("T")[0].split("-");
                var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
                return date;
            }
        }

        if(infoEdit.validade === '' || value.length > 1) {
            value = value;
        } else {
            value = convertDate(infoEdit.validade);
        }

        if(infoEdit.validade !== undefined) {
            setVal(`${value.split("/").reverse().join("/")}T00:00:00`);
        }

        return (
            <>
                <FontAwesomeIcon
                    icon={["fal", "calendar-alt"]}
                    size="1x"
                    style={{ color: "#000", position: "absolute", transform: "translate(10px, 10px)" }}
                />
                <input
                    className="form-control bg-white frm-sm w-100 pl-5 rounded"
                    onFocus={openCalendar}
                    value={value}
                    placeholder={"Até quando?"}
                    //defaultValue={}
                    readOnly
                    name="richard"
                    autoComplete="off"
                />
            </>
        )
    }

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            //accountingAccounts: (isEdit === true || hasSavedInputs === true) ? { description: infoEdit.accountingAccountsDesc, id: infoEdit.accountingAccounts } : '',
            haveContract: (isEdit === true || hasSavedInputs === true) ? { description: infoEdit.haveContractDesc, id: infoEdit.haveContract } : '',
            validade: (isEdit === true || hasSavedInputs === true) ? infoEdit.validade : '',
            //contractDoc: (isEdit === true || hasSavedInputs === true) ? infoEdit.contractDoc : '',
            //contaCorrente: (isEdit === true || hasSavedInputs === true) ? { description: infoEdit.contaCorrenteDesc, id: infoEdit.contaCorrente } : '',
            costCenter: (isEdit === true || hasSavedInputs === true) ? { description: infoEdit.centroCustoDesc, id: infoEdit.centroCusto } : ''
        },
    });

    const watchHaveContract: any = watch(`haveContract`, infoEdit?.haveContract ? infoEdit.haveContract : '');

    const onSubmit = (data: any) => {
        data.validade = values !== 'null' ? values.format?.("YYYY-MM-DDTHH:mm:ss.SSZ") : '';


        if (isEdit === false) {
            /// ADD SUPPLIERS PAGE
            data.contractDoc = contract;
            data.contractName = contract === null ? "sem_contrato.jpg" : contratName;

            //data.accountingAccountsDesc = data.accountingAccounts.description;
            //data.accountingAccounts = parseInt(data.accountingAccounts.id);

            //data.contaCorrenteDesc = data.contaCorrente.description;
            //data.contaCorrente = parseInt(data.contaCorrente.id);

            data.haveContractDesc = data.haveContract.description;
            data.haveContract = data.haveContract.id;

            data.centroCustoDesc = data.costCenter.description;
            data.costCenter = data.costCenter.id;

            setCtrlEventKey(true);
            if (data.haveContract === '1' && (data.contractDoc === null || data.validade === '')) {
                setContractError("Favor anexar um contrato e informar sua data de validade");
            } else {
                if (data.validade === '') {
                    delete data.validade;
                }
                setContractError(false);
                action(data);
            }
        } else {
            /// EDIT SUPPLIERS PAGE
            let edit: any = { ...infoEdit, ...data };
            edit.affiliateId = parseInt(idPartner);

            //edit.contaCorrente = edit.contaCorrente.id;
            //edit.accountingAccounts = edit.accountingAccounts.id;
            edit.haveContract = edit.haveContract.id;
            edit.costCenter = parseInt(edit.costCenter.id);
            edit.contractDoc = contract;

            edit.supplierContactModel = edit.affiliateContact;
            edit.costCenterSupplierModel = edit.costCenterSupplierDTO;
            edit.usersSupplierModel = edit.usersSupplierDTO
            
            edit.validade = val;

            editPartners(edit);
        }

    };

    function handleCancel() {
        window.location.reload();
    }

    return (
        <Card className="bg-white mt-4" id="editAccountingForms">
            <Card.Header id="accordionPersonal">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>{t('accordionAccount.title')}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0 mt-3" fluid>
                        <Row>
                            <div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="formAccountCenter">
                                            <Form.Label>{t('accordionAccount.coastCenter')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="costCenter"
                                                rules={{ required: { value: true, message: 'Por favor, informe o centro de custo.' } }}
                                                render={({ field }: any) => (
                                                    <GetCostCenter propsField={field} propsErrors={errors} propsLabel={t('accordionAccount.coastCenter')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="costCenter"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md="4" controlId="formAccount">
                                            <Form.Label>{t('accordionAccount.account')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="accountingAccounts"
                                                rules={{ required: { value: true, message: 'Por favor, selecione a conta contábil.' } }}
                                                render={({ field }: any) => (
                                                    <GetAccountingAccounts propsField={field} propsErrors={errors} propsLabel={t('accordionAccount.account')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="accountingAccounts"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group> */}
                                        <Form.Group as={Col} md="2" controlId="formAccountContract?">
                                            <Form.Label>{t('accordionAccount.hasCont')}?</Form.Label>
                                            <Controller
                                                control={control}
                                                name="haveContract"
                                                rules={{ required: { value: true, message: 'Por favor, selecione o contrato' } }}
                                                render={({ field }: any) => (
                                                    <GetYesOrNot propsField={field} propsErrors={errors} propsLabel={t('accordionInfoCompany.hasCont')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="haveContract"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        {watchHaveContract?.id === '1' || watchHaveContract?.id === 1 || watchHaveContract === 1 || watchHaveContract === '1'
                                            ?
                                            <>
                                                <Form.Group as={Col} md="4" controlId="formAccountValid">
                                                    <Form.Label>{t('accordionAccount.val')}</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="validade"
                                                        render={({ field }: any) => (
                                                            <DatePicker
                                                                weekDays={t('accordionAccount.calendarPT') === 'pt' || t('accordionAccount.calendarPT') === 'es' ? weekDays : []}
                                                                months={t('accordionAccount.calendarPT') === 'pt' || t('accordionAccount.calendarPT') === 'es' ? months : []}
                                                                render={<CustomMultipleInput />}
                                                                onChange={setValues}
                                                                format={"DD/MM/YYYY"}
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="validade"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                    {contractError !== false ? <span style={{ color: 'red' }}>{contractError}</span> : <></>}
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>{t('accordionAccount.cont')}</Form.Label><br />

                                                    <Form.Control
                                                        type="file"
                                                        id="contract"
                                                        accept=".jpg"
                                                        disabled={!hasContractControl.value}
                                                        onChange={contractHandler}
                                                        autoComplete="off"
                                                        required
                                                    />

                                                    <label className="btn-file" htmlFor="contract">{t('accordionAccount.anex')}</label>
                                                    <small>{contratName}</small>
                                                </Form.Group>
                                            </>
                                            :
                                            <>
                                            </>
                                        }
                                    </Row>
                                    <Row className="mb-3">
                                        {/* <Form.Group as={Col} md="4" controlId="formAccountCC">
                                            <Form.Label>{t('accordionAccount.c/c')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="contaCorrente"
                                                rules={{ required: { value: true, message: 'Por favor, informe a conta corrente.' } }}
                                                render={({ field }: any) => (
                                                    <GetMovimentAccounting
                                                        propsField={field}
                                                        propsErrors={errors}
                                                        propsLabel={"Conta Movimento"}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="contaCorrente"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formAccountCenter">
                                            <Form.Label>{t('accordionAccount.coastCenter')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="costCenter"
                                                rules={{ required: { value: true, message: 'Por favor, informe o centro de custo.' } }}
                                                render={({ field }: any) => (
                                                    <GetCostCenter propsField={field} propsErrors={errors} propsLabel={t('accordionAccount.coastCenter')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="costCenter"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group> */}
                                    </Row>
                                    <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                        {
                                            isEdit === true
                                                ?
                                                <Button
                                                    className="form-button cancel"
                                                    onClick={handleCancel}
                                                >
                                                    Cancelar
                                                </Button>
                                                :
                                                <Button
                                                    type="button"
                                                    className="form-button back-btn"
                                                    onClick={() => {
                                                        setStepCtrl(2)
                                                    }}
                                                >
                                                    voltar
                                                </Button>
                                        }
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            {t('accordionDocuments.buttonContinue')}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionAccount;