import React, { useState } from "react";
import api from '../../../../services/api';
import { setCookie } from 'nookies'

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReCAPTCHA from "react-google-recaptcha";

function AuthLogin() {
    const { t } = useTranslation();

    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [showPass, setShowPass] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);

    const [isRecaptcha, setIsRecaptcha] = useState<any>(null);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (isRecaptcha !== null) {
            const getAuthenticate = async () => {
                try {
                    const { data } = await api.post('/Login/Authenticate', {
                        "email": e.target.email.value,
                        "password": e.target.cust_pass.value
                    });
                    if (data.statusCode !== 400) {
                        setIsRecaptcha(null);
                        localStorage.setItem('bpaSigAuth', JSON.stringify(data.data));
                        const c2tourAuth: any = JSON.parse(localStorage.getItem('bpaSigAuth') || "{}");
                        setCookie(null, 'authCookie', 'loggedInTrue', {
                            maxAge: (60 * 60 * 60),
                            path: '/',
                        })
                        setLoading(false)
                        if (data.data.user.perfils.length > 1) {
                            window.location.href = window.location.origin + '/profile';
                        } else {
                            console.log(c2tourAuth)
                            var newProfileObj: any = c2tourAuth;
                            newProfileObj.user.perfils = undefined;
                            newProfileObj.user.perfil = data.data.user.perfils[0];
                            newProfileObj.user.perfil.nivel = btoa(`${(newProfileObj.user.perfil.nivel)}`);
                            localStorage.setItem('bpaSigAuth', JSON.stringify(newProfileObj));
                            localStorage.setItem("GroupId", data.data.user.perfils[0].token.token);
                            window.location.href = window.location.origin + '/dashboard';
                        }

                    } else {
                        setLoading(false)
                        setErrorMessage(data.errorMessage);
                        setIsRecaptcha(null);
                    }

                } catch (error: any) {
                    if (error?.response?.status === 400) {
                        setLoading(false);
                        setErrorMessage(error.response.data.errorMessage);
                    }
                }
            }

            getAuthenticate();
        }else {
            setLoading(false);
            alert("Por favor, selecione o reCAPTCHA");
        }
    };

    const GetError = () => {
        /* console.log(errorMessage === null); */
        if (errorMessage === null) {
            return (
                <div className="form-group"></div>
            );
        } else {
            return (
                <div className="invalid form-group">
                    <div className="invalid-feedback">{errorMessage}</div>
                </div>
            );
        }
    }

    function handleRacaptcha(value: any) {
        setIsRecaptcha(value);
    }

    return (
        <>
            <form className="user" name="login_form" onSubmit={(e) => onSubmit(e)}>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail">{t("login.email")}</label>
                    <input type="email" name="email" className="form-control" id="exampleInputEmail" aria-describedby="emailHelp" autoComplete="off" />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword">{t("login.password")}</label>
                    <input type={showPass === true ? "text" : "password"} name="cust_pass" className="form-control" id="exampleInputPassword" autoComplete="off" />
                    <div className="iconsPassword">
                        {showPass === false ?
                            <FontAwesomeIcon
                                icon={["fal", "eye-slash"]}
                                size="1x"
                                onClick={() => { setShowPass(!showPass) }}
                            />
                            :
                            <FontAwesomeIcon
                                icon={["fal", "eye"]}
                                size="1x"
                                onClick={() => { setShowPass(!showPass) }}
                            />
                        }

                    </div>
                </div>
                <GetError />

                <div className="form-group">
                    <div className="custom-control custom-checkbox small">
                        <input type="checkbox" name="remember" className="custom-control-input" value="1" id="customCheck" autoComplete="off" />
                        <label className="custom-control-label" htmlFor="customCheck">{t("login.remember")}</label>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <div className="bg-profile-recaptcha">
                        {
                            loading === false
                                ?
                                <ReCAPTCHA
                                    sitekey={`${process.env.REACT_APP_SERVER_SITEKEY}`}
                                    onChange={handleRacaptcha}
                                />
                                :
                                <></>
                        }
                    </div>
                    </div>
                <button type="submit" className="form-button btn btn-primary btn-block btn-login" onClick={() => { setLoading(true) }}>{loading === true ? <div className="load"></div> : t("login.send")}</button>
            </form>
        </>
    )
}

export default AuthLogin;

