import React, { useEffect, useState } from "react";
import { Badge, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DoubleCalendar from "../../../../components/Calendar/DoubleCalendar5DaysBefore";
import DropdownNumberPeopleIntegrated from "../../../../components/DropdownNumberPeopleIntegrated/DropdownNumberPeopleIntegrated";
//import DropdownNumberPeople from "../../../../components/DropdownNumberPeople/DropdownNumberPeople";

import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { addItemCart } from "../../../../store/cart";
import "./ProductOptions.scss"
import DropdownHours from "../../../../components/DropdownHours/DropdownHours";
import RefundRules from "../RefundRules/RefundRules";

export interface modalityInfo {
  modality: any;
  indexMod: any;
  tourDetails: any;
  setModalShow?: any;
  isModal?: any;
}

const TourOptions = ({
  modality,
  indexMod,
  tourDetails,
  setModalShow,
  isModal = false,
}: any) => {
  function ScrollTop() {
    window.scrollTo(0, 0);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isIntegration = false;

  const [numberPeople, setNumberPeople] = useState<any>(null);
  const [dateTour, setDateTour] = useState<any>([]);

  const [indexador, setIndexador] = useState<any>([]); ///array que me diz quais dos tourOptions vai habilitar o dropdown

  const [ranges, setRanges] = useState<any>(null);

  const [peopleDisponible, setPeopleDisponible] = useState<boolean>(false);

  const [preloader, setPreloader] = useState<boolean>(false);

  const cartSIG = useAppSelector((state) => state.cart);

  const setNewNumberPeople = (peoples: any) => {
    setNumberPeople(peoples);
  };

  const dispatch = useAppDispatch();

  function changePriceDateTour(obj: any) {
    setDateTour(obj);
    setNumberPeople({
      adults: "1",
      childs: "0",
      infants: "0",
      elders: "0",
      student: "0",
      globalPeople: "1",
    });
  }

  function addIndex(index: any) {
    var aux: any = indexador;
    if (aux.includes(index) === false) {
      aux.push(index);
    }
    setIndexador(aux);
  }

  useEffect(() => {
    var aux: any = [];
    for (var i = 0; i < tourDetails.modalities.length; i++) {
      aux.push({
        adult: 0,
        child: 0,
        infant: 0,
      });
    }
    setDateTour(aux);
  }, [tourDetails.modalities]);

  function keepDropdownOpen() {
    var elem: any = document.getElementById("authCartDropdown");

    if (elem && !elem.classList.contains("active")) {
      elem.classList.add("active");
    }
  }

  const addToCart = (itemOption: any, index: any) => {
    const item = {
      productName: itemOption.productName,
      productNameEN: itemOption.productNameEN,
      productNameES: itemOption.productNameES,
      modalityName: itemOption.modalityName,
      imgCart: tourDetails.images[0],
      imagesBaseUrl: tourDetails.imagesBaseUrl,
      typeProduct: tourDetails.typeProduct,
      price: itemOption?.tarif?.price,

      idTarif: itemOption.idTarif,
      idPickup: itemOption.idPickup,

      priceAdults: itemOption.priceAdults,
      priceChilds: itemOption.priceChilds,
      priceInfants: itemOption.priceInfants,
      priceElders: itemOption.priceElders,
      priceStudent: itemOption.priceStudent,
      priceGlobalPeople: itemOption.priceGlobalPeople,

      sellingType: itemOption.idSellingType,
      adults: itemOption.adults,
      childs: itemOption.childs,
      infants: itemOption.infants,
      elders: itemOption.elders,
      student: itemOption.student,

      globalPeople: itemOption.globalPeople,
      totalPeople: itemOption.totalPeople,

      productType: 1,
      productCode: tourDetails.productCode,
      time: itemOption.time,
      date: itemOption.date,
      supplier: tourDetails.supplier,
      supplierFantasyName: tourDetails.supplierFantasyName,
      typePickup: itemOption.embarkingType,
      meetingPoint:
        itemOption.embarkingType === "0" ? itemOption.meetingPoint : "",
      pickupListId:
        itemOption.embarkingType === "0" ? 0 : itemOption.pickupListId,
      pickup: 0,

      discount: 0,
      customValueNet: 0,
      customValueSell: 0,
      goingSource: "null",
      commingSource: "null",
      latOrigem: "null",
      lngOrigem: "null",
      latDestino: "null",
      lngDestino: "null",
      cia: "null",
      voo: "null",
      smallSuitcase: 0,
      bigSuitcase: 0,
      internalNotes: " ",
      idVeiculo: 0,
      passengers: [],
    };
    dispatch(addItemCart(item));
  };

  const auth = (option: any, index: any) => {
    let totalPeople: any;

    if (numberPeople?.adults !== '1') {
      totalPeople = Number(numberPeople?.adults) + Number(numberPeople?.childs) + Number(numberPeople?.infants) + Number(numberPeople?.elders) + Number(numberPeople?.student) + Number(numberPeople?.globalPeople);
    } else {
      totalPeople = 0;
    }

    if (totalPeople === 0 || numberPeople === null || dateTour.length < 1) {
      alert("Preencha todos os dados");
    } else {
      option.productName = tourDetails.productName;
      option.productNameEN = tourDetails.productNameEN;
      option.productNameES = tourDetails.productNameES;

      option.adults = numberPeople.adults;
      option.childs = numberPeople.childs;
      option.infants = numberPeople.infants;
      option.elders = numberPeople.elders;
      option.student = numberPeople.student;
      option.globalPeople = dateTour[index].idSellingType === 1 ? 1 : numberPeople.adults + numberPeople.childs + numberPeople.infants + numberPeople.elders + numberPeople.student + numberPeople.globalPeople;
      option.totalPeople = dateTour[index].idSellingType === 1 ? numberPeople.globalPeople : undefined;

      // option.date = (document.getElementById(`date-${option.id}`) as HTMLInputElement).value.split('/').reverse().join('-');
      option.date =
        isModal === true
          ? (
            document.querySelector(
              `.modal-products-info #date-${option.id}`
            ) as HTMLInputElement
          ).value
            .split("/")
            .reverse()
            .join("-")
          : (
            document.querySelector(
              `.accordion #date-${option.id}`
            ) as HTMLInputElement
          ).value
            .split("/")
            .reverse()
            .join("-");

      option.time = dateTour[index].time;

      const selectedDate =
        isModal === true
          ? JSON.parse(
            (
              document.querySelector(
                `.modal-products-info #date-${option.id}`
              ) as HTMLInputElement
            ).dataset.object || "{}"
          )
          : JSON.parse(
            (
              document.querySelector(
                `.accordion #date-${option.id}`
              ) as HTMLInputElement
            ).dataset.object || "{}"
          );
      // const selectedDate = JSON.parse((document.getElementById(`date-${option.id}`) as HTMLInputElement).dataset.object || '{}');

      option.idTarif = selectedDate.idTarif;
      /* option.idPickup = (document.getElementById(`pickup`) as HTMLInputElement).value; */

      option.priceAdults = dateTour[index].adult || 0;
      option.priceChilds = dateTour[index].child || 0;
      option.priceInfants = dateTour[index].infant || 0;
      option.priceElders = dateTour[index].elders || 0;
      option.priceStudent = dateTour[index].student || 0;
      option.priceGlobalPeople = dateTour[index].global || 0;
      option.idSellingType = dateTour[index].idSellingType;

      const verifyEvery = cartSIG.dados.every((elem: any) => {
        if (
          elem.productName === option.productName &&
          elem.date === option.date
        ) {
          return false;
        } else {
          return true;
        }
      });

      if (option.date !== "") {
        var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

        cartSIG.dados.forEach((elem: any) => {
          if (elem.productCode === tourDetails.productCode) {
            repeatedItem = true;
          }
        });

        if (repeatedItem === true && verifyEvery === false) {
          setModalShow(false);
          alert("Produto já existe no carrinho!");
        } else {
          addToCart(option, index);
          keepDropdownOpen();
          setModalShow(false);
          setTimeout(() => ScrollTop(), 200);
        }
      } else {
        alert("Preencha todos os dados");
      }
    }
  };

  return (
    <div className="bg-white mt-4">
      <Row className="m-0">
        <div className="col-12 col-md-9 py-3">
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <h3>
                {modality.modalityName}
                {modality.rangeComissionLabel !== null &&
                  modality.rangeComissionLabel !== "DEFAULT" ? (
                  // eslint-disable-next-line react/jsx-no-undef
                  <span className="rangecomission">
                    <Badge
                      className={modality.rangeComissionLabel
                        .toString()
                        .toLowerCase()}
                    ></Badge>{" "}
                    {modality.rangeComissionLabel}
                  </span>
                ) : (
                  <></>
                )}
              </h3>
              <div className="sub-info">
                <div className="d-flex flex-wrap mt-2">
                  <div translate="no"
                    style={{ height: 'unset' }}
                    className={
                      modality.daysOfWeek.dom === 1
                        ? "weekDay enable"
                        : "weekDay disable"
                    }
                  >
                    Dom
                  </div>
                  <div translate="no"
                    style={{ height: 'unset' }}
                    className={
                      modality.daysOfWeek.seg === 1
                        ? "weekDay enable"
                        : "weekDay disable"
                    }
                  >
                    Seg
                  </div>
                  <div translate="no"
                    style={{ height: 'unset' }}
                    className={
                      modality.daysOfWeek.ter === 1
                        ? "weekDay enable"
                        : "weekDay disable"
                    }
                  >
                    Ter
                  </div>
                  <div translate="no"
                    style={{ height: 'unset' }}
                    className={
                      modality.daysOfWeek.qua === 1
                        ? "weekDay enable"
                        : "weekDay disable"
                    }
                  >
                    Qua
                  </div>
                  <div translate="no"
                    style={{ height: 'unset' }}
                    className={
                      modality.daysOfWeek.qui === 1
                        ? "weekDay enable"
                        : "weekDay disable"
                    }
                  >
                    Qui
                  </div>
                  <div translate="no"
                    style={{ height: 'unset' }}
                    className={
                      modality.daysOfWeek.sex === 1
                        ? "weekDay enable"
                        : "weekDay disable"
                    }
                  >
                    Sex
                  </div>
                  <div translate="no"
                    style={{ height: 'unset' }}
                    className={
                      modality.daysOfWeek.sab === 1
                        ? "weekDay enable"
                        : "weekDay disable"
                    }
                  >
                    Sab
                  </div>
                </div>
              </div>
              <div className="sub-info">
                <small>
                  {
                    modality.activityStart === undefined || modality.activityStart === null
                      ?
                      ''
                      :
                      <span>
                        <FontAwesomeIcon
                          icon={["fal", "clock"]}
                          size="1x"
                          className="mr-2"
                        />
                        {`Ínicio do tour: ${modality.activityStart}`}
                      </span>
                  }
                  <span>
                    <FontAwesomeIcon
                      icon={["fal", "clock"]}
                      size="1x"
                      className="mr-2"
                    />
                    {`Duração: ${modality.activityDuration}`}
                  </span>
                  <span>
                    <FontAwesomeIcon
                      icon={["fal", "clock"]}
                      size="1x"
                      className="mr-2"
                    />
                    {`Compra antecipada: até ${modality.tarif.buyingAdvance} minutos`}
                  </span>
                </small>
              </div>
              <Row className="my-2">
                <div className="col-12 col-md-6">
                  <h4 className="tour-name text-info-products">Inclui</h4>
                  <div className="session-not-yes yes">
                    <small>
                      {modality.includedItems.map((text: any, index: any) => {
                        return (
                          <p key={index}>
                            <FontAwesomeIcon icon={["fal", "check"]} size="1x" />
                            {text.replace("•	", "")}
                          </p>
                        );
                      })}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <h4 className="tour-name text-info-products">Não inclui</h4>
                  <div className="session-not-yes not">
                    <small>
                      {modality.excludedItems.map((text: any, index: any) => {
                        return (
                          <p key={index}>
                            <FontAwesomeIcon icon={["fal", "times"]} size="1x" />
                            {text.replace("•	", "")}
                          </p>
                        );
                      })}
                    </small>
                  </div>
                </div>
              </Row>
            </div>
            <div className="bg-cancel-rules">
              <RefundRules tourDetails={modality} index={indexMod} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 text-center bg-price py-5">
          <div>
            <span className="small text-muted w-50">
              Preço por adulto a partir de:
            </span>
            <div className="price pb-2">
              <span className="h4">
                <small>R$ </small>
                {modality.tarif === null
                  ? "??"
                  : modality.tarif.price.toFixed(2).replace(".", ",")}
              </span>
            </div>
            <div style={{ padding: "0 15px", position: "relative" }}>
              <form className="bg-accordion-add">
                <div onClick={() => { addIndex(indexMod) }}>
                  <DoubleCalendar
                    modalityID={modality.id}
                    product={modality}
                    productCode={tourDetails.productCode}
                    changePriceDateTour={changePriceDateTour}
                    dateTour={dateTour}
                    index={indexMod}
                    setRanges={setRanges}
                    setPreloader={setPreloader}
                  ></DoubleCalendar>
                  <input autoComplete='off' type="hidden" data-id="dataInit" value="2021-12-06" />
                </div>
                <div>
                  {numberPeople !== null &&
                    indexador?.includes(indexMod) === true ? (
                    preloader === false ? (
                        <DropdownHours
                          changePriceDateTour={changePriceDateTour}
                          dateTour={dateTour}
                          index={indexMod}
                          setPeopleDisponible={setPeopleDisponible}
                          info={tourDetails}
                        />
                    ) : (
                      <div className="panel-dropdown bg-white d-flex panel-dropdown-custom">
                        <div style={{ height: "20px" }}>
                          <div className="load"></div>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="panel-dropdown bg-white d-flex panel-dropdown-custom">
                      <div style={{ height: "20px" }}></div>
                    </div>
                  )}
                </div>
                <div className="panel-dropdown bg-white">
                  {
                    peopleDisponible === true
                      ?
                      <DropdownNumberPeopleIntegrated
                        actionPeople={setNewNumberPeople}
                        info={tourDetails}
                        dateTour={dateTour}
                        index={indexMod}
                        ranges={ranges}
                      />
                      :
                      <></>
                  }
                  {/* <DropdownNumberPeople
                                        actual={indexMod}
                                        info={tourDetails}
                                        actionPeople={setNewNumberPeople}
                                        dateTour={dateTour}
                                        isIntegration={isIntegration}
                                        ranges={ranges}
                                    ></DropdownNumberPeople> */}
                </div>
                <span
                  className="btn btn-options btn-primary btn-block btn-add-cart link-add"
                  onClick={() => auth(modality, indexMod)}
                >
                  <FontAwesomeIcon
                    icon={["fal", "cart-arrow-down"]}
                    size="1x"
                    className="mr-2"
                  />
                  Adicionar
                </span>
              </form>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default TourOptions;
