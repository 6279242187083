import React, { useState, useEffect } from 'react';

import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

//import { useTranslation } from "react-i18next";

import './Calendar.scss';

const CustomTime = ({ openCalendar, value, setTime}: any) => {

  setTime(value[0]);
  
  return (
    <input
      id="time"
      className="form-control bg-white rounded"
      onFocus={openCalendar}
      value={value}
      readOnly
      placeholder="Escolha um horário"
      autoComplete='off'
    />
  )
}

const OnlyTime = ({ date, setValue = () => {}, field, setTime}: any) => {


  return (
    <DatePicker
      disableDayPicker
      format="HH:mm"
      render={<CustomTime setTime={setTime} />}
      plugins={[
        <TimePicker hideSeconds />
      ]}
    />
  )
}

export default OnlyTime;



