import React, { useEffect, useState } from 'react';
import api from '../../services/api';

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import FilterManifest from './components/FilterManifest/FilterManifest'
import TableManifest from './components/TableManifest/TableManifest';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import './Manifest.scss';

function Manifest() {
    const [manifestObj, setManifestObj] = useState<any>(null);
    const [error, setError] = useState<any>();
    const [allowLoadFromFilter, setAllowLoadFromFilter] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);


    //FILTRO
    const [actualCod, setActualCod] = useState<any>();
    const [actualDate, setActualDate] = useState<any>([]);
    const [actualType, setActualType] = useState<any>();
    const [actualOperation, setActualOperation] = useState<any>();
    const [actualStatus, setActualStatus] = useState<any>();

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    function clearActualValues() {
        setActualCod("");
        setActualDate([]);
        setActualType(null);
        setActualOperation("");
        setActualStatus(null);
    }

    async function buscar(cod: any, date: any, type: any, operation: any, status: any) {
        setManifestObj(null);
        setLoading(true);
        setPageCount(1);
        setActualCod(cod);
        setActualDate(date);
        setActualType(type);
        setActualOperation(operation);
        setActualStatus(status);

        let beginDate = []
        let endDate = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        console.log(beginDate, endDate)

        try {
            const res = await api.post('/Manifest/GetReport',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "item": cod ? cod : '',
                    "tipo": type ? parseInt(type) : null, // 1 - transfer | 2 - tour | 3 Ticket
                    "operacao": operation ? parseInt(operation) : null, // 1 - Própria | 2 - Antonio Pedro Calarge Wollner Transportes e Eventos LTDA | 
                    "status": status ? parseInt(status) : null,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                },
            );
            if (res.status !== 400) {
                setLoading(false);
                setManifestObj(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);;
            }
        } catch (error: any) {
            setLoading(false);
        }
    };

    useEffect(() => {
        setManifestObj(null);
        setLoading(true);
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        async function getManifest() {
            try {
                const res = await api.post('/Manifest/GetReport',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": actualCod ? actualCod : '',
                        "tipo": actualType ? parseInt(actualType) : null, // 1 - transfer | 2 - tour | 3 Ticket
                        "operacao": actualOperation ? parseInt(actualOperation) : null, // 1 - Própria | 2 - Terceiros 
                        "status": null,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    },
                );
                if (res.status !== 400) {
                    setLoading(false);
                    setManifestObj(res.data.data.rows)
                    console.log(res.data.data.rows)
                    setTotalRows(res.data.data.rowsCount);
                }
            } catch (error: any) {
                setLoading(false);
            }
        }
        getManifest();

    }, [pageCount, rowsPerPage])

    async function exportManifest() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        try {
            const exp = await api.post('/Manifest/ExportReport',
                {
                    "page": pageCount,
                    "rowsPerPage": totalRows,
                    "item": actualCod ? actualCod : '',
                    "tipo": actualType ? parseInt(actualType) : null, // 1 - transfer | 2 - tour | 3 Ticket
                    "operacao": actualOperation ? parseInt(actualOperation) : null, // 1 - Própria | 2 - Terceiros 
                    "status": null,
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                        //"Access-Control-Allow-Origin": "*"

                    },
                    responseType: 'blob'
                },

            );
            if (exp.status !== 400) {
                const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'manifesto.xlsx',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printManifest() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        try {
            const print = await api.post('/Manifest/PrintReport',
                {
                    "page": pageCount,
                    "rowsPerPage": totalRows,
                    "item": actualCod ? actualCod : '',
                    "tipo": actualType ? parseInt(actualType) : null, // 1 - transfer | 2 - tour | 3 Ticket
                    "operacao": actualOperation ? parseInt(actualOperation) : null, // 1 - Própria | 2 - Terceiros 
                    "status": null,
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                        //"Access-Control-Allow-Origin": "*"

                    },
                    responseType: 'blob'
                },

            );
            if (print.status !== 400) {
                const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'manifesto.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);


            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Operações / Manifesto'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Manifesto</h2>
                    </div>
                    <FilterManifest
                        buscar={buscar}
                        clearActualValues={clearActualValues}
                        allowLoadFromFilter={allowLoadFromFilter}
                    />
                    <TableManifest
                        manifestObj={manifestObj}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportManifest={exportManifest}
                        printManifest={printManifest}
                        loading={loading}
                    />
                </div>
            </DesktopDefault>
        </>

    );
}

export default Manifest;