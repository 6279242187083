import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";

import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import Modal from "react-bootstrap/Modal";

import api from '../../../../services/api';

import FormReceiptValueEdit from "../Forms/FormReceiptValueEdit/FormReceiptValueEdit";

//STYLES
import "./TableSessionBillingBillsReceive.scss";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";

export interface propTable {
  bills: any,
  dataOperacao: any,
  refrestData: any,
}

const TableSessionBillingBillsReceive: React.FC<propTable> = ({
  bills, dataOperacao, refrestData
}: propTable) => {
  const { t } = useTranslation();

  const [billEditModalShow, setBillEditModalShow] = React.useState(false);
  const [billEdit, setBillEdit] = React.useState(null);
  const [issueInvoiceModalShow, setIssueInvoiceModalShow] = React.useState(false);
  const [issueInvoiceModal2Show, setIssueInvoiceModal2Show] = React.useState(false);
  const [cancelInvoiceModalShow, setCancelInvoiceModalShow] = React.useState(false);
  const [invoiceToIssue, setInvoiceToIssue] = React.useState(0);

  function handleBillEditClick(row: any) {
    // chama modal
    setBillEdit(row);
    setBillEditModalShow(true);
  }

  function handleActionBillEditModal(data: any) {
    setBillEdit(null);
  }

  function handleActionBillEditHideModal() {
    //() => setBillEditModalShow(false)
    setBillEditModalShow(false);
    setBillEdit(null);
  }

  function issueInvoice(row: any) {
    var tour_dat = new Date(dataOperacao.substring(0,4), parseInt(dataOperacao.substring(5,7))-1, dataOperacao.substring(8,10));//new Date(data_tour);
    var hoje = new Date();
    hoje.setHours(0,0,0,0);
    
    if(tour_dat < hoje && tour_dat.getMonth() < hoje.getMonth()) {
      //console.log('menor');
      setInvoiceToIssue(row.newItemsId);
      setIssueInvoiceModalShow(true);
    } else {
      //console.log('n menor');
      issueInvoice1(row.newItemsId);
    }
  }

  function issueInvoice1(id: any) {
    setIssueInvoiceModalShow(false);
    setInvoiceToIssue(id);
    setIssueInvoiceModal2Show(true);
  }

  async function issueInvoice2(id: any, notaCliente: any) {
    setIssueInvoiceModal2Show(false);

    let userData = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');
    const user = userData.user;

    const uid = user.uId;
    const usuario = `${user.nome} ${user.lastName}`;

    const config = {
        headers: { 'Authorization': `Bearer ${userData.token.token}` },
    };

    try {
        const res = await api.post('SessionClosure/IssueInvoiceAsync',
            {
                "itemId": id,
                "notaCliente": notaCliente,
                "uid": uid,
                "usuario": usuario
            }, config
        );
        if (res.status !== 400) {
            const dados = res.data.data;

            alert(dados.texto);

            if(dados.log != 1) {
              refrestData();
            }
        }
    } catch (error: any) {
        //setError(true)
        if (error?.response?.status === 401) {
            window.location.href = window.location.origin + '/';
        }
    }
  }
  const handleIssueInvoiceClick = (row: any) => {
    issueInvoice(row);
  }

  // const handleIssueInvoiceInBathClick = () => {
    
  // }

  function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
    //const blob = new Blob([file], { type: filetype });
    const blob = `data:${filetype};base64,${file}`;
    //console.log(blob);
    //if(navigator.msSaveBlob){ // For ie and Edge
    //  return navigator.msSaveBlob(blob, filename);
    //}
    //else{
      let link = document.createElement('a');
      link.href = blob;//window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
      link.remove();
      window.URL.revokeObjectURL(link.href);
    //}
  };

  async function downloadInvoice(id: any) {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
    };

    try {
        const res = await api.post('SessionClosure/DownloadInvoicePdfAsync',
            {
              "itemId": id
            }, config
        );
        if (res.status !== 400) {
            //download file
            const dados = res.data.data;
            download(dados.file, dados.fileType, dados.fileName);
        }
    } catch (error: any) {
        //setError(true)
        if (error?.response?.status === 401) {
            window.location.href = window.location.origin + '/';
        }
    }
  }

  const handleDownloadInvoiceClick = (row: any) => {
    downloadInvoice(row.newItemsId);
  }

  function cancelInvoice(id: any) {
    setInvoiceToIssue(id);
    setCancelInvoiceModalShow(true);
  }

  async function cancelInvoice1(id: any) {
    setCancelInvoiceModalShow(false);

    let userData = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');
    const user = userData.user;

    const uid = user.uId;
    const usuario = `${user.nome} ${user.lastName}`;

    const config = {
        headers: { 'Authorization': `Bearer ${userData.token.token}` },
    };

    try {
      const res = await api.post('SessionClosure/CancelInvoice',
        {
          "itemId": id,
          "uid": uid,
          "usuario": usuario
        }, config
      );
      if (res.status !== 400) {
        const dados = res.data.data;

        alert(dados.texto);

        if(dados.log != 1) {
          refrestData();
        }
      }
    } catch (error: any) {
      //setError(true)
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
    }
  }

  const handleCancelInvoiceClick = (row: any) => {
    cancelInvoice(row.newItemsId);
  }


  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" onClick={(e: any) => handleBillEditClick(row)}>
                <FontAwesomeIcon
                  icon={["fal", "edit"]}
                  size="1x"
                  style={{ marginRight: "1px" }}
                /> Editar
              </Dropdown.Item>
              {row.statusNotaFiscal == 4 ? <>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="2" onClick={(e: any) => handleIssueInvoiceClick(row)}>
                <FontAwesomeIcon
                  icon={["fal", "file-invoice"]}
                  size="1x"
                  style={{ marginRight: "1px" }}
                /> Emitir Nota
              </Dropdown.Item></>
              : row.statusNotaFiscal == 1 ? <>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="2" onClick={(e: any) => handleCancelInvoiceClick(row)}>
                <FontAwesomeIcon
                  icon={["fal", "times"]}
                  size="1x"
                  style={{ marginRight: "1px" }}
                /> Cancelar Nota
              </Dropdown.Item></>
              : row.statusNotaFiscal == 3 || row.statusNotaFiscal == 5 ? <>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="2" onClick={(e: any) => handleIssueInvoiceClick(row)}>
                <FontAwesomeIcon
                  icon={["fal", "file-invoice"]}
                  size="1x"
                  style={{ marginRight: "1px" }}
                /> Re-Emitir Nota
              </Dropdown.Item></>
              : <></>
              }
              <Dropdown.Divider />
              <Dropdown.Item eventKey="3" onClick={(e: any) => handleDownloadInvoiceClick(row)} disabled={row.statusNotaFiscal == 4}>
                <FontAwesomeIcon
                  icon={["fal", "file-pdf"]}
                  size="1x"
                  style={{ marginRight: "1px" }}
                /> Baixar Nota
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any, row: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={"w-100 "+
          (cell === 'Conciliado'
            ? "bg-blue"
            : cell === 'Faturado' || cell === 'Recebido'
              ? "bg-ativo"
              : cell === 'Pendente'
                ? "bg-inativo"
                : "")
        }
      >
        {cell}
      </div>
    );
  }

  function addStatusNotaFiscal(cell: any, row: any) {
    var statusDesc = row.statusNotaFiscalDesc;

    return (
      cell == 1 ? 
        <FontAwesomeIcon
          icon={["fal", "check"]}
          size="1x"
          style={{ marginRight: "1px", color: "#006400" }}
          title={statusDesc != '' ? statusDesc : 'Emitida'}
        />
      : cell == 2 ? 
        <FontAwesomeIcon
          icon={["fal", "clock"]}
          size="1x"
          style={{ marginRight: "1px" }}
          title={statusDesc != '' ? statusDesc : 'Processando'}
        />
      : cell == 3 ? 
        <FontAwesomeIcon
          icon={["fal", "times"]}
          size="1x"
          style={{ marginRight: "1px", color: "#FF0000" }}
          title={statusDesc != '' ? statusDesc : 'Cancelada'}
        />
      : cell == 5 ? 
        <FontAwesomeIcon
          icon={["fal", "exclamation-triangle"]}
          size="1x"
          style={{ marginRight: "1px", color: "#FFA500" }}
          title={statusDesc != '' ? statusDesc : 'Erro'}
        />
      : <></>
    );
  }

  /* ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  const columns = [
    { dataField: "formaPagamento", text: "Forma de Pag.", sort: true },
    { dataField: "adquirente", text: "Adquirente", sort: true },
    { dataField: "descricao", text: "Descrição", sort: true },
    { dataField: "contaContabil", text: "Conta Contabil", sort: true },
    { dataField: "agente", text: "Cliente", sort: true },
    { dataField: "totalPax", text: "QTD", sort: true },
    { dataField: "totalAdult", text: "ADT", sort: true },
    { dataField: "totalChild", text: "CHD", sort: true },
    { dataField: "totalInfant", text: "INF", sort: true },
    {
      dataField: "valor",
      text: "Valor",
      sort: true,
      formatter: convertValue,
    },
    {
      dataField: "vencimento",
      text: "Vencimento*",
      sort: true,
      formatter: convertDate,
    },
    { dataField: "observacao", text: "Observação*", sort: true },
    { dataField: "statusPagamentoDesc", text: "Status Pagamento", formatter: addStatus },
    { dataField: "valorReceita", text: "Receita (R$)", sort: true, formatter: convertValue },
    { dataField: "faturaId", text: "Fatura", sort: true },
    { dataField: "statusNotaFiscal", text: "Nota Fiscal", formatter: addStatusNotaFiscal },
    { dataField: "localizador", text: "Ações", formatter: addActionButton },
  ];

  /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

  const notFoundProducts = [{ clientes: "Nenhuma session encontrada" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === 2) {
      return 'border-analise';
    } else if (row.statusPagamento === 3 || row.statusPagamento === 4) {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  if (bills !== null) {
    return (
      <>
        <div className="table-default session-b-bills-receive-table">
          <div className="table-container">
            <div>
              <h4 className="p-0 m-0">Contas a Receber</h4>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={bills}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />

            {billEdit !== null? 
              <Modal
                className="modalAuth"
                show={billEditModalShow}
                onHide={handleActionBillEditHideModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton></Modal.Header>
                  <FormReceiptValueEdit action={handleActionBillEditModal} info={billEdit} />
                </>
              </Modal>: <></>}

              <ModalQuestion 
                modalShow={issueInvoiceModalShow}
                setModalShow={setIssueInvoiceModalShow}
                titulo="Emitir Nota Fiscal"
                mensagem="Data com mes anterior ao da data corrente, Continua?"
                handleActionConfirmModal={(e: any) => issueInvoice1(invoiceToIssue)}
                //handleActionCancelModal={handleActionCancelDeleteClick}
              />

              <ModalQuestion 
                modalShow={issueInvoiceModal2Show}
                setModalShow={setIssueInvoiceModal2Show}
                titulo="Emitir Nota Fiscal"
                mensagem="Nota emitida contra (P)assageiro ou (C)liente ?"
                buttons={
                  <>
                    <Button className="form-button mr-3" onClick={(e:any) => issueInvoice2(invoiceToIssue,2)}>
                      Passageiro
                    </Button>
                    <Button className="form-button mr-3" onClick={(e:any) => issueInvoice2(invoiceToIssue,1)}>
                      Cliente
                    </Button>
                  </>
                }
                //handleActionConfirmModal={(e: any) => issueInvoice2(invoiceToIssue,2)}
                //handleActionCancelModal={handleActionCancelDeleteClick}//1=cliente;2=passageiro
              />

              <ModalQuestion 
                modalShow={cancelInvoiceModalShow}
                setModalShow={setCancelInvoiceModalShow}
                titulo="Cancelar Nota Fiscal"
                mensagem="Deseja mesmo cancelar a Nota Fiscal?"
                handleActionConfirmModal={(e: any) => cancelInvoice1(invoiceToIssue)}
                //handleActionCancelModal={handleActionCancelDeleteClick}
              />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="session-b-bills-receive-table not-found d-flex w-100 justify-content-center mt-4">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableSessionBillingBillsReceive;
