import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import "./VoucherDownload.scss";
import api from "../../../../services/api";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";

export interface propVoucher {
    setVoucherModal: any,
    currentReservation: any,
    setModalShow: any,
    setModalLog: any,
    setModalMessage: any
}

const VoucherDownload: React.FC<propVoucher> = ({
    setVoucherModal, currentReservation, setModalShow, setModalLog, setModalMessage
}: propVoucher) => {
    const [voucher, setVoucher] = useState<any>(null);
    const [selected, setSelected] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false);
    const [isLoad, setIsLoad] = useState<boolean>(false);

    useEffect(() => {
        if (currentReservation?.idNewItems === undefined) {
            currentReservation.idNewItems = currentReservation?.idItem;
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        async function listVoucher() {
            try {
                const { data } = await api.post(`/Reservations/ListAllItemLogPrint`,
                    {
                        "idItem": currentReservation?.idNewItems
                    }, config);
                if (data.status !== 400) {
                    setVoucher(data.data.data);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listVoucher();
    }, [currentReservation, currentReservation?.idNewItems, isLoad]);

    useEffect(() => {
        const removeElem: any = document.querySelectorAll(".bg-voucher-download tbody tr td input");
        const trElem: any = document.querySelectorAll(".bg-voucher-download tbody tr");

        for (let i = 0; i < voucher?.length; i++) {
            if (voucher[i].texto.includes("Usado")) {
                trElem[i].style = "pointer-events: none; border-color: #cecece !important;border-right: 1px solid #cecece !important; border-top: 1px solid #cecece !important; border-bottom: 1px solid #cecece !important; border-left: 2px solid #707070 !important; background-color: #cecece4f !important; transition: .3s;";
                removeElem[i]?.remove();
            }
        }
    }, [voucher]);

    function convertDate(cell: any) {
        return (
            <>
                {cell?.split(" ")[0]}
            </>
        )
    }

    const columns = [
        { dataField: "date", text: "Data", formatter: convertDate },
        { dataField: "qr", text: "Ticket" },
        { dataField: "texto", text: "Situação" },
    ];

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando Tickets", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    const rowClasses = (row: any) => {
        return '';
    };

    let vouchers: any = [];

    const selectRow: SelectRowProps<any> = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selected,
        onSelect: (row, isSelect) => {
            if (isSelect === true) {
                vouchers.push(row.qr);
            } else {
                let i: any = vouchers.indexOf(row.qr);
                vouchers.splice(i, 1);
            }
        },
        onSelectAll: (isSelect, rows) => {
            if (isSelect === true) {
                for (let i = 0; i < rows.length; i++) {
                    if (voucher[i].texto.includes("Usado") !== true) {
                        vouchers.push(rows[i].qr)
                    }
                }
            } else {
                vouchers.splice(0);
            }
        }
    }

    const DownloadVoucher = async () => {
        setLoading(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            if (vouchers.length > 0) {
                const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/Checkout/SecondWayWithQR`,
                    {
                        "idItem": currentReservation?.idNewItems,
                        "qr": vouchers
                    }
                    , config);
                if (data.status !== 400) {
                    if (data.data.log === 0 || data.data.log === '0') {
                        setVoucherModal(false);
                        setModalShow(true);
                        setModalLog(data.data.log);
                        setModalMessage("Download realizado com sucesso");

                        const link = document.createElement('a');
                        link.href = `data:${data.data.data.voucherFile.contentType};base64, ${data.data.data.voucherFile.content}`;
                        link.setAttribute(
                            'download',
                            data.data.data.voucherFile.fileName,
                        );

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // // Clean up and remove the link
                        link?.parentNode?.removeChild(link);

                        setLoading(false);
                        logReprint(vouchers);
                    } else if (data.data.log === 1 || data.data.log === '1') {
                        setModalLog(data.data.log);
                        setLoading(false);
                        setModalMessage(data.data.texto);
                    }
                }
            } else {
                setModalLog(1);
                setLoading(false);
                //setModalMessage(data.data.texto);
                alert("Por favor, selecione um ticket a ser impresso")
            }
        } catch (error: any) {
            setLoading(false);
            setModalLog(1);
        }
    };

    const getAccessCode = async (idItem: any) => {
        setLoadingRefresh(true);

        try {
            const { data } = await api.get(`/NewReservations/UpdateStatusSkidataReservationsByIdAsync/${idItem}`);

            if (data.status !== 400) {
                setLoadingRefresh(false);
                setIsLoad(!isLoad);
            }
        } catch (error: any) {
        }
    }

    const logReprint = async (vouchers: any) => { // Log de Reimpressão
        if (currentReservation?.idNewItems === undefined) {
            currentReservation.idNewItems = currentReservation?.idItem;
        }

        try {
            const { data } = await api.post(`/Reservations/SavePrintLog`,
                {
                    "idItem": currentReservation.idNewItems,
                    "qr": vouchers
                }
            );
            if (data?.statusCode !== 400) {
                setTimeout(() => {
                    //setManagement('success');
                }, 5000);
            } else {
                //setManagement("error");
            }
        } catch (e) {
            //setManagement("error");
        }
    }

    if (voucher !== null && loadingRefresh === false) {
        return (
            <div className="d-flex flex-column justify-content-center h-100">
                <Modal.Header className="justify-content-between align-items-center">
                    <div>
                        <h4 className="pt-1 text-primary title-reservation">Controle de Voucher</h4>
                    </div>
                    <FontAwesomeIcon
                        icon={['fal', 'times']}
                        className="mx-3 text-info"
                        size="lg"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => { setVoucherModal(false) }}
                    />
                </Modal.Header>
                <div className="bg-voucher-download">
                    <hr className="w-100 my-2" style={{ borderBottom: "1px solid" }}></hr>
                    <div className="table-default">
                        <FontAwesomeIcon
                            icon={['fad', 'redo-alt']}
                            className='access-code-reload-btn text-primary mr-2'
                            onClick={() => { getAccessCode(currentReservation?.idNewItems) }}
                            size="1x"
                            style={{ position: "absolute", right: "0", top: "38.5px", fontSize: "1.25rem" }}
                        />
                        <BootstrapTable
                            bootstrap4
                            keyField="qr"
                            selectRow={selectRow}
                            data={voucher}
                            columns={columns}
                            rowClasses={rowClasses}
                        />
                    </div>
                </div>
                <Modal.Footer className="d-flex justify-content-end px-0 pb-0">
                    <button className="btn btn-primary" onClick={DownloadVoucher}>
                        {loading === true ? <div className="load"></div> : "Download"}
                    </button>
                </Modal.Footer>
            </div>
        )
    } else {
        return (
            <div className="d-flex flex-column justify-content-center h-100">
                <Modal.Header className="justify-content-between align-items-center">
                    <div>
                        <h4 className="pt-1 text-primary title-reservation">Controle de Voucher</h4>
                    </div>
                    <FontAwesomeIcon
                        icon={['fal', 'times']}
                        className="mx-3 text-info"
                        size="lg"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => { setVoucherModal(false) }}
                    />
                </Modal.Header>
                <div className="bg-voucher-download">
                    <hr className="w-100 my-2" style={{ borderBottom: "1px solid" }}></hr>
                    <div className="table-default loading">
                        <div className="load primary m-0 mr-2" style={{ position: "absolute", right: "0", top: "38.5px" }}></div>
                        <BootstrapTable
                            bootstrap4
                            keyField="qr"
                            selectRow={selectRow}
                            data={loadingProducts}
                            columns={loadingColumns}
                            rowClasses={rowClasses}
                        />
                    </div>
                </div>
                <Modal.Footer className="d-flex justify-content-end px-0">
                    <button className="btn btn-primary">
                        <div className="load"></div>
                    </button>
                </Modal.Footer>
            </div>
        )
    }
}

export default VoucherDownload;