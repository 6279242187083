import React from 'react';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";

import './Calendar.scss';

const CustomMultipleInput = ({ openCalendar, value, date, setValue, errors, startTimeLocal }: any) => {
  if (typeof value === 'object') {
    value = value[0];
  } else {
    value = date;
  }

  setValue(value);
  //value[0] = date;

  return (
    <input
      className="form-control"
      onFocus={openCalendar}
      value={value}
      autoComplete='off'
    //readOnly
    />
  )
}

const SingleCalendarWithDefaultValue = ({
  date, setValue = () => { }, field, errors = undefined, startTimeLocal = undefined, disableFutureDates = false, disablePastDates = false, pastDateToDisable = null, futureDateToDisable = null
}: any) => {
  const { t } = useTranslation();
  //const [aux, setAux] = useState<any>(date);

  /* useEffect(()=>{
    setValue('date',aux);
  },[aux]) */

  const minDate: any = pastDateToDisable !== null ? new Date(pastDateToDisable) : new Date();
  const maxDate: any = futureDateToDisable !== null ? new Date(futureDateToDisable) : new Date();

  return (
    <DatePicker
      {...field}
      //svalue={new Date(defaultDate)}
      //onChange={(e: any) => {setAux(e.year + "-" + e.month + "-" + e.day) }}
      weekDays={t('calendar.weekDays').split(',')}
      months={t('calendar.months').split(',')}
      format={configData.DATE.PT}
      calendarPosition={`top`}
      maxDate={disableFutureDates === true ? maxDate : ''}
      minDate={disablePastDates === true ? minDate : ''}
      render={<CustomMultipleInput date={date} setValue={setValue} errors={errors} startTimeLocal={startTimeLocal} />}
    />
  )
}

export default SingleCalendarWithDefaultValue;