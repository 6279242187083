import React, { Key, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../assets/sass/modal.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';

import GetSessionsByDateAndType from "../../C2Points/GetSessionsByDateAndType";

import api from '../../../services/api';

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

export interface propModal {
  action: any,
  bill: any,
  date: any,
  operationType: any, 
  sessionRelocAtual: any
};

const FormSessionChange: React.FC<propModal> = ({
  action, bill, date, operationType, sessionRelocAtual
}: propModal, props: any) => {
  const { t } = useTranslation();
  const [sessionReloc, setSessionReloc] = useState<string>(sessionRelocAtual);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sessionReloc: sessionRelocAtual
    },
  });

  const onSubmitContact = (data: any) => {
    //console.log(sessionReloc);
    //return;

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
    };

    async function changeSession() {
        try {
            const { data } = await api.post('Manifest/ChangeSession',
                {
                  "localizador": bill.localizador,
                  "itemId": bill.newItemsId,
                  "qtdePax": bill.totalPax, 
                  "sessionOrigem": sessionRelocAtual,
                  "sessionDestino": sessionReloc,
                }, config
            );

            if (data.status !== 400) {
              const dados = data.data;

              alert(dados.texto);

              if(dados.log != 1) {
                action(data);
              }
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    }
    changeSession();
  };

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <h5 className="contact-title mb-5">Trocar Session</h5>
            <Form
              className="form-add-contact"
              noValidate
            >
              <div className="">
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Session Destino:</Form.Label>
                    <Controller
                      control={control}
                      name="sessionReloc"
                      rules={{ required: { value: true, message: 'Por favor, informe o tipo.' } }}
                      render={({ field }: any) => (
                        <GetSessionsByDateAndType propsField={field} propsErrors={errors} propsLabel='Session Destino' date={date} operationType={operationType} setSessionReloc={setSessionReloc} defaultValue={sessionRelocAtual} />
                      )}
                    />
                  </Form.Group>
                </Row>
              </div>
            </Form>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {/* <Button className="form-button mr-3" onClick={props.onHide}>
            Cancelar
          </Button> */}
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(onSubmitContact)}
          >
            Confirmar
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormSessionChange;
