import React, { useEffect, useState } from "react";

import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
/* import GetSuppliersFilter from "../../../../components/C2Points/GetSuppliersFilter"; */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../services/api";
import GetProductsListSubCategoryMult from "../../C2Points/GetProductsListSubCategoryMult";
//import GetCostCenter from "../../../../components/C2Points/GetCostCenter";

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { addDataTour } from "../../../store/product";

export interface productTour {
    action: any,
    receiveObject: any,
    tourObject: any,
    isEdit: any,
    isTicket?: boolean;
    editFunction?: any;
};

const DataTour: React.FC<productTour> = React.memo(({
    action, receiveObject, tourObject, isEdit, isTicket = false, editFunction
}: productTour) => {

    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const tourSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.tour)));
    /* Controle Redux  */

    const [nameProdPT, setNameProdPT] = useState<string>("");
    const [nameProdEN, setNameProdEN] = useState<string>("");
    const [nameProdES, setNameProdES] = useState<string>("");

    const [valNamePt, setValNamePt] = useState<boolean>(false);
    const [valNameEs, setValNameEs] = useState<boolean>(false);
    const [valNameEn, setValNameEn] = useState<boolean>(false);

    const [controlLoop, setControlLoop] = useState<boolean>(false); //para controlar o bug do useeffect que possui "tourSIG" no array de dependência

    const [serviceType, setServiceType] = useState<string>('');

    const [numMin, setNumMin] = useState<any>(parseInt(tourSIG?.tour?.minNumPeople || 1));
    const [numMax, setNumMax] = useState<any>(parseInt(tourSIG?.tour?.maxNumPeople || 1));
    const [editName, setEditName] = useState<boolean>(true);
    //const editName: any = true;
    const [editSale, setEditSale] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        //getValues,
        setValue,
        formState: { errors },
    } = useForm({});

    function less(type: any) {
        if (type === "min") {
            if (numMin > 1) {
                setNumMin(numMin - 1);
            }
        } else {
            if (numMax > 1) {
                if (numMin === numMax) {  //impede que o número máximo de pessoas seja menor que o mínimo
                    setNumMin(numMin - 1);
                }
                setNumMax(numMax - 1);
            }
        }
    }

    function more(type: any) {
        if (type === "min" && numMin < 500) {
            if (numMin === numMax) {  //impede que o número mínimo de pessoas seja maior que o máximo
                setNumMax(parseInt(numMax) + 1);
            }
            setNumMin(parseInt(numMin) + 1);
        } else if (numMax < 500) {
            setNumMax(parseInt(numMax) + 1);
        }
    }

    useEffect(() => {
        if (tourSIG?.tour?.productName && controlLoop === false) {
            setControlLoop(true);
            setNameProdPT(tourSIG.tour.productName[0].text);
            setValue("nameTourPT", tourSIG.tour.productName[0].text);
            setNameProdEN(tourSIG.tour.productName[1].text);
            setValue("nameTourEN", tourSIG.tour.productName[1].text);
            setNameProdES(tourSIG.tour.productName[2].text);
            setValue("nameTourES", tourSIG.tour.productName[2].text);

            setServiceType(tourSIG.tour.sellingType === 2 ? 'Regular' : "Privativo")
            setValue('sellingType', tourSIG.tour.sellingType)

            setNumMin(parseInt(tourSIG.tour.minNumPeople));
            setNumMax(parseInt(tourSIG.tour.maxNumPeople));

            setServiceType(tourSIG.tour.sellingType === 2 ? "Regular" : "Privativo");
        }/* else if(controlLoop === false){
            setControlLoop(true);

            setNameProdPT(tourObject.nameProduct);
            setValue("nameTourPT", tourObject.nameProduct);
            if(tourObject.isTranslate === 1){
                TranslateTextEN(tourObject.nameProduct);
                TranslateTextES(tourObject.nameProduct);
            }

            setServiceType(tourObject.sellingTypeDesc);
            setValue('sellingType',tourObject.sellingType);


        } */

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourSIG])

    useEffect(() => {
        if (isEdit === false) {
            setNameProdPT(tourObject.nameProduct);
            setValue("nameTourPT", tourObject.nameProduct);
            if (tourObject.isTranslate === 1) {
                if (!tourObject.nameTourEN) {
                    TranslateTextEN(tourObject.nameProduct);
                }
                if (!tourObject.nameTourES) {
                    TranslateTextES(tourObject.nameProduct);
                }
            }

            setServiceType(tourObject.sellingTypeDesc);
            setValue('sellingType', tourObject.sellingType);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createTicketObject = (data: any) => {
        data.isTicket = true;
        data.productName = [
            {
                "text": nameProdPT,
                "lang": 1
            },
            {
                "text": nameProdEN,
                "lang": 2
            },
            {
                "text": nameProdES,
                "lang": 3
            }
        ];

        data.minNumPeople = parseInt(numMin);
        data.maxNumPeople = parseInt(numMax);
        data.sellingType = serviceType === "Privativo" ? 1 : 2;
        data.ticketSellingType = serviceType === "Privativo" ? 1 : 2;
        data.sellingTypeDesc = data.sellingType === 1 ? "Privativo" : "Regular";

        delete data.nameTourPT;
        delete data.nameTourEN;
        delete data.nameTourES;

        //data.productSubCategory = data.subcategory.map(({ value }: any) => ({ productCategoryId: value }));

        return data;
    }

    const createTourObject = (data: any) => {
        data.productName = [
            {
                "text": nameProdPT,
                "lang": 1
            },
            {
                "text": nameProdEN,
                "lang": 2
            },
            {
                "text": nameProdES,
                "lang": 3
            }
        ];

        data.minNumPeople = parseInt(numMin);
        data.maxNumPeople = parseInt(numMax);
        data.sellingType = serviceType === "Privativo" ? 1 : 2;
        data.sellingTypeDesc = data.sellingType === 1 ? "Privativo" : "Regular";

        delete data.nameTourPT;
        delete data.nameTourEN;
        delete data.nameTourES;

        data.productSubCategory = data.subcategory.map(({ value }: any) => ({ productCategoryId: value }));

        return data;
    }

    const onSubmit = (data: any) => {
        let newTourObject: any = { ...tourObject, ...data };
        let dataToInsert: any;

        if (isTicket) {
            dataToInsert = createTicketObject(data);
        } else {
            dataToInsert = createTourObject(data);
        }

        if (isEdit !== true) {
            if (valNamePt === true && valNameEn === true && valNameEs === true) {
                dispatch(addDataTour(dataToInsert));
                action(/* isTicket ?  */"operation"/*  : "summaryAndDetails" */, newTourObject);
            }
        } else {
            delete dataToInsert.subcategory;

            editFunction(dataToInsert);
            //receiveObject(data);
        }
    }

    const TranslateTextEN = async (name: string) => {
        try {
            const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                "q": name,
                "source": "pt-BR",
                "target": "en",
                "format": "text"
            });
            if (res.status !== 400) {
                setNameProdEN(res?.data?.data?.translations[0]?.translatedText);
                setValue('nameTourEN', res?.data?.data?.translations[0]?.translatedText);
            }
        } catch (error: any) {

        }
        /* try {
            const res = await api.post(`https://api.mymemory.translated.net/get?q=${name}&langpair=pt-BR|en-GB`);
            if (res.status !== 400) {
                setNameProdEN(res.data.responseData.translatedText);
                setValue('nameTourEN', res.data.responseData.translatedText);
            }
        } catch (error: any) {

        } */
    };

    const TranslateTextES = async (name: string) => {
        try {
            const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                "q": name,
                "source": "pt-BR",
                "target": "es",
                "format": "text"
            });
            if (res.status !== 400) {
                setNameProdES(res?.data?.data?.translations[0]?.translatedText);
                setValue('nameTourES', res?.data?.data?.translations[0]?.translatedText);
            }
        } catch (error: any) {

        }
        /* try {
            const res = await api.post(`https://api.mymemory.translated.net/get?q=${name}&langpair=pt-BR|es-ES`);
            //const res = await api.post(`https://api.mymemory.translated.net/get?q=${portText.split("<p>")[1].split("</p>")[0]}&langpair=pt-BR|es-ES`);
            if (res.status !== 400) {
                setNameProdES(res.data.responseData.translatedText);
                setValue('nameTourES', res.data.responseData.translatedText);
            }
        } catch (error: any) {

        } */
    };

    useEffect(() => {
        if (nameProdPT !== undefined) {
            if (nameProdPT?.length < 4) {
                setValNamePt(false);
            } else {
                setValNamePt(true);
            }
        }

        if (nameProdES !== undefined) {
            if (nameProdES?.length < 4) {
                setValNameEs(false);
            } else {
                setValNameEs(true);
            }
        }

        if (nameProdEN !== undefined) {
            if (nameProdEN?.length < 4) {
                setValNameEn(false);
            } else {
                setValNameEn(true);
            }
        }
    }, [nameProdEN, nameProdES, nameProdPT])

    const handleChangeNumMin = (e: any) => {
        setNumMin(e.target.value);
    }

    const handleBlurNumMin = (e: any) => {
        if (e.target.value >= numMax) {
            setNumMin(numMax);
        }
    }

    const handleChangeNumMax = (e: any) => {
        setNumMax(e.target.value);
    }

    const handleBlurNumMax = (e: any) => {
        if (e.target.value <= numMin) {
            setNumMax(numMin);
        }
        if (numMax > 500) {
            setNumMax(500);
        }
        if (numMin > 500) {
            setNumMin(500);
        }
    }

    const handleBlurItems = (e: any) => {
        if (tourObject.isTranslate === 1 && editName === true) {
            TranslateTextItemsEN(e.target.value);
            TranslateTextItemsES(e.target.value);
        }
    };

    const TranslateTextItemsEN = async (name: string) => {
        if (name !== undefined && name !== "") {
            try {
                const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                    "q": name,
                    "source": "pt-BR",
                    "target": "en",
                    "format": "text"
                });
                if (res.status !== 400) {
                    setNameProdEN(res?.data?.data?.translations[0]?.translatedText);
                    setValue('nameTourEN', res?.data?.data?.translations[0]?.translatedText);
                }
            } catch (error: any) { }
        } else {
            setValue(`nameTourEN`, "");
            setNameProdEN("");
        }
    };

    const TranslateTextItemsES = async (name: string) => {
        if (name !== undefined && name !== "") {
            try {
                const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                    "q": name,
                    "source": "pt-BR",
                    "target": "es",
                    "format": "text"
                });
                if (res.status !== 400) {
                    setNameProdES(res?.data?.data?.translations[0]?.translatedText);
                    setValue('nameTourES', res?.data?.data?.translations[0]?.translatedText);
                }
            } catch (error: any) { }
        } else {
            setValue(`nameTourES`, "");
            setNameProdES("");
        }
    };

    return (
        <>
            {/* CONTENT */}
            {
                (isEdit === false || tourSIG?.tour?.productName) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content">
                            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <h3 className="text-primary pt-title mb-0">{`Dados do ${isTicket ? "Ticket" : "Tour"}`}</h3>

                                <div className="pt-content-info">
                                    <Row>
                                        <div className="d-flex justify-content-between">
                                            <h5 className="mb-0 pt-subtitle">Nome do Produto</h5>
                                            <div className="btn-edit" onClick={() => setEditName(!editName)}>
                                                <FontAwesomeIcon
                                                    icon={["fal", "edit"]}
                                                    size="1x"
                                                    className="mr-2"
                                                />
                                                Editar
                                            </div>
                                        </div>

                                        <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                            <Form.Label className="label-small d-flex justify-content-between">
                                                <span>Nome do tour (Português)</span>
                                                <span style={{ fontWeight: 400 }}>
                                                    {`${nameProdPT.length} caracteres`}
                                                </span>
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="nameTourPT"
                                                //rules={{ required: { value: true, message: 'Por favor, informe o nome do produto' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text"
                                                        aria-invalid={errors?.nameTourPT ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {
                                                            setNameProdPT(e.target.value);
                                                            setValue('nameTourPT', e.target.value)
                                                        }}
                                                        onBlur={(e: any) => {
                                                            handleBlurItems(e);
                                                        }}
                                                        value={nameProdPT}
                                                        maxLength={134}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="nameTourPT"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />

                                            {
                                                valNamePt !== true
                                                    ?
                                                    <small className="mb-0" style={{ color: "red" }}>Nome do tour com no mínimo 4 caracteres</small>
                                                    :
                                                    <></>
                                            }

                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                            <Form.Label className="label-small d-flex justify-content-between">
                                                <span>Nome do tour (Inglês)</span>
                                                <span style={{ fontWeight: 400 }}>
                                                    {`${nameProdEN.length} caracteres`}
                                                </span>
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="nameTourEN"
                                                rules={{ required: { value: true, message: 'Por favor, informe o nome do produto' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text"
                                                        aria-invalid={errors?.nameTourEN ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {
                                                            setNameProdEN(e.target.value);
                                                            setValue('nameTourEN', e.target.value);
                                                        }}
                                                        value={nameProdEN}
                                                        disabled={
                                                            tourObject.isTranslate === 1 && editName === true
                                                        }
                                                        maxLength={134}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            {/* <ErrorMessage
                                                                errors={errors}
                                                                name="nameTourEN"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            /> */}

                                            {
                                                valNameEn !== true
                                                    ?
                                                    <small className="mb-0" style={{ color: "red" }}>Nome do tour com no mínimo 4 caracteres</small>
                                                    :
                                                    <></>
                                            }

                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                            <Form.Label className="label-small d-flex justify-content-between">
                                                <span>Nome do tour (Espanhol)</span>
                                                <span style={{ fontWeight: 400 }}>
                                                    {`${nameProdES.length} caracteres`}
                                                </span>
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="nameTourES"
                                                rules={{ required: { value: true, message: 'Por favor, informe o nome do produto' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text"
                                                        aria-invalid={errors?.nameTourES ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {
                                                            setNameProdES(e.target.value);
                                                            setValue('nameTourES', e.target.value)
                                                        }}
                                                        value={nameProdES}
                                                        disabled={
                                                            tourObject.isTranslate === 1 && editName === true
                                                        }
                                                        maxLength={134}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            {/* <ErrorMessage
                                                    errors={errors}
                                                    name="nameTourES"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                /> */}

                                            {
                                                valNameEs !== true
                                                    ?
                                                    <small className="mb-0" style={{ color: "red" }}>Nome do tour com no mínimo 4 caracteres</small>
                                                    :
                                                    <></>
                                            }

                                        </Form.Group>
                                    </Row>
                                </div>

                                <div className="pt-content-info pt-2">
                                    <Row>
                                        <Col md={6}>
                                            <div className="d-flex justify-content-between mt-3">
                                                <h5 className="mb-0 pt-subtitle">Tipos de Venda</h5>
                                                {/*{!isEdit &&
                                                    <div className="btn-edit" onClick={() => setEditSale(!editSale)}>
                                                        <FontAwesomeIcon
                                                            icon={["fal", "edit"]}
                                                            size="1x"
                                                            className="mr-2"
                                                        />
                                                        Editar
                                                    </div>
                                                }*/}
                                            </div>

                                            <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                <Form.Label className="label-small">
                                                    Tipo de Serviço
                                                </Form.Label>
                                                {
                                                    editSale === true
                                                        ?
                                                        <>
                                                            <Controller
                                                                control={control}
                                                                name="sellingType"
                                                                //rules={{ required: { value: true, message: 'Por favor, escolha um tipo de serviço' } }}
                                                                render={({ field }: any) => (
                                                                    <div className="input-select-custom">
                                                                        <Form.Select
                                                                            {...field}
                                                                            aria-invalid={errors?.sellingType ? "true" : ""}
                                                                            as="select"
                                                                            variant="standard"
                                                                            margin="normal"
                                                                            className="form-control"
                                                                            onChange={(e: any) => {
                                                                                setServiceType(e.target.value);
                                                                                setValue('sellingType', e.target.value)
                                                                            }}
                                                                            value={serviceType}
                                                                            disabled
                                                                            required
                                                                        >
                                                                            {/*<option value='' disabled>Selecione</option>*/}
                                                                            <option value='Regular' selected>Regular</option>
                                                                            {/*<option value='Privativo'>Privativo</option>*/}
                                                                        </Form.Select>
                                                                    </div>
                                                                )}
                                                            />
                                                        </>
                                                        :
                                                        <p className="mb-0">{serviceType}</p>
                                                }
                                            </Form.Group>
                                        </Col>
                                        {!isTicket &&
                                            <Col md={6}>
                                                <h5 className="mt-3 mb-0 pt-subtitle">Regras de Venda</h5>

                                                <Row>
                                                    <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                        <Form.Label className="label-small">
                                                            Mínimo de Pessoas
                                                        </Form.Label>
                                                        <div className="d-flex">
                                                            <Button onClick={() => less('min')}>-</Button>

                                                            <Controller
                                                                control={control}
                                                                name="minPeople"
                                                                //rules={{ required: { value: true, message: 'Por favor, informe o nome do produto' } }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        className="mx-2 text-center"
                                                                        style={{ maxWidth: "80px" }}
                                                                        type="text"
                                                                        aria-invalid={errors?.minPeople ? "true" : ""}
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        maxLength={3}
                                                                        value={numMin}
                                                                        onChange={(e: number) => { handleChangeNumMin(e) }}
                                                                        onBlur={(e: number) => { handleBlurNumMin(e) }}
                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                        required
                                                                        autoComplete='off'
                                                                    />
                                                                )}
                                                            />

                                                            <Button onClick={() => more('min')}>+</Button>
                                                        </div>
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="minPeople"
                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                        <Form.Label className="label-small">
                                                            Máximo de Pessoas <strong>(até 500)</strong>
                                                        </Form.Label>
                                                        <div className="d-flex">
                                                            <Button onClick={() => less('max')}>-</Button>

                                                            <Controller
                                                                control={control}
                                                                name="maxPeople"
                                                                //rules={{ required: { value: true, message: 'Por favor, informe o nome do produto' } }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        className="mx-2 text-center"
                                                                        style={{ maxWidth: "80px" }}
                                                                        type="text"
                                                                        aria-invalid={errors?.maxPeople ? "true" : ""}
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        maxLength={3}
                                                                        value={numMax}
                                                                        onChange={(e: number) => { handleChangeNumMax(e) }}
                                                                        onBlur={(e: number) => { handleBlurNumMax(e) }}
                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                        required
                                                                        autoComplete='off'
                                                                    />
                                                                )}
                                                            />

                                                            <Button onClick={() => more('max')}>+</Button>
                                                        </div>
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="maxPeople"
                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                            </Col>
                                        }
                                    </Row>
                                </div>

                                {
                                    (isEdit === false || tourSIG?.tour?.productSubCategory) && !isTicket &&
                                    <div className="pt-content-info pt-2" style={{ border: "none" }}>
                                        <Row>
                                            <h5 className="mt-3 mb-0 pt-subtitle">Subcategorias</h5>
                                            <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                <Form.Label className="label-small">
                                                    Escolha as suas subcategorias onde seu produto se encaixa
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="subcategory"
                                                    rules={{ required: { value: true, message: 'Por favor, informe as subcategorias' } }}
                                                    render={({ field }: any) => (
                                                        <GetProductsListSubCategoryMult
                                                            propsField={field}
                                                            propsLabel={''}
                                                            propsErrors={errors}
                                                            setValue={setValue}
                                                            infoEdit={tourSIG?.tour?.productSubCategory || []}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="subcategory"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        </Row>
                                    </div>
                                }

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    {
                                        isEdit !== true
                                            ?
                                            <Row className="mx-0 mt-5">
                                                <Button
                                                    variant="outline-primary"
                                                    className="form-button mr-3"
                                                    onClick={() => action('startProduct')}
                                                >
                                                    Voltar
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                >
                                                    Continuar
                                                </Button>
                                            </Row>
                                            :
                                            <Row className=" mx-0 mt-5">
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                //onClick={() => action('meetingPoint')}
                                                >
                                                    Salvar
                                                </Button>
                                            </Row>
                                    }
                                </div>
                            </Form>
                        </Card>
                    </Col >
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    )
});

export default DataTour;