import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
//import Button from "@restart/ui/esm/Button";

import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import SingleCalendar from "../../Calendar/SingleCalendar";

import { ModalBody } from "react-bootstrap";

function ModalImageVehicle(props: any) {
    const [modalShow, setModalShow] = useState(false);

    return (
        <div>
            <Modal
                {...props}
                className={
                    props.classModal
                        ? "modal-custom modalAuth " + props.classModal
                        : "modal-custom modalAuth"
                }
                show={modalShow}
                onHide={() => {
                    setModalShow(false);
                    props.closeAction ? props.closeAction() : <></>;
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <Modal.Header closeButton>
                        <div>
                            <h3>
                                {
                                    props.title ? props.title : ""
                                }
                            </h3>
                        </div>
                    </Modal.Header>
                    <hr />
                </>
                <ModalBody>
                    <div>
                        <h6>Informações do Veículo</h6>
                        <Row>
                            <Col>
                                <span>TIPO</span>
                                <b>{props.obj?.tipo}</b>
                            </Col>
                            <Col>
                                <span>MODELO</span>
                                <b>{props.obj?.modelo}</b>
                            </Col>
                            <Col>
                                <span>ANO</span>
                                <b>{props.obj?.ano}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>COR</span>
                                <b>{props.obj?.cor}</b>
                            </Col>
                            <Col>
                                <span>PLACA</span>
                                <b>{props.obj?.placa}</b>
                            </Col>
                            <Col>
                                <span>BLINDADO?</span>
                                <b>{props.obj?.blindado}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>PAX</span>
                                <b>{props.obj?.passageiros}</b>
                            </Col>
                            <Col>
                                <span>MALAS</span>
                                <b>{props.obj?.malas}</b>
                            </Col>
                            <Col>
                                <span>MALAS DE MAO</span>
                                <b>{props.obj?.malasMao}</b>
                            </Col>
                        </Row>
                        <Row className="mx-0">
                            <h6 className="px-0 py-2">Foto do Veículo:</h6>
                            <div className="bg-modal-image" style={{ backgroundImage: `url(${props.obj?.foto})` }}></div>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>

            <div className="d-flex justify-content-center">
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setModalShow(true)}
                >
                    <FontAwesomeIcon
                        icon={["fas", "camera-alt"]}
                        size="1x"
                        style={{ marginRight: "10px" }}
                    />
                </span>
            </div>
        </div>
    );
}

export default ModalImageVehicle;
