import React, { Key, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
//import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';

//import { useTranslation } from 'react-i18next';

//STYLES
import './PartnersTableUsers.scss';

import ModalDefault from '../../../../../components/Modal/ModalDefault/ModalDefault';
import FormNewUsersModal from '../../../../../components/Forms/FormNewUsersModal/FormNewUsersModal';

//import objectSuppliers from '../SuppliersTableUsers/locales/suppliersUsers.json';

export interface propModal {
  actionTableUser?: any,
  infoEdit?: any;
  isEdit?: any;
  disableAddContact?: any;
  usersEdit?: any;
};

const PartnersTableUsers: React.FC<propModal> = ({
  actionTableUser, infoEdit, isEdit = false, disableAddContact = false, usersEdit
}: propModal) => {
  const [userObject, setUserObject] = useState<any>([]);
  const [closeModal, setCloseModal] = useState<any>(false);

  function verificaId(arr: any, elem: any) {
    for (var i: any = 0; i < arr.length; i++) {
      if (arr[i].id === elem || arr[i].userId === elem) return true;
    }
    return false;
  }

  const handleAction = (objectUserModal: any) => {
    var aux: any = userObject;

    objectUserModal.forEach((element: any) => {
      if (!verificaId(userObject, element?.id)) {
        aux.push(element);
        //console.log(element)
      }
    });
    setUserObject([...aux]);
  };

  function removeUser(id: any) {
    var aux: any = [];
    var tempId: any;
    userObject.forEach((element: any) => {
      tempId = element?.id ? element.id : element?.userId;
      if (!(tempId === id)) {
        aux.push(element);
      }
    });

    setUserObject(aux);
  }

  useEffect(() => {
    //if (isEdit === false) {
    actionTableUser(userObject);
    //}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject])

  useEffect(() => {
    if (isEdit === true) {
      setUserObject(infoEdit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleSelectUsers(value: any, index: any) {
    console.log(userObject)
    userObject[index].nivelInfo = Number(value);
    userObject[index].nivel = Number(value);
    console.log(value, index, userObject);
  }

  
  useEffect(() => {
    for (let i = 0; i < userObject?.length; i++) {
        handleSelectUsers('29', i)
    }
  }, [userObject])

  return (
    <>
      <div className="table-add-default partners-users-table">
        <div className="table-container">
          <Table striped bordered>
            <thead>
              <tr>
                <th>Perfil</th>
                <th>Nome</th>
                <th>Sobrenome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>CPF</th>
                <th>Nível</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {userObject?.length > 0
                ? userObject.map((info: any, index: Key) => (
                  <tr key={index}>
                    <td>{info?.photoBase64}</td>
                    <td>{info?.name?.split(" ")[0] || info?.nome}</td>
                    <td>{info?.name?.split(" ")[1] || info?.lastName}</td>
                    <td>{info?.email}</td>
                    <td>{info?.telefone}</td>
                    <td>{info?.cpf}</td>
                    <td>{/* {info?.nivel} */}
                      {
                        usersEdit !== true
                          ?
                          info?.nivel === null || info?.nivel === undefined
                            ?
                            <select className='form-control' onChange={(e: any) => handleSelectUsers(e.target.value, index)}>
                              <option value="1" disabled>Selecione</option>
                              <option value="29" selected >Agente Não Comissionado</option>
                            </select>
                            :
                            <select className='form-control' onChange={(e: any) => handleSelectUsers(e.target.value, index)}>
                              <option value="0" disabled>Selecione</option>
                              <option value="29" selected>Agente Não Comissionado</option>
                            </select>
                          :
                          info?.nivel
                      }
                    </td>
                    {/* <td>{info?.userId}</td> */}
                    <td>{
                      <div className="center-table options-table">
                        <Dropdown drop="down" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
                          </Dropdown.Toggle>

                          {disableAddContact === false
                            ?
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="1" className="text-danger removeRow" onClick={() => removeUser(info?.id ? info?.id : info?.userId)}><FontAwesomeIcon icon={['fal', 'trash-alt']} />{'Remover'}</Dropdown.Item>
                            </Dropdown.Menu>
                            :
                            <></>
                          }
                        </Dropdown>
                      </div>
                    }</td>
                  </tr>
                ))
                : ""
              }
              {disableAddContact === false
                ?
                <p className="add-contact">
                  <td colSpan={12} className="text-center btn-add-contact">
                    <ModalDefault
                      title="Vincular usuário"
                      classModal="modal-pos-tax"
                      name={'+'}
                      class="btn-add"
                      closeModal={closeModal}
                      setCloseModal={setCloseModal}
                    >
                      <FormNewUsersModal
                        actionModalUser={handleAction}
                        setCloseModal={setCloseModal}
                      />
                    </ModalDefault>
                  </td>
                </p>
                :
                <></>
              }
            </tbody>
          </Table>


        </div>
      </div>
    </>
  );
}

export default PartnersTableUsers;