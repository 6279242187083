import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

export interface propPlus {
  propsField: any,
  propsLabel?: any
  propsErrors: any,
  setFilterSupplier?: any
  supplier?: any,
  setValue?: any,
  defaultId?: any,
  disabled?: boolean,
  isMulti?: boolean
};

const GetSuppliersFilterBlackout: React.FC<propPlus> = ({
  propsField, propsErrors, setFilterSupplier, supplier, setValue, defaultId = null, disabled = false, isMulti = false
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listAllSuppliers() {
      try {
        const { data } = await api.get(`/BillsPayable/GetSuppliers`, config);
        if (data.status !== 400) {
          setInfo(data.data);

          if (defaultId !== null) {
            let aux: any = [];

            if (isMulti) {
              defaultId.forEach((elem: any) => {
                aux.push(data.data.find((e: any) => parseInt(e.supplierId) === parseInt(elem)))
              });
              setValue('suppliers', aux.map((elem: any) => { return ({ 'compName': elem?.compName, 'supplierId': elem?.supplierId, "supplierIdentity": elem?.supplierIdentity }) }));
            } else {
              aux = data.data.find((e: any) => parseInt(e.supplierId) === parseInt(defaultId))
              if (aux) {
                setValue('supplier', { 'compName': aux?.compName, 'supplierId': aux?.supplierId, "supplierIdentity": aux?.supplierIdentity });
              }
            }
          }
          // console.log(data.data.find((elem: any) => elem.supplierId === 94))
        }
      } catch (error: any) {
        setTimeout(() => {
          listAllSuppliers();
        }, 5000)
      }
    }
    listAllSuppliers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (info !== null) {
    if (setFilterSupplier) {
      setFilterSupplier(propsField.value?.supplierId)
    }

    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
          <Select
            {...propsField}
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            //isDisabled={disabled}
            isMulti={isMulti}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={`Selecione`}
            options={Array.from(info)}
            getOptionValue={(option: any) => `${option.supplierId}`}
            getOptionLabel={(option: any) => `${option.compName}`}
          //value={info.find((a: any) => a.supplierId === propsField.value)}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetSuppliersFilterBlackout;