import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import { useForm } from "react-hook-form";

import './FilterBillsPay.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
//import GlobalAlert from "../../../Alert/GlobalAlert/GlobalAlert";

export interface propFilter {
    buscar: any,
    billsPay: any,
    error: any,
    clearActualValues: any;
    allowLoadFromFilter?: any;
}

const FilterBillsPay: React.FC<propFilter> = ({
    buscar, billsPay, error, clearActualValues, allowLoadFromFilter
}) => {

    const { t } = useTranslation();

    const [filterLocal, setFilterLocal] = useState<any>("");
    const [filterStatus, setFilterStatus] = useState<any>(null);
    const [filterType, setFilterType] = useState<any>(null);
    const [filterSupplier, setFilterSupplier] = useState<any>("");
    const [filterTypeDate, setFilterTypeDate] = useState<any>(3);
    const [filterDate, setFilterDate] = useState<any>([]);

    const [alert, setAlert] = useState<any>();
    const [checked, setChecked] = useState<boolean>(true);

    const {
        control,
        //handleSubmit,
        //register,
        //formState: { errors },
    } = useForm({});

    const handleFilterClick = (event: any) => {
        //console.log(filterLocal, filterStatus, filterType, filterSupplier, filterTypeDate, filterDate);
        buscar(filterLocal, filterStatus, filterType, filterSupplier, filterTypeDate, filterDate);
    };

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if(selectValue[i].id === "rowsPerPage"){
                selectValue[i].value = "10";
            }else{
                selectValue[i].value = "";
            }
        }

        setFilterLocal("");
        setFilterStatus(null);
        setFilterType(null);
        setFilterSupplier("")
        setFilterTypeDate(3);
        setFilterDate([]);
        buscar("", null, null, "", 3, []);
        clearActualValues();
    };



    const handleChangeLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterLocal(newValue);
    }

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatus(newValue);
    }

    const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterType(newValue);
    }

    const handleChangeSupplier = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterSupplier(newValue);
    }

    const handleChangeTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeDate(newValue);
    }

    const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterDate(newValue);
    }

    /*     useEffect(() => {
            if (suppliers.length === 0 || error === true) {
                setAlert(
                    <GlobalAlert alertType={"danger"}>
                        Não foi possível encontrar nenhum fornecedor.
                    </GlobalAlert>
                )
                setTimeout(() => setAlert(null), 2000);
            } else {
                setAlert(null);
            }
        }, [suppliers, error]) */

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Contas</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            {alert}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Localizador</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChangeLocal}
                                                    autoComplete="off"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Status do Pagamento</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatus(e)}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Pendente</option>
                                                            <option value="2">Conciliado</option>
                                                            <option value="3">Faturado</option>
                                                            <option value="4">Recebido</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Tipo</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeType(e)}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Operacional</option>
                                                            <option value="2">Não Operacional</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Fornecedor</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChangeSupplier}
                                                    autoComplete="off"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Tipo de Data</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeTypeDate(e)}
                                                        >
                                                            <option value="">Selecione</option>
                                                            <option value="3">Vencimento</option>
                                                            <option value="2">Operação</option>
                                                            <option value="1">Reserva</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{allowLoadFromFilter === true ? <p className="load"></p> : t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default FilterBillsPay;