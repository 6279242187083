import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

import api from '../../services/api';

/* import Form from 'react-bootstrap/Form'; */

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
};


const GetAfiliatesType: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`/DropDown/GetAfiliatesType`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);

  if (info !== null) {
    return (
      <>
        <div className={propsErrors.clientType !== undefined ? "endpoint-error" : "endpoint"}>
            <Form.Control
            {...propsField}
              as="select"
              required
              className="form-select"
            >
              <option value=''>Selecione</option>
              {info.length > 0 ? info.map((info: any) => (
                <option value={info.id}>{info.description}</option>
              )) : ''}
            </Form.Control>
          {/* <Select
            {...propsField}
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            aria-invalid={propsErrors?.clientType ? "true" : ""}
            label={propsLabel}
            as="select"
            placeholder={"Selecione"}
            variant="standard"
            margin="normal"
            options={Array.from(info)}
            getOptionLabel={(option: any) => `${option.description}`}
          /> */}
        </div>
      </>
    );
  } else {
    return <>

    </>
  }
}

export default GetAfiliatesType;