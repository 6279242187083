import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GetStatesFilter from "../../../../../components/C2Points/GetStatesFilter";
import Pagination from "../../../../../components/Pagination/Pagination";
import api from "../../../../../services/api";

export interface propFilter {
    setAffiliates: any; setModalAffiliatesShow: any; affiliates: any;
}

const ModalAssociateAffiliates: React.FC<propFilter> = ({
    setAffiliates, setModalAffiliatesShow, affiliates
}) => {
    const [t] = useTranslation();
    const [foundProducts, setFoundProducts] = useState<any>([]);
    const [actualName, setActualName] = useState<any>("");
    const [actualState, setActualState] = useState<any>("");
    const [actualStatus, setActualStatus] = useState<any>(0);
    const [listOfSelected, setListOfSelected] = useState<any>([]);

    // const [selectedCheck, setSelectedCheck] = useState<any>([]);
    const [itemToBeListed, setItemToBeListed] = useState<any>(affiliates === null ? [] : affiliates);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    function clearActualValues() {
        setActualName("");
        setActualState("");
        setActualStatus(0);

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if (selectValue[i].id === "rowsPerPage") {
                selectValue[i].value = "10";
            } else {
                selectValue[i].value = "";
            }
        }

        buscar('', '', 0);
    }

    useEffect(() => {
        var aux: any = [];
        itemToBeListed.forEach((elem: any) => {
            aux.push(elem.resellerId || elem.id);
        });
        setListOfSelected(aux);
    }, [itemToBeListed])

    const handleToLink = () => {
        setAffiliates(itemToBeListed);
        setModalAffiliatesShow(false);
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        async function filter() {
            try {
                const { data } = await api.post(`/Supplier/GetAffiliatesByFilterAsync`,
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "name": actualName,
                        "state": actualState,
                        "status": actualStatus,
                        "type": 0,
                        "country": 0,
                        "beginDate": '',
                        "endDate": '',
                        "orderByField": ""
                    }, config
                );
                if (data.status !== 400) {
                    setFoundProducts(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                }

            } catch (error) { }
        }
        filter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);


    async function buscar(name: any, state: any, status: any) {
        setFoundProducts([]);
        setActualName(name);
        setActualState(state);
        setActualStatus(status);
        setPageCount(1);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        try {
            const { data } = await api.post('/Supplier/GetAffiliatesByFilterAsync',
                {
                    "page": pageCount,
                    "rowsPerPage": rowsPerPage,
                    "name": name,
                    "state": state,
                    "status": parseInt(status),
                    "type": 0,
                    "country": 0,
                    "beginDate": '',
                    "endDate": '',
                    "orderByField": ""
                }
                , config
            );

            if (data.status !== 400) {
                setFoundProducts(data.data.rows);
                setTotalRows(data.data.rowsCount);
            }
        } catch (error: any) {

        }
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setActualName(newValue);
    };

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setActualStatus(newValue);
    };


    const {
        control,
        formState: { errors }
    } = useForm();

    const handleFilterClick = () => {
        buscar(actualName, actualState, actualStatus)
    }

    useEffect(() => {
        if (affiliates === null) {
            setItemToBeListed([]);
        }else{
            let aux: any = [];

            affiliates.forEach((element: any) => {
                aux.push({...element, id: element.resellerId });
            });

            setItemToBeListed(aux);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const selectRow: SelectRowProps<any> = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: listOfSelected,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect === true) {
                var add: any;
                add = [...itemToBeListed, row];
                setItemToBeListed(add);
            } else {
                var exclude: any = [];
                for (var i: any = 0; i < itemToBeListed.length; i++) {
                    if (itemToBeListed[i].id !== row.id) {
                        exclude.push(itemToBeListed[i]);
                    }
                }

                setItemToBeListed(exclude);
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect === true) {
                setItemToBeListed(rows);
            } else {
                setItemToBeListed([]);
            }
        }
    }

    const rowClasses = (row: any, rowIndex: any) => {
        if (row.statusDesc === "Em Análise") {
            return 'border-analise';
        } else if (row.statusDesc === "Ativo") {
            return 'border-ativo';
        } else {
            return 'border-inativo';
        }
    };

    const columns = [
        //{ dataField: "product_code", text: "" },
        { dataField: "nomeFantasia", text: "Nome do Afiliado" },
        { dataField: "identity", text: "Código do Afiliado" },
        { dataField: "statusDesc", text: "Status" },
    ];

    return (
        <>
            <div>
                <Card className="p-4">
                    <Form className="h-100">
                        <div className='d-flex flex-column justify-content-between h-100'>
                            <div>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>Nome do Afiliado/Revendedor</Form.Label>
                                        <Controller
                                            control={control}
                                            name="searchProduct"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className="input-select-custom">
                                                    <Form.Control
                                                        {...field}
                                                        label="product"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        value={actualName}
                                                        onChange={(e: any) => handleChangeName(e)}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>{t('partners.partnersfilter.state')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="state"
                                            render={({ field }: any) => (
                                                <GetStatesFilter propsField={field} propsLabel={t('accordionInfoCompany.state')} propsErrors={errors} setFilterState={setActualState} />
                                            )}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Status</Form.Label>
                                        <Controller
                                            control={control}
                                            name="searchSupplier"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className="input-select-custom endpoint">
                                                    <Form.Select
                                                        value={actualStatus}
                                                        onChange={(e: any) => handleChangeStatus(e)}
                                                    >
                                                        <option value="0">Selecione status</option>
                                                        <option value="1">Em Análise</option>
                                                        <option value="2">Ativo</option>
                                                        <option value="3">Inativo</option>
                                                    </Form.Select>
                                                </div>

                                            )}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row
                                    as={Col}
                                    md="12"
                                    className="mt-5 justify-content-md-end"
                                >
                                    <Col xs lg="12" className="d-flex justify-content-md-end">

                                        <button className="btn btn-default btn-outline-primary px-4 mr-3" type='button'
                                            onClick={clearActualValues}
                                        >
                                            LIMPAR
                                        </button>

                                        <button className="btn btn-default btn-primary" type='button'
                                            onClick={handleFilterClick}
                                        >
                                            PESQUISAR
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </Card>

            </div>

            {/* TABELA */}
            <div className="mt-4">
                <div className="table-default ModalAssociateAffiliatesFound-table">
                    <div className="table-container">
                        <div>
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                data={foundProducts}
                                columns={columns}
                                selectRow={selectRow}
                                rowClasses={rowClasses}
                            />
                            <Pagination
                                totalRows={totalRows}
                                pageCount={pageCount}
                                setPageCount={setPageCount}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                selectNumberRows={"yes"}
                            />
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <div className="btn btn-default btn-outline-primary px-4 mr-3" /* type='button' */ onClick={() => { setModalAffiliatesShow(false) }}>Cancelar</div>
                            <div className="btn btn-default btn-primary" onClick={handleToLink}>Associar</div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default ModalAssociateAffiliates;