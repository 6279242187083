import React from 'react';
import { Card } from "react-bootstrap";
import SessionPassengersTransfers from '../SessionPassengersTransfers/SessionPassengersTransfers';
import SessionTransferDestiny from '../SessionTransfer/SessionTransferDestiny';
import SessionTransferOrigin from '../SessionTransfer/SessionTransferOrigin';

//COMPONENTS


export interface propInfo {
    sessionInfo: any,
    sessionInfoOrigin?: any,
    sessionInfoDestiny?: any
};

const SessionInfo: React.FC<propInfo> = ({
    sessionInfo, sessionInfoOrigin, sessionInfoDestiny
}: propInfo) => {

    if (sessionInfoDestiny !== undefined) {
        return (
            <>
                <Card className="bg-white mt-4 p-4">
                    <div className="suppliers d-flex justify-content-between flex-column">
                        <h2 className="text-primary">{`Session - ${sessionInfoOrigin.localizador}`}</h2>
                    </div>
                    <div>
                        <SessionPassengersTransfers
                            items={sessionInfoOrigin}
                        />
                    </div>
                    <div className="pb-4">
                        <div className="d-flex align-items-center pb-4">
                            <b>IDA -</b>
                            <div className={
                                sessionInfoOrigin.status === "Alocação Parcial"
                                    ? "resumo bg-parcial py-1 px-3 mx-2"
                                    : sessionInfoOrigin.status === "Emitido"
                                        ? "resumo bg-emitido py-1 px-3"
                                        : sessionInfoOrigin.status === "Pendente"
                                            ? "resumo bg-pendente py-1 px-3"
                                            : sessionInfoOrigin.status === "Pendente Escala" || sessionInfoOrigin.status === "Pendente Aceite"
                                                ? "resumo bg-pendente-escala py-1 px-3 mx-2"
                                                : sessionInfoOrigin.status === "Confirmado"
                                                    ? "resumo bg-emitido py-1 px-3"
                                                    : ""
                            }>
                                {sessionInfoOrigin.status}
                            </div>
                        </div>
                        <div className="passengers-transfers py-0 w-100">
                            <div style={{ width: "15%" }}>
                                <p>DATA / HORA</p>
                                <b>{`${sessionInfoOrigin.dataOperacao.split("T")[0].split("-").reverse().join("/")} - ${sessionInfoOrigin.horaOperacao}`}</b>
                            </div>
                            <div style={{ width: "10%" }}>
                                <p>CIA / VOO</p>
                                <b>{sessionInfoOrigin.voo}</b>
                            </div>
                            <div style={{ width: "20%" }}>
                                <p>ORIGEM</p>
                                <b>{sessionInfoOrigin.origem}</b>
                            </div>
                            <div style={{ width: "20%" }}>
                                <p>DESTINO</p>
                                <b title={sessionInfoOrigin.destino}>{sessionInfoOrigin.destino}</b>
                            </div>
                            <div style={{ width: "20%" }}>
                                <p>FORNECEDOR</p>
                                <b>{sessionInfoOrigin.fornecedor}</b>
                            </div>
                            <div style={{ width: "15%" }}>
                                <p>TELEFONE</p>
                                <b>{sessionInfoOrigin.telefone ? sessionInfoOrigin.telefone : 0}</b>
                            </div>
                        </div>
                        <div className="recursos">
                            <SessionTransferOrigin
                                transfers={sessionInfoOrigin}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="d-flex align-items-center pb-4">
                            <b>VOLTA -</b>
                            <div className={
                                sessionInfoDestiny.status === "Alocação Parcial"
                                    ? "resumo bg-parcial py-1 px-3 mx-2"
                                    : sessionInfoDestiny.status === "Emitido"
                                        ? "resumo bg-emitido py-1 px-3"
                                        : sessionInfoDestiny.status === "Pendente"
                                            ? "resumo bg-pendente py-1 px-3"
                                            : sessionInfoDestiny.status === "Pendente Escala" || sessionInfoDestiny.status === "Pendente Aceite"
                                                ? "resumo bg-pendente-escala py-1 px-3 mx-2"
                                                : sessionInfoDestiny.status === "Confirmado"
                                                    ? "resumo bg-emitido py-1 px-3"
                                                    : ""
                            }>
                                {sessionInfoDestiny.status}
                            </div>
                        </div>
                        <div className="passengers-transfers py-0 w-100">
                            <div style={{ width: "15%" }}>
                                <p>DATA / HORA</p>
                                <b>{`${sessionInfoDestiny.dataOperacao.split("T")[0].split("-").reverse().join("/")} - ${sessionInfoDestiny.horaOperacao}`}</b>
                            </div>
                            <div style={{ width: "10%" }}>
                                <p>CIA / VOO</p>
                                <b>{sessionInfoDestiny.voo}</b>
                            </div>
                            <div style={{ width: "20%" }}>
                                <p>ORIGEM</p>
                                <b>{sessionInfoDestiny.origem}</b>
                            </div>
                            <div style={{ width: "20%" }}>
                                <p>DESTINO</p>
                                <b title={sessionInfoDestiny.destino}>{sessionInfoDestiny.destino}</b>
                            </div>
                            <div style={{ width: "20%" }}>
                                <p>FORNECEDOR</p>
                                <b>{sessionInfoDestiny.fornecedor}</b>
                            </div>
                            <div style={{ width: "15%" }}>
                                <p>TELEFONE</p>
                                <b>{sessionInfoDestiny.telefone ? sessionInfoDestiny.telefone : 0}</b>
                            </div>
                        </div>
                        <div className="recursos">
                            <SessionTransferDestiny
                                transfers={sessionInfoDestiny}
                            />
                        </div>
                    </div>

                </Card>
            </>
        );
    } else {
        return (
            <>
                <Card className="bg-white mt-4 p-4">
                    <div className="suppliers d-flex justify-content-between flex-column">
                        <h2 className="text-primary">{`Session - ${sessionInfoOrigin.localizador}`}</h2>
                    </div>
                    <div>
                        <SessionPassengersTransfers
                            items={sessionInfoOrigin}
                        />
                    </div>
                    <div className="pb-4">
                        <div className="d-flex align-items-center pb-4">
                            <b>IDA -</b>
                            <div className={
                                sessionInfoOrigin.status === "Alocação Parcial"
                                    ? "resumo bg-parcial py-1 px-3 mx-2"
                                    : sessionInfoOrigin.status === "Emitido"
                                        ? "resumo bg-emitido py-1 px-3"
                                        : sessionInfoOrigin.status === "Pendente"
                                            ? "resumo bg-pendente py-1 px-3"
                                            : sessionInfoOrigin.status === "Pendente Escala" || sessionInfoOrigin.status === "Pendente Aceite"
                                                ? "resumo bg-pendente-escala py-1 px-3 mx-2"
                                                : sessionInfoOrigin.status === "Confirmado"
                                                    ? "resumo bg-emitido py-1 px-3"
                                                    : ""
                            }>
                                {sessionInfoOrigin.status}
                            </div>
                        </div>
                        <div className="passengers-transfers py-0 w-100">
                            <div style={{ width: "15%" }}>
                                <p>DATA / HORA</p>
                                <b>{`${sessionInfoOrigin.dataOperacao.split("T")[0].split("-").reverse().join("/")} - ${sessionInfoOrigin.horaOperacao}`}</b>
                            </div>
                            <div style={{ width: "10%" }}>
                                <p>CIA / VOO</p>
                                <b>{sessionInfoOrigin.voo}</b>
                            </div>
                            <div style={{ width: "20%" }}>
                                <p>ORIGEM</p>
                                <b>{sessionInfoOrigin.origem}</b>
                            </div>
                            <div style={{ width: "20%" }}>
                                <p>DESTINO</p>
                                <b title={sessionInfoOrigin.destino}>{sessionInfoOrigin.destino}</b>
                            </div>
                            <div style={{ width: "20%" }}>
                                <p>FORNECEDOR</p>
                                <b>{sessionInfoOrigin.fornecedor}</b>
                            </div>
                            <div style={{ width: "15%" }}>
                                <p>TELEFONE</p>
                                <b>{sessionInfoOrigin.telefone ? sessionInfoOrigin.telefone : 0}</b>
                            </div>
                        </div>
                        <div className="recursos">
                            <SessionTransferOrigin
                                transfers={sessionInfoOrigin}
                            />
                        </div>
                    </div>
                </Card>
            </>
        )
    }

}

export default SessionInfo;