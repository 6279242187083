import React, { useCallback, useEffect, useState } from 'react';
//import { parseCookies } from 'nookies';

import api from '../../services/api';

//COMPONENTS
import { Button, Col, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
//import ChartSalesReport from './components/ChartSalesReport/ChartSalesReport';
import ReportBDBFilter from './components/ReportBDBFilter/ReportBDBFilter';
//import TableSalesReport from './components/TableSalesReport/TableSalesReport';

//Style
import './BDBReport.scss';
import Chart from 'react-google-charts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function BDBReport() {

  const [key, setKey] = useState<string>('Relatório BDB');
  const [firstChart, setFirstChart] = useState<any>(["", "Total de Vendas (R$)"]);
  const [refundChart, setRefundChart] = useState<any>(["", "Devoluções (R$)"]);
  const [secondChart, setSecondChart] = useState<any>(["Dias"/* , "Total de Vendas (R$)" */, "Cartão de Crédito (R$)", "Cartão de Débito (R$)", "PIX", "Dinheiro (R$)", "Cancelamentos (R$)"/* , "Devoluções" */]);
  const [pdfBase64, setPdfBase64] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [modalLog, setModalLog] = useState<any>(2);

  const color_primary: any = process.env.REACT_APP_COLOR_PRIMARY;

  const firstOpt = {
    colors: [color_primary],
  }

  /* const refundOpt = {
    colors: ["#A020F0"],
  } */

  const options = {
    axes: {
      y: {
        0: { label: "Vendas", }, // Top x-axis.
      },
    },
    colors: ["#3871CF", "#BA3B2E", "green"]
  };

  const buscar = useCallback(async (date: any, codigoCaixa: string, uidOperator: string) => {
    setLoading(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    let today: any = new Date().toLocaleDateString();
    let dataInicio: any;
    let dataFim: any;

    if (date?.length === 2) {
      dataInicio = date[0];
      dataFim = date[1];
    } else if (date?.length === 1) {
      dataInicio = date[0];
      dataFim = date[0];
    } else {
      dataInicio = today;
      dataFim = today;
    }

    try {
      const { data } = await api.post(`/BilheteriaCaixa/BilheteriaRelatorDiario`,
        {
          "dataInicio": dataInicio.split('/').reverse().join('-'),
          "dataFim": dataFim.split('/').reverse().join('-'),
          "uidOperador": uidOperator,
          "idCaixa": codigoCaixa,
          "filtro": 3  // 1 = ano, 2 = ano e  mes, 3 =  ano mes e dia| só aceita valores 1, 2 ou 3
        }, config
      );
      if (data?.log === 0) {
        setPdfBase64(data?.data?.pdfRelatorio);
        let setUpFirstChart: any = [];

        /* SEPARANDO AS DATAS QUE RECEBO DA API */
        let dates = data?.data?.dadosPagamentoManagementReport?.map((elem: any) => {
          return elem.dataLabel
        })

        let allDatesUnit: any = dates.filter((elem: any, index: number) => {
          return dates.indexOf(elem) === index;
        });
        /* [END] SEPARANDO AS DATAS QUE RECEBO DA API */

        /* CRIA O ARRAY QUE VAI MONTAR O PRIMEIRO CHART */
        let value: number = 0;

        allDatesUnit.forEach((elem: string) => {
          value = data?.data?.dadosPagamentoManagementReport?.filter((e: any) => e.dataLabel === elem && e.tipo !== "REFUND" && e.tipo !== "GRATUIDADE").map((e: any) => e.soma).reduce((acumulador: number, actual: number) => {
            return acumulador + actual;
          }, 0)
          setUpFirstChart?.push([`${elem.split('-').reverse().join('/')}`, value])
        });

        /* [END] CRIA O ARRAY QUE VAI MONTAR O PRIMEIRO CHART */

        setFirstChart([
          ["", "Total de Vendas (R$)"],
          ...setUpFirstChart
        ])

        let setUpSecondChart: any = [];
        let setUpRefundChart: any = [];

        allDatesUnit.forEach((elem: string) => {
          let filtered: any = data?.data?.dadosPagamentoManagementReport?.filter((e: any) => e.dataLabel === elem);
          /* let mapped: any = filtered.map((e: any) => e.soma);
          let reduced: any = mapped.reduce((acumulador: number, actual: number) => {
            return acumulador + actual;
          }); */
          let creditCard: any = filtered?.filter((e: any) => e.tipo === 'CREDITCARD')[0]?.soma || 0;
          let debitCard: any = filtered?.filter((e: any) => e.tipo === 'DEBITCARD')[0]?.soma || 0;
          let cash: any = filtered?.filter((e: any) => e.tipo === 'CASH')[0]?.soma || 0;
          let pix: any = filtered?.filter((e: any) => e.tipo === 'PIX')[0]?.soma || 0;
          let refund: any = filtered?.filter((e: any) => e.tipo === 'REFUND')[0]?.soma || 0;
          let giveaway: any = filtered?.filter((e: any) => e.tipo === 'GRATUIDADE')[0]?.soma || 0;

          setUpSecondChart?.push([`${elem.split('-').reverse().join('/')}`, /* reduced, */ creditCard, debitCard, pix, cash/* , refund */]);
          setUpRefundChart?.push([`${elem.split('-').reverse().join('/')}`, refund, giveaway]);
        });

        setSecondChart([
          ["Dias", /* "Total de Vendas (R$)", */ "Cartão de Crédito (R$)", "Cartão de Débito (R$)", "PIX (R$)", "Dinheiro (R$)"/* , "Devoluções" */],
          ...setUpSecondChart
        ])

        setRefundChart([
          ["", "Devoluções (R$)", "Gratuidade (R$)"],
          ...setUpRefundChart
        ])

        setLoading(false);
      } else {
        setModalLog(1);
        setModalMessage("Erro ao validar modelo");
        setModalShow(true);
      }
    } catch (e: any) {
      setModalLog(1);
      setModalMessage("<h4>Oops, algo deu erro!</h4><p>Por favor, entre em contato com o nosso suporte técnico!</p>")
      setModalShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    buscar(null, '', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportReport = () => {
    setModalLog(0);
    setModalShow(true);
    setModalMessage("Exportação do relatório realizado com sucesso!");

    const linkSource = `data:application/pdf;base64,${pdfBase64}`;
    const downloadLink = document.createElement("a");
    const fileName = "Relatório_Vendas_Bilheteria.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard sales-report">
          <Breadcrumb title={'Relatório BDB'} />
          <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>Relatório BDB</h2>
          </div>

          <ReportBDBFilter
            buscar={buscar}
          />

          <Tabs
            id="controlled-tab-example"
            defaultActiveKey="Relatório BDB"
            activeKey={key}
            onSelect={(k: any) => {
              setKey(k);
            }}
            className="mt-4"
          >
            <Tab eventKey="Relatório BDB" title="Relatório BDB">
              {/* <h4>{tabTitle}</h4> */}
              {/* GRAFICO 1 */}
              {
                loading === true
                  ?
                  <>
                    <div className="text-center text-primary my-2"><h4>Processando</h4></div>
                    <div className="animated-background row-loading"></div>
                  </>
                  :
                  <>
                    <Row className="d-flex justify-content-between">
                      <Col xs={12} className="d-flex justify-content-end mb-5">
                        <Button
                          variant="outline-info"
                          onClick={exportReport}
                        >
                          Exportar Relatório
                        </Button>
                      </Col>
                      <Col xs={6} className='first-chart'>
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="200px"
                          data={firstChart}
                          options={firstOpt}
                        />
                      </Col>
                      <Col xs={6} className='first-chart'>
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="200px"
                          data={refundChart}
                        //options={refundOpt}
                        />
                      </Col>
                    </Row>
                    {/* GRAFICO 2 */}
                    <div>
                      <Chart
                        chartType="Bar"
                        width="100%"
                        height="300px"
                        data={secondChart}
                        options={options}
                      />

                    </div>
                  </>
              }
            </Tab>
          </Tabs>
        </div>
        <Modal
          className="modalAuth modal-validation"
          show={modalShow}
          onHide={() => {
            setLoading(false);
            setModalMessage('');
            setModalShow(false);

            if (modalLog !== 0) {
              window.location.reload();
            }
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <>
            <Modal.Body
              className="modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column"
            >
              <div>
                {
                  modalLog === 0
                    ?
                    <FontAwesomeIcon
                      icon={["fal", "check-circle"]}
                      size="5x"
                      className='text-primary'
                    />
                    :
                    <FontAwesomeIcon
                      icon={["fal", "times-circle"]}
                      size="5x"
                      className='text-primary'
                    />
                }
              </div>

              <div dangerouslySetInnerHTML={{
                __html: modalMessage,
              }} />
              <div className="d-flex justify-content-center pt-3">
                <button
                  onClick={() => {
                    setModalMessage('');
                    setLoading(false);
                    setModalShow(false);

                    if (modalLog !== 0) {
                      window.location.reload();
                    }
                  }}
                  className="btn btn-primary mx-2 w-25"
                  style={{ color: '#FFF' }}
                >
                  Confirmar
                </button>
              </div>
            </Modal.Body>
          </>
        </Modal>
      </DesktopDefault>
    </>

  );
}

export default BDBReport;