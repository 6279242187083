import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import api from "../../services/api";
import DesktopDefault from "../../templates/DesktopDefault";
import ProductTour from "./ProductTour";
import { useAppDispatch } from '../../store/hooks';
import { resetTour } from "../../store/product";

function AddTour() {
    /* Controle Redux  */
    const dispatch = useAppDispatch();
    /* Controle Redux  */

    const [tourObject, setTourObject] = useState<any>({}); //objetão
    const [modalShow, setModalShow] = useState<any>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<any>("Carregando");

    const [, setTour] = useState<boolean>(false);
    const isEdit = false;

    //process.env.NODE_ENV === "development" -> ambiente de desenvolvimento
    const [management, setManagement] = useState(process.env.NODE_ENV === "development" ? 0 : 0);
    const [alreadyPassed, setAlreadyPassed] = useState(0);

    const handleNext = (newStep: any, newObject: any = {}) => {
        setTourObject({ ...tourObject, ...newObject })

        switch (newStep) {
            case 'startProduct':
                setManagement(0);
                break;
            case 'dataTour':
                if (alreadyPassed === 0) {
                    setAlreadyPassed(1);
                }
                setManagement(1);
                break;
            case 'operation':
                if (alreadyPassed === 1) {
                    setAlreadyPassed(2);
                }
                setManagement(2);
                break;
            case 'summaryAndDetails':
                if (alreadyPassed === 2) {
                    setAlreadyPassed(3);
                }
                setManagement(3);
                break;
            case 'descriptionsAndHigh':
                if (alreadyPassed === 3) {
                    setAlreadyPassed(4);
                }
                setManagement(4);
                break;
            case 'photo':
                if (alreadyPassed === 4) {
                    setAlreadyPassed(5);
                }
                setManagement(5);
                break;
            case 'modalities':
                if (alreadyPassed === 5) {
                    setAlreadyPassed(6);
                }
                setManagement(6);
                break;
            case 'tariffs':
                if (alreadyPassed === 6) {
                    setAlreadyPassed(7);
                }
                setManagement(7);
                break;
            case 'location':
                if (alreadyPassed === 7) {
                    setAlreadyPassed(8);
                }
                setManagement(8);
                break;
            case 'instructions':
                if (alreadyPassed === 8) {
                    setAlreadyPassed(9);
                }
                setManagement(9);
                break;
            // case 'contact':
            //     setManagement(10);
            //     break;
            /* case 'rules':
                setManagement(11);
                break; */
            case 'seo':
                if (alreadyPassed === 9) {
                    setAlreadyPassed(10);
                }
                setManagement(10);
                break;
            case 'finish':
                setAlreadyPassed(11);
                setModalShow(true)
                setTimeout(() => {
                    insertTour();
                }, 1000);
                break;
            default:
                break;
        }
    };

    const insertTour = async () => {
        //setModalShow(true)
        const productTour = localStorage.getItem('productTour') || '{}';

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const { data } = await api.post('Tour/TryinsertNewProductTour', productTour, config);
            if (data.statusCode !== 400) {
                setModalLog(data.data.log);
                setModalMessage(data.data.texto);

                if (data.data.log === 0) {
                    dispatch(resetTour(data));
                }
            } else {
                setModalLog(1);
                setModalMessage(data?.data?.texto || data?.errorMessage);
            }

        } catch (error: any) {
            if (error?.response?.status === 400) {

            }
        }

        localStorage.removeItem('productTour');
    }

    const receiveObject = async (data: any) => {
        setModalShow(true);
        setTourObject({ ...tourObject, ...data });
        setTour(true);
    }

    const reload = () => {
        if (modalLog === 0) {
            window.location.href = "/products/list-products";
        }
    };

    useEffect(() => {
        dispatch(resetTour(''));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={"Produtos / Adicionar Tour"} />

                    <div className="d-flex align-items-center my-3">
                        <span
                            className="back m-0"
                            onClick={() => {
                                window.location.href = "/products/list-products";
                            }}
                        >
                            <FontAwesomeIcon
                                icon={["fal", "angle-double-left"]}
                                style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                            />
                            Voltar
                        </span>
                    </div>


                    <Row className="add-product">
                        {/* MENU */}
                        {
                            management !== 0
                                ?
                                <Col md={3} lg={2}>
                                    <div className='card menu border-0'>
                                        <div className="list-menu">
                                            <div className="sub-list-menu">
                                                <span className="list-menu-item item-title">
                                                    Informações Básicas
                                                </span>
                                                <span className={management === 1 ? "list-menu-item item-action active" : management > 1 || 1 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 1 <= alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (1 <= alreadyPassed) { handleNext('dataTour', {}) } }}>
                                                        <p>Dados do tour</p>
                                                        {management > 1 || 1 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                        {/*ICONE DE CHECKED PARA QUANDO PASSAR PRO PROXIMO STEP <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]}/> */}
                                                    </div>
                                                </span>
                                                <span className={management === 2 ? "list-menu-item item-action active" : management > 2 || 2 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 2 <= alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (2 <= alreadyPassed) { handleNext('operation', {}) } }}>
                                                        <p>Operação</p>
                                                        {management > 2 || 2 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 3 ? "list-menu-item item-action active" : management > 3 || 3 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 3 <= alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (3 <= alreadyPassed) { handleNext('summaryAndDetails', {}) } }}>
                                                        <p>Resumo e Detalhes</p>
                                                        {management > 3 || 3 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 4 ? "list-menu-item item-action active" : management > 4 || 4 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 4 <= alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (4 <= alreadyPassed) { handleNext('descriptionsAndHigh', {}) } }}>
                                                        <p>Descrição e Destaques</p>
                                                        {management > 4 || 4 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>

                                                <span className={management === 5 ? "list-menu-item item-action active" : management > 5 || 5 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 5 <= alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (5 <= alreadyPassed) { handleNext('photo', {}) } }}>
                                                        <p>Fotos</p>
                                                        {management > 5 || 5 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                            </div>

                                            <div className="sub-list-menu">
                                                <span className="list-menu-item item-title">
                                                    Opções e Preços
                                                </span>
                                                <span className={management === 6 ? "list-menu-item item-action active" : management > 6 || 6 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 6 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (6 <= alreadyPassed) { handleNext('modalities', {}) } }}>
                                                        <p>Modalidades</p>
                                                        {management > 6 || 6 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 7 ? "list-menu-item item-action active" : management > 7 || 7 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 7 <= alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (7 <= alreadyPassed) { handleNext('tariffs', {}) } }}>
                                                        <p>Tarifários</p>
                                                        {management > 7 || 7 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                            </div>

                                            <div className="sub-list-menu">
                                                <span className="list-menu-item item-title">
                                                    Conteúdo
                                                </span>
                                                <span className={management === 8 ? "list-menu-item item-action active" : management > 8 || 8 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 8 <= alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (8 <= alreadyPassed) { handleNext('location', {}) } }}>
                                                        <p>Localização</p>
                                                        {management > 8 || 8 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 9 ? "list-menu-item item-action active" : management > 9 || 9 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 9 <= alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (9 <= alreadyPassed) { handleNext('instructions', {}) } }}>
                                                        <p>Instruções </p>
                                                        {management > 9 || 9 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                {/* <span className={management === 10 ? "list-menu-item item-action active" : management > 10 ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 1 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (1 < alreadyPassed) { handleNext('dataTour', {}) } }}>
                                                        <p>Contato do Fornecedor </p>
                                                        {management > 10 ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span> */}
                                                {/* <span className={management === 11 ? "list-menu-item item-action active" : management > 11 ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 1 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (1 < alreadyPassed) { handleNext('dataTour', {}) } }}>
                                                        <p>Regras de cancelamento </p>
                                                        {management > 11 ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span> */}
                                                <span className={management === 10 ? "list-menu-item item-action active" : management > 10 || 10 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 10 <= alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (10 <= alreadyPassed) { handleNext('seo', {}) } }}>
                                                        <p>SEO </p>
                                                        {management > 10 || 10 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                </Col>
                                :
                                <></>
                        }

                        <ProductTour receiveObject={receiveObject} tourObject={tourObject} handleNext={handleNext} management={management} isEdit={isEdit} />
                    </Row>
                </div>
                <Modal
                    className="modalAuth"
                    show={modalShow}
                    onHide={() => {
                        if (modalLog === 0) {
                            reload();
                        }
                        setModalLog(null);
                        setModalMessage("Carregando");
                        setModalShow(false);
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body
                            className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                            style={{ height: "600px" }}
                        >
                            {modalLog === null ? (
                                <>
                                    <div className="loading-modal">
                                        <div className="load"></div>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    {modalLog === 0 ? (
                                        <FontAwesomeIcon
                                            icon={["fal", "check-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={["fal", "times-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                    )}
                                </div>
                            )}
                            <div>{modalMessage}</div>
                            <div className="d-flex justify-content-center pt-3">
                                <button
                                    onClick={() => {
                                        reload();
                                        setModalLog(null);
                                        setModalMessage("Carregando");
                                        setModalShow(false);
                                    }}
                                    className="btn btn-primary mx-2 w-25"
                                >
                                    Confirmar
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                </Modal>
            </DesktopDefault>
        </>
    )
}

export default AddTour;