import React, { useState } from 'react';
import api from '../../../../services/api';

import { Form, Modal, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Pagination from '../../../../components/Pagination/Pagination';

export interface propInfo {
    info: any;
    loading: any;
    exportReport: any;
    printReport: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
}

const TableSalesReport: React.FC<propInfo> = ({
    info, loading, exportReport, printReport, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage
}) => {
    /* dados e funções da coluna  */

    function addReservation(cell: any, row: any) {
        return (
            <div className='d-flex align-items-center flex-column' style={{ maxWidth: '100px' }}>
                <div className="d-flex">
                    {cell}
                </div>
                <div className={
                    row.status === "Confirmado"
                        ?
                        "bg-ativo"
                        :
                        row.status === "Cancelado"
                            ?
                            "bg-inativo"
                            :
                            "bg-analise"
                }>
                    {row.status}
                </div>

            </div>
        );
    }

    function addDate(cell: any, row: any) {
        return (
            <div>
                <div className="d-flex">
                    {cell?.split(' ')[0]}
                </div>
            </div>
        );
    }

    function addClient(cell: any, row: any) {
        return (
            <div>
                {textReduce(cell)}
                <div>
                    <span style={{ fontSize: "12px" }}>
                        {
                            row.promotor !== null
                            ?
                            textReduce(`Promotor: ${row.promotor}`)
                            :
                            ""
                        }
                    </span>
                </div>
            </div>
        );
    }

    function addMethod(cell: any, row: any) {
        return (
            <div>
                <div className="d-flex" style={{ fontSize: "14px" }}>
                    {textReduce(cell)}
                </div>
                {row.bandeira !== null && row.bandeira !== ""
                    ?
                    <div>
                        <span style={{ fontSize: "12px" }}>
                            {textReduce(`Bandeira: ${row.bandeira}`)}
                        </span>
                    </div>
                    :
                    <></>
                }
            </div>
        );
    }

    function addPayment(cell: any, row: any) {
        return (
            <div className='d-flex align-items-center flex-column' style={{ maxWidth: '100px' }}>
                <div className="d-flex" style={{ fontSize: "14px" }}>
                    {parseInt(cell).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
                <div className={
                    row.totalDue < 0
                        ? "bg-inativo"
                        : "bg-ativo"
                }>
                    {row.pagamento}
                </div>
            </div>
        );
    }

    function addService(cell: any, row: any) {
        return (
            <div>
                <div className="d-flex" style={{ fontSize: "14px" }}>
                    {cell?.split(' ')[0]}
                </div>
                <div className=''>
                    <span style={{ fontSize: "12px" }}>
                        Serviço:
                        <strong>
                            {row.type}
                        </strong>
                    </span>
                </div>
            </div>
        );
    }

    function textReduce(cell: any) {
        return (
            <>
                <OverlayTrigger
                    placement="top-start"
                    overlay={
                        <Tooltip id="tooltip">{cell}</Tooltip>
                    }>
                    <div className="d-flex" style={{ fontSize: "14px" }}>
                        <span className='text-short'>
                            <span className="text-nowrap-custom">
                                {cell}
                            </span>
                        </span>
                    </div>
                </OverlayTrigger>
            </>
        )
    }

    const columns = [
        { dataField: "reserva", text: "Reserva", formatter: addReservation },
        { dataField: "dataReserva", text: "Data Reserva", formatter: addDate },
        { dataField: "dataServico", text: "Serviço", formatter: addService },
        { dataField: "productNome", text: "Produto", formatter: textReduce },
        { dataField: "nomeClient", text: "Cliente", formatter: addClient },
        { dataField: "agentName", text: "Agente", formatter: addClient },
        { dataField: "formapag", text: "Forma de Pagamento", formatter: addMethod },
        { dataField: "valor", text: "Pagamento", formatter: addPayment }
    ];

    /*--------------------------------------------------*/

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "vendas", text: "Vendas" }];

    const notFoundProducts = [{ vendas: "Nenhuma venda encontrada" }];
    /* [END] In case of empty table*/

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando relatórios", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */
    /*[END] dados da coluna */

    const customTotal = (from: any, to: any, size: any) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );

    const rowClasses = (row: any, rowIndex: any) => {
        return 'border-normal'
    };

    if ((info !== null && info?.length > 0) && loading === false) {
        return (
            <>
                <div className="table-default sales-report-table">
                    <div className="table-container">
                        <div>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                                    <div>
                                        <button className="btn btn-bills" onClick={printReport}>
                                            <FontAwesomeIcon
                                                file-invoice
                                                icon={["fal", "print"]}
                                                size="1x"
                                                style={{ marginRight: "5px" }}
                                            />
                                            Imprimir
                                        </button>
                                    </div>
                                    <div>
                                        <button className="btn btn-bills" onClick={exportReport}>
                                            <FontAwesomeIcon
                                                icon={["fal", "share"]}
                                                size="1x"
                                                style={{ marginRight: "5px" }}
                                            />
                                            Exportar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={info}
                            columns={columns}
                            striped={true}
                            rowClasses={rowClasses}
                        />

                        <Pagination
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            selectNumberRows="yes"
                        />
                    </div>
                </div>
            </>
        );
    } else if (info === null || loading === true) {
        return (
            <>
                <div className="table-default bills-receive-table loading not-found">
                    <div className="table-container">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                                <div>
                                    <button className="btn btn-bills" onClick={printReport} disabled>
                                        <FontAwesomeIcon
                                            file-invoice
                                            icon={["fal", "print"]}
                                            size="1x"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Imprimir
                                    </button>
                                </div>
                                <div>
                                    <button className="btn btn-bills">
                                        <FontAwesomeIcon
                                            icon={["fal", "share"]}
                                            size="1x"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Exportar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                            rowClasses={rowClasses}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default bills-receive-table not-found">
                    <div className="table-container">
                        <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                            <div>
                                <button className="btn btn-bills" onClick={printReport} disabled={true}>
                                    <FontAwesomeIcon
                                        file-invoice
                                        icon={["fal", "print"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                    />
                                    Imprimir
                                </button>
                            </div>
                            <div>
                                <button className="btn btn-bills" disabled={true}>
                                    <FontAwesomeIcon
                                        icon={["fal", "share"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                    />
                                    Exportar
                                </button>
                            </div>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default TableSalesReport;