import React, { Key, useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Container, Row, Col } from "react-bootstrap";

import "../../../../assets/sass/modal.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import api from '../../../../services/api';

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import "./FormBillReceivableLog.scss";

export interface propModal {
  //action: any,
  localizador: any
};

const FormBillReceivableLog: React.FC<propModal> = ({
  //action,
  localizador
}: propModal, props: any) => {
  const { t } = useTranslation();

  const [controlObject, setControlObject] = useState<any>([]);

  function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if(cell && cell != '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
    
  }

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listAccountingAccounts() {
      try {
        const { data } = await api.get(`/BillsReceivable/GetOrderLog/${localizador}`, config);
        if (data.status !== 400) {
          const dados = data.data;

          setControlObject(dados);
          /* console.log(data); */
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountingAccounts();
  }, []);

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <h5 className="contact-title mb-5">Log de Alterações</h5>
            <div className="table-default bill-receive-log-table">
              <div className="table-container">
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Texto</th>
                      <th>Usuario</th>
                      <th>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {controlObject.length > 0
                      ? controlObject.map((info: any, index: Key) => (
                        <tr key={index}>
                          <td>{info.texto}</td>
                          <td>{info.usuario}</td>
                          <td>{convertDate(info.data)}</td>
                        </tr>
                      ))
                      : <tr style={{ border: "none" }}><td colSpan={6} className="text-center">Nenhum item encontrado</td></tr>}
                  </tbody>
                </Table>
              </div>
            </div>
          </Row>
        </Container>
      </Modal.Body>

      {/* <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          <Button
            type="button"
            className="form-button"
            onClick={onSubmitForm}
          >
            <FontAwesomeIcon
              icon={["fal", "save"]}
              size="1x"
              style={{ marginRight: "5px" }}
            /> Fechar 
          </Button>
        </Row>
      </Modal.Footer> */}


    </>
  );
}

export default FormBillReceivableLog;
