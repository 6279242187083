import React, { Key, useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
};

const GetAccountStatus: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);
  /*   const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
   */
  /* const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    }else{
      setIsMenuOpen(false);
    }    
  } */

  useEffect(() => {
    // const token = localStorage.getItem('GroupId') || '{}';
    // const config = {
    //   headers: { 'Authorization': `Bearer ${token}` },
    // };
    async function listAccountTypes() {
      try {
        //const { data } = await api.get(`/DropDown/GetAccountingAccounts`, config);
        //if (data.status !== 400) {
        const dados = [
          { id: 1, descricao: 'Ativo' },
          { id: 2, descricao: 'Inativo' },
        ];
        setInfo(dados)
        /* console.log(data); */
        //}
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountTypes();
  }, []);

  if (info !== null) {
    return (
      <>
        <div className={propsErrors.status !== undefined ? "endpoint-error" : "endpoint"}>
          <Form.Select
            {...propsField}
            aria-invalid={propsErrors?.status ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"

          >
            <option value=''>Selecione</option>
            {info.length > 0 ? info.map((info: any, index: Key) => (
              <option key={index} value={info.id}>{info.descricao}</option>
            )) : ''}
          </Form.Select>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetAccountStatus;