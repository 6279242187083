import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableSessionBillingBillsPay.scss";

export interface propTable {
  bills: any;
}

const TableSessionBillingBillsPay: React.FC<propTable> = ({
  bills
}: propTable) => {
  const { t } = useTranslation();

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any, row: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  function addStatus(cell: any, row: any) {
    return (
      cell !== 1 ?
        <FontAwesomeIcon
          icon={["fal", "check"]}
          size="1x"
          style={{ marginRight: "1px" }}
        />
      : <></>
    );
  }

  /* ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  const columns = [
    { dataField: "descricao", text: "Descrição", sort: true },
    { dataField: "contaContabil", text: "Categoria", sort: true },
    { dataField: "fornecedor", text: "Fornecedor", sort: true },
    { dataField: "totalPax", text: "QTD", sort: true },
    { dataField: "totalAdult", text: "ADT", sort: true },
    { dataField: "totalChild", text: "CHD", sort: true },
    { dataField: "totalInfant", text: "INF", sort: true },
    {
      dataField: "valor",
      text: "Valor",
      sort: true,
      formatter: convertValue,
    },
    {
      dataField: "vencimento",
      text: "Vencimento*",
      sort: true,
      formatter: convertDate,
    },
    { dataField: "observacao", text: "Observação*", sort: true },
  ];

  /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

  const notFoundProducts = [{ clientes: "Nenhuma session encontrada" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === 2) {
      return 'border-analise';
    } else if (row.statusPagamento === 5) {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  if (bills !== null) {
    return (
      <>
        <div className="table-default session-b-bills-pay-table">
          <div className="table-container">
            <div>
              <h4 className="p-0 m-0">Contas a Pagar</h4>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={bills}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default session-b-bills-pay-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableSessionBillingBillsPay;
