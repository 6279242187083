/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit'
// import TourList from '../pages/Sales/components/TourList/TourList';

const productSlice = createSlice({
  name: 'product',
  initialState: {
    tour: {
      modalidade: []
    }
  },
  reducers: {

    addStartProduct: (state: any, action) => {
      const startTourObject = {
        "productName": action.payload.productName,
        "sellingType": action.payload.sellingType,
        "operationType": action.payload.operationType,
        "isTranslate": action.payload.isTranslate,
      }

      state.tour = { ...state.tour, ...startTourObject };
    },

    addDataTour: (state: any, action) => {
      let finalObject: any = action.payload.isTicket ? { //Ticket
        "productName": action.payload.productName,
        "sellingType": action.payload.sellingType,
        "ticketSellingType": action.payload.ticketSellingType,

      } : { //Tour
        "productName": action.payload.productName,
        "minNumPeople": action.payload.minNumPeople,
        "maxNumPeople": action.payload.maxNumPeople,
        "sellingType": action.payload.sellingType,
        "productSubCategory": action.payload.productSubCategory
      }

      const dataTourObject = {
        ...finalObject
      }

      state.tour = { ...state.tour, ...dataTourObject };
    },

    addOperation: (state: any, action) => {

      let finalObject: any = action.payload.isTicket ? { //Ticket
        "ticketSupplyType": parseInt(action.payload.ticketSupplyType || 0),
        "supplierId": action.payload.supplierId,
        "supplierIdentity": action.payload.supplierIdentity,

        "integrationPurchaseType": action.payload.integrationPurchaseType,
        "integrationAccessType": action.payload.integrationAccessType,
      } : { //Tour
        "operationType": action.payload.operationType,

        //Tipo de Operação Própria
        "resourcesId": action.payload.resourcesId,
        "ourVehicleType": action.payload.ourVehicleType,
        "sessionGroupId": action.payload.sessionGroupId,

        //Tipo de Operação Terceiros
        "supplierId": action.payload.supplierId,
        "supplierIdentity": action.payload.supplierIdentity,

        "integrationPurchaseType": action.payload.integrationPurchaseType,
        "integrationAccessType": action.payload.integrationAccessType,
      }

      const operationObject = {
        ...finalObject,
      }

      state.tour = { ...state.tour, ...operationObject };
    },

    addSummaryAndDetails: (state: any, action) => {
      let finalObject: any = action.payload.isTicket ? {
        "ticketInfo": action.payload.ticketInfo
      } : {
        "summary": action.payload.summary,
        "details": action.payload.details
      }

      state.tour = { ...state.tour, ...finalObject };
    },

    addPhoto: (state: any, action) => {

      const photoObject = {
        "productImages": action.payload,
      }

      state.tour = { ...state.tour, ...photoObject };
    },

    ticketAddPhoto: (state: any, action) => {
      const photoObject = {
        "ticketImages": action.payload,
      }

      state.tour = { ...state.tour, ...photoObject };
    },


    addDescriptionAndHigh: (state: any, action) => {
      let finalObject: any = action.payload.isTicket ? {
        "ticketDescription": action.payload.ticketDescription
      } : {
        "description": action.payload.description,
        "highLight": action.payload.highLight, //OBRIGATÓRIO Nome do Produto deve estar entre  | 10 e 100 caracteres
      }

      state.tour = { ...state.tour, ...finalObject };
    },

    addModality: (state: any, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes 

      let ticketOnly: any = action.payload.isTicket === true ? {
        "validadeTicket": action.payload.validadeTicket,
        "tariffAgeTextAdult": action.payload?.tariffAgeTextAdult,
        "tariffAgeTextChild": action.payload?.tariffAgeTextChild,
        "tariffAgeTextInfant": action.payload?.tariffAgeTextInfant,
        "tariffAgeTextStudent": action.payload?.tariffAgeTextStudent,
        "tariffAgeTextElder": action.payload?.tariffAgeTextElder,
        "tariffAgeTextGlobal": action.payload?.tariffAgeTextGlobal,
      } : {
        "tariffAgeTextAdult": action.payload?.tariffAgeTextAdult,
        "tariffAgeTextChild": action.payload?.tariffAgeTextChild,
        "tariffAgeTextInfant": action.payload?.tariffAgeTextInfant,
        "tariffAgeTextGlobal": action.payload?.tariffAgeTextGlobal,
      }

      let labelContainer = state.tour.sellingType === 1 ? {
        "tariffGlobalAgeText": action.payload.tariffGlobalAgeText,
      } : {
        "tariffAdultAgeText": action.payload.tariffAdultAgeText,
        "tariffChildAgeText": action.payload.tariffChildAgeText,
        "tariffInfantAgeText": action.payload.tariffInfantAgeText,
        "tariffEldersAgeText": action.payload.tariffEldersAgeText,
        "tariffStudentAgeText": action.payload.tariffStudentAgeText,
      }


      let embarque = parseInt(action.payload.embarkingType) === 0 ? {
        "meetingPoint": action.payload.meetingPoint
      } : {
        "pickupList": parseInt(action.payload.pickupList),
        "pickupDescription": action.payload.pickupDescription,
      };

      let refundRules = parseInt(action.payload.cancelationType) === 3
        ? {
          "refundRules": action.payload.refundRules
        }
        : {
          "refundRules": [
            {
              "refundRules": parseInt(action.payload.cancelationType)
            }
          ]
        };

      let references = action.payload.referenceCodePT || action.payload.referenceCodeEN || action.payload.referenceCodeES
        ? {
          "referenceCode": [
            {
              "text": action.payload.referenceCodePT,
              "lang": 1
            },
            {
              "text": action.payload.referenceCodeEN,
              "lang": 2
            },
            {
              "text": action.payload.referenceCodeES,
              "lang": 3
            }
          ]
        }
        : {};

      let notIncluded = action.payload.itemsNotIncluded.length > 0
        ? {
          "itemsNotIncluded": action.payload.itemsNotIncluded,
        }
        : {};

      let stock = action.payload.stock === 'yes'
        ? {
          "dailyStockTotal": parseInt(action.payload.dailyStockTotal)
        }
        : {
          "dailyStockTotal": 1
        };

      const modalityObject = {
        ...ticketOnly,
        ...labelContainer,
        ...embarque,
        ...refundRules,
        ...references,
        ...notIncluded,
        ...stock,

        "tariff": [],

        "modalityName": [
          {
            "text": action.payload.modalityNamePT,
            "lang": 1
          },
          {
            "text": action.payload.modalityNameEN,
            "lang": 2
          },
          {
            "text": action.payload.modalityNameES,
            "lang": 3
          }
        ],
        "modalityDescription": [
          {
            "text": 'Sem informação',
            "lang": 1
          },
          {
            "text": 'Sem informação',
            "lang": 2
          },
          {
            "text": 'Sem informação',
            "lang": 3
          }
        ],
        "daysOfWeek": {
          "seg": action.payload.seg,
          "ter": action.payload.ter,
          "qua": action.payload.qua,
          "qui": action.payload.qui,
          "sex": action.payload.sex,
          "sab": action.payload.sab,
          "dom": action.payload.dom
        },
        "durationHours": parseInt(action.payload.activityDuration.split(':')[0]),
        "durationMinutes": parseInt(action.payload.activityDuration.split(':')[1]),
        //"idComissionRange": parseInt(action.payload.idComissionRange),
        //"buyingAdvance": action.payload.buyingAdvance,
        "embarkingType": parseInt(action.payload.embarkingType),
        "itemsIncluded": action.payload.itemsIncluded,

        "offerGuidingDetails": true/* action.payload.offerGuidingDetails */,
        "guidingDetails": action.payload.guidingDetails,
        "freeSale": action.payload.freeSale,

        "havePurchaseIntegration": action.payload.havePurchaseIntegration,
        "haveAccessIntegration": action.payload.haveAccessIntegration,
        "haveSigSigIntegration": action.payload.sigSigIntegration.productIntegrationCode === undefined ? 2 : 1,

        "sigSigIntegration": action.payload.sigSigIntegration,
        "productPurchaseIntegration": action.payload.productPurchaseIntegration,
        "accessIntegration": action.payload.accessIntegration,

        "productModalityRange": action.payload.productModalityRange
        //"dailyStockTotal": parseInt(action.payload.dailyStockTotal) || 1
      }

      state.tour.modalidade = [...(state.tour.modalidade || []), modalityObject];
    },

    removeModality: (state: any, action) => {
      let data: any = [];

      for (let i: number = 0; i < state.tour.modalidade.length; i++) {
        if (i !== action.payload) {
          data.push(state.tour.modalidade[i]);
        }
      }

      state.tour.modalidade = data;
    },

    duplicateModality: (state: any, action) => {
      let aux: any = tryToCatchDuplicate(state.tour, action.payload);

      let newIndex = (aux.modalidade.length - 1)

      state.tour.modalidade = [...(state.tour.modalidade || []), aux.modalidade[newIndex]]
    },

    editModality: (state: any, action) => {
      let ticketOnly: any = action.payload.isTicket === true ? {
        "validadeTicket": action.payload.validadeTicket
      } : {

      }

      let embarque = parseInt(action.payload.embarkingType) === 0 ? {
        "meetingPoint": action.payload.meetingPoint
      } : {
        "pickupList": action.payload.pickupList,
        "pickupDescription": action.payload.pickupDescription,
      };

      /* let labelContainer = state.tour.sellingType === 1 ? {
        "tariffGlobalAgeText": action.payload.tariffGlobalAgeText,
      } : {
        "tariffAdultAgeText": action.payload.tariffAdultAgeText,
        "tariffChildAgeText": action.payload.tariffChildAgeText,
        "tariffInfantAgeText": action.payload.tariffInfantAgeText,
        "tariffEldersAgeText": action.payload.tariffEldersAgeText,
        "tariffStudentAgeText": action.payload.tariffStudentAgeText,
      } */

      let refundRules = parseInt(action.payload.cancelationType) === 3 ? {
        "refundRules": action.payload.refundRules
      } : {
        "refundRules": [
          {
            "refundRules": parseInt(action.payload.cancelationType)
          }
        ]
      };

      let references = action.payload.referenceCodePT || action.payload.referenceCodeEN || action.payload.referenceCodeES
        ? {
          "referenceCode": [
            {
              "text": action.payload.referenceCodePT,
              "lang": 1
            },
            {
              "text": action.payload.referenceCodeEN,
              "lang": 2
            },
            {
              "text": action.payload.referenceCodeES,
              "lang": 3
            }
          ]
        }
        : {};

      let notIncluded = action.payload.itemsNotIncluded.length > 0
        ? {
          "itemsNotIncluded": action.payload.itemsNotIncluded,
        }
        : {};

      let stock = action.payload.stock === 'yes'
        ? {
          "dailyStockTotal": parseInt(action.payload.dailyStockTotal)
        }
        : {
          "dailyStockTotal": 1
        };


      let modCode = action.payload.keepModCode === true
        ? {
          "idModalidade": action.payload.idModalidade,
          "productModCode": action.payload.productModCode,
          "uniqueModCode": action.payload.uniqueModCode,
        }
        : {};

      const modalityObject = {
        ...ticketOnly,
        ...embarque,
        /* ...labelContainer, */
        ...refundRules,
        ...references,
        ...notIncluded,
        ...stock,
        ...modCode,

        "tariff": action.payload.tariff, //state.tour.modalidade[action.payload.indexToEdit].tariff,

        "modalityName": [
          {
            "text": action.payload.modalityNamePT,
            "lang": 1
          },
          {
            "text": action.payload.modalityNameEN,
            "lang": 2
          },
          {
            "text": action.payload.modalityNameES,
            "lang": 3
          }
        ],
        "modalityDescription": [
          {
            "text": 'Sem informação',
            "lang": 1
          },
          {
            "text": 'Sem informação',
            "lang": 2
          },
          {
            "text": 'Sem informação',
            "lang": 3
          }
        ],
        "daysOfWeek": {
          "seg": action.payload.seg,
          "ter": action.payload.ter,
          "qua": action.payload.qua,
          "qui": action.payload.qui,
          "sex": action.payload.sex,
          "sab": action.payload.sab,
          "dom": action.payload.dom
        },
        "durationHours": action.payload.activityDuration.split(':')[0],
        "durationMinutes": action.payload.activityDuration.split(':')[1],
        //"idComissionRange": parseInt(action.payload.idComissionRange),
        //"buyingAdvance": action.payload.buyingAdvance,
        "embarkingType": parseInt(action.payload.embarkingType),
        "itemsIncluded": action.payload.itemsIncluded,

        "offerGuidingDetails": true /* action.payload.offerGuidingDetails */,
        "guidingDetails": action.payload.guidingDetails,
        "freeSale": action.payload.freeSale,

        "havePurchaseIntegration": action.payload.havePurchaseIntegration,
        "haveAccessIntegration": action.payload.haveAccessIntegration,
        "haveSigSigIntegration": action.payload.sigSigIntegration.productIntegrationCode === undefined ? 2 : 1,

        "sigSigIntegration": action.payload.sigSigIntegration,
        "productPurchaseIntegration": action.payload.productPurchaseIntegration,
        "accessIntegration": action.payload.accessIntegration,

        /* "havePurchaseIntegration": action.payload.productPurchaseIntegration.length === 0 ? 2 : 1,
        "haveAccessIntegration": action.payload.accessIntegration.length === 0 ? 2 : 1,
        "haveSigSigIntegration": action.payload.sigSigIntegration.productIntegrationCode === undefined ? 2 : 1,

        "sigSigIntegration": action.payload.sigSigIntegration,
        "productPurchaseIntegration": action.payload.productPurchaseIntegration,
        "accessIntegration": action.payload.accessIntegration, */

        "productModalityRange": action.payload.productModalityRange,

        "tariffAgeTextAdult": action.payload?.tariffAgeTextAdult,
        "tariffAgeTextChild": action.payload?.tariffAgeTextChild,
        "tariffAgeTextInfant": action.payload?.tariffAgeTextInfant,
        "tariffAgeTextGlobal": action.payload?.tariffAgeTextGlobal,
        //"dailyStockTotal": parseInt(action.payload.dailyStockTotal)
      }

      state.tour.modalidade[action.payload.indexToEdit] = modalityObject;
    },

    ticketAddModality: (state: any, action) => {
      let embarque = parseInt(action.payload.embarkingType) === 2 ? {
        "meetingPointLocation": action.payload.meetingPoint
      } : parseInt(action.payload.embarkingType) === 1 ? {
        "pickupLocationId": parseInt(action.payload.pickupList),
        "pickupDescription": action.payload.pickupDescription,
      } : {

      };

      /* let labelContainer = state.tour.sellingType === 1 ? {
        "tariffGlobalAgeText": action.payload.tariffGlobalAgeText,
      } : {
        "tariffAdultAgeText": action.payload.tariffAdultAgeText,
        "tariffChildAgeText": action.payload.tariffChildAgeText,
        "tariffInfantAgeText": action.payload.tariffInfantAgeText,
        "tariffEldersAgeText": action.payload.tariffEldersAgeText,
        "tariffStudentAgeText": action.payload.tariffStudentAgeText,
      } */

      let refundRules = parseInt(action.payload.cancelationType) === 3
        ? {
          "ticketRefundPolicy": action.payload.refundRules
        }
        : {
          "ticketRefundPolicy": [
            {
              "refundRule": parseInt(action.payload.cancelationType)
            }
          ]
        };

      let references = action.payload.referenceCodePT || action.payload.referenceCodeEN || action.payload.referenceCodeES
        ? {
          "referenceCode": [
            {
              "text": action.payload.referenceCodePT,
              "lang": 1
            },
            {
              "text": action.payload.referenceCodeEN,
              "lang": 2
            },
            {
              "text": action.payload.referenceCodeES,
              "lang": 3
            }
          ]
        }
        : {};

      let notIncluded = action.payload.itemsNotIncluded?.length > 0
        ? {
          "itensNotIncluded": action.payload.itemsNotIncluded,
        }
        : {};
      const modalityObject = {
        ...embarque,
        /* ...labelContainer, */
        ...refundRules,
        ...references,
        ...notIncluded,

        "ticketTariff": [],

        "modalityName": [ //done
          {
            "text": action.payload.modalityNamePT,
            "lang": 1
          },
          {
            "text": action.payload.modalityNameEN,
            "lang": 2
          },
          {
            "text": action.payload.modalityNameES,
            "lang": 3
          }
        ],
        "modalityDescription": [ //done
          {
            "text": 'Sem informação',
            "lang": 1
          },
          {
            "text": 'Sem informação',
            "lang": 2
          },
          {
            "text": 'Sem informação',
            "lang": 3
          }
        ],

        "seg": action.payload.seg, //done
        "ter": action.payload.ter, //done
        "qua": action.payload.qua, //done
        "qui": action.payload.qui, //done
        "sex": action.payload.sex, //done
        "sab": action.payload.sab, //done
        "dom": action.payload.dom, //done
        "eventDurationDays": 0, //IMPLEMENTAR ?
        "eventDurationHours": parseInt(action.payload.activityDuration.split(':')[0]), //done
        "eventDurationMinutes": parseInt(action.payload.activityDuration.split(':')[1]), //done
        //"idComissionRange": parseInt(action.payload.idComissionRange), //done
        //"buyingAdvance": action.payload.buyingAdvance, //done
        //"buyingAdvanceHours": action.payload.buyingAdvance.replace('h', '').replace('min', ''), //done
        "ticketWithdrawl": action.payload.ticketWithdrawl, //done
        "offerTransportation": action.payload.offerTransportation, //done
        "wheelchairAccessibility": action.payload.wheelchairAccessibility, //done
        "lineDetail": action.payload.lineDetail, //done
        "sellingType": state.tour.sellingType,
        "validationType": action.payload.validationType, //done
        "validationDefinedDate": action.payload.validationType === 4 ? action.payload.validationDefinedDate : undefined, //done
        "validationPeriodMinutes": action.payload.validationPeriodMinutes, //done
        "validationPeriodHours": action.payload.validationPeriodHours, //done
        "validationPeriodDays": action.payload.validationPeriodDays, //done
        "fixedGlobalTariff": state.tour.sellingType, //Privativo vai ser 1 (fixa), regular vai ser 2 (variável)
        "ticketSellingType": action.payload.ticketSellingType, //done
        "peopleGroupLimit": action.payload.ticketSellingType === 2 ? action.payload.peopleGroupLimit : 0, //done
        "integrationSigCode": "string", //IMPLEMENTAR
        "embarkingType": parseInt(action.payload.embarkingType),
        "itensIncluded": action.payload.itemsIncluded,

        "offerGuidings": 1,
        "guidingDetails": action.payload.guidingDetails,
        "freeSale": action.payload.freeSale,

        "withDrawlAddress": action.payload.withDrawlAddress,

        "havePurchaseIntegration": action.payload.productPurchaseIntegration.length === 0 ? 2 : 1,
        "haveAccessIntegration": action.payload.accessIntegration.length === 0 ? 2 : 1,
        "haveSigSigIntegration": action.payload.sigSigIntegration.productIntegrationCode === undefined ? 2 : 1,

        "sigSigIntegration": action.payload.sigSigIntegration,
        "productPurchaseIntegration": action.payload.productPurchaseIntegration,
        "accessIntegration": action.payload.accessIntegration,

        "productModalityRange": action.payload.productModalityRange,

        "tariffAgeTextAdult": action.payload?.tariffAgeTextAdult,
        "tariffAgeTextChild": action.payload?.tariffAgeTextChild,
        "tariffAgeTextInfant": action.payload?.tariffAgeTextInfant,
        "tariffAgeTextStudent": action.payload?.tariffAgeTextStudent,
        "tariffAgeTextElder": action.payload?.tariffAgeTextElder,
        "tariffAgeTextGlobal": action.payload?.tariffAgeTextGlobal,
        //"dailyStockTotal": parseInt(action.payload.dailyStockTotal) || 1
      }

      state.tour.modalidade = [...(state.tour.modalidade || []), modalityObject];
    },

    ticketDuplicateModality: (state: any, action) => {
      let aux: any = tryToCatchDuplicateTicket(state.tour, action.payload);

      state.tour.modalidade = [...(state.tour.modalidade || []), aux.modalidade[0]]
    },

    ticketEditModality: (state: any, action) => {
      let ticketOnly: any = action.payload.isTicket === true ? {
        "validadeTicket": action.payload.validadeTicket
      } : {

      }

      /* let labelContainer = state.tour.sellingType === 1 ? {
        "tariffGlobalAgeText": action.payload.tariffGlobalAgeText,
      } : {
        "tariffAdultAgeText": action.payload.tariffAdultAgeText,
        "tariffChildAgeText": action.payload.tariffChildAgeText,
        "tariffInfantAgeText": action.payload.tariffInfantAgeText,
        "tariffEldersAgeText": action.payload.tariffEldersAgeText,
        "tariffStudentAgeText": action.payload.tariffStudentAgeText,
      } */

      let embarque = parseInt(action.payload.embarkingType) === 2 ? {
        "meetingPointLocation": action.payload.meetingPoint
      } : parseInt(action.payload.embarkingType) === 1 ? {
        "pickupLocationId": parseInt(action.payload.pickupList),
        "pickupDescription": action.payload.pickupDescription,
      } : {
        
      };

      let refundRules = parseInt(action.payload.cancelationType) === 3
        ? {
          "ticketRefundPolicy": action.payload.refundRules
        }
        : {
          "ticketRefundPolicy": [
            {
              "refundRule": parseInt(action.payload.cancelationType)
            }
          ]
        };

      let references = action.payload.referenceCodePT || action.payload.referenceCodeEN || action.payload.referenceCodeES
        ? {
          "referenceCode": [
            {
              "text": action.payload.referenceCodePT,
              "lang": 1
            },
            {
              "text": action.payload.referenceCodeEN,
              "lang": 2
            },
            {
              "text": action.payload.referenceCodeES,
              "lang": 3
            }
          ]
        }
        : {};

      let notIncluded = action.payload.itemsNotIncluded.length > 0
        ? {
          "itensNotIncluded": action.payload.itemsNotIncluded,
        }
        : {};

      let modCode = action.payload.keepModCode === true
        ? {
          "id": action.payload.id,
          "ticketModCode": action.payload.ticketModCode,
          "ticketModUniqueCode": action.payload.ticketModUniqueCode,
        }
        : {};

      const modalityObject = {
        ...ticketOnly,
        ...embarque,
       /*  ...labelContainer, */
        ...refundRules,
        ...references,
        ...notIncluded,
        ...modCode,

        "ticketTariff": action.payload.ticketTariff,//state.tour.modalidade[action.payload.indexToEdit].ticketTariff,

        "modalityName": [
          {
            "text": action.payload.modalityNamePT,
            "lang": 1
          },
          {
            "text": action.payload.modalityNameEN,
            "lang": 2
          },
          {
            "text": action.payload.modalityNameES,
            "lang": 3
          }
        ],
        "modalityDescription": [
          {
            "text": 'Sem informação',
            "lang": 1
          },
          {
            "text": 'Sem informação',
            "lang": 2
          },
          {
            "text": 'Sem informação',
            "lang": 3
          }
        ],
        "seg": action.payload.seg, //done
        "ter": action.payload.ter, //done
        "qua": action.payload.qua, //done
        "qui": action.payload.qui, //done
        "sex": action.payload.sex, //done
        "sab": action.payload.sab, //done
        "dom": action.payload.dom, //done
        "eventDurationDays": 0, //IMPLEMENTAR ?
        "eventDurationHours": parseInt(action.payload.activityDuration.split(':')[0]), //done
        "eventDurationMinutes": parseInt(action.payload.activityDuration.split(':')[1]), //done
        //"idComissionRange": parseInt(action.payload.idComissionRange), //done
        //"buyingAdvance": action.payload.buyingAdvance, //done
        //"buyingAdvanceHours": action.payload.buyingAdvance.replace('h', '').replace('min', ''), //done
        "ticketWithdrawl": action.payload.ticketWithdrawl, //done
        "offerTransportation": action.payload.offerTransportation, //done
        "wheelchairAccessibility": action.payload.wheelchairAccessibility, //done
        "lineDetail": action.payload.lineDetail, //done
        "sellingType": state.tour.sellingType,
        "validationType": action.payload.validationType, //done
        "validationDefinedDate": action.payload.validationType === 4 ? action.payload.validationDefinedDate : undefined, //done
        "validationPeriodMinutes": action.payload.validationPeriodMinutes, //done
        "validationPeriodHours": action.payload.validationPeriodHours, //done
        "validationPeriodDays": action.payload.validationPeriodDays, //done
        "fixedGlobalTariff": state.tour.sellingType, //Privativo vai ser 1 (fixa), regular vai ser 2 (variável)
        "ticketSellingType": action.payload.ticketSellingType, //done
        "peopleGroupLimit": action.payload.ticketSellingType === 2 ? action.payload.peopleGroupLimit : 0, //done
        "integrationSigCode": "string", //IMPLEMENTAR
        "embarkingType": parseInt(action.payload.embarkingType),
        "itensIncluded": action.payload.itemsIncluded,

        "offerGuidings": 1,
        "guidingDetails": action.payload.guidingDetails,
        "freeSale": action.payload.freeSale,

        "withDrawlAddress": action.payload.withDrawlAddress,

        "havePurchaseIntegration": action.payload.productPurchaseIntegration.length === 0 ? 2 : 1,
        "haveAccessIntegration": action.payload.accessIntegration.length === 0 ? 2 : 1,
        "haveSigSigIntegration": action.payload.sigSigIntegration.productIntegrationCode === undefined ? 2 : 1,

        "sigSigIntegration": action.payload.sigSigIntegration,
        "productPurchaseIntegration": action.payload.productPurchaseIntegration,
        "accessIntegration": action.payload.accessIntegration,

        "productModalityRange": action.payload.productModalityRange,

        "tariffAgeTextAdult": action.payload?.tariffAgeTextAdult,
        "tariffAgeTextChild": action.payload?.tariffAgeTextChild,
        "tariffAgeTextInfant": action.payload?.tariffAgeTextInfant,
        "tariffAgeTextStudent": action.payload?.tariffAgeTextStudent,
        "tariffAgeTextElder": action.payload?.tariffAgeTextElder,
        "tariffAgeTextGlobal": action.payload?.tariffAgeTextGlobal,
        //"dailyStockTotal": parseInt(action.payload.dailyStockTotal)
      }

      state.tour.modalidade[action.payload.indexToEdit] = modalityObject;
    },

    addTariffs: (state: any, action) => {
      let tourTicketDiferences: any = action.payload.isTicket ? {
        "ticketHourlyPromotions": action.payload.ticketHourlyPromotions,
        "ticketDailyTariff": action.payload.ticketDailyTariff,
        "ticketHasManyActivities": 2,
      } : {
        "promoHourTariff": action.payload.promoHourTariff,
        "daysTariff": action.payload.daysTariff,
      }

      let freeField = state.tour.sellingType === 1 ? {
        "tariffGlobalAgeText": state.tour.modalidade[action.payload.modality.value].tariffGlobalAgeText,
      } : {
        "tariffAdultAgeText": state.tour.modalidade[action.payload.modality.value].tariffAdultAgeText,
        "tariffChildAgeText": state.tour.modalidade[action.payload.modality.value].tariffChildAgeText,
        "tariffInfantAgeText": state.tour.modalidade[action.payload.modality.value].tariffInfantAgeText,
        "tariffEldersAgeText": state.tour.modalidade[action.payload.modality.value].tariffEldersAgeText,
        "tariffStudentAgeText": state.tour.modalidade[action.payload.modality.value].tariffStudentAgeText,
      }

      let tarCode = action.payload.tarCode ? {
        "tarCode": action.payload.tarCode,
      } : {}

      const tariffObject = {
        ...tourTicketDiferences,
        ...freeField,
        ...tarCode,

        "tariffName": action.payload.tariffName,
        "tariffStart": action.payload.beginDate + 'T00:00:00',
        "tariffEnd": action.payload.endDate + 'T00:00:00',
        "commission": action.payload.commission,
        "ticketTariffCode": action.payload?.ticketTariffCode,
        "ticketModCode": action.payload?.ticketModCode,
        "ticketCode": action.payload?.ticketCode,
        "bloqueado": false,
        "canaisTarifario": action.payload.canaisTarifario,
        "activityStartTariff": action.payload.activityStartTariff,
      }

      if (action.payload.isTicket) {
        state.tour.modalidade[action.payload.modality.value].ticketTariff.push(tariffObject);
      } else {
        state.tour.modalidade[action.payload.modality.value].tariff.push(tariffObject);
      }

    },
    
    editTariff: (state: any, action) => {
      let tourTicketDiferences: any = action.payload.isTicket ? {
        "ticketHourlyPromotions": action.payload.ticketHourlyPromotions,
        "ticketDailyTariff": action.payload.ticketDailyTariff,
        "ticketHasManyActivities": 2,
      } : {
        "promoHourTariff": action.payload.promoHourTariff,
        "daysTariff": action.payload.daysTariff,
      }

      let freeField = state.tour.sellingType === 1 ? {
        "tariffGlobalAgeText": state.tour.modalidade[action.payload.modalityIndexToEdit].tariffGlobalAgeText,
      } : {
        "tariffAdultAgeText": state.tour.modalidade[action.payload.modalityIndexToEdit].tariffAdultAgeText,
        "TariffChildAgeText": state.tour.modalidade[action.payload.modalityIndexToEdit].tariffChildAgeText,
        "TariffInfantAgeText": state.tour.modalidade[action.payload.modalityIndexToEdit].tariffInfantAgeText,
      }

      let tarCode = {...action.payload.tarCode}

      const tariffObject = {
        ...tourTicketDiferences,
        ...freeField,
        ...tarCode,

        "tariffName": action.payload.tariffName,
        "tariffStart": action.payload.beginDate + 'T00:00:00',
        "tariffEnd": action.payload.endDate + 'T00:00:00',
        "commission": action.payload.commission,
        "ticketTariffCode": action.payload.tarCode.ticketTariffCode,
        "ticketModCode": action.payload.ticketModCode,
        "ticketCode": action.payload.ticketCode,
        "bloqueado": action.payload.bloqueado,
        "canaisTarifario": action.payload.canaisTarifario,
        "activityStartTariff": action.payload.activityStartTariff,
      }

      if (action.payload.isTicket) {
        state.tour.modalidade[action.payload.modalityIndexToEdit].ticketTariff[action.payload.tariffIndexToEdit] = tariffObject;
      } else {
        state.tour.modalidade[action.payload.modalityIndexToEdit].tariff[action.payload.tariffIndexToEdit] = tariffObject;
      }
    },

    removeTariff: (state: any, action) => {
      let data: any = [];

      for (let i: number = 0; i < state.tour.modalidade[action.payload.modalityIndexToRemove].tariff.length; i++) {
        if (i !== action.payload.tariffIndexToRemove) {
          data.push(state.tour.modalidade[action.payload.modalityIndexToRemove].tariff[i]);
        }
      }

      state.tour.modalidade[action.payload.modalityIndexToRemove].tariff = data;

    },

    ticketRemoveTariff: (state: any, action) => {
      let data: any = [];

      for (let i: number = 0; i < state.tour.modalidade[action.payload.modalityIndexToRemove].ticketTariff.length; i++) {
        if (i !== action.payload.tariffIndexToRemove) {
          data.push(state.tour.modalidade[action.payload.modalityIndexToRemove].ticketTariff[i]);
        }
      }

      state.tour.modalidade[action.payload.modalityIndexToRemove].ticketTariff = data;
    },

    duplicateTariff: (state: any, action) => {
      if (action.payload.isTicket) {
        state.tour.modalidade[action.payload.index].ticketTariff.push(state.tour.modalidade[action.payload.index].ticketTariff[action.payload.indexTariff]);
      } else {
        state.tour.modalidade[action.payload.index].tariff.push(state.tour.modalidade[action.payload.index].tariff[action.payload.indexTariff]);
      }
    },

    changeTariffStatus: (state: any, action) => {
      if (action.payload.isTicket) {
        state.tour.modalidade[action.payload.indexModality].ticketTariff[action.payload.indexTariff].bloqueado = action.payload.status;
      } else {
        state.tour.modalidade[action.payload.indexModality].tariff[action.payload.indexTariff].bloqueado = action.payload.status;
      }
    },

    addLocation: (state: any, action) => {
      let ticketOnly: any = action.payload.isTicket === true ? {
        "eventAddress": action.payload.eventAddress,
        "ticketLocationLat": action.payload.ticketLocationLat,
        "ticketLocationLong": action.payload.ticketLocationLong
      } : {
        "tourLocationLat": action.payload.tourLocationLat,
        "tourLocationLong": action.payload.tourLocationLong,
      }

      const locationObject = {
        ...ticketOnly,

        "eventLocation": action.payload.eventLocation,
      }

      state.tour = { ...state.tour, ...locationObject };
    },

    addInsctructions: (state: any, action) => {
      let ticketOnly: any = action.payload.isTicket === true ? {
        "voucherType": action.payload.voucherType,
        "documentIsNeeded": action.payload.travellerInfoIsNeed,
        "voucherInstructions": action.payload.voucherInstructions,
      } : {
        "travellerInfoIsNeed": action.payload.travellerInfoIsNeed,
        "voucherInstruction": action.payload.voucherInstruction,
      }

      const insctructionsObject = {
        ...ticketOnly,

        "travellerInfoForAll": action.payload.travellerInfoForAll,
        "fullName": action.payload.fullName,
        "sex": action.payload.sex,
        "nationality": action.payload.nationality,
        "birthday": action.payload.birthday,
        "numDocument": action.payload.numDocument,
        "cellPhone": action.payload.cellPhone,
      }

      state.tour = { ...state.tour, ...insctructionsObject };
    },

    addContact: (state: any, action) => {
      const contactObject = {
        "supplierEmail": action.payload.supplierEmail,
        "supplierPhone": action.payload.supplierPhone,
        "supplierMerchant": action.payload.supplierMerchant,
        "supplierWpp": action.payload.supplierWpp,
        "supplierResidential": action.payload.supplierResidential,
      }

      state.tour = { ...state.tour, ...contactObject };
    },

    addSEO: (state: any, action) => {

      const seoObject = action.payload.isTicket === true ?
        {
          "metaTitle": action.payload.metaTitle,
          "metaDescription": action.payload.metaDescription,
          "palavraChave": action.payload.palavraChave,
          "slug": action.payload.slug
        } : {
          "metaTitleBR": action.payload.metaTitleBR,
          "metaTitleEN": action.payload.metaTitleEN,
          "metaTitleES": action.payload.metaTitleES,
          "metaDescriptionBR": action.payload.metaDescriptionBR,
          "metaDescriptionEN": action.payload.metaDescriptionEN,
          "metaDescriptionES": action.payload.metaDescriptionES,
          "palavraChaveBR": action.payload.palavraChaveBR,
          "palavraChaveEN": action.payload.palavraChaveEN,
          "palavraChaveES": action.payload.palavraChaveES,
          "slug": [
            {
              "text": action.payload.palavraChaveBR,
              "lang": 1
            },
            {
              "text": action.payload.palavraChaveEN,
              "lang": 2
            },
            {
              "text": action.payload.palavraChaveES,
              "lang": 3
            }
          ]
        }

      state.tour = { ...state.tour, ...seoObject };
      saveToLocalStorage(state.tour);
    },

    listProduct: (state: any, action) => {
      state.tour = action.payload;
      saveToLocalStorage(state.tour);
    },

    resetTour: (state: any, action) => {
      state.tour = {
        tour: {
          modalidade: []
        }
      };

      saveToLocalStorage(state.tour);
    },
  }
})

export const {
  addStartProduct,
  addDataTour,
  addOperation,
  addSummaryAndDetails,
  addPhoto,
  ticketAddPhoto,
  addDescriptionAndHigh,
  addModality,
  removeModality,
  duplicateModality,
  editModality,
  ticketAddModality,
  ticketDuplicateModality,
  ticketEditModality,
  addTariffs,
  editTariff,
  removeTariff,
  ticketRemoveTariff,
  duplicateTariff,
  changeTariffStatus,
  addLocation,
  addInsctructions,
  addContact,
  addSEO,
  listProduct,
  resetTour
} = productSlice.actions

function saveToLocalStorage(state: any) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("productTour", serialisedState);

  } catch (e) {
    console.warn(e);
  }
}

function tryToCatchDuplicate(state: any, info: any) {
  let auxObj: any = null;
  
  try {
    let aux: any = JSON.stringify(state);
    
    
    auxObj = JSON.parse(aux);

    let newIndex = (auxObj.modalidade.length + 1 )

    let newMod = auxObj.modalidade[info.index];

    newMod.modalityName = [
      {
        "text": auxObj.modalidade[info.index].modalityName[0].text + " (Cópia)",
        "lang": 1
      },
      {
        "text": auxObj.modalidade[info.index].modalityName[1].text + " (Copy)",
        "lang": 2
      },
      {
        "text": auxObj.modalidade[info.index].modalityName[2].text + " (Copiar)",
        "lang": 3
      }
    ]

    if (info.isEdit) {
      delete newMod.productModCode;
      delete newMod.uniqueModCode;
      delete newMod.idModalidade;

      for (let i: number = 0; i < newMod.productModalityRange.length; i++) {
        delete newMod.productModalityRange[i].productCode;
        delete newMod.productModalityRange[i].productModCode;
        delete newMod.productModalityRange[i].productCode;
      }

      for (let i: number = 0; i < newMod.tariff.length; i++) {
        newMod.tariff[i].tariffName = newMod.tariff[i].tariffName + ' (Cópia)'

        delete newMod.tariff[i].tarCode;
        delete newMod.tariff[i].idTarifario;
        delete newMod.tariff[i].gradeCode;

        for (let j: number = 0; j < newMod.tariff[i].daysTariff.length; j++) {
          delete newMod.tariff[i].daysTariff[j].daysTarCode;

          for (let k: number = 0; k < newMod.tariff[i].daysTariff[j].horariosTarifario.length; k++) {
            delete newMod.tariff[i].daysTariff[j].horariosTarifario[k].daysTarCode;
            delete newMod.tariff[i].daysTariff[j].horariosTarifario[k].daysUniqueTarCode;
            delete newMod.tariff[i].daysTariff[j].horariosTarifario[k].modCode;
            delete newMod.tariff[i].daysTariff[j].horariosTarifario[k].tarCode;
            delete newMod.tariff[i].daysTariff[j].horariosTarifario[k].productCode;
          }
        }
        for (let j: number = 0; j < newMod.tariff[i].canaisTarifario.length; j++) {
          delete newMod.tariff[i].canaisTarifario[j].modCode;
          delete newMod.tariff[i].canaisTarifario[j].productCode;
          delete newMod.tariff[i].canaisTarifario[j].tarCode;

        }
      };
    }

    auxObj.modalidade.push(newMod)
    return auxObj;

  } catch (e) {
    console.warn(e);
  }
}

function tryToCatchDuplicateTicket(state: any, info: any) {
  let auxObj: any = null;

  try {
    let aux: any = JSON.stringify(state);
    auxObj = JSON.parse(aux);

    auxObj.modalidade[0] = auxObj.modalidade[info.index];

    auxObj.modalidade[0].modalityName = [
      {
        "text": auxObj.modalidade[info.index].modalityName[0].text + " (Cópia)",
        "lang": 1
      },
      {
        "text": auxObj.modalidade[info.index].modalityName[1].text + " (Copy)",
        "lang": 2
      },
      {
        "text": auxObj.modalidade[info.index].modalityName[2].text + " (Copiar)",
        "lang": 3
      }
    ]

    if (info.isEdit) {
      delete auxObj.modalidade[0].ticketCode;
      delete auxObj.modalidade[0].ticketModCode;
      delete auxObj.modalidade[0].ticketModUniqueCode;
      delete auxObj.modalidade[0].id;

      for (let i: number = 0; i < auxObj.modalidade[0].productModalityRange.length; i++) {
        delete auxObj.modalidade[0].productModalityRange[i].productCode;
        delete auxObj.modalidade[0].productModalityRange[i].productModCode;
      }

      for (let i: number = 0; i < auxObj.modalidade[0].ticketTariff.length; i++) {
        delete auxObj.modalidade[0].ticketTariff[i].ticketTariffCode;
        delete auxObj.modalidade[0].ticketTariff[i].id;
        delete auxObj.modalidade[0].ticketTariff[i].ticketModCode;
        delete auxObj.modalidade[0].ticketTariff[i].ticketTariffUniqueCode;
        delete auxObj.modalidade[0].ticketTariff[i].ticketCode;

        for (let j: number = 0; j < auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff.length; j++) {
          delete auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].daysTarCode;
          delete auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].daysUniqueTarCode;
          delete auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].ticketCode;
          delete auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].ticketModCode;
          delete auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].ticketTariffCode;
          delete auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].ticketTariffUniqueCode;

          for (let k: number = 0; k < auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].horariosTarifario.length; k++) {
            delete auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].horariosTarifario[k].daysTarCode;
            delete auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].horariosTarifario[k].daysUniqueTarCode;
            delete auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].horariosTarifario[k].modCode;
            delete auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].horariosTarifario[k].tarCode;
            delete auxObj.modalidade[0].ticketTariff[i].ticketDailyTariff[j].horariosTarifario[k].productCode;
          }
        }
        for (let j: number = 0; j < auxObj.modalidade[0].ticketTariff[i].canaisTarifario.length; j++) {
          delete auxObj.modalidade[0].ticketTariff[i].canaisTarifario[j].modCode;
          delete auxObj.modalidade[0].ticketTariff[i].canaisTarifario[j].productCode;
          delete auxObj.modalidade[0].ticketTariff[i].canaisTarifario[j].tarCode;
        }
      };
    }

    return auxObj;

  } catch (e) {
    console.warn(e);
  }
}

// const store2 = configureStore({
//   reducer: productSlice.reducer
// })

// Can still subscribe to the store
// store.subscribe(() => console.log(store.getState()))

// convert object to string and store in localStorage

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store2.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatchProduct = typeof store2.dispatch

export default productSlice;