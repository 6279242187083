import React, { useState } from "react";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";

import dataInvoice from './locales/invoice.json';

import './TableInvoice.scss';

function TableInvoice() {

    const {
        control,
    } = useForm();

    function addStatus(cell: any, row: any) {
        return (
            <div
                className={
                    cell === "Conciliado"
                        ? "bg-analise  w-100"
                        : cell === "Pago"
                            ? "bg-ativo w-100"
                            : cell === "Pendente"
                                ? "bg-inativo  w-100"
                                : ""
                }
            >
                {cell}
            </div>
        );
    }

    function convertValue(cell: any) {
        console.log(cell)
        return (
            <>
                <span>R$ {cell},00</span>
            </>
        )
    }

    function convertDate(cell: any, row: any) {
        if (cell !== null) {
            //var date = convertUTCDateToLocalDate(new Date(cell));
            var aux: any = cell.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
            return (
                <>
                    {date}
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    const rowClasses = (row: any, rowIndex: any) => {
        /* console.log(row, rowIndex) */
        if (row.status === "Conciliado") {
            return 'border-analise';
        } else if (row.statusPagamento === "Pago") {
            return 'border-ativo';
        } else {
            return 'border-inativo';
        }
    };

    const columns = [
        {
            dataField: 'contasaPagarId',
            text: ``,
            formatter: addCheckButton
        },
        {
            dataField: "date",
            text: "Data Operação",
            formatter: convertDate,
        },
        {
            dataField: "description",
            text: "Tour/Descrição",
            sort: true
        },
        {
            dataField: "client",
            text: "Cliente",
            sort: true,
        },
        {
            dataField: "value",
            text: "Valor",
            formatter: convertValue,
        },
        {
            dataField: "status",
            text: "Status",
            formatter: addStatus,
        },
    ];

    function addCheckButton(cell: any, row: any) {
        return (
            <>
                <div className="center-table">
                    <Controller
                        control={control}
                        name={'' + cell}
                        render={({ field }: any) => (
                            <Form.Check
                                {...field}
                                fullWidth
                                variant="standard"
                                margin="normal"
                                type="checkbox"
                                //defaultChecked={confereChecked(cell)}
                                value={cell}
                            //onClick={() => { handleClick(cell) }}
                            />
                        )}
                    />
                </div>
            </>
        )
    }

    return (
        <>
            <div className="table-invoice filter d-flex w-100 justify-content-center mt-4">
                <div className="table-container">
                    <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={dataInvoice.invoice}
                        columns={columns}
                        rowClasses={rowClasses}
                    //striped={true}
                    />
                </div>
            </div>
        </>
    )
}

export default TableInvoice;