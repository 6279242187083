import React, { useEffect, useState } from 'react';
import api from '../../../../../../services/api';

import Helmet from 'react-helmet';

//COMPONENTS
import DesktopDefault from '../../../../../../templates/DesktopDefault';
import Breadcrumb from '../../../../../../components/Breadcrumb/Breadcrumb';
import SessionInfo from './components/SessionInfo/SessionInfo';

import './SingleSessionTransfer.scss';

function SingleSessionTransfer() {

    const sessionId = window.location.href.split('id=')[1];

    const [sessionInfo, setSessionInfo] = useState<any>(undefined);
    const [sessionInfoOrigin, setSessionInfoOrigin] = useState<any>(undefined);
    const [sessionInfoDestiny, setSessionInfoDestiny] = useState<any>(undefined);

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };
        const EditSupplier = async () => {
            try {
                const { data } = await api.get(`/Manifest/GetTransfer/${sessionId}`, config);
                if (data.status !== 400) {
                    console.log(data.data)
                    setSessionInfo(data.data);
                    setSessionInfoOrigin(data.data[0]);
                    setSessionInfoDestiny(data.data[1]);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) { }
                if (error?.response?.status === 400) { }
            }
        }
        EditSupplier();
    }, [])

    //console.log(sessionInfoOrigin, sessionInfoDestiny)

    if (sessionInfoOrigin || sessionInfoDestiny) {
        return (
            <>
                {/* <Helmet>
                    <title>Session - {JSON.stringify(sessionInfo?.localizador)} - {JSON.stringify(sessionInfo?.origem)} - {JSON.stringify(sessionInfo?.destino)}</title>
                    <meta name="description" content={sessionInfo?.descricao} />
                </Helmet> */}
                <DesktopDefault>
                    <div className="container-fluid content-dashboard single-session-tranfers">
                        <Breadcrumb title={'Operações / Manifesto / Session'} />

                        <SessionInfo
                            sessionInfo={sessionInfo}
                            sessionInfoOrigin={sessionInfoOrigin}
                            sessionInfoDestiny={sessionInfoDestiny}
                        />

                    </div>
                </DesktopDefault>
            </>
        );
    } else {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard single-session-tranfers">
                        <Breadcrumb title={'Operações / Manifesto / Session'} />
                    </div>
                </DesktopDefault>
            </>
        );
    }
}

export default SingleSessionTransfer;