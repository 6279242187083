/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DesktopDefault from "../../templates/DesktopDefault";
import EnableCategory from "./components/EnableCategory/EnableCategory";
import AddCategoryTable from "./components/AddCategoryTable/AddCategoryTable";
import LinkProduct from "./components/LinkProduct/LinkProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./AddCategory.scss";
import api from "../../services/api";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import RequestResponseModal from "../../components/Modal/RequestResponseModal/RequestResponseModal";

export interface propEdit {
  categoryInfo?: any;
}

const AddCategory: React.FC<propEdit> = ({ categoryInfo }: propEdit) => {
  //loading
  const [loading, setLoading] = useState<any>(false);
  //loading

  const [allowReload, setAllowReload] = React.useState(false);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  const [modalResponse, setModalResponse] = useState<any>(null); /// ["loading","error","sucess"] para abrir o modal pós envio
  const [modalText, setModalText] = useState<any>(""); /// ["loading","error","sucess"] para abrir o modal pós envio
  const [image, setImage] = useState<any>(null);
  const [product, setProduct] = useState<any>(null);
  const [categoryList, setCategoryList] = useState<any>(null);
  const [salesChannel, setSalesChannel] = useState<any>([]);
  const [modalShow, setModalShow] = useState(false);

  const [modalSubmitShow, setModalSubmitShow] = useState<any>(false);
  const [modalLog, setModalLog] = useState<any>(null);
  const [modalMessage, setModalMessage] = useState<any>("Carregando");

  const handleRevokeCache = async () => {
    const config = {
      headers: { "ngrok-skip-browser-warning": "69420" },
    };

    try {
      const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/products/revokecache`, config);
      if (data.status !== 400) { }
    } catch (error: any) {
      if (error.response.status === 401) { }
    }
  }

  const onSubmit = (obj: any) => {
    setModalSubmitShow(true);

    obj.categoryImage = image !== null ? image : "";

    let aux: any = [];
    for (let i = 0; i < categoryList.length; i++) {
      aux.push({
        /* productName: categoryList[i].product_name, */
        productCode: categoryList[i].productCode,
      });
    }

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function enableCategory() {
      try {
        const { data } = await api.post(
          "Categories/CreateCategoriesAsync",
          {
            categorieName: obj.name,
            categorieSlug: obj.url,
            categorieDescription: obj.description,
            categorieStatus: obj.category === undefined ? 1 : obj.category,
            categorieChannel: 1,
            categoriePublic: 0,
            categorieGroupId: 1,
            channelApp: 0,
            channelSite: 0,
            channelMarketplace: 0,
            channelB2B: 0,
            productsList: aux,
            salesChannel: salesChannel.map((elem: any) => elem.value),
          },
          config
        );

        if (data.status !== 400) {
          setModalLog(data.data.log);
          setModalMessage(data.data.texto);
          handleRevokeCache();
        }
      } catch (error: any) {
        /* setModalResponse("error");
        setModalText(error?.response?.texto); */
      }
    }
    enableCategory();
  };

  const { control, setValue, handleSubmit } = useForm({});

  useEffect(() => {
    if (modalResponse === "success") {
      setAllowReload(true);
    }

    if (allowReload === true && modalResponse === null) {
      window.location.href = "/listar-categorias";
    }
  }, [modalResponse]);

  const reload = () => {
    if (modalLog === 0) {
      window.location.href = "/listar-categorias";
    }
  };

  return (
    <DesktopDefault>
      <div className="container-fluid content-dashboard add-categoria">
        <Breadcrumb title={"Categoria Site / Adicionar Categoria"} />

        <div className="mt-4 ">
          <div className="d-flex align-items-center">
            <span
              className="back"
              onClick={() => {
                window.location.href = "/listar-categorias";
              }}
            >
              <FontAwesomeIcon
                icon={["fal", "angle-double-left"]}
                style={{ margin: "0 5px 0 0", fontSize: "16px" }}
              />
              Voltar
            </span>
          </div>
          <h2 className="title" style={{ color: "#707070" }}>
            Adicionar Categoria{" "}
          </h2>
        </div>

        <Form
          className="my-4"
          noValidate
          validated={false}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="false"
        >
          <EnableCategory
            control={control}
            product={product}
            setValue={setValue}
            setImage={setImage}
            setSalesChannel={setSalesChannel}
          />

          <div className="my-5 d-flex justify-content-center">
            <Modal
              className={"modal-large modal-custom modalAuth"}
              show={modalShow}
              onHide={() => {
                setModalShow(false);
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <div className="d-flex justify-content-between w-100 mb-4">
                  <h3>Vincular Produto</h3>
                </div>
              </Modal.Header>
              <LinkProduct
                //buscar={buscar}
                setCategoryList={setCategoryList}
                setModalShow={setModalShow}
                categoryList={categoryList}
              />
            </Modal>
            <Button
              onClick={() => {
                setModalShow(true);
              }}
            >
              Vincular Produto
            </Button>
          </div>

          <AddCategoryTable
            loading={loading}
            categoryList={categoryList}
            setCategoryList={setCategoryList}
            info={categoryInfo}
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Form>
        <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
          <div className="btn-sucess">
            <Modal
              className="modal-custom modalAuth"
              show={modalResponse !== null}
              onHide={() => setModalResponse(null)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Header closeButton></Modal.Header>
                <RequestResponseModal
                  modalResponse={modalResponse}
                  modalText={modalText}
                  setModalResponse={setModalResponse}
                />
              </>
            </Modal>
          </div>
        </Row>
      </div>

      <Modal
        className="modalAuth"
        show={modalSubmitShow}
        onHide={() => {
          reload();
          setModalLog(null);
          setModalMessage("Carregando");
          setModalSubmitShow(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <>
          <Modal.Body
            className="modal-body text-center sucess-pos d-flex justify-content-evenly"
            style={{ height: "600px" }}
          >
            {modalLog === null ? (
              <>
                <div className="loading-modal">
                  <div className="load"></div>
                </div>
              </>
            ) : (
              <div>
                {modalLog === 0 ? (
                  <FontAwesomeIcon
                    icon={["fal", "check-circle"]}
                    size="5x"
                    className="text-primary"
                    style={{ fontSize: "7.5em" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={["fal", "times-circle"]}
                    size="5x"
                    className="text-primary"
                    style={{ fontSize: "7.5em" }}
                  />
                )}
              </div>
            )}
            <div>{modalMessage}</div>
            <div className="d-flex justify-content-center pt-3">
              <button
                onClick={() => {
                  reload();
                  setModalLog(null);
                  setModalMessage("Carregando");
                  setModalSubmitShow(false);
                }}
                className="btn btn-primary mx-2 w-25"
              >
                Confirmar
              </button>
            </div>
          </Modal.Body>
        </>
      </Modal>
    </DesktopDefault>
  );
};

export default AddCategory;
