import React, { useState, useEffect, Key } from 'react';
import api from '../../../../services/api';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import {
  Row,
  Col,
  Badge,
  Dropdown,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import DoubleCalendar from '../../../../components/Calendar/DoubleCalendarEdit';
import DoubleCalendarTicket from '../../../../components/Calendar/DoubleCalendarTicketEdit';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import NumberOfPeople from '../NumberOfPeople/NumberOfPeople';
//import NumberOfPeopleTicket from '../NumberOfPeople/NumberOfPeopleTicket';

import './CorporateDetailsTicket.scss';
import AccessCodeComponent from '../../../Reservations/components/AccessCodeComponent/AccessCodeComponent';

export interface propInfo {
  id: any;
  setDetailsModal: any;
  action: any;
  editClick: any;
  handleNext?: any;
  handleModal?: any;
  seeVoucherDownload: any;
  issueTicket: any;
}

const CorporateDetailsTicket: React.FC<propInfo> = ({
  id,
  setDetailsModal,
  action,
  editClick,
  handleNext,
  handleModal,
  seeVoucherDownload,
  issueTicket
}: propInfo) => {
  const [item, setItem] = useState<any>(null);
  const [, setTexts] = useState<any>('Pesquisando');
  const [resetTimer, setResetTimer] = useState<any>(1);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [, setAllowCalendar] = useState<boolean>(false);
  const [name, setName] = useState<any>();
  const [cellphone, setCellphone] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [cpf, setCpf] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setPassageiro] = useState<any>();
  const [date, setDate] = useState<any>();
  const [dateTour, setDateTour] = useState<any>([]);
  const [internalNotesItem, setInternalNotesItem] = useState<any>();
  const [pickup, setPickup] = useState<any>(undefined);
  const [tourOptions, setTourOptions] = useState<any>(null);

  const [editSuccess, setEditSuccess] = useState<any>(false);
  const [currentEditResponse, setCurrentEditResponse] = useState<any>(null);
  const [editErrorText, setEditErrorText] = useState<any>('');

  const [passengers, setPassengers] = useState<any>(undefined);

  const [allowAccessCodeComponent, setAllowAccessCodeComponent] =
    useState<any>(false);
  const [accessBtnLoad, setAccessBtnLoad] = useState<any>(false);
  const [accessBtnError, setAccessBtnError] = useState<any>(false);

  const level = atob(
    JSON.parse(localStorage.getItem('bpaSigAuth') || '{}').user.perfil.nivel,
  );

  useEffect(() => {
    if (editClick === true && item !== null) {
      setEditProfile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourOptions]);

  const [loading, setLoading] = useState<any>(false);

  const validated = false;

  useEffect(() => {
    let timer1 = setTimeout(() => setTexts('Pesquisando'), 500);
    let timer2 = setTimeout(() => setTexts('Pesquisando.'), 1000);
    let timer3 = setTimeout(() => setTexts('Pesquisando..'), 1500);
    let timer4 = setTimeout(() => setTexts('Pesquisando...'), 2000);
    let timer5 = setTimeout(() => setResetTimer(resetTimer + 1), 2000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
      clearTimeout(timer5);
    };
  }, [resetTimer]);

  const getDetails = async () => {
    setItem(null);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': '69420',
      },
    };
    try {
      const res = await api.get(
        `/NewReservations/GetNewReservationsByIdAsync/${id}`,
        config,
      );
      if (res.status !== 400) {
        setItem(res.data.data);
      }
    } catch (error: any) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let endP = '';

    if (item !== null) {
      setName(item.nome);
      setCellphone(item.telefone);
      setEmail(item.email);
      setCpf(item.cpf);
      setPassageiro(item.quantidadePax);
      setDate(item.startTimeLocal);
      setPassengers({
        adult: item.quantityReservation.adultSale,
        child: item.quantityReservation.childSale,
        infant: item.quantityReservation.infantSale,
        student: item.quantityReservation.studentSale,
        elder: item.quantityReservation.elderSale,
      });
      setInternalNotesItem(item.internalNotesItem);

      for (var i: any = 0; i < item.pickupListByOrderDTO.length; i++) {
        if (item.nomePickup === item.pickupListByOrderDTO[i].nomePickup) {
          setPickup(item.pickupListByOrderDTO[i].idPickupLocation);
        }
      }

      endP =
        item.productType === 1
          ? `/Products/FetchSingleTourAsync`
          : `/Products/FetchSingleTicketAsync`;

      getIdChannel();
    }

    const getProdModCode = async (idChannel: number) => {
      try {
        const { data } = await api.post(endP, {
          productCode: item.productCode,
          lang: 'BR',
          slug: '',
          IdCanal: idChannel,
          newProduct: item.idCanal === 0 ? 1 : 0,
        });
        if (data.status !== 400) {
          setTourOptions(data.data.modalities);
          setAllowCalendar(true);
        }
      } catch (error) { }
    };

    async function getIdChannel() {
      const config = {
        headers: { 'ngrok-skip-browser-warning': '69420' },
      };
      try {
        const { data } = await api.get(
          `${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/${item.source}`,
          config,
        );
        if (data.statusCode === 200) {
          getProdModCode(data.data.data);
        }
      } catch (error) { }
    }
  }, [item]);

  useEffect(() => {
    var aux: any = [];
    if (tourOptions !== null) {
      for (var i = 0; i < tourOptions.length; i++) {
        aux.push({
          adult: 0,
          child: 0,
          infant: 0,
        });
      }
    }
    setDateTour(aux);
  }, [tourOptions]);

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any, isHour: any) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000,
    );

    //var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours /*  - offset */);

    if (isHour === true) {
      return newDate.toLocaleString().split(' ')[1];
    } else {
      return newDate.toLocaleString().split(' ')[0];
    }
  }

  function convertDate(cell: any, isHour: any = false) {
    var date = convertUTCDateToLocalDate(new Date(cell), false).replaceAll(
      ',',
      '',
    );
    var hours = convertUTCDateToLocalDate(new Date(cell), true);
    if (isHour === true) {
      return <>{hours}</>;
    } else {
      return <>{date}</>;
    }
  }
  /*converte a data UTC para a data local do usuário*/

  function changePriceDateTour(obj: any) {
    setDateTour(obj);
    setDate(obj[0].data);
  }

  function EmailTotemSecond(idItem: any) {
    // setCancelamentoModal(true);
    let lng =
      item.idioma === 'Português'
        ? 'BR'
        : item.idioma === 'Inglês'
          ? 'EN'
          : item.idioma === 'Espanhol'
            ? 'ES'
            : 'BR';

    const EmailTotem = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const { data } = await api.post(
          `${process.env.REACT_APP_SERVER_URL_API}/Checkout/EmailTotemSecondWay?IdItem=${idItem}&language=${lng}`,
          {},
          config,
        );
        if (data.status !== 400) {
          if (data.data.log === 0 || data.data.log === '0') {
            const link = document.createElement('a');
            link.href = `data:${data.data.data.voucherFile.contentType};base64, ${data.data.data.voucherFile.content}`;
            link.setAttribute('download', data.data.data.voucherFile.fileName);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // // Clean up and remove the link
            link?.parentNode?.removeChild(link);
          } else if (data.data.log === 1 || data.data.log === '1') {
            alert(data.data.data.texto);
          }
        }
      } catch (error: any) { }
    };

    EmailTotem();
  }

  const {
    control,
    handleSubmit,
    //formState: { errors },
  } = useForm();

  const onSubmit = (res: any) => {
    setLoading(true);
    setEditSuccess(true);
    setCurrentEditResponse('loading');

    res.name = name;
    res.cellphone = cellphone;
    res.email = email;
    res.cpf = cpf;
    res.date = date;
    res.internalNotesItem = internalNotesItem;

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '69420',
      },
    };

    const updateReservation = async () => {
      try {
        const { data } = await api.post(
          `/Checkout/EditEnviaCheckout`,
          {
            firstName: res.name,
            lastName: item.sobrenome,
            phone: res.cellphone,
            email: res.email,
            idTarif: item.idTarifario,
            internalNotes: res.internalNotesItem,
            IdNewItem: item.idItem,
            date: res.date,
            idBooking: item.idBookings,
            BookingLoc: item.bookingLoc,
            Foreign: 1,
            Language: 1,
            adults: passengers.adult,
            childs: passengers.child,
            infants: passengers.infant,
            elders: passengers.elder,
            students: passengers.student,
            pickup: res.listPickup
              ? parseInt(res.listPickup.idPickupLocation)
              : pickup,
            newProduct: item.idCanal === 0 ? 1 : 0,
          },
          config,
        );
        if (data.status !== 400) {
          if (data.data.log === 1 || data.data.log === '1') {
            setCurrentEditResponse('error');
            setEditErrorText(data.data.texto);
            setLoading(false);
          } else {
            setCurrentEditResponse('success');
            setEditErrorText(data.data.texto);
          }
          // window.location.reload();
        }
      } catch (error: any) {
        console.log(error?.response);
        setCurrentEditResponse('error');
        setEditErrorText(error?.response?.data?.errorMessage);
        setLoading(false);
      }
    };
    updateReservation();
  };

  const cancelAccessCode = (accessCodeIndex: any) => {
    setLoading(true);
    setEditSuccess(true);
    setCurrentEditResponse('loading');

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '69420',
      },
    };

    const updateReservation = async () => {
      try {
        const { data } = await api.post(
          `/Checkout/EditEnviaCheckout`,
          {
            firstName: item.nome,
            lastName: item.sobrenome,
            phone: item.telefone,
            email: item.email,
            idTarif: item.idTarifario,
            internalNotes: item.internalNotesItem,
            IdNewItem: item.idItem,
            date: item.startTimeLocal,
            idBooking: item.idBookings,
            BookingLoc: item.bookingLoc,
            Foreign: 1,
            Language: 1,
            newProduct: item.idCanal === 0 ? 1 : 0,
            /* "adults": passengers.adult,
                        "childs": passengers.child,
                        "infants": passengers.infant,
                        "elders": passengers.elder,
                        "students": passengers.student,
                        "pickup": undefined, */
            accessCodeListToRemove: [
              item.qrCodeAccessReservationDTO[accessCodeIndex].accessCode,
            ],
          },
          config,
        );
        if (data.status !== 400) {
          if (data.data.log === 1 || data.data.log === '1') {
            setCurrentEditResponse('error');
            setEditErrorText(data.data.texto);
            setLoading(false);
          } else {
            setCurrentEditResponse('success');
            setEditErrorText(data.data.texto);
          }
          // window.location.reload();
        }
      } catch (error: any) {
        console.log(error.response);
        setCurrentEditResponse('error');
        setEditErrorText(error?.response?.data?.errorMessage);
        setLoading(false);
      }
    };
    updateReservation();
  };

  if (item !== null) {
    console.log(item.bookingLoc)
    return (
      <>
        <div>
          <Form
            className="modal-content"
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="false"
          >
            <Modal.Body>
              <div className="modal-header rounded-0">
                <div>
                  <h4 className="pt-1 text-primary title-reservation">
                    {item.productName.split(' - ')[0]}
                  </h4>
                  <small>
                    {item.idioma === 'Espanhol'
                      ? item.modalityEs
                      : item.idioma === 'Inglês'
                        ? item.modalityEN
                        : item.modalityBR}
                  </small>
                </div>

                <div className="buttons-options">
                  <div>
                    {editProfile === false ? (
                      <Dropdown drop="down">
                        <Dropdown.Toggle className="options-reservations">
                          <FontAwesomeIcon
                            icon={['fal', 'angle-down']}
                            className="mx-2 text-info"
                            size="2x"
                            style={{ marginTop: '-3px' }}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                              setEditProfile(!editProfile);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'edit']}
                              className="mr-2"
                            />
                            Editar
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="2" onClick={() => issueTicket(item)} disabled={item.status === 1 || level !== "33"}
                          >
                            <FontAwesomeIcon icon={['fal', 'download']} className="mr-2" />
                            Emitir Reserva
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={['fal', 'times']}
                      className="mx-3 text-info"
                      size="lg"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setDetailsModal(false);
                      }}
                    />
                  </div>
                </div>
              </div>

              <hr
                className="w-100 my-4"
                style={{ borderBottom: '1px solid' }}
              />

              <div className="w-100 p-0">
                <Row className="p-0 m-0">
                  <Col md={1} className="divisor-custom">
                    <Badge
                      className="px-1"
                      bg={
                        item.status === 1
                          ? 'success'
                          : item.status === 2
                            ? 'danger'
                            : item.status === 7
                              ? 'yellow'
                              : 'info'
                      }
                    >
                      {item.status === 1
                        ? 'Ativo'
                        : item.status === 2
                          ? 'Cancelado'
                          : item.status === 7
                            ? 'Pré Reserva'
                            : 'Não Informado'}
                    </Badge>
                  </Col>
                  <Col md={3} className="divisor-custom p-0 m-0 pl-4">
                    <small>
                      Código da reserva: <b>{item.bookingLoc}</b>
                    </small>
                  </Col>
                  {/* <div className='divider'></div> */}
                  <Col
                    md={4}
                    className="divisor-custom p-0 m-0 pl-4"
                    style={{ width: '321px' }}
                  >
                    <small>
                      Titular da reserva: <b>{item.nomeCompleto}</b>
                    </small>
                  </Col>
                  {/* <div className='divider'></div> */}
                  <Col md={3} className="p-0 m-0 pl-4">
                    <small>
                      Canal: <b>{item.source}</b>
                    </small>
                  </Col>
                </Row>

                <Row className="p-0 m-0 mt-5">
                  <h6 className="mb-3">Dados do titular da reserva</h6>
                  <Col md={3}>
                    <span className="subtitle-modal">Nome</span>
                    {editProfile === false ||
                      level === '29' ||
                      item.idCanal === 0 ? (
                      <span className="response-modal">
                        <b>{item.nomeCompleto}</b>
                      </span>
                    ) : (
                      <>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="formInfoCompanySubscription"
                        >
                          <Controller
                            control={control}
                            name="name"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                type="text"
                                value={name}
                                size="sm"
                                onChange={(e: any) => {
                                  setName(e.target.value);
                                }}
                                autoComplete="off"
                                required
                              />
                            )}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">Telefone</span>
                    {editProfile === false ||
                      level === '29' ||
                      item.idCanal === 0 ? (
                      <span className="response-modal">
                        <b>{item.telefone}</b>
                      </span>
                    ) : (
                      <>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="formInfoCompanySubscription"
                        >
                          <Controller
                            control={control}
                            name="cellphone"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                type="text"
                                size="sm"
                                value={cellphone}
                                onChange={(e: any) => {
                                  setCellphone(e.target.value);
                                }}
                                autoComplete="off"
                                required
                              />
                            )}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">Email</span>
                    {editProfile === false ||
                      level === '29' ||
                      item.idCanal === 0 ? (
                      <OverlayTrigger
                        placement={'bottom'}
                        overlay={<Tooltip id="tooltip">{item.email}</Tooltip>}
                      >
                        <span className="response-modal response-modal-custom">
                          <b>{item.email}</b>
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="formInfoCompanySubscription"
                        >
                          <Controller
                            control={control}
                            name="email"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                type="text"
                                size="sm"
                                value={email}
                                onChange={(e: any) => {
                                  setEmail(e.target.value);
                                }}
                                autoComplete="off"
                                required
                              />
                            )}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">
                      {item.estrangeiro === 2 ? 'CPF' : 'Passaporte'}
                    </span>
                    <span className="response-modal">
                      <b>{item.cpf}</b>
                    </span>
                  </Col>
                </Row>

                <Row className="p-0 m-0 mt-5">
                  <h6 className="mb-3">Informações da Reserva</h6>
                  <Col md={3}>
                    <span className="subtitle-modal">Pessoas</span>
                    <span className="response-modal">
                      <b>{item.quantidadePax} Passageiros</b>
                    </span>
                    {/* {editProfile === false
                                            ?
                                            <span className="response-modal"><b>{item.quantidadePax} Passageiros</b></span>
                                            :
                                            <div className='number-of-people'>
                                                {
                                                    item.productType === 4
                                                        ?
                                                        <NumberOfPeopleTicket quantityReservation={item.quantityReservation} setPassengers={setPassengers} />
                                                        :
                                                        <NumberOfPeople quantityReservation={item.quantityReservation} setPassengers={setPassengers} />
                                                }

                                            </div>
                                        } */}
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">Data</span>
                    {editProfile === false ? (
                      <span className="response-modal">
                        <b>
                          {item.startTimeLocal
                            .split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')}
                        </b>
                      </span>
                    ) : (
                      <>
                        <Form.Group as={Col} md="12">
                          <InputGroup
                            hasValidation
                            className=""
                            size="sm"
                            placeholder="Quando?"
                            id="date-"
                          >
                            {item.productType === 1 ? (
                              <DoubleCalendar
                                modalityID={item.idModalidade}
                                product={tourOptions[0]}
                                /* product={{
                                                                    "prodModCode": "MWR7859",
                                                                    "tarif": {
                                                                        "tarCode": "TARNF5356"
                                                                    }
                                                                }} */
                                productCode={item.productCode}
                                changePriceDateTour={changePriceDateTour}
                                dateTour={dateTour}
                                index={0}
                                startTimeLocal={convertDate(
                                  item.startTimeLocal,
                                )}
                                item={item}
                              ></DoubleCalendar>
                            ) : (
                              <DoubleCalendarTicket
                                modalityID={item.idModalidade}
                                product={tourOptions[0]}
                                /* product={{
                                                                    "prodModCode": "MWR7859",
                                                                    "tarif": {
                                                                        "tarCode": "TARNF5356"
                                                                    }
                                                                }} */
                                productCode={item.productCode}
                                changePriceDateTour={changePriceDateTour}
                                dateTour={dateTour}
                                index={0}
                                startTimeLocal={convertDate(
                                  item.startTimeLocal,
                                )}
                                item={item}
                              ></DoubleCalendarTicket>
                            )}
                          </InputGroup>
                        </Form.Group>
                      </>
                    )}
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">Hora</span>
                    <span className="response-modal">
                      <b>{convertDate(item.startTimeLocal, true)}</b>
                    </span>
                  </Col>
                  {/* <Col md={3} >
                                        <span className="subtitle-modal">Pickup</span>
                                        {editProfile === false
                                            ?
                                            <span className="response-modal"><b>{item.nomePickup}</b></span> :
                                            <>
                                                <Form.Group as={Col} md="12" controlId="formInfoCompanySubscription">
                                                    <Controller
                                                        control={control}
                                                        name="listPickup"
                                                        render={({ field }: any) => (
                                                            <div className='input-select-custom'>
                                                            <GetPickupList
                                                                propsField={field}
                                                                propsErrors={errors}
                                                                pickupList={item.pickupListByOrderDTO}
                                                                />
                                                                </div>
                                                        )}
                                                    />
                                                </Form.Group>
                                            </>
                                        }
                                    </Col> */}
                </Row>

                {item.status === 2 ? (
                  <Row className="p-0 m-0 mt-5">
                    <h6 className="mb-3">Informações do Cancelamento</h6>
                    <Col md={3}>
                      <span className="subtitle-modal">Cancelada por:</span>
                      <span className="response-modal">
                        <b>{item.nameCancel + ' ' + item.lastNameCancel}</b>
                      </span>
                    </Col>
                    <Col md={3}>
                      <span className="subtitle-modal">Data</span>
                      <span className="response-modal">
                        <b>{convertDate(item.dataCancelamento)}</b>
                      </span>
                    </Col>
                    <Col md={3}>
                      <span className="subtitle-modal">Hora</span>
                      <span className="response-modal">
                        <b>{convertDate(item.dataCancelamento, true)}</b>
                      </span>
                    </Col>
                    {item.source !== 'BILHETERIA' && item.source !== 'TOTEM' ? (
                      <Col md={3}>
                        <span className="subtitle-modal">Motivo</span>
                        <span className="response-modal">
                          <b>{item.motivoCancelado}</b>
                        </span>
                      </Col>
                    ) : (
                      ''
                    )}
                  </Row>
                ) : (
                  ''
                )}

                <Row className="p-0 m-0 mt-5">
                  <div className="d-flex justify-content-start">
                    <h6 className="mb-3 mr-3">Códigos de Acesso</h6>
                    {accessBtnLoad === false ? (
                      <>
                        {editProfile === false ? (
                          <>
                            <FontAwesomeIcon
                              icon={['fad', 'redo-alt']}
                              className="access-code-reload-btn text-primary mr-3"
                              onClick={() => {
                                setAllowAccessCodeComponent(true);
                              }}
                              size="1x"
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="load primary m-0 mr-3"></div>
                      </>
                    )}
                    <span style={{ color: 'red', fontSize: '14px' }}>
                      {accessBtnError === true
                        ? 'Erro ao processar operação'
                        : ''}
                    </span>
                  </div>

                  {allowAccessCodeComponent === true ? (
                    <>
                      <AccessCodeComponent
                        item={item}
                        setAccessBtnLoad={setAccessBtnLoad}
                        setAccessBtnError={setAccessBtnError}
                        setAllowAccessCodeComponent={
                          setAllowAccessCodeComponent
                        }
                        cancelAccessCode={cancelAccessCode}
                      />
                    </>
                  ) : (
                    <>
                      {item.qrCodeAccessReservationDTO.length > 0
                        ? item.qrCodeAccessReservationDTO.map(
                          (unit: any, index: Key) => {
                            return (
                              <>
                                <div className="d-flex justify-content-between access-code">
                                  <div
                                    className={
                                      unit.activationTimestamp === null
                                        ? 'code-waiting'
                                        : 'code-used'
                                    }
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                    }}
                                  >
                                    <div className="d-flex">
                                      <span className="icon-code mr-2">
                                        <FontAwesomeIcon
                                          icon={['fad', 'ticket']}
                                          className={
                                            unit.activationTimestamp === null
                                              ? 'mx-2 text-info'
                                              : 'mx-2 text-success'
                                          }
                                          size="lg"
                                        />
                                      </span>
                                      <span>
                                        <h6 className="w-auto">
                                          Código
                                          <small
                                            style={{ marginLeft: '0.5rem' }}
                                          >
                                            <Badge
                                              className=" ml-2"
                                              bg={
                                                unit.activationTimestamp ===
                                                  null && unit.status === 1
                                                  ? 'info'
                                                  : unit.activationTimestamp ===
                                                    null &&
                                                    unit.status === 2
                                                    ? 'danger'
                                                    : 'success'
                                              }
                                            >
                                              {unit.activationTimestamp ===
                                                null && unit.status === 1
                                                ? 'Em aberto'
                                                : unit.activationTimestamp ===
                                                  null && unit.status === 2
                                                  ? 'Cancelado'
                                                  : 'Usado'}
                                            </Badge>
                                          </small>
                                          {unit.activationTimestamp !==
                                            null ? (
                                            <>
                                              <small
                                                style={{
                                                  marginLeft: '0.5rem',
                                                }}
                                              >
                                                <Badge
                                                  className=" ml-2"
                                                  bg={
                                                    unit.activationTimestamp ===
                                                      null
                                                      ? 'info'
                                                      : unit.partial === false
                                                        ? 'blue'
                                                        : 'warning'
                                                  }
                                                >
                                                  Skidata
                                                </Badge>
                                              </small>
                                              {unit.partial === false ? (
                                                <>
                                                  <small
                                                    style={{
                                                      marginLeft: '0.5rem',
                                                    }}
                                                  >
                                                    <Badge
                                                      className=" ml-2"
                                                      bg={'blue'}
                                                    >
                                                      SIG
                                                    </Badge>
                                                  </small>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </h6>
                                        <span className="response-modal">
                                          Nº do código: {unit.accessCode}
                                        </span>
                                        {unit.activationTimestamp === null ? (
                                          <></>
                                        ) : (
                                          <>
                                            <span className="ml-3 response-modal text-strong">
                                              Data da visita:
                                            </span>{' '}
                                            <span className="response-modal">
                                              {convertDate(
                                                unit.activationTimestamp,
                                              )}{' '}
                                              -{' '}
                                              {convertDate(
                                                unit.activationTimestamp,
                                                true,
                                              )}
                                            </span>
                                          </>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex align-items-center h-100 gap-4">
                                      <h6 className="mb-0">
                                        {unit.faixaLabel}
                                      </h6>
                                      <h6 className="mb-0">
                                        {unit.valorTicket.toLocaleString(
                                          'pt-br',
                                          {
                                            style: 'currency',
                                            currency: 'BRL',
                                          },
                                        )}
                                      </h6>
                                    </div>
                                  </div>

                                  {unit.status === 1 ? (
                                    <>
                                      <div>
                                        {/* {<Dropdown drop="start">
                                                                                <Dropdown.Toggle variant="light ">
                                                                                    <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item eventKey="3" disabled={false} className="text-danger remove" onClick={() => { cancelAccessCode(index) }}>
                                                                                        <FontAwesomeIcon
                                                                                            icon={['fal', 'ban']}
                                                                                            className="mr-2"
                                                                                        />
                                                                                        <span>Cancelar código de acesso</span>
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>} */}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div></div>
                                    </>
                                  )}
                                </div>
                              </>
                            );
                          },
                        )
                        : 'Não informado'}
                    </>
                  )}
                </Row>

                <Row className="p-0 m-0 mt-5">
                  <h6 className="mb-3">Informações Gerais</h6>
                  <Col md={3}>
                    <span className="subtitle-modal">Data da Emissão</span>
                    <span className="response-modal">
                      <b>
                        {item.dataEmissao
                          .split('T')[0]
                          .split('-')
                          .reverse()
                          .join('/')}
                      </b>
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">Fornecedor</span>
                    <span className="response-modal">
                      <b>{item.nomeFornecedor}</b>
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">
                      Telefone do Fornecedor
                    </span>
                    <span className="response-modal">
                      <b>{item.telefoneFornecedor}</b>
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">Agente</span>
                    <span className="response-modal">
                      <b>
                        {item.resellerName} - {item.resellerReference}
                      </b>
                    </span>
                  </Col>
                </Row>

                <Row className="p-0 m-0 mt-5">
                  <h6 className="mb-3">Dados da pagamento</h6>
                  <Col md={3}>
                    <span className="subtitle-modal">Valor</span>
                    <span className="response-modal">
                      <b>R$ {item.amount.toFixed(2).split('.').join(',')}</b>
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">Forma de Pagamento</span>
                    <span className="response-modal">
                      <b>{item.formaPagamento}</b>
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">Cupom de desconto</span>
                    <span className="response-modal">
                      <b>
                        {item.cupom} -{' '}
                        {item.percentCupom > 0 ? item.percentCupom : ''}
                      </b>
                    </span>
                  </Col>
                </Row>

                <Row className="p-0 m-0 mt-5 flex-column">
                  <h6 className="mb-3">Informações importantes</h6>
                  <Col md={6} className="mb-3">
                    <span className="subtitle-modal">Modalidades</span>
                    <span className="response-modal">
                      <b>{item.modalityBR}</b>
                    </span>
                  </Col>
                  <Col md={6}>
                    <span className="subtitle-modal">Notas Internas</span>
                    {editProfile === false || level === '29' ? (
                      <span className="response-modal">
                        <b>{item.internalNotesItem}</b>
                      </span>
                    ) : (
                      <>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="formInfoCompanySubscription"
                        >
                          <Controller
                            control={control}
                            name="internalNotesItem"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                type="text"
                                as="textarea"
                                rows={4}
                                size="sm"
                                value={internalNotesItem}
                                onChange={(e: any) => {
                                  setInternalNotesItem(e.target.value);
                                }}
                                autoComplete="off"
                                required
                              />
                            )}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Col>
                </Row>

                <Row className="p-0 m-0 my-5">
                  <Col md={6}>
                    <span className="subtitle-modal">Inclui</span>
                    <ul className="exclusoes small" id="excluiVoucher">
                      {item.itemsIncludedDTO.length > 0
                        ? item.itemsIncludedDTO.map((unit: any, index: Key) => {
                          return (
                            <li key={index}>
                              <FontAwesomeIcon
                                icon={['fal', 'check']}
                                className="mx-2 text-success"
                                size="lg"
                              />
                              {unit.atividade}
                            </li>
                          );
                        })
                        : 'Não informado'}
                    </ul>
                  </Col>
                  <Col md={6}>
                    <span className="subtitle-modal">Não Inclui</span>
                    <ul className="exclusoes small" id="excluiVoucher">
                      {item.itemsNotIncludedDTO.length > 0
                        ? item.itemsNotIncludedDTO.map(
                          (unit: any, index: Key) => {
                            return (
                              <li key={index}>
                                <FontAwesomeIcon
                                  icon={['fal', 'times']}
                                  className="mx-2 text-info"
                                  size="lg"
                                />
                                {unit.atividade}
                              </li>
                            );
                          },
                        )
                        : 'Não informado'}
                    </ul>
                  </Col>
                </Row>

                <hr />

                <Row className="p-0 m-0 my-5">
                  <h6 className="mb-3">Emails Reminder</h6>

                  {/* {test.length > 0
                                        ? test.map((unit: any, index: Key) => { */}
                  {item.messageStatusSends.length > 0
                    ? item.messageStatusSends.map((unit: any, index: Key) => {
                      return (
                        <div key={index}>
                          <span className="subtitle-modal">
                            {unit.messageType}
                          </span>
                          {unit.statusSend === 'DELIVERED' ? (
                            <div className="d-flex">
                              <small style={{ width: '120px' }}>
                                <Badge
                                  className="ml-2 mr-4 px-4"
                                  bg={'success'}
                                  style={{ width: '120px' }}
                                >
                                  Entregue
                                </Badge>
                              </small>
                              <p
                                className="px-4"
                                style={{
                                  fontSize:
                                    '14px' /* , borderRight: "1px solid #ccc" */,
                                }}
                              >
                                Data de Entrega:{' '}
                                {convertDate(unit.dateMessage)}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          {unit.statusSend === 'SEND' ? (
                            <div className="d-flex">
                              <small style={{ width: '120px' }}>
                                <Badge
                                  className="ml-2 mr-4 px-4 badge grey"
                                  style={{ width: '120px' }}
                                >
                                  Enviado
                                </Badge>
                              </small>
                              <p
                                className="px-4"
                                style={{ fontSize: '14px' }}
                              >
                                Data da Abertura:{' '}
                                {convertDate(unit.dateMessage)}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          {unit.statusSend === 'NOTSEND' ? (
                            <div className="d-flex">
                              <small style={{ width: '120px' }}>
                                <Badge
                                  className="ml-2 mr-4 px-4"
                                  bg={'danger'}
                                  style={{ width: '120px' }}
                                >
                                  Não entregue
                                </Badge>
                              </small>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })
                    : 'nenhuma atividade encontrada'}
                </Row>

                <hr />

                <Row className="p-0 m-0 my-5">
                  <h6 className="mb-3">Logs</h6>
                  {item.logs.length > 0
                    ? item.logs.map((unit: any, index: Key) => {
                      return (
                        <div key={index}>
                          <p className="mb-1" style={{ fontSize: '14px' }}>
                            <strong>{unit.usuario}</strong> -{' '}
                            {convertDate(unit.dateUpdated)} -{' '}
                            {convertDate(unit.dateUpdated, true)}
                          </p>
                          <p className="mb-1" style={{ fontSize: '14px' }}>
                            <strong>E-mail: </strong>
                            {unit.email} | <strong>Telefone: </strong>
                            {unit.telefone} | <strong>Idioma: </strong>
                            {unit.idioma}
                          </p>
                          <p style={{ fontSize: '14px' }}>
                            {unit.internalNotesItem
                              ? unit.internalNotesItem
                              : ''}
                          </p>

                          <hr style={{ opacity: '.1' }} />
                        </div>
                      );
                    })
                    : 'nenhuma atividade encontrada'}
                </Row>
              </div>
            </Modal.Body>

            {editProfile === false ? (
              <></>
            ) : (
              <Modal.Footer>
                <div className="modal-footer rounded-0 border-0">
                  <button
                    type="button"
                    className="btn btn-outline-primary rounded-0"
                    data-dismiss="modal"
                    onClick={() => {
                      setEditProfile(!editProfile);
                    }}
                  >
                    <i className="fas fa-times fa-fw"></i>{' '}
                    <span data-vouchercampo="close">Cancelar</span>
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary rounded-0 details-reservations-submit-button"
                  >
                    {loading === true ? (
                      <div className="load"></div>
                    ) : (
                      'Editar Reserva'
                    )}
                  </button>
                </div>
              </Modal.Footer>
            )}
          </Form>
        </div>
        <Modal
          className="modal-custom-sm modal-confirm bg-modal-custom modal-cancel-reservation"
          show={editSuccess !== false}
          onHide={() => {
            setEditSuccess(false);
            setEditProfile(false);
            getDetails();
            currentEditResponse === 'success' ? (
              <></>
            ) : (
              setCurrentEditResponse(null)
            );
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="">
            {currentEditResponse === 'success' ? (
              <Modal.Body className="center">
                <div className="sucess-pos">
                  <div className="text-center">
                    <FontAwesomeIcon
                      icon={['fal', 'check-circle']}
                      size="5x"
                      style={{ fontSize: '7.5em' }}
                    />
                    <h5>Parabéns</h5>
                    <p>{editErrorText}</p>
                  </div>
                  <Button
                    className="my-3 mx-auto"
                    onClick={() => {
                      setEditSuccess(false);
                      setEditProfile(false);
                      getDetails(); /* window.location.reload(); */
                    }}
                  >
                    Fechar
                  </Button>
                </div>
              </Modal.Body>
            ) : currentEditResponse === 'error' ? (
              <Modal.Body className="center">
                <div className="sucess-pos">
                  <div className="text-center">
                    <FontAwesomeIcon
                      icon={['fal', 'times-circle']}
                      size="5x"
                      style={{ fontSize: '7.5em' }}
                    />
                    <h5>Error</h5>
                    <p>{editErrorText}</p>
                  </div>
                  <Button
                    className="my-5 mx-auto"
                    onClick={() => setEditSuccess(false)}
                  >
                    Fechar
                  </Button>
                </div>
              </Modal.Body>
            ) : (
              <Modal.Body className="center">
                <div className="loading">
                  <div className="text-center">
                    <div className="load-big mb-3"></div>
                    <h5>Carregando</h5>
                  </div>
                </div>
              </Modal.Body>
            )}
          </div>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Modal.Body>
          <div className="modal-header rounded-0">
            <div>
              <div
                className="pt-1 text-primary title-reservation animated-background"
                style={{ height: '30px', width: '500px' }}
              ></div>
              <small></small>
            </div>

            <div className="buttons-options">
              <div></div>
              <div>
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  className="mx-3 text-info"
                  size="lg"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setDetailsModal(false);
                  }}
                />
              </div>
            </div>
          </div>

          <hr className="w-100 my-4" style={{ borderBottom: '1px solid' }} />

          <div className="w-100 p-0">
            <Row className="p-0 m-0">
              <Col md={2}>
                <div
                  className=" animated-background"
                  style={{ height: '18px', width: '80%' }}
                ></div>
              </Col>
              <Col md={3}>
                <div
                  className=" animated-background"
                  style={{ height: '18px', width: '80%' }}
                ></div>
              </Col>
              <div className="divider"></div>
              <Col md={6} className="p-0 m-0 pl-4">
                <div
                  className=" animated-background"
                  style={{ height: '18px', width: '80%' }}
                ></div>
              </Col>
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  className="mb-3 animated-background"
                  style={{ height: '24px', width: '200px' }}
                ></div>
                <div className="d-flex">
                  <Col
                    md={3}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                  <Col md={3}>
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                  <Col md={3}>
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                </div>
              </div>
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  className="mb-3 animated-background"
                  style={{ height: '24px', width: '200px' }}
                ></div>
                <div className="d-flex">
                  <Col
                    md={3}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                  <Col md={3}>
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                  <Col md={3}>
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                  <Col md={3}>
                    <span
                      className="subtitle-modal animated-background"
                      style={{
                        marginBottom: '10px',
                        height: '18px',
                        width: '60px',
                      }}
                    ></span>
                    <div
                      className=" animated-background"
                      style={{ height: '12px', width: '100px' }}
                    ></div>
                  </Col>
                </div>
              </div>
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div
                className="mb-3 animated-background"
                style={{ height: '24px', width: '200px' }}
              ></div>
              {[1, 2, 3, 4, 5].length > 0
                ? [1, 2, 3, 4, 5].map((unit: any, index: Key) => {
                  return (
                    <div
                      className="my-3"
                      key={index}
                      style={{ display: 'flex' }}
                    >
                      <span className=" mr-2">
                        <div
                          className="mb-3 animated-background"
                          style={{
                            height: '50px',
                            width: '50px',
                            borderRadius: '50%',
                          }}
                        ></div>
                      </span>
                      <span>
                        <div
                          className="mb-3 animated-background"
                          style={{ height: '24px', width: '100px' }}
                        ></div>
                        <div
                          className=" animated-background"
                          style={{ height: '12px', width: '200px' }}
                        ></div>
                      </span>
                    </div>
                  );
                })
                : ''}
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div
                className="mb-3 animated-background"
                style={{ height: '24px', width: '200px' }}
              ></div>
              <div className="d-flex">
                <Col
                  md={3}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
                <Col md={3}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
                <Col md={3}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
                <Col md={3}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
              </div>
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div
                className="mb-3 animated-background"
                style={{ height: '24px', width: '200px' }}
              ></div>
              <div className="d-flex">
                <Col
                  md={3}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
                <Col md={3}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
                <Col md={3}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '60px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '100px' }}
                  ></div>
                </Col>
              </div>
            </Row>

            <Row className="p-0 m-0 mt-5">
              <div
                className="mb-3 animated-background"
                style={{ height: '24px', width: '200px' }}
              ></div>
              <div className="d-flex">
                <Col
                  md={6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '120px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '300px' }}
                  ></div>
                </Col>
                <Col md={6}>
                  <span
                    className="subtitle-modal animated-background"
                    style={{
                      marginBottom: '10px',
                      height: '18px',
                      width: '120px',
                    }}
                  ></span>
                  <div
                    className=" animated-background"
                    style={{ height: '12px', width: '300px' }}
                  ></div>
                </Col>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </>
    );
  }
};

export default CorporateDetailsTicket;

/* function getProdModCode() {
    throw new Error('Function not implemented.');
} */
