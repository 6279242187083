import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";

import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

//STYLES
import "./SuppliersTable.scss";
import "../../../../../assets/sass/table.scss";

export interface propTable {
  suppliers: any;
  loading: any;
}

const SuppliersTable: React.FC<propTable> = ({
  suppliers, loading
}: propTable) => {
  const { t } = useTranslation();
  //console.log(suppliers[0].dataCadastro.split('T')[0])


  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" disabled={true} ><FontAwesomeIcon icon={['fal', 'comment-alt-dollar']} className="mr-2" />   {t('suppliersTable.account')}</Dropdown.Item>
              <Dropdown.Item eventKey="2" href={"/suppliers/edit-suppliers?id=" + cell}><FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />  {t('suppliersTable.edit')}</Dropdown.Item>
              <Dropdown.Item eventKey="3" disabled={true}><FontAwesomeIcon icon={['fal', 'circle']} className="mr-2" />   {t('suppliersTable.status')}</Dropdown.Item>
              <Dropdown.Item eventKey="5" disabled={true}><FontAwesomeIcon icon={['fal', 'file-contract']} className="mr-2" />  {t('suppliersTable.contract')}</Dropdown.Item>
              <Dropdown.Item eventKey="4" disabled={true} className="text-danger remove"><FontAwesomeIcon icon={['fal', 'trash-alt']} className="mr-2" />  {t('suppliersTable.remove')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any, row: any) {
    var date = convertUTCDateToLocalDate(new Date(cell));
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Em Análise"
            ? "bg-analise"
            : cell === "Ativo"
              ? "bg-ativo"
              : cell === "Inativo"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  /* ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  const columns = [
    { dataField: "nomeFantasia", text: `${t("listSuppliers.tableName")}`, sort: true },
    { dataField: "state", text: `${t("listSuppliers.tableLocation")}`, sort: true },
    { dataField: "compPhone", text: `${t("listSuppliers.tableTel")}`, sort: true },
    {
      dataField: "dataCadastro",
      text: `${t("listSuppliers.tableDate")}`,
      sort: true,
      formatter: convertDate,
    },
    { dataField: "typeDesc", text: `${t("listSuppliers.filterClientType")}`, sort: true },
    {
      dataField: "statusDesc",
      text: `${t("listSuppliers.tableStatus")}`,
      sort: true,
      formatter: addStatus,
    },
    { dataField: "id", text: `${t("listSuppliers.tableAction")}`, formatter: addActionButton },
  ];

  /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */


  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando Fornecedores", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */


  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

  const notFoundProducts = [{ clientes: "Nenhum cliente cadastrado" }];
  /* [END] In case of empty table*/

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    //showTotal: true,
    //alwaysShowAllBtns: true,
  });

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusDesc === "Em Análise") {
      return 'border-analise';
    } else if (row.statusDesc === "Ativo") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  if (suppliers !== null) {
    return (
      <>
        <div className="table-default suppliers-table">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">{t("listSuppliers.tableTitle")}</h3>
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={suppliers}
              columns={columns}
              pagination={pagination}
              rowClasses={rowClasses}
            />
          </div>
        </div>
      </>
    );
  } else if (suppliers === null && loading === true) {
    return (
      <>
        <div className="table-default suppliers-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default suppliers-table not-found">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">{t("listSuppliers.tableTitle")}</h3>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
            />
          </div>
        </div>
      </>
    );
  }
}

export default SuppliersTable;
