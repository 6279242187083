import React, { createElement, useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';

import { Chart } from "react-google-charts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface propInfo {
    infoChart: any;
    filter?: any;
    loading: any;
}

const ChartSalesReport: React.FC<propInfo> = ({
    infoChart, filter = 'Mês', loading
}) => {

    const color_primary: any = process.env.REACT_APP_COLOR_PRIMARY;
    //const color_secondary: any = "red";

    const [salesInfo, setSalesInfo] = useState<any>(null);
    const [totalSales, setTotalSales] = useState<any>(0);

    const [reservationsInfo, setReservationsInfo] = useState<any>(null);
    const [totalReservations, setTotalReservations] = useState<any>(0);

    const [passengerInfo, setPassengersInfo] = useState<any>(null);
    const [totalPassenger, setTotalPassenger] = useState<any>(0);

    useEffect(() => {
        if (infoChart !== null && infoChart !== undefined) {
            let i: number;
            let countingSales: any = 0;
            let countingReservations: any = 0;
            let countingPassenger: any = 0;

            const dataVendas = [
                [filter, "Vendas (R$)"]
            ]

            for (i = 0; i < infoChart.length; i++) {
                dataVendas.push([infoChart[i].date_label?.toString().split('-').reverse().join('-'), infoChart[i].totalAmount]);
                countingSales = countingSales + infoChart[i].totalAmount;
            }
            setSalesInfo(dataVendas);
            setTotalSales(countingSales);

            const dataReservas = [
                [filter, "Nº Reservas", "Nº Passageiros"],
            ]
            const dataPassageiros = [
                [filter, "N Passageiros"],
            ]

            for (i = 0; i < infoChart.length; i++) {
                
                dataReservas.push([infoChart[i].date_label?.toString().split('-').reverse().join('-'), infoChart[i].totalCount, infoChart[i].passAmount]);

                dataPassageiros.push([infoChart[i].date_label?.toString().split('-').reverse().join('-'), infoChart[i].passAmount]);

                countingReservations = countingReservations + infoChart[i].totalCount;
                countingPassenger = countingPassenger + infoChart[i].passAmount;
            }
            setReservationsInfo(dataReservas);
            setPassengersInfo(dataPassageiros)
            setTotalReservations(countingReservations);
            setTotalPassenger(countingPassenger);
        }
    }, [infoChart, filter])

    const optionsVendas = {
        chart: {
            title: "",
        },
        colors: [color_primary],

    };

    const optionsReservas = {
        chart: {
            title: ""
        }/* ,
        colors: [color_primary, color_secondary], */
    };

    return (
        <>
            <Row className="cards">
                <div className="col-md-2 card-unit">
                    <Card>
                        <div className="col-md-10">
                            <Row>
                                <span className='card-title'>
                                    Total de Vendas
                                </span>
                            </Row>
                            <Row>
                                <span className='cart-content'>
                                    {totalSales.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span>
                            </Row>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                                icon={["fad", "coins"]}
                                className="text-primary p-1"
                                size="3x"
                            />
                        </div>
                    </Card>
                </div>
                <div className="col-md-2 card-unit">
                    <Card>
                        <div className="col-md-10">
                            <Row>
                                <span className='card-title'>
                                    Total de Reservas
                                </span>
                            </Row>
                            <Row>
                                <span className='cart-content'>
                                    {totalReservations}
                                </span>
                            </Row>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                                icon={["fad", "ballot-check"]}
                                className="text-primary p-1"
                                size="3x"
                            />
                        </div>
                    </Card>
                </div>
                <div className="col-md-2 card-unit">
                    <Card>
                        <div className="col-md-10">
                            <Row>
                                <span className='card-title'>
                                    Total de Passageiros
                                </span>
                            </Row>
                            <Row>
                                <span className='cart-content'>
                                    {totalPassenger}
                                </span>
                            </Row>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                                icon={["fad", "ballot-check"]}
                                className="text-primary p-1"
                                size="3x"
                            />
                        </div>
                    </Card>
                </div>
            </Row>

            <Row>

                <div className="col-md-6">
                    <Card className='h-100'>
                        <div className='p-3'>
                            {salesInfo !== null && loading === false
                                ?
                                <>
                                    <Chart
                                        chartType="Bar"
                                        width="100%"
                                        height="251px"
                                        data={salesInfo}
                                        options={optionsVendas}
                                    />
                                </>
                                :
                                <>
                                    <div className="animated-background row-loading" style={{ height: '251px' }}></div>
                                </>
                            }
                        </div>
                    </Card>
                </div>
                <div className="col-md-6">
                    <Card className='h-100'>
                        <div className='p-3'>
                            {(reservationsInfo !== null && passengerInfo !== null) && loading === false
                                ?
                                <>
                                    <Chart
                                        chartType="Bar"
                                        width="100%"
                                        height="251px"
                                        data={reservationsInfo}
                                        options={optionsReservas}
                                    />
                                </>
                                :
                                <>
                                    <div className="animated-background row-loading" style={{ height: '251px' }}></div>
                                </>
                            }
                        </div>
                    </Card>
                </div>
            </Row>
        </>

    );
}

export default ChartSalesReport;