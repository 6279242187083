import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ReactQuillComponent from "../../ReactQuillComponent/ReactQuillComponent";
import api from "../../../services/api";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { addInsctructions } from "../../../store/product";
import { ErrorMessage } from "@hookform/error-message";

export interface operation {
  action: any;
  receiveObject: any;
  tourObject: any;
  isEdit: any;
  isTicket?: boolean;
  editFunction?: any;
}

const Insctructions: React.FC<operation> = React.memo(({
  action, receiveObject, tourObject, isEdit, isTicket = false, editFunction = () => { }
}: operation) => {
  /* Controle Redux  */
  const dispatch = useAppDispatch();
  const tourSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.tour)));
  /* Controle Redux  */

  const [check, setCheck] = useState<boolean>(false);
  const [infoAdd, setInfoAdd] = useState<any>([
    {
      info: "Nome completo",
      name: "fullName",
      value: check,
    },
    {
      info: "Data de nascimento",
      name: "birthday",
      value: check,
    },
    {
      info: "Gênero",
      name: "sex",
      value: check,
    },
    {
      info: "RG / CPF / Passaporte",
      name: "numDocument",
      value: check,
    },
    {
      info: "Nacionalidade",
      name: "nationality",
      value: check,
    },
    {
      info: "Telefone",
      name: "phone",
      value: check,
    },
  ]);

  const [allowEdit, setAllowEdit] = useState<boolean>(false);

  const [instructionsPT, setInstructionsPT] = useState<string>("");
  const [instructionsEN, setInstructionsEN] = useState<string>("");
  const [instructionsES, setInstructionsES] = useState<string>("");

  const [isInfoPersonal, setIsInfoPersonal] = useState<number>(1);
  const [isTraveled, setIsTraveled] = useState<number>(1);
  const [voucherType, setVoucherType] = useState<number>(1);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({});

  const watchTravellerInfoIsNeed = watch(`travellerInfoIsNeed`, "no");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (tourObject.instructions !== undefined) {
      setInstructionsPT(tourObject.instructions[0].text);
      setInstructionsEN(tourObject.instructions[1].text);
      setInstructionsES(tourObject.instructions[2].text);
    }
  }, [tourObject]);

  useEffect(() => {
    if (tourSIG.tour?.voucherInstruction !== undefined || tourSIG.tour?.voucherInstructions !== undefined) {
      setIsTraveled(tourSIG.tour.travellerInfoForAll === true ? 2 : 1);
      setValue("travellerInfoForAll", tourSIG.tour.travellerInfoForAll);

      for (let i: any = 0; i < infoAdd.length; i++) {
        changeInfoAdd(tourSIG.tour[`${infoAdd[i].name}`], i);
        setValue(infoAdd[i].name, tourSIG.tour[`${infoAdd[i].name}`]);
      }
      if (isTicket) {
        setVoucherType(tourSIG.tour.voucherType);
        setValue("voucherType", tourSIG.tour.voucherType);
        setIsInfoPersonal(tourSIG.tour.documentIsNeeded);
        setValue("travellerInfoIsNeed", tourSIG.tour.documentIsNeeded === 1 ? "yes" : "no");
      } else {
        setIsInfoPersonal(tourSIG.tour.travellerInfoIsNeed === true ? 1 : 2);
        setValue("travellerInfoIsNeed", tourSIG.tour.travellerInfoIsNeed === true ? "yes" : "no");
      }

      if (tourSIG.tour?.voucherInstruction !== undefined) {
        setInstructionsPT(tourSIG.tour.voucherInstruction[0].text);
        setValue("instructionsPT", tourSIG.tour.voucherInstruction[0].text);

        setInstructionsEN(tourSIG.tour.voucherInstruction[1].text);
        setValue("instructionsEN", tourSIG.tour.voucherInstruction[1].text);

        setInstructionsES(tourSIG.tour.voucherInstruction[2].text);
        setValue("instructionsES", tourSIG.tour.voucherInstruction[2].text);
      }

      if (tourSIG.tour?.voucherInstructions !== undefined) {
        setInstructionsPT(tourSIG.tour.voucherInstructions[0].text);
        setValue("instructionsPT", tourSIG.tour.voucherInstructions[0].text);

        setInstructionsEN(tourSIG.tour.voucherInstructions[1].text);
        setValue("instructionsEN", tourSIG.tour.voucherInstructions[1].text);

        setInstructionsES(tourSIG.tour.voucherInstructions[2].text);
        setValue("instructionsES", tourSIG.tour.voucherInstructions[2].text);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTicketObject = (data: any) => {
    data.isTicket = true;

    data.travellerInfoIsNeed = isInfoPersonal; // 1 ou 2
    data.documentIsNeeded = isInfoPersonal; // 1 ou 2
    data.voucherType = voucherType; // 1 ou 2

    data.voucherInstructions = [
      {
        text: instructionsPT,
        lang: 1,
      },
      {
        text: instructionsEN,
        lang: 2,
      },
      {
        text: instructionsES,
        lang: 3,
      },
    ];

    delete data.instructionsPT;
    delete data.instructionsEN;
    delete data.instructionsES;

    return data;
  }

  const createTourObject = (data: any) => {
    data.travellerInfoIsNeed = false//isInfoPersonal === 1 ? true : false;
    data.travellerInfoForAll = false//isTraveled === 1 ? false : true;
    data.fullName = false//data.fullName === undefined ? false : data.fullName;
    data.sex = false//data.sex === undefined ? false : data.sex;
    data.nationality = false//data.nationality === undefined ? false : data.nationality;
    data.birthday = false//data.birthday === undefined ? false : data.birthday;
    data.numDocument = false//data.numDocument === undefined ? false : data.numDocument;
    data.cellPhone = false//data.phone === undefined ? false : data.phone;

    data.voucherInstruction = [
      {
        text: instructionsPT,
        lang: 1,
      },
      {
        text: instructionsEN,
        lang: 2,
      },
      {
        text: instructionsES,
        lang: 3,
      },
    ];

    delete data.instructionsPT;
    delete data.instructionsEN;
    delete data.instructionsES;

    return data;
  }

  const onSubmit = (data: any) => {
    let newTourObject: any = { ...tourObject, ...data };
    let dataToInsert: any;

    if (data.travellerInfoIsNeed === 'yes' && !isTicket) {
      if (!(data.fullName || data.birthday || data.sex || data.numDocument || data.nationality || data.phone)) {
        setError('travellerInfoIsNeed', { type: 'custom', message: 'Favor selecionar pelo menos uma das informações pessoais' })
        return;
      } else {
        setError('travellerInfoIsNeed', { type: 'custom', message: '' });
      }
    }

    if (isTicket) {
      dataToInsert = createTicketObject(data);
    } else {
      dataToInsert = createTourObject(data);
    }

    if (isEdit !== true) {
      dispatch(addInsctructions(dataToInsert));
      action("seo", newTourObject);
    } else {
      if (!isTicket) {
        delete dataToInsert.voucherType;
      }

      editFunction(dataToInsert)
      //receiveObject(data);
    }
  };

  function changeInfoAdd(value: any, index: any) {
    let aux: any = infoAdd;
    aux[index].value = !aux[index].value;
    setInfoAdd(aux);
    setCheck(!check);
  }


  const handleBlur = (e: any) => {
    if (tourObject.isTranslate === 1 && allowEdit === false) {
      let ptText: any = getValues().instructionsPT;
      TranslateTextEN(ptText);
      TranslateTextES(ptText);
    }
  };

  const TranslateTextEN = async (name: string) => {
    if (name !== undefined) {
      try {
        const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
          "q": name,
          "source": "pt-BR",
          "target": "en",
          "format": "text"
        });
        if (res.status !== 400) {
          setInstructionsEN(res?.data?.data?.translations[0]?.translatedText);
          setValue("instructionsEN", res?.data?.data?.translations[0]?.translatedText);
        }
      } catch (error: any) { }
    }
  };
  /* Testando api de tradução */
  const TranslateTextES = async (name: string) => {
    if (name !== undefined) {
      try {
        const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
          "q": name,
          "source": "pt-BR",
          "target": "es",
          "format": "text"
        });
        if (res.status !== 400) {
          setInstructionsES(res?.data?.data?.translations[0]?.translatedText);
          setValue("instructionsES", res?.data?.data?.translations[0]?.translatedText);
        }
      } catch (error: any) { }
    }
  };

  return (
    <>
      {
        (isEdit === false || tourSIG?.tour?.productName) ?
          <Col md={9} lg={10}>
            <Card className="pt-content">
              <Form
                className="h-100"
                noValidate
                validated={false}
                onSubmit={handleSubmit(onSubmit)}
              >
                <h3 className="text-primary pt-title mb-0">Instruções</h3>

                <div className="pt-content-info">
                  <Row>
                    {false/* !isTicket */ &&
                      <Col md='6'>
                        <Row>
                          <Form.Group as={Col} md="12" controlId="">
                            <Form.Label className="label-small">
                              {isTicket ? "Necessário algum documento?" : "Os viajantes precisam fornecer informações pessoais?"}

                            </Form.Label>

                            <Row className="mx-0 gap-4">
                              <Controller
                                control={control}
                                name="travellerInfoIsNeed"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-1 mb-1"
                                    type="radio"
                                    id={"travellerInfoIsNeed-1"}
                                    value="yes"
                                    required
                                    checked={getValues().travellerInfoIsNeed === "yes"}
                                    label={"Sim"}
                                    onClick={() => setIsInfoPersonal(1)}
                                  />
                                )}
                              />

                              <Controller
                                control={control}
                                name="travellerInfoIsNeed"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-1 mb-1"
                                    type="radio"
                                    id={"travellerInfoIsNeed-2"}
                                    value="no"
                                    required
                                    defaultChecked={true}
                                    checked={
                                      getValues().travellerInfoIsNeed === "no" ||
                                      !getValues().travellerInfoIsNeed
                                    }
                                    label={"Não"}
                                    onClick={() => setIsInfoPersonal(2)}
                                  />
                                )}
                              />
                            </Row>
                            <ErrorMessage
                              errors={errors}
                              name={`travellerInfoIsNeed`}
                              render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                          </Form.Group>

                          {watchTravellerInfoIsNeed === "yes" && !isTicket && (
                            <>
                              <Row>
                                <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                  <Form.Label className="label-small">
                                    Informações solicitadas
                                  </Form.Label>

                                  <Row className="mx-0">
                                    {infoAdd.map((elem: any, index: any) => {
                                      return (
                                        <Controller
                                          control={control}
                                          name={elem.name}
                                          render={({ field }: any) => (
                                            <Form.Check
                                              {...field}
                                              id={`infoAdd${index}`}
                                              type="checkbox"
                                              variant="standard"
                                              margin="normal"
                                              label={elem.info}
                                              checked={getValues()[elem.name] === true}
                                              onChange={(e: any) => {
                                                changeInfoAdd(e.target.checked, index);
                                                setValue(
                                                  `${elem.name}`,
                                                  e.target.checked
                                                );
                                              }}
                                              required
                                            />
                                          )}
                                        />
                                      );
                                    })}
                                  </Row>
                                </Form.Group>
                              </Row>
                            </>
                          )}
                        </Row>
                      </Col>
                    }
                    <Col md='6'>
                      <Row>
                        {false/* !isTicket */ &&
                          <Form.Group as={Col} md="12" controlId="">
                            <Form.Label className="label-small">
                              Quais viajantes precisam fornecê-las?
                            </Form.Label>

                            <Row className="mx-0 gap-4">
                              <Form.Check
                                className="col-4 mb-1"
                                type="radio"
                                name="travellerInfoForAll"
                                id={"travellerInfoForAll-1"}
                                value="1"
                                required
                                checked={isTraveled === 1 ? true : false}
                                label={"Apenas um representante"}
                                onClick={() => setIsTraveled(1)}
                              />

                              <Form.Check
                                className="col-1 mb-1"
                                type="radio"
                                name="travellerInfoForAll"
                                id={"travellerInfoForAll-2"}
                                value="2"
                                required
                                checked={isTraveled === 2 ? true : false}
                                label={"Todos"}
                                onClick={() => setIsTraveled(2)}
                              />
                            </Row>
                          </Form.Group>
                        }

                        {
                          isTicket &&
                          <Form.Group as={Col} md="12" controlId="" className=''>
                            <Form.Label className="label-small">
                              Tipo de ingresso
                            </Form.Label>

                            <Row className="mx-0 gap-4">
                              <Form.Check
                                className="col-4 mb-1"
                                type="radio"
                                name="voucherType"
                                value="1"
                                required
                                checked={voucherType === 1 ? true : false}
                                label={"Mobile ou papel"}
                                onClick={() => setVoucherType(1)}
                              />

                              <Form.Check
                                className="col-4 mb-1"
                                type="radio"
                                name="voucherType"
                                value="2"
                                required
                                checked={voucherType === 2 ? true : false}
                                label={"Somente papel"}
                                onClick={() => setVoucherType(2)}
                              />
                            </Row>
                          </Form.Group>
                        }
                      </Row>
                    </Col>
                  </Row>


                </div>

                <div className="pt-content-info" style={{ border: "none" }}>
                  <Row>
                    <div>
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-2 pt-subtitle">
                          Instruções do Voucher
                        </h5>
                        {tourObject.isTranslate === 1 && (
                          <>
                            <div
                              className="btn-edit ml-2"
                              style={{ position: "unset" }}
                              onClick={() => setAllowEdit(!allowEdit)}
                            >
                              <FontAwesomeIcon
                                icon={["fal", "edit"]}
                                size="1x"
                                className="mr-2"
                              />
                              Editar
                            </div>
                          </>
                        )}
                      </div>

                      <p className="small mb-0">
                        Crie um texto curto para contar de forma clara e atrativa.
                      </p>
                      <p className="small mb-0">
                        Sugerimos que adicione as seguintes palavras mais buscadas
                        pelos passageiros no Google: ingresso, excursão, passeio e
                        atração.
                      </p>
                    </div>

                    <ReactQuillComponent
                      setValue={setValue}
                      control={control}
                      handleBlur={handleBlur}
                      name="instructionsPT"
                      title="Instruções do Tour (Português)"
                      errors={errors}
                      setState={setInstructionsPT}
                      state={instructionsPT}
                      isEdit={isEdit}
                      size={12}
                      id="instructionsPT"
                      counter={false}
                      required={false}
                    />

                    <ReactQuillComponent
                      setValue={setValue}
                      control={control}
                      name="instructionsEN"
                      title="Instruções do Tour (Inglês)"
                      errors={errors}
                      setState={setInstructionsEN}
                      state={instructionsEN}
                      isEdit={isEdit}
                      size={6}
                      counter={false}
                      id="instructionsEN"
                      style={
                        tourObject.isTranslate === 1 &&
                          allowEdit === false
                          ? { backgroundColor: "#e9ecef", opacity: "1" }
                          : {}
                      }
                      required={false}
                    />

                    <ReactQuillComponent
                      setValue={setValue}
                      control={control}
                      name="instructionsES"
                      title="Instruções do Tour (Espanhol)"
                      errors={errors}
                      setState={setInstructionsES}
                      state={instructionsES}
                      isEdit={isEdit}
                      size={6}
                      counter={false}
                      id="instructionsES"
                      style={
                        tourObject.isTranslate === 1 &&
                          allowEdit === false
                          ? { backgroundColor: "#e9ecef", opacity: "1" }
                          : {}
                      }
                      required={false}
                    />

                  </Row>
                </div>

                <div className="h-100 d-flex justify-content-end align-items-end">
                  {isEdit !== true ? (
                    <Row className="mx-0 mt-5">
                      <Button
                        variant="outline-primary"
                        className="form-button mr-3"
                        onClick={() => action("location")}
                      >
                        Voltar
                      </Button>
                      <Button type="submit" className="form-button">
                        Continuar
                      </Button>
                    </Row>
                  ) : (
                    <Row className=" mx-0 mt-5">
                      <Button
                        type="submit"
                        className="form-button"
                      //onClick={() => action('meetingPoint')}
                      >
                        Salvar
                      </Button>
                    </Row>
                  )}
                </div>
              </Form>
            </Card>
          </Col>
          :
          <>
            <Col md={9} lg={10}>
              <Card className="pt-content h-100">
                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                  <div className="load mb-4"></div>
                  <div>Carregando dados do produto</div>
                </div>
              </Card>
            </Col>
          </>
      }
    </>
  );
}
);

export default Insctructions;
