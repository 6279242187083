import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import './ListAccounts.scss'

//COMPONENTS
import AccountsFilter from '../components/AccountsFilter/AccountsFilter';
import AccountsTable from '../components/AccountsTable/AccountsTable';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../../templates/DesktopDefault';


function ListAccounts() {
  //const { t } = useTranslation();
  const [accounts, setAccounts] = useState<any>(null);
  const [actualName, setActualName] = useState<any>("");
  const [actualType, setActualType] = useState<any>(0);
  const [actualStatus, setActualStatus] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  useEffect(() => {
    setAccounts(null);
    setLoading(true);
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listAccounts() {
      try {
        const { data } = await api.post('MerchantAccount/GetReportAsync',
          {
            "page": pageCount,
            "rowsPerPage": rowsPerPage,
            "contaAdquirente": 1,
            "nome": actualName,
            "status": actualStatus,
            "tipo": actualType,
            "orderByField": ""
          }, config
        );

        if (data.status !== 400) {
          setLoading(false);
          setAccounts(data.data.rows);
          setTotalRows(data.data.rowsCount);
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccounts();
  }, [pageCount, rowsPerPage]);

  async function buscar(name: string, type: any, status: any) {
    setAccounts(null);
    setLoading(true);
    setActualName(name);
    setActualType(type);
    setActualStatus(status);
    setPageCount(1);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    try {
      const res = await api.post('/MerchantAccount/GetReportAsync',
        {
          "page": 1,
          "rowsPerPage": rowsPerPage,
          "contaAdquirente": 1,
          "nome": name,
          "status": (status === '') ? null : status,
          "tipo": (type === '') ? null : type,
          "orderByField": ""
        }, config
      );
      if (res.status !== 400) {
        setLoading(false);
        setAccounts(res.data.data.rows);
        setTotalRows(res.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
    }
  };

  function clearActualValues() {
    setActualName("");
    setActualType(0);
    setActualStatus(0);
  }

  return (
    <>
        <DesktopDefault>
          <div className="container-fluid content-dashboard">

            <Breadcrumb title={'Financeiro / Parâmetros / Lista de Contas Correntes'} />

            <div className="accounts mt-4">
              <h2 className="title pb-2">Lista de Contas Correntes</h2>
            </div>

            <AccountsFilter
              buscar={buscar}
              clearActualValues={clearActualValues}
            />

            <AccountsTable
              accounts={accounts}
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              loading={loading}
            />
          </div>
        </DesktopDefault>

    </>

  );

}

export default ListAccounts;