import React, { useState } from "react";
import api from '../../../../services/api';

import { Form, Modal, Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";

//STYLES
import "./TableBillsReceive.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import Insert from "./components/Insert/Insert";
import SingleNote from "./components/SingleNote/SingleNote";
import CreditNote from "./components/CreditNote/CreditNote";
import Invoice from "./components/Invoice/Invoice";
import ExpirationReceive from "./components/ExpirationReceive/ExpirationReceive";
import FormBillsToPay from "../../../../components/Forms/FormBillsToPay/FormBillsToPay";

export interface propTable {
  billsReceive: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  loading: any;
  exportBills: any;
  printBills: any;
  totalBillsReceive?: any;
  actualDate: any;
  parameters?: any;
}

const TableBillsReceive: React.FC<propTable> = ({
  billsReceive, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loading, exportBills, printBills, totalBillsReceive, actualDate, parameters
}: propTable) => {
  const { t } = useTranslation();

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [expiration, setExpiration] = useState<any>();

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>();
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalResponse, setModalResponse] = useState<any>(null);
  const [datapgto, setDatapgto] = useState<any>(undefined);

  const tourAuth: any = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;

  async function settleBills(row: any) {
    setSelectedSingleRow(row);  //recebe array vazio ([]) para liquidar consulta
    setModalShow(true);
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" onClick={() => { settleBills(row) }} ><FontAwesomeIcon icon={['fal', 'dollar-sign']} className="mr-2" style={{ width: '18px' }} />Liquidar</Dropdown.Item>
              <Dropdown.Item eventKey="3" disabled={true}><FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />   {t('suppliersTable.edit')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any, row: any) {
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell}</span>
      </>
    )
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Pendente"
            ? "bg-analise"
            : cell === "Confirmada"
              ? "bg-ativo"
              : cell === "Cancelada"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  function addPayment(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Pendente"
            ? "bg-inativo"
            : cell === "Recebido"
              ? "bg-ativo"
              : "bg-analise"
        }
      >
        {cell}
      </div>
    );
  }

  const columns = [
    {
      dataField: "vencimento",
      text: "Vencimento",
      sort: true,
      formatter: convertDate,
    },
    { dataField: "formaPagamento", text: "Forma de Pagamento", sort: true },
    { dataField: "nFatura", text: "Nº Fatura" },
    { dataField: "agente", text: "Agente", sort: true },
    {
      dataField: "dataReserva",
      text: "Data Reserva",
      sort: true,
      formatter: convertDate,
    },
    {
      dataField: "dataOperacao",
      text: "Data Operação",
      sort: true,
      formatter: convertDate,
    },
    { dataField: "tour", text: "Tour", sort: true },
    { dataField: "cliente", text: "Cliente", sort: true },
    {
      dataField: "valor",
      text: "Valor",
      sort: true,
      formatter: convertValue,
    },
    {
      dataField: "statusReserva",
      text: "Status Reserva",
      sort: true,
      formatter: addStatus,
    },
    {
      dataField: "statusPagamento",
      text: "Status Pagamento",
      sort: true,
      formatter: addPayment,
    },
    { dataField: "id", text: "Ações", formatter: addActionButton },
  ];

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "contas", text: "Contas" }];

  const notFoundProducts = [{ contas: "Nenhuma conta encontrada" }];
  /* [END] In case of empty table*/

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando contas", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.statusReserva === "Pendente") {
      return 'border-analise';
    } else if (row.statusReserva === "Confirmada") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };


  /*NOTA AVULSA PESQUISAR */

  const [singleNoteInfo, setSingleNoteInfo] = useState<any>(undefined);

  const searchSingleNote = (localizador: any = '') => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function getSingleSearch() {
      try {
        const { data } = await api.get(`/BillsReceivable/GetOrderInfo/${localizador}`, config);
        if (data.statusCode !== 400) {
          //console.log(data);
          setSingleNoteInfo(data.data);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          //
        }
      }
    }
    getSingleSearch();
  }

  function closeAction() {
    setSingleNoteInfo(undefined);
  }
  /* [END] NOTA AVULSA PESQUISAR */


  const onSubmit = (data: any) => {
    var fixDate = datapgto.split('/');
    data.dataPgto = `${fixDate[2]}-${fixDate[1]}-${fixDate[0]}`;
    data.contaLiquidacao = data.contaLiquidacao ? data.contaLiquidacao.id : 0;
    var valor: any = selectedSingleRow.valor;

    var items: any = [{
      "id": parseInt(selectedSingleRow.newPaymentsId),
      "tipo": 0//itemsToSettle[i].tipo
    }];

    async function settleQuery() {
      try {
        const res = await api.post('/BillsReceivable/SettleBills',
          {
            //"parametros": parameters, // objeto com os parametros da consulta
            "items": items, // array com os itens a serem liquidados
            "valor": valor,
            "descTaxa": data.descTaxa ? parseInt(data.descTaxa) : 0, // valor taxa desconto
            "jurosMulta": data.jurosMulta ? parseInt(data.jurosMulta) : 0, // valor taxa juros
            "contaLiquidacao": data.contaLiquidacao ? parseInt(data.contaLiquidacao) : 0, // conta liquidação
            "dataPgto": data.dataPgto, // data do pagamento
            "uid": uId,
            "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
          },
        );
        if (res.status !== 400) {
          if (res.data.data.log === 0) {
            setModalResponse('success');
            //setAllowReload(true);
          }
          else if (res.data.data.log === 1) {
            setModalResponse('error');
          }
        }
      } catch (error: any) {
        console.log(error.response)
        if (error?.response?.status === 401) {
          //window.location.href = window.location.origin + '/';
        }
      }
    }

    settleQuery();
  }

  function handleReload() {
    window.location.reload();
  }

  if (billsReceive !== null) {
    return (
      <>
        <div className="table-default bills-receive-table">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <ModalDefault
                      title="Inserir Pagamento"
                      name="Inserir"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <Insert
                        show={setModalSuccess}
                        setResponseText={setResponseText}
                        setModalContent={setModalContent}
                        setLog={setLog}
                      />
                    </ModalDefault>
                  </div>
                  <div>
                    <ModalDefault
                      title="Nota Avulsa"
                      name="Nota Avulsa"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                      localation="yes"
                      getLocalizador={searchSingleNote}
                      closeAction={closeAction}
                    >
                      <SingleNote singleNoteInfo={singleNoteInfo} searchSingleNote={searchSingleNote} />
                    </ModalDefault>
                  </div>
                  <div>
                    <ModalDefault
                      title="Nota de Crédito"
                      name="Nota de Crédito"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                      getLocalizador={searchSingleNote}
                      closeAction={closeAction}
                    >
                      <CreditNote singleNoteInfo={singleNoteInfo} searchSingleNote={searchSingleNote} />
                    </ModalDefault>
                  </div>
                  <div>
                    <button className="btn btn-bills p-0">
                      <Dropdown drop="down">
                        <Dropdown.Toggle variant="light ">
                          <FontAwesomeIcon
                            icon={["fal", "share"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          Exportar
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="1" onClick={() => { exportBills(1) }} >  Layout Padrão</Dropdown.Item>
                          <Dropdown.Item eventKey="2" onClick={() => { exportBills(2) }} >  Layout Azul Viagens</Dropdown.Item>
                          <Dropdown.Item eventKey="3" onClick={() => { exportBills(3) }} >  Layout HotelBeds</Dropdown.Item>
                          <Dropdown.Item eventKey="4" onClick={() => { exportBills(4) }} >  Layout Cartão de Crédito</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </button>
                  </div>
                  <div>
                    <button className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </button>
                  </div>
                  <div>
                    <ModalDefault
                      title="Faturar"
                      name="Faturar"
                      icon="yes"
                      typeIcon="file-invoice"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <Invoice />
                    </ModalDefault>
                  </div>
                  <div>
                    <Dropdown drop="down" >
                      <Dropdown.Toggle className="menu-profile p-0">
                        <button className="btn btn-bills">
                          <FontAwesomeIcon
                            icon={["fal", "dollar-sign"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          Liquidar em Lote
                        </button>
                      </Dropdown.Toggle>

                      <Dropdown.Menu id="dropdown-menu-liquidar">
                        <Dropdown.Item eventKey="1" disabled={false} onClick={() => { window.location.href = "/settle-bills-to-receive" }}> Liquidar Parcial</Dropdown.Item>
                        {/*  <Dropdown.Item eventKey="2" disabled={false} onClick={() => { settleBills([]) }}> Liquidar Consulta</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <ModalDefault
                      title="Vencimento"
                      name="Alterar Vencimento em Lote"
                      icon="yes"
                      typeIcon="calendar"
                      classBtn="btn btn-bills"
                      classModal="modal-large modal-large-custom"
                      vencimento="yes"
                      setValue={setExpiration}
                    >
                      <ExpirationReceive
                        billsReceive={billsReceive}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        setExpiration={setExpiration}
                        expiration={expiration}
                        actualDate={actualDate}
                        parameters={parameters}
                      />
                    </ModalDefault>
                  </div>
                </div>
                <div className="d-flex align-items-center" style={{ textAlign: "right" }}>
                  <h5 className="mb-0">Total: <span className={Math.sign(totalBillsReceive) === -1 ? "negative" : ""}>{totalBillsReceive.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></h5>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsReceive}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
        {/* Modal de Liquidar */}
        <Modal
          className={"modal-custom modalAuth modal-large"}
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
              <h3>
                Liquidar
              </h3>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
              <FormBillsToPay control={control} errors={errors} setDatapgto={setDatapgto} modalResponse={modalResponse} setModalResponse={setModalResponse} billsReceive={true} />
            </Form>
          </>
        </Modal>
        {/* END - Modal de Liquidar */}

        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => setModalSuccess(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 1 || log === "1"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                            style={{ fontSize: "7.5em", color: "#6731FF" }}
                          />
                          :
                          log === 0 || log === "0"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "check-circle"]}
                              size="5x"
                              style={{ fontSize: "7.5em", color: "#6731FF" }}
                            />
                            :
                            <FontAwesomeIcon
                              icon={["fal", "question-circle"]}
                              size="5x"
                              style={{ fontSize: "7.5em", color: "#6731FF" }}
                            />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={handleReload}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          <button
                            onClick={() => setModalShow(false)}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}
      </>
    );
  } else if (billsReceive === null && loading === true) {
    return (
      <>
        <div className="table-default bills-receive-table loading not-found">
          <div className="table-container">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <ModalDefault
                    title="Inserir Pagamento"
                    name="Inserir"
                    icon="yes"
                    typeIcon="plus-circle"
                    classBtn="btn btn-bills"
                    classModal="modal-large"
                  >
                    <Insert />
                  </ModalDefault>
                </div>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      icon={["fal", "plus-circle"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Nota Avulsa
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      icon={["fal", "plus-circle"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Nota de Crédito
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      icon={["fal", "file-invoice"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Faturar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      icon={["fal", "dollar-sign"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Liquidar em Lote
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      icon={["fal", "calendar"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Alterar Vencimento em Lote
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default bills-receive-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableBillsReceive;
