/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import { useTranslation } from 'react-i18next';

//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../../templates/DesktopDefault';
import CorporateFilter from '././CorporateTicketFilter/CorporateTicketFilter';
import CorporateTable from '././CorporateTicketTable/CorporateTicketTable';
import { Link } from 'react-router-dom';

function CorporateTicket() {
  const { t } = useTranslation();

  const level = atob(
    JSON.parse(localStorage.getItem('bpaSigAuth') || '{}').user.perfil.nivel,
  );

  const [corporateTicket, setCorporateTicket] = useState<any>(null);
  const [modalContent, setModalContent] = useState<any>();
  const [confirm, setConfirm] = useState<any>(
    'Tem certeza que deseja cancelar esse item?',
  );
  const [log, setLog] = useState<any>();
  const [id, setId] = useState<any>('');
  const [actualDate, setActualDate] = useState<any>('');
  const [status, setStatus] = useState<any>(0);
  const [nameClient, setNameClient] = useState<any>(0);
  const [requester, setRequester] = useState<any>('');
  const [loading, setLoading] = useState<any>(false);
  const [actualDateType, setActualDateType] = useState<any>(1);
  const [typeTicket, setTypeTicket] = useState<any>('');

  const [pdfBase64, setPdfBase64] = useState<any>();

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  useEffect(() => {
    setCorporateTicket(null);
    setLoading(true);
    let beginDate: any = [];
    let endDate: any = [];

    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/');
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/');
      endDate = actualDate[1].split('/');
    }

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '69420',
      },
    };
    async function listarReservas() {
      try {
        const { data } = await api.post(
          `/Report/ReportCorpByFilter`,
          {
            page: pageCount,
            rowsPerPage: rowsPerPage,
            id: 0,
            booking: id,
            status: status,
            idClient: nameClient === "" || nameClient === undefined ? 0 : nameClient,
            applicant: requester,
            dateType: actualDateType,
            dateI: actualDate.length > 0 ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : "",
            dateF: actualDate.length === 1 ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : actualDate !== null && actualDate.length === 2 ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : "",
            source: typeTicket
          },
          config,
        );

        if (data.status !== 400) {
          setPdfBase64(data.data.file);
          setLoading(false);
          setCorporateTicket(data.data.rows);
          setTotalRows(data.data.rowsCount);
          //console.log(data.data);
        }
      } catch (error: any) {
        setLoading(false);
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listarReservas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  async function buscar(
    id: string,
    applicant: string,
    idClient: any,
    date: any,
    status: any,
    dataType: number,
    actualPage: number = 1,
    type: any
  ) {
    setCorporateTicket(null);
    setLoading(true);
    setId(id);
    setStatus(status);
    setActualDate(date);
    setPageCount(actualPage);
    setActualDateType(dataType);
    setRequester(applicant);
    setNameClient(idClient);
    setTypeTicket(type);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '69420',
      },
    };

    let beginDate = [];
    let endDate = [];

    if (date.length === 1) {
      beginDate = date[0].split('/');
    } else if (date.length === 2) {
      beginDate = date[0].split('/');
      endDate = date[1].split('/');
    }

    try {
      const res = await api.post(
        `/Report/ReportCorpByFilter`,
        {
          page: actualPage,
          rowsPerPage: rowsPerPage,
          id: 0,
          booking: id,
          status: status,
          idClient: idClient === "" || idClient === undefined ? 0 : idClient,
          applicant: applicant,
          dateType: dataType,
          dateI:
            date.length > 0
              ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`
              : "",
          dateF:
            date.length === 1
              ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}`
              : date !== null && date.length === 2
                ? `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                : "",
          source: type === "" || type === undefined ? "" : type
        },
        config,
      );
      if (res.status !== 400) {
        setPdfBase64(res.data.data.file);
        setLoading(false);
        setCorporateTicket(res.data.data.rows);
        setTotalRows(res.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
    }
  }

  function handleModal() {
    let modal = document.querySelector('.bg-modal-custom');
    modal?.classList.toggle('modal-close');
  }

  function reloadTable() {
    buscar(
      id,
      requester,
      nameClient,
      actualDate,
      status,
      actualDateType,
      pageCount,
      typeTicket
    );
  }

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb
            title={'Reservas / Listar Reagendamentos'}
          />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: '#707070' }}>
              Listar Reagendamentos
            </h2>
            <Link to={'/corporate-sale'} className="btn btn-primary">
              Reagendamento
            </Link>
          </div>

          <CorporateFilter buscar={buscar} />

          <CorporateTable
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            corporateTicket={corporateTicket}
            confirm={confirm}
            log={log}
            modalContent={modalContent}
            setConfirm={setConfirm}
            setLog={setLog}
            handleModal={handleModal}
            loading={loading}
            reloadTable={reloadTable}
            pdfBase64={pdfBase64}
          />
        </div>
      </DesktopDefault>
    </>
  );
}

export default CorporateTicket;