import React, { useEffect, useState, Key } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
};

const GetTariffType: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`/DropDown/GetTariffType`, config);
        if (data.status !== 400) {
          setInfo(data.data)
          /* console.log(data); */
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);

  console.log(info)

  if (info !== null) {
    return (
      <>
        <Form.Select
          {...propsField}
          aria-invalid={propsErrors?.TarTipo ? "true" : ""}
          label={propsLabel}
          as="select"
          variant="standard"
          margin="normal"
          //disabled={isGroup !== "1" ? true : false}
          required
        >
          <option value='' selected disabled>Selecione</option>
          {
            info.length > 0
              ?
              info.map((info: any, index: Key) => (
                <option key={index} value={info.id}>{info.description}</option>
              ))
              :
              ''
          }
        </Form.Select>
      </>
    );
  } else {
    return <>

    </>
  }
}

export default GetTariffType;