import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import './ListBlackout.scss'

//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../../templates/DesktopDefault';
import BlackoutFilter from './conponents/BlackoutFilter';
import BlackoutTable from './conponents/BlackoutTable';
import { Link } from 'react-router-dom';
//import ReservationsTableResponsive from '../components/ReservationsTableResponsive/ReservationsTableResponsive';

function ListBlackout() {
  const [blackouts, setBlackouts] = useState<any>(null);
  const [name, setName] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [status, setStatus] = useState<number>(0);
  const [actualDate, setActualDate] = useState<any>([]);
  const [actualProductCode, setActualProductCode] = useState<string>('');

  const [loading, setLoading] = useState<any>(false);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  //let today: any = new Date();

  useEffect(() => {
    setBlackouts(null);
    setLoading(true);

    let beginDate: any = []
    let endDate: any = []
    
    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/')
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/')
      endDate = actualDate[1].split('/')
    }

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    async function listarBlackouts() {
      try {
        const { data } = await api.post('/Blackout/GetBlackoutByFilterAsync',
          {
            "page": pageCount,
            "rowsPerPage": rowsPerPage,
            "blackoutNameFind": name,
            "blackoutCodeFind": code,
            "blackoutProductCodeFind": actualProductCode,
            "blackoutStatusFind": status,
            "blackoutGroupIdFind": 0,
            "blackoutDateStartFind": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : ''/* `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}` */,
            "blackoutDateEndFind": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : ''/* `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}` */,
            "orderByField": ""
          }, config
        );

        if (data.status !== 400) {
          setLoading(false);
          setBlackouts(data.data.data.rows);
          setTotalRows(data.data.data.rowsCount);
        }
      } catch (error: any) {
        setLoading(false);
        /* setTimeout(() => {
          listarBlackouts();
        }, 1000); */
      }
    }
    listarBlackouts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  async function buscar(name: string, code: string, status: number, date: any, productCode: any) {
    setBlackouts(null);
    setLoading(true);
    setName(name);
    setStatus(status);
    setCode(code);
    setActualDate(date);
    setActualProductCode(productCode);
    setPageCount(1);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    let beginDate = []
    let endDate = []
    //let today: any = new Date();

    if (date.length === 1) {
      beginDate = date[0].split('/')
    } else if (date.length === 2) {
      beginDate = date[0].split('/')
      endDate = date[1].split('/')
    }

    try {
      const res = await api.post('/Blackout/GetBlackoutByFilterAsync',
        {
          "page": pageCount,
          "rowsPerPage": rowsPerPage,
          "blackoutNameFind": name,
          "blackoutCodeFind": code,
          "blackoutProductCodeFind": productCode,
          "blackoutStatusFind": status,
          "blackoutGroupIdFind": 0,
          "blackoutDateStartFind": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : ''/* `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}` */,
          "blackoutDateEndFind": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : ''/* `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}` */,
          "orderByField": ""
        }, config
      );
      if (res.status !== 400) {
        setLoading(false);
        setBlackouts(res.data.data.data.rows);
        setTotalRows(res.data.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">

          <Breadcrumb title={"Blackout / Listar Blackouts"} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>Listar Blackouts</h2>
          <Link to={'/blackout/add-blackout'} className='btn btn-primary'>
              Novo Blackout
            </Link>
          </div>

          <BlackoutFilter buscar={buscar} />

          <BlackoutTable
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            blackouts={blackouts}
            loading={loading}
          />

        </div>
      </DesktopDefault>
    </>
  );
}

export default ListBlackout;