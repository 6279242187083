import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Container, Row } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* const ModalDefault: FC<{}> = ({ children }: any) => { */
function ModalQuestion(props: any) {
  const [modalShow, setModalShow] = React.useState(props.modalShow);

  useEffect(() => {

    setModalShow(props.modalShow);
  }, [props.modalShow])

  function onCancelClick(e: any) {
    if(props.handleActionCancelModal) {
      props.handleActionCancelModal(e);
    } else {
      props.setModalShow(false);
    } 
  }

  return (
    <div>
      <Modal
        className="modalAuth"
        show={modalShow}
        onHide={() => props.setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <>
          <Modal.Header className="p-3" closeButton></Modal.Header>
          <Modal.Body>
            <Container className="bg-modal-contact p-0" fluid>
              <Row>
                <h5 className="contact-title mb-5">{props.titulo}</h5>
                <p className="pl-3" style={{fontSize: "1.2rem"}}>
                  <FontAwesomeIcon
                    icon={["fal", (props.iconType ? props.iconType : "question-circle") ]}
                    size="1x"
                    style={{ marginRight: "5px" }}
                  /> {props.mensagem}
                </p>
              </Row>
            </Container>
          </Modal.Body>
          
          <Modal.Footer>
            <Row className="buttons-modal d-flex justify-content-end mx-0">
              {props.buttons ? props.buttons :
              <>
                <Button className="form-button mr-3" onClick={onCancelClick}>
                  Cancelar
                </Button>
                <Button
                  type="button"
                  className="form-button"
                  onClick={props.handleActionConfirmModal}
                >
                  <FontAwesomeIcon
                    icon={["fal", "check"]}
                    size="1x"
                    style={{ marginRight: "5px" }}
                  /> Confirmar
                </Button>
              </>
              }
            </Row>
          </Modal.Footer>
        </>
      </Modal>
    </div>
  );
};

export default ModalQuestion;
