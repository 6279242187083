import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';
import Select from 'react-select';

export interface propPlus {
    propsField: any,
    isMulti?: any,
    productsCode: any,
    setValue: any,
    watchModalities?: any,
};

const GetModalitiesListCodBlackout: React.FC<propPlus> = ({
    propsField, isMulti = false, productsCode, setValue, watchModalities
}: propPlus) => {
    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function productsListCod() {
            try {
                const { data } = await api.post(`/Blackout/FetchModalitiesByProductsAsync`,
                    {
                        "productCode": productsCode.map((elem: any) => elem.code)
                    },
                    config);
                if (data.status !== 400) {
                    let aux: any = []
                    data?.data?.data?.forEach((item: any) => {
                        item.modalities.forEach((modality: any) => {
                            aux.push({ "value": modality.modalityCode, "label": modality.modalityName, "productCode": item.productCode })
                        });
                    });

                    setInfo(() => aux);
                    setValue('modalities', watchModalities?.filter((elemFilter: any) => aux.map((elemMap: any) => elemMap.productCode).includes(elemFilter.productCode)) || '')
                }
            } catch (error: any) {
                /* setTimeout(() => {
                    productsListCod();
                }, 5000); */
            }
        }
        productsListCod();
        console.log(productsCode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsCode]);

    if (info !== null) {
        //const transformed = info.map(({ code, name }: any) => ({ value: code, label: name }));
        return (
            <>
                <div className="endpoint">
                    {isMulti === false ?
                        <>
                            <Form.Control
                                as="select"
                                {...propsField}
                                required
                                autoComplete='off'
                                className="form-select"
                            >
                                <option value={0} selected >Selecione</option>
                                {info.length > 0 ? info.map((info: any) => (
                                    <option value={info.value}>{info.label}</option>
                                )) : ''}
                            </Form.Control>
                        </>
                        :
                        <>
                            <Select
                                {...propsField}
                                aria-labelledby="aria-label"
                                inputId="aria-example-input"
                                name="aria-live-color"
                                onMenuOpen={onMenuOpen}
                                onMenuClose={onMenuOpen}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                as="select"
                                variant="standard"
                                margin="normal"
                                placeholder={"Selecione"}
                                isMulti
                                options={info}
                                getOptionLabel={(option: any) => `${option.label}`}
                            />
                        </>}
                </div>
            </>
        );
    } else {
        return (
            <div className='endpoint'>
                <Form.Select>
                    <option value='' disabled></option>
                </Form.Select>
            </div>
        )
    }
}

export default GetModalitiesListCodBlackout;